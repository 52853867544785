import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import BillEditContentInfo from './BillEditContentInfo';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';

const BillEditContent = (props) => {
  const {
    billFormData,
    onSave,
    onClose,
    errorMessage,
    billFormValidation,
    updateBillFormData,
    inProgress
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  return (
    <form onSubmit={onSave}>
      <BillEditContentInfo
        billFormData={billFormData}
        billFormValidation={billFormValidation}
        updateBillFormData={updateBillFormData}
      />    
      {
        errorMessage && 
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons 
        changesDetails={"Expense " + billFormData.BillNumber + " details"}
        onClose={onClose}
      />}
    </form>
  );
}

export default BillEditContent;