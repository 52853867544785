import React from 'react';

import IncomingLoadListRejectDialogContent from './IncomingLoadListRejectDialogContent';

const IncomingLoadListRejectDialog = (props) => {
  const {
    shipperPortalOrderAssignmentID,
    rejectFunction,
    getList,
    isOpen,
    onClose,
    reference
  } = props;

  const onReject = e => {
    e.preventDefault();
    processRejectOrder();    
  };

  const processRejectOrder = async () => {
    await rejectFunction(shipperPortalOrderAssignmentID);
    onClose();
    getList();
  };

  return (
    isOpen &&
    <IncomingLoadListRejectDialogContent
    shipperPortalOrderAssignmentID={shipperPortalOrderAssignmentID}
      onReject={onReject}
      isOpen={isOpen}
      onClose={onClose}
      reference={reference}
    />
  );
}

export default IncomingLoadListRejectDialog;