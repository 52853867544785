import { Route } from 'react-router-dom';
import MyAppointmentView from './MyAppointmentView';

const MyAppointmentRouter = () => {
  return (
    <>
      <Route exact path="/spark/my-appointment" component={MyAppointmentView} />
    </>
  );

}

export default MyAppointmentRouter;