import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './EmailTemplateService';
import { getSmartTags } from '../resource/ResourceService';
import EmailTemplateCreateDialogContent from './EmailTemplateCreateDialogContent';


const EmailTemplateCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    docTypeID: null,
    docType: '',
    subject: '',
    body: ''
  };

  const [emailTemplateFormData, setEmailTemplateFormData] = useState(initialState);
  const [emailTemplateFormValidation, setEmailTemplateFormValidation] = useState({});
  const [emailSmartTags, setEmailSmartTags] = useState([]);
  const [isEmailBodyClicked, setIsEmailBodyClicked] = useState(false);
  const [isEmailSubjectClicked, setIsEmailSubjectClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setEmailTemplateFormValidation(buildValidation(emailTemplateFormData));
    }
  }, [emailTemplateFormData, isOpen]);

  useEffect(() => { 
    if (emailTemplateFormData.docTypeID) { 
      getDocTypeSmartTags(emailTemplateFormData.docTypeID);
    } else {
      setEmailTemplateFormData(e => ({ ...e,  docTypeID: null })); 
      setEmailSmartTags([]); 
    }
  }, [emailTemplateFormData.docTypeID]);

  function getDocTypeSmartTags(docTypeID) { 
    getSmartTags(docTypeID)
    .then(function(response) {
      var data = response.split('\n');
      setEmailSmartTags(data);
    });
  };

  const emailSubjectClicked = (e) => { 
    setIsEmailBodyClicked(false);
    setIsEmailSubjectClicked(true);
  };

  const emailBodyClicked = (e) => {
    setIsEmailSubjectClicked(false); 
    setIsEmailBodyClicked(true);
  };

  const onSmartTagButtonClick = (value) => {
    if (isEmailBodyClicked) { 
      setEmailTemplateFormData(e => ({ ...e,  body: emailTemplateFormData.body + value}));
    } else if (isEmailSubjectClicked) { 
      setEmailTemplateFormData(e => ({ ...e,  subject: emailTemplateFormData.subject + value}));
    }
  };

  const updateEmailTemplateFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    emailTemplateFormData[name] = value;
    emailTemplateFormData[nameData] = valueData;
    setEmailTemplateFormData({ ...emailTemplateFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(emailTemplateFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(emailTemplateFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setEmailTemplateFormData({initialState});
    onClose();
  };
  
  return (
    isOpen &&
    <EmailTemplateCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      emailTemplateFormData={emailTemplateFormData}
      emailTemplateFormValidation={emailTemplateFormValidation}
      updateEmailTemplateFormData={updateEmailTemplateFormData}
      inProgress={inProgress}
      emailSmartTags={emailSmartTags}
      onSmartTagButtonClick={onSmartTagButtonClick}
      emailBodyClicked={emailBodyClicked}
      emailSubjectClicked={emailSubjectClicked}
    />
  );
}

const buildValidation = (emailTemplateFormData) => {
  const {
    docType,
    subject,
    body
  } = emailTemplateFormData || {};
  const validation = new Validation();
  return {
    docType: validation.isEmpty(docType),
    subject: validation.isEmpty(subject),
    body: validation.isEmpty(body),
  };
};

export default EmailTemplateCreateDialog;