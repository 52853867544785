import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CorePhoneField from '../core/CorePhoneField';
import CoreFavoriteButtonField from '../core/CoreFavoriteButtonField';

const ContactEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    contactFormData,
    onUpdate,
    errorMessage,
    contactFormValidation,
    updateContactFormData,
    currentFavorite
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Contact</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={5} xs={12}>
              <CoreTextField
                name="Name"
                label="Name"
                value={contactFormData.Name}
                onChange={e => updateContactFormData(e)}
                isRequired={true}
                validationError={contactFormValidation.Name}
                autoFocus={true}
                maxLength={50}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <CoreTextField
                name="Position"
                label="Title"
                value={contactFormData.Position}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.Position}
                maxLength={50}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <CoreTextField
                name="Email"
                label="E-mail"
                value={contactFormData.Email}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.Email}
                maxLength={255}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <CorePhoneField
                name="Phone"
                label="Phone"              
                value={contactFormData.Phone}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.Phone}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <CoreTextField
                name="Extension"
                label="Extension"              
                value={contactFormData.Extension}
                nameData="extension"
                valueData={contactFormData.Extension}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.Extension}
                maxLength={6}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CoreFavoriteButtonField
            name="isFav"
            label="Mark as Fav"
            value={contactFormData.isFav}
            onChange={e => updateContactFormData(e)}
            currentFavorite={currentFavorite}
            contactID={contactFormData.ID}
          />
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ContactEditDialogContent;