import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconOrder from '../icon/IconOrder';

const NavigationDrawerListItemIncomingLoads = () => {
  return (
    <NavigationDrawerListItem
      title={'Incoming Loads'}
      icon={<IconOrder />}
      uri={'/spark/incoming-load'} />
  );

}

export default NavigationDrawerListItemIncomingLoads;