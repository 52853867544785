import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Validation from '../validation';
import { get as getLocation, update as locationUpdate } from './LocationService';
import LocationEditContent from './LocationEditContent';
import CoreExitButton from '../core/CoreExitButton';
import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getCredentialByID } from '../envase_trace/EnvaseTraceService';

const LocationEdit = (props) => {
  let history = useHistory();
  const { id } = useParams();

  const [inProgress, setInProgress] = useState(false);
  const [locationFormData, setLocationFormData] = useState({});
  const [locationFormDataLoaded, setLocationFormDataLoaded] = useState(false);
  const [locationFormValidation, setLocationFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [coordinates, setCoordinates] = useState({'lat': 37, 'lng': -95});
  const [initialProviderID, setInitialProviderID] = useState(null);
  const [traceCredentials, setTraceCredentials] = useState({});

  useEffect(() => {
    getLocation(id)
      .then(function(response) {
        response.Province = response.Province.trim();
        response.Country = response.Country.trim();
        setLocationFormData({ ...response });
        setInitialProviderID(response.EnvaseTraceProviderID);
        setLocationFormDataLoaded(true);
      });
  }, [id]);

  useEffect(() => {
    setLocationFormValidation(buildValidation(locationFormData));
  }, [locationFormData]);

  useEffect(() => { 
    getGeocode(
      locationFormData.Address, 
      locationFormData.City, 
      locationFormData.Province, 
      locationFormData.Country, 
      locationFormData.PostalCode) 
      .then(function(response) {
        setCoordinates(response);
    });
  }, [
    locationFormData.Address, 
    locationFormData.City, 
    locationFormData.Province, 
    locationFormData.Country, 
    locationFormData.PostalCode
  ]);

  useEffect(() => { 
    if (locationFormData.EnvaseTraceProviderID) { 
      getCredentialByID(locationFormData.EnvaseTraceProviderID)
        .then(function(response) { 
          setTraceCredentials(response);
        });
    }
  }, [locationFormData.EnvaseTraceProviderID]);

  const updateLocationFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    locationFormData[name] = value;
    locationFormData[nameData] = valueData;
    setLocationFormData({ ...locationFormData });
  };

  const onSave = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(locationFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await locationUpdate(locationFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    // Redirect to the location view page
    history.push('/spark/location/view/' + locationFormData.LocationTemplateID);
  };

  const onClose = () => {
    history.push('/spark/location/view/' + id);
  };
  
  return (
    <div>
      <CorePageHeader
        title={"Edit Location " + locationFormData.InternalName}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {locationFormDataLoaded &&
        <LocationEditContent
          onSave={onSave}
          onClose={onClose}
          errorMessage={errorMessage}
          locationFormData={locationFormData}
          locationFormValidation={locationFormValidation}
          updateLocationFormData={updateLocationFormData}
          coordinates={coordinates}
          inProgress={inProgress}
          traceCredentials={traceCredentials}
          initialProviderID={initialProviderID}
        />}
      </Container>
    </div>
  );
}

const buildValidation = (locationFormData) => {
  const { 
    InternalName,
    CompanyName,
    Country,
    Province
  } = locationFormData || {};
  const validation = new Validation();
  return {
    InternalName: validation.isEmpty(InternalName),
    CompanyName: validation.isEmpty(CompanyName),
    Country: validation.isEmpty(Country),
    Province: validation.isEmpty(Province)
  };
};

export default LocationEdit;