import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'LocationAreaTag/' + token + '/';

export function useLocationAreaTagGetListRequest(id) {
  const [response, setResponse] = useState([]);

  const fetch = useCallback(() => {
    async function fetchData() {
      const params = {
        locationTemplateID: id
      };
      const httpService = new HttpService();
      const result = await httpService
        .get(uri + 'GetList', params);
      const areaTags = result.response;
      setResponse(areaTags);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id, fetch]);

  return {
    data: response,
    refetch: fetch
  };
}