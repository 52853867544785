import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import IconLiveDrop from '../icon/IconLiveDrop';
import IconShipType from '../icon/IconShipType';
import IconReferenceNumber from '../icon/IconReferenceNumber';
import IconLicensePlate from '../icon/IconLicensePlate';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const OrderViewSummaryInfoSection = (props) => {
  const classes = useStyles();

  const {
    order
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="ORDER INFORMATION" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Shipment Type"
              value={order.Type}
              icon={<IconShipType />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Live/Drop"
              value={order.LiveDrop}
              icon={<IconLiveDrop />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ fontSize: '14px' }} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Reference Number"
              value={order.ReferenceNumber}
              icon={<IconReferenceNumber />}
            />
          </Grid>
        </Grid>
        {order.OnHold && <Grid container item xs={12}>
          <Grid item xs={12} style={{ fontSize: '14px' }} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Reason for Hold"
              value={order.HoldReason}
              icon={<IconLicensePlate />}
            />
          </Grid>
        </Grid>}
      </Grid>
    </div>
  );
}

export default OrderViewSummaryInfoSection;
