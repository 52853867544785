import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ChatConversationCreateDialog from "./ChatConversationCreateDialog";
import { getContactDriversList } from './ChatService';

const useStyles = makeStyles(() => ({
  chatButton: {
    position: "absolute",
    top: "12px",
    right: "15px",
    height: "44px",
    width: "44px"
  },
}));

const ChatConversationCreateButton = (props) => {
  const { startConversation } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({});

  useEffect(() => {
    let isMounted = true;
    getContactDriversList().then((response) => isMounted && setDrivers(response));
    return () => (isMounted = false);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDriver({});
  };

  const handleChange = (e) => {
    setSelectedDriver(e.target.value);
  };

  const startConvo = () => {
    setOpen(false);
    startConversation(selectedDriver).then(() => setSelectedDriver({}));
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.chatButton}
        onClick={handleOpen}
      >
        <AddIcon />
      </Fab>
      <ChatConversationCreateDialog
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        selectedDriver={selectedDriver}
        drivers={drivers}
        startConvo={startConvo}
      />
    </>
  );
};

export default ChatConversationCreateButton;
