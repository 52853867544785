import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { get, getNotes } from './EquipmentService';
import EquipmentViewGeneralSection from './EquipmentViewGeneralSection';
import EquipmentViewDetailSection from './EquipmentViewDetailSection';
import EquipmentViewPageHeader from './EquipmentViewPageHeader';
import NoteTabBar from '../notes/NoteTabBar';
import NotesList from '../notes/NotesList';

const EquipmentView = () => {
  const { id } = useParams();

  const [equipment, setEquipment] = useState({});
  const [notes, setEquipmentNotes] = useState([]);

  useEffect(() => {
    get(id)
      .then(function(response) {
        response.ServiceStart = getDateWithoutTime(response.ServiceStart);
        response.ServiceEnd = getDateWithoutTime(response.ServiceEnd);
        setEquipment(response);
      });
    getNotes(id)
      .then(function(response) {
        setEquipmentNotes(response); 
      });
  }, [id]);

  const getEquipmentNotes = () => {
    getNotes(id)
      .then(function(response) {
        setEquipmentNotes(response);
      });
  };

  const getDateWithoutTime = (date) => {
    if (!date) {
      return;      
    }
    return date.substring(0, date.length - 9);
  };

  return (
    <div>
      <EquipmentViewPageHeader
        equipment={equipment}
      />
      <EquipmentViewGeneralSection
        equipment={equipment}
      />
      <EquipmentViewDetailSection
        equipment={equipment}
      />
      <NoteTabBar
        id={id}
        noteType={10}
        getNotes={getEquipmentNotes}
      />
      <NotesList
        notes={notes}
        getNotes={getEquipmentNotes}
      />
    </div>
  );

}

export default EquipmentView;