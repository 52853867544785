const formattingFunctions = {
  formatCurrency(value, useDecimals = false) {
    value = useDecimals ? parseFloat(value).toFixed(2) : parseInt(value);
    if (value < 0) {
      value = value * -1;
      return `-$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    } else {
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
  },

  percentageDiff(currentValue, prevValue) {
    const diff = (currentValue - prevValue);
    const number = Math.abs((diff/prevValue)*100);
    const percentage = isNaN(number) ? 0 : number;
    if (percentage === Infinity || percentage === -Infinity) {
      return '0.00 %'
    } else {
      return `${percentage.toFixed(2)} %`
    }
    
  },

  getPercentage(partialValue, totalValue) {
    const number = (100 * partialValue) / totalValue;
    const percentage = isNaN(number) ? 0 : number;
    if (percentage === Infinity || percentage === -Infinity) {
      return '0.00 %'
    } else {
      return `${percentage.toFixed(2)} %`
    }
  },

  convertDate(data){
    if(data !== null && data !== undefined){
        const date = data.substring(8, Math.min(25, 10));
        const month = data.substring(5, Math.min(25, 7));
        const time = data.substring(11, Math.min(25, 16));
        const year = data.substring(0, Math.min(25, 4));
        const d = new Date(year + '/' + month + '/' + date + ' ' + time + ':00');
        return d;
    }            
    return data;
  }
}

export default formattingFunctions;
