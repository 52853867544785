import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreDataGridList from '../core/CoreDataGridList';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import PersonnelViewSummary from './PersonnelViewSummary';
import { downloadFile } from '../globalFunction';
import { getList, getListCount, exportList } from './PersonnelService';
import Validation from '../validation';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';

const PersonnelListTable = () => {
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem('personnelListAmount')) || 10,
    count: parseInt(localStorage.getItem('personnelListAmount')) || 10,
    showObsolete: false,
    isASC: true,
    sortBy: 'InternalName',
    globalSearch: '',
    filters: []
  });
  let history = useHistory();
  const validation = new Validation();
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [listWidth, setListWidth] = useState('xl');
  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [personnel, setPersonnel] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
  });
  const [companyFeature, setCompanyFeature] = useState([]);

  const updateFilterData = (e) => {
    const { name, value } = e.target;
    filterData[name] = value;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => {  
        const name = params.getValue("ID");
        const obsolete = params.getValue("Obsolete");
        return (
          <div>
            <IconButton title="Open Full Record" onClick={() => openRecord(name)}>              
              <OpenInBrowserIcon color={obsolete ? 'error' : 'primary'}/>
            </IconButton>
            <IconButton title="Open Slideout" onClick={() => openSlideout(name)}>
              <MenuOpenIcon color={obsolete ? 'error' : 'primary'}/>
            </IconButton>
          </div>
        )
      }
    },
    {
      field: 'PersonnelID',
      hide: true,
    },
    {
      field: 'InternalName',
      headerName: 'Personnel Name',
      align: 'left',
      flex: 2,
    },
    {
      field: 'ListAsDriver',
      headerName: 'Type',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        const name = params.getValue("ListAsDriver") ? "Driver" : "User";
        return (
          <div>
            <span>
              {name}
            </span>
          </div>
        )
      }
    },
    {
      field: 'District',
      headerName: 'District',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: 'County',
      headerName: 'Barangay',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: 'City',
      headerName: 'City & State',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        const city = params.getValue("City").trim();
        const state = params.getValue("Province").trim();
        return (
          <div>
            {city && <span>
              {city}{state && <span>, {state}</span>}
            </span>}
            {!city && state && <span>{state}</span>}
          </div>
        )
      }
    }, 
    {
      field: 'HomePhone',
      headerName: 'Phone',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <CoreIntlPhoneOutput value={params.getValue("HomePhone")} />
        )
      }
    },
    {
      field: 'Email',
      headerName: 'Email',
      align: 'left',
      flex: 1,
    },
    {
      field: 'Title',
      headerName: 'Title',
      align: 'left',
      flex: 1,
    },
    {
      field: "Obsolete",
      headerName: "Active",
      align: 'left',
      flex: 1,
      hide: !params.showObsolete,
      renderCell: (params) => {
        const name = params.getValue("Obsolete") ? "No" : "Yes";
        return (
          <div>
            {name}
          </div>
        )
      }
    },
  ];

  const closeSummary = () => {
    setIsSummaryOpen(false);    
  };

  useEffect(() => {
    async function getInitPersonnelList() {
      const result = await getList({
        page: 1,
        amount: parseInt(localStorage.getItem('personnelListAmount')) || 10,
        count: parseInt(localStorage.getItem('personnelListAmount')) || 10,
        showObsolete: false,
        isASC: true,
        sortBy: 'InternalName',
        globalSearch: '',
        filters: []
      });
      const totalCount = await getListCount(params);
      if (result.length > 0) {
        setParams(c => ({ ...c, count: totalCount }));
      } else {
        setParams(c => ({ ...c, count: 0 }));
      }
      setPersonnel(result);
      setInProgress(false); 
    }
    getCompanyFeature()
        .then(function(response){
          setCompanyFeature(response);
        });
    getInitPersonnelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changePage(page) {
    params.page = page + 1;
    getPersonnelList();  
    setParams(params);
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getPersonnelList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;  
    getPersonnelList();  
    setParams(params);
    localStorage.setItem('personnelListAmount', amount);
  };

  // function pushParamFilter(filterName, value, operator) {
  //   params.filters.push({
  //     IsActive: true,
  //     columnName: filterName,
  //     conditionName: operator,
  //     condition: 2,
  //     value: value,
  //   });
  // }

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      // pushParamFilter("Search", filterData.Search, 'Contains');
      params.globalSearch = filterData.Search;
    } else {
      params.globalSearch = '';
    }
    params.page = 1;
    setParams(params);
    getPersonnelList();
  };

  const getPersonnelList = async () => {
    setInProgress(true);
    const result = await getList(params);
    const totalCount = await getListCount(params);
    if (result.length > 0) {
      setParams(c => ({ ...c, count: totalCount }));
    } else {
      setParams(c => ({ ...c, count: 0 }));
    }
    setPersonnel(result);
    setInProgress(false);
  };

  const exportPersonnelList = async () => {
    const result = await exportList(params);
    downloadFile(result, 'personnel-export.csv');
  };

  const openRecord = (id) => {
    history.push('/spark/personnel/view/' + id);
  };

  const openSlideout = (id) => {
    setCurrentRowClicked(id);
    setIsSummaryOpen(true);
  };

  const showInactive = () => {
    params.showObsolete = true;
    setParams(params);
    getPersonnelList();
  };

  const hideInactive = () => {
    params.showObsolete = false;
    setParams(params);
    getPersonnelList();
  };

  return (
    <div style={{position:'relative'}}>
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={personnel}
          params={params}
          showInactive={showInactive}
          hideInactive={hideInactive}
          exportList={exportPersonnelList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='InternalName'
          filterData={filterData}
          updateFilterData={updateFilterData}
          showObsoleteButton={true}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
      <PersonnelViewSummary
        personnelID={currentRowClicked}
        isOpen={isSummaryOpen}
        onClose={closeSummary}
      />
    </div>
  )
};

export default PersonnelListTable;