import HttpService from '../http-service';
import HTTPConfiguration from '../http-configuration';
import Validation from '../validation';

const token = localStorage.getItem('token');
const uri = 'Reservation/' + token + '/';
const validation = new Validation();
const config = new HTTPConfiguration();

export async function getToken() {
  const httpService = new HttpService();
  const result = await httpService.get(uri + 'GetToken');
  setCookie("precheckAccessToken", result, new Date(new Date().getTime() + 5 * 60000).toUTCString());
  return result;
};

 function setCookie(key, value, expiration) {
  document.cookie = `${key}=${value};domain=${process.env.REACT_APP_DOMAIN};expires=${expiration}`;
}