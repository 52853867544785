import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import AutomaticPayableRuleSetSection from '../automatic_payable/AutomaticPayableRuleSetSection';
import AutomaticPayableTabBar from '../automatic_payable/AutomaticPayableTabBar';
import PointToPointTemplateLineListTable from '../point_to_point/PointToPointTemplateLineListTable';
import PointToPointTemplateLineCreateDialog from '../point_to_point/PointToPointTemplateLineCreateDialog';
import PointToPointTemplateSection from '../point_to_point/PointToPointTemplateSection';
import { Update as PersonnelUpdate } from '../personnel/PersonnelService';
import { GetList as getTemplateLineList } from '../point_to_point/PointToPointTemplateLineService';
import { GetList as getOtherAutoPayableList } from '../automatic_payable/AutomaticPayableService';

import AutomaticPayableListTable from '../automatic_payable/AutomaticPayableListTable';
import AutomaticPayableCreateDialog from '../automatic_payable/AutomaticPayableCreateDialog';

const rightFloat = {
  float: 'right'
};
const centerAlign = { 
  display: 'flex',
  alignItems: 'center'
};

const PersonnelViewAutomaticPayableSection = (props) => { 
  const { 
    personnel,
    setPersonnel,
    isOpen,
    toggleIsOpen,
  } = props;

  const initialState = {
    AutoPayRuleSet: personnel.AutomaticPayableRule,
    PointToPointTemplateName : '',
    PointToPointTemplateID: personnel.PointToPointTemplateID,
  };

  const [inProgress, setInProgress] = useState(false);
  const [isTemplateLineCreateDialogOpen, setIsTemplateLineCreateDialogOpen] = useState(false);
  const [autoPayFormData, setAutoPayFormData] = useState(initialState);
  const [tabPosition, setTabPosition] = useState(personnel.AutomaticPayableRule && personnel.AutomaticPayableRule === 'Other' ? 1 : 0);
  const [autoPayables, setAutoPayables] = useState([]);

  const [isAutoPayCreateDialogOpen, setIsAutoPayCreateDialogOpen] = useState(false);
  const [otherAutoPayables, setOtherAutoPayables] = useState([]);

  useEffect(() => {
    if (isOpen && personnel.AutomaticPayableRule && personnel.AutomaticPayableRule === 'Point-to-Point') {
      async function getPointToPointTemplateLineList() {
        setInProgress(true);
        const result = await getTemplateLineList(autoPayFormData.PointToPointTemplateID);
        setAutoPayables(result);
        setInProgress(false);
      }
      getPointToPointTemplateLineList();
    }
    if (isOpen && personnel.AutomaticPayableRule && personnel.AutomaticPayableRule === 'Other') {
      async function getAutoPayablesOtherList() {
        setInProgress(true);
        const result = await getOtherAutoPayableList(personnel.ID);
        setOtherAutoPayables(result);
        setInProgress(false);
      }      
      getAutoPayablesOtherList();
    }
  }, [autoPayFormData.PointToPointTemplateID, isOpen, personnel.AutomaticPayableRule, personnel.ID]);
  
  const onTabChange = (e) => { 
    setTabPosition(e.target.value);
    if (e.target.value === 0) { 
      getAutoPayables();
    } else { 
      getAutoPayablesOther();
    }
  };

  const updateAutoPayFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    autoPayFormData[name] = value;
    autoPayFormData[nameData] = valueData;
    setAutoPayFormData({ ...autoPayFormData });
    updateTabsFromUpdateAutoPayFormData(e);
  };

  const updateTabsFromUpdateAutoPayFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    if (name === 'AutoPayRuleSet') {
      if (value === 'Point-to-Point') {
        setTabPosition(0);
      } else {
        setTabPosition(1);
      }
      personnel.AutomaticPayableRule = value;
      setPersonnel(personnel);     
      processUpdateRequest();
    } else if (nameData === 'PointToPointTemplateID') {
      personnel.PointToPointTemplateID = valueData;
      setPersonnel(personnel);
      processUpdateRequest();
    }    
  };

  const processUpdateRequest = async () => {
    setInProgress(true);
    var result = await PersonnelUpdate(personnel);
    setInProgress(false);
    if (result.error) {
      console.log(result.error);
      return;
    }
  };

  const openTemplateLineCreateDialog = () => {
    setIsTemplateLineCreateDialogOpen(true);
  };

  const closeTemplateLineCreateDialog = () => {
    getAutoPayables();
    setIsTemplateLineCreateDialogOpen(false);
  };

  const getAutoPayables = () => {
    getTemplateLineList(autoPayFormData.PointToPointTemplateID)
      .then(function (response) {
        setAutoPayables(response);
      });
  };

  const openAutomaticPayableCreateDialog = () => {
    setIsAutoPayCreateDialogOpen(true);
  };

  const closeAutomaticPayableCreateDialog = () => {
    getAutoPayablesOther();
    setIsAutoPayCreateDialogOpen(false);
  };

  const getAutoPayablesOther = () => { 
    getOtherAutoPayableList(personnel.ID)
      .then(function(response) { 
        setOtherAutoPayables(response);
      });
  };

  const updatePersonnelPointToPointTemplate = (p2pTemplate) => {      
    const dispatchEvent = {
      target: {
        name: 'PointToPointTemplateName',
        value: p2pTemplate.Name,
        nameData: 'PointToPointTemplateID',
        valueData: p2pTemplate.ID
      }
    };
    updateAutoPayFormData(dispatchEvent);
  };

  return ( 
    <Container maxWidth="lg" className="mt-4 mb-4" component={Paper}>
      {inProgress && <LinearProgress />}
      {isOpen &&
      <Grid container spacing={2} style={centerAlign}>
        <Grid item md={10} xs={10}>
          <h4>Automatic Expenses</h4>
        </Grid>
        <Grid item md={2} xs={2}>
          <span style={rightFloat}>
            <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Automatic Payable Items"></CoreExpandLessButton>
          </span>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {"Envase TMS allows expenses to be created on Orders automatically, based on rules that are defined below. You can select to have these expenses created based on Point-to-Point, Per Mile, or Revenue %. While you can create different types of Automatic Expense rule-sets, only one rule-set can be active at a time."}
            </Typography>
        </Grid>
        <Grid item md={3} xs={3}>
          <AutomaticPayableRuleSetSection
            autoPayFormData={autoPayFormData}
            updateAutoPayFormData={updateAutoPayFormData}
          />
        </Grid>                    
        <Grid item md={12} xs={12}>
          <AutomaticPayableTabBar
            tabPosition={tabPosition}
            onChange={e => onTabChange(e)}
          />
        </Grid>
        {tabPosition === 0 && <Grid item md={3} xs={3}>
          <PointToPointTemplateSection
            name={"PointToPointTemplateID"}
            value={autoPayFormData.PointToPointTemplateID}
            pointToPointTemplateName={autoPayFormData.PointToPointTemplateName}
            autoPayFormData={autoPayFormData}
            updateAutoPayFormData={updateAutoPayFormData}
          />
        </Grid>}   
        <Grid item md={12} xs={12}>
          {tabPosition === 0 && autoPayFormData.PointToPointTemplateID !== null && <PointToPointTemplateLineListTable
            templateLines={autoPayables}
            getTemplateLines={getAutoPayables}
            setTemplateLines={setAutoPayables}
            openTemplateLineCreateDialog={openTemplateLineCreateDialog}
            isPersonnelAutoPay={true}
            personnelID={personnel.PersonnelID}
            updatePersonnelPointToPointTemplate={updatePersonnelPointToPointTemplate}
          />}
          {tabPosition === 1 && <AutomaticPayableListTable
            automaticPayables={otherAutoPayables}
            getAutomaticPayables={getAutoPayablesOther}
            setAutomaticPayables={setOtherAutoPayables}
            openAutomaticPayableCreateDialog={openAutomaticPayableCreateDialog}
          />}
        </Grid>
      </Grid>   
      }
      {isTemplateLineCreateDialogOpen &&
      <PointToPointTemplateLineCreateDialog
        pointToPointTemplateID={autoPayFormData.PointToPointTemplateID}
        isOpen={isTemplateLineCreateDialogOpen}
        onClose={closeTemplateLineCreateDialog}
      />}
      {isAutoPayCreateDialogOpen &&
      <AutomaticPayableCreateDialog
        personnelID={personnel.ID}
        isOpen={isAutoPayCreateDialogOpen}
        onClose={closeAutomaticPayableCreateDialog}
      />
      }
      {!isOpen &&
      <Grid container spacing={2} style={centerAlign} className="mb-4">
        <Grid item md={10} xs={10}>
          <h4>Automatic Expenses</h4>
        </Grid>
        <Grid item md={2} xs={2}>
          <span style={rightFloat}>
            <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Expense Items"></CoreExpandMoreButton>
          </span>
        </Grid>
      </Grid>
      }
    </Container>
  )

};

export default PersonnelViewAutomaticPayableSection;