import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

import CorePhoneField from '../core/CorePhoneField';
import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';
import CountryField from '../country/CountryField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';

const CustomerEditContentBillAddress = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData,
    coordinates,
    branch
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={8}>
          <h4>Billing Address</h4>
        </Grid>
        <Grid item md={4} xs={4}>
          <Typography variant="subtitle2" gutterBottom style={{float:"right"}} className="mt-1">
            <CoreCheckboxField
              name="BillAddressSame"
              label="Same as Physical"
              value={customerFormData.BillAddressSame}
              onChange={e => updateCustomerFormData(e)}
            />
          </Typography>   
        </Grid>  
      </Grid>
      {!customerFormData.BillAddressSame &&
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            {(customerFormData.BillCountry !== "Philippines"  && branch.Country !== "Philippines") && <>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="BillAddress"
                  label="Address"
                  value={customerFormData.BillAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.BillAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="BillCity"
                  label="City"
                  value={customerFormData.BillCity}
                  validationError={customerFormValidation.BillCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="BillProvince"
                  label="State / Province"
                  value={customerFormData.BillProvince}
                  validationError={customerFormValidation.BillProvince}
                  onChange={e => updateCustomerFormData(e)}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="BillPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.BillPostalCode}
                  validationError={customerFormValidation.BillPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CountryField
                  name="BillCountry"
                  label="Country"              
                  value={customerFormData.BillCountry}
                  validationError={customerFormValidation.BillCountry}
                  onChange={e => updateCustomerFormData(e)}
                />
              </Grid>
            </>}
            {(customerFormData.BillCountry === "Philippines" ||   branch.Country === "Philippines") && <>
              <Grid item md={6} xs={6}>
                <CountryField
                  name="BillCountry"
                  label="Country"              
                  value={customerFormData.BillCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.BillCountry}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="BillProvince"
                  label="State / Province"
                  value={customerFormData.BillProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.BillProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="BillCity"
                  label="City"
                  value={customerFormData.BillCity}
                  validationError={customerFormValidation.BillCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="BillPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.BillPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.BillPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="BillDistrict"
                  label="District"
                  value={customerFormData.BillDistrict}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="BillCounty"
                  label="Barangay"
                  value={customerFormData.BillCounty}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="BillAddress"
                  label="Address"
                  value={customerFormData.BillAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.BillAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
            </Grid>
            </>}
            <Grid item md={6} xs={6}>
              <CorePhoneField
                name="BillPhone"
                label="Primary Number"              
                value={customerFormData.BillPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.BillPhone}
              />
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CorePhoneField
                name="BillTollFree"
                label="Secondary Number"              
                value={customerFormData.BillTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.BillTollFree}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }     
        </Grid>
      </Grid>}
    </Container>  
  );
}

export default CustomerEditContentBillAddress;