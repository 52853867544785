import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { format } from 'date-fns';

import { getIntermodalInvoiceList, exportList } from '../order/OrderService';
import { select, deselect, selectBatch, deselectBatch, getBatchInvoiceStatus } from './IntermodalInvoiceService';
import LinearProgress from '@material-ui/core/LinearProgress';
import IntermodalInvoiceListStatus from './IntermodalInvoiceListStatus';
import IntermodalInvoiceListFilterRow from './IntermodalInvoiceListFilterRow';
import Validation from '../validation';
import CoreToggleField from '../core/CoreToggleField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CoreDataGridList from '../core/CoreDataGridList';
import { downloadFile } from '../globalFunction';
import IntermodalInvoiceBatchGroupStatus from './IntermodalInvoiceBatchGroupStatus';

const IntermodalInvoiceListTable = () => {

  const validation = new Validation();
  const [orders, setOrders] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [listWidth, setListWidth] = useState('xl');
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("invoiceListAmount")) || 10,
    count: parseInt(localStorage.getItem("invoiceListAmount")) || 10,
    isASC: true,
    sortBy: 'OrderNum',
    filters: [],
    ShowAllInvoicing: true, // All
    ShowCompleted: false, // Pending
    ShowBillingOnHold: false, // Billing On Hold
    ShowApprovedForBilling: false, // Approved For Billing
  });

  const [filterData, setFilterData] = useState({
    Search: '',
    Company: '',
    CustomerID: null,
    BillDate: null,
    ReferenceNumber: '',
    BookingNum: ''
  })

  let history = useHistory();

  useEffect(() => {
    buildURLSearchFilters();
    getOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function buildURLSearchFilters() {
    const urlParams = new URLSearchParams(window.location.search);
    const listStatus = urlParams.get('listStatus');
    const referenceNumber = urlParams.get('referenceNumber');
    const bookingNum = urlParams.get('bookingNum');
    const cust = urlParams.get('customer');
    const custID = parseInt(urlParams.get('ID'));
    params.ShowAllInvoicing = true;
    params.ShowCompleted = false;
    params.ShowBillingOnHold = false;
    params.ShowApprovedForBilling = false;
    setFilterData(c => ({ ...c, Company: cust }));    
    setFilterData(c => ({ ...c, CustomerID: custID }));    
    pushParamFilter('Customer', cust, 'Contains');
    if (listStatus) {
      updateStatusButtonChange({target:{name: listStatus}});
    }
    if (referenceNumber) {
      setFilterData(c => ({ ...c, ReferenceNumber: referenceNumber }));    
      pushParamFilter('ReferenceNumber', referenceNumber, 'Equals');
      setParams(params);
    }
    if (bookingNum) {
      setFilterData(c => ({ ...c, BookingNum: bookingNum }));    
      pushParamFilter('BookingNum', bookingNum, 'Equals');
      setParams(params);
    }
  };

  const getOrderList = async () => {
    setInProgress(true);
    const result = await getIntermodalInvoiceList(params);
    if (result.length > 0) { 
      setParams(c => ({ ...c, count: result[0].TotalRows }));
    } else { 
      setParams(c => ({ ...c, count: 0 }));
    }
    setOrders(result);
    setInProgress(false);
  };

  const buildParamFilter = () => {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }
    if (filterData.BillDate) {
      pushParamFilter("BillDate", filterData.BillDate, 'Less Than');
    }
    if (filterData.Company) {
      pushParamFilter("Customer", filterData.Company, 'Contains');
    }
    if (!validation.isEmpty(filterData.ReferenceNumber)) {
      pushParamFilter("ReferenceNumber", filterData.ReferenceNumber, 'Contains');
    }
    if (!validation.isEmpty(filterData.BookingNum)) {
      pushParamFilter("BookingNum", filterData.BookingNum, 'Contains');
    }
    params.page = 1;
    setParams(params);
    getOrderList();
  };

  const pushParamFilter = (filterName, value, operator) => {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  };

  const updateFilterData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    filterData[name] = value;
    filterData[nameData] = valueData;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const resetFilterRow = () => {
    params.filters = [];
    params.page = 1;
    setParams(params);
    setFilterData(c => ({ ...c, BillDate: null }));
    setFilterData(c => ({ ...c, Company: '' }));
    setFilterData(c => ({ ...c, CustomerID: null }));
    setFilterData(c => ({ ...c, ReferenceNumber: '' }));
    setFilterData(c => ({ ...c, BookingNum: '' }));
    getOrderList();
  };

  const updateStatusButtonChange = e => {
    const { name } = e.target;
    switch (name) {
      // All
      case 'ShowAllInvoicing':
        params.ShowAllInvoicing = true;
        params.ShowCompleted = false;
        params.ShowBillingOnHold = false;
        params.ShowApprovedForBilling = false;
        break;
      // Pending
      case 'ShowCompleted':
        params.ShowAllInvoicing = false;
        params.ShowCompleted = true;
        params.ShowBillingOnHold = false;
        params.ShowApprovedForBilling = false;
        break;
      // Billing On Hold
      case 'ShowBillingOnHold':
        params.ShowAllInvoicing = false;
        params.ShowBillingOnHold = true;
        params.ShowCompleted = false;
        params.ShowApprovedForBilling = false;
        break;
      // Approved for Billing
      case 'ShowApprovedForBilling':
        params.ShowAllInvoicing = false;
        params.ShowCompleted = false;
        params.ShowBillingOnHold = false;
        params.ShowApprovedForBilling = true;
        break;
      default:
        break;
    }
    params.page = 1;
    setParams(params);
    getOrderList();
  };

  const updateSelectedToggle = async e => {
    const { name, value } = e.target;
    const order = orders.find(o => o.ID === name);
    order.IsSelectedForInvoicing = value;
    if ((order.BatchInvoiceMethod === 'Reference Number' && !validation.isEmpty(order.ReferenceNumber)) || 
      (order.BatchInvoiceMethod === 'Master BL/Booking Number' && !validation.isEmpty(order.BookingNum))) {
      order.ResetStatus = true;
    }
    setOrders([...orders]);
    if (value) {
      await select(name);
    } else {
      order.IsSelectedForBatchInvoicing = false;
      setOrders([...orders]);
      await deselect(name);
    }
    updateBatchGroupStatuses(order);
  };

  const updateSelectedBatchInvoicing = async e => {
    const { name, value } = e.target;
    const order = orders.find(o => o.ID === name);
    order.IsSelectedForBatchInvoicing = value;
    if ((order.BatchInvoiceMethod === 'Reference Number' && !validation.isEmpty(order.ReferenceNumber)) || 
      (order.BatchInvoiceMethod === 'Master BL/Booking Number' && !validation.isEmpty(order.BookingNum))) {
      order.ResetStatus = true;
    }
    setOrders([...orders]);
    if (value) {
      order.IsSelectedForInvoicing = true;
      setOrders([...orders]);
      await selectBatch(name);
    } else {
      await deselectBatch(name);
    }
    updateBatchGroupStatuses(order);
  };

  const updateBatchGroupStatuses = async (invoice) => {
    const batchInvoiceMethod = invoice.BatchInvoiceMethod;
    if (batchInvoiceMethod === 'Reference Number') {
      updateBatchGroupReferenceNumberStatuses(invoice.ReferenceNumber);
    }
    if (batchInvoiceMethod === 'Master BL/Booking Number') {
      updateBatchGroupMasterBLStatuses(invoice.BookingNum);
    }
  };

  const updateBatchGroupReferenceNumberStatuses = async (referenceNumber) => {
    for(const invoice of orders) {
      if (
        invoice.ReferenceNumber && 
        invoice.ReferenceNumber === referenceNumber
      ) {
        updateBatchGroupStatus(invoice);
      }
    }
  };

  const updateBatchGroupMasterBLStatuses = async (masterBL) => {
    for (const invoice of orders) {
      if (
        invoice.BookingNum && 
        invoice.BookingNum === masterBL
      ) {
        updateBatchGroupStatus(invoice);
      }
    }
  };

  const updateBatchGroupStatus = async(invoice) => {
    invoice.ResetStatus = true;
    setOrders([...orders]);
    const status = await getBatchInvoiceStatus(invoice.ID);
    invoice.BatchInvoiceStatus = status;
    invoice.ResetStatus = false;
    setOrders([...orders]);
  };

  let columns = [
    {
      field: 'IsSelectedForInvoicing',
      headerName: ' ',
      align: 'left',
      flex: 0.3,
      sortable: false,
      hide: !params.ShowApprovedForBilling,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const intermodalInvoiceID = params.getValue("ID");
        const isSelectedForInvoicing = params.getValue("IsSelectedForInvoicing") || false;
        return (
          <div>
            <CoreCheckboxField
              name={intermodalInvoiceID}
              value={isSelectedForInvoicing}
              onChange={updateSelectedToggle} />
          </div>
        )
      }
    },
    {
      field: 'IsSelectedForBatchInvoicing',
      headerName: 'Batch',
      align: 'left',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      hide: !params.ShowApprovedForBilling,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const intermodalInvoiceID = params.getValue("ID");
        const IsSelectedForBatchInvoicing = params.getValue("IsSelectedForBatchInvoicing") || false;
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>
              <CoreToggleField
                label={null}
                value={IsSelectedForBatchInvoicing}
                name={intermodalInvoiceID}
                onChange={updateSelectedBatchInvoicing}
              />
            </span>
          </div>
        )
      }
    },
    {
      field: 'BatchInvoiceStatus',
      headerName: ' ',
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const invoice = params.row;
        return (
          <IntermodalInvoiceBatchGroupStatus
            invoice={invoice}
            inProgress={inProgress}
          />
        )
      }
    },
    {
      field: 'OrderNum',
      headerName: 'Order #',
      align: 'left',
      flex: 1
    },
    {
      field: 'BatchInvoiceGroupCount',
      headerName: 'Batch Group Remaining',
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      colspan: 2,
      renderCell: (params) => {
        const invoice = params.row;
        return (
          <span>
            {invoice.BatchInvoiceGroupCount > 0 && invoice.BatchInvoiceGroupCount}
            {invoice.BatchInvoiceGroupCount === 0 && '-'}
          </span>
        )
      }
    },
    {
      field: "Status",
      headerName: "Load Status",
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        const name = params.getValue("Status") || "Unassigned";
        return (
          <div>
              {name}
          </div>
        )
      }
    },
    {
      field: 'Customer',
      headerName: 'Customer Bill To',
      align: 'left',
      flex: 2
    },
    {
      field: 'ReferenceNumber',
      headerName: 'REF #',
      align: 'left',
      flex: 1
    },
    {
      field: 'ContainerNumber',
      headerName: 'Container #',
      align: 'left',
      flex: 1
    },
    {
      field: 'BookingNum',
      headerName: 'MBL # / BK #',
      align: 'left',
      flex: 1
    },
    {
      field: 'LastConfirmedDate',
      headerName: 'Completed Date',
      aligh: 'left',
      flex: 1.1,
      renderCell: (params) => {
        const name = params.getValue("LastConfirmedDate") ? format(new Date(params.getValue("LastConfirmedDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'TotalCharges',
      headerName: 'Total Charges',
      headerAlign: 'right',
      align: 'right',
      flex: 1
    },
    {
      field: 'Profit',
      headerName: 'Profit',
      headerAlign: 'right',
      align: 'right',
      flex: 1
    },
    {
      field: 'Margin',
      headerName: 'Profit Margin',
      headerAlign: 'right',
      align: 'right',
      flex: 1
    }
  ];

  const onInvoiceRowClick = (row) => {
    const uri = '/spark/intermodal-invoice/view/' + row.id;
    // const parameters = {
    //   showCompleted: params.ShowCompleted,
    //   showBillingOnHold: params.ShowBillingOnHold,
    //   showApprovedForBilling: params.ShowApprovedForBilling,
    //   filters: params.filters
    // };
    history.push(uri, params);
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getOrderList();
    setParams(params);
  };

  function changePage(page) {
    params.page = page + 1;
    getOrderList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    getOrderList();
    setParams(params);
    localStorage.setItem("invoiceListAmount", amount);
  };

  const exportInvoiceList = async () => {
    const result = await exportList(params);
    downloadFile(result, 'invoice-list.csv');
  };

  return (
    <div style={{position:'relative'}}>
      <Container maxWidth={listWidth} className="mt-1">
        <IntermodalInvoiceListStatus
          intermodalInvoiceStatuses={params}
          updateStatusButtonChange={updateStatusButtonChange}
        />
      </Container>
      <IntermodalInvoiceListFilterRow
        filterData={filterData}
        updateFilterData={updateFilterData}
        resetFilterRow={resetFilterRow}
        showTransmitButton={params.ShowApprovedForBilling}
        listWidth={listWidth}
        customerName={filterData.Company}
      />
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={orders}
          params={params}
          exportList={exportInvoiceList}
          onRowClick={onInvoiceRowClick}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='OrderNum'
          filterData={filterData}
          updateFilterData={updateFilterData}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
    </div>
  );

}

export default IntermodalInvoiceListTable;