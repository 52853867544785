import React from 'react';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { CurrencyDisplay } from '@envasetechnologies/feepay-components';

const FeePayShoppingCartSubtotal = (props) => { 
  const {
    total
  } = props;

  return ( 
    <div>
      <Container maxWidth="xl" className="m1-1 munaGrid" component={Paper}>
        <Grid container item xs spacing={2} className="mb-1">
          <Grid item xs={6} style={{textAlign: 'left'}}>
            <h3>Subtotal</h3>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <h3><CurrencyDisplay amount={total} currency={"PHP"} /></h3>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FeePayShoppingCartSubtotal;