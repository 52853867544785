import MuiPhoneNumber from 'material-ui-phone-number';
import { useLocation } from 'react-router';

const CoreIntlPhoneOutput = (props) => {
  let {value, fontStyle=''} = props
  const {pathname} = useLocation();
  value = value && typeof(value) === 'string' ? value.trim() : value;

  if (value) {
    return (
      <div className={`faux-plain-text ${pathname.includes("view") ? 'view' : ''}`} style={{fontStyle}}>
        <MuiPhoneNumber disabled value={value} defaultCountry={"us"} />
      </div>
    )
  } else {
    return null;
  }
}

export default CoreIntlPhoneOutput;
