import React from 'react';
import Container from '@material-ui/core/Container';
import OrderViewSummaryInfoSection from './OrderViewSummaryInfoSection';
import OrderViewSummaryLocationSection from './OrderViewSummaryLocationSection';
import OrderViewSummaryEquipmentSection from './OrderViewSummaryEquipmentSection';
import OrderViewSummaryDocumentsSection from './OrderViewSummaryDocumentsSection';

const OrderViewSummaryTabBarDetail = (props) => {
  const {
    order,
    equipment,
    documents,
  } = props;

  return (
    <Container maxWidth="lg">
      <OrderViewSummaryInfoSection
        order={order}
      />
      <OrderViewSummaryLocationSection
        id={order.OriginID}
        label="ORIGIN ADDRESS"
      />
      <OrderViewSummaryLocationSection
        id={order.DestinationID}
        label="DESTINATION ADDRESS"
      />
      <OrderViewSummaryEquipmentSection
        equipment={equipment}
      />
      <OrderViewSummaryDocumentsSection
        documents={documents}
      />
    </Container>
  );

}

export default OrderViewSummaryTabBarDetail;