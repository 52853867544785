import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import PersonnelAccountRepField from '../personnel/PersonnelAccountRepField';
import CoreTextField from '../core/CoreTextField';
import CustomerBatchInvoiceMethodField from './CustomerBatchInvoiceMethodField';
import CoreNumberField from '../core/CoreNumberField';

const CustomerCreateContentInfo = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>General Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="internalName"
            label="Internal Name"
            value={customerFormData.internalName}
            onChange={e => updateCustomerFormData(e)}
            isRequired={true}
            validationError={customerFormValidation.internalName}
            autoFocus={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="legalName"
            label="Legal Name"
            value={customerFormData.legalName}
            onChange={e => updateCustomerFormData(e)}
            isRequired={true}
            validationError={customerFormValidation.legalName}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PersonnelAccountRepField
            name="accountRepName"
            label="Account Rep"
            value={customerFormData.accountRepName}
            nameData="accountRepID"
            valueData={customerFormData.accountRepID}
            onChange={e => updateCustomerFormData(e)}
            isRequired={true}
            validationError={customerFormValidation.accountRepID}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <CoreTextField
            name="email"
            label="Email"
            value={customerFormData.email}
            validationError={customerFormValidation.email}
            onChange={e => updateCustomerFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <CustomerBatchInvoiceMethodField
            name="batchInvoiceMethod"
            label="Batch Invoice Method"
            value={customerFormData.batchInvoiceMethod}
            isRequired={true}
            onChange={e => updateCustomerFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12} className="mb-2">
          <CoreNumberField
            name="creditTerms"
            label="Credit Terms"
            minValue={0}
            value={customerFormData.creditTerms}
            onChange={e => updateCustomerFormData(e)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default CustomerCreateContentInfo;