import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'IntermodalItinerary/' + token + '/';

export async function getDrivers() {
  const httpService = new HttpService();
  const result = await httpService
    .get('IntermodalEvent/' + token + '/GetAvailableDrivers');
  return result;
};

export async function getList(startDate, personnelID) {
  startDate = new Date(startDate);
  let start_date = startDate.getDate();
  let start_month = startDate.getMonth() + 1;
  let start_year = startDate.getFullYear();

  let params = {
    startDate: (`${start_month}-${start_date}-${start_year}`),
    personnelID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList', params)
  return result;
};


export async function getLegList(startDate, endDate, personnelID) {
  startDate = new Date(startDate);
  let start_date = startDate.getDate();
  let start_month = startDate.getMonth() + 1;
  let start_year = startDate.getFullYear();

  endDate = new Date(endDate);
  let end_date = endDate.getDate();
  let end_month = endDate.getMonth() + 1;
  let end_year = endDate.getFullYear();

  let params = {
    startDate: (`${start_month}-${start_date}-${start_year}`),
    endDate: (`${end_month}-${end_date}-${end_year}`),
    personnelID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLegList', params)
  return result;
};

export async function getEventList(intermodalLegID) {
  if (!intermodalLegID) {
    return;
  }
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEventList', {intermodalLegID});
  return result;
};

export async function moveEventIndex(legID, eventID, newEventIndex) {
  var params = {
    legID: legID,
    eventID: eventID,
    newEventIndex: newEventIndex,
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveEventIndex', null, params);
  return result;
};

export async function moveLegIndex(legID, driverID, newDriverItineraryIndex) {
  var params = {
    legID: legID,
    driverID: driverID,
    newDriverItineraryIndex: newDriverItineraryIndex
  }
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveLegIndex', null, params);
  return result;
};

export async function getUnAssignedLegs() {
  const httpService = new HttpService();
  const result = httpService
    .get(uri + 'GetUnassignedLegs');
  return result;
};

export async function assignLegToDriver(legID, driverID) {
  const params = {
    legID,
    driverID
  };
  const httpService = new HttpService();
  const result = httpService
    .post(uri + 'AssignLeg', null, params);
  return result;
};
