import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CoreIntlPhoneOutput from './CoreIntlPhoneOutput';

const CoreTextDisplayField = (props) => {
  const { 
    label, 
    value, 
    icon, 
    isEmail=false, 
    isPhoneNum=false, 
    labelStyle={ color: 'gray'},
    iconSpacing=false
  } = props;
  
  const formControlStyle = {flexFlow: 'nowrap', alignItems: 'center'};
  const buildStartAdornment = () => {
    if (icon) {
      return (
        <Grid item xs={2} style={{ maxWidth: '45px', minWidth: '45px' }}>
          <IconButton style={{paddingTop: '0px', pointerEvents: 'none'}}>
            {icon}
          </IconButton>
        </Grid>);
    }
    if (iconSpacing) { 
      return ( 
        <Grid item xs={2} style={{ maxWidth: '10px', minWidth: '10px' }}>
        </Grid>
      );
    }
    return <></>;
  };
  return (
    <div style={{paddingBottom: 'inherit'}}>
      <Grid container>
        {buildStartAdornment()}
        <Grid item xs={9}>
          <FormControl fullWidth style={ formControlStyle }>
            <label style={ labelStyle }>
              {label}
            </label>
          </FormControl>
          <span style={{overflowWrap: "break-word"}}>
            {
              value && isPhoneNum ? <CoreIntlPhoneOutput value={value}/> :
              value && isEmail ? (<Link href={`mailto:${value}`}>{value}</Link>) :
              value
            } &nbsp;
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

CoreTextDisplayField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.element,
  isPhoneNum: PropTypes.bool,
  isEmail: PropTypes.bool
};

export default CoreTextDisplayField;
