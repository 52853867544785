import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { disableEnvaseConnect } from './PersonnelService';

const PersonnelDisableEnvaseMobile = (props) => {
  const {
    isOpen,
    onClose,
    driverName,
    personnelID
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onConfirm = async () => {
    setIsLoading(true);
    const result = await disableEnvaseConnect(personnelID);
    setIsLoading(false);
    if (result.error) {
      setErrorMessage(result.error);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setErrorMessage(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onConfirm}>
        <DialogTitle id="form-dialog-title" style={{ textTransform: 'capitalize' }}>
          Disable Envase Mobile Driver
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to disable mobile access for {driverName}?
          </DialogContentText>
          {errorMessage &&
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" disabled={isLoading}>
            Disable Access {isLoading && <CircularProgress size={16} style={{ marginLeft: '5px' }} />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

PersonnelDisableEnvaseMobile.propTypes = {
  driverName: PropTypes.string.isRequired,
  personnelID: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default PersonnelDisableEnvaseMobile;
