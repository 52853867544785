import React, { useState, useEffect } from 'react';

import { getAvailableAreaTags, getList, applyAreaTags, removeAreaTags } from './LocationAreaTagService';
import LocationAreaTagCreateDialogContent from '../location_area_tag/LocationAreaTagCreateDialogContent';

const LocationAreaTagCreateDialog = (props) => {
  const {
    id,
    isOpen,
    onClose,
    getAreaTags,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(true);
  const [availableAreaTags, setAvailableAreaTags] = useState([]);
  const [appliedAreaTags, setAppliedAreaTags] = useState([]);
  const [leftCheckedItems, setLeftCheckedItems] = useState([]);
  const [rightCheckedItems, setRightCheckedItems] = useState([]);

  useEffect(() => {
    getAvailableAreaTags(id)
      .then(function(response) { 
        setAvailableAreaTags(response);
        setInProgress(false);
      });
    getList(id)
      .then(function(response) { 
        setAppliedAreaTags(response);
        setInProgress(false);
      });
  }, [id]);

  const onCreate = (isLeftSide) => {
    setInProgress(true);
    processCreateRequest(isLeftSide);
  };

  const processCreateRequest = async (isLeftSide) => {
    setErrorMessage('');
    const result = isLeftSide ? await applyAreaTags(id, leftCheckedItems) : await removeAreaTags(id, rightCheckedItems);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error[0]);
      return;
    }
    getLists();
  };

  const getLists = () => {
    setLeftCheckedItems([]);
    setRightCheckedItems([]);
    getAvailableAreaTags(id)
      .then(function(response) { 
        setAvailableAreaTags(response);
        setInProgress(false);
      });
    getList(id)
      .then(function(response) { 
        setAppliedAreaTags(response);
        setInProgress(false);
      });
    getAreaTags();
  };

  const closeDialog = () => {
    onClose();
  };

  return (
    isOpen &&
    <LocationAreaTagCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      inProgress={inProgress}
      availableAreaTags={availableAreaTags}
      appliedAreaTags={appliedAreaTags}
      leftCheckedItems={leftCheckedItems}
      setLeftCheckedItems={setLeftCheckedItems}
      rightCheckedItems={rightCheckedItems}
      setRightCheckedItems={setRightCheckedItems}
    />
  );
}

export default LocationAreaTagCreateDialog;