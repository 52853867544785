import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';

import NavigationDrawerListItemCustomer from './NavigationDrawerListItemCustomer';
import NavigationDrawerListItemDashboard from './NavigationDrawerListItemDashboard';
import NavigationDrawerListItemDispatch from './NavigationDrawerListItemDispatch';
import NavigationDrawerListItemEquipment from './NavigationDrawerListItemEquipment';
import NavigationDrawerListItemInvoice from './NavigationDrawerListItemInvoice';
import NavigationDrawerListItemLocation from './NavigationDrawerListItemLocation';
import NavigationDrawerListItemOrder from './NavigationDrawerListItemOrder';
import NavigationDrawerListItemPersonnel from './NavigationDrawerListItemPersonnel';
import NavigationDrawerListItemSettlement from './NavigationDrawerListItemSettlement';
import NavigationDrawerListItemQuickBooks from './NavigationDrawerListItemQuickBooks';
// import NavigationDrawerListItemSetting from './NavigationDrawerListItemSetting';
import CoreNavigrationDrawerListItemConfiguration from './NavigationDrawerListItemConfiguration';
import NavigationDrawerListItemItinerary from './NavigationDrawerListItemItinerary';
import NavigationDrawerListItemReports from './NavigationDrawerListItemReports';
import NavigationDrawerListItemIncomingLoads from './NavigationDrawerListItemIncomingLoads';
import NavigationDrawerListItemTruckVisits from './NavigationDrawerListItemTruckVisits';
import NavigationNestedDrawer from './NavigationNestedDrawer';
import IconRecords from '../icon/IconRecords';
import IconAccountBalance from '../icon/IconAccountBalance';
import NavigationDrawerListItemMyAppointments from './NavigationDrawerListItemMyAppointments';
import NavigationDrawerListItemTransactionHistory from './NavigationDrawerListItemTransactionHistory';

const useStyles = makeStyles({
  list: {
    width: 250,
  }
});

const NavigationDrawerList = (props) => {
  const classes = useStyles();
  const [companyFeature, setCompanyFeature] = useState([]);

useEffect(() => {
  async function getCompanyFeatureData() { 
    const result = await getCompanyFeature();
    setCompanyFeature(result);
  }
  getCompanyFeatureData();
}, []);

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={props.handleDrawerClose}
      onKeyDown={props.handleDrawerClose}
      onMouseEnter={props.handleDrawerOpen} 
      onMouseLeave={props.handleDrawerClose}
    >
      <List>
        <NavigationDrawerListItemDashboard />
        {
          companyFeature && companyFeature.IsICTSI && 
          <NavigationDrawerListItemIncomingLoads />
        }
        <NavigationDrawerListItemOrder />
        { 
          companyFeature && companyFeature.IsICTSI && 
          <NavigationDrawerListItemMyAppointments />
        }
        {
          companyFeature && companyFeature.IsICTSI && 
          <NavigationDrawerListItemTruckVisits />
        }
        <NavigationDrawerListItemDispatch />
        <NavigationDrawerListItemItinerary />
        <NavigationDrawerListItemReports />

        <NavigationNestedDrawer title='Records' icon={<IconRecords />}>
          <NavigationDrawerListItemPersonnel />
          <NavigationDrawerListItemCustomer />
          <NavigationDrawerListItemLocation />
          <NavigationDrawerListItemEquipment />
        </NavigationNestedDrawer>

        <NavigationNestedDrawer title='Accounting' icon={<IconAccountBalance />}>
          <NavigationDrawerListItemInvoice />
          <NavigationDrawerListItemSettlement />
          <NavigationDrawerListItemQuickBooks />
          {companyFeature && companyFeature.IsICTSI && <NavigationDrawerListItemTransactionHistory />}
        </NavigationNestedDrawer>
      </List>
      {/* <Divider /> */}
      {/* <List> */}
        {/* <NavigationDrawerListItemSetting /> */}
        <CoreNavigrationDrawerListItemConfiguration />
      {/* </List> */}
    </div>
  );

}

export default NavigationDrawerList;