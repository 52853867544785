import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

const OrderEnvaseTraceEventListTable = (props) => { 
  const { 
    traceEvents
  } = props;

  let columns = [
    {
      field: 'EventType',
      headerName: 'Type',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },
    {
      field: 'Date',
      headerName: 'Date',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const date = params.getValue("Date");
        const time = params.getValue("DateTime");
        return (
          <div>{date} {time}</div>
        )
      }
    },
    {
      field: 'Location',
      headerName: 'Location',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },
    {
      field: 'Message',
      headerName: 'Message',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },    
    {
      field: 'EventDate',
      headerName: 'Tracing Date',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const eventDate = params.getValue("EventDate");
        const eventDateTime = params.getValue("EventDateTime");
        return (
          <div>{eventDate} {eventDateTime}</div>
        )
      }
    }
  ];

  return ( 
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1 munaGrid" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={traceEvents} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        components={{        
          NoRowsOverlay: () => (
            <div className="mt-4" style={{'text-align': 'center'}}><br/><br/>
              Envase Trace Provider detected. <br/>
              Please ensure sufficient Order details are provided and Trace info will be provided shortly. <br/>
              Click Envase Trace tab to refresh.
            </div>
          )
        }}      
      />
  </Container>
  )
}

OrderEnvaseTraceEventListTable.propTypes = { 
  traceEvents: PropTypes.array.isRequired
};

export default OrderEnvaseTraceEventListTable;