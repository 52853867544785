import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './DocumentTypeService';
import DocumentTypeCreateDialogContent from './DocumentTypeCreateDialogContent';

const DocumentTypeCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    description: '',
    relatedTo: 'Customer',
    displayInApp: false,
    includeInInvoice: false,
  };
  
  const [documentTypeFormData, setDocumentTypeFormData] = useState(initialState);
  const [documentTypeFormValidation, setDocumentTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDocumentTypeFormValidation(buildValidation(documentTypeFormData));
    }
  }, [documentTypeFormData, isOpen]);

  const updateDocumentTypeFormData = e => {
    const { name, value } = e.target;
    documentTypeFormData[name] = value;
    setDocumentTypeFormData({ ...documentTypeFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(documentTypeFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(documentTypeFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setDocumentTypeFormData({initialState});
    onClose();
  };

  return (
    isOpen &&
    <DocumentTypeCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      documentTypeFormData={documentTypeFormData}
      documentTypeFormValidation={documentTypeFormValidation}
      updateDocumentTypeFormData={updateDocumentTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (documentTypeFormData) => {
  const {
    description,
    relatedTo,
  } = documentTypeFormData || {};
  const validation = new Validation();
  return {
    description: validation.isEmpty(description),
    relatedTo: validation.isEmpty(relatedTo)
  };
};

export default DocumentTypeCreateDialog;