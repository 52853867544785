import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import OrderCreateContentLocation from './OrderCreateContentLocation';

const useStyles = makeStyles(() => ({
  line: { 
    opacity: '0.3'
  }
}));

const OrderCreateContentLocations = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    updateOrderFormData,
    orderFormValidation,
    initialProviderID
  } = props;

  return (
    <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
      <Grid container md={12} xs={12}>
        <Grid container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <OrderCreateContentLocation
              headerLabel="Origin Location"
              name="originID"
              locationName={orderFormData.originLocationName}
              value={orderFormData.originID}
              validationError={orderFormValidation.originID}
              updateOrderFormData={updateOrderFormData}
              orderType={orderFormData.type}
              isOriginLocation={true}
              initialProviderID={initialProviderID}
            />
          </Grid>
        </Grid>
        <hr className={classes.line}></hr>
        <Grid container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <OrderCreateContentLocation
              headerLabel="Destination Location"
              name="destinationID"
              locationName={orderFormData.destinationLocationName}
              value={orderFormData.destinationID}
              validationError={orderFormValidation.destinationID}
              updateOrderFormData={updateOrderFormData}
              orderType={orderFormData.type}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

}

export default OrderCreateContentLocations;