import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const IncomingLoadListRejectDialogContent = (props) => {
  const {
    onReject,
    isOpen,
    onClose,
    reference
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onReject}>
        <DialogTitle id="form-dialog-title">Confirm Rejecting Load</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject Load {reference}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default IncomingLoadListRejectDialogContent;