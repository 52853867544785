import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import PointToPointTemplateLineEditDialog from './PointToPointTemplateLineEditDialog';
import PointToPointTemplateLineEditCheckDialog from './PointToPointTemplateLineEditCheckDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const PointToPointTemplateLineColumnEditButton = (props) => { 
  const { 
    pointToPointTemplateLineID,
    getPointToPointTemplateLines,
    isPersonnelAutoPay,
    personnelID,
    updatePersonnelPointToPointTemplate,
  } = props;
  const classes = useStyles();
  const [isPointToPointTemplateLineEditModalOpen, setIsPointToPointTemplateLineEditModalOpen] = useState(false);
  const [isPersonnelAutoPayCheckModalOpen, setIsPersonnelAutoPayCheckModalOpen] = useState(false);
  const [isCreateNewTemplate, setIsCreateNewTemplate] = useState(false);

  const openPointToPointTemplateLineEditModal = () => { 
    if (isPersonnelAutoPay) {
      setIsPersonnelAutoPayCheckModalOpen(true);
    } else {
      setIsPointToPointTemplateLineEditModalOpen(true);
    }
  };

  const openTemplateLineEditModal = () => { 
    setIsPersonnelAutoPayCheckModalOpen(false);
    setIsPointToPointTemplateLineEditModalOpen(true);
  };

  const createNewTemplate = () => { 
    setIsCreateNewTemplate(true);
    setIsPersonnelAutoPayCheckModalOpen(false);
    setIsPointToPointTemplateLineEditModalOpen(true);
  };

  const closePointToPointTemplateLineEditModal = (p2pTemplate) => {  
    if (p2pTemplate && updatePersonnelPointToPointTemplate) {
      updatePersonnelPointToPointTemplate(p2pTemplate);
    }
    setIsPointToPointTemplateLineEditModalOpen(false);
    getPointToPointTemplateLines();
  };

  const closePointToPointTemplateLineEditCheckModal = () => { 
    setIsPersonnelAutoPayCheckModalOpen(false);
    getPointToPointTemplateLines();
  };

  return ( 
    <>
      <IconButton className={classes.iconText} onClick={openPointToPointTemplateLineEditModal}>
        <EditIcon/>&nbsp;
        <span>EDIT</span>
      </IconButton> 
      <PointToPointTemplateLineEditDialog
        pointToPointTemplateLineID={pointToPointTemplateLineID}
        isOpen={isPointToPointTemplateLineEditModalOpen}
        onClose={closePointToPointTemplateLineEditModal}
        isCreateNewTemplate={isCreateNewTemplate}
        personnelID={personnelID}
      />
      <PointToPointTemplateLineEditCheckDialog
        isOpen={isPersonnelAutoPayCheckModalOpen}
        openEditDialog={openTemplateLineEditModal}
        createNewTemplate={createNewTemplate}
        onClose={closePointToPointTemplateLineEditCheckModal}
      />
    </>
  );
};

export default PointToPointTemplateLineColumnEditButton;