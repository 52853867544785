import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
    tabWidth: {
      width: '640px',
    }
  }));

const AutomaticPayableTabBar = (props) => { 
  const { 
    tabPosition,
    onChange
  } = props;
  const classes = useStyles();

  const onTabEvent = (newValue) => { 
    const tempEvent = { 
      target: { 
        value: newValue
      }
    };
    onChange(tempEvent);
  };

  return ( 
    <Container style={{ padding: '0' }} maxWidth="lg" className="mt-2">
      <Grid container spacing={2} className="mt-2">
        <Grid container item xs={6}>
          <Tabs
            value={tabPosition}
            onChange={ (event, newValue) => { onTabEvent(newValue) }}
            indicatorColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab value={0} label='Point-to-Point' />
            <Tab value={1} label='Other' />
          </Tabs>
        </Grid>
      </Grid>
    </Container>
  )
};

export default AutomaticPayableTabBar;