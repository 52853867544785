import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'IntermodalEvent/' + token + '/';

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  return result.response;
};

export async function getAvailableDriversList(eventID) {
  var params = {
    eventID: eventID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAvailableDrivers', params);
  const response = result.data;
  for (const driver of response) {
    driver.Name = driver.DriverName.trim();
  }
  return response;
};

export async function getUnavailableDriversList(eventID) {
  var params = {
    eventID: eventID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetUnavailableDrivers', params);
  const response = result.data;
  for (const driver of response) {
    driver.Name = driver.DriverName.trim();
  }
  return response;
};

export async function create(intermodalEventFormData, insertingAfterEventID, isAddEventAbove) {
  var params = {
    insertingAfterEventID: insertingAfterEventID,
    isAddEventAbove: isAddEventAbove
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', intermodalEventFormData, params);
  return result;
};

/*
export async function createChassisPickupReturn(intermodalEventFormData, insertingAfterEventID, isAddEventAbove) {
  var params = {
    insertingAfterEventID: insertingAfterEventID,
    isAddEventAbove: isAddEventAbove
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateChassisPickupReturn', intermodalEventFormData, params);
  return result;
};
*/

export async function createChassisPickupReturn(intermodalEventFormData, insertingAfterEventID, isAddEventAbove) {
  var params = {
    IntermodalEvent: intermodalEventFormData,
    InsertingAfterEventID: insertingAfterEventID,
    IsAddEventAbove: isAddEventAbove
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateChassisPickupReturn', params);
  return result;
};

export async function update(intermodalEventFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', intermodalEventFormData);
  return result;
};

export async function deleteEvent(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};

export async function updateLeg(intermodalLegFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateLeg', intermodalLegFormData);
  return result;
};

export async function deleteLeg(id, deletePayables) {
  var params = {
    legID: id,
    deleteRelatedPayables: deletePayables || false
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'DeleteLeg', null, params);
  return result;
};

export async function addLeg(id) {
  var params = {
    legID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'AddLeg', null, params);
  return result;
};

export async function addDryRunLeg(id) {
  var params = {
    legID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'AddDryRunLeg', null, params);
  return result.response;
};

export async function moveEventDown(id) {
  var params = {
    eventID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveEventDown', null, params);
  return result;
};

export async function moveEventUp(id) {
  var params = {
    eventID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveEventUp', null, params);
  return result;
};

export async function moveLegDown(id) {
  var params = {
    legID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveLegDown', null, params);
  return result;
};

export async function moveLegUp(id) {
  var params = {
    legID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MoveLegUp', null, params);
  return result;
};

export async function getList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetIntermodalEventList', params);
  const response = result.data;
  return response;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ExportIntermodalEventList', params);
  return result;
};

export async function getLegEventListByByOrderNumber(orderNum) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalLegEventListByOrderNumber/' + orderNum);
  const response = result.data;
  return response;
};