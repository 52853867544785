import React, { useEffect, useState } from 'react';
import { Button, Grid, Dialog, 
    DialogActions, DialogContent, 
    DialogTitle, DialogContentText
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import { DataGrid } from "@material-ui/data-grid";
import CoreWarningDialog from '../core/CoreWarningDialog';
import CoreNumberField from '../core/CoreNumberField';
import CoreListTextField from '../core/CoreListTextField';
import CoreListNumberField from '../core/CoreListNumberField';
import CoreToggleField from '../core/CoreToggleField';
import EquipmentTypeField from '../equipment_type/EquipmentTypeField';
import CarrierListField from '../carrier/CarrierListField';

import { copyOrder } from './OrderService';
import { update } from '../orderEquipment/OrderEquipmentService';
import Validation from '../validation';

const OrderCopyDialog = (props) => { 
  const { 
    open,
    onClose,
    order
  } = props;

  const [inProgress, setInProgress] = useState(false);
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [orderCopies, setOrderCopies] = useState([]);
  const [isDataEntered, setIsDataEntered] = useState(false);
  const [vendorOwner, setVendorOwner] = useState({OwnerCarrierID: 0, OwnerCarrierName: ''});
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [orderCopyFormValidation, setOrderCopyFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [numberOfCopiesEntered, setNumberOfCopiesEntered] = useState(false);
  var currentOrdersCopied = 0;

  const [orderCopyFormData, setOrderCopyFormData] = useState({
    IntermodalOrderID: 0,
    CopyReferenceNumber: false,
    CopyMasterBL: false,
    CopyContainerWeight: false,
    CopyRevenue: false,
    CopyNotes: false,
    // Vessel Information:
    CopyVesselName: false,
    CopyVoyage: false,
    CopyVesselDate: false,
    CopyVesselTime: false,
    CopyVesselActualDate: false,
    CopyVesselActualTime: false,
    // Container Information:
    CopyContainerReleaseDate: false,
    CopyContainerReleaseTime: false,
    CopyContainerLastDate: false,
    CopyContainerLastTime: false,
    CopyCutoffDate: false,
    CopyCutoffTime: false,
    CopyFirstReceivedDate: false,
    CopyFirstReceivedTime: false
  });

  useEffect(() => {
    if (parseInt(numberOfCopies) !== 0 && orderCopies.length === parseInt(numberOfCopies)) { 
      setOrderCopyFormValidation(buildValidation(vendorOwner));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCopies, vendorOwner]);

  useEffect(() => { 
    var orderCopySettings = JSON.parse(localStorage.getItem("intermodalOrderCopy"));
    if (orderCopySettings) { 
      setOrderCopyFormData(orderCopySettings);  
    }
    setOrderCopyFormData(o => ({ ...o, IntermodalOrderID: order.ID  }));  
  }, [order]);

  let columns = [
    {
      field: "ID",
      headerName: "OrderID",
      hide: true
    },
    {
      field: 'Index',
      headerName: '#',
      hide: false,
    },
    {
      field: "OrderNum",
      headerName:"Order #",
      hide: false,
      
    },
    {
      field: "Container.UnitNum",
      headerName: "Container #",
      hide: false,
      flex: 1,
      renderCell: (params) => {
        const index = parseInt(params.getValue("Index")) - 1;       
        return (
          <CoreListTextField
            name="UnitNum"
            value={orderCopies[index].Container.UnitNum}
            autoFocus={false}
            isCapsOnly={true}
            onChange={e => updateContainerData(e, index)}
            isRequired={true}
          />
        )
      }
    },
    {
      field: "Container.OutsideLengthFt",
      headerName: "Size",
      hide: false,
      flex: 0.5,
      renderCell: (params) => { 
        const index = parseInt(params.getValue("Index")) - 1;
        return (
          <CoreListNumberField
            name="OutsideLengthFt"
            value={orderCopies[index].Container.OutsideLengthFt}
            autoFocus={false}
            minValue={0}
            onChange={e => updateContainerData(e, index)}
          />
        )
      }
    },
    {
      field: "Container.EquipmentType",
      headerName: "Equipment Type",
      hide: false,
      flex: 1,
      renderCell: (params) => { 
        const index = parseInt(params.getValue("Index")) - 1;
        return (
          <span style={{width: '100%', lineHeight: '22px'}}>
            <EquipmentTypeField
              name="EquipmentType"
              value={orderCopies[index].Container.EquipmentType}
              onChange={e => updateContainerData(e, index)}
              isRequired={true}
            />
          </span>
        )
      }
    }
  ];

  const updateNumberOfCopies = e => {
    const { value } = e.target;
    setNumberOfCopies(value);
  };

  const updateOrderCopyFormData = e => { 
    const { name, value } = e.target;
    orderCopyFormData[name] = value;
    setOrderCopyFormData({ ...orderCopyFormData });
  };

  const updateVendorOwner = e => { 
    const { name, value, nameData, valueData } = e.target;
    vendorOwner[name] = value;
    vendorOwner[nameData] = valueData;
    setVendorOwner({ ...vendorOwner });
    setIsDataEntered(true);
  };

  const updateContainerData = (e, index) => {
    const { name, value } = e.target;
    orderCopies.at(index).Container[name] = value;
    setOrderCopies([...orderCopies]);
    setIsDataEntered(true);
  };

  const copy = (index) => {
    copyOrder(orderCopyFormData)
      .then(function(response) {
        response.Index = index + 1;
        setOrderCopies(o => [...o, response]);
        currentOrdersCopied++;
        if (currentOrdersCopied < numberOfCopies) { 
          copy(currentOrdersCopied);
        }  
      });
  };

  function saveOrderCopySettings() { 
    var tmp = { ...orderCopyFormData };
    delete tmp.IntermodalOrderID;
    localStorage.setItem("intermodalOrderCopy", JSON.stringify(tmp));
  };

  const onOrderCopy = async (e) => { 
    if (!numberOfCopies || numberOfCopies === 0) { 
      return;
    }
    setInProgress(true);
    saveOrderCopySettings();
    copy(currentOrdersCopied);
    setInProgress(false);
  };

  const onSaveContainerInfo = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(orderCopyFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processSaveContainerInfo();
  };

  const processSaveContainerInfo = async () => { 
    setErrorMessage('');
    orderCopies.forEach(async (orderCopy) => {
      orderCopy.Container.OwnerCarrierID = vendorOwner.OwnerCarrierID;
      const result = await update(orderCopy.Container);
      if (result.error) {
        setErrorMessage('Order ' + orderCopy.OrderNum + ': ' + result.error);
        return;
      }
    });
    if (errorMessage.length === 0) { 
      setIsDataEntered(false);
    }
    setInProgress(false);
  };

  const onCellClick = e => {
    if (e.field === "OrderNum") {
      window.open('/spark/order/view/' + e.row.ID, '_blank', 'noopener,noreferrer');
    }
  };

  const onCloseButtonClick = (e) => { 
    if (isDataEntered) { 
      setIsWarningDialogOpen(true);
    } else { 
      closeDialog();
    }
  };

  const next = (e) => { 
    setNumberOfCopiesEntered(true);
  };

  const closeWarningDialog = (e) => { 
    setIsWarningDialogOpen(false);
  };

  const confirmWarningDialog = (e) => { 
    setIsWarningDialogOpen(false);
    closeDialog();
  };

  const closeDialog = (e) => {
    currentOrdersCopied = 0;
    setNumberOfCopies(1);
    setNumberOfCopiesEntered(false);
    setOrderCopies([]);
    setIsDataEntered(false);
    setVendorOwner({OwnerCarrierID: 0, OwnerCarrierName: ''});
    onClose();
  };

  return ( 
    <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={!!numberOfCopiesEntered ? "md" : "sm"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Copy Order {order.OrderNum}</DialogTitle>
      <div>
        <DialogContent>
          {orderCopies.length === 0 && !numberOfCopiesEntered && !inProgress && <>
          <Grid container item xs spacing={12} className="mt-2 mb-2">
            <Grid item xs={6}>
              <CoreNumberField
                name="numberOfCopies"
                label="Number of Copies"
                value={numberOfCopies}
                onChange={e => updateNumberOfCopies(e)}
                isRequired={true}
                minValue={1}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose} className="grey">
              Cancel
            </Button>
            <Button onClick={next} disabled={inProgress} className="grey">
              Next
            </Button>
          </DialogActions>
          </>}
          {numberOfCopiesEntered && parseInt(numberOfCopies) !== 0 && orderCopies.length === 0 && !inProgress && 
            <>
            <Grid container item xs spacing={2} className="mt-1 mb-2">
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <DialogContentText>
                    Toggle which Order fields to copy over.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <h4>General Information</h4>
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Reference Number"
                    name="CopyReferenceNumber"
                    value={orderCopyFormData.CopyReferenceNumber}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Master BL"
                    name="CopyMasterBL"
                    value={orderCopyFormData.CopyMasterBL}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Container Weight"
                    name="CopyContainerWeight"
                    value={orderCopyFormData.CopyContainerWeight}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}><h4>Vessel Information</h4></Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Vessel Name"
                    name="CopyVesselName"
                    value={orderCopyFormData.CopyVesselName}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Voyage"
                    name="CopyVoyage"
                    value={orderCopyFormData.CopyVoyage}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                {order.Type !== 'Export' && <Grid item xs={3}>
                  <CoreToggleField
                    label="Est. Arrival Date"
                    name="CopyVesselDate"
                    value={orderCopyFormData.CopyVesselDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type !== 'Export' && orderCopyFormData.CopyVesselDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="Est. Arrival Time"
                    name="CopyVesselTime"
                    value={orderCopyFormData.CopyVesselTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type !== 'Export' && <Grid item xs={3}>
                  <CoreToggleField
                    label="Actual Arrival Date"
                    name="CopyVesselActualDate"
                    value={orderCopyFormData.CopyVesselActualDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type !== 'Export' && orderCopyFormData.CopyVesselActualDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="Actual Arrival Time"
                    name="CopyVesselActualTime"
                    value={orderCopyFormData.CopyVesselActualTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h4>Container Availability</h4>
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Release Date"
                    name="CopyContainerReleaseDate"
                    value={orderCopyFormData.CopyContainerReleaseDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                {orderCopyFormData.CopyContainerReleaseDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="Release Time"
                    name="CopyContainerReleaseTime"
                    value={orderCopyFormData.CopyContainerReleaseTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type !== 'Export' && <Grid item xs={3}>
                  <CoreToggleField
                    label="Last Free Day Date"
                    name="CopyContainerLastDate"
                    value={orderCopyFormData.CopyContainerLastDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type !== 'Export' && orderCopyFormData.CopyContainerLastDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="Last Free Day Time"
                    name="CopyContainerLastTime"
                    value={orderCopyFormData.CopyContainerLastTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type === 'Export' && <Grid item xs={3}>
                  <CoreToggleField
                    label="Cut Off Date"
                    name="CopyCutoffDate"
                    value={orderCopyFormData.CopyCutoffDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type === 'Export' && orderCopyFormData.CopyCutoffDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="Cut Off Time"
                    name="CopyCutoffTime"
                    value={orderCopyFormData.CopyCutoffTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type === 'Export' && <Grid item xs={3}>
                  <CoreToggleField
                    label="First Received Date"
                    name="CopyFirstReceivedDate"
                    value={orderCopyFormData.CopyFirstReceivedDate}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
                {order.Type === 'Export' && orderCopyFormData.CopyFirstReceivedDate && <Grid item xs={3}>
                  <CoreToggleField
                    label="First Received Time"
                    name="CopyFirstReceivedTime"
                    value={orderCopyFormData.CopyFirstReceivedTime}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>}
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h4>Related Records</h4>
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Revenue"
                    name="CopyRevenue"
                    value={orderCopyFormData.CopyRevenue}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CoreToggleField
                    label="Notes"
                    name="CopyNotes"
                    value={orderCopyFormData.CopyNotes}
                    onChange={e => updateOrderCopyFormData(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={closeDialog} className="grey">
                Cancel
              </Button>
              <Button onClick={onOrderCopy} disabled={inProgress} className="grey">
                Confirm
              </Button>
            </DialogActions>
            </>
          }
          {numberOfCopies && orderCopies && orderCopies.length === parseInt(numberOfCopies) && parseInt(numberOfCopies) !== 0 && !inProgress && 
          <>
            <DialogContentText>
              <Grid container item xs spacing={2}>
                <Grid item xs={2}>
                  Order(s) Created:
                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={3}>
                  <CarrierListField
                    label="Vendor"  
                    name="OwnerCarrierName"
                    value={vendorOwner.OwnerCarrierName}
                    nameData="OwnerCarrierID"
                    valueData={vendorOwner.OwnerCarrierID}
                    onChange={e => updateVendorOwner(e)}
                    validationError={orderCopyFormValidation.OwnerCarrierID}
                    isRequired={true}
                    autoFocus={true}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
            <DataGrid
              getRowId={(row) => row.ID} 
              hideFooter={true} 
              autoHeight={true} 
              rows={orderCopies} 
              columns={columns} 
              pageSize={5} 
              disableColumnMenu={true}
              disableColumnSelector={true}
              disableSelectionOnClick={true}
              onCellClick={(e) => onCellClick(e)}
            />
            {errorMessage && <br/>}
            {errorMessage && 
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            }
            {!errorMessage && <br/>}
            <DialogActions>
              <Button onClick={onCloseButtonClick} className="grey">
                Close
              </Button>  
              {isDataEntered &&
                <Button onClick={onSaveContainerInfo} disabled={inProgress} type="submit" color="primary">
                  Save
                </Button>
              }
            </DialogActions>
          </>}
        </DialogContent>
      </div>
    </Dialog>
    <CoreWarningDialog
      isOpen={isWarningDialogOpen}
      onConfirm={confirmWarningDialog}
      onClose={closeWarningDialog}
    />
    </>
  )
}

const buildValidation = (vendorOwner) => { 
 const {
  OwnerCarrierID
 } = vendorOwner || {};
 const validation = new Validation();
 return { 
  OwnerCarrierID: validation.isEmpty(OwnerCarrierID)
 }
};

export default OrderCopyDialog;