import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as UnitTypeSvg } from '../assets/svg-icons/unit-type@1x.svg';

const IconUnitType = () => {
    return (
        <SvgIcon fontSize="small">
            <UnitTypeSvg />
        </SvgIcon>    
    )
}
export default IconUnitType;