import React, { useState } from 'react';
import CoreDeleteDialogContent from '../core/CoreDeleteDialogContent';

const CoreDeleteDialog = (props) => {
  const {
    deleteID,
    deleteFunction,
    onDeleteSuccess,
    isOpen,
    onClose,
    reference
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const onDelete = e => {
    e.preventDefault();
    processDelete();    
  };

  const processDelete = async () => {
    setErrorMessage('');
    const result = await deleteFunction(deleteID);
    handleErrors(result);
  };

  const handleErrors = (result) => { 
    if (result.errors?.Error) { 
      setErrorMessage(result.errors.Error);
      return;
    }
    if (result.error) { 
      setErrorMessage(result.error);
      return;
    }
    onClose();
    onDeleteSuccess();
  };

  const closeDialog = () => { 
    setErrorMessage('');
    onClose();
  };

  return (
    isOpen &&
    <CoreDeleteDialogContent
      deleteID={deleteID}
      onDelete={onDelete}
      isOpen={isOpen}
      onClose={closeDialog}
      reference={reference}
      errorMessage={errorMessage}
    />
  );
}

export default CoreDeleteDialog;