import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import DocumentTypeCategoryField from './DocumentTypeCategoryField';

const DocumentTypeEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    documentTypeFormData,
    onUpdate,
    errorMessage,
    documentTypeFormValidation,
    updateDocumentTypeFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Document Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Description"
                label="Name"
                value={documentTypeFormData.Description}
                onChange={e => updateDocumentTypeFormData(e)}
                isRequired={true}
                validationError={documentTypeFormValidation.Description}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <DocumentTypeCategoryField
                name="RelatedTo"
                label="Document Category"
                value={documentTypeFormData.RelatedTo}
                onChange={e => updateDocumentTypeFormData(e)}
                isRequired={true}
                validationError={documentTypeFormValidation.RelatedTo}
              />
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CoreCheckboxField
                label="Display in App"
                value={documentTypeFormData.DisplayInApp}
                name="DisplayInApp"
                onChange={e => updateDocumentTypeFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CoreCheckboxField
                label="Include In Invoice"
                value={documentTypeFormData.IncludeInInvoice}
                name="IncludeInInvoice"
                onChange={e => updateDocumentTypeFormData(e)}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default DocumentTypeEditDialogContent;