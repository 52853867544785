import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import CoreDateField from '../core/CoreDateField';
import PersonnelField from '../personnel/PersonnelField';
import IntermodalPaymentDeductibleCreateDialog from './IntermodalPaymentDeductibleCreateDialog';
import IntermodalPaymentConfirmDialog from './IntermodalPaymentConfirmDialog';

const useStyles = makeStyles(() => ({
  justifyRight: {
    display: 'flex', 
    justifyContent: 'right'
  }
}));

const IntermodalPaymentFilterRow = (props) => {
    const {
      filterData,
      updateFilterData,
      setApprovedSettlements,
      completeSettlements,
      markPending,
      parameters,
      updateParams,
      getSettlements,
      settlements,
      printStatements,
      listWidth
    } = props;
    const classes = useStyles();
    const [isDeductibleDialogOpen, setIsDeductibleDialogOpen] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const handleMarkAsCompleted = () => {
      setIsConfirmationDialogOpen(true);
    };

    const handleClose = () => { 
      setIsDeductibleDialogOpen(false);
      setIsConfirmationDialogOpen(false);
      getSettlements();
    };

    return (
      <div>
      <Container maxWidth={listWidth} className="mb-2">
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={4}>
                <CoreDateField
                  name={"BillDate"}
                  label="Pay Date"
                  value={parameters.BillDate}
                  onChange={e => updateParams(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <PersonnelField
                  name={"Personnel"}
                  label={"Personnel"}
                  value={filterData.PersonnelID}
                  nameData="PersonnelID" 
                  valueData={filterData.PersonnelID}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} className={classes.justifyRight}>
            {parameters.ShowApproved && settlements.length > 0 && <Grid item md={3} xs={12}> 
              <Grid container spacing={1}>
                <Button 
                  variant="contained" 
                  style={{flex: 1, backgroundColor: '#6639BF', color: 'white'}}
                  onClick={markPending}>
                  {"Mark As Pending"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </Grid>  
            </Grid>
            }
            {!parameters.ShowCompleted && !parameters.ShowApproved && settlements.length > 0 && <Grid item md={3} xs={12}> 
              <Grid container spacing={1}>
                <Button 
                  variant="contained" 
                  style={{flex: 1, backgroundColor: '#6639BF', color: 'white'}}
                  onClick={setApprovedSettlements}>
                  {"Mark As Approved"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </Grid>  
            </Grid>
            } 
            {!parameters.ShowCompleted && parameters.ShowApproved && settlements.length > 0 && <Grid item md={3} xs={12}> 
              <Grid container spacing={1}>
                <Button 
                  variant="contained" 
                  style={{flex: 1, backgroundColor: '#6639BF', color: 'white'}}
                  onClick={() => handleMarkAsCompleted()}>
                  {"Mark As Completed"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </Grid>  
            </Grid>
            } 
            {parameters.ShowApproved && settlements.length > 0 && <Grid item md={3} xs={12}>
              <Grid container spacing={1}>
                <Button
                  name=""
                  variant="contained"
                  style={{flex: 1, backgroundColor: '#6639BF', color: 'white'}}
                  onClick={printStatements}
                >
                  {"Print Statement(s)"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </Grid>
              </Grid>
            }
            {parameters.BillDate && <Grid item md={3} xs={12}>
              <Grid container spacing={1}>
                <Button 
                  name="settlement_list[generate_payable_deductable]" 
                  variant="contained" 
                  style={{flex: 1, backgroundColor: '#6639BF', color: 'white'}}
                  onClick={() => setIsDeductibleDialogOpen(true)}
                >
                  {"Generate Expense"}
                </Button>
              </Grid>
            </Grid>
            }
          </Grid>
        </Grid>
      </Container>
      <IntermodalPaymentDeductibleCreateDialog
        isOpen={isDeductibleDialogOpen}
        onClose={handleClose}
        populatedParams={{BillDate: parameters.BillDate, PersonnelID: filterData.PersonnelID}}
      />
      <IntermodalPaymentConfirmDialog
        isOpen={isConfirmationDialogOpen}
        onConfirm={completeSettlements}
        onClose={handleClose}
        bills={settlements}
      />
      </div>
    );
};

export default IntermodalPaymentFilterRow;