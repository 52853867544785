import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Constants from './DashboardConstants.json';
import DashboardDispatchTable from './DashboardDispatchTable';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3), //grid padding
    textAlign: 'left',
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  flag: {
    display: 'inline-block',
    width: '14px',
    height: '14px',
    lineHeight: '14px',
    marginRight: '6px',
    borderRadius: '7px'
  },
  legendItem: {
    fontSize: '14px',
  }
}));

const DashboardDispatchSummary = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{fontWeight:'bold', display:'flex', justifyContent:'flex-start'}}>
            Weekly Dispatch View
          </Typography>
        </Grid>
        <Grid item xs={4} container>
          <Grid item xs={4} align="right" className={classes.legendItem}>
            <Tooltip title="To be picked up that day." placement="top-end">
              <div>
                <span className={classes.flag} style={{backgroundColor: Constants.DISPATCH.STATUS["AwaitingPickup"].color}} />
                Awaiting Pickup
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={4} align="right" className={classes.legendItem}>
            <Tooltip title="Planned date is posted. Appt date is not posted." placement="top">
              <div>
                <span className={classes.flag} style={{backgroundColor: Constants.DISPATCH.STATUS["NeedsUpdate"].color}} />
                Needs Update
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={4} align="right" className={classes.legendItem}>
            <Tooltip title="Appt date is not posted. Current time is later than planned date." placement="top">
              <div>
                <span className={classes.flag} style={{backgroundColor: Constants.DISPATCH.STATUS["RunningLate"].color}} />
                Running Late
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DashboardDispatchTable />
      </Grid>
    </Paper>
  );

}

export default DashboardDispatchSummary;
