import React from 'react';
import QuickbooksSyncSuppressDialogContent from './QuickbooksSyncSuppressDialogContent';

const QuickbooksSyncSuppressDialog = (props) => {
  const {
    ID,
    suppressFunction,
    getList,
    isOpen,
    onClose,
  } = props;

  const onSuppress = e => {
    e.preventDefault();
    processSuppress();    
  };

  const processSuppress = async () => {
    await suppressFunction(ID);
    onClose();
    getList();
  };

  return (
    isOpen &&
    <QuickbooksSyncSuppressDialogContent
      ID={ID}
      onSuppress={onSuppress}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

export default QuickbooksSyncSuppressDialog;