import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CoreExitButton from '../core/CoreExitButton';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { updateLeg } from '../intermodalEvent/IntermodalEventService';
import DispatchListDriverAssignDrawerAvailableSection from './DispatchListDriverAssignDrawerAvailableSection';
// import DispatchListDriverAssignDrawerUnavailableSection from './DispatchListDriverAssignDrawerUnavailableSection';

const useStyles = makeStyles(() => ({
  paper: {
    width: 450
  }
}));

const DispatchListDriverAssignDrawer = (props) => {
  const classes = useStyles();
  const {
    event,
    setEvent,
    isOpen,
    onClose,
  } = props;

  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formattedLeg, setFormattedLeg] = useState({
    ID: null,
    IntermodalOrderID: null,
    LegIndex: null,
    DriverID: null,
    PlannedDate: null,
  });

  const updateEventFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    event[name] = value;
    event[nameData] = valueData;
    setEvent({ ...event });
    formattedLeg.ID = event.LegID;
    formattedLeg.IntermodalOrderID = event.IntermodalOrderID;
    formattedLeg.LegIndex = event.LegIndex;
    formattedLeg.DriverID = event.DriverID;
    formattedLeg.PlannedDate = event.PlannedDate;
    setFormattedLeg({ ...formattedLeg });
    onSave();
  };

  const onSave = () => {
    setInProgress(true);
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await updateLeg(formattedLeg);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    setInProgress(false);
    onClose();
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpen}
        ModalProps={{onBackdropClick: onClose }}
        classes={{ paper: classes.paper }}>
        <CoreSummaryHeader
          title={"Assign Driver"}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
        />
        {inProgress && <LinearProgress />}
        <Container maxWidth="lg" className="mt-2">
          <DispatchListDriverAssignDrawerAvailableSection
            event={event}
            updateEventFormData={updateEventFormData}
          />
          {/* ----------------------------------------------------
            Hide this for now since we don't have it ready to roll*
            <DispatchListDriverAssignDrawerUnavailableSection
              event={event}
            />
          */}
          {errorMessage && <Grid item md={12} xs={12} className="mt-2">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </Container>
      </Drawer>
    </>
  );

}

export default DispatchListDriverAssignDrawer;