import React, {useState, useEffect} from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';

import IconNavigationToolbarButton from './NavigationToolbarButton';
// Using notifications for testing live data on Chat UX
// import { getNotificationsList as getChatsList } from '../notifications/NotificationsService';
import { getConversations as getChatsList } from '../chat/ChatService';

const useStyles = makeStyles((theme) => ({
  purpleIcon: {
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);',
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  popoverContainer: {
    padding: '12px'
  },
  seeAll: {
    paddingLeft: '12px'
  },
  unseenGridItem: {
    backgroundColor: 'rgba(102, 57, 191, 0.08)',
    margin: '10px 0 0 0',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '8px'
  },
  message: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  date: {
    paddingTop: '8px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  purpleCircle: {
    height: '8px',
    width: '8px',
    marginTop: '4px',
    borderRadius: '4px',
    backgroundColor: '#6639BF',
  },
  truncate: {
    width: '210px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noResults: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '20px 0 10px 0;',
    flexGrow: 1,
    textAlign: 'center'
  }
}));

const NavigationToolbarButtonChat = ({ history }) => {
  const location = useLocation();
  const classes = useStyles();

  const [chatList, setChats] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unseenChat, setUnseenChat] = useState(0);

  useEffect(() => {
    // Listen to location to update counts
    async function getChats() { 
      const result = await getChatsList();
      if(result?.length > 0) {
        setChats(result);
        let count = 0;
        for(let i = 0; i < result.length; ++i){
          if(!result[i].IsRead) {
            count++;
          }
        }
        setUnseenChat(count);
      }
    }
    getChats();
  }, [location]);

  const chatBadgeClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChatClick = () => {
    history.push('/spark/chat');
    setAnchorEl(null);
  }

  const UnreadGridItems = () => {
    // Update with chat api endpoint when available.
    let itemList=[];
    chatList.forEach((chat,index)=>{
      if (!chat.IsRead) {
        itemList.push(
          <Grid container className={classes.unseenGridItem} key={index} onClick={()=>handleChatClick()}>
            <Grid item xs={2}>
              <Avatar style={{backgroundColor:'#6639BF'}}>
                {chat.PersonnelInternalName &&
                chat.PersonnelInternalName.length > 0 &&
                chat.PersonnelInternalName.includes(" ")
                ? chat.PersonnelInternalName.split(" ").shift().charAt(0) +
                  chat.PersonnelInternalName.split(" ").pop().charAt(0)
                : ""}
                </Avatar>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.truncate}>{chat.PersonnelInternalName}</Typography>
              <Typography variant="body2" className={`${classes.message} ${classes.truncate}`}>{chat.Body}</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <div className={classes.purpleCircle} />
              <Typography variant="body2" className={classes.date}>
                {chat.DateCreated?.split('T', 1)[0]}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    })
    return itemList
  }

  const open = Boolean(anchorEl);
  const id = open ? 'chat-popover' : undefined;

  return (
    <>
      <div aria-describedby={id} onClick={(e)=>chatBadgeClick(e)}>
        {unseenChat ? (
          <Badge badgeContent={unseenChat} color="primary">
            <div className={classes.purpleIcon}>
              <IconNavigationToolbarButton name="navigation[chat]" icon={<ChatIcon/>}  />
            </div>
          </Badge>
        ) : (
          <IconNavigationToolbarButton name="navigation[chat]" icon={<ChatIcon/>} />
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '420px' },
        }}
      >
        <Grid container className={classes.popoverContainer}>
          <Grid item xs={6} >
            <Typography variant="body2">Chat</Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="body2">
              { unseenChat ? `${unseenChat} unread` : null }
              <Link href="/spark/chat" color="primary" className={classes.seeAll} onClick={()=>handleChatClick()}>See All</Link>
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.lightDivider} />
        <Grid container className={classes.popoverContainer} style={{paddingTop:'0'}}>
          { unseenChat ? (<UnreadGridItems />) : (
            <Typography variant="subtitle2" className={classes.noResults}>You have no new chats.</Typography>
          ) }
        </Grid>
      </Popover>
    </>
  );

}

export default withRouter(NavigationToolbarButtonChat);
