import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import QuickbooksMappedRevenueList from './QuickbooksMappedRevenueList';
import QuickbooksMappingHeader from './QuickbooksMappingHeader';
import CoreAddButton from '../core/CoreAddButton';
import QuickbooksMappedRevenueCreateDialog from './QuickbooksMappedRevenueCreateDialog';
import QuickbooksMappedRevenueEditDialog from './QuickbooksMappedRevenueEditDialog';
import { getAcctSyncRevenueItemList, deleteAcctSyncRevenueItem, areRevenueItemsMapped } from './QuickbooksService';
import Alert from '@material-ui/lab/Alert';

const QuickbooksMappingRevenue = () => {

  const [mappedRevenueData, setMappedRevenueData] = useState([]);
  const [isRevenueCreateDialogOpen, setIsRevenueCreateDialogOpen] = useState(false);
  const [isRevenueEditDialogOpen, setIsRevenueEditDialogOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [isMapped, setIsMapped] = useState(true);
  const [currentRevenueID, setCurrentRevenueID] = useState(0);

  useEffect(() => {    
    getRevenueList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRevenueMapped = async() => {
    setInProgress(true);
    const result = await areRevenueItemsMapped();
    setInProgress(false);
    setIsMapped(result);
  };

  const getRevenueList = async () => { 
    setInProgress(true);
    const result = await getAcctSyncRevenueItemList();
    checkRevenueMapped();
    setInProgress(false);
    if (result.length > 0) {
      setMappedRevenueData(result);
    }
  };

  const revenueMappingDelete = async (acctSyncXactID) => {
    setInProgress(true);
    deleteAcctSyncRevenueItem(acctSyncXactID)
      .then(function(response) { 
        getRevenueList();
      });
  };

  const handleOpenCreate = () => {
    setIsRevenueCreateDialogOpen(true);
  };

  const closeRevenueCreateDialog = () => {
    setIsRevenueCreateDialogOpen(false);
    getRevenueList();
  };

  const revenueMappingEdit = (acctSyncXactID) => {
    setCurrentRevenueID(acctSyncXactID);
    setIsRevenueEditDialogOpen(true);
  };

  const closeRevenueEditDialog = () => {
    setIsRevenueEditDialogOpen(false);
    getRevenueList();
  };

  return (
    <>
      <Paper className="mt-2 mb-2 munaGrid" elevation={1} square={true} align="center">
        <QuickbooksMappingHeader
          title="Revenue Item Mapping"
          description="All Envase revenue items must be mapped to QuickBook items"
          notation = "This includes all revenue items that been obsoleted within your system to pull historical data"
          addButton={<CoreAddButton handleOpenCreate={handleOpenCreate}></CoreAddButton>}
        />
        {!isMapped && <Alert severity="warning">{"There are unmapped revenue items. Please click '+ Add' to map the rest of the Local Revenue Items."}</Alert>}
        <QuickbooksMappedRevenueList 
          inProgress={inProgress}
          mappedRevenueData={mappedRevenueData}
          revenueMappingDelete={revenueMappingDelete}
          revenueMappingEdit={revenueMappingEdit}
        />
      </Paper>
      <QuickbooksMappedRevenueCreateDialog
        isOpen={isRevenueCreateDialogOpen}
        onClose={closeRevenueCreateDialog}
      />
      <QuickbooksMappedRevenueEditDialog
        acctSyncXactID={currentRevenueID}
        isOpen={isRevenueEditDialogOpen}
        onClose={closeRevenueEditDialog}
      />
    </>
  );
}

export default QuickbooksMappingRevenue;
