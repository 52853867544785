import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CorePhoneField from '../core/CorePhoneField';
import CoreFavoriteButtonField from '../core/CoreFavoriteButtonField';

const ContactCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    contactFormData,
    onCreate,
    errorMessage,
    contactFormValidation,
    updateContactFormData
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Contact</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="name"
                label="Name"
                value={contactFormData.name}
                onChange={e => updateContactFormData(e)}
                isRequired={true}
                validationError={contactFormValidation.name}
                autoFocus={true}
                maxLength={50}              
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="position"
                label="Title"
                value={contactFormData.position}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.position}
                maxLength={50}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="email"
                label="E-mail"
                value={contactFormData.email}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.email}
                maxLength={255}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CorePhoneField
                name="phone"
                label="Phone"              
                value={contactFormData.phone}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.phone}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <CoreTextField
                name="extension"
                label="Extension"              
                value={contactFormData.extension}
                nameData="extension"
                valueData={contactFormData.extension}
                onChange={e => updateContactFormData(e)}
                validationError={contactFormValidation.extension}
                maxLength={6}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CoreFavoriteButtonField
            name="isFav"
            label="Mark as Fav"
            value={contactFormData.isFav}
            onChange={e => updateContactFormData(e)}
          />
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ContactCreateDialogContent;