import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconEmail from '../icon/IconEmail';
import IconPhone from '../icon/IconPhone';
import IconLegalName from '../icon/IconLegalName';
import IconLocationLight from '../icon/IconLocationLight';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import { getGeocode } from '../mileage/MileageService';

const useStyles = makeStyles(() => ({
  infoCard: {
    width: '380px',
    border: '0',
    boxShadow: '0',
    borderRadius: '8px',
    padding: '5px 10px 0px 10px !important',
    backgroundColor: '#f5f5f5'
  },
  summarySectionTitle: {
    fontWeight: 'normal',
    fontSize: '12px',
    marginTop: '28px',
    marginBottom: '12px'
  },
  divider: {
    borderBottom: '1px solid #efefef',
    paddingBottom: '20px'
  },
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  },
  iconSize24: {
    fontSize: '24px'
  }
}));

const LocationViewSummaryInfoSection = (props) => {
  const classes = useStyles();

  const [addressCoordinates, setAddressCoordinates] = useState({'lat': 43, 'lng': -95});

  const {
    location
  } = props;

  useEffect(() => { 
    getGeocode(
      location.Address, 
      location.City, 
      location.Province, 
      location.Country, 
      location.PostalCode) 
      .then(function(response) {
        setAddressCoordinates(response);
     });
  }, [
    location.Address, 
    location.City, 
    location.Province, 
    location.Country, 
    location.PostalCode
  ]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>LOCATION INFORMATION</h5>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Name"
              value={location.InternalName}
              icon={<IconLegalName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.iconSize}>
          <Grid item xs={12} style={{fontSize: '14px'}}>     
            <CoreTextDisplayField
              label="Address"
              value={location.Address}
              icon={<IconLocationLight />}
            />
          </Grid>
          <Grid item xs={12} style={{height:'180px', borderRadius:'5px', marginTop: '15px'}}>
            { addressCoordinates && 
              <CoreHereDisplayMap
                coordinates={addressCoordinates}
              />
            }
          </Grid>
        </Grid>
      </Grid>
      {location.LoadingInfo &&
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>LOADING INFO</h5>
        </Grid>
        <Grid container item xs={12}>
          <Card elevation={0} className={classes.infoCard}>
            <CardContent >              
              <Typography variant="body2" component="p">
                {location.LoadingInfo}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      }
      {location.ReceivingInfo && 
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>RECEIVING INFO</h5>
        </Grid>
        <Grid container item xs={12}>
          <Card elevation={0} className={classes.infoCard}>
            <CardContent>              
              <Typography variant="body2" component="p">
                {location.ReceivingInfo}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      }
      {location.Notes &&
      <Grid container spacing={1} className={classes.divider}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>ADDITIONAL INFO</h5>
        </Grid>
        <Grid container item xs={12}>
          <Card elevation={0} className={classes.infoCard}>
            <CardContent>              
              <Typography variant="body2" component="p">
                {location.Notes}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      }
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>QUICK CONTACT</h5>
        </Grid>
        <Grid container item xs={12} style={{paddingBottom: '10px'}}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Contact Name"
              value={location.Contact}
              icon={<IconLegalName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{paddingBottom: '10px'}}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize24}>
            <CoreTextDisplayField
              label="Email"
              value={location.Email}
              isEmail={true}
              icon={<IconEmail />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize24}>
            <CoreTextDisplayField
              label="Phone"
              value={<CoreIntlPhoneOutput value={location.Phone} />}
              icon={<IconPhone />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LocationViewSummaryInfoSection;