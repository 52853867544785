import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as HealthSvg } from '../assets/svg-icons/health@1x.svg'

const IconHealth = () => {
  return (
    <SvgIcon fontSize="small">
      <HealthSvg />
    </SvgIcon>
  )
}

export default IconHealth;