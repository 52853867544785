import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const CoreAlertDialog = (props) => {
  const {handleAlertClose, alertOpen, alertMessage, onConfirm} = props;

  return (
    <div>
      <Dialog
        open={alertOpen}
        aria-describedby="alert-dialog-description"
        className="infoDialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} autoFocus color="primary">
            No
          </Button>
          <Button onClick={onConfirm} autoFocus color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CoreAlertDialog.propTypes = {
  // alertMessage: Can be type string or type element
  alertOpen: PropTypes.bool.isRequired,
  handleAlertClose: PropTypes.func.isRequired
};

export default CoreAlertDialog;
