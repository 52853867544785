import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CustomerSvg } from '../assets/svg-icons/customer@1x.svg';

const IconCustomer = () => {
  return (
    <SvgIcon fontSize="small">
      <CustomerSvg />
    </SvgIcon>
  )
}
export default IconCustomer;