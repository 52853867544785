import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BlockSvg } from '../assets/svg-icons/block@1x.svg'

const IconBlock = (props) => {

  const {
    fontSize = 'small'
  } = props;

  return (
    <SvgIcon fontSize={fontSize}>
      <BlockSvg />
    </SvgIcon>
  )
}

IconBlock.propTypes = {
  fontSize: PropTypes.string
};

export default IconBlock;