export const getItemStyle = (isDragging, draggableStyle) => ({
  flexGrow: 1,
  //paddingRight: '20px',
  ...draggableStyle,
});

export const getLegItemStyle = (isDragging, draggableStyle) => ({
  flexGrow: 1,
  marginBottom: '5px',
  border: '0.5px solid #D3D3D3',
  borderRadius: '4px',
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver) => ({
  flexGrow: 1,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const onDragEnd = (result, itemList) => {
  if (!result.destination) {
    return;
  }

  const items = reorder(
    itemList,
    result.source.index,
    result.destination.index
  );

  return items;
};