import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'TravelDoc/' + token + '/';

export async function Create(personnelTravelDocFormData) {
  const httpService = new HttpService();
  const result = await httpService
      .post(uri + 'Create?PersonnelID=' + personnelTravelDocFormData.PersonnelID + '&ACETravelDocType=' + personnelTravelDocFormData.ACETravelDocType 
        + '&DocumentNumber=' + personnelTravelDocFormData.DocumentNumber + '&Province=' + personnelTravelDocFormData.Province 
        + '&Country=' + personnelTravelDocFormData.Country + '&ExpiryDate=' + personnelTravelDocFormData.ExpiryDate + '&RemindTaskID=' + personnelTravelDocFormData.RemindTaskID);
  return result;
};

export async function Update(personnelTravelDocFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + personnelTravelDocFormData.PersonnelTravelDocID + '/Update?PersonnelTravelDocID=' + personnelTravelDocFormData.PersonnelTravelDocID 
        + '&PersonnelID=' + personnelTravelDocFormData.PersonnelID + '&ACETravelDocType=' + personnelTravelDocFormData.ACETravelDocType 
        + '&DocumentNumber=' + personnelTravelDocFormData.DocumentNumber + '&Province=' + personnelTravelDocFormData.Province 
        + '&Country=' + personnelTravelDocFormData.Country + '&ExpiryDate=' + personnelTravelDocFormData.ExpiryDate 
        + '&RemindTaskID=' + personnelTravelDocFormData.RemindTaskID);
  return result;
};

export async function Delete(personnelTravelDocFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + personnelTravelDocFormData.PersonnelTravelDocID + '/Delete');
  return result;
};