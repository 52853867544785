import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import IconPrint from '../icon/IconPrint';

const useStyles = makeStyles(() => ({
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)',
    width: '90px',
    height: '36px'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  }
}));

const OrderPrintMenuButton = (props) => {
  const classes = useStyles();

  const {
    onClick
  } = props;

  return (
    <Button 
      variant="outlined" 
      color="primary"
      size="small"
      className={classes.buttonColor}
      onClick={onClick} 
      startIcon={<span className={classes.iconColor}><IconPrint /></span>}
      >
      Print
    </Button>
  );
}

export default OrderPrintMenuButton;