import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import IntermodalInvoiceViewPageHeader from './IntermodalInvoiceViewPageHeader';
import IntermodalInvoiceViewTabBar from './IntermodalInvoiceViewTabBar';
import { get as getLocation } from '../location/LocationService';

import { get, getIntermodalInvoiceList } from '../order/OrderService';
import { billingOnHold, readyForBilling } from './IntermodalInvoiceService';
import { getInvoiceCode } from '../customer_invoice_code/CustomerInvoiceCodeService';
import LinearProgress from '@material-ui/core/LinearProgress';
import IntermodalInvoiceViewRevenueList from './IntermodalInvoiceViewRevenueList';

const IntermodalInvoiceView = (props) => {

  const {
    location
  } = props;

  let history = useHistory();
  const { id } = useParams();

  const [order, setOrder] = useState({});
  const [inProgress, setInProgress] = useState(true);
  const [orderListInProgress, setOrderListInProgress] = useState(true);
  const [tabSelected, setTabSelected] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [orderPosition, setOrderPosition] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    amount: 10000,
    count: 10,
    isASC: true,
    sortBy: 'ID',
    filters: [],
    ShowDispatched: false,
    ShowInTransit: false,
    ShowCompleted: true,
    ShowBillingOnHold: false,
    ShowApprovedForBilling: false,
    ShowInvoiced: false
  });

  useEffect(() => {
    setInProgress(true);
    setOrderListInProgress(true);
    get(id)
      .then(function (response) {
        initializeFilterParams();
        setOrder(response);
        getLocations(response);
        getCustomerInvoiceCode(response);
        setInProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id]);

  useEffect(() => {    
    if (!inProgress) {
      getOrderCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, inProgress]);

  const initializeFilterParams = () => {
    let params = location?.state;
    params.amount = 10000;
    setListParams(location?.state);
  };

  function getLocations(orderResponse) {
    getLocation(orderResponse.OriginID)
      .then(function (response) {
        setOrder(c => ({ ...c, OriginCompanyName: response.CompanyName }));
        setOrder(c => ({ ...c, OriginAddress: response.Address }));
        setOrder(c => ({ ...c, OriginCity: response.City }));
        setOrder(c => ({ ...c, OriginState: response.Province }));
        setOrder(c => ({ ...c, OriginZip: response.PostalCode }));
        setOrder(c => ({ ...c, OriginCountry: response.Country }));
      });
    getLocation(orderResponse.DestinationID)
      .then(function (response) {
        setOrder(c => ({ ...c, DestinationCompanyName: response.CompanyName }));
        setOrder(c => ({ ...c, DestinationAddress: response.Address }));
        setOrder(c => ({ ...c, DestinationCity: response.City }));
        setOrder(c => ({ ...c, DestinationState: response.Province }));
        setOrder(c => ({ ...c, DestinationZip: response.PostalCode }));
        setOrder(c => ({ ...c, DestinationCountry: response.Country }));
      });
  };

  const getCustomerInvoiceCode = async (orderResponse) => {
    const response = await getInvoiceCode(orderResponse.CustomerInvCodeID);
    if (response) {
      setOrder(c => ({ ...c, CustomerInvCode: response.InvCode }));
    }
  };

  const getOrderCount = async () => {
    const response = await getIntermodalInvoiceList(listParams);
    if (response.length > 0){
      setOrderCount(response[0].TotalRows);
      var order = response.find(o => o.ID === parseInt(id));
      setOrderList(response);
      setOrderPosition(order.resultNum);
      setOrderListInProgress(false);
    }
  };

  const updateTabs = e => {
    const { value } = e.target;
    setTabSelected(value);
  };

  const nextInvoice = () => {
    if (orderPosition === orderCount) {
      history.push('/spark/intermodal-invoice');
      return;
    }
    var nextNumber = orderPosition + 1;
    setOrderPosition(nextNumber);
    var order = orderList.find(o => o.resultNum === nextNumber);
    history.push('/spark/intermodal-invoice/view/' + order.ID, listParams);
  };

  const previousInvoice = () => {
    if (orderPosition === 1){
      return;
    }
    var nextNumber = orderPosition - 1;
    setOrderPosition(nextNumber);
    var order = orderList.find(o => o.resultNum === nextNumber);
    history.push('/spark/intermodal-invoice/view/' + order.ID, listParams);
  };

  const setReadyForBilling = async () => {
    setInProgress(true);
    await readyForBilling(id);
    nextInvoice();
    setInProgress(false);
  };

  const setBillingOnHold = async () => {
    setInProgress(true);
    await billingOnHold(id);
    nextInvoice();
    setInProgress(false);
  };

  return (
    <div>
      <IntermodalInvoiceViewPageHeader
        title={'Order ' + order.OrderNum + ' Details'} 
        orderCount={orderCount}
        orderPosition={orderPosition}
        nextFunction={nextInvoice}
        previousFunction={previousInvoice}
        billingOnHoldFunction={setBillingOnHold}
        readyForBillingFunction={setReadyForBilling}
        inProgress={inProgress}
        listInProgress={orderListInProgress}
      />
      {inProgress && <LinearProgress />}
      {!inProgress &&
      <IntermodalInvoiceViewRevenueList
        intermodalOrderID={id}
      />
      }
      {!inProgress && 
      <IntermodalInvoiceViewTabBar
        id={id}
        order={order}
        getOrder={get}
        noteType={31} // new NoteType for Intermodal Order
        tabSelected={tabSelected}
        onChange={updateTabs}
      />}
    </div>
  );
}

export default IntermodalInvoiceView;