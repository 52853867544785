import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AddSvg } from '../assets/svg-icons/add@1x.svg';

const IconAdd = () => {
  return (
    <SvgIcon fontSize="small">
      <AddSvg />
    </SvgIcon>
  )
}
export default IconAdd;