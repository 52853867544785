import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import { getGeocode } from '../mileage/MileageService';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import IconLocationLight from '../icon/IconLocationLight';
import IconInternalName from '../icon/IconInternalName';
import IconLegalName from '../icon/IconLegalName';
import IconCity from '../icon/IconCity';
import IconState from '../icon/IconState';
import IconZipCode from '../icon/IconZipCode';
import IconCountry from '../icon/IconCountry';
import IconPhone from '../icon/IconPhone';
import IconLicensePlate from '../icon/IconLicensePlate';

const PersonnelViewAddressSection = (props) => {
  const {
    personnel,
    vendor
  } = props;
  const [coordinates, setCoordinates] = useState({ 'lat': 37, 'lng': -95 });

  useEffect(() => {
    getGeocode(
      personnel.Address, 
      personnel.City, 
      personnel.Province, 
      personnel.Country, 
      personnel.PostalCode)
      .then(function (response) {
        setCoordinates(response);
      });
  }, [personnel]);

  return (
    <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
      {personnel.DriverType !== 2 && 
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          <Grid item container spacing={2} xs={12}>
            <Grid item md={12} xs={12}>
              <h4>Address</h4>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="Address"
                  value={personnel.Address}
                  icon={
                    <IconLocationLight />
                  }
                />
              </Grid>
              {personnel.Country !== "Philippines" && <>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="City"
                    value={personnel.City}
                    icon={
                      <IconCity />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="State / Province"
                    value={personnel.Province}
                    icon={
                      <IconState />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Zip/Postal Code"
                    value={personnel.PostalCode}
                    icon={
                      <IconZipCode />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Country"
                    value={personnel.Country}
                    icon={
                      <IconCountry />
                    }
                  />
                </Grid>
              </>}
              {personnel.Country === "Philippines" && <>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="District"
                    value={personnel.District}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Barangay"
                    value={personnel.County}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Zip/Postal Code"
                    value={personnel.PostalCode}
                    icon={
                      <IconZipCode />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="City"
                    value={personnel.City}
                    icon={
                      <IconCity />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="State / Province"
                    value={personnel.Province}
                    icon={
                      <IconState />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Country"
                    value={personnel.Country}
                    icon={
                      <IconCountry />
                    }
                  />
                </Grid>
              </>}
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Primary Number"
                  value={personnel.HomePhone}
                  icon={
                    <IconPhone />
                  }
                  isPhoneNum={true}
                />
              </Grid>
              <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Secondary Number"
                  value={personnel.BusPhone}
                  icon={
                    <IconPhone />
                  }
                  isPhoneNum={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }
        </Grid>
      </Grid>
      }

    {personnel.DriverType === 2 && 
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          <Grid item container spacing={2} xs={12}>
            <Grid item md={12} xs={12}>
              <h4>Company Information</h4>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Company Name"
                  value={vendor.VendorInternalName}
                  icon={
                    <IconInternalName /> 
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Company Legal Name"
                  value={vendor.LegalName}
                  icon={
                    <IconLegalName /> 
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Federal ID Number/EIN"
                  value={vendor.FederalIDNum}
                  icon={
                    <IconLicensePlate /> 
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Payment Terms"
                  value={vendor.PaymentTerms}
                  icon={
                    <IconLicensePlate /> 
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="Address"
                  value={vendor.DisAddress}
                  icon={
                    <IconLocationLight />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Company City"
                  value={vendor.DisCity}
                  icon={
                    <IconCity />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="State / Province"
                  value={vendor.DisProvince}
                  icon={
                    <IconState />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Zip/Postal Code"
                  value={vendor.DisPostalCode}
                  icon={
                    <IconZipCode />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Country"
                  value={vendor.DisCountry}
                  icon={
                    <IconCountry />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Primary Number"
                  value={vendor.DisPhone}
                  icon={
                    <IconPhone />
                  }
                  isPhoneNum={true}
                />
              </Grid>
              <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Secondary Number"
                  value={vendor.DisTollFree}
                  icon={
                    <IconPhone />
                  }
                  isPhoneNum={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }
        </Grid>
      </Grid>
      }
    </Container>
  );
}

export default PersonnelViewAddressSection;