import { Route } from 'react-router-dom';
import NotificationsList from './NotificationsList';

const NotificationsRouter = () => {
  return (
    <>
      <Route exact path="/spark/notifications" component={NotificationsList} />
    </>
  );

}

export default NotificationsRouter;
