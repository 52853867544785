import React, { useEffect, useState } from 'react';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { getTerminalCharges } from '../order/OrderService';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreDateTimeField from '../core/CoreDateTimeField';
import { format } from 'date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import { usePaymentRequests, useCartRequests } from '@envasetechnologies/feepay-components';
import CoreTextField from '../core/CoreTextField';
import Validation from '../validation';

const NavigationToolbarAuthorizeTerminalFeesDialog = (props) => {
  const {
    open,
    onClose,
  } = props;

  const feePayPaymentRequests = usePaymentRequests();
  const cartRequests = useCartRequests();
  const [inProgress, setInProgress] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [amountDue, setAmountDue] = useState(0);
  const [draftNum, setDraftNum] = useState([]);
  const [billingTransactions, setBillingTransactions] = useState([]);
  const [buyMessage, setBuyMessage] = useState('');
  const [eventFormData, setEventFormData] = useState({
    containerNumber: '',
    payThroughDate: format(new Date(), 'MM/dd/yyyy HH:mm')
  });
  const [eventFormValidation, setEventFormValidation] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setEventFormValidation(buildValidation(eventFormData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFormData]);

  const updateEventFormData = e => {
    const { name, value } = e.target;
    eventFormData[name] = value;
    setEventFormData({ ...eventFormData });   
  };

  const closeDialog = () => {
    setEventFormData(c => ({ ...c, containerNumber: '' }));
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setDraftNum([]);
    setAmountDue(0);
    setBillingTransactions([]);
    setPaymentMethods([]);
    setBuyMessage('');
    setIsPaid(false);
  };

  const getTerminalChargesData = e => {
    resetDialog();
    setInProgress(true);
    setErrorMessage('');
    e.preventDefault();
    if (!new Validation().isValidObject(eventFormValidation)) {      
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    getTerminalCharges(eventFormData)
      .then(function(response) {
        setAmountDue(response.totalAmountDue ?? 0);
        setDraftNum(response.draftNbr);
        setBillingTransactions(response['argo:billingTransaction'] ? response['argo:billingTransaction'] : []);
        setPaymentMethods(response.PaymentMethods ?? []);
        setInProgress(false);
      });
  };

  const payTerminalFees = async() => {    
    setBuyMessage('');
    setInProgress(true);
    if (paymentMethods.OnAccountPaymentMethod) {
      await processQuickBuy();
    } else if (paymentMethods.ADRPaymentMethod) {
      await processAddToCart();
    }
    setInProgress(false);   
  };

  const processQuickBuy = async () => { 
    const body = {
      product: {
        externalId: draftNum,
        type: 'ICTSI_FEE_INVOICE',
        description: eventFormData.containerNumber
      },
      paymentMethod: {
        type: 'ICTSI_ON_ACCOUNT'
      }
    };
    const quickBuyResponse = await feePayPaymentRequests.requestQuickBuy(body);
    if (quickBuyResponse.status === 'TRANSACTION_COMPLETE') {
      setIsPaid(true);
      setBuyMessage('Terminal Charges have been successfully processed and have been added to your outstanding ICTSI account balance.');
    } else {
      setBuyMessage('There was an error processing your payment. Please try again.');
    }
  }

  const processAddToCart = async (appointmentResponse) => { 
    var productInput = { 
      id: '',
      externalId: draftNum,
      type: 'ICTSI_FEE_INVOICE',
      description: eventFormData.containerNumber,
      canRefresh: true,
      maxRefreshes: 10,
      initialDurationInMinutes: 1,
      refreshDurationInMinutes: 1
    };
    const addToCartResponse = await cartRequests.requestAddProductToCart(productInput);
    if (addToCartResponse.cartId) {
      setBuyMessage('Terminal Charges have been added to cart. Please proceed to checkout to complete payment.');
    } else {
      setBuyMessage('There was an error adding to cart. Please try again.');
    }
  };

  let columns = [
    {
      field: 'argo:TariffDescription',
      headerName: 'Item',
      align: 'left',
      flex: 2,
      sortable: false,
    },
    {
      field: 'argo:ItemQuantity',
      headerName: 'Quantity',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'argo:ItemRateBilled',
      headerName: 'Rate',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'argo:ItemTaxTotal',
      headerName: 'Tax',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'argo:ItemAmount',
      headerName: 'Amount',
      align: 'left',
      flex: 1,
      sortable: false
    }
  ];

  return(
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth={"md"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Authorize Terminal Fees</DialogTitle>
      {inProgress && <LinearProgress />}
      <div>
        <DialogContent>   
          <form onSubmit={getTerminalChargesData}>  
            <Grid container item xs spacing={2} className="mt-1 mb-1"> 
              <Grid item xs={6} className="mb-1">
                <CoreTextField
                  name="containerNumber"
                  label="Container Number"
                  value={eventFormData.containerNumber}
                  onChange={e => updateEventFormData(e)}
                  validationError={eventFormValidation.containerNumber}
                  isRequired={true}
                  autoFocus={true}
                />
              </Grid>
              <Grid item xs={4} className="mb-1">
                <CoreDateTimeField
                  name="payThroughDate"
                  label="Pay Through Date"
                  value={eventFormData.payThroughDate}
                  onChange={e => updateEventFormData(e)}
                  validationError={eventFormValidation.payThroughDate}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={2} className="mb-1">
                <Button variant="contained" color="primary" size="large" type="submit" onClick={getTerminalChargesData}>
                  Get Fees
                </Button>
              </Grid>
              {errorMessage && <Grid item md={12} xs={12}>
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Grid>}
              <Grid item xs={12}  className="mb-2">
                <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
                  <DataGrid
                    getRowId={(row) => row['argo:TariffID']} 
                    rows={billingTransactions}
                    columns={columns} 
                    hideFooter={true} 
                    autoHeight={true} 
                    pageSize={100} 
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableSelectionOnClick={true}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="Total Amount"
                  value={amountDue + " PHP"}
                />
              </Grid>
              {buyMessage !== '' && <Alert severity="info" className='mt-2'>{buyMessage}</Alert>}
            </Grid>
            <DialogActions>
              <Button onClick={closeDialog} className="grey">
                Close
              </Button>
              <Button onClick={payTerminalFees} disabled={inProgress || amountDue === undefined || amountDue === 0 || isPaid} className="grey">
                Pay
              </Button>
            </DialogActions>    
          </form>      
        </DialogContent>
      </div>
    </Dialog>
  )
  
}

const buildValidation = (eventFormData) => {
  const {
    containerNumber,
    payThroughDate
  } = eventFormData || {};
  const validation = new Validation();
  return {
    containerNumber: validation.isEmpty(containerNumber),
    payThroughDate: validation.isEmpty(payThroughDate),
  };
};

export default NavigationToolbarAuthorizeTerminalFeesDialog;