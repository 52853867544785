import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import ContactListColumnEditButton from './ContactListColumnEditButton';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import ContactListColumnFavoriteIcon from './ContactListColumnFavoriteIcon';
import { deleteContact } from './ContactService';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomerViewContactListSection = (props) => {
  const {
    contacts,
    getContacts,
    setContacts
  } = props;

  const [openSuccessCopy, setOpenSuccessCopy] = useState(false);

  const getCurrentFavorite = (contacts) => {
    let currentFavoriteContactID = "";
    for (let i = 0; i < contacts.length; i++) {
      if(contacts[i].isFav) {
        currentFavoriteContactID = contacts[i].ID;
      }
    }
    return currentFavoriteContactID;
  }

  let columns = [
    { 
      field: 'Name', 
      headerName: 'Name', 
      align: 'left', 
      flex: 1.5, 
      sortable: false
    },
    { 
      field: 'isFav', 
      headerName: 'Favorite', 
      align: 'left', 
      flex: 0.75, 
      sortable: false,
      renderCell: (params) => {
        const isFav = params.getValue("isFav") || false;
        return (
          <ContactListColumnFavoriteIcon
            isFavorite={isFav}
            contactID={params.getValue("ID")}
            currentFavorite={getCurrentFavorite(contacts)}
            contacts={contacts}
            getContacts={getContacts}
          />
        )
      }
    },
    { 
      field: 'Position', 
      headerName: 'Title', 
      align: 'left', 
      flex: 1, 
      sortable: false 
    },
    { 
      field: 'Email', 
      headerName: 'Email', 
      align: 'left', 
      flex: 1, 
      sortable: false
    },
    { 
      field: 'Phone', 
      headerName: 'Phone', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        return (
          <CoreIntlPhoneOutput value={params.getValue("Phone")} fontStyle={{fontSize: '14px'}} />
        )
      }
    },
    { 
      field: 'Extension', 
      headerName: 'Ext.', 
      align: 'left', 
      sortable: false,
      
    },
    { 
      field: 'Controls', 
      headerName: ' ', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const contactID = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
            <ContactListColumnEditButton
              contactID={contactID}
              getContacts={getContacts}
              currentFavorite={getCurrentFavorite(contacts)}
            />}
            {showControls &&
            <CoreListColumnDeleteButton
              reference={`${params.getValue("Name")} from Contacts`}
              deleteID={contactID}
              deleteFunction={deleteContact}
              getList={getContacts}
            />}
          </div>
        )
      }
    }
  ];

  const onContactRowEnter = (params) => {
    for (const contact of contacts) {
      if (contact.ID === params.row.ID) {
        contact.ShowControls = true;
      }
    }
    setContacts([ ...contacts ]);
  };

  const onContactRowLeave = (params) => {
    for (const contact of contacts) {
      if (contact.ID === params.row.ID) {
        contact.ShowControls = false;
      }
    }
    setContacts([...contacts]);
  };

  const onContactCellClick = (params) => {
    switch (params.field) {
      case 'Email':
        navigator.clipboard.writeText(params.formattedValue);
        setOpenSuccessCopy(true);
        break;
      case 'Phone':
        navigator.clipboard.writeText(params.formattedValue);
        setOpenSuccessCopy(true);
        break;
      default:
        break;
    }
  };

  const handleSnackBarClose = () => {
    setOpenSuccessCopy(false);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="munaGrid" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={contacts} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onContactRowEnter(e)}
        onRowLeave={e => onContactRowLeave(e)}
        onCellClick={e => onContactCellClick(e)}
      />
      <Snackbar open={openSuccessCopy} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          Content copied!
        </Alert>
      </Snackbar>
    </Container>
  );
}

CustomerViewContactListSection.propTypes = {
  contacts: PropTypes.array.isRequired,
  getContacts: PropTypes.func.isRequired
};

export default CustomerViewContactListSection;