import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconTrash from '../icon/IconTrash';
import QuickbooksSyncSuppressDialog from './QuickbooksSyncSuppressDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const QuickbooksSyncSuppressButton = (props) => {
  const {
    ID,
    suppressFunction,
    getList
  } = props;
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openSuppressModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className={classes.iconText} onClick={openSuppressModal}>
        <IconTrash />&nbsp;
        <span>SUPPRESS</span>
      </Button> 
      <QuickbooksSyncSuppressDialog
        ID={ID}
        suppressFunction={suppressFunction}
        getList={getList}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
}

QuickbooksSyncSuppressButton.propTypes = {
  ID: PropTypes.number.isRequired,
  suppressFunction: PropTypes.func.isRequired,
};

export default QuickbooksSyncSuppressButton;