import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

import { getProviderList } from '../envase_trace/EnvaseTraceService';

const EnvaseTraceProviderFieldDropdown = (props) => {
  const {
    label,
    name,
    value,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    async function getEnvaseTraceProviders() { 
      const result = await getProviderList();
      for(const provider of result) {
        if (provider.LocationName) {
          provider.SecondaryText = provider.LocationName;
        }
      }
      setProviders(result);
      setIsLoading(false);
    }
    getEnvaseTraceProviders();
  }, []);

  const onProviderSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue && newValue.ID ? newValue.ID : null,
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.ID === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {   
    const provider = providers
      .find(p => p.ID === value);
    if (provider) {
      return provider.Name;
    }
    return value ? value : '';
  };

  return (
    <>
      {!isLoading && <CoreAutocompleteField
        label={label}
        value={value}
        name={name}
        isRequired={isRequired}
        validationError={validationError}
        onChange={onProviderSelected}
        getDefaultValue={getDefaultValue}
        getOptionSelected={getOptionSelected}
        showSecondaryText={true}
        options={providers}
        freeSolo={true}
        {...rest}
      />
      }
    </>
  );

}

EnvaseTraceProviderFieldDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EnvaseTraceProviderFieldDropdown;