import React from 'react';

import CoreDeleteDialogContent from '../core/CoreDeleteDialogContent';

const FeePayShoppingCartItemsDeleteDialog = (props) => {
  const {
    itemID,
    deleteCartItem,
    reference,
    isOpen,
    onClose,
  } = props;

  const onDelete = e => {
    e.preventDefault();
    processDeleteCartItem();    
  };

  const processDeleteCartItem = async () => {
    await deleteCartItem(itemID);
    onClose();
  };

  return (
    isOpen &&
    <CoreDeleteDialogContent
      onDelete={onDelete}
      isOpen={isOpen}
      onClose={onClose}
      reference={reference}
    />
  );
}

export default FeePayShoppingCartItemsDeleteDialog;