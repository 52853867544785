import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import NoteEditDialogContent from '../notes/NoteEditDialogContent';
import { get, update } from '../notes/NotesService';

const NoteEditDialog = (props) => {
  const {
    noteID,
    isOpen,
    onClose
  } = props;

  const [noteFormData, setNoteFormData] = useState({});
  const [noteFormValidation, setNoteFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
        setNoteFormValidation(buildValidation(noteFormData));
    }
  }, [noteFormData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      get(noteID)
        .then(function(response) {
          setNoteFormData(response);
      });
    }
  }, [isOpen, noteID]);

  const updateNoteFormData = e => {
      const {name, value} = e.target;
      noteFormData[name] = value;
      setNoteFormData({ ...noteFormData});
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(noteFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(noteFormData);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    props.onClose();
  };

  return (
    isOpen && 
    <NoteEditDialogContent
        isOpen={isOpen}
        onClose={onClose}
        onUpdate={onUpdate}
        errorMessage={errorMessage}
        noteFormData={noteFormData}
        noteFormValidation={noteFormValidation}
        updateNoteFormData={updateNoteFormData}
    />
  );
}

const buildValidation = (noteFormData) => {
  const {
      Subject,
      Note
  } = noteFormData || {};
  const validation = new Validation();
  return {
      Subject: validation.isEmpty(Subject),
      Note: validation.isEmpty(Note)
  }
};

export default NoteEditDialog;