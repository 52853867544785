import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconPersonnel from '../icon/IconPersonnel';

const NavigationDrawerListItemPersonnel = () => {
  return (
    <NavigationDrawerListItem
      title={'Personnel'}
      icon={<IconPersonnel />}
      uri={'/spark/personnel'} />
  );

}

export default NavigationDrawerListItemPersonnel;