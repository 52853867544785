import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CustomerCreateDialogContentInfo from './CustomerCreateDialogContentInfo';
import CustomerCreateDialogContentAddress from './CustomerCreateDialogContentAddress';

const CustomerCreateDialogContent = (props) => {
  const {
    isOpen,
    resetForm,
    customerFormData,
    onCreate,
    errorMessage,
    customerFormValidation,
    updateCustomerFormData
  } = props;

  const submitForm = (e) => {
    onCreate(e);
  }

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form>      
        <DialogTitle id="form-dialog-title">Create Customer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <CustomerCreateDialogContentInfo
              customerFormData={customerFormData}
              customerFormValidation={customerFormValidation}
              updateCustomerFormData={updateCustomerFormData}
            />
            <br/>
            <CustomerCreateDialogContentAddress
              customerFormData={customerFormData}
              customerFormValidation={customerFormValidation}
              updateCustomerFormData={updateCustomerFormData}
            />
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={e => submitForm(e)}>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CustomerCreateDialogContent;