import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white',
    maxHeight: 70
  },
}));

const CoreSummaryHeader = (props) => {
  const classes = useStyles();

  const {
    title,
    createButton,
    isInverted,
    statusIcon
  } = props;

  return (
    <Paper
      variant="outlined"
      className={clsx({
        [classes.purpleBackground]: isInverted
      })}
      square>
      <Container
        maxWidth="xl"
        className="mt-2">
        <Typography variant="h6">
          {title}&nbsp;&nbsp;&nbsp;
          {statusIcon}
        </Typography>
        {createButton}
      </Container>
    </Paper>
  );
}

CoreSummaryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  createButton: PropTypes.element,
  isInverted: PropTypes.bool
};

export default CoreSummaryHeader;