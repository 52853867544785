import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import OrderAppointmentCancelDialog from './OrderAppointmentCancelDialog';

const OrderAppointmentCancelButton = (props) => {
    const {
        appointment,
        onAppointmentCancel,
        onClose
    } = props;

    const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);

    const openCancelAppointmentModal = () => {
        setIsCancelAppointmentModalOpen(true);
    };

    const closeCancelAppointmentModal = () => {
        onAppointmentCancel();
        setIsCancelAppointmentModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={openCancelAppointmentModal}
            >
                <span>Cancel</span>
            </Button>
            <OrderAppointmentCancelDialog
                isOpen={isCancelAppointmentModalOpen}
                onClose={closeCancelAppointmentModal}
                appointment={appointment}
                onAppointmentCancel={onAppointmentCancel}
            />
        </>
    )

}

export default OrderAppointmentCancelButton;