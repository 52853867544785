import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as TrashSvg } from '../assets/svg-icons/trash@1x.svg';

const IconTrash = () => {
  return (
    <SvgIcon fontSize="small">
      <TrashSvg />
    </SvgIcon>
  )
}
export default IconTrash;