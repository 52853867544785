import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CheckSvg } from '../assets/svg-icons/check@1x.svg'

const IconCheck = (props) => {

  const {
    fontSize = 'small'
  } = props;

  return (
    <SvgIcon fontSize={fontSize}>
      <CheckSvg />
    </SvgIcon>
  )
}

IconCheck.propTypes = {
  fontSize: PropTypes.string
};

export default IconCheck;