import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, IconButton } from '@material-ui/core';
import CoreOutlinedTextField from '../core/CoreOutlinedTextField';
import CoreDateTimeField from '../core/CoreDateTimeField';
import CoreDropdownField from '../core/CoreDropdownField';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(() => ({
    iconText: {
      fontSize: '0.875rem',
      color: 'black',
      fontWeight: 550,
      filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
    },
}));

const TruckVisitFilterRow = (props) => {
    const {
        filterData,
        updateFilterData,
        resetFilterRow,
        listWidth
      } = props;
    const classes = useStyles();

    return (
        <div>
            <Container maxWidth={listWidth} className="mb-2">
                <Grid container spacing={1}>
                    <Grid item md={11} xs={12}>
                        <Grid container spacing={2}>
                            {/* <Grid item md={2} xs={3}>
                                <CoreOutlinedTextField
                                    name={"truckVisitAppointmentId"}
                                    label="Truck Visit Appt ID"
                                    value={filterData.truckVisitAppointmentId}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid> */}
                            <Grid item md={2} xs={4}>
                                <CoreOutlinedTextField
                                    name={"tvaNumber"}
                                    label="Truck Visit Appt #"
                                    value={filterData.tvaNumber || ''}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <CoreOutlinedTextField
                                    name={"locationCode"}
                                    label="Location"
                                    value={filterData.locationCode || ''}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={3} xs={4}>
                                <CoreOutlinedTextField
                                    name={"tractorLicensePlateNumber"}
                                    label="Truck License Plate #"
                                    value={filterData.tractorLicensePlateNumber || ''}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={3} xs={4}>
                                <CoreOutlinedTextField
                                    name={"truckerDriverLicenseNumber"}
                                    label="Driver License #"
                                    value={filterData.truckerDriverLicenseNumber || ''}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <CoreOutlinedTextField
                                    name={"gateId"}
                                    label="Gate"
                                    value={filterData.gateId || ''}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <CoreDropdownField
                                    name={"truckVisitAppointmentStatus"}
                                    label="Status"
                                    value={filterData.truckVisitAppointmentStatus}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                    list={["Draft", "Created", "Cancelled", "Expired", "Departed"]}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <CoreDateTimeField
                                    name={"startDateTime"}
                                    label="Start Date/Time"
                                    value={filterData.startDateTime}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <CoreDateTimeField
                                    name={"endDateTime"}
                                    label="End Date/Time"
                                    value={filterData.endDateTime}
                                    onChange={e => updateFilterData(e)}
                                    variant={"outlined"}
                                    size={"small"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={12}>
                        <Grid container spacing={1} justify='flex-end'>
                            <IconButton className={classes.iconText} onClick={resetFilterRow}>
                                <HighlightOffIcon />&nbsp;<span>RESET</span>
                            </IconButton>
                        </Grid>
                    </Grid>    
                </Grid>        
            </Container>
        </div>
    );    
}

export default TruckVisitFilterRow;