import HttpService from "../http-service";

const token = localStorage.getItem('token');
const uri = 'Admin/' + token + '/';

export async function isOwner() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsOwner');
  return result;
};

export async function enableEnvase(personnelID) {
  const httpService = new HttpService();
  const params = {
    personnelID
  };
  const result = await httpService
    .post(uri + 'EnableEnvase', null, params);
  return result;
};

export async function disableEnvase(personnelID) {
  const httpService = new HttpService();
  const params = {
    personnelID
  };
  const result = await httpService
    .post(uri + 'DisableEnvase', null, params);
  return result;
};
