import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import OrderEquipmentEditDialog from './OrderEquipmentEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const OrderEquipmentListColumnEditButton = (props) => {
  const {
    orderEquipmentID,
    orderID,
    getEquipment
  } = props;

  const classes = useStyles();
  const [isOrderEquipmentEditModalOpen, setIsOrderEquipmentEditModalOpen] = useState(false);

  const openOrderEquipmentEditModal = () => {
    setIsOrderEquipmentEditModalOpen(true);
  };

  const closeOrderEquipmentEditModal = () => {
    setIsOrderEquipmentEditModalOpen(false);
    getEquipment();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openOrderEquipmentEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <OrderEquipmentEditDialog
        orderEquipmentID={orderEquipmentID}
        orderID={orderID}
        isOpen={isOrderEquipmentEditModalOpen}
        onClose={closeOrderEquipmentEditModal}
      />
    </>
  );
}

OrderEquipmentListColumnEditButton.propTypes = {
  orderEquipmentID: PropTypes.number.isRequired
};

export default OrderEquipmentListColumnEditButton;