import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'CompanyFeature/' + token + '/';

export async function getCompanyFeature() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get');
  return result.response;
};
