import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'LocationAreaTag/' + token + '/';

export async function get(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};

export async function getList(id) {
  var params = {
    locationTemplateID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList', params);
  return result.response;
};

export async function getAvailableAreaTags(id) {
  var params = {
    locationTemplateID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAvailableAreaTags', params);
  return result.response;
};

export async function applyAreaTags(id, areaTags) {
  var params = {
    locationTemplateID: id,
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ApplyAreaTags', areaTags, params);
  return result;
};

export async function removeAreaTags(id, areaTags) {
  var params = {
    locationTemplateID: id,
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'RemoveAreaTags', areaTags, params);
  return result;
};