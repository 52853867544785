import React, {useState, useEffect} from 'react';
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from "@material-ui/core/Switch";
import { LinearProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import IconRefresh from '../icon/IconRefresh';
import Alert from '@material-ui/lab/Alert';
import QuickbooksSyncSuppressButton from './QuickbooksSyncSuppressButton';

import { getInvoicesToCreate, createQuickbooksInvoice, areRevenueItemsMapped, suppressInvoice } from './QuickbooksService';

const useStyles = makeStyles(() => ({
  iconText: {
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);',
    '& svg': {
      fontSize: '26px'
    }
  },
}));

const QuickbooksSyncInvoiceList = (props) => {
  const [invoicesToSync, setInvoicesToSync] = useState([])
  const [syncAll, setSyncAll] = useState(true)
  const [inProgress, setInProgress] = useState(false);
  const [invoicesSentCount, setInvoicesSentCount] = useState(0);
  const [processedInvoicesCount, setProcessedInvoicesCount] = useState(0);
  const [isMapped, setIsMapped] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    getInvoices();
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  const getInvoices = async() => {
    setInProgress(true);
    const invoices = await getInvoicesToCreate();
    setInvoicesToSync(invoices);
    checkRevenueMapped();
  }

  const checkRevenueMapped = async() => {
    const result = await areRevenueItemsMapped();
    setInProgress(false);
    setIsMapped(result);
  };

  const toggleSync = (id) => {
    const invoicesIndex = invoicesToSync.findIndex(invoice => invoice.InvoiceID === id);
    let newArray = [...invoicesToSync];
    newArray[invoicesIndex] = {
      ...newArray[invoicesIndex],
      Sync: !newArray[invoicesIndex].Sync,
      Status: newArray[invoicesIndex].Sync ? "Ommit from sync" : "Ready to sync"
    }
    setInvoicesToSync(newArray);
  }

  const toggleSyncAll = () => {
    let newArray = [...invoicesToSync]
    newArray.map((data, i) => {
      if (syncAll === true) {
        return (newArray[i].Status = "Ommit from sync", newArray[i].Sync = false); 
      } else {
        return (newArray[i].Status = "Ready to sync", newArray[i].Sync = true);
      }
    });
    setInvoicesToSync(newArray);
    setSyncAll(!syncAll);
   };

  const columns = [
    { 
      field: 'InvoiceNum',
      headerName: 'Invoice',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            {params.getValue("InvoiceNum")}
          </div>
        )
      }
    },
    { 
      field: 'InternalName',
      headerName: 'Customer',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <div>{params.getValue("InternalName")}</div>
        )
      }
    },
    { 
      field: 'Status',
      headerName: 'Status',
      sortable: false,
      flex: 1.5,
      align: 'left',
      renderCell: (params) => {
        return (
          <div>{params.getValue("Status")}</div>
        )
      }
    },
    {
      field: '',
      headerName: '',
      headerAlign: 'right',
      sortable: false,
      flex: 0.5,
      align: 'right',
      renderCell: (params) => {
        const invoiceID = params.getValue("InvoiceID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div style={{ width:'100%',textAlign:'right' }}>
             {showControls &&
              <QuickbooksSyncSuppressButton
                ID={invoiceID}
                suppressFunction={suppressInvoice}
                getList={getInvoices}
            />}
            <Switch
              checked={params.getValue("Sync")}
              onClick={() => toggleSync(params.getValue("InvoiceID"))}
              inputProps={{ 'aria-label': 'controlled' }}
              color="primary"
            />
          </div>
        )
      }
    }, 
  ];

  const onRowEnter = (params) => {
    for (const invoice of invoicesToSync) {
      if (invoice.InvoiceID === params.row.InvoiceID) {
        invoice.ShowControls = true;
      }
    }
    setInvoicesToSync([ ...invoicesToSync ]);
  };

  const onRowLeave = (params) => {
    for (const invoice of invoicesToSync) {
      if (invoice.InvoiceID === params.row.InvoiceID) {
        invoice.ShowControls = false;
      }
    }
    setInvoicesToSync([...invoicesToSync]);
  };

  const syncQuickbooksInvoices = async () => { 
    setInProgress(true);
    var array = [...invoicesToSync];
    invoicesToSync.forEach(invoice => { 
      if (invoice.Sync && invoice.CustRemoteID)  {
        syncQuickbooksInvoice(invoice, array);
        setInvoicesSentCount(invoicesSentCount + 1);
      }
    });
    for (const invoice of invoicesToSync) {
      if (invoice.Sync && invoice.CustRemoteID === null ) {
        await syncQuickbooksInvoice(invoice, array);
        setInvoicesSentCount(invoicesSentCount + 1);
      }
    }
    if (invoicesSentCount === processedInvoicesCount) {
      setInProgress(false);
    }
  };

  async function syncQuickbooksInvoice (invoice, array) {
    var response = await createQuickbooksInvoice(invoice);
    setProcessedInvoicesCount(processedInvoicesCount + 1);
    var index = array.indexOf(invoice);
    if (response.errors.Error) {
      if (index !== -1) {
        array[index].Status = response.errors.Error;
        setInvoicesToSync([...array]);
      }
    } else {     
      if (index !== -1) {
        array.splice(index, 1);
        setInvoicesToSync([...array]);
      }
    }
  };

  return (
    <>
      {!isMapped && <Alert className='mt-2' severity="warning">{"There are unmapped revenue items. Please map the rest of the Local Revenue Items in the Configure tab."}</Alert>}
      <Paper className="mt-2 munaGrid" elevation={1} square={true} align="center">
        {inProgress && <LinearProgress />}
        <div style={{padding:"20px 15px", textAlign:"left", display:"flex", alignItems:"center"}}>
          <Typography variant="subtitle1">
            Invoices
          </Typography>
          <div style={{ width:'100%',textAlign:'right' }}>
            <Tooltip title="Sync Selected Invoices" placement="top">
              <IconButton className={classes.iconText} onClick={syncQuickbooksInvoices} disabled={inProgress}>
                <IconRefresh />
              </IconButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Select all Invoices to sync" placement="top">
              <Switch
                checked={syncAll}
                onClick={() => toggleSyncAll()}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </Tooltip>
          </div>
        </div>
        <div style={{width: '100%'}}>
          <DataGrid
            rows={invoicesToSync}
            columns={columns}
            getRowId={(row) => row.InvoiceID}
            hideFooter={true} 
            autoHeight={true} 
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            onRowEnter={e => onRowEnter(e)}
            onRowLeave={e => onRowLeave(e)}
          />
        </div>
      </Paper>
    </>
  );
}

export default QuickbooksSyncInvoiceList;
