import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AppointmentBookSvg } from '../assets/svg-icons/appointment-book@1x.svg';

const IconAppointmentBook = () => {
  return (
    <SvgIcon fontSize="small">
      <AppointmentBookSvg />
    </SvgIcon>
  )
}
export default IconAppointmentBook;