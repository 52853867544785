import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import CoreTextField from '../core/CoreTextField';
import CountryField from '../country/CountryField';
import ProvinceField from '../province/ProvinceField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CorePhoneField from '../core/CorePhoneField';
import EnvaseTraceProviderFieldDropdown from '../envase_trace/EnvaseTraceProviderFieldDropdown';
import LocationTypeField from './LocationTypeField';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';

const LocationEditContentInfo = (props) => {
  const {
    locationFormData,
    locationFormValidation,
    updateLocationFormData,
    coordinates,
    traceCredentials,
    initialProviderID
  } = props;

  const [branch, setBranch] = useState('');

  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };

  return (
    <div>
      <Container maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h4>Location Information</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <CoreTextField
              name="InternalName"
              label="Internal Name"
              value={locationFormData.InternalName}
              onChange={e => updateLocationFormData(e)}
              isRequired={true}
              validationError={locationFormValidation.InternalName}
              autoFocus={true}
              maxLength={255}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <CoreTextField
              name="CompanyName"
              label="Company Name"
              value={locationFormData.CompanyName}
              onChange={e => updateLocationFormData(e)}
              isRequired={true}
              validationError={locationFormValidation.CompanyName}
              maxLength={255}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <EnvaseTraceProviderFieldDropdown
              name="EnvaseTraceProviderID"
              label="Trace Provider"
              value={locationFormData.EnvaseTraceProviderID}
              onChange={e => updateLocationFormData(e)}
              isRequired={false}
              validationError={locationFormValidation.EnvaseTraceProviderID}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <LocationTypeField
              name="Type"
              label="Type"
              value={locationFormData.Type}
              onChange={e => updateLocationFormData(e)}
              isRequired={false}
            />
          </Grid>
          {locationFormData.EnvaseTraceProviderID !== null && !traceCredentials.IsEnabled  && <Grid item md={12} xs={12} className="mb-2">
            <Alert severity="warning">{"Trace Provider credentials do not exist, contact your admin to update"}</Alert>
          </Grid>}
          {locationFormData.EnvaseTraceProviderID !== null && locationFormData.EnvaseTraceProviderID === initialProviderID && traceCredentials.IsEnabled && <Grid item md={12} xs={12} className="mb-2">
            <Alert severity="success">{"Trace Provider linked"}</Alert>
          </Grid>}
        </Grid>
      </Container>
      <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className="mb-2">
            <Grid container spacing={2}>   
              {(locationFormData.Country !== "Philippines" && branch.Country !== "Philippines") && <>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="Address"
                    label="Address"
                    value={locationFormData.Address}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.Address}
                    maxLength={255}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="City"
                    label="City"
                    value={locationFormData.City}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.City}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <ProvinceField
                    name="Province"
                    label="State / Province"
                    value={locationFormData.Province}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.Province}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="PostalCode"
                    label="ZIP / Postal Code"
                    value={locationFormData.PostalCode}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.PostalCode}
                    maxLength={15}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CountryField
                    label="Country"
                    name="Country"
                    value={locationFormData.Country}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.Country}
                  />
                </Grid>
              </>}
              {(locationFormData.Country === "Philippines" || branch.Country === "Philippines") && <>
              <Grid item md={6} xs={6}>
                  <CountryField
                    label="Country"
                    name="Country"
                    value={locationFormData.Country}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.Country}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <ProvinceField
                    name="Province"
                    label="State / Province"
                    value={locationFormData.Province}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.Province}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="City"
                    label="City"
                    value={locationFormData.City}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.City}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="PostalCode"
                    label="ZIP / Postal Code"
                    value={locationFormData.PostalCode}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.PostalCode}
                    maxLength={15}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="District"
                    label="District"
                    value={locationFormData.District}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.District}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="County"
                    label="Barangay"
                    value={locationFormData.County}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.County}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="address"
                    label="Address"
                    value={locationFormData.Address}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.Address}
                    maxLength={255}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
              </>}
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Primary Number"
                  name="Phone"
                  value={locationFormData.Phone}
                  isRequired={false}
                  validationError={locationFormValidation.Phone}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Fax"
                  name="Fax"
                  value={locationFormData.Fax}
                  isRequired={false}
                  validationError={locationFormValidation.Fax}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Secondary Number"
                  name="TollFree"
                  value={locationFormData.TollFree}
                  isRequired={false}
                  validationError={locationFormValidation.TollFree}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
            </Grid>              
          </Grid>
          <Grid item md={6} xs={12}>
            {coordinates &&
              <CoreHereDisplayMap
                coordinates={coordinates}
              />
            }           
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}

export default LocationEditContentInfo;