import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create as customerCreate } from './CustomerService';
import CustomerCreateDialogContent from './CustomerCreateDialogContent';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { getPersonnelID } from '../account/AccountService';
import { getInternalName } from '../account/AccountService';

const CustomerCreateDialog = (props) => {

  const {
    isOpen,
    onClose,
    persistedCustomerName
  } = props;

  const initialData = {
    internalName: '',
    legalName: '',
    email: '',
    accountRep: '',
    accountRepID: null,
    isBOLDocument: false,
    creditOtherReport: false,
    isHazmat: false,
    creditTradeChecked: false,
    creditelReport: false,
    creditBankChecked: false,
    branchID: null,
    salesRepID: null,
    podRequirements: 0,
    invoiceTransmitMethod: 0,
    custAddress: '',
    custCity: '',
    custProvince: '',
    custPostalCode: '',
    custCountry: '',
  }

  const [customerFormData, setCustomerFormData] = useState(initialData);
  const [customerFormValidation, setCustomerFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    initializeState()
  }, [setCustomerFormData]);

  useEffect(() => {
    if (isOpen) {
      setCustomerFormValidation(buildValidation(customerFormData));
    }
  }, [customerFormData, isOpen]);

  useEffect(() => { 
    setCustomerFormData(c => ({ ...c, internalName: persistedCustomerName || '' }));
  }, [isOpen, persistedCustomerName]);

  const initializeState = (() => {
    async function setDefaultAccountRep() {
      const accountRep = await getInternalName();
      setCustomerFormData(c => ({ ...c, accountRep: accountRep }));
    }
    async function setDefaultAccountRepID() {
      const accountRepID = await getPersonnelID();
      setCustomerFormData(c => ({ ...c, accountRepID: accountRepID }));
    }
    async function setDefaultSalesRepID() {
      const salesRepID = await getPersonnelID();
      setCustomerFormData(c => ({ ...c, salesRepID: salesRepID }));
    }
    async function setDefaultBranchID() {
      const branchID = await getMainBranchID();
      setCustomerFormData(c => ({...c, branchID: branchID}));
    }
    setDefaultAccountRep();
    setDefaultAccountRepID();
    setDefaultBranchID();
    setDefaultSalesRepID();
  });

  const updateCustomerFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    customerFormData[name] = value;
    customerFormData[nameData] = valueData;
    setCustomerFormData({ ...customerFormData });
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(customerFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }    
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await customerCreate(customerFormData);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    resetForm(parseInt(result.response), customerFormData.internalName);
  };

  const resetForm = (response, customerName) => {
    setCustomerFormData(initialData);
    initializeState();
    if (response) { 
      onClose(response, customerName);
    } else {  
      onClose();
    }
  }

  return (
    isOpen &&
    <CustomerCreateDialogContent
      isOpen={isOpen}
      resetForm={resetForm}
      onCreate={onCreate}
      errorMessage={errorMessage}
      customerFormData={customerFormData}
      customerFormValidation={customerFormValidation}
      updateCustomerFormData={updateCustomerFormData}
      />
  );
}

const buildValidation = (customerFormData) => {
  const { 
    internalName,
    legalName,
    accountRepID
  } = customerFormData || {};
  const validation = new Validation();
  return {
    internalName: validation.isEmpty(internalName),
    legalName: validation.isEmpty(legalName),
    accountRepID: validation.isNumber(accountRepID)
  };
};

export default CustomerCreateDialog;