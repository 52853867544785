import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import NoteTextAreaField from '../notes/NoteTextAreaField';
import EmailTemplateDocTypeField from './EmailTemplateDocTypeField';
import EmailTemplateSmartTagButton from './EmailTemplateSmartTagButton';

const EmailTemplateEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    emailTemplateFormData,
    onUpdate,
    errorMessage,
    emailTemplateFormValidation,
    updateEmailTemplateFormData,
    inProgress,
    emailSmartTags,
    onSmartTagButtonClick,
    emailBodyClicked,
    emailSubjectClicked
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Email Template</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <EmailTemplateDocTypeField
                name="DocType"
                label="Document Type"
                value={emailTemplateFormData.DocType}
                nameData="DocTypeID"
                valueData={emailTemplateFormData.DocTypeID}
                onChange={e => updateEmailTemplateFormData(e)}
                isRequired={true}
                validationError={emailTemplateFormValidation.DocType}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Subject"
                label="Subject"
                value={emailTemplateFormData.Subject}
                onChange={e => updateEmailTemplateFormData(e)}
                isRequired={true}
                validationError={emailTemplateFormValidation.Subject}
                onLeaveEvent={emailSubjectClicked}
              />
            </Grid>
            <Grid item md={12} xs={12}>
                <NoteTextAreaField
                  name="Body"
                  value={emailTemplateFormData.Body}
                  onChange={e => updateEmailTemplateFormData(e)}
                  isRequired={true}
                  validationError={emailTemplateFormValidation.Body}
                  maxLength={4000}
                  onLeaveEvent={emailBodyClicked}
                />
            </Grid>
            <Grid item md={12} xs={12}>
              {emailSmartTags.map((smartTag) => (
                <span style={{display: 'inline-block'}}>
                  <EmailTemplateSmartTagButton
                    value={smartTag}
                    onButtonClick={onSmartTagButtonClick}
                  />
                </span>
              ))}
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default EmailTemplateEditDialogContent;