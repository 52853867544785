import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import { getList } from '../equipment_type/EquipmentTypeService';
import EquipmentTypeCreateDialog from '../equipment_type/EquipmentTypeCreateDialog';
import ConfigurationViewEquipmentTypeSectionListTable from './ConfigurationViewEquipmentTypeSectionListTable';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewEquipmentTypeSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;

  const [inProgress, setInProgress] = useState(true);
  const [isEquipmentTypeCreateDialogOpen, setIsEquipmentTypeCreateDialogOpen] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  useEffect(() => {
      getList()
        .then(function (response) {
          for (const equipmentType of response) {
            equipmentType.Name = equipmentType.FullPath;
          }
          setEquipmentTypes(response);
          setInProgress(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getEquipmentTypesList = () => {
    getList()
      .then(function (response) {
        for (const equipmentType of response) {
          equipmentType.Name = equipmentType.FullPath;
        }
        setEquipmentTypes(response);
      });
  };

  const openEquipmentTypeCreateDialog = () => {
    setIsEquipmentTypeCreateDialogOpen(true);
  };

  const closeEquipmentTypeCreateDialog = () => {
    setIsEquipmentTypeCreateDialogOpen(false);
    getEquipmentTypesList();
  };
    
  return (
    <div>
      {inProgress && <LinearProgress />}
        <Paper variant="outlined" square className="mt-1">
          {isOpen &&
            <Container maxWidth="lg" className="mt-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Equipment Types"}
                <span style={rightFloat}>
                  <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Equipment Type Configuration Settings"></CoreExpandLessButton>
                </span>
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {"Here you can add, edit and delete equipment types for the tractor and trailer unit types"}
                <span style={rightFloat}>
                </span>
              </Typography>
              <ConfigurationViewEquipmentTypeSectionListTable
                equipmentTypes={equipmentTypes}
                getEquipmentTypes={getEquipmentTypesList}
                setEquipmentTypes={setEquipmentTypes}
                handleOpenCreate={openEquipmentTypeCreateDialog}
              />
            </Container>
          }
          {isOpen &&
            <DialogActions style={justifyContent} className="mb-1">
            </DialogActions>
          }
          {!isOpen &&
            <Container maxWidth="lg" className="mt-2 mb-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Equipment Types"}
                <span style={rightFloat}>
                  <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Equipment Type Settings"></CoreExpandMoreButton>
                </span>
              </Typography>
            </Container>
          }
        </Paper>
        <EquipmentTypeCreateDialog
          isOpen={isEquipmentTypeCreateDialogOpen}
          onClose={closeEquipmentTypeCreateDialog}
        />
    </div>
  )
}

export default ConfigurationViewEquipmentTypeSection
