import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import { get as getLocation } from '../location/LocationService';
import { getGeocode } from '../mileage/MileageService';
import IconLocationLight from '../icon/IconLocationLight';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px',
      color: '#333333'
    }
  },
  mapContainer: {
    height: '180px',
    margin: '5px 0',
    backgroundColor:'#F8F8F8'
  }
}));

const OrderViewSummaryLocationSection = (props) => {
  const classes = useStyles();

  const { id, label } = props;

  const [coordinates, setCoordinates] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if(!location) {
      getLocation(id).then(function(response) {
        setLocation(response);        
      });
    }
    if(location) {
      getGeocode(
        location.Address, 
        location.City, 
        location.Province, 
        location.Country, 
        location.PostalCode)
      .then(function(response) {
        setCoordinates(response);
      });
    }
  }, [location, id]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title={label} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px', fontWeight: 'bold'}} className={classes.iconSize}>
            {location && <CoreTextDisplayField
              label={location.CompanyName}
              labelStyle={{color: '#333333'}}
              icon={<IconLocationLight />}            
            />}
          </Grid>
          <Grid item xs={12} style={{fontSize: '14px', paddingLeft: '45px', marginTop: '-10px'}}>
            {location && location.Address && <CoreTextDisplayField
              label={location.Address}
              labelStyle={{color: '#333333'}}        
            />}
          </Grid>
          <Grid item xs={12} style={{fontSize: '14px', paddingLeft: '45px', marginTop: '-10px'}}>
            {location && (location.City || location.Province) && <CoreTextDisplayField
              label={location.City ? location.City + ", " + location.Province : location.Province}
              labelStyle={{color: '#333333'}}        
            />}
          </Grid>
          <Grid item xs={12} className={classes.mapContainer}>
            {coordinates &&
              <CoreHereDisplayMap
                coordinates={coordinates}
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderViewSummaryLocationSection;
