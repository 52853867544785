import EquipmentListTable from './EquipmentListTable';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import EquipmentCreateButton from './EquipmentCreateButton';

const EquipmentList = () => {
  return (
    <div>
      <CorePageHeader
        title="Equipment"
        breadCrumb="Home / ... / Equipment List"
        createButton= {
          <EquipmentCreateButton />
        }
        />
      <Container maxWidth="false" className="mt-4">
        <EquipmentListTable />
      </Container>
    </div>
  );

}

export default EquipmentList;