import React, { useState } from 'react';
import Validation from '../validation';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import AuthenticationPage from './AuthenticationPage';
import {generatePasswordReset} from './Authentication.service';

function AuthenticationResetPassword() {

  const [username, setUsername] = useState('');
  const [isResetSent, setIsResetSent] = useState(false);

  const handleDataChange = e => {
    const { value } = e.target;
    setUsername(value);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (!new Validation().isEmpty(username)){
      await generatePasswordReset(username);
      setIsResetSent(true);
    }
  };

  const resetPasswordForm =
    <div>
      <Typography variant="h5" gutterBottom className="mt-2">
        Reset Password
      </Typography>
      {isResetSent &&
        <Grid container>
          <Grid item md={12}>
            <Typography gutterBottom>
              Check your email for a link to reset your password.
              If it doesn't appear within a few minutes, check your spam folder or you may not have an account.
            </Typography>
          </Grid>
          <Grid item md={12} className="mt-3">
            <Button variant="contained" color="primary" size="large" href="/login">
              Return to sign in
            </Button>
          </Grid>
        </Grid>
      }
      {!isResetSent && 
        <Typography gutterBottom>
          Please enter your Envase Username in the space below and press the 'Send Email' button.
        </Typography>
      }
      { !isResetSent && 
        <form onSubmit={handleSubmit} className="mt-2">
          <Grid container>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-password">
                  Username
                </InputLabel>
                <Input
                  name="username"
                  type="text"
                  value={username}
                  onChange={handleDataChange}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12} className="mt-3">
              <Button type="submit" variant="contained" color="primary" size="large">
                Send Email
              </Button>
            </Grid>
            {/* {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>} */}
            <Grid item md={12} xs={12} className="mt-3">
              <Typography gutterBottom>
                Remember Password?&nbsp;
                <Link href="/login">
                  Sign In
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      }
    </div>

  return (
    <AuthenticationPage form={resetPasswordForm} />
  );

}

export default AuthenticationResetPassword;