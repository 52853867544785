import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import { updateAcctSyncRevenueItem as update, getAcctSyncRevenueItem as get } from './QuickbooksService';
import QuickbooksMappedRevenueEditDialogContent from './QuickbooksMappedRevenueEditDialogContent';
import { get as getReceivableChargeType } from '../receivable_charge_type/ReceivableChargeTypeService';

const QuickbooksMappedRevenueEditDialog = (props) => {
  const {
    acctSyncXactID,
    isOpen,
    onClose
  } = props;

  const [quickbooksRevenueFormData, setQuickbooksRevenueFormData] = useState({
    AcctSyncXactID: acctSyncXactID,
    RevenueItemName: '',
    LocalID: null,
    RemoteName: '',
    RemoteID: '',
  });
  const [quickbooksRevenueFormValidation, setQuickbooksRevenueFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      get(acctSyncXactID)
      .then(function(response) {        
        setQuickbooksRevenueFormData({ ...response });
        getReceivableChargeTypeName(response);
      });    
    }
  }, [isOpen, acctSyncXactID]);

  useEffect(() => {
    if (isOpen) {
      setQuickbooksRevenueFormValidation(buildValidation(quickbooksRevenueFormData));
    }
  }, [quickbooksRevenueFormData, isOpen]);

  const getReceivableChargeTypeName = (response) => {
    getReceivableChargeType(response.LocalID)
      .then(function(result) {
        setQuickbooksRevenueFormData(c => ({ ...c, RevenueItemName: result.Name }));
      });   
  };

  const updateFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    quickbooksRevenueFormData[name] = value;
    quickbooksRevenueFormData[nameData] = valueData;
    setQuickbooksRevenueFormData({ ...quickbooksRevenueFormData });
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(quickbooksRevenueFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(quickbooksRevenueFormData);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    props.onClose();
  };

  return (
    isOpen && 
    <QuickbooksMappedRevenueEditDialogContent
        isOpen={isOpen}
        onClose={onClose}
        onUpdate={onUpdate}
        errorMessage={errorMessage}
        quickbooksRevenueFormData={quickbooksRevenueFormData}
        quickbooksRevenueFormValidation={quickbooksRevenueFormValidation}
        updateFormData={updateFormData}
    />
  );
}

const buildValidation = (quickbooksRevenueFormData) => {
  const {
    LocalID,    
    RemoteID,
  } = quickbooksRevenueFormData || {};
  const validation = new Validation();
  return {
    LocalID: validation.isNumber(LocalID),
    RemoteID: validation.isEmpty(RemoteID),
  }
};

export default QuickbooksMappedRevenueEditDialog;