import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getCarriersList } from './CarrierService';

const CarrierListField = (props) => {
  const {
    label,
    value,
    name,
    nameData,
    valueData,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [carrierList, setCarrierList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getCarriers() {
      const result = await getCarriersList();
      setCarrierList(result);
      setIsLoading(false);
    }
    getCarriers();
  }, []);

  const onCarrierSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.InternalName : '',
        nameData: nameData,
        valueData: newValue ? newValue.ID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.InternalName === value) {
      return option.InternalName;
    }
  };

  const getDefaultValue = () => {
    const carrier = carrierList
      .find(carrier => carrier.InternalName === value);
    if (carrier) {
      return carrier.InternalName;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onCarrierSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={carrierList}
      {...rest}
    />
  );

}

CarrierListField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default CarrierListField;