import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import LocationEditContentInfo from './LocationEditContentInfo';
import LocationEditContentOtherInfo from './LocationEditContentOtherInfo';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';

const LocationEditContent = (props) => {
  const {
    locationFormData,
    onSave,
    onClose,
    errorMessage,
    locationFormValidation,
    updateLocationFormData,
    coordinates,
    inProgress,
    traceCredentials,
    initialProviderID
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  return (
    <form onSubmit={onSave}>
      <LocationEditContentInfo
        locationFormData={locationFormData}
        locationFormValidation={locationFormValidation}
        updateLocationFormData={updateLocationFormData}
        coordinates={coordinates}
        traceCredentials={traceCredentials}
        initialProviderID={initialProviderID}
      />
      <br/>
      <LocationEditContentOtherInfo
        locationFormData={locationFormData}
        locationFormValidation={locationFormValidation}
        updateLocationFormData={updateLocationFormData}
      />
      {
        errorMessage && 
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons 
        changesDetails={"Location " + locationFormData.InternalName + " details"}
        onClose={onClose}
      />}
    </form>
  );
}

export default LocationEditContent;