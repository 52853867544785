import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory } from 'react-router-dom';
import Validation from '../validation';
import { create as locationCreate } from './LocationService';
import LocationCreateContent from './LocationCreateContent';
import CoreExitButton from '../core/CoreExitButton';
import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getCredentialByID } from '../envase_trace/EnvaseTraceService';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';

const LocationCreate = () => { 
  let history = useHistory();

  const [inProgress, setInProgress] = useState(false);
  const [locationFormData, setLocationFormData] = useState({
    internalName: '',
    companyName: '',
    address: '',
    city: '',
    postalCode: '',
    province: '',
    country: '',
    loadingInfo: '',
    receivingInfo: '',
    notes: '',
    phone: '',
    tollFree: '',
    signatureRequired: 1,
    envasetraceproviderID: null,
    type: ''
  });
  const [locationFormValidation, setLocationFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [coordinates, setCoordinates] = useState({'lat': 0, 'lng': 0});
  const [traceCredentials, setTraceCredentials] = useState({});

  useEffect(() => { 
    setBranchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBranchDetails = async () => { 
    const mainBranchID = await getMainBranchID();
    const branch = await getBranch(mainBranchID);
    locationFormData.country = branch.Country;
    setLocationFormData({ ...locationFormData });
  };

  useEffect(() => {
    setLocationFormValidation(buildValidation(locationFormData));
  }, [locationFormData]);

  useEffect(() => {
    getGeocode(
      locationFormData.address, 
      locationFormData.city, 
      locationFormData.province, 
      locationFormData.country, 
      locationFormData.postalCode) 
    .then(function(response) {
      setCoordinates(response);
   });
  }, [
    locationFormData.address, 
    locationFormData.city, 
    locationFormData.province, 
    locationFormData.country, 
    locationFormData.postalCode
  ]);

  useEffect(() => { 
    if (locationFormData.envasetraceproviderID) { 
      getCredentialByID(locationFormData.envasetraceproviderID)
        .then(function(response) { 
          setTraceCredentials(response);
        });
    }
  }, [locationFormData.envasetraceproviderID]);

  const updateLocationFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    locationFormData[name] = value;
    locationFormData[nameData] = valueData;
    setLocationFormData({ ...locationFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(locationFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await locationCreate(locationFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    // Redirect to the location view page
    history.push('/spark/location/view/' + result.response);
  };

  const onClose = () => {
    history.push('/spark/location');
  };

  return (
    <div>
      <CorePageHeader
        title="Create New Location"
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />      
      <Container maxWidth="lg" className="mt-4"> 
        {inProgress && <LinearProgress />}
        <LocationCreateContent
          onCreate={onCreate}
          onClose={onClose}
          errorMessage={errorMessage}
          locationFormData={locationFormData}
          locationFormValidation={locationFormValidation}
          updateLocationFormData={updateLocationFormData}
          coordinates={coordinates}
          inProgress={inProgress}
          traceCredentials={traceCredentials}
        />
      </Container>
    </div>
  );
}

const buildValidation = (locationFormData) => {
  const { 
    internalName,
    companyName,
    country,
    province
  } = locationFormData || {};
  const validation = new Validation();
  return {
    internalName: validation.isEmpty(internalName),
    companyName: validation.isEmpty(companyName),
    country: validation.isEmpty(country),
    province: validation.isEmpty(province)
  };
};

export default LocationCreate;