import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EventBusy from '@material-ui/icons/EventBusy';
import IncomingLoadListRejectDialog from './IncomingLoadListRejectDialog';

const IncomingLoadListRejectButton = (props) => {
  const {
    shipperPortalOrderAssignmentID,
    rejectFunction,
    getList,
    reference
  } = props;
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const openRejectModal = () => {
    setIsRejectModalOpen(true);

  };

  const closeRejectModal = () => {
    setIsRejectModalOpen(false);
  };

  return (
    <>
      <IconButton title="Reject Order" onClick={openRejectModal}>
        <EventBusy color='error'/>
      </IconButton>
      <IncomingLoadListRejectDialog
        shipperPortalOrderAssignmentID={shipperPortalOrderAssignmentID}
        reference={reference}
        rejectFunction={rejectFunction}
        getList={getList}
        isOpen={isRejectModalOpen}
        onClose={closeRejectModal}
      />
    </>
  );
}

IncomingLoadListRejectButton.propTypes = {
  shipperPortalOrderAssignmentID: PropTypes.number.isRequired,
  rejectFunction: PropTypes.func.isRequired,
};

export default IncomingLoadListRejectButton;