import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import { get } from './DocumentTypeService';

import DocumentTypeEditDialog from './DocumentTypeEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const DocumentTypeListColumnEditButton = (props) => {
  const {
    documentTypeID,
    getDocumentTypes
  } = props;

  const classes = useStyles();
  const [documentTypeFormData, setDocumentTypeFormData] = useState({});
  const [isDocumentTypeEditModalOpen, setIsDocumentTypeEditModalOpen] = useState(false);

  async function openDocumentTypeEditModal() {
    const result = await get(documentTypeID);
    setDocumentTypeFormData(result);
    setIsDocumentTypeEditModalOpen(true);
  };

  const closeDocumentTypeEditModal = () => {
    setIsDocumentTypeEditModalOpen(false);
    getDocumentTypes();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openDocumentTypeEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <DocumentTypeEditDialog
        documentTypeID={documentTypeID}
        isOpen={isDocumentTypeEditModalOpen}
        onClose={closeDocumentTypeEditModal}
        documentTypeFormData={documentTypeFormData}
        setDocumentTypeFormData={setDocumentTypeFormData}
      />
    </>
  );
}

DocumentTypeListColumnEditButton.propTypes = {
  documentTypeID: PropTypes.number.isRequired
};

export default DocumentTypeListColumnEditButton;