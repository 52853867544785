import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import LocationField from '../location/LocationField';
import EnvaseTraceProviderFieldDropdown from '../envase_trace/EnvaseTraceProviderFieldDropdown';
import { getCredentialByID } from '../envase_trace/EnvaseTraceService';

const useStyles = makeStyles(() => ({
  mapContainer: {
    position: 'relative', 
    right:'-16px'
  },
  providerFieldOffset: { 
    height: '48px'
  }
}));

const OrderEditContentLocation = (props) => {
  const classes = useStyles();
  const {
    name, 
    value,
    locationName,
    validationError,
    updateOrderFormData,
    headerLabel,
    isAbleToEditLocation,
    orderType,
    isOriginLocation = false,
    initialProviderID = null
  } = props;

  const [locationData, setLocationData] = useState({
    Address: '',
    City: '',
    Province: '',
    PostalCode: '',
    Country: '',
    Latitude: '123123',
    Longitude: null,
    EnvaseTraceProviderID: null
  });
  const [locationCoordinates, setLocationCoordinates] = useState({'lat': 0, 'lng': 0});
  const [traceCredentials, setTraceCredentials] = useState({});

  useEffect(() => { 
    setLocationCoordinates({'lat': locationData.Latitude, 'lng': locationData.Longitude});
  }, [locationData.Latitude, locationData.Longitude]);

  const updateLocationData = data => {
    if (data === null) {
      setLocationData(l => ({ Address: '', City: '', Province: '', PostalCode: '', Country: '', Latitude: 0, Longitude: 0, EnvaseTraceProviderID: null }));
    } else {
      setLocationData({ ...data });
    }
  };

  useEffect(() => { 
    if (orderType === "Import" && locationData.EnvaseTraceProviderID) { 
      getCredentialByID(locationData.EnvaseTraceProviderID) 
        .then(function(response) { 
          setTraceCredentials(response);
        });
    }
  }, [orderType, locationData.EnvaseTraceProviderID]);

  const updateProviderData = data => { 
    const {
      value
    } = data.target;
    setLocationData(l => ({ ...l, EnvaseTraceProviderID: value }));
    updateOrderFormData(data);
  };

  const buildTraceProviderNotifications = () => {
    var notification = [];
    if (isOriginLocation && orderType === "Import" && value) { 
      if (locationData.EnvaseTraceProviderID === null) { 
        notification.push(
          <Grid item md={12} xs={12}>
            <Alert severity="warning">{"Trace Provider not linked to location"}</Alert>
          </Grid>
        )
      } else if (locationData.EnvaseTraceProviderID !== null && !traceCredentials?.IsEnabled) { 
        notification.push(
          <Grid item md={12} xs={12}>
            <Alert severity="warning">{"Trace Provider credentials do not exist, contact your admin to update"}</Alert>
          </Grid>
        )
      } else if (locationData.EnvaseTraceProviderID !== null && initialProviderID === locationData.EnvaseTraceProviderID && traceCredentials?.IsEnabled) { 
        notification.push(
          <Grid item md={12} xs={12}>
            <Alert severity="success">{"Trace Provider linked"}</Alert>
          </Grid>
        )
      }
    }
    return notification;
  };

  return (
    <Grid container md={12} xs={12} spacing={2}> 
      <Grid item md={12} xs={12}>
        <h4>{headerLabel}</h4>
      </Grid>
      <Grid container item md={6} xs={6} spacing={2}>              
        {!isAbleToEditLocation && <Grid item md={12} xs={12} style={{paddingTop: '0px'}}>
          <Alert severity="warning">{headerLabel + " has already departed and cannot be modified."}</Alert>
        </Grid>}         
        <br />
        <Grid item md={12} xs={12}>
          <LocationField
            label="Location Name"
            name={name}
            value={value}
            locationForEdit={locationName}
            responseObject={updateLocationData}
            onChange={e => updateOrderFormData(e)}
            validationError={validationError}
            isRequired={true}
            isDisabled={!isAbleToEditLocation}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="Address"
            label="Address"
            value={locationData.Address}
            maxLength={255}
            readOnly={true}
          />
        </Grid>
        {locationData.Country !== "Philippines" && <>
            <Grid item md={6} xs={6}>
            <CoreTextField
              name="City"
              label="City"
              value={locationData.City}
              maxLength={100}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="Province"
              label="State"
              value={locationData.Province}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="PostalCode"
              label="ZIP/Postal Code"
              value={locationData.PostalCode}
              maxLength={15}
              readOnly={true}
              noWrap={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="Country"
              label="Country"
              value={locationData.Country}
              readOnly={true}
            />
          </Grid>
        </>}
        {locationData.Country === "Philippines" && <>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="District"
              label="District"
              value={locationData.District}
              maxLength={100}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="County"
              label="Barangay"
              value={locationData.County}
              maxLength={100}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="PostalCode"
              label="ZIP/Postal Code"
              value={locationData.PostalCode}
              maxLength={15}
              readOnly={true}
              noWrap={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="City"
              label="City"
              value={locationData.City}
              maxLength={100}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="Province"
              label="State"
              value={locationData.Province}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="Country"
              label="Country"
              value={locationData.Country}
              readOnly={true}
            />
          </Grid>
        </>}
        {isOriginLocation && orderType === "Import" && <Grid item md={12} xs={12}>
          <EnvaseTraceProviderFieldDropdown
            name="EnvaseTraceProviderID"
            label="Trace Provider"
            value={locationData.EnvaseTraceProviderID}
            onChange={e => updateProviderData(e)}
            isRequired={false}
          />
        </Grid>}
        {buildTraceProviderNotifications()}
      </Grid>
      <Grid container item md={6} xs={6} className={classes.mapContainer} style={{paddingTop: '0px'}}>
        {(!!locationCoordinates.lat && !!locationCoordinates.lng) && 
        <CoreHereDisplayMap
            coordinates={locationCoordinates}
        />}
      </Grid>
  </Grid>
  );

}

export default OrderEditContentLocation;