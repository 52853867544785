import React from 'react';
import Alert from '@material-ui/lab/Alert';
import LocationCreateContentInfo from './LocationCreateContentInfo';
import LocationCreateContentOtherInfo from './LocationCreateContentOtherInfo';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';

const LocationCreateContent = (props) => {
  const {
    locationFormData,
    onCreate,
    onClose,
    errorMessage,
    locationFormValidation,
    updateLocationFormData,
    coordinates,
    inProgress,
    traceCredentials
  } = props;

  return (
    <form onSubmit={onCreate}>
      <LocationCreateContentInfo
        locationFormData={locationFormData}
        locationFormValidation={locationFormValidation}
        updateLocationFormData={updateLocationFormData}
        coordinates={coordinates}
        traceCredentials={traceCredentials}
      />
      <br/>
      <LocationCreateContentOtherInfo
        locationFormData={locationFormData}
        locationFormValidation={locationFormValidation}
        updateLocationFormData={updateLocationFormData}
      />
      {
        errorMessage && 
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons onClose={onClose}/>}
    </form>
  );
}

export default LocationCreateContent;