import { React, useState, useEffect, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import CoreTextDisplayField from '../core/CoreTextDisplayField';

const useStyles = makeStyles(() => ({
  line: { 
    opacity: '0.3'
  }
}));

const OrderViewVesselContainerSection = (props) => {
  const classes = useStyles();
  const {
    order
  } = props;
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => { 
    setHeight(ref.current.clientHeight > ref2.current.clientHeight ? ref.current.clientHeight : ref2.current.clientHeight);
  }, [order]);

  return (
    <>
    <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
      <Grid container md={12} xs={12} alignItems="flex-start">
        <Grid ref={ref} container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <h4>Vessel Information</h4>
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Vessel Name"
              value={order.VesselName}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Voyage"
              value={order.VesselVoyager}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Estimated Arrival Date"
              value={order.VesselDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Estimated Arrival Time"
              value={order.VesselTime}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Actual Arrival Date"
              value={order.VesselActualDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Actual Arrival Time"
              value={order.VesselActualTime}
              iconSpacing={true}
            />
          </Grid>}
        </Grid>
        <hr style={{height: height}} className={classes.line}></hr>
        <Grid ref={ref2} container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <h4>Container Availability</h4>
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Release Date"
              value={order.ContainerReleaseDate}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Release Time"
              value={order.ContainerReleaseTime}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Last Free Day Date"
              value={order.ContainerLastDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Last Free Day Time"
              value={order.ContainerLastTime}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type !== 'Export' && <Grid item xs={4}>
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Cut Off Date"
              value={order.CutoffDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Cut Off Time"
              value={order.CutoffTime}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}>
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="First Received Date"
              value={order.FirstReceivedDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}>
            <CoreTextDisplayField
              label="First Received Time"
              value={order.FirstReceivedTime}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === 'Export' && <Grid item xs={4}></Grid>}
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Reported By"
              value={order.ImportExportReportedby}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={8}></Grid>
          {order.Type === "Import" && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Empty Ready Date"
              value={order.EmptyReadyDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === "Import" && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Empty Ready Time"
              value={order.EmptyReadyTime}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === "Export" && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Load Ready Date"
              value={order.LoadReadyDate}
              iconSpacing={true}
            />
          </Grid>}
          {order.Type === "Export" && <Grid item xs={4}>
            <CoreTextDisplayField
              label="Load Ready Time"
              value={order.LoadReadyTime}
              iconSpacing={true}
            />
          </Grid>}
        </Grid>
      </Grid>
    </Container>
    </>
  );
}

export default OrderViewVesselContainerSection;