import HttpService from '../http-service';

export const getGateTransactions = async (pagination) => {
  const token = localStorage.getItem('token');
  const uri = 'IntermodalOrderAppointments/' + token + '/';

  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetReservationGateTransaction', pagination);
  return result;
};

export const getReferenceNumber = async (gateTransactionId) => {
  const token = localStorage.getItem('token');
  const uri = 'IntermodalTruckVisitAppointments/' + token + '/';
  const params = { gateTransactionId };

  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `GetTmsReferenceNumber`, null, params);
  return result;
};

export const addTruckVisitAppointment = async (truckVisitAppointmentId, tmsReferenceNumber, gateTransactionIds) => {
  const token = localStorage.getItem('token');
  const uri = 'IntermodalTruckVisitAppointments/' + token + '/';
  const body = {
    truckVisitAppointmentId: new String(truckVisitAppointmentId).toString(),
    tmsReferenceNumber,
    gateTransactionIds
  }

  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `AddCreatedTruckVisitAppointment`, body);
  return result;
};