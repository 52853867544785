import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'ShipperPortalOrderAssignment/' + token + '/';

export async function get(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', null, params);
  return result.data;
};

export async function getMatchingLocation(id, isOrigin) {
  var params = {
    id: id,
    isOrigin: isOrigin,
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetMatchingLocation', params);
  return result.response;
};

export async function getCustomer(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCustomer', params);
  return result.response;
};

export async function getIncomingLoadsList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetIncomingLoadsList', params);
  return result.response;  
};

export async function acceptIncomingLoad(id, customerID, originLocationMapped, destinationLocationMapped, matchedOriginLocation, matchedDestinationLocation) {
  var params = {
    id: id,
    customerID: customerID,
    originLocationTemplateID: originLocationMapped === true ? matchedOriginLocation.LocationTemplateID : null,
    destinationLocationTemplateID: destinationLocationMapped === true ? matchedDestinationLocation.LocationTemplateID : null
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'AcceptIncomingLoad', null, params);
  return result.response;
};

export async function rejectIncomingLoad(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'RejectIncomingLoad', null, params);
  return result;
};