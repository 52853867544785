import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  iconFloatRight: {
    margin: 0,
    top: 'auto',
    bottom: 12,
    left: 'auto',
    float: 'right'
  },
}));

const OrderCreateButton = (props) => {
  const {
    onCloseRoute
  } = props;

  let history = useHistory();
  const classes = useStyles();

  const openOrderCreate = () => {
    history.push({
      pathname: '/spark/order/create', 
      state: {
        onCloseRoute: onCloseRoute
      }
    });
  };

  return (
    <>
      <Tooltip
        placement="right"
        title={"Create Order"}>
        <Fab
          name="order_list[create]"
          color="primary"
          aria-label="add"
          className={classes.iconFloatRight}
          // style={style}
          onClick={openOrderCreate}>
          <AddIcon />
        </Fab>
      </Tooltip >
    </>
  );
}

export default OrderCreateButton;