import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';

import Validation from '../validation';
import { getFull, getVendor, getTravelDocs, Update as PersonnelUpdate, PersonnelVendorProfileUpdate } from '../personnel/PersonnelService';
import { Create as TravelDocCreate, Update as TravelDocUpdate, Delete as TravelDocDelete } from '../travel-doc/TravelDocService';

import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';

import CorePageHeader from '../core/CorePageHeader';
import CoreExitButton from '../core/CoreExitButton';
import PersonnelEditContent from '../personnel/PersonnelEditContent';

const PersonnelEdit = () => {
  const { id } = useParams();
  let history = useHistory();

  const [personnelFormDataLoaded, setPersonnelFormDataLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [PersonnelFormData, setPersonnelFormData] = useState({
    FirstName: '',
    MiddleName: '',
    LastName: '',
    InternalName: '',
    Address: '',
    City: '',
    Province: '',
    PostalCode: '',
    Country: '',
    Gender: '',
    SIN: '',
    Nationality: '',
    HomePhone: '',
    PersCell: '',
    BusPhone: '',
    Title: '',
    StartDate: null,
    EndDate: null,
    HealthNum: '',
    EmergencyNumber: '',
    EmergencyContact: '',
    DriverType: 0,
    Check1: false,
    Check1Date: new Date(),
    Check2: false,
    Check2Date: new Date(),
    Check3: false,
    Check3Date: new Date(),
    BranchID: 0,
    CanFunds: 0,
    ListAsDriver: 0,
    RelatedVendorID: null
  });

  const [VendorFormData, setVendorFormData] = useState({
    VendorInternalName:'',
    LegalName:'',
    RemitName:'',
    FederalIDNum: '',
    PaymentTerms:0,
    DisAddress:'',
    DisCity:'',
    DisProvince:'',
    DisPostalCode:'',
    DisCountry:'',
    DisPhone:'',
    DisTollFree:'',
    RemAddress:'',
    RemCity:'',
    RemProvince:'',
    RemPostalCode:'',
    RemCountry:'',
    RemPhone:'',
    RemTollFree:'',
    RemAddressSame:false
   });

  const [DriverLicenseFormData, setDriverLicenseFormData] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 8,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );

  const [TWICFormData, setTWICFormData] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 18,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );

  const [PersonnelFormValidation, setPersonnelFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [coordinates, setCoordinates] = useState({'lat': 37, 'lng': -95});

  useEffect(() => {
    function formatTravelDocs(personnelTravelDocs) {
      for (var i = 0; i < personnelTravelDocs.length; i++) {
        if (personnelTravelDocs[i].ACETravelDocType === 8) {
          setDriverLicenseFormData(personnelTravelDocs[i]);
        }
        if (personnelTravelDocs[i].ACETravelDocType === 18) {
          setTWICFormData(personnelTravelDocs[i]);
        }
      }
    }
    function getVendorInfo(vendorID) {
      getVendor(vendorID)
      .then(function(response) {
        setVendorFormData({ ...response });
      });
    }
    getFull(id)
      .then(function(response) {
        setPersonnelFormData({ ...response });
        setPersonnelFormDataLoaded(true);
        if(response.RelatedVendorID) {
          getVendorInfo(response.RelatedVendorID);
        }
    });
    getTravelDocs(id)
      .then(function(response) {
        formatTravelDocs(response);
    });
  }, [id]);

  useEffect(() => {
    setPersonnelFormValidation(buildValidation(PersonnelFormData));
  }, [PersonnelFormData]);

  useEffect(() => { 
    function getAddressGeocode() {
      if(PersonnelFormData.DriverType !== 2) {
        getGeocode(
          PersonnelFormData.Address, 
          PersonnelFormData.City, 
          PersonnelFormData.Province, 
          PersonnelFormData.Country, 
          PersonnelFormData.PostalCode)
          .then(function (response) {
            setCoordinates(response);
          });
      }
    }
    getAddressGeocode();
  }, [
    PersonnelFormData.Address, 
    PersonnelFormData.City, 
    PersonnelFormData.Province, 
    PersonnelFormData.PostalCode, 
    PersonnelFormData.Country, 
    PersonnelFormData.DriverType
  ]);
  
  useEffect(() => { 
    function getAddressGeocode() {
      if(PersonnelFormData.DriverType === 2 ) {
        getGeocode(
          VendorFormData.DisAddress, 
          VendorFormData.DisCity, 
          VendorFormData.DisProvince, 
          VendorFormData.DisCountry, 
          VendorFormData.DisPostalCode)
          .then(function (response) {
            setCoordinates(response);
        });
      } 
    }
    getAddressGeocode();
  }, [
    VendorFormData.DisAddress, 
    VendorFormData.DisCity, 
    VendorFormData.DisProvince, 
    VendorFormData.DisPostalCode, 
    VendorFormData.DisCountry, 
    PersonnelFormData.DriverType
  ]);

  useEffect(() => { 
    function getAddressGeocode() {
      if(PersonnelFormData.DriverType === 2 ) {
        getGeocode(
          VendorFormData.DisAddress, 
          VendorFormData.DisCity, 
          VendorFormData.DisProvince, 
          VendorFormData.DisCountry, 
          VendorFormData.DisPostalCode)
          .then(function (response) {
            setCoordinates(response);
        });
      } 
    }
    getAddressGeocode();
  }, [
    VendorFormData.DisAddress, 
    VendorFormData.DisCity, 
    VendorFormData.DisProvince, 
    VendorFormData.DisCountry,
    VendorFormData.DisPostalCode, 
    PersonnelFormData.DriverType
  ]);

  const updatePersonnelFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    PersonnelFormData[name] = value;
    PersonnelFormData[nameData] = valueData;
    setPersonnelFormData({ ...PersonnelFormData });
  };

  const updateVendorFormData = (e) => {
    const {name, value, nameData, valueData} = e.target;
    VendorFormData[name] = value;
    VendorFormData[nameData] = valueData;

    setVendorFormData({ ...VendorFormData });
  };

  const updateDriverLicenseFormData = (e) => {
    const {name, value} = e.target;
    DriverLicenseFormData[name] = value;
    setDriverLicenseFormData({ ...DriverLicenseFormData });
  };

  const updateTWICFormData = (e) => {
    const {name, value} = e.target;
    TWICFormData[name] = value;
    setTWICFormData({ ...TWICFormData });
  };

  const formatPersonnel = () => {
    PersonnelFormData.Check1Date = PersonnelFormData.Check1Date ? PersonnelFormData.Check1Date : new Date();
    PersonnelFormData.Check2Date = PersonnelFormData.Check2Date ? PersonnelFormData.Check2Date : new Date();
    PersonnelFormData.Check3Date = PersonnelFormData.Check3Date ? PersonnelFormData.Check3Date : new Date();
    // Strip Phone Numbers
    PersonnelFormData.BusPhone = PersonnelFormData.BusPhone ? PersonnelFormData.BusPhone.replace(/\D/g,'') : '';
    PersonnelFormData.PersCell = PersonnelFormData.PersCell ? PersonnelFormData.PersCell.replace(/\D/g,'') : '';
    PersonnelFormData.HomePhone = PersonnelFormData.HomePhone ? PersonnelFormData.HomePhone.replace(/\D/g,'') : '';
    setPersonnelFormData({ ...PersonnelFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(PersonnelFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    formatPersonnel();
    setErrorMessage('');
    var result;
    if(PersonnelFormData.DriverType === 2) {
      result = await PersonnelVendorProfileUpdate(PersonnelFormData, VendorFormData)
    } else {
      result = await PersonnelUpdate(PersonnelFormData);
    }
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    handleTravelDocs();
    // Redirect to the Personnel view page
    history.push('/spark/Personnel/view/' + id);
  };

  const handleTravelDocs = () => {
    if (!DriverLicenseFormData.PersonnelTravelDocID) {
      processTravelDocCreateRequest(DriverLicenseFormData);
    } else {
      processTravelDocDeleteUpdateRequest(DriverLicenseFormData);
    }
    if (!TWICFormData.PersonnelTravelDocID) {      
      processTravelDocCreateRequest(TWICFormData);
    } else {
      processTravelDocDeleteUpdateRequest(TWICFormData);
    }
  };

  const processTravelDocCreateRequest = async (travelDoc) => {
    if (!travelDoc.DocumentNumber) {
      return;
    }
    if (travelDoc.ExpiryDate) {
      var date = new Date(travelDoc.ExpiryDate);
      travelDoc.ExpiryDate = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());  
    } else {
      travelDoc.ExpiryDate = "";
    }
    travelDoc.PersonnelID = id;
    travelDoc.Province = PersonnelFormData.Province;
    travelDoc.Country = PersonnelFormData.Country;
    TravelDocCreate(travelDoc);
  };

  const processTravelDocDeleteUpdateRequest = async (travelDoc) => {
    if (!travelDoc.DocumentNumber) {
      processTravelDocDeleteRequest(travelDoc);
      return;
    }
    if (travelDoc.ExpiryDate) { 
      var date = new Date(travelDoc.ExpiryDate);
      travelDoc.ExpiryDate = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());  
    } else {
      travelDoc.ExpiryDate = "";
    }
    travelDoc.PersonnelID = id;
    travelDoc.Province = PersonnelFormData.Province;
    travelDoc.Country = PersonnelFormData.Country;
    TravelDocUpdate(travelDoc);
  };

  const processTravelDocDeleteRequest = async (travelDoc) => {
    TravelDocDelete(travelDoc);
  };

  const onClose = () => {
    history.push('/spark/personnel/view/' + id);
  };

  return (
    <div>
      <CorePageHeader
        title={"Edit Personnel " + PersonnelFormData.InternalName}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {personnelFormDataLoaded &&
        <PersonnelEditContent
          onClose={onClose}
          onUpdate={onUpdate}
          errorMessage={errorMessage}
          PersonnelFormData={PersonnelFormData}
          PersonnelFormValidation={PersonnelFormValidation}
          updatePersonnelFormData={updatePersonnelFormData}
          updateVendorFormData={updateVendorFormData}
          VendorFormData={VendorFormData}
          DriverLicenseFormData={DriverLicenseFormData}
          updateDriverLicenseFormData={updateDriverLicenseFormData}
          TWICFormData={TWICFormData}
          updateTWICFormData={updateTWICFormData}
          coordinates={coordinates}
          inProgress={inProgress}
        />}
      </Container>
    </div>
  );
}

const buildValidation = (PersonnelFormData) => {
  const { 
    FirstName,
    InternalName,
    StartDate,
    Country
  } = PersonnelFormData || {};
  const validation = new Validation();
  return {
    FirstName: validation.isEmpty(FirstName),
    InternalName: validation.isEmpty(InternalName),
    StartDate: validation.isEmpty(StartDate),
    Country: validation.isEmpty(Country)
  };
};

export default PersonnelEdit;