import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import AvailableDriverField from '../intermodalEvent/AvailableDriverField';
import { getAvailableDriversList } from '../intermodalEvent/IntermodalEventService';
import DispatchListDriverAssignDrawerText from './DispatchListDriverAssignDrawerText';

const DispatchListDriverAssignDrawerAvailableSection = (props) => {
  const {
    event,
    updateEventFormData
  } = props;

  const [driverList, setDriverList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [driverSelectionID, setDriverSelectionID] = useState(null)

  useEffect(() => {
    async function getAvailableDriverList() {
      const result = await getAvailableDriversList(event.ID);
      setDriverList(result);
      setIsLoading(false);
    }
    getAvailableDriverList();
  }, [event.ID]);

  const selectDriverFromList = (driver) => {
    setDriverSelectionID(driver.DriverID)
    const dispatchEvent = {
      target: {
        name: "Driver",
        value: driver ? driver.Name : '',
        nameData: "DriverID",
        valueData: driver ? driver.DriverID : null
      }
    };
    updateEventFormData(dispatchEvent);
  };

  return (
    <div>
      <Grid item md={12} xs={12}>
        <AvailableDriverField
          legID={event.LegID}
          name="Driver"
          label="Search"
          value={event.Driver}
          nameData="DriverID"
          valueData={event.DriverID}
          onChange={e => updateEventFormData(e)}
        />
      </Grid>
      {!isLoading && driverList.length > 0 &&
        <Grid container spacing={0}>
          <CoreSummarySectionTitle title="AVAILABLE" />
          {driverList.map((driver, i) => {
            return (
              driver.IsAvailable === 'Available' ?
              <Grid item md={12} xs={12} key={i}>
                <DispatchListDriverAssignDrawerText
                  label={driver.Name}
                  icon={<Avatar>{driver.Name.charAt(0)}</Avatar>}
                  onClick={() => selectDriverFromList(driver)}
                  driver={driver}
                  type="AVAILABLE"
                  loader={driverSelectionID === driver.DriverID && <CircularProgress size={14} />}
                />
              </Grid> 
              : null
            )
          })}
        </Grid>
      }
    </div>
  );
}

export default DispatchListDriverAssignDrawerAvailableSection;