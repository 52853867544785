import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CitySvg } from '../assets/svg-icons/city@1x.svg';

const IconCity = () => {
  return (
    <SvgIcon fontSize="small">
      <CitySvg />
    </SvgIcon>
  )
}
export default IconCity;