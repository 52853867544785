import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import NoteCreateDialog from '../notes/NoteCreateDialog';
import CoreAddButton from '../core/CoreAddButton';

const useStyles = makeStyles(() => ({
  tabWidth: {
    width: '640px',
  }
}));

const NoteTabBar = (props) => {
  const {
    id,
    noteType,
    getNotes,
  } = props;
  const classes = useStyles();
  const [isNotesCreateDialogOpen, setNotesCreateDialogOpen] = useState(false);

  const openNotesCreateDialog = () => {
    setNotesCreateDialogOpen(true);
  };

  const closeNotesCreateDialog = () => {
    setNotesCreateDialogOpen(false);
    getNotes();
  };

  return (
    <Container style={{ padding: '0' }} maxWidth="lg" className="mt-2">
      <Grid container spacing={2} className="mt-2">
        <Grid container item xs={2}>
          <Tabs
            value={0}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab value={0} label='Notes' />
          </Tabs>
        </Grid>
        <Grid container item xs={9}></Grid>
        <Grid container item xs={1}>
          <CoreAddButton handleOpenCreate={openNotesCreateDialog}></CoreAddButton>
        </Grid>
      </Grid>
      <NoteCreateDialog
        originID={id}
        noteType={noteType}
        isOpen={isNotesCreateDialogOpen}
        onClose={closeNotesCreateDialog}
      />
    </Container>
  );
}

export default NoteTabBar;
