import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { update, applyAreaTagToLocations } from './AreaTagService';
import AreaTagEditDialogContent from './AreaTagEditDialogContent';

const AreaTagEditDialog = (props) => {
  const {
    isOpen,
    onClose,
    areaTagFormData,
    setAreaTagFormData, 
    areaTagLines,
    setAreaTagLines,
    openAreaTagLineCreateDialog,
    getAreaTagLineList,
    inProgress,
    setInProgress,
    locationsToApplyCount,
  } = props;

  const [areaTagFormValidation, setAreaTagFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setAreaTagFormValidation(buildValidation(areaTagFormData));
    }
  }, [areaTagFormData, isOpen]);

  const updateAreaTagFormData = e => {
    const { name, value } = e.target;
    areaTagFormData[name] = value;
    setAreaTagFormData({ ...areaTagFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(areaTagFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(areaTagFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error[0]);
      return;
    }
    props.onClose();
  };

  const applyAreaTagsToLocation = () => {
    setInProgress(true);
    applyAreaTagToLocations(areaTagFormData.AreaTagID)
      .then(function (response) {
        getAreaTagLineList();
        setInProgress(false);
      });
  };

  return (
    <>
      {isOpen &&
      <AreaTagEditDialogContent
        isOpen={isOpen}
        onClose={onClose}
        onUpdate={onUpdate}
        errorMessage={errorMessage}
        areaTagFormData={areaTagFormData}
        areaTagFormValidation={areaTagFormValidation}
        updateAreaTagFormData={updateAreaTagFormData}
        inProgress={inProgress}
        openAreaTagLineCreateDialog={openAreaTagLineCreateDialog}
        areaTagLines={areaTagLines}
        setAreaTagLines={setAreaTagLines}
        getAreaTagLineList={getAreaTagLineList}
        applyAreaTagsToLocation={applyAreaTagsToLocation}
        locationsToApplyCount={locationsToApplyCount}
      />}    
    </>
  );
}

const buildValidation = (areaTagFormData) => {
  const {
    Name,
  } = areaTagFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
  };
};

export default AreaTagEditDialog;