import React from 'react';
import Grid from '@material-ui/core/Grid';
import IntermodalPaymentListStatusButton from './IntermodalPaymentListStatusButton';

import IconOnHold from '../icon/IconOnHold';
import IconPendingInvoice from '../icon/IconPendingInvoice';
import IconReady from '../icon/IconReady';
import IconCheck from '../icon/IconCheck';

const IntermodalPaymentListStatus = (props) => {
  const {
    settlementStatuses,
    settlements,
    updateStatusButtonChange
  } = props;

  return (
    <div className="mb-2">
      <Grid container justify="space-around" spacing={4}>
        <Grid item md={3} xs={3}>
          <IntermodalPaymentListStatusButton 
            count={settlements[0] ? settlements[0].PendingCount : 0}
            name="ShowPending"
            label={"Pending"}
            icon={<IconPendingInvoice />}
            settlementStatus={settlementStatuses.ShowPending}
            buttonColor={"#6639BF"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalPaymentListStatusButton 
            count={settlements[0] ? settlements[0].OnHoldCount : 0}
            name="ShowOnHold"
            label={"On Hold"}
            icon={<IconOnHold />}
            settlementStatus={settlementStatuses.ShowOnHold}
            buttonColor={"#FFB74D"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalPaymentListStatusButton 
            count={settlements[0] ? settlements[0].ApprovedCount : 0}
            name="ShowApproved"
            label={"Approved"}
            icon={<IconReady />}
            settlementStatus={settlementStatuses.ShowApproved}
            buttonColor={"#00E676"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalPaymentListStatusButton 
            count={settlements[0] ? settlements[0].CompletedCount : 0}
            name="ShowCompleted"
            label={"Completed"}
            icon={<IconCheck />}
            settlementStatus={settlementStatuses.ShowCompleted}
            buttonColor={"#00E676"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
      </Grid>
    </div>
  );

}

export default IntermodalPaymentListStatus;