import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ConfigurationSvg } from '../assets/svg-icons/configuration@1x.svg'

const IconConfiguration = () => {
  return (
    <SvgIcon fontSize="small">
      <ConfigurationSvg />
    </SvgIcon>
  )
}

export default IconConfiguration;