import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomerField from '../customer/CustomerField';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const IncomingLoadListAcceptDialogContent = (props) => {
  const {
    onAccept,
    isOpen,
    onClose,
    reference,
    acceptDialogFormData,
    errorMessage, 
    inProgress,
    locationFormData,
    handleRadioChange,
    matchedOriginLocation,
    matchedDestinationLocation,
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onAccept}>
        <DialogTitle id="form-dialog-title">Confirm Accepting Load</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to accept Load {reference}?          
          </DialogContentText>
          <Grid item md={12} xs={12}>
            <CustomerField
              name="customer"
              label="Customer"
              customerForEdit={acceptDialogFormData.customer}
              value={acceptDialogFormData.customer}
              nameData="customerID"
              valueData={acceptDialogFormData.customerID}
              isDisabled={true}  
            />
          </Grid>
          <Grid className='mt-2' item md={12} xs={12}>
            {matchedOriginLocation === null && <FormLabel><b>A new location for Origin will be generated.</b></FormLabel>}
            {matchedOriginLocation !== null && <FormLabel><b>Would you like to map the Origin to a Location we found:</b><br/>
              {matchedOriginLocation.CompanyName}, {matchedOriginLocation.Address}, {matchedOriginLocation.City}, {matchedOriginLocation.Province} {matchedOriginLocation.PostalCode}
            </FormLabel>}
            {matchedOriginLocation !== null && <RadioGroup
              name="originLocationMapped"
              value={locationFormData.originLocationMapped}
              onChange={handleRadioChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No (Selecting this will generate a new location for Origin)" />
            </RadioGroup>}
          </Grid>
          <Grid className='mt-2' item md={12} xs={12}>
            {matchedDestinationLocation === null && <FormLabel><b>A new location for Destination will be generated.</b></FormLabel>}
            {matchedDestinationLocation !== null && <FormLabel><b>Would you like to map the Destination to a Location we found:</b><br/>
              {matchedDestinationLocation.CompanyName}, {matchedDestinationLocation.Address}, {matchedDestinationLocation.City}, {matchedDestinationLocation.Province}  {matchedDestinationLocation.PostalCode}</FormLabel>}
            {matchedDestinationLocation !== null && <RadioGroup
              name="destinationLocationMapped"
              value={locationFormData.destinationLocationMapped}
              onChange={handleRadioChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No (Selecting this will generate a new location for Destination)" />
            </RadioGroup>}
          </Grid>
          {errorMessage && <Grid item md={12} xs={12} className="mt-1">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default IncomingLoadListAcceptDialogContent;