import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import OrderViewGeneralSection from './OrderViewGeneralSection';
import OrderViewVesselContainerSection from './OrderViewVesselContainerSection';
import OrderViewLocationSection from './OrderViewLocationSection';
import OrderTabBar from './OrderTabBar';
import { get, getNotes } from './OrderService';
import { printInvoice, printBatchInvoice } from '../intermodal_invoice/IntermodalInvoiceService';
import { get as getLocation } from '../location/LocationService';
import { getInvoiceCode } from '../customer_invoice_code/CustomerInvoiceCodeService';
import OrderViewPageHeader from './OrderViewPageHeader';

const OrderView = () => {
  let history = useHistory();
  const { id } = useParams();
  const onCloseRoute = history.location.state?.onCloseRoute ? history.location.state?.onCloseRoute : '/spark/order';

  const [order, setOrder] = useState({});
  const [notes, setOrderNotes] = useState([]);
  const [tabSelected, setTabSelected] = useState(history.location.state?.tabSelected ? history.location.state?.tabSelected : 0);
  const [inProgress, setInProgress] = useState(true);
  const [envaseTraceLocationExists, setEnvaseTraceLocationExists] = useState(false);

  useEffect(() => {
    get(id)
      .then(async function (response) {
        setOrder(response);
        getLocations(response)
        getCustomerInvoiceCode(response);
        getNotesList();
        setInProgress(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function getLocations(orderResponse) {
    getLocation(orderResponse.OriginID)
      .then(function (response) {
        setOrder(c => ({ ...c, OriginCompanyName: response.CompanyName,
          OriginAddress: response.Address,
          OriginCity: response.City,
          OriginState: response.Province,
          OriginZip: response.PostalCode,
          OriginCountry: response.Country,
          OriginDistrict: response.District,
          OriginCounty: response.County
        }));
        if (response.ProviderName !== null && orderResponse.Type === "Import") {
          setEnvaseTraceLocationExists(true);
        }
      });
    getLocation(orderResponse.DestinationID)
      .then(function (response) {
        setOrder(c => ({ ...c, DestinationCompanyName: response.CompanyName,
          DestinationAddress: response.Address,
          DestinationCity: response.City,
          DestinationState: response.Province,
          DestinationZip: response.PostalCode,
          DestinationCountry: response.Country,
          DestinationDistrict: response.District,
          DestinationCounty: response.County
         }));
         if (response.ProviderName !== null && orderResponse.Type === "Import") {
          setEnvaseTraceLocationExists(true);
        }
      });
  };

    const getCustomerInvoiceCode = async (orderResponse) => {
      const response = await getInvoiceCode(orderResponse.CustomerInvCodeID);
      if (response) {
        setOrder(c => ({ ...c, CustomerInvCode: response.InvCode }));
      }
    };

    const updateTabs = e => {
      const { value } = e.target;
      setTabSelected(value);
    };

    function getOrder() {
      get(id)
        .then(function (response) {
          setOrder(response);
          getLocations(response);
          getCustomerInvoiceCode(response);
        });
    };

    function downloadInvoice() {
      printInvoice(id)
        .then(function(response) {
            window.open(response, '_blank');
        });
    };

    function downloadBatchInvoice() {
      printBatchInvoice(id)
        .then(function(response) {
          window.open(response, '_blank');
        });
    };

    function getNotesList() {
      getNotes(id)
        .then(function(response) {
          setOrderNotes(response);
        });
    };
    
  return (
    <div>
      <OrderViewPageHeader
        order={order}
        getOrder={getOrder}
        getNotes={getNotesList}
        printInvoice={downloadInvoice}
        printBatchInvoice={downloadBatchInvoice}
        onCloseRoute={onCloseRoute}
      />
      <OrderViewGeneralSection
        order={order}
      />
      <OrderViewLocationSection
        order={order}
      />
      <OrderViewVesselContainerSection
        order={order}
      />
      {!inProgress && <OrderTabBar
        id={id}
        order={order}
        getOrder={getOrder}
        noteType={31}
        getNotes={getNotesList}
        notes={notes}
        tabSelected={tabSelected}
        onChange={updateTabs}
        envaseTraceLocationExists={envaseTraceLocationExists}
      />}
    </div>
  );

}

export default OrderView;