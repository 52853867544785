import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'PayableCharge/' + token + '/';

export async function getPayable(id) { 
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + id);
    return result.data[0];
}

export async function createPayable(payableFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', payableFormData);
  return result;
};

export async function updatePayable(payableFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', payableFormData);
  return result;
};

export async function deletePayable(payableChargeID) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + payableChargeID + '/Delete');
  return result;
};

export async function intermodalOrderCreate(payableFormData, intermodalOrderID, intermodalLegID) {
  var params = { 
    intermodalOrderID: intermodalOrderID,
    intermodalLegID: intermodalLegID,
    driverID: payableFormData.driverID
  }
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + intermodalOrderID + '/IntermodalOrderCreate', payableFormData, params);
  return result;
};

export async function intermodalOrderUpdate(payableFormData) { 
    const httpService = new HttpService();
    const result = await httpService
        .post(uri + 'IntermodalOrderUpdate', payableFormData);
    return result;
};

export async function intermodalOrderDelete(id) { 
    var params = {
      id: id
    }
    const httpService = new HttpService();
    const result = await httpService
        .post(uri + 'IntermodalOrderDelete', null, params);
    return result;
};

export async function deleteReceivable(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalOrderDelete', params);
  return result;
};