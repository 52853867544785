import React from 'react';
import PropTypes from 'prop-types';
import IconPrint from '../icon/IconPrint';
import IconButton from '@material-ui/core/IconButton';

const CoreDataGridPrint = (props) => {
  const {
    onClick
  } = props;

  return (
    <IconButton onClick={onClick}>
      <IconPrint />
    </IconButton>
  );
}

CoreDataGridPrint.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CoreDataGridPrint;
