import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';

const DispatchListDriverAssignDrawerText = (props) => {
  const { label, icon, onClick, driver, type, loader } = props;
  const onClickProp = onClick ? () => onClick(driver) : null;
  const formControlStyle = {flexFlow: 'nowrap', alignItems: 'center'};
  const cursorStyle = type === "AVAILABLE" ? "pointer" : "not-allowed";
  const isLoading = loader || null;
  const buildStartAdornment = () => {
    if (icon) {
      return (
        <IconButton style={{paddingTop: '0px', pointerEvents: 'none'}}>
          {icon}
        </IconButton>);
    }
    return <></>;
  };

  return (
    <div onClick={onClickProp} style={{cursor:cursorStyle}}>
      <Grid container>
        <Grid item xs={2} style={{maxWidth: '45px'}}>
          {buildStartAdornment()}
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth style={ formControlStyle }>
            <label style={{padding: '12px 0 0 20px', cursor:cursorStyle}}>
              {label} {isLoading}
            </label>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

DispatchListDriverAssignDrawerText.propTypes = {
  icon: PropTypes.element.isRequired,
  driver: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default DispatchListDriverAssignDrawerText;