import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CoreToggleDisplayField from '../core/CoreToggleDisplayField';

const LocationViewInfoSection = (props) => {
  const {
    location
  } = props;

  return (
    <div>
      <Container maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={1} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Loading Info</h4>
          </Grid>
          <Grid container item md={12} xs={12} className="mb-2">
            {location.LoadingInfo}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className="mt-1" component={Paper}>
        <Grid container spacing={1} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Receiving Info</h4>
          </Grid>
          <Grid container item md={12} xs={12} className="mb-2">
            {location.ReceivingInfo}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className="mt-1" component={Paper}>
        <Grid container spacing={1} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Additional Info</h4>
          </Grid>
          <Grid container item md={12} xs={12} className="mb-2">
            {location.Notes}
          </Grid>
          <Grid item md={12} xs={12} className="mb-2" style={{marginLeft: '-10px'}}>
            <CoreToggleDisplayField
              label="Signature Required"
              value={location.SignatureRequired ? true : false}
              disabled={!location.SignatureRequired}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}

export default LocationViewInfoSection;