import { Route, Redirect, Switch  } from 'react-router-dom';
import AuthenticationLogin from './AuthenticationLogin';
import AuthenticationCreateAccount from './AuthenticationCreateAccount';
import AuthenticationResetPassword from './AuthenticationResetPassword';
import AuthenticationCreditCard from './AuthenticationCreditCard';

const AuthenticationRouter = ({ parentURL }) => {
  return (
    <Switch>
      {/* <Route exact path={`${parentURL}/login`} component={AuthenticationLogin} /> */}
      <Route exact path="/login" component={AuthenticationLogin} />
      <Route exact path="/create-account" component={AuthenticationCreateAccount} />
      <Route exact path="/reset-password" component={AuthenticationResetPassword} />
      <Route exact path="/payment-method" component={AuthenticationCreditCard} />
      <Redirect to="/login" />
    </Switch>
  );

}

export default AuthenticationRouter;