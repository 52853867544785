import HttpService from "../http-service";

const token = localStorage.getItem('token');
const uri = 'Tax/' + token + '/';

export async function getTIN(){
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetTIN');
    return result;
}