import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import QuickbooksRevenueDropdownField from './QuickbooksRevenueDropdownField';

const QuickbooksMappedRevenueEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    quickbooksRevenueFormData,
    onUpdate,
    errorMessage,
    quickbooksRevenueFormValidation,
    updateFormData,
  } = props;

  return (
    <Dialog open={isOpen} fullWidth={true} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Edit Revenue Mapping</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={12}>
              <CoreTextField
                label="Local Revenue Item"
                value={quickbooksRevenueFormData.RevenueItemName}
                readOnly={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <QuickbooksRevenueDropdownField
                label={"QuickBooks Revenue Item"}
                name={"RemoteName"}
                value={quickbooksRevenueFormData.RemoteName}
                nameData={"RemoteID"}
                valueData={quickbooksRevenueFormData.RemoteID}
                isRequired={true}
                onChange={e => updateFormData(e)}
                validationError={quickbooksRevenueFormValidation.RemoteID}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>        
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default QuickbooksMappedRevenueEditDialogContent;