import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as UnitNumberSvg } from '../assets/svg-icons/unit-number@1x.svg';

const IconUnitNumber = () => {
  return (
    <SvgIcon fontSize="small">
      <UnitNumberSvg />
    </SvgIcon>
  )
}
export default IconUnitNumber;