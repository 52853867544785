import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteLegAndPayablesDialogContent = (props) => {
  const {
    onDelete,
    isOpen,
    onClose,
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <DialogTitle id="form-dialog-title">Confirm Delete Leg and Related Expenses</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The LEG is related to one or more expenses. Do you want to also delete the related expenses? <br />
          (Clicking No will only delete the Leg)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={() => onDelete(false)}>
          No
        </Button>
        <Button color="primary" onClick={() => onDelete(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteLegAndPayablesDialogContent;