import HttpService from '../http-service';
import { format } from 'date-fns';

const token = localStorage.getItem('token');
const uri = 'Bill/' + token + '/';

const serializeBillIDArray = function(billIDs) {
  var httpString = '';
  for (var i = 0; i < billIDs.length; i++) {
    httpString += '&billIDs[' + i +']=' + billIDs[i];
  }
  return httpString.substring(1);
};

export async function get(billID) {
  var params = {
    billID: parseInt(billID),
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalOrderBill', params);
  const response = result.response;
  response.BillDate = response.BillDate ? format(new Date(response.BillDate), 'MM/dd/yyyy') : null;
  return response;
};

export async function getBills(intermodalOrderID) {
  var params = {
    intermodalOrderID: parseInt(intermodalOrderID),
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalOrderBillList', params);
  const response = result.response;
  for (const bill of response) {
    bill.BillDate = bill.BillDate ? format(new Date(bill.BillDate), 'MM/dd/yyyy') : null;
  }
  return response;
};

export async function getList(params) {
    const httpService = new HttpService();
    const result = await httpService
      .post(uri + 'GetIntermodalPaymentList', params);
    const response = result.data;
    for(const settlement of response) { 
      settlement.ID = settlement.resultNum;
      settlement.BillDate = settlement.BillDate ? format(new Date(settlement.BillDate), 'MM/dd/yyyy') : null;
      settlement.Marked = false;
    }
    return response;
};
export async function getPayableCharges(billID) {
  var params = {
    billID: parseInt(billID),
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPayableChargesList', params);
  return result.response;
};

export async function updateBill(data) {
  const httpService = new HttpService();
  data.CanFunds = data.CanFunds ? 1 : 0;
  const result = await httpService
    .post(uri + data.BillID + '/Update', data);
  return result;
}

export async function deleteBill(billID) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + billID + '/Delete');
  return result;
};

export async function exportList(params) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ExportIntermodalPaymentList', params);
  return result;
};

export async function setBillOnHold(billID, isOnHold) { 
  var params = {
    billID: parseInt(billID),
    isBillOnHold: isOnHold
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SetBillOnHold', null, params);
  const response = result;
  return response;
};

export async function setBillsToApproved(ids) {
  const httpService = new HttpService();
  const result = await httpService
    .postForFile(uri + 'SetBillsToApproved?' + serializeBillIDArray(ids));
  const response = result;
  return response; 
};

export async function setBillsToPending(ids) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SetBillsToPending', ids);
  return result; 
};

export async function tagForBilling(id, value) {
  var params = { 
    billID: id,
    value: value ? 1 : 0
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/TagForBilling', null, params);
  return result; 
};

export async function processIntermodalPayment(personnelPayments) { 
  const httpService = new HttpService();
  const result = await httpService
    .postForFile(uri + 'ProcessIntermodalPayment', personnelPayments);
  return result;
};

export async function createDeductible(deductibleFormData) { 
  var params = {
    payDate: format(new Date(deductibleFormData.BillDate), 'MM/dd/yyyy'),
    personnelID: deductibleFormData.PersonnelID,
    payableChargeTypeID: deductibleFormData.PayableChargeTypeID,
    description: deductibleFormData.Description,
    units: deductibleFormData.Units,
    unitRate: deductibleFormData.UnitRate,
    orderNumber: deductibleFormData.OrderNumber,
    legID: deductibleFormData.LegID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateIntermodalPayableDeductible', params);
  const response = result;
  return response;
};