import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  zeroMargin: {
    marginLeft: '0px',
  },
}));

const CoreTextDisplayField = (props) => {
  const { label, value, disabled } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <FormControlLabel
      className={classes.zeroMargin}
      control={<Checkbox checked={checked} name="checkedA" color="primary" />}
      label={label}
      disabled={disabled}
    />
  );
}

CoreTextDisplayField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};

export default CoreTextDisplayField;