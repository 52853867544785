import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as TractorTypeSvg } from '../assets/svg-icons/tractor-type@1x.svg';

const IconTractorType = () => {
  return (
    <SvgIcon fontSize="small">
      <TractorTypeSvg />
    </SvgIcon>
  )
}
export default IconTractorType;