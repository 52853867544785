import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  summarySectionTitle: {
    fontWeight: 'normal',
    fontSize: '12px',
    marginTop: '28px',
    marginBottom: '12px'
  },
}));

const CoreSummarySectionTitle = (props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <h5 className={classes.summarySectionTitle}>{title}</h5>
  )

}

export default CoreSummarySectionTitle;
