import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconSettlements from '../icon/IconSettlements';

const NavigationDrawerListItemSettlement = () => {
  return (
    <NavigationDrawerListItem
      title={'Settlements'}
      icon={<IconSettlements />}
      uri={'/spark/settlement'} />
  );

}

export default NavigationDrawerListItemSettlement;