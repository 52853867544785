import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getList } from '../receivable_charge_type/ReceivableChargeTypeService';
import ConfigurationViewRevenueItemSectionListTable from './ConfigurationViewRevenueItemSectionListTable';
import ReceivableChargeTypeCreateDialog from '../receivable_charge_type/ReceivableChargeTypeCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};

const ConfigurationViewRevenueItemSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isRevenueItemCreateDialogOpen, setIsRevenueItemCreateDialogOpen] = useState(false);
  const [revenueItems, setRevenueItems] = useState({});

  useEffect(() => {
    getList()
      .then(function (response) {
        setRevenueItems(response);
        setInProgress(false);
      });
  }, []);

  const getRevenueItemsList = () => {
    getList()
      .then(function (response) {
        setRevenueItems(response);
      });
  };

  const openRevenueItemCreateDialog = () => {
    setIsRevenueItemCreateDialogOpen(true);
  };

  const closeRevenueItemCreateDialog = () => {
    setIsRevenueItemCreateDialogOpen(false);
    getRevenueItemsList();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Revenue Items"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Revenue Items"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"Revenue items are the line items you'll add to your orders when billing your customers."}
                <span style={rightFloat}>
                </span>
            </Typography>
            <ConfigurationViewRevenueItemSectionListTable
              revenueItems={revenueItems}
              getRevenueItems={getRevenueItemsList}
              setRevenueItems={setRevenueItems}
              handleOpenCreate={openRevenueItemCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
            <span></span>
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Revenue Items"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Revenue Items"></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <ReceivableChargeTypeCreateDialog
        isOpen={isRevenueItemCreateDialogOpen}
        onClose={closeRevenueItemCreateDialog}
      />
    </div>
  );

}

export default ConfigurationViewRevenueItemSection;