import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';

const LocationEditContentOtherInfo = (props) => {
  const {
    locationFormData,
    locationFormValidation,
    updateLocationFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Other Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="LoadingInfo"
            label="Loading Info"
            value={locationFormData.LoadingInfo}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.LoadingInfo}
            maxLength={255}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="ReceivingInfo"
            label="Receiving Info"
            value={locationFormData.ReceivingInfo}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.ReceivingInfo}
            maxLength={255}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="Notes"
            label="Additional Info"
            value={locationFormData.Notes}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.Notes}
            maxLength={4000}
          />
        </Grid>
        <Grid item md={12} xs={12} className="mb-2">
          <CoreCheckboxField
            name="SignatureRequired"
            label="Signature Required"
            value={locationFormData.SignatureRequired}
            onChange={e => updateLocationFormData(e)}
          />
        </Grid>
      </Grid>
    </Container>
  );
  
}

export default LocationEditContentOtherInfo;