import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Note/' + token + '/';

export async function create(note) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', note);
  return result;
};

export async function update(note) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', note);
  return result;
};

export async function deleteNote(noteID) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + noteID + '/Delete');
  return result;
};

export async function get(noteID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + noteID);
  return result.data[0];
};

export async function markImportant(noteID, isImportant) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + noteID + "/MarkImportant?isImportant=" + isImportant);
  return result;
};