import React from 'react';
import Button from '@material-ui/core/Button';
import { authenticate } from './QuickbooksService';

const QuickbooksConnectButton = () => {

  const fireAuthentication = async () => {
    let result = await authenticate();
    window.location.href = result;
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={fireAuthentication}>Connect to QBO</Button>     
    </>
  )
}

export default QuickbooksConnectButton;
