import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import IconCountry from '../icon/IconCountry';
import IconInternalName from '../icon/IconInternalName';
import IconLegalName from '../icon/IconLegalName';
import IconPersonnelLight from '../icon/IconPersonnelLight';
import IconLicensePlate from '../icon/IconLicensePlate';

const PersonnelViewGeneralSection = (props) => {
  const {
    personnel
  } = props;

  return (
    <>
      <Container maxWidth="lg" className="mt-4" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>General Information</h4>
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Internal Name"
              value={personnel.InternalName}
              icon={
                <IconInternalName /> 
              }
            />
          </Grid>
          <Grid item xs={3}>     
            <CoreTextDisplayField
              label="First Name"
              value={personnel.FirstName}
              icon={
                <IconLegalName />
              }
          />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Middle Name"
              value={personnel.MiddleName}
              icon={
                <IconLegalName />
              }
            />
          </Grid>
          <Grid item xs={3}>     
            <CoreTextDisplayField
              label="Last Name"
              value={personnel.LastName}
              icon={
                <IconLegalName />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Gender"
              value={personnel.Gender}
              icon={
                <IconPersonnelLight />
              }
            />  
          </Grid>
          <Grid item xs={3}>
              <CoreTextDisplayField
                label="SSN / SIN"
                value={personnel.SIN}
                icon={
                  <IconLicensePlate />
                }
              />
          </Grid>
          <Grid item xs={3}>
              <CoreTextDisplayField
                label="Citizenship Country"
                value={personnel.Nationality}
                icon={
                  <IconCountry />
                }
              />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PersonnelViewGeneralSection;