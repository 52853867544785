import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import DocumentFieldEditDialog from './DocumentFieldEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const DocumentFieldListColumnEditButton = (props) => {
  const {
    documentFieldID,
    getDocumentFields
  } = props;

  const classes = useStyles();
  const [isDocumentFieldEditModalOpen, setIsDocumentFieldEditModalOpen] = useState(false);

  const openDocumentFieldEditModal = () => {
    setIsDocumentFieldEditModalOpen(true);
  };

  const closeDocumentFieldEditModal = () => {
    setIsDocumentFieldEditModalOpen(false);
    getDocumentFields();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openDocumentFieldEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <DocumentFieldEditDialog
        documentFieldID={documentFieldID}
        isOpen={isDocumentFieldEditModalOpen}
        onClose={closeDocumentFieldEditModal}
      />
    </>
  );
}

DocumentFieldListColumnEditButton.propTypes = {
  documentFieldID: PropTypes.number.isRequired
};

export default DocumentFieldListColumnEditButton;