import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as SettlementSvg } from '../assets/svg-icons/settlements@1x.svg'

const IconSettlements = () => {
  return (
    <SvgIcon fontSize="small">
      <SettlementSvg />
    </SvgIcon>
  )
}

export default IconSettlements;