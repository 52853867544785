import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';

const LocationCreateContentOtherInfo = (props) => {
  const {
    locationFormData,
    locationFormValidation,
    updateLocationFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Other Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="loadingInfo"
            label="Loading Info"
            value={locationFormData.loadingInfo}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.loadingInfo}
            maxLength={255}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="receivingInfo"
            label="Receiving Info"
            value={locationFormData.receivingInfo}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.receivingInfo}
            maxLength={255}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CoreTextField
            name="notes"
            label="Additional Info"
            value={locationFormData.notes}
            onChange={e => updateLocationFormData(e)}
            validationError={locationFormValidation.notes}
            maxLength={4000}
          />
        </Grid>
        <Grid item md={12} xs={12} className="mb-2">
          <CoreCheckboxField
            name="signatureRequired"
            label="Signature Required"
            value={locationFormData.signatureRequired}
            onChange={e => updateLocationFormData(e)}
          />
        </Grid>
      </Grid>
    </Container>
  );
  
}

export default LocationCreateContentOtherInfo;