import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import OrderListStatusButton from './OrderListStatusButton';
import IconDispatch from '../icon/IconDispatch';
import IconDispatched from '../icon/IconDispatched';
import IconCompleted from '../icon/IconCompleted';
import IconMissingPaper from '../icon/IconMissingPaper';
import IconAppForBilling from '../icon/IconAppForBilling';
import IconInvoiced from '../icon/IconInvoiced';
import IconEmergency from '../icon/IconEmergency';
import IconClose from '../icon/IconClose';

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    flexWrap: 'nowrap',
  },
}));

const OrderListStatus = (props) => {
  const {
    orderStatuses,
    orders,
    updateStatusButtonChange,
    listWidth
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth={listWidth} className="mb-2">
        <Grid container justify="space-between" className={classes.gridWrap}>
          <OrderListStatusButton
            count={orders[0] ? orders[0].AllCount : 0}
            name={"All"}
            label={"All"}
            orderStatus={orderStatuses.ShowAll}
            onChange={updateStatusButtonChange}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].UnassignedCount : 0}
            name={"ShowUnassigned"}
            label={"Unassigned"}
            orderStatus={orderStatuses.ShowUnassigned}
            onChange={updateStatusButtonChange}
            icon={<IconEmergency />}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].DispatchedCount : 0}
            name={"ShowDispatched"}
            label={"Dispatched"}
            orderStatus={orderStatuses.ShowDispatched}
            onChange={updateStatusButtonChange}
            icon={<IconDispatch />}
            buttonColor={"#54C7F2"}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].InTransitCount : 0}
            name={"ShowInTransit"}
            label={"In Transit"}
            orderStatus={orderStatuses.ShowInTransit}
            onChange={updateStatusButtonChange}
            icon={<IconDispatched />}
            buttonColor={"#FFA30A"}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].CompletedCount : 0}
            name={"ShowCompleted"}
            label={"Completed"}
            orderStatus={orderStatuses.ShowCompleted}
            onChange={updateStatusButtonChange}
            icon={<IconCompleted />}
            buttonColor={"#86DC76"}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].BillingOnHoldCount : 0}
            name={"ShowBillingOnHold"}
            label={"Invoicing On Hold"}
            orderStatus={orderStatuses.ShowBillingOnHold}
            onChange={updateStatusButtonChange}
            icon={<IconMissingPaper />}
            buttonColor={"#EA5858"}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].ApprovedForBillingCount : 0}
            name={"ShowApprovedForBilling"}
            label={"Approved For Invoicing"}
            orderStatus={orderStatuses.ShowApprovedForBilling}
            onChange={updateStatusButtonChange}
            icon={<IconAppForBilling />}
            buttonColor={"#9575CD"}
          />
          <OrderListStatusButton
            count={orders[0] ? orders[0].InvoicedCount : 0}
            name={"ShowInvoiced"}
            label={"Invoiced"}
            orderStatus={orderStatuses.ShowInvoiced}
            onChange={updateStatusButtonChange}
            icon={<IconInvoiced />}
            buttonColor={"#80CBC4"}
          />      
          <OrderListStatusButton
            count={orders[0] ? orders[0].CancelledCount : 0}
            name={"ShowCancelled"}
            label={"Cancelled"}
            orderStatus={orderStatuses.ShowCancelled}
            onChange={updateStatusButtonChange}
            icon={<IconClose />}
            buttonColor={"#EA5858"}
          />
        </Grid>
      </Container>
    </div>
  );

}

export default OrderListStatus;