import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import { get } from './EmailTemplateService';

import EmailTemplateEditDialog from './EmailTemplateEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const EmailTemplateListColumnEditButton = (props) => {
  const {
    id,
    getEmailTemplates
  } = props;
  
  const classes = useStyles();
  const [emailTemplateFormData, setEmailTemplateFormData] = useState({});
  const [isEmailTemplateEditModalOpen, setIsEmailTemplateEditModalOpen] = useState(false);

  async function openEmailTemplateEditModal() {
    const result = await get(id);
    setEmailTemplateFormData(result);
    setIsEmailTemplateEditModalOpen(true);
  };

  const closeEmailTemplateEditModal = () => {
    setIsEmailTemplateEditModalOpen(false);
    getEmailTemplates();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openEmailTemplateEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <EmailTemplateEditDialog
        id={id}
        isOpen={isEmailTemplateEditModalOpen}
        onClose={closeEmailTemplateEditModal}
        emailTemplateFormData={emailTemplateFormData}
        setEmailTemplateFormData={setEmailTemplateFormData}
      />
    </>
  );
}

EmailTemplateListColumnEditButton.propTypes = {
  id: PropTypes.number.isRequired
};

export default EmailTemplateListColumnEditButton;