import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from './OrderEquipmentService';
import OrderEquipmentEditDialogContent from './OrderEquipmentEditDialogContent';
import { getEquipmentByUnitNum } from '../equipment/EquipmentService';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { setDateTimeToZero } from '../globalFunction';
import { getInternalName } from '../carrier/CarrierService';
import { isContainerNumberValid, getContainerNumberErrorMessage } from '../container_validator/ContainerValidator';

const OrderEquipmentEditDialog = (props) => {
  const {
    orderEquipmentID,
    orderID,
    isOpen,
    onClose
  } = props;

  const [equipmentFormData, setEquipmentFormData] = useState({
    ID: orderEquipmentID,
    UnitNum: '',
    IntermodalOrderID: orderID,
    EquipmentID: null,
    LastFreeReturnDate: null,
    OwnerCarrierName: '',
    UnitType: 3,
    TractorType: 3,
    BranchID: 0,
    EquipmentType: '',
    OwnerType: 2,
    OwnerCarrierID: null,
    ServiceStart: new Date().toLocaleDateString(),
    FuelType: 1,
    OutsideLengthFt: 0,
    OutsideWidthFt: 0,
    NumAxles: 0,
    IsLeased: false,
  });
  const [equipmentFormValidation, setEquipmentFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [containerValidatorErrorMessage, setContainerValidatorErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      get(orderEquipmentID)
      .then(function(response) {
        setEquipmentFormData({ ...response });
      });    
    }
  }, [isOpen, orderEquipmentID]);

  useEffect(() => {
    if (isOpen) {
        setEquipmentFormValidation(buildValidation(equipmentFormData));
    }
  }, [equipmentFormData, isOpen]);

  const updateEquipmentFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    if (e.target.name === "OwnerType" && e.target.value !== equipmentFormData.OwnerType) {
      equipmentFormData.OwnerCarrierID = null;
      equipmentFormData.OwnerCarrierName = "";
    }
    if (e.target.name === 'UnitType' && (e.target.value === 2 || e.target.value === 3)) {
      equipmentFormData.OwnerType = 2;     
    }
    if (e.target.name === 'UnitType') {
      equipmentFormData.EquipmentType = '';     
    }
    equipmentFormData[name] = value;
    equipmentFormData[nameData] = valueData;
    setEquipmentFormData({ ...equipmentFormData });
    if (e.target.name === 'UnitNum' || e.target.name === 'UnitType') { 
      validateContainer();
    }
  };

  const validateContainer = () => { 
    setContainerValidatorErrorMessage('');
    if (equipmentFormData.UnitType === 2) { 
      if (!isContainerNumberValid(equipmentFormData.UnitNum)) { 
        setContainerValidatorErrorMessage(getContainerNumberErrorMessage(equipmentFormData.UnitNum));
      }
    }
  };

  const getEquipmentFormData = async () => {
    if (equipmentFormData.UnitNum) {
      const result = await getEquipmentByUnitNum(equipmentFormData.UnitNum);
      if (result) {
        equipmentFormData.EquipmentID = result.EquipmentID;
        equipmentFormData.UnitNum = result.UnitNum;
        equipmentFormData.OwnerCarrierName = await getInternalName(result.OwnerCarrierID);
        equipmentFormData.UnitType = result.UnitType;
        equipmentFormData.TractorType = result.TractorType ?? 3;
        equipmentFormData.BranchID = result.BranchID;
        equipmentFormData.EquipmentType = result.EquipmentType
        equipmentFormData.OwnerType = result.OwnerType;
        equipmentFormData.OwnerCarrierID = result.OwnerCarrierID;
        equipmentFormData.ServiceStart = result.ServiceStart;
        equipmentFormData.FuelType = result.FuelType;
        equipmentFormData.OutsideLengthFt = result.OutsideLengthFt;
        equipmentFormData.OutsideWidthFt = result.OutsideWidthFt;
        equipmentFormData.NumAxles = result.Axles;
        equipmentFormData.IsLeased = result.IsLeased;
        setEquipmentFormData({ ...equipmentFormData });
      } else {
        resetEquipmentFormData();
      }
    }
  };

  const resetEquipmentFormData = async() => {
    equipmentFormData.EquipmentID = null;
    equipmentFormData.TractorType = 3;
    const branchID = await getMainBranchID();
    equipmentFormData.BranchID = branchID;
    equipmentFormData.ServiceStart = new Date().toLocaleDateString();
    equipmentFormData.FuelType = 1;
    equipmentFormData.OutsideWidthFt = 0;
    equipmentFormData.NumAxles = 0;
    equipmentFormData.IsLeased = false;
    setEquipmentFormData({ ...equipmentFormData });
  };

  const formatEquipment = () => {
    equipmentFormData.LastFreeReturnDate = equipmentFormData.LastFreeReturnDate ? setDateTimeToZero(equipmentFormData.LastFreeReturnDate) : null;
    equipmentFormData.OutsideLengthFt = equipmentFormData.OutsideLengthFt ? parseInt(equipmentFormData.OutsideLengthFt) : 0;
    equipmentFormData.OutsideWidthFt = equipmentFormData.OutsideWidthFt ? parseInt(equipmentFormData.OutsideWidthFt) : 0;
    setEquipmentFormData({ ...equipmentFormData });
  };

  const onUpdate = e => {
    formatEquipment();
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(equipmentFormValidation)) {      
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    } 
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(equipmentFormData);
    setInProgress(false);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    onClose();     
  };

  return (
    isOpen &&
    <OrderEquipmentEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      containerValidatorErrorMessage={containerValidatorErrorMessage}
      equipmentFormData={equipmentFormData}
      equipmentFormValidation={equipmentFormValidation}
      updateEquipmentFormData={updateEquipmentFormData}
      inProgress={inProgress}
      getEquipmentFormData={getEquipmentFormData}
    />
  );
}

const buildValidation = (equipmentFormData) => {
  const {
    UnitNum,
    UnitType,
    OwnerType,
    ServiceStart,
    OwnerCarrierID
  } = equipmentFormData || {};
  const validation = new Validation();
  return {
    UnitNum: (UnitType !== 2 || OwnerType !== 2) && (UnitType !== 3 || OwnerType !== 2) ? validation.isEmpty(UnitNum) : false,
    UnitType: validation.isNumber(UnitType),
    OwnerType: validation.isNumber(OwnerType),
    ServiceStart: validation.isEmpty(ServiceStart),
    EquipmentType: !validation.isEmpty(UnitNum) ? validation.isEmpty(UnitNum) : false,
    OwnerCarrierID: (OwnerType === 1 || OwnerType === 2) && !validation.isEmpty(UnitNum) ? validation.isNumber(OwnerCarrierID) : false
  };
};

export default OrderEquipmentEditDialog;