import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getList } from '../document_type/DocumentTypeService';
import ConfigurationViewDocumentTypeSectionListTable from './ConfigurationViewDocumentTypeSectionListTable';
import DocumentTypeCreateDialog from '../document_type/DocumentTypeCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewDocumentTypeSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isDocumentTypeCreateDialogOpen, setIsDocumentTypeCreateDialogOpen] = useState(false);
  const [documentTypes, setDocumentTypes] = useState({});

  useEffect(() => {
    getList()
      .then(function (response) {
        setDocumentTypes(response);
        setInProgress(false);
      });
  }, []);

  const getDocumentTypesList = () => {
    getList()
      .then(function (response) {
        setDocumentTypes(response);
      });
  };

  const openDocumentTypeCreateDialog = () => {
    setIsDocumentTypeCreateDialogOpen(true);
  };

  const closeDocumentTypeCreateDialog = () => {
    setIsDocumentTypeCreateDialogOpen(false);
    getDocumentTypesList();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Document Types"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Document Types"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"Need to keep track of different document types? You can create additional document types here that you use on record types. If your drivers need visibility, simply toggle the  \"Display in App\" option."}
                <span style={rightFloat}>
                </span>
              </Typography>
            <ConfigurationViewDocumentTypeSectionListTable
              documentTypes={documentTypes}
              getDocumentTypes={getDocumentTypesList}
              setDocumentTypes={setDocumentTypes}
              openDocumentTypeCreateDialog={openDocumentTypeCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Document Types"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Document Types"></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <DocumentTypeCreateDialog
        isOpen={isDocumentTypeCreateDialogOpen}
        onClose={closeDocumentTypeCreateDialog}
      />
    </div>
  );

}

export default ConfigurationViewDocumentTypeSection;