import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';

import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import OrderEditContentInfo from './OrderEditContentInfo';
import OrderEditContentVesselContainerInfo from './OrderEditContentVesselContainerInfo';
import OrderEditContentLocations from './OrderEditContentLocations';

const OrderEditContent = (props) => {
  const {
    orderFormData,
    onUpdate,
    onClose,
    errorMessage,
    orderFormValidation,
    updateOrderFormData,
    inProgress,
    initialProviderID
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  return (
    <form onSubmit={onUpdate} style={{position:"relative"}}>
      <OrderEditContentInfo
        orderFormData={orderFormData}
        orderFormValidation={orderFormValidation}
        updateOrderFormData={updateOrderFormData}
        customerName={orderFormData.Customer}
      />
      <br />
      <OrderEditContentLocations
        orderFormData={orderFormData}
        updateOrderFormData={updateOrderFormData}
        orderFormValidation={orderFormValidation}
        initialProviderID={initialProviderID}
      />
      {(orderFormData.Type === "Import" || orderFormData.Type === "Export") && 
      <br/>}
      <OrderEditContentVesselContainerInfo
        orderFormData={orderFormData}
        orderFormValidation={orderFormValidation}
        updateOrderFormData={updateOrderFormData}
      />
      <br/>
      {
        errorMessage && 
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress &&
        <CoreCreateContentStickyFooterButtons
          onClose={onClose}
          hasPermissionRequiredToEdit={orderFormData.Status === "Invoiced"}
          changesDetails={"Order " + orderFormData.OrderNum + " details"}
        />
      }
    </form>
  );

}

export default OrderEditContent;