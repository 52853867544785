import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreDateTimeField from '../core/CoreDateTimeField';
import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import LocationField from '../location/LocationField';
import IntermodalEventTypeField from './IntermodalEventTypeField';
import EnvaseTraceProviderFieldDropdown from '../envase_trace/EnvaseTraceProviderFieldDropdown';
import CoreNumberField from '../core/CoreNumberField';

const IntermodalEventEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    eventFormData,
    onUpdate,
    errorMessage,
    eventFormValidation,
    updateEventFormData,
    inProgress,
    traceCredentials,
    initialProviderID
  } = props;

  const buildTraceProviderNotifications = () => {
    var notification = [];
    if (eventFormData.LocationTemplateID !== null && eventFormData.EnvaseTraceProviderID === null) { 
      notification.push(
        <Grid item md={12} xs={12}>
          <Alert severity="warning">{"Trace Provider not linked to location"}</Alert>
        </Grid>
      )
    } else if (eventFormData.EnvaseTraceProviderID !== null && !traceCredentials.IsEnabled) { 
      notification.push(
        <Grid item md={12} xs={12}>
          <Alert severity="warning">{"Trace Provider credentials do not exist, contact your admin to update"}</Alert>
        </Grid>
      )
    } else if (eventFormData.EnvaseTraceProviderID !== null && initialProviderID === eventFormData.EnvaseTraceProviderID && traceCredentials.IsEnabled) { 
      notification.push(
        <Grid item md={12} xs={12}>
          <Alert severity="success">{"Trace Provider linked"}</Alert>
        </Grid>
      )
    }
    return notification;
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Event</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <IntermodalEventTypeField
                name="EventType"
                label="Type"
                value={eventFormData.EventType}
                onChange={e => updateEventFormData(e)}
                isRequired={true}
                validationError={eventFormValidation.EventType}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={6} xs={6}>
            </Grid>
            <Grid item md={9} xs={9}>
              <LocationField
                name="LocationTemplateID"
                label="Location Name"
                value={eventFormData.LocationTemplateID}
                locationForEdit={eventFormData.LocationInternalName}
                onChange={e => updateEventFormData(e)}
                validationError={eventFormValidation.LocationTemplateID}
                isRequired={true}
              />
            </Grid>
            <Grid item md={3} xs={3}>
              {eventFormData.EventIndex !== 0 && <CoreNumberField
                name="Miles"
                label="Miles"
                isDecimal={true}
                minValue={0}
                value={eventFormData.Miles}
                onChange={e => updateEventFormData(e)}
              />}
            </Grid>
            {eventFormData.LocationTemplateID && <Grid item md={12} xs={12}>
              <EnvaseTraceProviderFieldDropdown
                name="EnvaseTraceProviderID"
                label="Trace Provider"
                value={eventFormData.EnvaseTraceProviderID}
                onChange={e => updateEventFormData(e)}
                isRequired={false}
              />
              </Grid>
            }
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="AppointmentNumber"
                label="Appt. #"
                value={eventFormData.AppointmentNumber}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>        
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="Note"
                label="Note"
                value={eventFormData.Note}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateTimeField
                name="AppointmentDateStart"
                label="Appt. Date & Time Start"
                value={eventFormData.AppointmentDateStart}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateTimeField
                name="AppointmentDateEnd"
                label="Appt. Date & Time End"
                value={eventFormData.AppointmentDateEnd}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateTimeField
                name="ActualArrivalDate"
                label="Actual Arrival Date & Time"
                value={eventFormData.ActualArrivalDate}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateTimeField
                name="ActualDepartDate"
                label="Actual Departure Date & Time"
                value={eventFormData.ActualDepartDate}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>  
              <CoreCheckboxField
                name="IsAppointmentExempt"
                label="Exempt Appointment"
                value={eventFormData.IsAppointmentExempt}
                onChange={e => updateEventFormData(e)}
              />
            </Grid>
            {errorMessage &&
              <Grid item md={12} xs={12} className="mt-3">
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Grid>}
            {buildTraceProviderNotifications()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={inProgress} type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default IntermodalEventEditDialogContent;