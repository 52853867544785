import React, { useState } from 'react';
import AuthenticationPage from './AuthenticationPage';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Link from '@material-ui/core/Link';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PhoneIcon from '@material-ui/icons/Phone';

const AuthenticationCreateAccount = () => {

  const [data, setData] = useState({
    fullName: '',
    organizationName: '',
    emailAddress: '',
    phone: '',
    country: 'United States'
  });

  const handleDataChange = e => {
    const { name, value } = e.target;
    data[name] = value;
    setData({ ...data });
  }

  const handleSubmit = e => {
    e.preventDefault();
  }

  const createAccountForm = <div>
    <Typography variant="h5" gutterBottom>
      Web-Based Trucking & Freight Broker TMS Software
    </Typography>
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="standard-adornment-password">Full Name</InputLabel>
            <Input
              name="fullName"
              type="email"
              value={data.fullName}
              onChange={handleDataChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled={true} aria-label="Person icon">
                    <SupervisedUserCircleIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="standard-adornment-password">Organization Name</InputLabel>
            <Input
              name="organization"
              type="email"
              value={data.organization}
              onChange={handleDataChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled={true} aria-label="Organization icon">
                    <BusinessCenterIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
            <Input
              name="emailAddress"
              type="email"
              value={data.emailAddress}
              onChange={handleDataChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled={true} aria-label="Email Address icon">
                    <AlternateEmailIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="standard-adornment-password">Phone</InputLabel>
            <Input
              name="phone"
              type="phone"
              value={data.phone}
              onChange={handleDataChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled={true} aria-label="Phone icon">
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="standard-adornment-password">Country</InputLabel>
            <Select
              name="country"
              id="country-select"
              value={data.country}
              onChange={handleDataChange}>
              <MenuItem value={'United States'}>United States</MenuItem>
              <MenuItem value={'Canada'}>Canada</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} className="mt-3">
          <Button type="submit" variant="contained" color="primary" size="large">
            Sign Up
            </Button>
        </Grid>
        <Grid item md={12} xs={12} className="mt-3">
          <Typography gutterBottom>
            Have an account?&nbsp;
            <Link href="login">
              Sign In
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  </div>

  return (
    <AuthenticationPage form={createAccountForm}/>
  );

}

export default AuthenticationCreateAccount;