import React, { useState, useEffect } from 'react';

import IncomingLoadListAcceptDialogContent from './IncomingLoadListAcceptDialogContent';
import { getMatchingLocation, getCustomer } from './IncomingLoadService';

const IncomingLoadListAcceptDialog = (props) => {
  const {
    shipperPortalOrderAssignmentID,
    acceptFunction,
    isOpen,
    onClose,
    reference,
    openConfirmationDialog
  } = props;

  const [inProgress, setInProgress] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptDialogFormData, setAcceptDialogFormData] = useState({
    customerID: null,
    customer: '',
  });
  const [locationFormData, setLocationFormData] = useState({
    originLocationMapped: false,
    destinationLocationMapped: false,
  });
  const [matchedOriginLocation, setMatchedOriginLocation] = useState(null);
  const [matchedDestinationLocation, setMatchedDestinationLocation] = useState(null);

  useEffect(() => {
    getCustomer(shipperPortalOrderAssignmentID)
      .then(function (response) {
        setAcceptDialogFormData(c => ({ ...c, customerID: response.ID }));
        setAcceptDialogFormData(c => ({ ...c, customer: response.InternalName }));
        setInProgress(false);
      });
    getMatchingLocation(shipperPortalOrderAssignmentID, true)
      .then(function (response) {
        if (response !== null) {
          setMatchedOriginLocation(response);
          setLocationFormData(c => ({ ...c, originLocationMapped: true}));
        }
        setInProgress(false);
      });
    getMatchingLocation(shipperPortalOrderAssignmentID, false)
      .then(function (response) {
        if (response !== null) {
          setMatchedDestinationLocation(response);
          setLocationFormData(c => ({ ...c, destinationLocationMapped: true}));
        }
        setInProgress(false);
      });
  }, [shipperPortalOrderAssignmentID]);

  const onAccept = e => {
    setInProgress(true);
    e.preventDefault();
    processAcceptOrder();    
  };

  const processAcceptOrder = async () => {
    setErrorMessage('');
    const result = await acceptFunction(
      shipperPortalOrderAssignmentID, acceptDialogFormData.customerID, 
      locationFormData.originLocationMapped, locationFormData.destinationLocationMapped,
      matchedOriginLocation, matchedDestinationLocation
    );
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    openConfirmationDialog(result);
  };

  const handleRadioChange = e => {
    const {
      name,
      value,   
    } = e.target;
    locationFormData[name] = (String(value).toLowerCase() === 'true');;
    setLocationFormData({ ...locationFormData });
  };

  return (
    isOpen &&
    <IncomingLoadListAcceptDialogContent
    shipperPortalOrderAssignmentID={shipperPortalOrderAssignmentID}
      onAccept={onAccept}
      isOpen={isOpen}
      onClose={onClose}
      reference={reference}
      errorMessage={errorMessage}
      acceptDialogFormData={acceptDialogFormData}
      inProgress={inProgress}
      locationFormData={locationFormData}
      handleRadioChange={handleRadioChange}
      matchedOriginLocation={matchedOriginLocation}
      matchedDestinationLocation={matchedDestinationLocation}
    />
  );
}

export default IncomingLoadListAcceptDialog;