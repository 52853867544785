import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Contact/' + token + '/';

export async function getCarrierContactsList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCarrierContactsForSpinner');
  const response = result.data;
  for (const contact of response) {
    if (contact){
      contact.Name = contact.Name.trim();
    }
  }
  return response;
};

export async function getCustomerContactsList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCustomerContactsForSpinner');
  const response = result.data;
  for (const contact of response) {
    contact.Name = contact.Name.trim();
    contact.Position = contact.Position.trim();
    contact.Email = contact.Email.trim();
    contact.Phone = contact.Phone.trim();
  }
  return response;
};

export async function get(contactID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + contactID);
  result.data[0].Name = result.data[0].Name.trim();
  result.data[0].Position = result.data[0].Position.trim();
  result.data[0].Email = result.data[0].Email.trim();
  result.data[0].Phone = result.data[0].Phone.trim();
  result.data[0].Extension = result.data[0].Extension.trim();
  return result.data[0];
};

export async function update(contactID, params) {
  params.id = contactID;
  params.Phone = params.Phone.replace(/\D/g,'');
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + contactID + '/Update', null, params);
    return result;
};

export async function deleteContact(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/delete');
  return result;
};

export async function toggleFavorite(id, isFav) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + "/ToggleFavorite?isFav=" + isFav);
  return result;
}