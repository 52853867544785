import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Carrier/' + token + '/';

export async function getCarriersList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCarriersForSpinner');
  const response = result.data;
  for (const carrier of response) {
    carrier.Name = carrier.InternalName.trim();
  }
  return response;
};

export async function getCarrierEquipmentList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetVendorEquipmentForSpinner');
  const response = result.data;
  for (const carrier of response) {
    carrier.Name = carrier.InternalName.trim();
  }
  return response;
};

export async function getOwnerOperatorsList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetOwnerOperatorsForSpinner');
  const response = result.data;
  for (const ownerOperator of response) {
    ownerOperator.Name = ownerOperator.InternalName.trim();
  }
  return response;
};

export async function getInternalName(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetInternalName');
  return result.data[0] ? result.data[0].InternalName : "";
};