import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const CoreSummaryFooter = (props) => {
  const useStyles = makeStyles((theme) => ({
    summaryFooter: {
      position: 'fixed',
      bottom: '0',
      width: '400px',
      margin: '0',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #ededed',
      boxShadow: '2px 13px 10px 4px #333333',
      zIndex: '3'
    },
    fixedFooterSpacer: {
      height: '70px'
    },
    root: {
      '& > *': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  const {
    editRecord,
    openRecord,
    disableEdit,
    enableMore,    
    onClickMore,
  } = props; 

  return (
    <div>
      <div className={classes.summaryFooter}>
        <Container className="mt-1 mb-1">
          <Grid container spacing={0} className={classes.root}>        
            <Button size="small" variant="outlined" onClick={openRecord} color="primary" startIcon={<OpenInBrowserIcon/>}>
              FULL VIEW
            </Button>        
            <Button size="small" variant="contained" onClick={editRecord} color="primary" disabled={disableEdit} startIcon={<EditIcon/>}>
              EDIT
            </Button>           
            {enableMore && <Button size="small" variant="outlined" onClick={onClickMore} color="primary">
              MORE
            </Button>}          
          </Grid>
        </Container>         
      </div>
      <div className={classes.fixedFooterSpacer} />
    </div>
  );
}

export default CoreSummaryFooter;
