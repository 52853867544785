import React, {useRef} from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreDataGridToolbar from './CoreDataGridToolbar';
import { useReactToPrint } from "react-to-print";
import IconButton from '@material-ui/core/IconButton';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

const CoreDataTableList = (props) => {
  const {
    data,
    columns,
    params,
    showInactive,
    hideInactive,
    exportList,
    changePage,
    changeRowsPerPage,
    sort,
    filterData,
    updateFilterData,
    onCellClick,
    onRowClick,
    onRowEnter,
    onRowLeave,
    defaultSortDir,
    defaultSortBy,
    showObsoleteButton,
    listWidth,
    setListWidth,
    rowCount,
    disableExportList
  } = props;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const rowClicked = (e) => {
    if (onRowClick){
      onRowClick(e);
    }
  };

  const cellClicked = (e) => {
    if (onCellClick) {
      onCellClick(e);
    }
  };

  const rowEntered = (e) => { 
    if (onRowEnter) { 
      onRowEnter(e);
    }
  };

  const rowLeave = (e) => { 
    if (onRowLeave) { 
      onRowLeave(e);
    }
  };

  const toggleWidth = () => {
    if (listWidth === 'xl') {
      setListWidth('false');
    } else {
      setListWidth('xl');
    }
  };

  const [defaultSort,] = React.useState([
    {
      field: defaultSortBy || '',
      sort: defaultSortDir || '',
    }
  ]);

  const handldeSortModelChange = (e) => {
    if (e.sortModel[0].field === params.sortBy) {
      if (e.sortModel[0].sort === 'asc' && params.isASC) {
        return;
      }
      if (e.sortModel[0].sort === 'dsc' && !params.isASC) {
        return;
      }
    }
    if (e.sortModel[0]) {
      sort(e.sortModel[0]);
    }
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth={listWidth ? listWidth : 'xl'} className="" component={Paper}>
      <CoreDataGridToolbar
        filterData={filterData}
        updateFilterData={updateFilterData}
        exportList={exportList}
        columns={columns}
        printTable={handlePrint}
        params={params}
        showInactive={showInactive}
        hideInactive={hideInactive}
        showObsoleteButton={showObsoleteButton}
        disableExportList={disableExportList}
      />
      <DataGrid
        ref={componentRef}
        getRowId={(row) => row.ID}
        autoHeight={true}
        rows={data}
        columns={columns}
        rowCount={rowCount ?? params.count}
        page={params.page - 1}
        pageSize={params.amount}
        onPageChange={(e) => {changePage(e.page)}}
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        sortModel={defaultSort}
        onSortModelChange={handldeSortModelChange}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}                
        sortingMode={'server'}
        paginationMode={'server'}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onCellClick={(e) => cellClicked(e)}
        onRowClick={(e) => rowClicked(e)}
        onRowEnter={e => rowEntered(e)}
        onRowLeave={e => rowLeave(e)}
        state={{
          keyboard: {
            cell: null,
            columnHeader: null,
          }
        }}
        />
        {listWidth !== undefined && <IconButton style={{ paddingTop: '0' }} onClick={toggleWidth} color="default" aria-label="Expand List Width" component="span">
          <AspectRatioIcon />
        </IconButton>}
    </Container>
  );
}

export default CoreDataTableList;