
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Equipment/' + token + '/';

export async function getList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetList', params);
  const response = result.data;
  if (!response) {
    return [];
  }
  for (const equipment of response) {
    if (equipment.UnitType === 0) {
        equipment.UnitType = "Tractor";
    } else if (equipment.UnitType === 1) {
        equipment.UnitType = "Trailer";
    } else if (equipment.UnitType === 2) {
        equipment.UnitType = "Container";
    } else if (equipment.UnitType === 3) {
        equipment.UnitType = "Chassis";
    }
    if (equipment.OwnerType === 0) { 
      equipment.OwnerName = "Company";
    } 
  }
  return response;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ExportList', params);
  return result;
};

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/getFull');
  // Unit Type formatting:
  if (result.data[0].UnitType === 0) {
    result.data[0].UnitType = "Tractor";
  } else if (result.data[0].UnitType === 1) {
    result.data[0].UnitType = "Trailer";
  } else if (result.data[0].UnitType === 2) {
    result.data[0].UnitType = "Container";
  } else if (result.data[0].UnitType === 3) {
    result.data[0].UnitType = "Chassis";
  }
  // Tractor Type formatting:
  if (result.data[0].TractorType === 0) {
    result.data[0].TractorType = "Long-Haul (Standard Revenue)";
  } else if (result.data[0].TractorType === 1) {
    result.data[0].TractorType = "Local (Standard Revenue)";
  } else if (result.data[0].TractorType === 2) {
    result.data[0].TractorType = "Local (No Revenue)";
  } else if (result.data[0].TractorType === 3) {
    result.data[0].TractorType = "Local (Hourly Revenue)";
  }
  // Owner formatting:
  if (result.data[0].OwnerType === 0) { 
    result.data[0].OwnerCarrierName = "Company";
  }
  return result.data[0];
};

export async function getForUpdate(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/getFull');
  result.data[0].UnitType = result.data[0].UnitType.toString();
  return result.data[0];
};

export async function getEquipmentByUnitNum(unitNum) {
  var params = {
    unitNum: unitNum
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEquipmentByUnitNum', params);
  return result.response;
};

export async function getNotes(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetNotes');
  return result.data;
};

export async function getForSpinner() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinner');
  const response = result.data;
  for (const equipment of response) {
    equipment.Name = equipment.UnitNum.trim();
  }
  return response;
};

export async function create(EquipmentFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', EquipmentFormData);
  return result;
};

export async function Update(EquipmentFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', EquipmentFormData);
  return result;
};