import { Route } from 'react-router-dom';
import DragNDropExample from './drag-n-drop-example';
import Itinerary from './Itinerary';

const ItineraryRouter = () => {
  return (
    <>
      <Route exact path="/spark/itinerary" component={Itinerary} />
      <Route exact path="/spark/itinerary/example" component={DragNDropExample} />
    </>
  );

}

export default ItineraryRouter
