import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreTextField from '../core/CoreTextField';
import PayableChargeTypeField from '../payableChargeType/PayableChargeTypeField';

const BillViewExpenseCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    payableFormData,
    onCreate,
    errorMessage,
    payableFormValidation,
    updatePayableFormData
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Expense Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} xs={12}>
              <PayableChargeTypeField
                label="Expense Item Type"   
                value={payableFormData.payableChargeTypeName}
                name="payableChargeTypeName"
                nameData="payableChargeTypeID"
                valueData={payableFormData.payableChargeTypeID}
                isRequired={true}
                validationError={payableFormValidation.payableChargeTypeID}
                onChange={e => updatePayableFormData(e)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="description"
                label="Description"
                value={payableFormData.description}
                onChange={e => updatePayableFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="units"
                label="Quantity"
                minValue={0}
                isDecimal={true}
                value={payableFormData.units}
                validationError={payableFormValidation.units}
                onChange={e => updatePayableFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="unitRate"
                label="Rate"
                minValue={0}
                isCurrency={true}
                value={payableFormData.unitRate}
                validationError={payableFormValidation.unitRate}
                onChange={e => updatePayableFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={false}>
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="subtotal"
                label="Total"
                minValue={0}
                isCurrency={true}
                disabled={true}
                value={payableFormData.subtotal}
                validationError={payableFormValidation.subtotal}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default BillViewExpenseCreateDialogContent;