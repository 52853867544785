import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const CoreDataGridSearch = (props) => {
  const {
    value,
    ...rest
  } = props;

  const buildStartAdornment = () => {
    return (
      <InputAdornment position="start">
        <SearchIcon style={{fill: "gray"}}/>
      </InputAdornment>);
  };

  return (
    <FormControl fullWidth>
      <InputLabel style={{ left: '31px'}}
        shrink={value ? true : false}>
        {"Search"}
      </InputLabel>
      <Input
        type="text"
        value={value}
        startAdornment={buildStartAdornment()}
        {...rest}
      />
    </FormControl>
  );
}

CoreDataGridSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default CoreDataGridSearch;
