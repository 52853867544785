import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DocumentSvg } from '../assets/svg-icons/document@1x.svg';

const IconDocument= () => {
    return (
        <SvgIcon fontSize="small">
            <DocumentSvg />
        </SvgIcon>    
    )
}
export default IconDocument;
