import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  widthSize: {
    width: '100%',
  },
}));

const EquipmentOwnerTypeDropdownField = (props) => {
  const {
    value,
    onChange
  } = props;
  const classes = useStyles();

  const [menuValue, setMenuValue] = useState('');

  useEffect(() => {
    setMenuValue(value);
  }, [value]);

  const handleChange = (event) => {
    const dispatchEvent = {
        target: {
          name: 'OwnerType',
          value: event.target.value
        }
      };
    onChange(dispatchEvent);
  };

  return (
    <FormControl className={classes.widthSize}>
      <InputLabel>Owned By</InputLabel>
      <Select 
        value={menuValue}
        onChange={(e, newValue) => { setMenuValue(e.target.value); handleChange(e) }}
      >
        <MenuItem value={0}>Company</MenuItem>
        <MenuItem value={1}>Owner/Operator</MenuItem>
        <MenuItem value={2}>Vendor</MenuItem>
      </Select>
    </FormControl>
  );
};

export default EquipmentOwnerTypeDropdownField;