import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const CoreToggleField = (props) => {
  const {
    label,
    value,
    name,
    onChange
  } = props;

  const [toggleSelected, setToggleSelected] = useState(value);

  useEffect(() => {
    setToggleSelected(value);
  }, [value]);

  const onToggleEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(dispatchEvent);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={toggleSelected}
            onChange={(e, newValue) => {
                onToggleEvent(newValue)         
                setToggleSelected(newValue)
              }              
            }
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}

export default CoreToggleField;