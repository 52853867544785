import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const QuickbooksSyncSuppressDialogContent = (props) => {
  const {
    onSuppress,
    isOpen,
    onClose
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onSuppress}>
        <DialogTitle id="form-dialog-title">Confirm Suppressing Transaction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that this action will hide this transaction from all future syncs and cannot be undone.<br/><br/>
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default QuickbooksSyncSuppressDialogContent;