import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Province/' + token + '/';

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  const response = result.data;
  for (const province of response) {
    province.Name = province.ShortName.trim();
  }
  return response;
};