import React, { useState, useEffect } from "react";
import { EditAppointment } from "@envasetechnologies/precheck-components";
import { getToken } from '../order/ReservationService'
import { getTIN } from '../tax/TaxService';

export default function MyAppointmentEditDetails(props) {
  const { isOpen, editAppt, handleEditAppointmentClicked } = props;

  const [preCheckToken, setPreCheckToken] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [motorCarrierTIN, setMotorCarrierTIN] = useState('');

  useEffect(() => {
    async function getPreCheckToken() {
      const response = await getToken();
      if (response) {
        setPreCheckToken(response);
      }
    };
  
    async function getTaxIdentificationNumber() {
      const response = await getTIN();
      if (response) {
        setMotorCarrierTIN(response);
        setIsDataLoaded(true);
      }
    }
  
    if (isOpen) {
      getPreCheckToken().then(() => {
        getTaxIdentificationNumber();
      });
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsDataLoaded(false);
    handleEditAppointmentClicked(null);
  };

  return (
    <>
      {isDataLoaded &&
      <EditAppointment
        isOpen={isOpen && isDataLoaded}
        onClose={() => handleClose()}
        gateTransactionId={editAppt.gateTransactionId}
        motorCarrierTIN={motorCarrierTIN}
        accessToken={preCheckToken}
      />
      }
    </>
  );
};