import DispatchListTable from './DispatchListTable';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import OrderCreateButton from '../order/OrderCreateButton';

const DispatchList = () => {
  return (
    <div>
      <CorePageHeader
        title="Dispatch"
        breadCrumb="Home / ... / Dispatch List"
        createButton={
          <OrderCreateButton
            onCloseRoute="/spark/dispatch"
          />
        }
        />
      <Container maxWidth="false" className="mt-4">
        <DispatchListTable />
      </Container>
    </div>
  );

}

export default DispatchList;