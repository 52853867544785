import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreTextField from '../core/CoreTextField';
import ReceivableChargeTypeField from '../receivable_charge_type/ReceivableChargeTypeField';

const RevenueEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    revenueFormData,
    onUpdate,
    errorMessage,
    revenueFormValidation,
    updateRevenueFormData,
    inProgress
  } = props;

  const updateUnits = data => {
    const dispatchUnits = {
      target: {
        name: "Units",
        value: data.UnitsDefaultValue
      }
    };
    const dispatchUnitRate = {
      target: {
        name: "UnitRate",
        value: data.UnitRateDefaultValue
      }
    };
    updateRevenueFormData(dispatchUnits);
    updateRevenueFormData(dispatchUnitRate);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Revenue Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} xs={12}>
              <ReceivableChargeTypeField
                label="Type"
                name="ReceivableChargeTypeName"
                value={revenueFormData.ReceivableChargeTypeName}
                nameData="ReceivableChargeTypeID"
                valueData={revenueFormData.ReceivableChargeTypeID}
                onChange={e => updateRevenueFormData(e)}
                responseObject={updateUnits}
                isRequired={true}
                validationError={revenueFormValidation.ReceivableChargeTypeID}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Description"
                label="Description"
                value={revenueFormData.Description}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="Units"
                label="Quantity"
                isCurrency={false}
                value={revenueFormData.Units}
                validationError={revenueFormValidation.Units}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="UnitRate"
                label="Rate"
                isCurrency={true}
                value={revenueFormData.UnitRate}
                validationError={revenueFormValidation.UnitRate}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={false}>
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="Subtotal"
                label="Total"
                isCurrency={true}
                disabled={true}
                value={revenueFormData.Subtotal}
                validationError={revenueFormValidation.Subtotal}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={inProgress} type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default RevenueEditDialogContent;