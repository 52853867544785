import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Account/' + token + '/';

export async function getPersonnelID() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPersonnelID');
  if (result.data) {
    return result.data[0].PersonnelID;
  }
  return null;
};

export async function getInternalName() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetInternalName');
  if (result.data) {
    return result.data[0].InternalName;
  }
  return null;
};

export async function logout() {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Logout');
  return result;
};

export async function getOrganizationID(){
  const token = localStorage.getItem('token');
  const uri = 'Account/' + token + '/GetOrganizationID';
  const httpService = new HttpService();
  const result = await httpService.get(uri);
  return result.response;
}