import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getForSpinnerCompare } from './LocationService';
import LocationCreateDialog from './LocationCreateDialog';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';

const LocationField = (props) => {

  const {
    label,
    value,
    name,
    locationForEdit,
    isRequired,
    validationError,
    onChange,
    responseObject,
    isDisabled,
    ...rest
  } = props;

  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [persistedLocationName, setPersistedLocationName] = useState('');
  const [showAddButton, setShowAddButton] = useState(false);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [branch, setBranch] = useState('');
 

  useEffect(() => {
    async function getLocationList() {
      const result = await getForSpinnerCompare(locationForEdit);
      setLocationList(result);
      setIsLoading(false);
    }
    getLocationList();
  }, [locationForEdit]);

  useEffect(() => {
    if (value === null && responseObject) {
      responseObject(value);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  async function getLocationListForSpinner(compareValue) {
    const result = await getForSpinnerCompare(compareValue);
    setLocationList(result);
  };

  const onLocationSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.LocationTemplateID : null,
        envaseTraceProviderID: newValue ? newValue.EnvaseTraceProviderID : null
      }
    };
    onChange(dispatchEvent);
    sendResponseObject(newValue);
  };

  const sendResponseObject = newValue => {
    if (responseObject) {
      responseObject(newValue);
    }
  };

  const getOptionSelected = (option, value) => {
    if (option && option.LocationTemplateID === value) {
      return option.InternalName;
    }
  };

  const getDefaultValue = () => {
    if (value && responseObject) {
      sendResponseObject(locationList.find(location => location.LocationTemplateID === value));
    }
    const location = locationList
      .find(location => location.LocationTemplateID === value);
    if (location) {
      return location.InternalName
    }
    return '';
  };

  const openLocationDialog = () => { 
    setIsLocationDialogOpen(true);
  };

  const getShowAddButton = (event, inputValue) => {
    const obj = locationList.find(l => l.InternalName.toLowerCase() === inputValue.toLowerCase());
    if (inputValue && obj === undefined) {
      setPersistedLocationName(inputValue);
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  };

  const closeLocationDialog = async (responseID, locationName) => {
    if (!isNaN(responseID)) {
      setIsLoading(true);
      const result = await getForSpinnerCompare(locationName);
      setLocationList(result);
      const obj = result.find(l => l.LocationTemplateID === responseID);
      onLocationSelected(obj);
      setIsLoading(false);
    }
    setIsLocationDialogOpen(false);
  };

  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };

  return (
    <>
    {!isLoading &&
    <CoreAutocompleteField
      label={label}
      class="locationnames"
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onLocationSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={locationList}
      openCreateDialogOpen={openLocationDialog}
      showAddButton={showAddButton}
      getShowAddButton={getShowAddButton}
      getList={getLocationListForSpinner}
      showSecondaryText={true}
      isDisabled={isDisabled}
      {...rest}
    />
    }
    <LocationCreateDialog
      persistedLocationName={persistedLocationName}
      isOpen={isLocationDialogOpen}
      onClose={closeLocationDialog}
      branch={branch}
    />
    </>
  );

}

LocationField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  locationForEdit: PropTypes.string,
  class:PropTypes.string
};

export default LocationField;