import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import IconUnitNumber from '../icon/IconUnitNumber';
import IconUnitType from '../icon/IconUnitType';
import IconTractorType from '../icon/IconTractorType';
import IconOwner from '../icon/IconOwner';
import CoreTextDisplayField from '../core/CoreTextDisplayField';

const EquipmentViewGeneralSection = (props) => {
  const {
    equipment
  } = props;

  return (
    <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
      <Grid container spacing={2}>
        <Grid container className="mt-2 mb-2">
          { equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="Manufacturer"
                value={equipment.Manufacturer}
                icon={
                  <IconUnitNumber />
                }
              />
            </Grid>
          }
          { equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="Vehicle Model"
                value={equipment.Model}
                icon={
                  <IconUnitType />
                }
              />
            </Grid>
          }
          { equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="Vehicle Year"
                value={equipment.ModelYear}
                icon={
                  <IconTractorType />
                }
              />
            </Grid>
          }
          { equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
                <CoreTextDisplayField
                  label="No. of Axles"
                  value={equipment.Axles}
                  icon={
                    <IconOwner />
                  }
                />
            </Grid>
          }
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Size"
              value={equipment.OutsideLengthFt}
              icon={
                <IconOwner />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EquipmentViewGeneralSection;