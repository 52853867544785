import React from 'react';

import PointToPointTemplateLineEditCheckDialogContent from './PointToPointTemplateLineEditCheckDialogContent.js';

const PointToPointTemplateLineEditCheckDialog = (props) => { 
  const { 
    isOpen,
    openEditDialog,
    createNewTemplate,
    onClose,
  } = props;

  return ( 
    isOpen &&
    <PointToPointTemplateLineEditCheckDialogContent
      isOpen={isOpen}
      onClose={onClose}
      openEditDialog={openEditDialog}
      createNewTemplate={createNewTemplate}      
    />
  );
};

export default PointToPointTemplateLineEditCheckDialog;