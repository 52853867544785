import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconTrash from '../icon/IconTrash';

import CoreDeleteDialog from '../core/CoreDeleteDialog'

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const CoreListColumnDeleteButton = (props) => {
  const {
    deleteID,
    deleteFunction,
    getList,
    reference
  } = props;
  const classes = useStyles();
  const [isCoreDeleteModalOpen, setIsCoreDeleteModalOpen] = useState(false);

  const openCoreDeleteModal = () => {
    setIsCoreDeleteModalOpen(true);

  };

  const closeCoreDeleteModal = () => {
    setIsCoreDeleteModalOpen(false);
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openCoreDeleteModal}>
        <IconTrash />&nbsp;
        <span>DELETE</span>
      </IconButton> 
      <CoreDeleteDialog
        deleteID={deleteID}
        reference={reference}
        deleteFunction={deleteFunction}
        onDeleteSuccess={getList}
        isOpen={isCoreDeleteModalOpen}
        onClose={closeCoreDeleteModal}
      />
    </>
  );
}

CoreListColumnDeleteButton.propTypes = {
  deleteID: PropTypes.number.isRequired,
  deleteFunction: PropTypes.func.isRequired,
};

export default CoreListColumnDeleteButton;