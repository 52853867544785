import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noRecords: {
    textAlign: 'center',
    padding: '30px 20px'
  }
}));

const CoreNoRecordsFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.noRecords}>
      No records found.
    </div>
  );
}

export default CoreNoRecordsFound;
