import React, { useState, useEffect } from "react";
import { enableEnvase } from "./AdminService";
import PersonnelEnableLoginDialogContent from "./PersonnelEnableLoginDialogContent";

const PersonnelEnableLoginDialog = (props) => {
  const { 
    open, 
    personnel, 
    handleClose 
  } = props;

  const [error, setError] = useState();
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const [personnelEmailAddress, setPersonnelEmailAddress] = useState('');
  const [personnelInternalName, setPersonnelInternalName] = useState('');

  useEffect(() => {
    setPersonnelEmailAddress(personnel.Email);
    setPersonnelInternalName(personnel.InternalName);
  }, [personnel]);

  const onClose = () => {
    setError(null);
    setSuccess(null);
    handleClose();
  };

  const onEnableUser = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    setLoading(true);
    await processRegisterEnvase();
    setLoading(false);
  };

  const processRegisterEnvase = async () => {
    const response = await enableEnvase(personnel.PersonnelID);
    if (response.response === 'Success') {
      setSuccess(
        `A confirmation email has been sent to ${personnel.InternalName}`
      );
    } else {
      if (response.error) {
        setError(response.error);
      } else {
        setError("An error has occured");
      }
      return;
    }
  };

  return (
    <PersonnelEnableLoginDialogContent
      emailAddress={personnelEmailAddress}
      internalName={personnelInternalName}
      loading={loading}
      error={error}
      success={success}
      onEnableUser={onEnableUser}
      onClose={onClose}
      isOpen={open}
    />
  );
};

export default PersonnelEnableLoginDialog;