import React, { useEffect, useState } from 'react';
import { Button, Grid, Dialog, 
    DialogActions, DialogContent, 
    DialogTitle
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import { getEvents } from './OrderService';
import { format } from 'date-fns';
import CoreCheckboxField from '../core/CoreCheckboxField';
import { printBOL } from '../intermodal_invoice/IntermodalInvoiceService';

const OrderPrintBOLDialog = (props) => { 
  const { 
    open,
    onClose,
    orderID
  } = props;

  const [inProgress, setInProgress] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [intermodalLegs, setIntermodalLegs] = useState([]);
  const [selectedLegs, setSelectedLegs] = useState([]);

  useEffect(() => {
    setInProgress(true);
    if (open) {
      getEvents(orderID)
        .then(function (response) {
          setIntermodalLegs(response);
          if (response.length === 1) {
            response[0].TagForPrinting = true;
            setIntermodalLegs([...response]);
            selectedLegs.push(response[0].ID);
            setSelectedLegs([...selectedLegs]);
          }
          setInProgress(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const closeDialog = () => { 
    setIntermodalLegs([]);
    setSelectedLegs([]);
    onClose();
  };

  const tagLegForPrinting = (e, leg) => {
    if (e.target.value) {      
      selectedLegs.push(leg.ID);
      setSelectedLegs([...selectedLegs]);
    } else {
      selectedLegs.splice(selectedLegs.indexOf(leg.ID), 1);
      setSelectedLegs([...selectedLegs]);
    }    
  };

  const printBOLButton = () => {
    setErrorMessage('');
    selectedLegs.sort();
    if (selectedLegs.length === 0) {
      setErrorMessage("Please select at least one Leg.");
      return;
    }
    selectedLegs.forEach((legID) => {
      printBOL(orderID, legID)
        .then(function(response) {
          window.open(response, '_blank');
        });
    });    
  };

  let columns = [
    {
      field: '',
      headerName: '',          
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: (params) => { 
        const leg = params.row;
        let tagForPrinting = params.getValue("TagForPrinting");
        return (  
          <div style={{
            width:'100%', 
            minWidth: '100%', 
            display: 'flex',
            justifyContent: 'space-between'}}>
            <span>
              <CoreCheckboxField
                label={null}
                value={tagForPrinting}
                name="TagForPrinting"
                onChange={(e) => tagLegForPrinting(e, leg) }
              />
            </span>
          </div>
        )
      }
    },
    {
      field: 'LegIndex',
      headerName: 'Leg #',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const leg = params.row;
        return (
          <div>{"Leg " + (leg.LegIndex + 1)}</div>
        )
      }
    },
    {
      field: 'FirstLocation',
      headerName: 'First Location',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const location = params.getValue("IntermodalEvents") ? params.getValue("IntermodalEvents")[0].LocationTemplate : null;
        return (
          <div>
            {location.InternalName}, {location.City + (location.ProvinceCode ? ", " : "") + (location.ProvinceCode ?? "")}
          </div>
        )
      }
    },
    {
      field: 'FinalLocation',
      headerName: 'Final Location',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const location = params.getValue("IntermodalEvents") ? params.getValue("IntermodalEvents")[params.getValue("IntermodalEvents").length - 1].LocationTemplate : null;
        return (
          <div>
            {location.InternalName}, {location.City + (location.ProvinceCode ? ", " : "") + (location.ProvinceCode ?? "")}
          </div>
        )
      }
    },
    {
      field: 'Driver',
      headerName: 'Driver',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Driver") ? params.getValue("Driver").InternalName : null;
        return (
          <div>{name}</div>
        )
      }
    },
    {
      field: 'PlannedDate',
      headerName: 'Planned Date',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("PlannedDate") ? format(new Date(params.getValue("PlannedDate")), 'MM/dd/yyyy') : null;
        return (
          <div>{name}</div>
        )
      }
    }
  ];

  return (
    <>
      <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"md"}
      aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Print BOL</DialogTitle>
        <div>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                {"The BOL can be generated for any Leg on the Order."} <br/>
                {"Select any of the Legs below, then choose \"Print\" to generate the BOL for each Leg selected."}
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
                  <DataGrid
                    getRowId={(row) => row.ID} 
                    rows={intermodalLegs}
                    columns={columns} 
                    hideFooter={true} 
                    autoHeight={true} 
                    pageSize={100} 
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableSelectionOnClick={true}
                  />
                </Paper>
              </Grid>  
              {errorMessage && 
                <Grid item md={12} xs={12}> 
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} className="grey">
              Close
            </Button>  
            <Button onClick={printBOLButton} disabled={inProgress || selectedLegs.length === 0} type="submit" color="primary">
              Print
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default OrderPrintBOLDialog;