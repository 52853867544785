import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LicensePlateSvg } from '../assets/svg-icons/license-plate@1x.svg';

const IconLicensePlate = () => {
    return (
        <SvgIcon fontSize="small">
            <LicensePlateSvg />
        </SvgIcon>    
    )
}
export default IconLicensePlate;