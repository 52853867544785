import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import { createAcctSyncRevenueItem as create } from './QuickbooksService';
import QuickbooksMappedRevenueCreateDialogContent from './QuickbooksMappedRevenueCreateDialogContent';

const QuickbooksMappedRevenueCreateDialog = (props) => {
  const {
    isOpen,
    onClose
  } = props;

  const [quickbooksRevenueFormData, setQuickbooksRevenueFormData] = useState({
    RevenueItemName: '',
    RevenueItemID: null,
    RemoteName: '',
    RemoteID: '',
  });
  const [quickbooksRevenueFormValidation, setQuickbooksRevenueFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setQuickbooksRevenueFormValidation(buildValidation(quickbooksRevenueFormData));
    }
  }, [quickbooksRevenueFormData, isOpen]);

  const updateFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    quickbooksRevenueFormData[name] = value;
    quickbooksRevenueFormData[nameData] = valueData;
    setQuickbooksRevenueFormData({ ...quickbooksRevenueFormData });
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(quickbooksRevenueFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(quickbooksRevenueFormData);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    props.onClose();
  };

  return (
    isOpen && 
    <QuickbooksMappedRevenueCreateDialogContent
        isOpen={isOpen}
        onClose={onClose}
        onCreate={onCreate}
        errorMessage={errorMessage}
        quickbooksRevenueFormData={quickbooksRevenueFormData}
        quickbooksRevenueFormValidation={quickbooksRevenueFormValidation}
        updateFormData={updateFormData}
    />
  );
}

const buildValidation = (quickbooksRevenueFormData) => {
  const {
    RevenueItemID,    
    RemoteID,
  } = quickbooksRevenueFormData || {};
  const validation = new Validation();
  return {
    RevenueItemID: validation.isNumber(RevenueItemID),
    RemoteID: validation.isEmpty(RemoteID),
  }
};

export default QuickbooksMappedRevenueCreateDialog;