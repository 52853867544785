import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import IconButton from '@material-ui/core/IconButton';

const CoreNumberField = (props) => {
  const [touchedByUser, setTouchedByUser] = useState(false);
  const { label, value, isRequired, icon, autoFocus, validationError, minValue, maxValue, disabled, isCurrency, isDecimal, ...rest } = props;

  const buildEndAdornment = () => {
    if (icon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled={true}>
            {icon}
          </IconButton>
        </InputAdornment>);
    }
    return <></>;
  };

  const buildStartAdornment = () => {
    if (isCurrency) {
      return (
        <InputAdornment position="start">
          <AttachMoneyIcon fontSize="small"/>
        </InputAdornment>);
    }
    return <></>;
  };

  return (
    <FormControl fullWidth>
      <InputLabel 
        required={isRequired} 
        error={isRequired && touchedByUser && validationError}
        shrink={ true }>
        {label}
      </InputLabel> 
      <Input
        type="number"
        value={value}
        onBlur={() => setTouchedByUser(true)}
        endAdornment={buildEndAdornment()}
        startAdornment={buildStartAdornment()}
        autoFocus={autoFocus}
        inputProps = {{
          min: minValue,
          max: maxValue,
          step: isDecimal || isCurrency ? 0.01 : 0.00
        }}
        disabled={disabled}
        {...rest}
      />
    </FormControl>
  );
}

CoreNumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreNumberField;
