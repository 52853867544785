import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as RecordSvg } from '../assets/svg-icons/records@1x.svg'

const IconOrder = () => {
  return (
    <SvgIcon fontSize="small">
      <RecordSvg />
    </SvgIcon>
  )
}

export default IconOrder;