import React, { useState, useEffect}  from 'react';
import Button from '@material-ui/core/Button';

const NavigationToolbarTestSiteIdentifier = () => {
    const [environmentIdentifier, setEnvironmentIdentifier] = useState();

  useEffect(() => {
    getEnvironment();
    }, []);


  const getEnvironment = async () => {
    const url = window.location.hostname;
    if (url.includes('app-dev') ) {
      setEnvironmentIdentifier('DEV');
    } else if (url.includes('app-stg')) {
      setEnvironmentIdentifier('STG');
    } else {
      setEnvironmentIdentifier('PROD');
    }

  };

  if(environmentIdentifier !=='PROD') {
    return (
      <Button variant="contained" color="secondary" >
        {environmentIdentifier}
      </Button>
    );
  } else {
    return null;
  }

}


export default NavigationToolbarTestSiteIdentifier;