import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DateCalSvg } from '../assets/svg-icons/datecal@1x.svg'

const IconDateCal = () => {
  return (
    <SvgIcon fontSize="small">
      <DateCalSvg />
    </SvgIcon>
  )
}

export default IconDateCal;