import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';

const AreaTagLineEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    areaTagLineFormData,
    onUpdate,
    errorMessage,
    areaTagLineFormValidation,
    updateAreaTagLineFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
    <form onSubmit={onUpdate}>
      <DialogTitle id="form-dialog-title">Update Area Tag Line</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-1">
          <Grid item md={6} xs={6}>
              <CoreTextField
                autoFocus={true}
                name="City"
                label="City"
                value={areaTagLineFormData.City}
                onChange={e => updateAreaTagLineFormData(e)}
                validationError={areaTagLineFormValidation.City}
                maxLength={100}
                autoComplete="disable-autocomplete"
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <ProvinceField
                name="Province"
                label="State"
                value={areaTagLineFormData.Province}
                onChange={e => updateAreaTagLineFormData(e)}
                isRequired={(areaTagLineFormData.City !== '' && areaTagLineFormData.PostalCode === '') || (areaTagLineFormData.City === '' && areaTagLineFormData.PostalCode === '')}
                validationError={areaTagLineFormValidation.Province}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="PostalCode"
                label="Zip Code"
                value={areaTagLineFormData.PostalCode}
                onChange={e => updateAreaTagLineFormData(e)}
                validationError={areaTagLineFormValidation.PostalCode}
                maxLength={15}
                isRequired={(areaTagLineFormData.City !== '' && areaTagLineFormData.Province === '') || (areaTagLineFormData.City === '' && areaTagLineFormData.Province === '')}                
                autoComplete="disable-autocomplete"
              />
            </Grid>
          {errorMessage && <Grid item md={12} xs={12} className="mt-3">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={inProgress} color="primary">
          Cancel
        </Button>
        <Button type="submit" disabled={inProgress} color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  </Dialog>
  );
}

export default AreaTagLineEditDialogContent;