import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import NoteCreateDialogContent from '../notes/NoteCreateDialogContent';
import { create } from '../notes/NotesService';

const NoteCreateDialog = (props) => {
  const {
    originID,
    noteType,
    isOpen,
    onClose
  } = props;

  const [noteFormData, setNoteFormData] = useState({
    NoteType: noteType,
    OriginID: originID,
    CreatedByID: 0,
    DateCreated: new Date(),
    Subject: '',
    Note: '',
    OrderAlert: false,
    QuoteAlert: false,
    DispatchAlert: false,
    Obsolete: false,
    BinaryFileID: null,
    SavedAsPathOnFS: null,
    FilenameOnFS: "",
    PathOnFS: "",
    isImportant: false
  });
  const [noteFormValidation, setNoteFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
        setNoteFormValidation(buildValidation(noteFormData));
    }
  }, [noteFormData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      resetNoteFormData();      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const resetNoteFormData = () => {
    setErrorMessage('');
    noteFormData.Subject = '';
    noteFormData.Note = '';
    noteFormData.isImportant = false;
    setNoteFormData({ ...noteFormData })
  };

  const updateNoteFormData = e => {
      const {name, value} = e.target;
      noteFormData[name] = value;
      setNoteFormData({ ...noteFormData});
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(noteFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(noteFormData);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    props.onClose();
  };

  return (
    isOpen && 
    <NoteCreateDialogContent
        isOpen={isOpen}
        onClose={onClose}
        onCreate={onCreate}
        errorMessage={errorMessage}
        noteFormData={noteFormData}
        noteFormValidation={noteFormValidation}
        updateNoteFormData={updateNoteFormData}
    />
  );
}

const buildValidation = (noteFormData) => {
  const {
      Subject,
      Note
  } = noteFormData || {};
  const validation = new Validation();
  return {
      Subject: validation.isEmpty(Subject),
      Note: validation.isEmpty(Note)
  }
};

export default NoteCreateDialog;