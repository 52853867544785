import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import IconButton from '@material-ui/core/IconButton';

const CoreOutlinedNumberField = (props) => {
  const { label, value, isRequired, icon, autoFocus, validationError, minValue, maxValue, disabled, isCurrency, variant, ...rest } = props;

  const buildEndAdornment = () => {
    if (icon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled={true}>
            {icon}
          </IconButton>
        </InputAdornment>);
    }
    return <></>;
  };

  const buildStartAdornment = () => {
    if (isCurrency) {
      return (
        <InputAdornment position="start">
          <AttachMoneyIcon fontSize="small"/>
        </InputAdornment>);
    }
    return <></>;
  };

  return (
    <FormControl fullWidth variant={variant}>
      <TextField
        label={label}
        type="number"
        value={value}
        endAdornment={buildEndAdornment()}
        startAdornment={buildStartAdornment()}
        autoFocus={autoFocus}
        step={0.00}
        inputProps = { {min: minValue, max: maxValue} }
        disabled={disabled}
        variant={variant}
        {...rest}
      />
    </FormControl>
  );
}

CoreOutlinedNumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreOutlinedNumberField;
