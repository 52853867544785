import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getReadyDispatchSummary, getEmptiesInDispatchSummary, getEmptiesOutDispatchSummary, getLoadsOutOfTerminalDispatchSummary, getLoadsIntoTerminalDispatchSummary, getDeliveriesDispatchSummary } from './DashboardService';
import Constants from './DashboardConstants.json';
import Avatar from '@material-ui/core/Avatar';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'left',
  },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  gridIcon: {
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  stats: {
    padding: theme.spacing(2)
  },
  divider: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  h6: {
    margin: '0 0 5px 0'
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  status: {
    margin: '0 0 5px 0',
    fontWeight: '400',
    fontSize: '12px'
  },
  count: {
    fontWeight: '600',
    alignSelf: 'center',
  },
  countLink: {
    fontWeight: '600',
    color: '#3f51b5',
    alignSelf: 'center',
  },
  iconAvatar:{
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  bold: {
    fontWeight: 'bold'
  },
}));

const DashboardDispatchItem = (props) => {
  let history = useHistory();
  const { 
    summaryType,
    filterDataAsParams,
    filterData,
  } = props;

  const [loading, setLoading] = useState(true)
  const [statsSummary, setStatsSummary] = useState([]);

  useEffect(() => {
    async function getReady() { 
      setLoading(true);
      const result = await getReadyDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getEmptiesIn() { 
      setLoading(true);
      const result = await getEmptiesInDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getEmptiesOut() { 
      setLoading(true);
      const result = await getEmptiesOutDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getLoadsOut() { 
      setLoading(true);
      const result = await getLoadsOutOfTerminalDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getLoadsIn() { 
      setLoading(true);
      const result = await getLoadsIntoTerminalDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getDeliveries() { 
      setLoading(true);
      const result = await getDeliveriesDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    if(summaryType === 'READY') {
      getReady();
    } else if(summaryType === 'EMPTIES_IN') { 
      getEmptiesIn();
    } else if(summaryType === 'EMPTIES_OUT') {
      getEmptiesOut();
    } else if(summaryType === 'LOADS_OUT') {
      getLoadsOut();
    } else if(summaryType === 'LOADS_IN') {
      getLoadsIn();
    } else if(summaryType === 'DELIVERIES') {
      getDeliveries();
    }
  }, [summaryType, filterData]);

  const classes = useStyles();

  const tally = (total) => {
    return  (
      (!loading) ? total : ''
    );
  };

  const icon = (iconName) => {
    switch (iconName) {      
      case "EventAvailableIcon": return <EventAvailableIcon />
      case "EventBusyIcon": return <EventBusyIcon />
      default: break;
    }
  };

  const navigate = (url, count) => {
    if (url && count) {
      history.push(url);
    } else {
      return;
    }
  }

  const getCountClass = (url, count) => {
    if (url && count) {
      return classes.countLink
    } else {
      return classes.count
    }
  }

  const getCursor = (url, count) => {
    if (url && count) {
      return classes.cursorPointer
    } else {
      return ''
    }
  }

  const outputGridItem = (i) => {
    return (
      <Grid item sm 
      onClick={ () => navigate(Constants.DISPATCH_VIEW[summaryType].STATUS[i].url + filterDataAsParams,
        tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[i].key])
      )}
      className={ getCursor(Constants.DISPATCH_VIEW[summaryType].STATUS[i].url, 
        tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[i].key])
      )}
    >
      <h6 className={classes.status}>{Constants.DISPATCH_VIEW[summaryType].STATUS[i].label}</h6>
      <Grid container className={classes.gridIcon}>
      <Avatar className={classes.iconAvatar} style={{backgroundColor: Constants.DISPATCH_VIEW[summaryType].STATUS[i].iconColor}}>
        <div>
          {icon(Constants.DISPATCH_VIEW[summaryType].STATUS[i].icon)}
        </div>
      </Avatar>
        &nbsp;&nbsp;
        <div className={
          getCountClass(Constants.DISPATCH_VIEW[summaryType].STATUS[i].url,
          tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[i].key])
        )}>
          {loading && <CircularProgress size={16} />}
          {tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[i].key])}
        </div>
      </Grid>
    </Grid>
    )
  }

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      <Grid className={classes.stats}>
        <Typography variant="subtitle1" className={classes.bold}>
          {Constants.DISPATCH_VIEW[summaryType].TITLE}
        </Typography>
        <Typography variant="h3">
          {loading && <CircularProgress />}
          {tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[1].key] + statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[2].key])}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontSize: '12px'}}>
          {Constants.DISPATCH_VIEW[summaryType].STATUS[0].label}
        </Typography>
      </Grid>
      <Grid className={classes.divider}>    
        <Divider className={classes.lightDivider} />
      </Grid>
      <Grid container className={classes.grid}>
        {outputGridItem(1)}
        {outputGridItem(2)}
      </Grid>
    </Paper>
  );

}

DashboardDispatchItem.propTypes = {
  summaryType: PropTypes.string
};

DashboardDispatchItem.defaultProps = {
  summaryType: ""
};

export default DashboardDispatchItem;
