import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as InvoiceSvg } from '../assets/svg-icons/invoicing@1x.svg'

const IconInvoice = () => {
  return (
    <SvgIcon fontSize="small">
      <InvoiceSvg />
    </SvgIcon>
  )
}

export default IconInvoice;