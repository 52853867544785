import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const CoreAutocompleteField = (props) => {

  const filter = createFilterOptions();

  const {
    label,
    value,
    optionValue,
    name,
    options,
    isRequired,
    validationError,
    onChange,
    getOptionSelected,
    getDefaultValue,
    getList,
    variant,
    disableClearable,
    openCreateDialogOpen,
    showAddButton,
    getShowAddButton,
    size,
    showSecondaryText,
    isDisabled,
    freeSolo,
    ...rest
  } = props;

  useEffect(() => {
    const selection = getDefaultValue();
    setInputValue(selection);
    setPrivateValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [touchedByUser, setTouchedByUser] = useState(false);
  const [privateValue, setPrivateValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  const onInputChange = (event, newInputValue) => {
    if (event && event.type !== 'blur') {
      setInputValue(newInputValue);
    }
    if (freeSolo && event && event.type !== 'blur') {
      setInputValue(newInputValue);
      onChange(newInputValue);
    }
    if (event && event.type === 'blur') {
      const selection = getDefaultValue();
      setInputValue(selection);
    }
    if (getShowAddButton !== undefined) {
      getShowAddButton(event, newInputValue);
    }
    if (getList !== undefined && event && event.type !== 'blur') {
      getList(newInputValue);
    }
  };

  const setCurrentSelection = () => {
    const selection = getDefaultValue();
    setInputValue(selection);
  };
 
  return (
    <>
      <Autocomplete
        style={{ position: 'relative' }}
        fullWidth
        disableClearable={disableClearable}
        openOnFocus
        options={options}
        autoHighlight
        value={privateValue}
        freeSolo={freeSolo}
        onChange={(event, newValue) => {
          if (showAddButton && newValue && newValue.inputValue) {
            getShowAddButton(event, newValue.inputValue);
            openCreateDialogOpen();
          } else {
            onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (showAddButton && params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              Name: `Create "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        onOpen={(event) => setInputValue('')}
        onClose={setCurrentSelection}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptionLabel={(option) => {
          if (option.inputValue) {
            return option.Name;
          }          
          return option.Name || '';
        }}
        size={size}
        renderOption={(option) => (
          <li>
              {option.Name}
              <br/>
              {showSecondaryText && <span style={{fontSize: '90%', color:'gray'}}>
                {option.SecondaryText}
              </span>}
          </li>
        )}
        disabled={isDisabled}
        renderInput={(params) =>
          <>
            <TextField
              label={label}
              onBlur={() => setTouchedByUser(true)}
              {...rest}
              required={isRequired}
              error={isRequired && touchedByUser && validationError}
              variant={variant}
              inputProps={{
                ...params.inputProps,
                autoComplete: "disable-autocomplete"
              }}
              {...params}
            />
          </>
        }
      />
    </>
  );

}

CoreAutocompleteField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  getDefaultValue: PropTypes.func,
  getOptionSelected: PropTypes.func,
  options: PropTypes.array.isRequired,
  showAddButton: PropTypes.bool,
  getShowAddButton: PropTypes.func
};

export default CoreAutocompleteField;