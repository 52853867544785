import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CompletedSvg } from '../assets/svg-icons/completed@1x.svg';

const IconCompleted = () => {
  return (
    <SvgIcon fontSize="small">
      <CompletedSvg />
    </SvgIcon>
  )
}
export default IconCompleted;