import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import ConfigurationViewPayableItemSectionList from './ConfigurationViewPayableItemSectionListTable';
import PayableItemCreateDialog from '../payable_item/PayableItemCreateDialog';
import { getPayableItems } from '../payable_item/PayableItemService';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewPayableItemSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isPayableItemCreateDialogOpen, setIsPayableItemCreateDialogOpen] = useState(false);
  const [payableItems, setPayableItems] = useState({});

  useEffect(() => {
    getPayableItems()
      .then(function (response) {
        setPayableItems(response);
        setInProgress(false);
      });
  }, []);


  const getPaybleItemsList = () => {
    getPayableItems()
      .then(function (response) {
        setPayableItems(response);
      });
  };

  const openPayableItemCreateDialog = () => {
    setIsPayableItemCreateDialogOpen(true);
  };

  const closePayableItemCreateDialog = () => {
    setIsPayableItemCreateDialogOpen(false);
    getPaybleItemsList();
  };
  
  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Expense Items"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Expense Items"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"Expense items are the line items you'll add to expenses or bills when paying your drivers or owner/operators."}
                <span style={rightFloat}>
                </span>
            </Typography>
            <ConfigurationViewPayableItemSectionList 
                  payableItems={payableItems}
                  setPayableItems={setPayableItems}
                  getPayableItems={getPaybleItemsList}
                  openPayableItemCreateDialog={openPayableItemCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
            <span></span>
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Expense Items"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Expense Items"></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <PayableItemCreateDialog
        isOpen={isPayableItemCreateDialogOpen}
        onClose={closePayableItemCreateDialog}
      />
    </div>
  );
}

export default ConfigurationViewPayableItemSection
