import IntermodalPaymentListTable from './IntermodalPaymentListTable';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';

const IntermodalPaymentList = () => {
  return (
    <div>
      <CorePageHeader
        title="Settlements"
        breadCrumb="Home / ... / Settlements List"
        createButton={
          null
        }/>
      <Container maxWidth="false" className="mt-4">
        <IntermodalPaymentListTable />
      </Container>
    </div>
  );

}

export default IntermodalPaymentList;