import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    statusIcon: {
      fontSize: '12px',
      margin: '5px'
    }
  }));

const EmailTemplateSmartTagButton = (props) => {
  const classes = useStyles();
  const { 
    value,
    onButtonClick
  } = props;

  return (
    <>
      <Button className={classes.statusIcon} variant="outlined" color="primary" onClick={e => onButtonClick(value)}> 
        {value.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ')} 
      </Button>
    </>
  );
}

EmailTemplateSmartTagButton.propTypes = {
  value: PropTypes.string
};

export default EmailTemplateSmartTagButton;