import React, { useEffect, useState } from 'react';
import CustomerEditContentInfo from './CustomerEditContentInfo';
import CustomerEditContentAddress from './CustomerEditContentAddress';
import CustomerEditContentBillAddress from './CustomerEditContentBillAddress';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import Alert from '@material-ui/lab/Alert';

import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';
const CustomerEditContent = (props) => {
  const {
    customerFormData,
    onSave,
    onClose,
    errorMessage,
    customerFormValidation,
    updateCustomerFormData,
    addressCoordinates,
    billAddressCoordinates,
    inProgress
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  const [branch, setBranch] = useState('');
  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };

  return (
    <form onSubmit={onSave}>
      <CustomerEditContentInfo
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
      />
      <br/>
      <CustomerEditContentAddress
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
        coordinates={addressCoordinates}
        branch={branch}
      />
      <br/>      
      <CustomerEditContentBillAddress
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
        coordinates={billAddressCoordinates}
        branch={branch}
      />
      {
        errorMessage && 
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons 
        changesDetails={"Customer " + customerFormData.InternalName + " details"}
        onClose={onClose}
      />}
    </form>
  );
}

export default CustomerEditContent;