import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import Constants from './OrderConstants.json';
import IconDocument from '../icon/IconDocument';
import { getDocument } from './OrderService'

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

function getDoc(ID) {
  // TODO: Error handling
  // Only get document if user clicks on link
  getDocument(ID).then(function(response) {
    window.open(response + '?printerFriendly=true', ID,
    "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=800,left = 0,top = 0").focus();
  });
}

const OrderViewSummaryDocumentsSection = (props) => {
  const classes = useStyles();

  const {
    documents
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="DOCUMENTS" />
        </Grid>
        { documents && documents.length > 0 ? (
          <>
            { documents.map((document, index) =>
              <Grid container item xs={12} onClick={() => getDoc(document.ID)} style={{cursor: 'pointer'}}>
                <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
                  <CoreTextDisplayField
                    label={Constants.DOCUMENT_TYPE[document.DocumentTypeID]}
                    value={document.FileName}
                    icon={<IconDocument />}
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : null }
      </Grid>
    </div>
  );
}

export default OrderViewSummaryDocumentsSection;
