import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreToggleField from '../core/CoreToggleField';
import PayableChargeTypeField from '../payableChargeType/PayableChargeTypeField';
import AreaTagField from '../area_tag/AreaTagField';
import CoreTextField from '../core/CoreTextField';

const PointToPointTemplateLineCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    templateLineFormData,
    onCreate,
    errorMessage,
    updateTemplateLineFormData,
    templateLineFormValidation,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Point-To-Point Template Line</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <AreaTagField
                label="Point A"
                name="OriginAreaTagName"
                value={templateLineFormData.OriginAreaTagName}
                nameData="OriginAreaTagID"
                valueData={templateLineFormData.OriginAreaTagID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.OriginAreaTagID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <AreaTagField
                label="Point B"
                name="DestinationAreaTagName"
                value={templateLineFormData.DestinationAreaTagName}
                nameData="DestinationAreaTagID"
                valueData={templateLineFormData.DestinationAreaTagID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.DestinationAreaTagID}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreToggleField
                label="Two-Way Rate"
                name="TwoWay"
                value={templateLineFormData.TwoWay}
                onChange={e => updateTemplateLineFormData(e)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <PayableChargeTypeField
                label="Expense Item Type"   
                value={templateLineFormData.PayableChargeTypeName}
                name="PayableChargeTypeName"
                nameData="PayableChargeTypeID"
                valueData={templateLineFormData.PayableChargeTypeID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.PayableChargeTypeID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              {(templateLineFormData.IntermodalUnitType === null || templateLineFormData.IntermodalUnitType === 0 || templateLineFormData.IntermodalUnitType === 1) && 
              <CoreNumberField
                name="Units"
                label="Quantity"
                minValue={0}
                isDecimal={true}
                value={templateLineFormData.Units}
                onChange={e => updateTemplateLineFormData(e)}
                isRequired={true}
                validationError={templateLineFormValidation.Units}              
              />}
              {templateLineFormData.IntermodalUnitRateType !== null && templateLineFormData.IntermodalUnitType !== 0 && templateLineFormData.IntermodalUnitType !== 1 && 
              <CoreTextField
                label="Unit Type"
                value={templateLineFormData.IntermodalUnitTypeName}
                disabled={true}
              />}
            </Grid>
            <Grid item md={6} xs={6}>
              {(templateLineFormData.IntermodalUnitRateType == null || templateLineFormData.IntermodalUnitRateType === 0) && 
              <CoreNumberField
                name="UnitRate"
                label="Rate"
                minValue={0}
                isCurrency={true}
                value={templateLineFormData.UnitRate}
                onChange={e => updateTemplateLineFormData(e)}
                isRequired={true}
                validationError={templateLineFormValidation.UnitRate}
              />}
              {templateLineFormData.IntermodalUnitRateType !== null && templateLineFormData.IntermodalUnitRateType !== 0 && 
              <CoreTextField
                label="Unit Rate Type"
                value={templateLineFormData.IntermodalUnitRateTypeName}
                disabled={true}
              />}
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PointToPointTemplateLineCreateDialogContent;