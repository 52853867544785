import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'QuickBook/' + token + '/';

export function getConnectButtonUrl() {
  const httpService = new HttpService();
  const endpoint = uri + 'authenticate?connect=true';
  return httpService.getConstructedEndPoint(endpoint);
}

export async function authenticate(){
  const httpService = new HttpService();
  const result = await httpService.get(uri + 'AuthenticateEnvase');
  return result;
}

export async function checkLogin() {
  const httpService = new HttpService();
  const result = await httpService.get(uri + 'CheckLogin');
  return result;
};

export async function getEarliestTransactionDate() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEarliestTransactionDate');
  return result.data[0];
};

export async function setEarliestTransactionDate(earliestTransaction) {
  var params = {
    earliestTransaction: earliestTransaction
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SetEarliestTransactionDate', null, params);
  return result;
};

export async function areAccountsMapped() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'AreAccountsMapped');
  return result;
};

export async function getAcctSyncAccount(id) {
  var params = {
    acctSyncAccountID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAcctSyncAccount', params);
  var account = result.response;
  account.LocalAccountName = account.LocalAccountActivityID
  return account;
};

export async function getAcctSyncAccountList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAcctSyncAccountList');  
  const response = result.data;
  const accItems = await getQuickbooksBankAccountList();
  for (const acc of response) {    
    acc.QuickbooksAccountName = accItems.find(item => item.Id === acc.RemoteAccountID).Name;
  }
  return response;
};

export async function getQuickbooksAccountName(remoteAccountID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + remoteAccountID + '/GetQuickbookAccountName');
  return result;
};

export async function createAcctSyncAccount(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateAcctSyncAccount', null, params);
  return result;
};

export async function updateAcctSyncAccount(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateAcctSyncAccount', params);
  return result;
};

export async function getUnmappedFinancialAccounts() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetUnmappedFinancialAccounts');
  return result.data;
};

export async function getQuickbooksBankAccountList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetQuickbooksBankAccountList');
  return result; 
};

export async function deleteAcctSyncAccount(acctSyncAccountID) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + acctSyncAccountID + '/DeleteAcctSyncAccount');
  return result;
};

export async function getAcctSyncRevenueItem(id) {
  var params = {
    acctSyncXactID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAcctSyncRevenueItem', params);
  return result.response;
};

export async function getAcctSyncRevenueItemList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAcctSyncRevenueItemList');
    const response = result.data;
    const revItems = await getQuickBooksRevenueItemList();
  for (const acc of response) {    
    const revItem = revItems.find(item => item.Id === acc.RemoteID);
    if(revItem !== undefined) {
      acc.QuickbooksRevenueItemName = revItem.Name;
    }
  }
  return response;
};

export async function createAcctSyncRevenueItem(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateAcctSyncRevenueItem', null, params);
  return result;
};

export async function updateAcctSyncRevenueItem(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateAcctSyncRevenueItem', params);
  return result;
};

export async function deleteAcctSyncRevenueItem(acctSyncRevenueItemID) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + acctSyncRevenueItemID + '/DeleteAcctSyncRevenueItem');
  return result;
};

export async function getUnmappedRevenueItems() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetUnmappedRevenueItems');
  return result.data;
};

export async function getQuickBooksRevenueItemList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetQuickBooksRevenueItemList');
  return result;
};

export async function getQuickbooksRevenueItemName(quickbooksRevenueItemID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + quickbooksRevenueItemID + '/GetQuickBooksRevenueItemName');
  return result;
};

export async function areRevenueItemsMapped() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'AreRevenueItemsMapped');
    return result;
};

export async function getInvoicesToCreate() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalInvoicesToCreate');
  var response = result.data;
  for (const invoice of response) { 
    invoice.Sync = true;
    invoice.Status = "Ready to sync";
    invoice.UseQuickbooksExchangeRate = false;
  }
  return response;
};

export async function createQuickbooksInvoice(invoice) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateQuickbooksIntermodalInvoice', invoice);
  return result;
};

export async function suppressInvoice(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SuppressInvoice', null, params);
  return result;
};