import React, { useState, useEffect } from "react";
import { Avatar, Card, CardHeader,CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chatListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardRoot: {
    height: "90px",
    width: "90%",
    margin: "15px auto 0 auto",
    cursor: "pointer",
    borderRadius: "8px",
    boxShadow: "none"
  },
  cardHeadContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  shapeCircle: {
    backgroundColor: "#8460cb",
    width: 10,
    height: 10,
    borderRadius: "50%",
  },
  selected: {
    backgroundColor: "#6639bf",
    "& .avatar": {
      backgroundColor: "#8460cb",
      color: "#fafafa"
    }
  },
  title: {
    fontSize: "14px",
    "& span": {
      color: "#fff",
    },
  },
  orderNo: {
    fontSize: "12px",
  },
  date: {
    float: "right",
    marginRight: "-22px",
    marginTop: "3px",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

const ChatConversationList = (props) => {
  const [height, setHeight] = useState(window.innerHeight);
  const { conversations, onChatClicked, selectedMessageInfo } = props;
  const classes = useStyles();
  const circle = <div className={classes.shapeCircle} />;

  const updateHeight = () => {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  if (conversations && conversations.errorMessage) return <>{conversations.errorMessage}</>;
  return (
    <div style={{height: height-132, overflowX: 'auto'}}>
        {!conversations.length > 0 && (
          <div align="center">
            <CircularProgress size={40} style={{marginTop: '50px'}} />
          </div>
        )}
        {conversations &&
          conversations.map((convo) => (
            convo.PersonnelID &&
            <Card
              key={convo.PersonnelID}
              elevation={1}
              className={classes.cardRoot}
              onClick={() => onChatClicked(convo)}
            >
              <div
                className={`${classes.cardHeadContainer} ${
                  convo.PersonnelID === selectedMessageInfo.id ? classes.selected : ""
                }`}
              >
                <CardHeader
                  className={convo.PersonnelID === selectedMessageInfo.id ? classes.title : ""}
                  avatar={
                    <Avatar aria-label="photo" className="avatar">
                      {convo.PersonnelInternalName &&
                      convo.PersonnelInternalName.length > 0 &&
                      convo.PersonnelInternalName.includes(" ")
                      ? convo.PersonnelInternalName.split(" ").shift().charAt(0) +
                        convo.PersonnelInternalName.split(" ").pop().charAt(0)
                      : ""}
                    </Avatar>
                  }
                  title={convo.PersonnelInternalName}
                  subheader={
                    <>
                      <span className={classes.orderNo} style={selectedMessageInfo.id ? {color: "#b19bde"} : {}}>
                      {convo.Body &&
                        convo.Body.substring(0, 30) + "..."}
                      </span>
                      <span className={classes.date} style={selectedMessageInfo.id ? {color: "#b19bde"} : {}}>
                        {convo.DateCreated?.substring(0,convo.DateCreated.indexOf("T"))}
                      </span>
                    </>
                  }
                  action={
                    convo.IsIncoming && !convo.IsRead ? (
                      <IconButton>{circle}</IconButton>
                      ):
                      <IconButton></IconButton>
                  }
                />
              </div>
            </Card>
          ))}
    </div>

  );
};

export default ChatConversationList;
