import { React, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CoreDateField from '../core/CoreDateField';
import CoreTimeField from '../core/CoreTimeField';
import { getForUpdate, update as updateOrder} from '../order/OrderService';
import { formatDateTime } from '../globalFunction';

const OrderAppointmentLoadReadyDateDialog = (props) => { 
  const { 
    isOpen,
    handleClose,
    openAppointmentCreateDialog,
    orderID,
  } = props;

  const [order, setOrder] = useState({});
  const [loadReadyDateTime, setLoadReadyDateTime] = useState({
    loadReadyDate: null,
    loadReadyTime: null
  });

  useEffect(() => { 
    if (isOpen) {
      async function getOrderForUpdate() { 
        var intermodalOrder =  await getForUpdate(orderID);
        setOrder(intermodalOrder);
      } 
      getOrderForUpdate();
    }
  }, [orderID]);

  const updateOrderLoadReadyDate = e => { 
    const { 
      name,
      value
    } = e.target;
    loadReadyDateTime[name] = value;
    setLoadReadyDateTime({ ...loadReadyDateTime });
  };

  const handleOrderAppointmentCreate = async () => { 
    order.LoadReadyDate = formatDateTime(loadReadyDateTime.loadReadyDate, loadReadyDateTime.loadReadyTime);
    await updateOrder(order);
    openAppointmentCreateDialog();
    handleClose();
  };

  return ( 
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Update Load Ready Date</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-2 mb-2">
          <Grid item md={12} xs={12}>
            <p>Please enter a Load Ready Date to book an Appointment for Order {order.OrderNum}.</p>
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreDateField
              name="loadReadyDate"
              label="Load Ready Date"
              value={loadReadyDateTime.loadReadyDate}
              onChange={e => updateOrderLoadReadyDate(e)}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTimeField
              name="loadReadyTime"
              label="Load Ready Time"
              value={loadReadyDateTime.loadReadyTime}
              onChange={e => updateOrderLoadReadyDate(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleOrderAppointmentCreate()} variant="contained" color="primary" disabled={!loadReadyDateTime.loadReadyDate}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default OrderAppointmentLoadReadyDateDialog;