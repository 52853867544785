import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import PointToPointTemplateLineCreateDialogContent from './PointToPointTemplateLineCreateDialogContent';
import { Create } from './PointToPointTemplateLineService';
import { getPayableItem } from '../payable_item/PayableItemService';

const PointToPointTemplateLineCreateDialog = (props) => { 
  const { 
    pointToPointTemplateID,
    isOpen,
    onClose,
  } = props;

  const [templateLineFormData, setTemplateLineFormData] = useState({});
  const [templateLineFormValidation, setTemplateLineFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => { 
    if (isOpen) { 
      setTemplateLineFormData(c => ({ ...c, PointToPointTemplateID: pointToPointTemplateID }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTemplateLineFormValidation(buildValidation(templateLineFormData));
    }
  }, [templateLineFormData, isOpen]);

  const updateTemplateLineFormData = e => { 
    const { name, value, nameData, valueData } = e.target;
    templateLineFormData[name] = value;
    templateLineFormData[nameData] = valueData;
    setTemplateLineFormData({ ...templateLineFormData });
    updatePayableItemTypeDefaults(nameData);
  };

  const updatePayableItemTypeDefaults = async (name) => {
    if (templateLineFormData.PayableChargeTypeID && name === "PayableChargeTypeID") {
      const payableItem = await getPayableItem(templateLineFormData.PayableChargeTypeID);
      setTemplateLineFormData(p => ({ ...p, Units: payableItem.UnitsDefaultValue}));
      setTemplateLineFormData(p => ({ ...p, UnitRate: payableItem.UnitRateDefaultValue }));
      setTemplateLineFormData(p => ({ ...p, IntermodalUnitRateType: payableItem.IntermodalUnitRateType }));
      setTemplateLineFormData(p => ({ ...p, IntermodalUnitRateTypeName: payableItem.IntermodalUnitRateTypeName }));
      setTemplateLineFormData(p => ({ ...p, IntermodalUnitType: payableItem.IntermodalUnitType }));
      setTemplateLineFormData(p => ({ ...p, IntermodalUnitTypeName: payableItem.IntermodalUnitTypeName }));
    } 
  };

  const onCreate = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(templateLineFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => { 
    setErrorMessage('');
    const result = await Create(templateLineFormData);
    if (result.error) {
      setErrorMessage(result.error);
      setInProgress(false);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => { 
    setTemplateLineFormData({});
    onClose();
  };

  return ( 
    isOpen &&
    <PointToPointTemplateLineCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      templateLineFormData={templateLineFormData}
      updateTemplateLineFormData={updateTemplateLineFormData}
      templateLineFormValidation={templateLineFormValidation}
      inProgress={inProgress}
    />
  )
};

const buildValidation = (templateLineFormData) => { 
  const { 
    OriginAreaTagID,
    DestinationAreaTagID,
    PayableChargeTypeID,
    Units,
    UnitRate
  } = templateLineFormData || {};
  const validation = new Validation();
  return { 
    OriginAreaTagID: validation.isNumber(OriginAreaTagID),
    DestinationAreaTagID: validation.isNumber(DestinationAreaTagID),
    PayableChargeTypeID: validation.isNumber(PayableChargeTypeID),
    Units: validation.isNumber(Units),
    UnitRate: validation.isNumber(UnitRate)
  };
};

export default PointToPointTemplateLineCreateDialog;