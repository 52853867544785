import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconActiveDate from '../icon/IconActiveDate';
import IconYardMove from '../icon/IconYardMove';
import IconDspDropped from '../icon/IconDspDropped';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px 0',
    cursor: 'pointer',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    width: '30%',
    textAlign: 'center',
    "& svg": {
      opacity: '0.6'
    },
    "& label": {
      opacity: '0.6'
    }
  },
  paperSelected: {
    padding: '20px 0',
    cursor: 'pointer',
    backgroundColor: '#f3f0fa',
    borderRadius: '8px',
    width: '30%',
    textAlign: 'center',
    '& svg': {
      filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%)',
      opacity: '1',
    },
    "& label": {
      opacity: '1'
    }
  },
  padding: {
    padding: '10px'
  },
  centerAlign: {
    textAlign: 'center'
  }
}));

const IntermodalEventRadioButton = (props) => {
  const {
    value,
    onChange,
    currentEventType,
    initialEventType,
    isAddEventAbove
  } = props;
  const classes = useStyles();

  const [eventChosen, setEventChosen] = useState({
    ShowEvent: false,
    ShowYardMove: false,
    ShowXDock: false,
  });

  useEffect(() => {
    switch (value) {
      case 'ShowEvent':
        eventChosen.ShowEvent = true;
        eventChosen.ShowYardMove = false;
        eventChosen.ShowXDock = false;
        break;
      case 'ShowYardMove':
        eventChosen.ShowEvent = false;
        eventChosen.ShowYardMove = true;
        eventChosen.ShowXDock = false;
        break;
      case 'ShowXDock':
        eventChosen.ShowEvent = false;
        eventChosen.ShowYardMove = false;
        eventChosen.ShowXDock = true;
        break;
      default:
        break;
    }
    setEventChosen(eventChosen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onEventChange = (e) => {
    const dispatchEvent = {
      target: {
        name: e.target.defaultValue,
      }
    };
    onChange(dispatchEvent);
  };

  function getYardMoveConditions() { 
    // DO NOT show 'Yard Move' if: 
    return (
      // first/last event
      currentEventType !== null &&
      // after chassis pickup
      !((initialEventType === 'Chassis Pickup' && !isAddEventAbove) || (currentEventType === 'Chassis Pickup' && isAddEventAbove)) && 
      // before chassis return
      !((initialEventType === 'Chassis Return' && isAddEventAbove) || (currentEventType === 'Chassis Return' && !isAddEventAbove)) &&
      // before mount
      !((initialEventType === 'Mount' && isAddEventAbove) || (currentEventType === 'Mount' && !isAddEventAbove)) &&
      // after dismount
      !((initialEventType === 'Dismount' && !isAddEventAbove) || (currentEventType === 'Dismount' && isAddEventAbove))
    );
  }

  return (
    <div style={{width: '100%'}}>
      <RadioGroup row value={value} aria-label="position" name="position" size="small" onChange={onEventChange}>
        <Paper
          className={`${classes.paper} ${eventChosen.ShowEvent && classes.paperSelected}`}
          elevation={0}
          spacing={1}>
          <Grid item md={12} xs={12} className={classes.centerAlign}>
            <IconActiveDate size={"large"} />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              value="ShowEvent"
              control={
                <Radio size="small" color="primary" />
              }
              label="EVENT"
              labelPlacement="top"
            />
          </Grid>
        </Paper>
        {(getYardMoveConditions() && currentEventType !== null) && <span className={classes.padding}></span>}
        {getYardMoveConditions() &&
          <Paper
            className={`${classes.paper} ${eventChosen.ShowYardMove && classes.paperSelected}`}
            elevation={0}
            spacing={1}>
            <Grid item md={12} xs={12} className={classes.centerAlign}>
              <IconYardMove />
            </Grid>
            <FormControlLabel
              value="ShowYardMove"
              control={
                <Radio size="small" color="primary" />
              }
              label="YARD MOVE"
              labelPlacement="top"
            />
          </Paper>
        }
        {(currentEventType !== 'Drop' && currentEventType !== null) && <span className={classes.padding}></span>}
        {(currentEventType !== 'Drop' && currentEventType !== null) &&
          <Paper
            className={`${classes.paper} ${eventChosen.ShowXDock && classes.paperSelected}`}
            elevation={0}
            spacing={1}>
            <Grid item md={12} xs={12} className={classes.centerAlign}>
              <IconDspDropped />
            </Grid>
            <FormControlLabel
              value="ShowXDock"
              control={
                <Radio size="small" color="primary" />
              }
              label="X-DOCK"
              labelPlacement="top"
            />
          </Paper>
        }
      </RadioGroup>
    </div>
  );

}

export default IntermodalEventRadioButton;