import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { createPayable } from '../payable/PayableService';
import BillViewExpenseCreateDialogContent from './BillViewExpenseCreateDialogContent';
import { getTaxTypeIDNone } from '../configuration/ConfigurationService';
import { getPayableItem } from '../payable_item/PayableItemService';

const BillViewExpenseCreateDialog = (props) => {
  const {
    billID,
    isOpen,
    onClose,
    defaultPayableChargeTypeID,
    defaultPayableChargeTypeName,
  } = props;

  const initialState = { 
    payableChargeID: 0,
    payableChargeTypeID: defaultPayableChargeTypeID ? defaultPayableChargeTypeID : null,
    payableChargeTypeName: defaultPayableChargeTypeName ? defaultPayableChargeTypeName : '',
    sourcePayableChargeID: null,
    billID: billID,
    indexForBill: 0,
    equipmentID: null,
    descriptionEdited: 0,
    description: '',
    classEdited: 0,
    classID: null,
    units: 1,
    unitsEdited: 0,
    unitRate: 0,
    unitRateEdited: 0,
    discountPercent: 0,
    subtotal: 0,
    tax1TypeID: 0,
    tax1Edited: 0,
    tax1: 0,
    tax2TypeID: 0,
    tax2Edited: 0,
    tax2: 0,
    aeRelatedDPCTID: null,
    relatedFuelStopID: null,
    deleted: 0, 
  };

  const [payableFormData, setPayableFormData] = useState(initialState);
  const [payableFormValidation, setPayableFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setPayableFormValidation(buildValidation(payableFormData));
    }
  }, [payableFormData, isOpen]);

  useEffect(() => {
    async function getDefaultTaxTypes() {
      const taxType = await getTaxTypeIDNone();
      setPayableFormData(p => (
        { ...p, tax1TypeID: taxType, tax2TypeID: taxType,
          payableChargeTypeID: defaultPayableChargeTypeID ? defaultPayableChargeTypeID : null,
          payableChargeTypeName: defaultPayableChargeTypeName ? defaultPayableChargeTypeName : '' }
      ));
    }
    getDefaultTaxTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setPayableFormData(p => (
      { ...p, description: payableFormData.payableChargeTypeName }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payableFormData.payableChargeTypeID]);

  const updatePayableFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    payableFormData[name] = value;
    payableFormData[nameData] = valueData;
    setPayableFormData({ ...payableFormData });
    updatePayableItemDefaults(nameData);
  };

  const updatePayableItemDefaults = async (name) => {
    if (payableFormData.payableChargeTypeID && name === "payableChargeTypeID") {
      const payableItem = await getPayableItem(payableFormData.payableChargeTypeID);
      setPayableFormData(p => ({ ...p, units: payableItem.UnitsDefaultValue}));
      setPayableFormData(p => ({ ...p, unitRate: payableItem.UnitRateDefaultValue }));
      setPayableFormData(s => ({...s, subtotal: (payableItem.UnitsDefaultValue * payableItem.UnitRateDefaultValue)}));
    } else {
      updateSubtotal();
    }
  };

  const updateSubtotal = () => {
    setPayableFormData(s => ({...s, subtotal: (payableFormData.units * payableFormData.unitRate)}));
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(payableFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await createPayable(payableFormData);
    if (result.errors.Error) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => { 
    setPayableFormData(initialState);
    props.onClose();
  };

  return (
    isOpen &&
    <BillViewExpenseCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      payableFormData={payableFormData}
      payableFormValidation={payableFormValidation}
      updatePayableFormData={updatePayableFormData}
    />
  );
}

const buildValidation = (payableFormData) => {
  const {
    payableChargeTypeID,
  } = payableFormData || {};
  const validation = new Validation();
  return {
    payableChargeTypeID: validation.isNumber(payableChargeTypeID),
  };
};

export default BillViewExpenseCreateDialog;