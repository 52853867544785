import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import { usePaymentRequests } from '@envasetechnologies/feepay-components';
import { CurrencyDisplay } from '@envasetechnologies/feepay-components';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import CoreExitButton from '../core/CoreExitButton';
import FeePayAdrSlideOutPayButton from './FeePayAdrSlideOutPayButton';
import FeePayAdrSlideOutItem from './FeePayAdrSlideOutItem';

const useStyles = makeStyles(() => ({
  paper: {
    width:400
  },
  totalAmount: { 
    display: 'inline-flex',
    alignItems: 'center',
    position: 'fixed',
    bottom: '80px',
    width: '352px',
  }
}));

const FeePayAdrSlideOut = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const {
    isOpen,
    onClose,
    items
  } = props;

  const [selectedAdrs, setSelectedAdrs] = useState([]);
  const [selectedAdrDropdown, setSelectedAdrDropdown] = useState([]);
  const [availableADRs, setAvailableADRs] = useState([]);
  const [idToADRMAP, setIdToADRMAP] = useState({});
  const [paymentIntent, setPaymentIntent] = useState({});
  const [loading, setLoading] = useState(false);

  const paymentRequests = usePaymentRequests();
  const baseUrl = window.location.href;

  useEffect(() => {
    async function getPaymentIntent() {
      setLoading(true);
      const intent = await createPaymentIntent();
      setPaymentIntent(intent);
      getAvailableAdrs(intent);
      setLoading(false);
    }
    if (isOpen) {
      getPaymentIntent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const selected = [];
    selectedAdrDropdown.forEach((id) => {
      const index = selectedAdrs.findIndex((adr) => adr.id === id);
      if (index === -1) {
        selected.push({ id: id, amount: amountThatShouldBeApplied(idToADRMAP[id].amount), number: idToADRMAP[id].number });
      } else {
        selected.push({ id: id, amount: selectedAdrs[index].amount, number: idToADRMAP[id].number });
      }
    });
    setSelectedAdrs(selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdrDropdown]);


  const amountThatShouldBeApplied = (amount) => {
    let totalSelectedAdrs = getTotalSelectedAdrAmount();
    if (amount) {
      if (totalSelectedAdrs + parseFloat(amount) > parseFloat(items[0].amount)) {
        var remainingAmount = parseFloat(Math.abs(totalSelectedAdrs - items[0].amount)).toFixed(2);
        return remainingAmount;
      }
      return amount;
    }
    return "0";
  };

  const createPaymentIntent = async () => {
    const createdPaymentIntent = await paymentRequests.requestCreatePaymentIntent({
      cartItems: items
    });
    var intent = await assignPaymentMethod(createdPaymentIntent);
    setPaymentIntent(intent);
    return intent;
  };

  const assignPaymentMethod = async (paymentIntent, additionalData) => { 
    if (paymentIntent) { 
      const attachedPaymentIntent = await paymentRequests.requestAttachPaymentMethod({
        paymentIntentId: paymentIntent.id,
        type: "ICTSI_ADR",
        additionalData: {
          returnUrl: baseUrl + '-payment',
          autoConfirm: true
        },
      });
      return attachedPaymentIntent;
    }
  };

  const uniqueId = (counter => () => ++counter)(0);

  const getAvailableAdrs = (paymentIntent) => {
    let idToAdrMap = {};
      const availableAdrs = paymentIntent?.paymentMethodOptions?.availableAdrs.map((adr) => {
        const adrWithId = { id: uniqueId(), ...adr };
        idToAdrMap[adrWithId.id] = adrWithId;
        return adrWithId;
      });
      setAvailableADRs(availableAdrs);
      setIdToADRMAP(idToAdrMap);
  };

  const handleAdrSelect = (event) => {
    setSelectedAdrDropdown(event.target.value);
  };

  const handleAdrDeSelect = (id) => {
    const updatedSelectedAdrDropdown = selectedAdrDropdown.filter((adrId) => adrId !== id);
    setSelectedAdrDropdown(updatedSelectedAdrDropdown);
  };

  const shouldMenuItemBeDisabled = (adr) => {
    const isSelected = selectedAdrDropdown.indexOf(adr.id)
    const alreadySelected = isSelected > -1 ? true : false;
    if (!alreadySelected && isAdrGreaterThanAmount()) {
      return true;
    }
    return false;
  };

  const isAdrGreaterThanAmount = () => {
    var totalAdrs = getTotalSelectedAdrAmount();
    if (totalAdrs >= items[0].amount) {
      return true;
    }
    return false;
  };

  const getTotalSelectedAdrAmount = () => {
    var totalAdrs = 0;
    selectedAdrs.forEach((adr) => {
      totalAdrs += getNumber(adr.amount);
    });
    return totalAdrs;
  };

  const getNumber = (number) => {
    if (number && number.toString().charAt(0) === 'e') {
      return parseFloat(number.toString().substring(1));
    }
    return parseFloat(number);
  };

  const handleAdrAmountSections = (id, amount) => {
    const updatedSelectedAdr = selectedAdrs.map((adr) => {
      if (adr.id === id) {
        return { ...adr, amount: amount };
      }
      return adr;
    });
    setSelectedAdrs(updatedSelectedAdr);
  };

  const confirmPaymentWithAdditionalData = (async (paymentIntentId) => {
    var formattedAdrList = [];
    selectedAdrs.forEach((adr) => {
      formattedAdrList.push({ adrNumber: idToADRMAP[adr.id].number, amount: adr.amount });
    });
    return await paymentRequests.requestConfirmPayment({
      paymentIntentId: paymentIntentId,
      additionalData: {
        adrList: formattedAdrList
      }
    });
  });

  const handlePayButtonClick = async () => { 
    var confirmedPaymentIntent = await confirmPaymentWithAdditionalData(paymentIntent.id);
    setPaymentIntent(confirmedPaymentIntent);
    history.push({
      pathname: '/spark/cart-payment',
      search: '?intentId=' + paymentIntent.id + '&status=AUTHORIZED&autoConfirm=true&isADR=true'
    });
  };

  return (
    <>
      <Drawer
        anchor={'right'} 
        open={isOpen}
        ModalProps={{onBackdropClick: onClose }}
        classes={{ paper: classes.paper }}
      >
        <CoreSummaryHeader
          title={"Pay Via ADR"}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
        />
        <br></br>
        {loading && <LinearProgress />}
        <FormControl>
          <InputLabel shrink id="adr-select-label">Select ADR's</InputLabel>
          <Select
            labelId="adr-select-label"
            id="adr-select"
            multiple
            disabled={loading}
            value={selectedAdrDropdown}
            renderValue={(selected) => selected.map((adrId) => idToADRMAP[adrId].number).join(', ')}
            MenuProps={{PaperProps: {style: {maxHeight: 250, width: 200}}}}
            onChange={handleAdrSelect}
          >
            {availableADRs?.map((adr) => (
              <MenuItem key={adr.id} value={adr.id} disabled={shouldMenuItemBeDisabled(adr)}>
               <Checkbox checked={selectedAdrDropdown.indexOf(adr.id) > -1}/>
               <ListItemText primary={adr.number} secondary={<CurrencyDisplay amount={adr.amount} currency={"PHP"} />} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedAdrs && <Box>
          {selectedAdrs?.map((adr) => {
            return (
              <FeePayAdrSlideOutItem
                key={adr.id}
                adr={adr}
                onAmountChange={handleAdrAmountSections}
                onCheckboxDeselect={handleAdrDeSelect}
              />)
          })}
        </Box>}
        <Container style={{ padding: '0', position: 'relative' }} maxWidth="lg"  className="mt-3">
          <Container maxWidth="lg">
            <Grid container spacing={2} className={classes.totalAmount}>
              <Grid item xs={6} style={{textAlign: 'left'}}>
                Total
              </Grid>
              <Grid item xs={6} style={{textAlign: 'right'}}>
                <CurrencyDisplay amount={items[0].amount} currency={"PHP"} />
              </Grid>
            </Grid>
          </Container>
          <FeePayAdrSlideOutPayButton 
            handlePayButtonClick={handlePayButtonClick}
            selectedAdrs={selectedAdrs}
            items={items}
          />
        </Container>
      </Drawer>
    </>
  );
};

export default FeePayAdrSlideOut;