import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CoreTextField from '../core/CoreTextField';
import CoreToggleField from '../core/CoreToggleField';
import PointToPointTemplateLineListTable from './PointToPointTemplateLineListTable';
import PointToPointTemplateLineCreateDialog from './PointToPointTemplateLineCreateDialog';
import PointToPointTemplateDriverDialog from './PointToPointTemplateDriverDialog';

const bold = {
  fontWeight: '600'
};

const PointToPointTemplateEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    onUpdate,
    rateTemplateFormData,
    updateRateTemplateFormData,
    rateTemplateFormValidation,
    templateLines,
    getTemplateLines,
    setTemplateLines,
    errorMessage,
    inProgress
  } = props;

  const [isTemplateLineCreateDialogOpen, setIsTemplateLineCreateDialogOpen] = useState(false);
  const [isDriversDialogOpen, setIsDriversDialogOpen] = useState(false);

  const openTemplateLineCreateDialog = () => {
    setIsTemplateLineCreateDialogOpen(true);
  };

  const closeTemplateLineCreateDialog = () => {
    getTemplateLines();
    setIsTemplateLineCreateDialogOpen(false);
  };

  const openDriversDialog = () => { 
    setIsDriversDialogOpen(true);
  };

  const closeDriversDialog = () => { 
    setIsDriversDialogOpen(false);
  };

  return ( 
    <>
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Point-To-Point Rate Template or Modify Point-To-Point Template Lines</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Name"
                label="Template Name"
                value={rateTemplateFormData.Name}
                onChange={e => updateRateTemplateFormData(e)}
                isRequired={true}
                validationError={rateTemplateFormValidation.Name}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreToggleField
                label="Active"
                name="IsActive"
                value={rateTemplateFormData.IsActive}
                onChange={e => updateRateTemplateFormData(e)}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
      <Paper variant="outlined" square className="mt-1">
        <Container maxWidth="lg" className="mt-2 mb-2">
          <Typography variant="subtitle1" style={bold} gutterBottom className="mb-2">
            {"Point-To-Point Template Lines"}
            <Button title={`Show all drivers using this template.`} 
              variant="contained" color="primary" style={{float: 'right'}} onClick={openDriversDialog}>
              Drivers
            </Button>
          </Typography>
          <PointToPointTemplateLineListTable
            templateLines={templateLines}
            getTemplateLines={getTemplateLines}
            setTemplateLines={setTemplateLines}
            openTemplateLineCreateDialog={openTemplateLineCreateDialog}
            isPersonnelAutoPay={true}
          />
        </Container>
      </Paper>     
    </Dialog>
    {isTemplateLineCreateDialogOpen &&
    <PointToPointTemplateLineCreateDialog
      pointToPointTemplateID={rateTemplateFormData.ID}
      isOpen={isTemplateLineCreateDialogOpen}
      onClose={closeTemplateLineCreateDialog}
    />
    }
    {isDriversDialogOpen &&
      <PointToPointTemplateDriverDialog
        templateID={rateTemplateFormData.ID}
        templateName={rateTemplateFormData.Name}
        isOpen={isDriversDialogOpen}
        onClose={closeDriversDialog}
      />
    }
  </>
  )
};

export default PointToPointTemplateEditDialogContent;