import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconCustomer from '../icon/IconCustomer';

const NavigationDrawerListItemCustomer = () => {
  return (
    <NavigationDrawerListItem 
      title={'Customers'} 
      icon={<IconCustomer />} 
      uri={'/spark/customer'}/>
  );

}

export default NavigationDrawerListItemCustomer;