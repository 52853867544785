import Container from "@material-ui/core/Container"
import CorePageHeader from "../core/CorePageHeader"
import TruckVisitListTable from "./TruckVisitListTable";

const TruckVisitList = () => {
    return (
        <div>
            <CorePageHeader
                title="Truck Visits"
                breadCrumb="Home / ... / Truck Visits"
            />
            <Container maxWidth="false" className="mt-4">
                <TruckVisitListTable />
            </Container>
        </div>
    );
}

export default TruckVisitList;