import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreExitButton from '../core/CoreExitButton';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import CoreSummaryFooter from '../core/CoreSummaryFooter';
import { get, getEquipment, getDocuments, getEvents } from './OrderService';
import OrderViewSummaryTabBar from './OrderViewSummaryTabBar';
import CircleIcon from '@material-ui/icons/Brightness1';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { markAsFullyPaid, markAsUnpaid, favorite as favoriteOrder, unfavorite as unfavoriteOrder } from './OrderService';

const useStyles = makeStyles(() => ({
  paper: {
    width:400
  },
  statusIcon: {
    color: 'black',
    fontWeight: 'normal',
    borderRadius: '15px',
    padding: '5px',
    fontSize: '12px',
    backgroundColor: '#E0E0E0'
  },
  iconCircle: {
    fontSize: '15px',
    verticalAlign: 'text-bottom'
  },
}));

const OrderViewSummary = (props) => {
  const classes = useStyles();
  const {
    orderID,
    isOpen,
    onClose,
    onCloseRoute,
    orderStatus,
    dispatchStatus
  } = props;
  let history = useHistory();

  const [inProgress, setInProgress] = useState(true);
  const [order, setOrder] = useState({});
  const [equipment, setEquipment] = useState({});
  const [events, setEvents] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [tabSelected, setTabSelected] = useState(1);
  const [statusIcon, setStatusIcon] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setInProgress(true);
    setOrder({});
    setEquipment({});
    setEvents([]);
    setDocuments([]);
    if (isOpen) {
      get(orderID).then(function(response) {
        setOrder(response);
        setInProgress(false);
      });
      getEquipment(orderID).then(function(response) {
        if(response.length > 0) {
          setEquipment(response[0]);
        }
      });
      getDocuments(orderID).then(function(response) {
        setDocuments(response);
      });
      getIntermodalEvents();
      if (orderStatus) {
        getOrderStatus();
      } else if (dispatchStatus) {
        getDispatchStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, orderID]);

  const editOrder = () => {
    if (tabSelected === 1) {
      history.push({
        pathname: '/spark/order/edit/' + order.ID,
        state: {
          order: order,
          onCloseRoute: onCloseRoute
        }
      });
    } else {
      history.push({
        pathname: '/spark/order/view/' + order.ID,
        state: {
          onCloseRoute: onCloseRoute,
          tabSelected: 4
        }
      });
    }
  };

  const openOrder = () => {
    history.push({
      pathname: '/spark/order/view/' + order.ID,
      state: {
        onCloseRoute: onCloseRoute
      }
    });
  };

  const updateTabs = e => {
    const { value } = e.target;
    setTabSelected(value);
  };

  function getIntermodalEvents() { 
    getEvents(orderID)
      .then(function(response) {
        setEvents(response);
    });
  };

  function getOrderStatus() {
    switch(orderStatus) {
      case "Unassigned": 
        setStatusIcon(getStatusIcon('#BDBDBD', orderStatus));
        break;
      case "Dispatched": 
        setStatusIcon(getStatusIcon('#54C7F2', orderStatus));
        break;
      case "In Transit": 
        setStatusIcon(getStatusIcon('#FFA30A', orderStatus));
        break;
      case "Completed": 
        setStatusIcon(getStatusIcon('#86DC76', orderStatus));
        break;
      case "Billing On Hold": 
        setStatusIcon(getStatusIcon('#EA5858', orderStatus));
        break;
      case "Approved For Billing": 
        setStatusIcon(getStatusIcon('#9575CD', orderStatus));
        break;
      case "Invoiced": 
        setStatusIcon(getStatusIcon('#80CBC4', orderStatus));
        break;
      case "Cancelled": 
        setStatusIcon(getStatusIcon('#EA5858', orderStatus));
        break;
      default: return;
    }
  };

  function getDispatchStatus() {
    switch(dispatchStatus) {
      case "On Vessel": 
        setStatusIcon(getStatusIcon('#E573D8', dispatchStatus));
        break;
      case "On Hold": 
        setStatusIcon(getStatusIcon('#FFB74D', dispatchStatus));
        break;
      case "Ready": 
        setStatusIcon(getStatusIcon('#81C784', dispatchStatus));
        break;
      case "Scheduled": 
        setStatusIcon(getStatusIcon('#FFD10A', dispatchStatus));
        break;
      case "Dispatched": 
        setStatusIcon(getStatusIcon('#73AFE5', dispatchStatus));
        break;
      case "Dropped": 
        setStatusIcon(getStatusIcon('#38A4D8', dispatchStatus));
        break;
      case "Last Free Day": 
        setStatusIcon(getStatusIcon('#E57373', dispatchStatus));
        break;
      default: return;
    }
  };

  function getStatusIcon(circleColor, statusName) {
    return <span className={classes.statusIcon}><CircleIcon style={{ color: circleColor }} className={classes.iconCircle}/> {statusName}&nbsp;</span>;
  };

  function getOrder() {
    get(orderID)
      .then(function (response) {
        setOrder(response);
      });
  };

  const favorite = () => { 
    favoriteOrder(orderID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const unFavorite = () => { 
    unfavoriteOrder(orderID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const markOrderAsPaid = () => { 
    markAsFullyPaid(orderID) 
      .then(function(response) { 
         getOrder();
         setAnchorEl(null);
      });
  };

  const markOrderAsUnpaid = () => {
    markAsUnpaid(orderID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <Drawer 
        anchor={'right'} 
        open={isOpen}
        ModalProps={{onBackdropClick: onClose }}
        classes={{ paper: classes.paper }}>  
        {!inProgress && <CoreSummaryHeader
          title={"Order " + order.OrderNum}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
          statusIcon={statusIcon}
        />}
        {inProgress && <LinearProgress />}
        {!inProgress && <OrderViewSummaryTabBar
          order={order}
          equipment={equipment}
          documents={documents}
          events={events}
          getEvents={getIntermodalEvents}
          setInProgress={setInProgress}
          tabSelected={tabSelected}
          onChange={updateTabs}
        />}        
        {!inProgress && <CoreSummaryFooter
          editRecord={editOrder}
          openRecord={openOrder}
          disableEdit={order.Status === "Cancelled"}
          enableMore={order.Status !== "Cancelled" || order.Status === "Invoiced"}
          onClickMore={handleClickMore}
        />}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: '150px',
              width: 'auto',
            },
          }}
        >       
        {order.Status !== "Cancelled" && !order.Favorite &&
          <MenuItem onClick={favorite}>Favorite</MenuItem>
        }
        {order.Status !== "Cancelled" && order.Favorite && 
          <MenuItem onClick={unFavorite}>Unfavorite</MenuItem>
        }        
        {order.Status === "Invoiced" && !order.IsFullyPaid &&
          <MenuItem onClick={markOrderAsPaid}>Mark As Paid</MenuItem>
        }
        {order.Status === "Invoiced" && order.IsFullyPaid &&
          <MenuItem onClick={markOrderAsUnpaid}>Mark As Unpaid</MenuItem>
        }
      </Menu>
      </Drawer>
    </>    
  );

}

export default OrderViewSummary;
