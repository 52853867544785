import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CountrySvg } from '../assets/svg-icons/country@1x.svg';

const IconCountry = () => {
  return (
    <SvgIcon fontSize="small">
      <CountrySvg />
    </SvgIcon>
  )
}
export default IconCountry;