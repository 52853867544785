import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreDateField from '../core/CoreDateField';
import Container from '@material-ui/core/Container';
import CoreOutlinedNumberField from '../core/CoreOutlinedNumberField';
import CoreBooleanField from '../core/CoreBooleanField';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CoreDropdownField from '../core/CoreDropdownField';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
  justifyContent: {
    justifyContent: 'right'
  },
}));

const DispatchFilterRow = (props) => {
  const {
    filterData,
    updateFilterData,
    resetFilterRow,
    listWidth
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth={listWidth} className="mb-2">
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={4}>
                  <CoreDateField
                    name={"DateFrom"}
                    label="Date From"
                    value={filterData.DateFrom}
                    onChange={e => updateFilterData(e)}
                    variant={"outlined"}
                    size={"small"}
                  />
                </Grid>
                <Grid item md={3} xs={4}>
                  <CoreDateField
                    name={"DateTo"}
                    label="Date To"
                    value={filterData.DateTo}
                    onChange={e => updateFilterData(e)}
                    variant={"outlined"}
                    size={"small"}
                  />
                </Grid>
                <Grid item md={3} xs={4}>
                <CoreBooleanField
                  name={"HasAppointment"}
                  label="Has Appointment"
                  value={filterData.HasAppointment}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={4}>
                <CoreOutlinedNumberField
                  name="ContainerSize"
                  label="Container Size"
                  minValue={0}
                  value={filterData.ContainerSize}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={4}>
                <CoreDropdownField
                  name={"LoadStatus"}
                  label={"Load Status"}
                  value={filterData.LoadStatus}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                  list={["Completed", "Billing On Hold", "Approved For Billing", "Invoiced"]}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <CoreDropdownField
                  name={"LoadedStatus"}
                  label={"Loaded/Empty Status"}
                  value={filterData.LoadedStatus}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                  list={["Loaded", "Empty"]}
                />
              </Grid>
              <Grid item md={4} xs={2}>
                  <CoreBooleanField
                    name={"IsBeingTraced"}
                    label={"Traced by Envase Trace"}
                    value={filterData.IsBeingTraced}
                    onChange={e => updateFilterData(e)}
                    variant={"outlined"}
                    size={"small"}
                  />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={12}>
            <Grid container spacing={1} className={classes.justifyContent}>
              <IconButton className={classes.iconText} onClick={resetFilterRow}>
                <HighlightOffIcon />&nbsp;<span>RESET</span>
              </IconButton>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </div>
  );

}

export default DispatchFilterRow;