import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import Validation from '../validation';
import CorePageHeader from '../core/CorePageHeader';
import CoreDateField from '../core/CoreDateField';
import PersonnelField from '../personnel/PersonnelField';
import ReportsCustomerLogsRecordTypeField from "./ReportsCustomerLogsRecordTypeField";
import { getCustomerLogs } from './ReportService';

const ReportsCustomerLogs = () => {
  let history = useHistory();

  const [reportFormData, setReportFormData] = useState({
    StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toString(),
    EndDate: new Date().toString(),
    PersonnelID: null,
    PersonnelName: null,
    RecordType: ""
  });

  const [reportFormValidation, setReportFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [reportData, setReportData] = useState([]);
  const [reportedLoaded, setReportedLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  let columns = [
    {
      field: 'ModificationTable',
      headerName: 'Record Type',
      align: 'left',
      width: 200
    },
    {
      field: 'RelatedID',
      headerName: 'Record ID',
      align: 'left',
      width: 125
    },
    {
      field: 'PersonnelName',
      headerName: 'Personnel Internal Name',
      align: 'left',
      width: 250
    },
    {
      field: 'ModificationDate',
      headerName: 'Date Changed',
      align: 'left',
      width: 200
    },
    {
      field: 'Modification',
      headerName: 'Change Description',
      align: 'left',
      flex: 1
    }
  ];

  useEffect(() => {
    setReportFormValidation(buildValidation(reportFormData));
  }, [reportFormData]);

  const updateReportFormData = e => {
    const {
      name,
      value,
      nameData,
      valueData,
    } = e.target;
    reportFormData[name] = value;
    reportFormData[nameData] = valueData;
    setReportFormData({ ...reportFormData });
  };
  
  const onClose = () => { 
    setReportFormData({
        StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toString(),
        EndDate: new Date().toString(),
        PersonnelID: null,
        PersonnelName: null,
        RecordType: "",
    });
    setReportData([]);
    setReportedLoaded(false);
    history.push('/spark/reports');
  };

  const onConfirm = e => {
    setInProgress(true);
    setErrorMessage('');
    e.preventDefault();
    if (!new Validation().isValidObject(reportFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processReportRequest();
  };

  const processReportRequest = async () => {
    let request = {
      ...reportFormData
    };
    request.StartDate = getDateWithZeroTime(request.StartDate);
    request.EndDate = getDateWithZeroTime(request.EndDate);
    if (request.RecordType === "") {
      request.RecordType = null;
    }
    const logs = await getCustomerLogs(request);
    for (let i = 0; i < logs.length; i++) {
      logs[i].Index = i;
    };
    setReportData(logs);
    setReportedLoaded(true);
    setInProgress(false);
  };

  function getDateWithZeroTime(date) {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  function ExportToolBar() { 
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <CorePageHeader 
        title="Reports - Customer Logs" 
      />
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Customer Logs</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{ paddingBottom: "10px" }}>
          <Grid item md={3} xs={3}>
            <CoreDateField 
              label="Start Date" 
              name="StartDate"
              value={reportFormData.StartDate}
              onChange={e => updateReportFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <CoreDateField 
              name="EndDate" 
              label="End Date"
              value={reportFormData.EndDate}
              onChange={e => updateReportFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <PersonnelField
              name="PersonnelName"
              label="Personnel"
              value={reportFormData.PersonnelName}
              nameData="PersonnelID"
              valueData={reportFormData.PersonnelID}
              onChange={e => updateReportFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <ReportsCustomerLogsRecordTypeField
              label="Record Type"
              name="RecordType"
              value={reportFormData.RecordType}
              onChange={e => updateReportFormData(e)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {errorMessage &&
                <Alert variant="filled" severity="error" className="mt-1 mb-1">
                {errorMessage}
                </Alert>
            }
          </Grid>
          <Grid item md={12} xs={12}>
            <Button onClick={onClose} disabled={inProgress} color="primary">
              Cancel
            </Button>
            <Button onClick={onConfirm} disabled={inProgress} color="primary">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Container>
      {reportedLoaded && <Container style={{'padding': 0}} square={true} maxWidth="lg" className="mt-4" component={Paper}>
        <DataGrid
          getRowId={(row) => row.Index} 
          autoHeight={true}
          rows={reportData}
          columns={columns}
          pageSize={100}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          components={{
            Toolbar: ExportToolBar,
          }}
        />
      </Container>}
    </>
  );
};

const buildValidation = (reportFormData) => {
  const {
    StartDate,
    EndDate
  } = reportFormData || {};
  const validation = new Validation();
  return {
    StartDate: validation.isEmpty(StartDate),
    EndDate: validation.isEmpty(EndDate) 
  };
};

export default ReportsCustomerLogs;