import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreOutlinedTextField from '../core/CoreOutlinedTextField';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomerField from '../customer/CustomerField';
import CoreDateField from '../core/CoreDateField';
import CoreBooleanField from '../core/CoreBooleanField';
import CoreListPaidUnpaidField from '../core/CoreListPaidUnpaidField';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const OrderListFilterRow = (props) => {
  const {
    filterData,
    updateFilterData,
    resetFilterRow,
    listWidth,
    customerName,
    companyFeature
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Container 
        maxWidth={listWidth} 
        className="mb-2">
        <Grid container spacing={1}>
          <Grid item md={10} xs={10}>
            <Grid container spacing={2}>
              <Grid item md={2} xs={2}>
                <CoreOutlinedTextField
                  name="ContainerNumber"
                  label="Container #"
                  value={filterData.ContainerNumber}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={2} xs={2}>
                <CoreOutlinedTextField
                  name="ReferenceNumber"
                  label="REF #"
                  minValue={0}
                  value={filterData.ReferenceNumber}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CustomerField
                  name="Customer"
                  label="Customer"
                  customerForEdit={customerName}
                  value={filterData.Customer}
                  nameData="CustomerID"
                  valueData={filterData.CustomerID}
                  variant={"outlined"}
                  size={"small"}
                  onChange={e => updateFilterData(e)}
                />
              </Grid>              
              <Grid item md={2} xs={2}>
                <CoreOutlinedTextField
                  name="BookingNum"
                  label="MBL # / BK #"
                  minValue={0}
                  value={filterData.BookingNum}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={2} xs={2}>
                <CoreDateField
                  name={"LastConfirmedDate"}
                  label="Last Confirmed Date"
                  value={filterData.LastConfirmedDate}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={1} xs={1}>
                <CoreListPaidUnpaidField
                  name={"PaidUnpaid"}
                  value={filterData.PaidUnpaid}
                  onChange={e => updateFilterData(e)}
                  size={"small"}
                />
              </Grid>
              {companyFeature && companyFeature.IsICTSI && <Grid item md={2} xs={2}>
                <CoreBooleanField
                  name={"AppointmentBooked"}
                  label="Has Appointment"
                  value={filterData.AppointmentBooked}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>}
            </Grid>
          </Grid>
          <Grid item md={1} xs={1} style={{paddingLeft: '10px'}}>
            <Grid container>
              <CoreBooleanField
                  name={"Favorite"}
                  label="Show Favorites"
                  value={filterData.Favorite}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
            </Grid>
          </Grid>
          <Grid item md={1} xs={12}>
            <Grid container spacing={1} justify="flex-end">
              <IconButton 
                item md={2}
                className={classes.iconText} 
                onClick={resetFilterRow}>
                <HighlightOffIcon />&nbsp;<span>RESET</span>
              </IconButton>             
            </Grid>
          </Grid>         
        </Grid>
      </Container>
    </div>
  );

}

export default OrderListFilterRow;