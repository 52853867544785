import { Route } from 'react-router-dom';
import DispatchList from './DispatchList';

const DispatchRouter = () => {
  return (
    <>
      <Route exact path="/spark/dispatch" component={DispatchList} />
    </>
  );

}

export default DispatchRouter;