import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory } from 'react-router-dom';
import Validation from '../validation';
import { create as customerCreate } from './CustomerService';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { getPersonnelID } from '../account/AccountService';
import CoreExitButton from '../core/CoreExitButton';
import CustomerCreateContent from './CustomerCreateContent';
import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';

const CustomerCreate = () => {
  let history = useHistory();

  const [customerFormData, setCustomerFormData] = useState({
    internalName: '',
    legalName: '',
    email: '',
    accountRep: '',
    accountRepID: null,
    branchID: null,
    salesRepID: null,
    podRequirements: 0,
    invoiceTransmitMethod: 0,
    creditTerms: 0,
    custAddress: '',
    custCity: '',
    custProvince: '',
    custPostalCode: '',
    custCountry: '',
    custPhone: '',
    custPhoneField: '',
    custTollFree: '',
    billAddressSame: false,
    billAddress: '',
    billCity: '',
    billProvince: '',
    billPostalCode: '',
    billCountry: '',
    billPhone: '',
    billTollFree: '',
    billTollFreeField: '',
    batchInvoiceMethod: 'No Grouping'
  });

  const [inProgress, setInProgress] = useState(false);
  const [customerFormValidation, setCustomerFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [addressCoordinates, setAddressCoordinates] = useState({'lat': 0, 'lng': 0});
  const [billAddressCoordinates, setBillAddressCoordinates] = useState({'lat': 0, 'lng': 0});

  useEffect(() => {
    async function setDefaultSalesRepID() {
      const salesRepID = await getPersonnelID();
      setCustomerFormData(c => ({ ...c, salesRepID: salesRepID }));
    }
    async function setDefaultBranchID() {
      const branchID = await getMainBranchID();
      setCustomerFormData(c => ({...c, branchID: branchID}));
    }
    setDefaultBranchID();
    setDefaultSalesRepID();
  }, [setCustomerFormData]);

  useEffect(() => {
    setCustomerFormValidation(buildValidation(customerFormData));
  }, [customerFormData]);

  useEffect(() => { 
    getGeocode(customerFormData.custAddress, customerFormData.custCity, customerFormData.custProvince, customerFormData.custPostalCode) 
      .then(function(response) {
        setAddressCoordinates(response);
     });
  }, [customerFormData.custAddress, customerFormData.custCity, customerFormData.custProvince, customerFormData.custPostalCode]);

  useEffect(() => { 
    if (!customerFormData.billAddressSame) {
      getGeocode(customerFormData.billAddress, customerFormData.billCity, customerFormData.billProvince, customerFormData.billPostalCode) 
      .then(function(response) {
        setBillAddressCoordinates(response);
     });
    }
  }, [customerFormData.billAddress, customerFormData.billCity, customerFormData.billProvince, customerFormData.billPostalCode, customerFormData.billAddressSame]);

  useEffect(() => {
    if (customerFormData.billAddressSame) {
      customerFormData.billAddress = customerFormData.custAddress;
      customerFormData.billCity = customerFormData.custCity;
      customerFormData.billProvince = customerFormData.custProvince;
      customerFormData.billPostalCode = customerFormData.custPostalCode;
      customerFormData.billCountry = customerFormData.custCountry;
      customerFormData.billPhone = customerFormData.custPhone;
      customerFormData.billTollFree = customerFormData.custTollFree;
      customerFormData.billDistrict = customerFormData.custDistrict;
      customerFormData.billCounty = customerFormData.custCounty;
    } else {
      customerFormData.billAddress = "";
      customerFormData.billCity = "";
      customerFormData.billProvince = "";
      customerFormData.billPostalCode = "";
      customerFormData.billCountry = "";
      customerFormData.billPhone = "";
      customerFormData.billTollFree = "";
      customerFormData.billDistrict = "";
      customerFormData.billCounty = "";
    }
    setCustomerFormData({ ...customerFormData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFormData.billAddressSame]);

  const updateCustomerFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    customerFormData[name] = value;
    customerFormData[nameData] = valueData;
    setCustomerFormData({ ...customerFormData });
  };

  const formatCustomer = () => {
    if (new Validation().isNumber(customerFormData.creditTerms)) {
      customerFormData.creditTerms = 0;
    }
    setCustomerFormData({ ...customerFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    formatCustomer();
    e.preventDefault(); 
    if (!new Validation().isValidObject(customerFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }    
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await customerCreate(customerFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    // Redirect to the customer view page
    history.push('/spark/customer/view/' + result.response);
  };

  const onClose = () => {
    history.push('/spark/customer');
  };

  return (
    <div>
      <CorePageHeader
        title="Create New Customer"
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        <CustomerCreateContent
          onCreate={onCreate}
          onClose={onClose}
          errorMessage={errorMessage}
          customerFormData={customerFormData}
          customerFormValidation={customerFormValidation}
          updateCustomerFormData={updateCustomerFormData}
          addressCoordinates={addressCoordinates}
          billAddressCoordinates={billAddressCoordinates}
          inProgress={inProgress}
        />
      </Container>
    </div>    
  );
}

const buildValidation = (customerFormData) => {
  const { 
    internalName,
    legalName,
    accountRepID,
    batchInvoiceMethod
  } = customerFormData || {};
  const validation = new Validation();
  return {
    internalName: validation.isEmpty(internalName),
    legalName: validation.isEmpty(legalName),
    accountRepID: validation.isNumber(accountRepID),
    batchInvoiceMethod: validation.isEmpty(batchInvoiceMethod)
  };
};

export default CustomerCreate;