import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import CoreCopyButton from '../core/CoreCopyButton';
import IconEmail from '../icon/IconEmail';
import IconPhone from '../icon/IconPhone';
import IconLegalName from '../icon/IconLegalName';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  },
  iconSize24: {
    fontSize: '24px'
  },
  divider: {
    borderBottom: '1px solid #efefef',
    paddingBottom: '20px'
  },
}));

const CustomerViewSummaryContactSection = (props) => {
  const classes = useStyles()
  const {
    contact
  } = props;

  return (
    <div>
    <Grid container spacing={1} className={classes.divider}>
      <Grid item md={12} xs={12}>
        <CoreSummarySectionTitle title="QUICK CONTACT" />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Contact Name"
            value={contact.Name}
            icon={<IconLegalName />}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={10} style={{fontSize:'14px'}} className={classes.iconSize24}>
          <CoreTextDisplayField
            label="Email"
            value={contact.Email}
            isEmail={true}
            icon={<IconEmail />}
          />
        </Grid>
        <Grid item xs={2}>
          <CoreCopyButton
            textToCopy={contact.Email}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize24}>
          <CoreTextDisplayField
            label="Phone"
            value={<CoreIntlPhoneOutput value={contact.Phone} />}
            icon={<IconPhone />}
          />
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
}

export default CustomerViewSummaryContactSection;