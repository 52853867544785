import { Route } from 'react-router-dom';
import EquipmentList from './EquipmentList';
import EquipmentView from './EquipmentView';
import EquipmentCreate from './EquipmentCreate';
import EquipmentEdit from './EquipmentEdit';

const EquipmentRouter = () => {
  return (
    <>
      <Route exact path="/spark/equipment" component={EquipmentList} />
      <Route exact path="/spark/equipment/view/:id" component={EquipmentView} />
      <Route exact path="/spark/equipment/create" component={EquipmentCreate} />
      <Route exact path="/spark/equipment/edit/:id" component={EquipmentEdit} />
    </>
  );

}

export default EquipmentRouter;