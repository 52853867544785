import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './Authentication.module.css';

const AuthenticationFooter = () => {

  return (
    <Grid item md={12} xs={12} className={styles.copyrightFooter}>
      <Grid item md={12} xs={12}>
        <Typography variant="caption" gutterBottom>
          © {new Date().getFullYear()} Envase Holdings LLC.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="caption" gutterBottom>
          All photo images copyright of the respective owner.
        </Typography>
      </Grid>
    </Grid>
  )
};

export default AuthenticationFooter;