import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { DataGrid } from "@material-ui/data-grid";

import { reportOptions } from './ReportListOptions';
import { getPersonnelID } from '../account/AccountService';
import { getFull } from '../personnel/PersonnelService';
import { getIsOwner } from '../admin/AdminService';

const ReportsListTable = () => { 
  let history = useHistory();

  let columns = [ 
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },
    {
      field: 'Description',
      headerName: 'Description',
      align: 'left',
      flex: 1,
      sortable: 'false'
    }
  ];

  const onReportCellClick = (cell) => {
    history.push(cell.row.redirect);
  };

  const [reports, setReports] = React.useState(reportOptions());

  React.useEffect(() => {
    const handleShowCustomerLogsReport = async () => {
      const isOwner = await getIsOwner();
      const personnelID = await getPersonnelID();
      const fullPersonnelRecord = await getFull(personnelID);

      if (isOwner && (fullPersonnelRecord.LoginID.trim() === "CMS" || fullPersonnelRecord.Email.trim() === "twsupport@envasetechnologies.com")) {
        let reports = reportOptions();
        const lastReport = reports[reports.length -1 ];
        const logsReport = {
          ID: lastReport.ID + 1,
          Name: "Customer Log Report",
          Description: "Search customer logs.",
          redirect: "/spark/reports/customer-logs-report"
        };
        reports.push(logsReport);
        setReports(reports);
      };
    };

    handleShowCustomerLogsReport();
  }, []);

  return  (
    <div>
      <Container style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-2" component={Paper}>
        <DataGrid
          getRowId={(row) => row.ID} 
          hideFooter={true} 
          autoHeight={true} 
          rows={reports} 
          columns={columns} 
          pageSize={100} 
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          onCellClick={e => onReportCellClick(e)}
        />
      </Container>
    </div>
  );
}

export default ReportsListTable;