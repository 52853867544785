import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBatchInvoiceMethods } from '../resource/ResourceService';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

const CustomerBatchInvoiceMethodField = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [batchInvoiceMethodList, setBatchInvoiceMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getBatchInvoiceMethodList() {
      const result = await getBatchInvoiceMethods();
      setBatchInvoiceMethodList(result);
      setIsLoading(false);
    }
    getBatchInvoiceMethodList();
  }, []);

  const onEventSelected = newValue => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue ? newValue.Name : ''
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const event = batchInvoiceMethodList
      .find(method => method.Name === value);
    if (event) {
      return event.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onEventSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={batchInvoiceMethodList}
      disableClearable={true}
      {...rest}
    />
  );

}

CustomerBatchInvoiceMethodField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default CustomerBatchInvoiceMethodField;