import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreAddButton from '../core/CoreAddButton';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import EquipmentTypeListColumnEditButton from '../equipment_type/EquipmentTypeListColumnEditButton';
import { deleteEquipmentType } from '../equipment_type/EquipmentTypeService';

const ConfigurationViewEquipmentTypeSectionListTable = (props) => {
  const {
    equipmentTypes,
    getEquipmentTypes,
    setEquipmentTypes,
    handleOpenCreate
  } = props;

  let columns = [
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 2,
      sortable: false,
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={handleOpenCreate}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const equipmentTypeID = params.getValue("EquipmentTypeID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <EquipmentTypeListColumnEditButton
                equipmentTypeID={equipmentTypeID}
                getEquipmentTypes={getEquipmentTypes}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Equipment Type: ${params.getValue("Name")}`}
                deleteID={equipmentTypeID}
                deleteFunction={deleteEquipmentType}
                getList={getEquipmentTypes}
              />}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configEquipmentTypeAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configEquipmentTypeAmount", amount);
  };

  const onFieldRowEnter = (params) => {
    for (const item of equipmentTypes) {
      if (item.EquipmentTypeID === params.row.EquipmentTypeID) {
        item.ShowControls = true;
      }
    }
    setEquipmentTypes([...equipmentTypes]);
  };

  const onFieldRowLeave = (params) => {
    for (const item of equipmentTypes) {
      if (item.EquipmentTypeID === params.row.EquipmentTypeID) {
        item.ShowControls = false;
      }
    }
    setEquipmentTypes([...equipmentTypes]);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.EquipmentTypeID}
        autoHeight={true}
        rows={equipmentTypes}
        columns={columns}
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onFieldRowEnter(e)}
        onRowLeave={e => onFieldRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewEquipmentTypeSectionListTable.propTypes = {
  equipmentTypes: PropTypes.array.isRequired,
  getEquipmentTypes: PropTypes.func.isRequired
};

export default ConfigurationViewEquipmentTypeSectionListTable;