import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { getEquipmentType, updateEquipmentType } from './EquipmentTypeService';
import EquipmentTypeEditDialogContent from './EquipmentTypeEditDialogContent';

const EquipmentTypeEditDialog = (props) => {
  const {
    equipmentTypeID,
    isOpen,
    onClose,
  } = props;

  const [equipmentTypeFormData, setEquipmentTypeFormData] = useState({});
  const [equipmentTypeFormValidation, setEquipmentTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getEquipmentType(equipmentTypeID)
      .then(function(response) {
        setEquipmentTypeFormData(response);
      });
    }
  }, [isOpen, equipmentTypeID]);

  useEffect(() => {
    if (isOpen) {
      setEquipmentTypeFormValidation(buildValidation(equipmentTypeFormData));
    }
  }, [equipmentTypeFormData, isOpen]);

  const updateEquipmentTypeFormData = e => {
    const { name, value } = e.target;
    equipmentTypeFormData[name] = value;
    setEquipmentTypeFormData({ ...equipmentTypeFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(equipmentTypeFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await updateEquipmentType(equipmentTypeFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    props.onClose();
  };

  return (
    isOpen &&
    <EquipmentTypeEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      equipmentTypeFormData={equipmentTypeFormData}
      equipmentTypeFormValidation={equipmentTypeFormValidation}
      updateEquipmentTypeFormData={updateEquipmentTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (equipmentTypeFormData) => {
  const {
    FullPath,
  } = equipmentTypeFormData || {};
  const validation = new Validation();
  return {
    FullPath: validation.isEmpty(FullPath),
  };
};

export default EquipmentTypeEditDialog;