import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Branch/' + token + '/';

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  const branch = result.data[0];
  branch.Province = branch.Province.trim();
  branch.Country = branch.Country.trim();
  branch.SCAC = branch.SCAC.trim();
  return branch;
};

export async function update(branch) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + branch.BranchID + '/Update', branch);
  return result;
};

export async function uploadLogo(id, logo) {
  var formData = new FormData();
  formData.append('file', logo);
  const httpService = new HttpService();
  const result = await httpService
    .postFile(uri + id + '/UploadLogo', formData);
  return result;
};

export async function getLogo(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + `${id}/GetLogo`);
  return result;
}