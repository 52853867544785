import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import PointToPointTemplateEditDialog from './PointToPointTemplateEditDialog';
import { Get, GetTemplateLines } from './PointToPointTemplateService';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const PointToPointTemplateColumnEditButton = (props) => { 
  const { 
    rateTemplateID,
    getRateTemplates
  } = props;

  const classes = useStyles();
  const [isRateTemplateEditModalOpen, setIsRateTemplateEditModalOpen] = useState(false);
  const [templateFormData, setTemplateFormData] = useState({});
  const [pointToPointTemplateLines, setPointToPointTemplateLines] = useState([]);

  useEffect(() => { 
    GetTemplateLines(rateTemplateID)
      .then(function(response) { 
        setPointToPointTemplateLines(response);
      });
  }, [rateTemplateID]);

  async function openTemplateEditModal() { 
    const result = await Get(rateTemplateID);
    setTemplateFormData(result);
    setIsRateTemplateEditModalOpen(true);
  };

  const closeRateTemplateEditModal = () => {
    setIsRateTemplateEditModalOpen(false);
    getRateTemplates();
  };

  const getPointToPointTemplateLines = () => { 
    GetTemplateLines(rateTemplateID)
      .then(function(response) { 
        setPointToPointTemplateLines(response);
      });
  };

  return ( 
    <>
      <IconButton className={classes.iconText} onClick={openTemplateEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <PointToPointTemplateEditDialog
        rateTemplateID={rateTemplateID}
        isOpen={isRateTemplateEditModalOpen}
        onClose={closeRateTemplateEditModal}
        templateFormData={templateFormData}
        setTemplateFormData={setTemplateFormData}
        pointToPointTemplateLines={pointToPointTemplateLines}
        getPointToPointTemplateLines={getPointToPointTemplateLines}
        setPointToPointTemplateLines={setPointToPointTemplateLines}
      />
    </>
  )
};

export default PointToPointTemplateColumnEditButton;