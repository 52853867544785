import React, { useState, useEffect } from 'react';
import Validation from '../validation';

import PointToPointTemplateLineEditDialogContent from './PointToPointTemplateLineEditDialogContent';
import { Get, Update } from './PointToPointTemplateLineService';
import { GetDrivers } from './PointToPointTemplateService';
import { getPayableItem } from '../payable_item/PayableItemService';


const PointToPointTemplateLineEditDialog = (props) => { 
  const { 
    pointToPointTemplateLineID,
    isOpen,
    onClose,
    isCreateNewTemplate,
    personnelID
  } = props;

  const [pointToPointTemplateLineFormData, setPointToPointTemplateLineFormData] = useState({});
  const [pointToPointTemplateLineFormValidation, setPointToPointTemplateLineFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [templateLineDataLoaded, setTemplateLineDataLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [personnelForNewTemplate, setPersonnelForNewTemplate] = useState([]);

  useEffect(() => { 
    if (isOpen) { 
      Get(pointToPointTemplateLineID)
        .then(function(response) { 
          setPointToPointTemplateLineFormData(response);        
          getDrivers(response.PointToPointTemplateID);           
          getInitialPayableItemData(response.PayableChargeTypeID);
          setTemplateLineDataLoaded(true);
        });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, pointToPointTemplateLineID]);

  useEffect(() => { 
    if (isOpen) { 
        setPointToPointTemplateLineFormValidation(buildValidation(pointToPointTemplateLineFormData));
    }
  }, [isOpen, pointToPointTemplateLineFormData]);

  const getDrivers = (pointToPointTemplateID) => {
    GetDrivers(pointToPointTemplateID, personnelID)
      .then(function(response) {
        setDrivers(response);
      });
  }; 

  const updatePointToPointTemplateLineFormData = e => { 
    const {name, value, nameData, valueData} = e.target;
    pointToPointTemplateLineFormData[name] = value;
    pointToPointTemplateLineFormData[nameData] = valueData;
    setPointToPointTemplateLineFormData({ ...pointToPointTemplateLineFormData });
    updatePayableItemTypeDefaults(nameData);
  };

  const updatePayableItemTypeDefaults = async (name) => {
    if (pointToPointTemplateLineFormData.PayableChargeTypeID && name === "PayableChargeTypeID") {
      getPayableItemData(pointToPointTemplateLineFormData.PayableChargeTypeID);
    } 
  };

  const getInitialPayableItemData = async(payableChargeTypeID) => {
    const payableItem = await getPayableItem(payableChargeTypeID);
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitRateType: payableItem.IntermodalUnitRateType }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitRateTypeName: payableItem.IntermodalUnitRateTypeName }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitType: payableItem.IntermodalUnitType }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitTypeName: payableItem.IntermodalUnitTypeName }));
  };

  const getPayableItemData = async(payableChargeTypeID) => {
    const payableItem = await getPayableItem(payableChargeTypeID);
      setPointToPointTemplateLineFormData(p => ({ ...p, Units: payableItem.UnitsDefaultValue}));
      setPointToPointTemplateLineFormData(p => ({ ...p, UnitRate: payableItem.UnitRateDefaultValue }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitRateType: payableItem.IntermodalUnitRateType }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitRateTypeName: payableItem.IntermodalUnitRateTypeName }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitType: payableItem.IntermodalUnitType }));
      setPointToPointTemplateLineFormData(p => ({ ...p, IntermodalUnitTypeName: payableItem.IntermodalUnitTypeName }));
  };

  const formatPersonnelForNewTemplate = () => {
    if (personnelID) {
      personnelForNewTemplate.push(personnelID);
    }
    drivers.forEach(
      driver => {
        if (driver.UpdateTemplate) {
          personnelForNewTemplate.push(driver.PersonnelID);
        }
      }
    );   
    setPersonnelForNewTemplate( ...personnelForNewTemplate );
  };

  const onUpdate = e => { 
    setInProgress(true);
    formatPersonnelForNewTemplate();
    e.preventDefault();
    if (!new Validation().isValidObject(pointToPointTemplateLineFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => { 
    setErrorMessage('');
    const result = await Update(pointToPointTemplateLineFormData, isCreateNewTemplate, personnelForNewTemplate);
    if (result.error) { 
      setErrorMessage(result.error[0]);
      setInProgress(false);
      return;
    }
    if (isCreateNewTemplate && result.response) {
      onClose(result.response);
    } else {
      onClose();
    }
  };

  const closeDialog = () => {
    onClose();
  };

  return ( 
    isOpen && templateLineDataLoaded &&
    <PointToPointTemplateLineEditDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onUpdate={onUpdate}
      templateLineFormData={pointToPointTemplateLineFormData}
      updateTemplateLineFormData={updatePointToPointTemplateLineFormData}
      errorMessage={errorMessage}
      templateLineFormValidation={pointToPointTemplateLineFormValidation}
      inProgress={inProgress}
      drivers={drivers}
      setDrivers={setDrivers}
      isCreateNewTemplate={isCreateNewTemplate}
    />
  );
};

const buildValidation = (pointToPointTemplateLineFormData) => { 
  const { 
    OriginAreaTagID,
    DestinationAreaTagID,
    PayableChargeTypeID,
    Units,
    UnitRate
  } = pointToPointTemplateLineFormData || {};
  const validation = new Validation();
  return { 
    OriginAreaTagID: validation.isNumber(OriginAreaTagID),
    DestinationAreaTagID: validation.isNumber(DestinationAreaTagID),
    PayableChargeTypeID: validation.isNumber(PayableChargeTypeID),
    Units: validation.isNumber(Units),
    UnitRate: validation.isNumber(UnitRate)
  };
};

export default PointToPointTemplateLineEditDialog;