// https://github.com/atlassian/react-beautiful-dnd
// https://codesandbox.io/s/vertical-list-forked-9e42qu

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicator from '@material-ui/icons/DragIndicator';
import { Grid } from '@material-ui/core';

const DragNDropExample = () => {

  // Each item requires an 'id' with a string value
  const [itemList, setItemList] = useState([
    { id: '1', name: 'First Item' },
    { id: '2', name: 'Second Item' },
    { id: '3', name: 'Third Item' },
    { id: '4', name: 'Fourth Item' }
  ]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 20,
    margin: `0 0 ${10}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 10,
    width: 250
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result)  => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      itemList,
      result.source.index,
      result.destination.index
    );

    setItemList(
      items
    );
  }


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {itemList.map((item, index) => (
              // We most likely want to extract this into a separate component
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={2}>
                        <div
                            {...provided.dragHandleProps}>
                          <DragIndicator />
                        </div>
                      </Grid>
                      <Grid item md={10}>
                        {item.name}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

}

export default DragNDropExample;