import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import IconUnitNumber from '../icon/IconUnitNumber';
import IconSize from '../icon/IconSize';
import IconContainer from '../icon/IconContainer';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const OrderViewSummaryEquipmentSection = (props) => {
  const classes = useStyles();

  const {
    equipment
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="EQUIPMENT" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Unit Number"
              value={equipment.UnitNum}
              icon={<IconUnitNumber />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Unit Type"
              value={equipment.UnitType}
              icon={<IconContainer />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Size (Outside Length)"
              value={equipment.OutsideLengthFt ? `${equipment.OutsideLengthFt} ft.` : ''}
              icon={<IconSize />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderViewSummaryEquipmentSection;
