import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';

const CoreExpandLessButton = (props) => {
  const { 
    toggleIsOpen,
    ariaLabel
  } = props;

  return (
    <IconButton style={{padding: "0"}} onClick={toggleIsOpen} color="primary" aria-label={ariaLabel} component="span">
      <ExpandLess />
    </IconButton>
  );
}

export default CoreExpandLessButton;