import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DroppedSvg } from '../assets/svg-icons/dsp-dropped1@1x.svg';

const IconDspDropped = () => {
  return (
    <SvgIcon fontSize="large">
      <DroppedSvg />
    </SvgIcon>
  )
}
export default IconDspDropped;