import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIntermodalUnitTypes } from '../resource/ResourceService';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

const IntermodalUnitTypeField = (props) => {

  const {
    label,
    value,
    valueData,
    name,
    nameData,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [unitTypeList, setUnitTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUnitTypeList() {
      const result = await getIntermodalUnitTypes();
      setUnitTypeList(result);
      setIsLoading(false);
    }
    getUnitTypeList();
  }, []);

  const onEventSelected = newValue => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue? newValue.ID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const unitType = unitTypeList.find(unitType => unitType.Name === value);
    if (unitType) {
      return unitType.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onEventSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={unitTypeList}
      {...rest}
    />
  );

}

IntermodalUnitTypeField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default IntermodalUnitTypeField;