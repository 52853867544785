import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';

const CorePhoneField = (props) => {

  const { 
    name, 
    label, 
    value,  
    isRequired, 
    autoFocus, 
    validationError, 
    onChange,
    ...rest
  } = props;

  
  const [phoneFlag, setPhoneFlag] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");

  React.useEffect(() => {
    var phone = value || "";
    if (phone && !phone.startsWith('+')) {
      phone = "+".concat(phone);
    }
    setPhoneValue(phone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBackspace = (formatNumber, phoneValue) => {
    if (formatNumber.length < phoneValue.replace(/\D/g,'').length) {
      return true;
    }
    return false;
  };

  const handleChange = newValue => {
    var formatNumber = newValue.replace(/\D/g,'');
    formatNumber.trim();
    if (!newValue.startsWith('+')) {
      newValue = "+".concat(newValue);
    }
    if (formatNumber.charAt(0) === "1" && phoneFlag !== true) {
      setPhoneFlag(true);
    } else if (formatNumber.charAt(0) !== "1" && phoneFlag === true) {
      setPhoneFlag(false);
    }
    if (formatNumber.length === 10 && !newValue.startsWith('+63') && !isBackspace(formatNumber, phoneValue) && phoneFlag !== true) { 
      newValue = newValue.substring(0, 1) + "1" + newValue.substring(1);
      formatNumber = "1" + formatNumber;
    }
    const dispatchEvent = {
      target: {
        name: name,
        value: formatNumber
      }
    };
    onChange(dispatchEvent);
    setPhoneValue(newValue);
  };

  return (
    <FormControl fullWidth>
      <MuiPhoneNumber
        name={name}
        label={label}
        data-cy="user-phone"
        onlyCountries={["us", "ca", "ph"]}
        value={phoneValue}
        onChange={handleChange}
        required={isRequired} 
        error={isRequired && validationError}
        autoFocus={autoFocus}
        {...rest}
      />
    </FormControl>
  );
}

CorePhoneField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default CorePhoneField;