import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const OrderAppointmentQuickBuySuccessDialog = (props) => {
  const {
    handleClose,
    isOpen,
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Appointment Successfully Processed</DialogTitle>
      <DialogContent>
        
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            Appointment has been successfully processed and has been added to your outstanding ICTSI account balance.
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog >
  );

}

export default OrderAppointmentQuickBuySuccessDialog;