import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getQuickBooksRevenueItemList } from './QuickbooksService';

const QuickbooksRevenueDropdownField = (props) => {

  const {
    label,
    value,
    name,
    nameData,
    valueData,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [revenues, setRevenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getRevenuesList() {
      const result = await getQuickBooksRevenueItemList();
      setRevenues(result);
      setIsLoading(false);
    }
    getRevenuesList();
  }, []);

  const onRevenueSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.Id : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const acct = revenues
      .find(acct => acct.Id === valueData);
    if (acct) {
      return acct.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onRevenueSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={revenues}
      {...rest}
    />
  );

}

QuickbooksRevenueDropdownField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default QuickbooksRevenueDropdownField;