import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';
import { useEnvaseTraceGetProviderRequest } from './useEnvaseTraceGetProviderRequest';

const token = localStorage.getItem('token');
const uri = 'Location/' + token + '/';

export function useLocationGetRequest(id) {
  const [response, setResponse] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const locationProviderResponse = useEnvaseTraceGetProviderRequest(response.EnvaseTraceProviderID);

  const fetch = useCallback(() => {
    async function fetchData() {
      const httpService = new HttpService();
      const result = await httpService
        .get(uri + id);
      let location = result.data[0];
      location.Phone = location.Phone.trim();
      location.Fax = location.Fax.trim();
      location.TollFree = location.TollFree.trim();
      setResponse(location);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (
      locationProviderResponse.data &&
      response &&
      response.InternalName
    ) {
      const provider = locationProviderResponse.data;
      response.ProviderName = provider.Name;
      response.LocationCode = provider.LocationCode;
      setResponse(response);
      setIsLoaded(true);
    }
  }, [locationProviderResponse, response]);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id, fetch]);

  return {
    data: response,
    refetch: fetch,
    isLoaded: isLoaded
  };
}