import React, { useState, useEffect } from 'react';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import IconOrder from '../icon/IconOrder';
import { getPaymentMethod, attachPaymentMethod } from './SubscriptionManagementService';

import './SubscriptionManagement.css';

const SubscriptionManagementCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');
  const [isCreditCardFormOpen, setIsCreditCardFormOpen] = useState(false);
  const [isCreditCardOnFile, setIsCreditCardOnFile] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState({});

  useEffect(() => {
    async function getPaymentInformation() {
      const response = await getPaymentMethod();
      if (response && response.brand) {
        setIsCreditCardOnFile(true);
        setPaymentInformation(response);
      } else {
        setIsCreditCardFormOpen(true);
      }
    }
    getPaymentInformation();
  }, []);

  const handleSubmit = async (event) => {
    setErrorMessage('');
    event.preventDefault();
    const result = await stripe.createToken(elements.getElement(CardElement));
    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      publishCreditCard(result.token.id);
    }
  };

  const publishCreditCard = async (cardToken) => {
    await attachPaymentMethod(cardToken);
    window.location.reload();
  };

  const handleCreditCardClick = () => {
    setIsCreditCardFormOpen(true);
  };

  return (
    <form className="form-style" onSubmit={handleSubmit}>
      {isCreditCardOnFile &&
        <Card 
          variant='outlined' 
          className='credit-card mb-2' 
          onClick={handleCreditCardClick}>
          <CardContent>
            <Grid container>
              <Grid item xs={1}>
                <IconOrder></IconOrder>
              </Grid>
              <Grid item xs={2}>
                <strong>{paymentInformation.brand}</strong>
              </Grid>
              <Grid item xs={6}>
                **** **** **** {paymentInformation.last4}
              </Grid>
              <Grid item xs={3} container justifyContent='flex-end'>
                {paymentInformation.expMonth}/{paymentInformation.expYear}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      {isCreditCardFormOpen &&
        <div>
          <div className='credit-card-input'>
            <CardElement />
          </div>
          {isCreditCardOnFile &&
            <Alert variant="outlined" severity="warning" className="mt-2">
              By clicking 'Update Credit Card' you will overwrite your existing payment method on file.
            </Alert>}
          {errorMessage &&
            <Alert variant="outlined" severity="error" className="mt-2">
              {errorMessage}
            </Alert>}
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className="submit-button mt-2"
            id="card-button">
            {!isCreditCardOnFile && <span>Add Credit Card</span>}
            {isCreditCardOnFile && <span>Update Credit Card</span>}
          </Button>
        </div>
      }
    </form>
  );
};

export default SubscriptionManagementCheckoutForm;