import CustomerCreateButton from './CustomerCreateButton';
import CustomerListTable from './CustomerListTable';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';

const CustomerList = () => {
  return (
    <div>
      <CorePageHeader
        title="Customer"
        breadCrumb="Home / ... / Customer List"
        createButton={
          <CustomerCreateButton/>
        }/>
      <Container maxWidth="false" className="mt-4">
        <CustomerListTable />
      </Container>
    </div>
  );

}

export default CustomerList;