import {Route} from 'react-router-dom'
import TruckVisitList from './TruckVisitList'

const TruckVisitRouter = () => {
    return (
        <>
            <Route exact path="/spark/truck-visit" component={TruckVisitList} />
        </>
    );
}

export default TruckVisitRouter;