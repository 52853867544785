import { React, useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CoreTextField from '../core/CoreTextField';
import CoreDateField from '../core/CoreDateField';
import CoreTimeField from '../core/CoreTimeField';

const useStyles = makeStyles(() => ({
  line: { 
    opacity: '0.3'
  }
}));

const OrderEditContentVesselContainerInfo = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    orderFormValidation,
    updateOrderFormData
  } = props;
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => { 
    setHeight(ref.current.clientHeight);
  }, [orderFormData]);

  return (
    <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
      <Grid container md={12} xs={12} alignItems="flex-start">
        <Grid container md={6} xs={6} spacing={2}>
          <Grid item md={12} xs={12}>
            <h4>Vessel Information</h4>
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="VesselName"
              label="Vessel Name"
              value={orderFormData.VesselName}
              onChange={e => updateOrderFormData(e)}
              validationError={orderFormValidation.VesselName}
              maxLength={100}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <CoreTextField
              name="VesselVoyager"
              label="Voyage"
              value={orderFormData.VesselVoyager}
              onChange={e => updateOrderFormData(e)}
              validationError={orderFormValidation.VesselVoyager}
              maxLength={100}
            />
          </Grid>
          {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
            <CoreDateField
              name="VesselDate"
              label="Estimated Arrival Date"
              value={orderFormData.VesselDate}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>}
          {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
            <CoreTimeField
              name="VesselTime"
              label="Estimated Arrival Time"
              value={orderFormData.VesselTime}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>}
          {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
            <CoreDateField
              name="VesselActualDate"
              label="Actual Arrival Date"
              value={orderFormData.VesselActualDate}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>}
          {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
            <CoreTimeField
              name="VesselActualTime"
              label="Actual Arrival Time"
              value={orderFormData.VesselActualTime}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>}
        </Grid>
        <hr className={classes.line} style={{height: height}}></hr>
        <Grid ref={ref} container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <h4>Container Availability</h4>
          </Grid>
          <Grid item md={6} xs={6}>
              <CoreDateField
                name="ContainerReleaseDate"
                label="Release Date"
                value={orderFormData.ContainerReleaseDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTimeField
                name="ContainerReleaseTime"
                label="Release Time"
                value={orderFormData.ContainerReleaseTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="ContainerLastDate"
                label="Last Free Day Date"
                value={orderFormData.ContainerLastDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type !== "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="ContainerLastTime"
                label="Last Free Day Time"
                value={orderFormData.ContainerLastTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === 'Export' && <Grid item md={6} xs={6}>
              <CoreDateField
                name="CutoffDate"
                label="Cut Off Date"
                value={orderFormData.CutoffDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === 'Export' && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="CutoffTime"
                label="Cut Off Time"
                value={orderFormData.CutoffTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === 'Export' && <Grid item md={6} xs={6}>
              <CoreDateField
                name="FirstReceivedDate"
                label="First Received Date"
                value={orderFormData.FirstReceivedDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === 'Export' && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="FirstReceivedTime"
                label="First Received Time"
                value={orderFormData.FirstReceivedTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="ImportExportReportedby"
                label="Reported By"
                value={orderFormData.ImportExportReportedby}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.ImportExportReportedby}
                maxLength={255}
              />
            </Grid>
            <Grid item md={6} xs={6}></Grid>
            {orderFormData.Type === "Import" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="EmptyReadyDate"
                label="Empty Ready Date"
                value={orderFormData.EmptyReadyDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === "Import" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="EmptyReadyTime"
                label="Empty Ready Time"
                value={orderFormData.EmptyReadyTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="LoadReadyDate"
                label="Load Ready Date"
                value={orderFormData.LoadReadyDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.Type === "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="LoadReadyTime"
                label="Load Ready Time"
                value={orderFormData.LoadReadyTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
        </Grid>
      </Grid>
    </Container>
  );

}

export default OrderEditContentVesselContainerInfo;