import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { get, getList, getLocationsToApply } from '../area_tag/AreaTagService';
import { getList as getAreaTagLines } from '../area_tag_line/AreaTagLineService';
import ConfigurationViewAreaTagSectionListTable from './ConfigurationViewAreaTagSectionListTable';
import AreaTagCreateDialog from '../area_tag/AreaTagCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import AreaTagEditDialog from '../area_tag/AreaTagEditDialog';
import AreaTagLineCreateDialog from '../area_tag_line/AreaTagLineCreateDialog';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationAreaTagSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isAreaTagCreateDialogOpen, setIsAreaTagCreateDialogOpen] = useState(false);
  const [isAreaTagEditDialogOpen, setIsAreaTagEditDialogOpen] = useState(false);
  const [areaTags, setAreaTags] = useState({});
  const [currentAreaTagID, setCurrentAreaTagID] = useState(null);
  const [areaTagFormData, setAreaTagFormData] = useState({});
  const [areaTagLines, setAreaTagLines] = useState([]);
  const [isAreaTagLineCreateDialogOpen, setIsAreaTagLineCreateDialogOpen] = useState(false);
  const [locationsToApplyCount, setLocationsToApplyCount] = useState(0);

  useEffect(() => {
    getList()
      .then(function(response) { 
        setAreaTags(response);
        setInProgress(false);
      });
  }, []);

  const getAreaTagList = () => {
    getList()
      .then(function(response) { 
        setAreaTags(response);
      });  
  };

  async function openAreaTagEditModal(areaTagID) {
    setInProgress(true);
    get(areaTagID)
      .then(function(result) {
        setAreaTagFormData(result);
        setIsAreaTagEditDialogOpen(true);
        setInProgress(false);
      });
  };

  const getAreaTagLineList = () => {
    setInProgress(true);
    getAreaTagLines(currentAreaTagID)
      .then(function(response) { 
        setAreaTagLines(response);
        setInProgress(false);
      });  
    getLocationsToApply(currentAreaTagID)
      .then(function(response) {
        setLocationsToApplyCount(response.length);
        setInProgress(false);
      });
  };

  const openAreaTagCreateDialog = () => {
    setIsAreaTagCreateDialogOpen(true);
  };

  const closeAreaTagCreateDialog = (areaTagID) => {
    setCurrentAreaTagID(areaTagID);
    setIsAreaTagCreateDialogOpen(false);
    getAreaTagList();
    if (areaTagID !== null) {
      openAreaTagEditModal(areaTagID);
    }
  };

  const closeAreaTagEditModal = () => {
    setCurrentAreaTagID(null);
    setAreaTagFormData({});
    setAreaTagLines([]);
    setIsAreaTagEditDialogOpen(false);
    getAreaTagList();
  };

  const openAreaTagLineCreateDialog = () => {
    setIsAreaTagLineCreateDialogOpen(true);
  };

  const closeAreaTagLineCreateDialog = () => {
    setIsAreaTagLineCreateDialogOpen(false);
    getAreaTagLineList();
  };
  
  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Area Tags"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel={"Hide Area Tags"}></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"Area Tags are tags attached to location records. They allow multiple locations to be grouped together in a shared geographical area. Locations can have multiple Area Tags associated."}
                <span style={rightFloat}>
                </span>
            </Typography>
            <ConfigurationViewAreaTagSectionListTable 
              areaTags={areaTags}
              getAreaTags={getAreaTagList}
              setAreaTags={setAreaTags}
              openAreaTagCreateDialog={openAreaTagCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
            <span></span>
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Area Tags"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel={"Show Area Tags"}></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <AreaTagCreateDialog
        isOpen={isAreaTagCreateDialogOpen}
        onClose={closeAreaTagCreateDialog}
      />
      <AreaTagEditDialog
        isOpen={isAreaTagEditDialogOpen}
        onClose={closeAreaTagEditModal}
        areaTagFormData={areaTagFormData}
        setAreaTagFormData={setAreaTagFormData}
        areaTagLines={areaTagLines}
        getAreaTagLineList={getAreaTagLineList}
        setAreaTagLines={setAreaTagLines}
        openAreaTagLineCreateDialog={openAreaTagLineCreateDialog}
        inProgress={inProgress}
        setInProgress={setInProgress}
        locationsToApplyCount={locationsToApplyCount}
      />
      {currentAreaTagID !== null && <AreaTagLineCreateDialog
        areaTagID={currentAreaTagID}
        isOpen={isAreaTagLineCreateDialogOpen}
        onClose={closeAreaTagLineCreateDialog}
      />}
    </div>
  );
}

export default ConfigurationAreaTagSection;
