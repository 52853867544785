import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CurrencySvg } from '../assets/svg-icons/currency@1x.svg'

const IconCurrency = () => {
  return (
    <SvgIcon fontSize="small">
      <CurrencySvg />
    </SvgIcon>
  )
}

export default IconCurrency;