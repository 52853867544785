import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Location/' + token + '/';

export function useLocationGetNotesRequest(id) {
  const [response, setResponse] = useState([]);

  const fetch = useCallback(() => {
    async function fetchData() {
      const httpService = new HttpService();
      const result = await httpService
        .get(uri + id + '/GetNotes');
      let notes = result.data;
      setResponse(notes);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id, fetch]);

  return {
    data: response,
    refetch: fetch
  };
}