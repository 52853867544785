import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Country/' + token + '/';

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  const response = result.data;
  for (const country of response) {
    country.Name = country.Name.trim();
  }
  return response;
};

export async function create(countryFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', null, countryFormData);
  return result;
};

export async function deleteCountry(countryID) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + countryID + '/Delete', countryID);
  return result;
}