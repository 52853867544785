import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dropDown: {
    width: "50%",
  },
}));

const ChatConversationCreateDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    handleChange,
    handleClose,
    selectedDriver,
    drivers,
    startConvo,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Start Chat</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the name of the driver you'd like to start a conversation
          with.
        </DialogContentText>
        <FormControl className={classes.dropDown}>
          <Select
            label="name-select"
            value={selectedDriver.PersonnelID ? selectedDriver : ""}
            onChange={handleChange}
            input={<Input />}
          >
            {drivers.map(
              (driver) =>
                driver.ID && (
                  <MenuItem key={driver.ID} value={driver}>
                    {driver.Name}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="grey">
          Cancel
        </Button>
        <Button
          disabled={!selectedDriver ? true : false}
          onClick={startConvo}
          color="primary"
        >
          Chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatConversationCreateDialog;
