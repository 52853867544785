import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import NavigationToolbarAuthorizeTerminalFeesDialog from './NavigationToolbarAuthorizeTerminalFeesDialog';

const NavigationToolbarAuthorizeTerminalFees = () => {

  const [isAuthorizeTerminalFeesOpen, setIsAuthorizeTerminalFeesOpen] = useState(false);

  function addHoldFeesToCart() {
    setIsAuthorizeTerminalFeesOpen(true);
  };

  function onCloseAuthorizeTerminalFeesDialog() {
    setIsAuthorizeTerminalFeesOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={addHoldFeesToCart} >
        <span>Authorize Terminal Fees</span>
      </Button>
      <NavigationToolbarAuthorizeTerminalFeesDialog
        open={isAuthorizeTerminalFeesOpen}
        onClose={onCloseAuthorizeTerminalFeesDialog}
      />
    </div>
  );

};

export default NavigationToolbarAuthorizeTerminalFees;