import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';

const uri = 'EnvaseTrace/';

export function useEnvaseTraceGetProviderRequest(id) {
  const [response, setResponse] = useState([]);

  const fetch = useCallback(() => {
    async function fetchData() {
      const params = {
        envaseTraceProviderID: id
      };
      const httpService = new HttpService();
      const result = await httpService
        .get(uri + 'GetProvider', params);
      const provider = result.response[0];
      setResponse(provider);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id, fetch]);

  return {
    data: response,
    refetch: fetch
  };
}