import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const CoreOutlinedTextField = (props) => {
  const { 
    label, 
    value, 
    icon, 
    autoFocus, 
    maxLength, 
    onLeaveEvent,
    variant,
    ...rest 
  } = props;

  const buildEndAdornment = () => {
    if (icon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled={true}>
            {icon}
          </IconButton>
        </InputAdornment>);
    }
    return <></>;
  };

  return (
    <FormControl fullWidth variant={variant}>
      <TextField
        label={label}
        type="text"
        value={value}
        endAdornment={buildEndAdornment()}
        autoFocus={autoFocus}
        variant={variant}
        inputProps = {{maxLength:maxLength}}      
        {...rest}
      />
    </FormControl>
  ); 

}

CoreOutlinedTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreOutlinedTextField;
