import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ContactsSvg } from '../assets/svg-icons/contacts@1x.svg'

const IconContacts = () => {
  return (
    <SvgIcon fontSize="small">
      <ContactsSvg />
    </SvgIcon>
  )
}

export default IconContacts;