import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreDateField from '../core/CoreDateField';
import PayableChargeTypeField from '../payableChargeType/PayableChargeTypeField';
import AutomaticPayableAutoAddIfField from './AutomaticPayableAutoAddIfField';
import OrderTypeField from '../order/OrderTypeField';
import IntermodalEventTypeField from '../intermodalEvent/IntermodalEventTypeField';

const AutomaticPayableEditDialogContent = (props) => {
  const { 
    isOpen,
    onClose,
    onUpdate,
    autoPayFormData,
    updateAutoPayFormData,
    errorMessage,
    autoPayFormValidation,
    inProgress
  } = props;

  return ( 
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Automatic Payable</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <CoreDateField
                name="DateStart"
                label="Date Start"
                value={autoPayFormData.DateStart}
                onChange={e => updateAutoPayFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateField
                name="DateEnd"
                label="Date End"
                value={autoPayFormData.DateEnd}
                onChange={e => updateAutoPayFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <AutomaticPayableAutoAddIfField
                label="Auto Add-If"
                name="AutoAddIfName"
                value={autoPayFormData.AutoAddIfName}
                nameData="AutoAddIf"
                valueData={autoPayFormData.AutoAddIf}
                isRequired={true}
                onChange={e => updateAutoPayFormData(e)}
                validationError={autoPayFormValidation.PayableChargeTypeID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <OrderTypeField
                label="Shipment Type"
                name="ShipmentType"
                value={autoPayFormData.ShipmentType}
                onChange={e => updateAutoPayFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}></Grid>
            <Grid item md={6} xs={6}>
              <IntermodalEventTypeField
                label="Event Type"
                name="EventType"
                value={autoPayFormData.EventType}
                onChange={e => updateAutoPayFormData(e)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <PayableChargeTypeField
                label="Payable Item Type"   
                value={autoPayFormData.PayableChargeTypeName}
                name="PayableChargeTypeName"
                nameData="PayableChargeTypeID"
                valueData={autoPayFormData.PayableChargeTypeID}
                isRequired={true}
                onChange={e => updateAutoPayFormData(e)}
                validationError={autoPayFormValidation.PayableChargeTypeID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="Units"
                label="Quantity"
                minValue={0}
                isDecimal={true}
                value={autoPayFormData.Units}
                onChange={e => updateAutoPayFormData(e)}
                isRequired={true}
                validationError={autoPayFormValidation.Units}
                disabled={autoPayFormData.IntermodalUnitType !== 0}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="UnitRate"
                label="Rate"
                minValue={0}
                isCurrency={true}
                value={autoPayFormData.UnitRate}
                onChange={e => updateAutoPayFormData(e)}
                isRequired={true}
                validationError={autoPayFormValidation.UnitRate}
                disabled={autoPayFormData.IntermodalUnitRateType !== 0}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};

export default AutomaticPayableEditDialogContent;