import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconLocation from '../icon/IconLocation';

const NavigationDrawerListItemLocation = () => {
  return (
    <NavigationDrawerListItem
      title={'Locations'}
      icon={<IconLocation />}
      uri={'/spark/location'} />
  );

}

export default NavigationDrawerListItemLocation;