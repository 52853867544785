import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import {
  Box,
  Container,
  Paper,
  Typography,
  Divider,
  Grid,
  Fab,
  TextField,
  Avatar
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { ThemeProvider, createMuiTheme  } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: { main: "#00e676" },
  },
});

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: 0,
    boxShadow: "5px 0 10px -4px rgb(206 206 206), -5px 0 10px -4px rgb(206 206 206)"
  },
  chatHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: theme.spacing(10),
  },
  messageArea: {
    display: "flex",
    flexDirection: "column",
  },
  message: {
    display: "flex",
    margin: "10px",
  },
  sent: {
    flexDirection: "row-reverse",
    float: "right",
    "& p": {
      backgroundColor: "#ebfdf4",
      alignSelf: "flex-end",
    },
  },
  received: {
    "& p": {
      backgroundColor: "#f3f0fa",
    }
  },
  paragraph: {
    fontSize: "14px",
    borderRadius: "10px",
    maxWidth: "500px",
    padding: "10px 20px",
    position: "relative",
    textAlign: "left",
  },
  typeMessage: {
    height: "70px",
    backgroundColor: "#f8f6f3",
  },
  typeBox: {
    alignItems: "center",
    height: "100%",
  },
  noMessageSelected: {
    textAlign: "center",
    paddingTop: "50px",
    fontSize: "14px"
  },
  chatForm: {
    display: "flex",
    width: "100%",
    padding: "0 18px",
    "& label": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "& textarea": {
      fontSize: "14px"
    }
  },
  chatBubbleAvatar: {
    float:"left",
    margin: "15px 10px 0 10px",
    height: "24px",
    width: "24px",
    fontSize: "11px",
    lineHeight: "24px"
  },
  name: {
    fontSize: "14px"
  },
  orderNo: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  displayDate: {
    textAlign: "center",
    margin: "10px 0",
  },
  timeStamp: {
    float: "right",
    fontSize: "10px",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

const ChatMessages = (props) => {
  const [height, setHeight] = useState(window.innerHeight);
  const classes = useStyles();
  const {
    messages,
    selectedMessageInfo,
    formData,
    updateFormData,
    onChatFormSubmit,
    messageUpdate,
  } = props;

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const updateHeight = () => {
    setHeight(window.innerHeight);
  }

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.getElementById("btn").click();
    }
  }

  const utcToLocalTimeZone = (date) => {
    date = date.split("+");
    return format(new Date(date[0] + "Z"), "yyyy-MM-dd'T'HH:mm");
  };

  const getTime = (date) => {
    if (!date) return "";
    const localTime = utcToLocalTimeZone(date);
    return format(new Date(localTime), "h:mm a");
  };

  const getDate = (date) => {
    if (!date) return "";
    const localDate = utcToLocalTimeZone(date);
    return format(new Date(localDate), "MMMM dd, yyyy");
  };

  const shouldDisplayDate = (firstDate, secondDate) => {
    if (!secondDate) {
      return true;
    }
    firstDate = parseISO(utcToLocalTimeZone(firstDate));
    secondDate = parseISO(utcToLocalTimeZone(secondDate));

    if (differenceInCalendarDays(firstDate, secondDate) !== 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (messages?.length > 0 || !messages?.errorMessage === "undefined") {
        messageUpdate(selectedMessageInfo.id);
      }
    }, 7000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessageInfo]);

  const memoizedMessages = React.useMemo(() => messages, [messages]);

  useEffect(() => {
    scrollToBottom();

    // Set height of messages pane.
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedMessages]);

  const avatarInitials =
  selectedMessageInfo.id &&
  selectedMessageInfo.name &&
  selectedMessageInfo.name.length > 0 &&
  selectedMessageInfo.name.includes(" ")
    ? selectedMessageInfo.name.split(" ").shift().charAt(0) +
      selectedMessageInfo.name.split(" ").pop().charAt(0)
    : "";

  if (!selectedMessageInfo.id/*messages?.length === 0 || messages?.errorMessage === "undefined"*/) {
    return (
      <Paper variant="outlined"
        square className={classes.chatContainer}
        style={{height: height-64, backgroundColor: '#ffffff'}}
      >
        <Box className={classes.messageArea}>
          <Typography variant="body1" className={classes.noMessageSelected}>
            No message Selected
          </Typography>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper variant="outlined" square className={classes.chatContainer}>
        <Box>
          <Container className={classes.chatHeader}>
            { selectedMessageInfo.name ? (
              <>
                <Avatar aria-label="photo" style={{marginRight: "15px"}}>
                  { avatarInitials }
                </Avatar>
                <div>
                  <Typography className={classes.name}>{selectedMessageInfo.name || ""}</Typography>
                </div>
                
              </>
            ) : ""}
            
          </Container>
          <Divider />
        </Box>
        <Box className={classes.messageArea} style={{height: height-216, overflowX: 'auto'}}>
          {messages &&
            messages.map((message, index) => (
              <div key={message.ID}>
                {shouldDisplayDate(message.DateCreated, messages[index - 1]?.DateCreated) === true ? (
                  <div className={classes.displayDate}>
                    <Typography variant="caption">
                      {getDate(utcToLocalTimeZone(message.DateCreated))}
                    </Typography>
                  </div>
                ) : (
                  <div></div>
                )}
                { selectedMessageInfo.name && message.IsIncoming === true ? (
                  <Avatar aria-label="photo" className={classes.chatBubbleAvatar}>
                    { avatarInitials }
                  </Avatar>
                ) : ""}
                <div
                  className={`${classes.message} ${
                    message.IsIncoming === false
                      ? classes.sent
                      : classes.received
                  }`}
                >
                  <Typography className={classes.paragraph}>
                    {message.Body}
                    <br></br>
                    <span className={classes.timeStamp}>{getTime(message.DateCreated)}</span>
                  </Typography>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
        </Box>
        <Paper elevation={0} square className={classes.typeMessage}>
          <Grid container className={classes.typeBox}>
            <form onSubmit={onChatFormSubmit} className={classes.chatForm}>
              <Grid item xs={11}>
                <TextField
                  className={classes.messageInput}
                  label="Write your message..."
                  placeholder="Enter message and hit send button"
                  value={formData}
                  onChange={(e) => updateFormData(e)}
                  onKeyPress={(e) => handleKeypress(e)}
                  rowsMax={4}
                  multiline
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <ThemeProvider theme={theme}>
                  <Fab id='btn'
                    type="submit"
                    size="small"
                    color="primary"
                    aria-label="send"
                  >
                    <SendIcon />
                  </Fab>
                </ThemeProvider>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Paper>
    );
  }
};

export default ChatMessages;
