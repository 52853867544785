import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import ChatConversationCreateButton from "./ChatConversationCreateButton";
import ChatMessages from "./ChatMessages";
import ChatConservationList from "./ChatConversationList";
import { getConversations, getMessages, sendMessage, markAsRead } from "./ChatService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  chatHeader: {
    position: "relative",
    height: "66px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#fafafa",
    "& .MuiTypography-h6": {
      paddingTop: "18px"
    }
  },
  paper: {
    padding: '20px', //grid padding
    textAlign: 'left',
    margin: '20px 20px 0px 20px'
  },
  unseenGridItem: {
    backgroundColor: 'rgba(0, 230, 118, 0.08)',
    margin: '2px 0',
    padding: '10px',
    "&:hover": {
      backgroundColor: 'rgba(0, 230, 118, 0.12)'
    }
  },
  defaultGridItem: {
    borderBottom: '1px solid #EEEEEE',
    margin: '0px',
    padding: '10px',
    "&:hover": {
      backgroundColor: 'rgb(247, 246, 243)'
    }
  },
  message: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  date: {
    paddingTop: '10px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  iconContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: "8px",
    height: '40px',
    width: '40px',
    textAlign: 'center',
  },
  iconContainerBorder: {
    border: '1px solid #EEEEEE'
  },
  iconCircle: {
    position: 'absolute',
    top: '-3px',
    right: '-3px',
    height: '8px',
    width: '8px',
    borderRadius: '4px',
  },
  greenCircle: {
    backgroundColor: '#00A152',
  },
  greyCircle: {
    backgroundColor: '#EEEEEE'
  },
  statusIcon: {
    paddingTop: '5px',
    '& svg': {
      fontSize: '26px'
    }
  },
  filterGreen: {
    filter: 'invert(50%) sepia(93%) saturate(5839%) hue-rotate(140deg) brightness(101%) contrast(101%)',
  },
  filterGrey: {
    opacity: '.6'
  },
  noResults: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '20px 0 10px 0;',
    flexGrow: 1,
    textAlign: 'center'
  },
  lowercase: {
    textTransform: 'lowercase'
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    padding: '0'
  },
  subtitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingBottom: '10px'
  },
  markAll: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    paddingTop:'15px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '15px',
      marginBottom: '-2px'
    }
  },
  backdrop: {
    zIndex: '1000',
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  chat: {
    height: 'calc(100vh - 100px)'
  }
}));

const ChatList = ({ history }) => {
  const [conversations, setConversations] = useState([]);
  const [newConversation, setNewConversation] = useState({});
  const [messages, setMessages] = useState([]);
  const [selectedMessageInfo, setSelectedMessageInfo] = useState({});
  const [formData, setFormData] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const onChatClicked = async (convo) => {
    await markAsRead(convo.PersonnelID);
    const response = await getMessages(convo.PersonnelID);
    setMessages(response);
    if (newConversation && convo.PersonnelID !== newConversation.PersonnelID) {
      setNewConversation({});
    }
    setSelectedMessageInfo({ id: convo.PersonnelID, name: convo.PersonnelInternalName });
  };

  const updateFormData = (e) => {
    setFormData(e.target.value);
  };

  const onChatFormSubmit = async (e) => {
    e.preventDefault();
    if (selectedMessageInfo.id) {
      const result = await sendMessage(selectedMessageInfo.id, formData);
      if (result.response === "Success") {
        messageUpdate(selectedMessageInfo.id);
        setFormData("");
        setNewConversation({});
      } else {
        setOpenSnack(true);
        setSnackMessage(result.error);
        setNewConversation({});
        setSelectedMessageInfo({});
      }
    }
  };

  const messageUpdate = async (messageId) => {
    const response = await getMessages(messageId);
    setMessages(response);
  };

  const startConversation = async (driver) => {
    var convo = conversations.find(conversation => conversation.PersonnelID === driver.PersonnelID);
    if (convo) {
      await markAsRead(convo.PersonnelID);
      const response = await getMessages(convo.PersonnelID);
      setMessages(response);
      setSelectedMessageInfo({ id: convo.PersonnelID, name: convo.PersonnelInternalName });
      return;
    }
    setNewConversation({
      PersonnelID: driver.PersonnelID,
      PersonnelInternalName: driver.Name,
      DateCreated: new Date().toISOString(),
    });
    setSelectedMessageInfo({ id: driver.PersonnelID, name: driver.Name });
  };

  const closeSnack = () => {
    setOpenSnack(false);
  };

  const doesConversationExist = (conversations) => {
    var convoExist = conversations.find(conversation => conversation.PersonnelInternalName === conversations.PersonnelInternalName);
    if (convoExist) {
      setNewConversation({});
    }
    return convoExist
  }

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const response = await getConversations();
      var convoExist = doesConversationExist(response);
      !convoExist && response.splice(0, 0, newConversation);
      isMounted && setConversations(response);
    };
    const interval = setInterval(() => {
      getData();
    }, 20000);
    getData();

    return () => {
      clearInterval(interval);
      isMounted = false;
    };
  }, [newConversation]);

  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.chat}>
        <Grid item xs={5} lg={3}>
          <Paper elevation={0} square className={classes.chatHeader}>
            <Container maxWidth="xl">
              <Typography variant="h6">
                Chat
              </Typography>
              <ChatConversationCreateButton startConversation={startConversation} />
            </Container>
          </Paper>
          <ChatConservationList
            conversations={conversations}
            onChatClicked={onChatClicked}
            selectedMessageInfo={selectedMessageInfo}
          />
        </Grid>
        <Grid item xs={7} lg={9}>
          <ChatMessages
            messages={messages}
            formData={formData}
            updateFormData={updateFormData}
            onChatFormSubmit={onChatFormSubmit}
            selectedMessageInfo={selectedMessageInfo}
            messageUpdate={messageUpdate}
          />
        </Grid>
        <Hidden xlDown>
          <Grid item lg={3}>
            Panel to be used for Order Details...
          </Grid>
        </Hidden>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnack}
        onClose={closeSnack}
        autoHideDuration={4000}
        message={snackMessage}
      />
    </div>
  );

}

export default ChatList;
