import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import CoreAddButton from '../core/CoreAddButton';
import { deleteCountry } from '../country/CountryService';

const ConfigurationViewCountrySectionList = (props) => {
  const {
    countries,
    getCountries,
    setCountries,
    openCountryCreateDialog
  } = props;

  let columns = [
    {
      field: 'EDICode',
      headerName: 'Country Code',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("EDICode");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>{name}</span>
          </div>
        )
      }
    },
    {
      field: 'Name',
      headerName: 'Country Name',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openCountryCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const countryID = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Country: ${params.getValue("Name")}`}
                deleteID={countryID}
                deleteFunction={deleteCountry}
                getList={getCountries}
              />}
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configCountryAmount")) || 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configCountryAmount", amount);
  };

  const onCountryRowEnter = (params) => {
    for (const item of countries) {
      if (item.ID === params.row.ID) {
        item.ShowControls = true;
      }
    }
    setCountries([ ...countries ]);
  };

  const onCountryRowLeave = (params) => {
    for (const item of countries) {
      if (item.ID === params.row.ID) {
        item.ShowControls = false;
      }
    }
    setCountries([...countries]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        autoHeight={true} 
        rows={countries} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onCountryRowEnter(e)}
        onRowLeave={e => onCountryRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewCountrySectionList.propTypes = {
  countries: PropTypes.array.isRequired,
  getCountries: PropTypes.func.isRequired
};

export default ConfigurationViewCountrySectionList;