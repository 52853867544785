import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import IncomingLoadListTable from './IncomingLoadListTable';

const IncomingLoadList = () => {

  return (
    <div>
      <CorePageHeader
        title="Incoming Loads" />
      <Container maxWidth="false" className="mt-4">
        <IncomingLoadListTable />
      </Container>
    </div>
  );

}

export default IncomingLoadList;