import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import ReceivableChargeTypeListColumnEditButton from '../receivable_charge_type/ReceivableChargeTypeListColumnButton';
import { obsoleteReceivableChargeType } from '../receivable_charge_type/ReceivableChargeTypeService';
import CoreAddButton from '../core/CoreAddButton';

const ConfigurationViewRevenueItemSectionListTable = (props) => {
  const {
    revenueItems,
    getRevenueItems,
    setRevenueItems,
    handleOpenCreate
  } = props;

  let columns = [
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 1.5,
      sortable: false,
    },
    {
      field: 'DescriptionDefault',
      headerName: 'Description',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'UnitsDefaultValue',
      headerName: 'Unit Default',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'UnitRateDefaultValue',
      headerName: 'Unit Rate Default',
      align: 'left',
      flex: 1,
      sortable: false,
    },    
    {
      field: 'FSCType',
      headerName: 'FSC Type',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => { 
        const isFSC = params.getValue("IsFSC") ? "Yes" : "No";   
        return ( 
          <div>
            {isFSC}
          </div>
        )
      }
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={handleOpenCreate}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const receivableChargeTypeID = params.getValue("ReceivableChargeTypeID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <ReceivableChargeTypeListColumnEditButton
                receivableChargeTypeID={receivableChargeTypeID}
                getReceivableChargeTypes={getRevenueItems}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Revenue Item: ${params.getValue("Name")}`}
                deleteID={receivableChargeTypeID}
                deleteFunction={obsoleteReceivableChargeType}
                getList={getRevenueItems}
              />}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configRevenueItemAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configRevenueItemAmount", amount);
  };

  const onFieldRowEnter = (params) => {
    for (const item of revenueItems) {
      if (item.ReceivableChargeTypeID === params.row.ReceivableChargeTypeID) {
        item.ShowControls = true;
      }
    }
    setRevenueItems([...revenueItems]);
  };

  const onFieldRowLeave = (params) => {
    for (const item of revenueItems) {
      if (item.ReceivableChargeTypeID === params.row.ReceivableChargeTypeID) {
        item.ShowControls = false;
      }
    }
    setRevenueItems([...revenueItems]);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ReceivableChargeTypeID}
        autoHeight={true}
        rows={revenueItems}
        columns={columns}
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onFieldRowEnter(e)}
        onRowLeave={e => onFieldRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewRevenueItemSectionListTable.propTypes = {
  revenueItems: PropTypes.array.isRequired,
  getRevenueItems: PropTypes.func.isRequired
};

export default ConfigurationViewRevenueItemSectionListTable;