import React from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

const CoreBooleanField = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    variant, 
    size,
    ...rest
  } = props;

  const booleanList = [
    { Name: "Yes" }, 
    { Name: "No" }
  ];

  const onBooleanSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const bool = booleanList
      .find(item => item.Name === value);
    if (bool) {
      return bool.Name;
    }
    return '';
  }

  return (
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onBooleanSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={booleanList}
      variant={variant}
      disableClearable={false}
      size={size}
      {...rest}
    />
  );

}

CoreBooleanField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default CoreBooleanField;