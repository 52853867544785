import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';

const CoreCreateContentStickyFooterButtons = (props) => {
  const {
    onClose,
    hasPermissionRequiredToEdit,
    changesDetails
  } = props;

  const useStyles = makeStyles((theme) => ({
  stickyControls: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      left: '0',
      zIndex: '2',
      border: 'none',
      borderTop: '1px solid #ededed !important',
      boxShadow: '2px 13px 10px 4px #333333',
    },
    spacer: {
      display: 'inline-block',
      width: '20px'
    },
    btnContainer: {
      textAlignLast: 'right'
    },
    fixedEditText: {
      paddingLeft: '50px'
    },    
    fixedControlsSpacer: {
      height: '60px'
    }
  }));

  const classes = useStyles();

  const saveButton = () => {
    let buttonProps = {
      type: "submit",
      variant: "contained",
      color: "primary"
    }
    let buttonLabel = changesDetails ? "Save Changes" : "Create";
    if(hasPermissionRequiredToEdit) {
      buttonProps = {
        type: "submit",
        variant: "contained",
        color: "primary",
        disabled: true
      }
      buttonLabel = "Permission required to edit";
    }
    return <Button {...buttonProps}>{buttonLabel}</Button>
  }

  return (
    <div>
      <Paper variant="outlined" square className={classes.stickyControls}>
        <Container maxWidth="lg" className={'mt-2 mb-2'}>
          <Grid container className={classes.fixedEditText}>
            <Grid item md={6} xs={6}>
              {changesDetails && <CoreTextDisplayField
                label={"Changes made to:"}
                value={changesDetails}               
              />}
            </Grid>
            <Grid item md={6} xs={6} className={classes.btnContainer}>
              {changesDetails && <Button onClick={onClose} variant="outlined" color="primary">
                Discard Changes
              </Button>}
              {!changesDetails && <Button onClick={onClose} variant="outlined" color="primary">
                Cancel
              </Button>}
              <span className={classes.spacer} />
              {saveButton()}
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <div className={classes.fixedControlsSpacer} />
    </div>    
  );
}

export default CoreCreateContentStickyFooterButtons;