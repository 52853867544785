import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as InternalNameSvg } from '../assets/svg-icons/internal-name@1x.svg';

const IconInternalName = () => {
  return (
    <SvgIcon fontSize="small">
      <InternalNameSvg />
    </SvgIcon>
  )
}
export default IconInternalName;