import React, { useState } from 'react';
import CorePageHeader from '../core/CorePageHeader';
import Container from '@material-ui/core/Container';
import ConfigurationViewBranchSection from './ConfigurationViewBranchSection';
import ConfigurationViewOperationSection from './ConfigurationViewOperationSection';
import ConfigurationViewOrderSettingSection from './ConfigurationViewOrderSettingSection';
import ConfigurationViewShipmentSettingSection from './ConfigurationViewShipmentSettingSection';
import ConfigurationViewDocumentFieldSection from './ConfigurationViewDocumentFieldSection';
import ConfigurationViewEmailTemplateSection from './ConfigurationViewEmailTemplateSection';
import ConfigurationViewDocumentTypeSection from './ConfigurationViewDocumentTypeSection';
import ConfigurationViewRevenueItemSection from './ConfigurationViewRevenueItemSection';
import ConfigurationViewPayableItemSection from './ConfigurationViewPayableItemSection';
// import ConfigurationViewDriverHourlySection from './ConfigurationViewDriverHourlySection';
import ConfigurationCountryTypeSection from './ConfigurationViewCountrySection';
import ConfigurationViewDriverMobileSection from './ConfigurationViewDriverMobileSection';
import ConfigurationAreaTagSection from './ConfigurationViewAreaTagSection';
import ConfigurationViewEnvaseTraceSection from './ConfigurationViewEnvaseTraceSection';
import ConfigurationViewPointToPointTemplatesSection from './ConfigurationViewPointToPointTemplatesSection';
import ConfigurationViewShipperManagementSection from './ConfigurationViewShipperManagementSection';
import ConfigurationViewEquipmentTypeSection from './ConfigurationViewEquipmentTypeSection';

const ConfigurationView = () => {

  const [isBranchOpen, setIsBranchOpen] = useState(true);
  const [isOperationOpen, setIsOperationOpen] = useState(false);
  const [isOrderSettingOpen, setIsOrderSettingOpen] = useState(false);
  const [isShipmentSettingOpen, setIsShipmentSettingOpen] = useState(false);
  const [isDocumentFieldOpen, setIsDocumentFieldOpen] = useState(false);
  const [isEmailTemplateOpen, setIsEmailTemplateOpen] = useState(false);
  const [isDocumentTypeOpen, setIsDocumentTypeOpen] = useState(false);
  const [isRevenueItemOpen, setIsRevenueItemOpen] = useState(false);
  const [isPayableItemOpen, setIsPayableItemOpen] = useState(false);
  // const [isDriverHourlyOpen, setIsDriverHourlyOpen] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isAreaTagOpen, setIsAreaTagOpen] = useState(false);
  const [isEquipmentTypeSectionOpen, setIsEquipmentTypeSectionOpen] = useState(false);
  const [isDriverSectionOpen, setIsDriverSectionOpen] = useState(false);
  const [isEnvaseTraceSectionOpen, setIsEnvaseTraceSectionOpen] = useState(false);
  const [isPointToPointRateTemplateSectionOpen, setIsPointToPointRateTemplateSectionOpen] = useState(false);
  const [isShipperManagementSectionOpen, setIsShipperManagementSectionOpen] = useState(false);

  function toggleIsBranchOpen() {
    if (isBranchOpen) {
      setIsBranchOpen(false);
    } else {
      setIsBranchOpen(true);
    }
  }

  function toggleIsOperationOpen() {
    if (isOperationOpen) {
      setIsOperationOpen(false);
    } else {
      setIsOperationOpen(true);
    }
  };

  function toggleIsOrderSettingOpen() {
    if (isOrderSettingOpen) {
      setIsOrderSettingOpen(false);
    } else {
      setIsOrderSettingOpen(true);
    }
  };

  function toggleIsShipmentSettingOpen() {
    if (isShipmentSettingOpen) {
      setIsShipmentSettingOpen(false);
    } else {
      setIsShipmentSettingOpen(true);
    }
  };
  
  function toggleIsDocumentFieldOpen() {
    if (isDocumentFieldOpen) {
      setIsDocumentFieldOpen(false);
    } else {
      setIsDocumentFieldOpen(true);
    }
  };

  function toggleIsEmailTemplateOpen() {
    if (isEmailTemplateOpen) {
      setIsEmailTemplateOpen(false);
    } else {
      setIsEmailTemplateOpen(true);
    }
  };

  function toggleIsDocumentTypeOpen() {
    if (isDocumentTypeOpen) {
      setIsDocumentTypeOpen(false);
    } else {
      setIsDocumentTypeOpen(true);
    }
  };

  function toggleIsRevenueItemOpen() {
    if (isRevenueItemOpen) {
      setIsRevenueItemOpen(false);
    } else {
      setIsRevenueItemOpen(true);
    }
  };

  function toggleIsPayableItemOpen() {
    if (isPayableItemOpen) {
      setIsPayableItemOpen(false);
    } else {
      setIsPayableItemOpen(true);
    }
  };

  // function toggleIsDriverHourlyOpen() {
  //   if (isDriverHourlyOpen) {
  //     setIsDriverHourlyOpen(false);
  //   } else {
  //     setIsDriverHourlyOpen(true);
  //   }
  // };
  
  function toggleIsCountryOpen() { 
    if (isCountryOpen)  {
      setIsCountryOpen(false);
    } else {
      setIsCountryOpen(true);
    }
  }

  function toggleIsAreaTagOpen() { 
    if (isAreaTagOpen)  {
      setIsAreaTagOpen(false);
    } else {
      setIsAreaTagOpen(true);
    }
  }

  function toggleIsEquipmentTypeSectionOpen() { 
    if (isEquipmentTypeSectionOpen)  {
      setIsEquipmentTypeSectionOpen(false);
    } else {
      setIsEquipmentTypeSectionOpen(true);
    }
  }

  function toggleIsDriverSectionOpen() { 
    if (isDriverSectionOpen)  {
      setIsDriverSectionOpen(false);
    } else {
      setIsDriverSectionOpen(true);
    }
  }

  function toggleIsEnvaseTraceSectionOpen() {
    if (isEnvaseTraceSectionOpen) {
      setIsEnvaseTraceSectionOpen(false);
    } else {
      setIsEnvaseTraceSectionOpen(true);
    }
  }

  function toggleIsPointToPointRateTemplatesSectionOpen() { 
    if (isPointToPointRateTemplateSectionOpen) { 
      setIsPointToPointRateTemplateSectionOpen(false);
    } else { 
      setIsPointToPointRateTemplateSectionOpen(true);
    }
  }
      
  function toggleIsShipperManagementSectionOpen() {
    if (isShipperManagementSectionOpen) {
      setIsShipperManagementSectionOpen(false);
    } else {
      setIsShipperManagementSectionOpen(true);
    }
  }

  return (
    <div>
      <CorePageHeader
        title="Configuration"
      />
      <Container maxWidth="lg" className="mt-2">
        <ConfigurationViewBranchSection
          isOpen={isBranchOpen}
          toggleIsOpen={toggleIsBranchOpen}
        />
        <ConfigurationViewOperationSection
          isOpen={isOperationOpen}
          toggleIsOpen={toggleIsOperationOpen}
        />
        <ConfigurationViewOrderSettingSection
          isOpen={isOrderSettingOpen}
          toggleIsOpen={toggleIsOrderSettingOpen}
        />
        <ConfigurationViewShipmentSettingSection
          isOpen={isShipmentSettingOpen}
          toggleIsOpen={toggleIsShipmentSettingOpen}
        />
        <ConfigurationViewDocumentFieldSection
          isOpen={isDocumentFieldOpen}
          toggleIsOpen={toggleIsDocumentFieldOpen}
        />
        <ConfigurationViewEmailTemplateSection 
          isOpen={isEmailTemplateOpen}
          toggleIsOpen={toggleIsEmailTemplateOpen}
        />
        <ConfigurationViewDocumentTypeSection 
          isOpen={isDocumentTypeOpen}
          toggleIsOpen={toggleIsDocumentTypeOpen}
        />
        <ConfigurationViewRevenueItemSection 
          isOpen={isRevenueItemOpen}
          toggleIsOpen={toggleIsRevenueItemOpen}
        />
        <ConfigurationViewPayableItemSection
          isOpen={isPayableItemOpen}
          toggleIsOpen={toggleIsPayableItemOpen}
        />
        <ConfigurationViewPointToPointTemplatesSection
          isOpen={isPointToPointRateTemplateSectionOpen}
          toggleIsOpen={toggleIsPointToPointRateTemplatesSectionOpen}
        />
        {/* <ConfigurationViewDriverHourlySection
          isOpen={isDriverHourlyOpen}
          toggleIsOpen={toggleIsDriverHourlyOpen}
        /> */}
        <ConfigurationCountryTypeSection
          isOpen={isCountryOpen}
          toggleIsOpen={toggleIsCountryOpen}
        />
        <ConfigurationAreaTagSection
          isOpen={isAreaTagOpen}
          toggleIsOpen={toggleIsAreaTagOpen}
        />
        <ConfigurationViewDriverMobileSection
          isOpen={isDriverSectionOpen}
          toggleIsOpen={toggleIsDriverSectionOpen}
        />
        <ConfigurationViewEquipmentTypeSection
          isOpen={isEquipmentTypeSectionOpen}
          toggleIsOpen={toggleIsEquipmentTypeSectionOpen}
        />
        <ConfigurationViewEnvaseTraceSection
          isOpen={isEnvaseTraceSectionOpen}
          toggleIsOpen={toggleIsEnvaseTraceSectionOpen}
        />
        <ConfigurationViewShipperManagementSection
          isOpen={isShipperManagementSectionOpen}
          toggleIsOpen={toggleIsShipperManagementSectionOpen}
        />
      </Container>
    </div>
  );

}

export default ConfigurationView;