import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from '../area_tag_line/AreaTagLineService';
import AreaTagLineEditDialogContent from '../area_tag_line/AreaTagLineEditDialogContent';

const AreaTagLineEditDialog = (props) => {
  const {
    areaTagLineID,
    isOpen,
    onClose,
    currentFavorite,
  } = props;
  
  const [areaTagLineFormData, setAreaTagLineFormData] = useState({});
  const [areaTagLineFormValidation, setAreaTagLineFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    if (isOpen) {
      get(areaTagLineID)
      .then(function(response) {
        setAreaTagLineFormData(response);
      });    
    }
  }, [isOpen, areaTagLineID]);

  useEffect(() => {
    if (isOpen) {
      setAreaTagLineFormValidation(buildValidation(areaTagLineFormData));
    }
  }, [areaTagLineFormData, isOpen]);

  const updateAreaTagLineFormData = e => {
    const {name, value} = e.target;
    areaTagLineFormData[name] = value
    setAreaTagLineFormData({ ...areaTagLineFormData});
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(areaTagLineFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(areaTagLineFormData);
    if (result.error) {
        setErrorMessage(result.error[0]);
        return;
    }
    onClose();
  };

  return (
    isOpen &&
    <AreaTagLineEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      areaTagLineFormData={areaTagLineFormData}
      areaTagLineFormValidation={areaTagLineFormValidation}
      updateAreaTagLineFormData={updateAreaTagLineFormData}
      currentFavorite={currentFavorite}
    />
  );
}

const buildValidation = (areaTagLineFormData) => {
  const {
    AreaTagID,
    City,
    Province, 
    PostalCode
  } = areaTagLineFormData || {};
  const validation = new Validation();
  return {
    AreaTagID: validation.isNumber(AreaTagID),
    Province: (City !== '' && PostalCode === '') || (City === '' && PostalCode === '') ? validation.isEmpty(Province) : false,
    PostalCode: (City !== '' && Province === '') || (City === '' && Province === '') ? validation.isEmpty(PostalCode) : false,
  };
};

export default AreaTagLineEditDialog;