import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import NoteTextAreaField from '../notes/NoteTextAreaField';
import CoreFavoriteButtonField from '../core/CoreFavoriteButtonField';

const NoteCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    noteFormData,
    onCreate,
    errorMessage,
    noteFormValidation,
    updateNoteFormData
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">New Note</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Subject"
                label="Subject"
                value={noteFormData.Subject}
                onChange={e => updateNoteFormData(e)}
                isRequired={true}
                validationError={noteFormValidation.Subject}
                autoFocus={true}
                maxLength={255}
              />
            </Grid>
            <Grid item md={12} xs={12}>
                <NoteTextAreaField
                  name="Note"
                  value={noteFormData.Note}
                  onChange={e => updateNoteFormData(e)}
                  isRequired={true}
                  validationError={noteFormValidation.Note}
                  maxLength={4000}
                />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CoreFavoriteButtonField
            name="isImportant"
            label="Mark as Important"
            value={noteFormData.isImportant}
            onChange={e => updateNoteFormData(e)}
          />
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NoteCreateDialogContent;