import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CoreWarningDialogContent = (props) => {
  const {
    isOpen,
    onConfirm,
    onClose
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onConfirm}>
        <DialogTitle id="form-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"You currently have unsaved work that will be lost if you proceed. Are you sure you want to close without saving?"} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button type="submit" color="primary">
            Yes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CoreWarningDialogContent;