import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import QuickbooksEarliestTransactionDate from './QuickbooksEarliestTransactionDate';
// import QuickbooksMappingAccount from './QuickbooksMappingAccount';
import QuickbooksMappingRevenue from './QuickbooksMappingRevenue';
import QuickbooksConnect from './QuickbooksConnect';
import CorePageHeader from '../core/CorePageHeader';
import QuickbooksSyncInvoiceList from './QuickbooksSyncInvoiceList';
import { checkLogin } from './QuickbooksService';

const useStyles = makeStyles((theme) => ({
  tabList: {
    listStyle: 'none',
    display: 'block',
    padding: '0',
    margin: '0',
  },
  inactive: {
    display: 'inline-block;',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '40px',
    borderBottom: '2px solid transparent',
    color: 'rgba(34, 34, 34, 0.38)',
    margin: '5px 0 0px 20px',
    cursor: 'pointer'
  },
  active: {
    display: 'inline-block;',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '40px',
    borderBottom: '2px solid #6639BF',
    color: '#6639BF',
    margin: '5px 0 0px 20px',
    cursor: 'pointer'
  },
  qbLogo: {
    margin: '5px 0 0 10px',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  }
}));
const Quickbooks = () => {
  const [tab, setTab] = useState("CONNECT");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTab(tab);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (tab === 'CONNECT') {
      return;
    }
    setTab(newValue);
    checkQBLogin();
  };

  async function checkQBLogin() {
    await checkLogin();
  };

  const classes = useStyles();

  return (
    <div>
      <CorePageHeader
        title="App Details"
      />
      <Container maxWidth="xl" className="mt-4">
        <Paper className="mt-2 munaGrid" elevation={1} square={true} align="left">
          <Grid container spacing={2}>
            <Grid item>
              <div style={{ width: 64, height: 64 }}>
                <img alt="complex" className={classes.qbLogo} src="/images/app_logos/quickbooks.png" />
              </div>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="subtitle1" style={{fontWeight: '500',paddingTop:'20px', paddingLeft: '10px'}}>
                    QuickBooks Online
                  </Typography>
                  {/* <Typography variant="subtitle2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                    Last sync on: 06 May 2021 07:21
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ul className={classes.tabList}>
            <li className={tab === "CONNECT" ? classes.active : classes.inactive} onClick={(e) => handleChange(e, "CONNECT")}>CONNECT</li>
            <li className={tab === "CONFIGURE" ? classes.active : classes.inactive} onClick={(e) => handleChange(e, "CONFIGURE")}>CONFIGURE</li>
            <li className={tab === "SYNC" ? classes.active : classes.inactive} onClick={(e) => handleChange(e, "SYNC")}>SYNC</li>
          </ul>
        </Paper>
        {tab === "CONNECT" &&
          <QuickbooksConnect />
        }
        {tab === "CONFIGURE" &&
          <>
            <QuickbooksEarliestTransactionDate />
            {/* <QuickbooksMappingAccount /> */}
            <QuickbooksMappingRevenue />
          </>
        }
        {tab === "SYNC" &&
          <>
            <QuickbooksSyncInvoiceList />
          </>
        }
      </Container>
    </div>
  );

}

export default Quickbooks;
