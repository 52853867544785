import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const IncomingLoadAcceptConfirmationDialog = (props) => { 
  const { 
    isOpen,
    onClose,
    createdOrder
  } = props;

  const getOrderLink = (intermodalOrder) => { 
    return '/spark/order/view/' + intermodalOrder.ID
  };

  return ( 
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Incoming Load Accepted</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-2 mb-2">
          <Grid item md={12} xs={12}>
            <p>The load has been successfully accepted. To view the load, click the link below: </p>
            <span>
              <a href={getOrderLink(createdOrder)} rel='noreferrer'>
                Order # {createdOrder.OrderNum}
              </a>
            </span>
          </Grid>
        </Grid>
        <DialogActions style={{paddingRight: '0'}}>
          <Button onClick={onClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent >
    </Dialog >
  )

};

export default IncomingLoadAcceptConfirmationDialog;