import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Grid, Paper } from '@material-ui/core';
import CoreNumberField from '../core/CoreNumberField';

const useStyles = makeStyles(() => ({
  paper: {
    width: 300,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}));

const AdrItem = (props) => {
  const {adr, onAmountChange, onCheckboxDeselect} = props;
  const [value, setValue] = useState(adr.amount);
  const classes = useStyles();

  const handleAmountChange = (event) => {
    setValue(event.target.value);
    onAmountChange(adr.id, event.target.value);
  };

  const handleCheckboxDeselect = () => {
    onCheckboxDeselect(adr.id);
  };

  return (
    <Box mt={5}>
      <Grid container justifyContent='center'>
        <Paper square={false} elevation={3} className={classes.paper}>
          <Box>
            <Grid container item>
              <Grid item>
                <Checkbox defaultChecked onChange={handleCheckboxDeselect} style={{paddingTop: 0}}/>
              </Grid>
              <Grid item>
                <CoreNumberField
                  name="Name"
                  label={adr.number}
                  minValue={0}
                  value={value}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => handleAmountChange(e)}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Box>
  );
};

export default AdrItem;