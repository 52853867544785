import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

// https://material-ui-pickers.dev/getting-started/installation

const CoreDateTimeField = (props) => {
  const { name, label, value, onChange } = props;

  const onSelectEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(dispatchEvent);
  };

  const handleDateTimeChange = (date) => {
    if (date) {
      date = date.toLocaleString('en-US');
    }
    onSelectEvent(date);
  };

  return (
    <FormControl fullWidth>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          placeholder=""
          label={label}
          value={value}
          onChange={handleDateTimeChange}
          format="MM/dd/yyyy HH:mm"
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

CoreDateTimeField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreDateTimeField;
