import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import EnvaseTraceConfigureDialogContent from './EnvaseTraceConfigureDialogContent';
import { 
  getCredential as envaseTraceServiceGetCredential,
  updateCredential as envaseTraceServiceUpdateCredential
} from './EnvaseTraceService';

const EnvaseTraceConfigureDialog = (props) => {
  const {
    isOpen,
    onClose,
    providerID
  } = props;

  const [formData, setFormData] = useState({});
  const [formValidation, setFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [areCredentialsLoaded, setAreCredentialsLoaded] = useState(false);

  useEffect(() => {
    async function getCredential() {
      const response = await envaseTraceServiceGetCredential(providerID);
      setFormData(response);
      setAreCredentialsLoaded(true);
    };
    getCredential();
  }, [isOpen, providerID]);

  useEffect(() => {
    setFormValidation(buildValidation(formData));
  }, [formData]);

  const updateFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    formData[name] = value;
    formData[nameData] = valueData;
    setFormData({ ...formData });
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(formValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };
  
  const processUpdateRequest = async () => {
    setInProgress(true);
    setErrorMessage('');
    const result = await envaseTraceServiceUpdateCredential(formData);
    setInProgress(false);
    if (result.errors) {
        setErrorMessage(result.errors.Error);
        return;
    }
    onClose();
  };

  return (
    isOpen && areCredentialsLoaded &&
    <EnvaseTraceConfigureDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      formData={formData}
      formValidation={formValidation}
      updateFormData={updateFormData}
      inProgress={inProgress}
    />
  );

}

const buildValidation = (formData) => {
  const {
    username,
    email,
    apiKey,
    authorization,
    sid,
    password,
    fleet
  } = formData || {};
  const validation = new Validation();
  let usernameValidation = false;
  let emailValidation = false;
  let passwordValidation = false;
  let apiKeyValidation = false;
  let authorizationValidation = false;
  let sidValidation = false;
  let fleetValidation = false;
  if (formData.LoginType === 'User Password') {
    usernameValidation = validation.isEmpty(username);
    usernameValidation = validation.isEmpty(password);
  }
  if (formData.LoginType === 'Email Password') {
    emailValidation = validation.isEmpty(email);
    passwordValidation = validation.isEmpty(password);
  }
  if (formData.LoginType === 'API Key') {
    apiKeyValidation = validation.isEmpty(apiKey);
  }
  if (formData.LoginType === 'Authorization SID') {    
    authorizationValidation = validation.isEmpty(authorization);
    sidValidation = validation.isEmpty(sid);
  }
  if (formData.LoginType === 'Authorization') {
    authorizationValidation = validation.isEmpty(authorization);
  }
  if (formData.LoginType === 'Fleet') {
    fleetValidation = validation.isEmpty(fleet);
  }
  return {
    username: usernameValidation,
    email: emailValidation,
    apiKey: apiKeyValidation,
    password: passwordValidation,
    authorization: authorizationValidation,
    sid: sidValidation,
    fleet: fleetValidation
  };
};

export default EnvaseTraceConfigureDialog;