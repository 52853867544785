import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import IntermodalPaymentDeductibleCreateDialogContent from './IntermodalPaymentDeductibleCreateDialogContent';
import { createDeductible } from '../bill/BillService';
import { get as getPayableChargeType } from '../payableChargeType/PayableChargeTypeService';

const IntermodalPaymentDeductibleCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
    populatedParams
  } = props;

  const [deductibleFormData, setDeductibleFormData] = useState({
    BillDate: null,
    PersonnelID: null,
    PayableChargeTypeID: '',
    Description: '',
    Units: 0,
    UnitRate: 0,
    Subtotal: 0,
    OrderNumber: null,
    LegID: null,
    RelatedReceivableChargeTypeID: null
  });
  const [deductibleFormValidation, setDeductibleFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => { 
    if (isOpen) { 
      setDefaultParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const setDefaultParams = () => { 
    setDeductibleFormData(d => ({...d, BillDate: populatedParams.BillDate, PersonnelID: populatedParams.PersonnelID, OrderNumber: populatedParams.OrderNumber}));
  };

  useEffect(() => {
    if (isOpen) {
      setDeductibleFormValidation(buildValidation(deductibleFormData));
    }
  }, [deductibleFormData, isOpen]);

  const updateDeductibleFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    setDefaultDescription(name, value);
    setRelatedRevenueID(nameData, valueData);
    deductibleFormData[name] = value;
    deductibleFormData[nameData] = valueData;
    setDeductibleFormData({ ...deductibleFormData });
    updateSubtotal();
  };

  const setDefaultDescription = (fieldName, newDescription) => {
      if (fieldName === 'PayableChargeTypeName' && deductibleFormData['PayableChargeTypeName'] !== newDescription) {
        deductibleFormData['Description'] = newDescription;
      }
  };

  const setRelatedRevenueID = async (fieldName, payableChargeTypeID) => { 
    if (fieldName === 'PayableChargeTypeID' && payableChargeTypeID ) { 
      const payableItem = await getPayableChargeType(payableChargeTypeID);
      setDeductibleFormData(d => ({...d, RelatedReceivableChargeTypeID: payableItem.RelatedReceivableChargeTypeID }));
    }
  };

  const updateSubtotal = () => {
    setDeductibleFormData(d => ({...d, Subtotal: (deductibleFormData.Units * deductibleFormData.UnitRate)}));
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(deductibleFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await createDeductible(deductibleFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    onClose(deductibleFormData);
  };

  return (
    isOpen && <>
    <IntermodalPaymentDeductibleCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      deductibleFormData={deductibleFormData}
      deductibleFormValidation={deductibleFormValidation}
      updateDeductibleFormData={updateDeductibleFormData}
      disableOrderNumber={populatedParams.OrderNumber}
      inProgress={inProgress}
      />
    </>
  );
}

const buildValidation = (deductibleFormData) => {
  const {
    BillDate,
    PersonnelID,
    PayableChargeTypeID,
  } = deductibleFormData || {};
  const validation = new Validation();
  return {
    BillDate: validation.isEmpty(BillDate),
    PersonnelID: validation.isNumber(PersonnelID),
    PayableChargeTypeID: validation.isNumber(PayableChargeTypeID)
  };
};

export default IntermodalPaymentDeductibleCreateDialog;