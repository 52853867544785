import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const CoreCopyButton = (props) => {
  const { textToCopy } = props;

  return (
    <>
      {textToCopy ? <Button onClick={() => navigator.clipboard.writeText(textToCopy)} color="primary">COPY</Button> : <></>}
    </>
  );
}

CoreCopyButton.propTypes = {
  textToCopy: PropTypes.string
};

export default CoreCopyButton;