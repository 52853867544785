import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  iconAvatar: props => ({
    backgroundColor: props.backgroundColor,
    width: theme.spacing(4),
    height: theme.spacing(4),
  }),
  iconAvatarSelected : {
    backgroundColor: '#FFFFFF',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  iconSelected: {
    filter: 'invert(13%) sepia(53%) saturate(3961%) hue-rotate(253deg) brightness(92%) contrast(91%);',
  },
  icon: {
    filter: 'invert(1)',
  }
}));

const DispatchListStatusButtonIcon = (props) => {
  const classes = useStyles(props);

  const {
    icon,
    isSelected
  } = props;

  return (
    <Avatar 
      className={clsx({
        [classes.iconAvatar]: !isSelected,
        [classes.iconAvatarSelected]: isSelected
      })}>
      <div className={clsx({
          [classes.icon]: !isSelected,
          [classes.iconSelected]: isSelected
        })}>
        {icon}
      </div>
    </Avatar>
  );
}

DispatchListStatusButtonIcon.protTypoes = {
  icon: PropTypes.element.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default DispatchListStatusButtonIcon;