import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Validation from '../validation';

import CorePageHeader from '../core/CorePageHeader';
import CoreDateField from '../core/CoreDateField';
import CustomerField from '../customer/CustomerField';
import ReportsProfitByCustomerDateFilterTypeField from './ReportsProfitByCustomerDateFilterTypeField';
import LocationField from '../location/LocationField';
import OrderTypeField from '../order/OrderTypeField';

import { getProfitByCustomerDetail } from './ReportService';

const ReportsProfitByCustomerDetail = () => { 
  let history = useHistory();
  const [reportFormData, setReportFormData] = useState({
    CustomerID: null,
    OrderType: "",
    OriginLocationID: null,
    DestinationLocationID: null,
    DateFilterOption: 0,
    StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    EndDate: new Date()
  });
  const [reportFormValidation, setReportFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [reportData, setReportData] = useState([]);
  const [reportedLoaded, setReportedLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  let columns = [
    {
        field: 'Customer',
        headerName: 'Customer Name',
        align: 'left',
        width: 200,
        sortable: false
    },
    {
        field: 'OrderNum',
        headerName: 'Order',
        align: 'left',
        sortable: false
    },
    {
        field: 'BatchInvoiceNum',
        headerName: 'Batch #',
        align: 'left',
        sortable: false
    },
    {
        field: 'InvoiceDate',
        headerName: 'Invoice Date',
        align: 'left',
        width: 150,
        sortable: false
    },
    {
        field: 'ReferenceNumber',
        headerName: 'Ref #',
        align: 'left',
        width: 125,
        sortable: false
    },
    {
        field: 'BookingNum',
        headerName: 'MB/Booking #',
        align: 'left',
        width: 125,
        sortable: false
    },
    {
        field: 'Origin',
        headerName: 'Origin',
        align: 'left',
        width: 200,
        sortable: false
    },
    {
        field: 'Destination',
        headerName: 'Destination',
        align: 'left',
        width: 200,
        sortable: false
    },
    {
        field: 'Type',
        headerName: 'Type',
        align: 'left',
        sortable: false
    },
    {
        field: 'Miles',
        headerName: 'Total Miles',
        align: 'left',
        width: 125,
        sortable: false
    },
    {
        field: 'Hours',
        headerName: 'Total Hours',
        align: 'left',
        width: 125,
        sortable: false
    },
    {
        field: 'Revenue',
        headerName: 'Total Revenue',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => {
            const revenue = params.getValue("Revenue");
            return (
                <span>${revenue.toFixed(2)}</span>
            )
        }
    },
    {
        field: 'RevenuePerHour',
        headerName: 'Revenue/Hr',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const revenuePerHour = params.getValue("RevenuePerHour");
            return ( 
                <span>${revenuePerHour}</span>
            )
        }
    },
    {
        field: 'RevenuePerMile',
        headerName: 'Revenue/Mile',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const revenuePerMile = params.getValue("RevenuePerMile");
            return ( 
                <span>${revenuePerMile}</span>
            )
        }
    },
    {
        field: 'Expense',
        headerName: 'Total Costs',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const expense = params.getValue("Expense");
            return ( 
                <span>${expense.toFixed(2)}</span>
            )
        }
    },
    {
        field: 'ExpensePerMile',
        headerName: 'Cost/Mile',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const expensePerMile = params.getValue("ExpensePerMile");
            return ( 
                <span>${expensePerMile}</span>
            )
        }
        },
    {
        field: 'ExpensePerHour',
        headerName: 'Cost/Hour',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const expensePerHour = params.getValue("ExpensePerHour");
            return ( 
                <span>${expensePerHour}</span>
            )
        }
    },
    {
        field: 'Profit',
        headerName: 'Profit $',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => { 
            const profit = params.getValue("Profit");
            return ( 
                <span>${profit}</span>
            )
        }
    },
    {
        field: 'ProfitMargin',
        headerName: 'Profit Margin',
        align: 'left',
        width: 125,
        sortable: false,
        renderCell: (params) => {
            const profitMargin = params.getValue("ProfitMargin");
            return ( 
                <span>{profitMargin} %</span>
            )
        }
    }
  ];

  useEffect(() => {
    setReportFormValidation(buildValidation(reportFormData));
  }, [reportFormData]);

  const updateReportFormData = e => {
    const {
      name,
      value,
      nameData,
      valueData,
    } = e.target;
    reportFormData[name] = value;
    reportFormData[nameData] = valueData;
    setReportFormData({ ...reportFormData });
  };

  const setCustomerTotalLines = (result) => {  
    var revenueTotal = 0;
    var expenseTotal = 0;
    var hoursTotal = 0;
    var milesTotal = 0;
    var currentRevenue = 0;
    var currentExpenses = 0;
    var currentHours = 0;
    var currentMiles = 0;
    var item = {};
    var data = result.slice();
    for (var i = 0; i < data.length; i++) {
        revenueTotal = revenueTotal + data[i].Revenue;
        expenseTotal = expenseTotal + data[i].Expense;
        hoursTotal = hoursTotal + data[i].Hours;
        milesTotal = milesTotal + data[i].Miles;
        currentRevenue = currentRevenue + data[i].Revenue;
        currentExpenses = currentExpenses + data[i].Expense;
        currentHours = currentHours + data[i].Hours;
        currentMiles = currentMiles + data[i].Miles;

        if (i === data.length - 1) { 
            item = { 
                Customer: 'SUBTOTAL: ',
                OrderNum: '',
                BatchInvoiceNum: '',
                InvoiceDate: '',
                ReferenceNumber: '',
                BookingNum: '',
                Origin: '',
                Destination: '',
                Type: '',
                Miles: currentMiles,
                Hours: currentHours,
                Revenue: currentRevenue,
                RevenuePerHour: currentHours ? (currentRevenue / currentHours).toFixed(2) : 0,
                RevenuePerMile: currentMiles ? (currentRevenue / currentMiles).toFixed(2) : 0,
                Expense: currentExpenses,
                ExpensePerHour: currentHours ? (currentExpenses / currentHours).toFixed(2) : 0,
                ExpensePerMile: currentMiles ? (currentExpenses / currentMiles).toFixed(2) : 0,
                Profit: currentRevenue - currentExpenses,
                ProfitMargin: currentRevenue ? (100 * ((currentRevenue - currentExpenses) / currentRevenue)).toFixed(2) : 0
            }
            data.splice(i + 1, 0, item);
            item = {
                Customer: 'TOTAL: ',
                OrderNum: '',
                BatchInvoiceNum: '',
                InvoiceDate: '',
                ReferenceNumber: '',
                BookingNum: '',
                Origin: '',
                Destination: '',
                Type: '',
                Miles: milesTotal,
                Hours: hoursTotal,
                Revenue: revenueTotal,
                RevenuePerHour: hoursTotal ? (revenueTotal / hoursTotal).toFixed(2) : 0,
                RevenuePerMile: milesTotal ? (revenueTotal / milesTotal).toFixed(2) : 0,
                Expense: expenseTotal,
                ExpensePerHour: hoursTotal ? (expenseTotal / hoursTotal).toFixed(2) : 0,
                ExpensePerMile: milesTotal ? (expenseTotal / milesTotal).toFixed(2) : 0,
                Profit: revenueTotal - expenseTotal,
                ProfitMargin: revenueTotal ? (100 * ((revenueTotal - expenseTotal) / revenueTotal)).toFixed(2) : 0
            };
            data.splice(data.length, 0, item);
            break;
        }

        if (data[i].Customer !== data[i + 1].Customer) { 
            item = { 
                Customer: 'SUBTOTAL: ',
                OrderNum: '',
                BatchInvoiceNum: '',
                InvoiceDate: '',
                ReferenceNumber: '',
                BookingNum: '',
                Origin: '',
                Destination: '',
                Type: '',
                Miles: currentMiles,
                Hours: currentHours,
                Revenue: currentRevenue,
                RevenuePerHour: currentHours ? (currentRevenue / currentHours).toFixed(2) : 0,
                RevenuePerMile: currentMiles ? (currentRevenue / currentMiles).toFixed(2) : 0,
                Expense: currentExpenses,
                ExpensePerHour: currentHours ? (currentExpenses / currentHours).toFixed(2) : 0,
                ExpensePerMile: currentMiles ? (currentExpenses / currentMiles).toFixed(2) : 0,
                Profit: currentRevenue - currentExpenses,
                ProfitMargin: currentRevenue ? (100 * ((currentRevenue - currentExpenses) / currentRevenue)).toFixed(2) : 0
            }
            data.splice(i + 1, 0, item);
            currentRevenue = 0;
            currentExpenses = 0;
            currentHours = 0;
            currentMiles = 0;
            i = i + 1;
        }
    };
    for(var j = 0; j < data.length; j++) { 
        data[j].Index = j;
    }
    setReportData(data);
    setReportedLoaded(true);
    };

  const onConfirm = e => { 
    setInProgress(true);
    setErrorMessage('');
    e.preventDefault();
    if (!new Validation().isValidObject(reportFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processReportRequest();
  };

  const processReportRequest = async () => {
    getProfitByCustomerDetail(reportFormData)
        .then(function(response) {
            setCustomerTotalLines(response);
            setInProgress(false);
        });
  };

  const onClose = () => { 
    setReportFormData({
        CustomerID: null,
        DateFilterOption: 0,
        StartDate: new Date(),
        EndDate: new Date()
    });
    setReportData([]);
    setReportedLoaded(false);
    history.push('/spark/reports');
  };

  function ExportToolBar() { 
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <CorePageHeader
        title="Reports - Profit by Customer Detail"
      />
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Profit by Customer Detail</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{paddingBottom: '10px'}}>
          <Grid item md={3} xs={3}>
            <CustomerField
              label="Customer"
              name="Customer"
              value={reportFormData.Customer}
              nameData="CustomerID"
              valueData={reportFormData.CustomerID}
              onChange={e => updateReportFormData(e)}
              isRequired={false}
              autoFocus={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <OrderTypeField
              name="OrderType"
              label="Order Type"
              value={reportFormData.OrderType}
              onChange={e => updateReportFormData(e)}
              isRequired={false}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <LocationField
              label="Origin"
              name="OriginLocationID"
              value={reportFormData.OriginLocationID}
              onChange={e => updateReportFormData(e)}
              isRequired={false}
            />
          </Grid>
          <Grid item md={3} xs={3}>
          <LocationField
              label="Destination"
              name="DestinationLocationID"
              value={reportFormData.DestinationLocationID}
              onChange={e => updateReportFormData(e)}
              isRequired={false}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <ReportsProfitByCustomerDateFilterTypeField
              label="Date Option"
              name="DateFilterOption"
              value={reportFormData.DateFilterOption}
              onChange={e => updateReportFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <CoreDateField
              label="Start Date"
              name="StartDate"
              value={reportFormData.StartDate}
              onChange={e => updateReportFormData(e)}
              validationError={reportFormValidation.StartDate}
              isRequired={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <CoreDateField
              name="EndDate"
              label="End Date"
              value={reportFormData.EndDate}
              onChange={e => updateReportFormData(e)}
              validationError={reportFormValidation.EndDate}
              isRequired={true}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {errorMessage &&
                <Alert variant="filled" severity="error" className="mt-1 mb-1">
                {errorMessage}
                </Alert>
            }
          </Grid>
          <Grid item md={12} xs={12}>
            <Button onClick={onClose} disabled={inProgress} color="primary">
              Cancel
            </Button>
            <Button onClick={onConfirm} disabled={inProgress} color="primary">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Container>
      {reportedLoaded && <Container style={{'padding': 0}} square={true} maxWidth="lg" className="mt-4" component={Paper}>
        <DataGrid
          getRowId={(row) => row.Index} 
          hideFooter={true}
          autoHeight={true}
          rows={reportData}
          columns={columns}
          pageSize={100}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          components={{
            Toolbar: ExportToolBar,
          }}
        />
      </Container>}
    </div>
  );
}

const buildValidation = (reportFormData) => {
    const {
      StartDate,
      EndDate
    } = reportFormData || {};
    const validation = new Validation();
    return {
      StartDate: validation.isEmpty(StartDate),
      EndDate: validation.isEmpty(EndDate) 
    };
};

export default ReportsProfitByCustomerDetail;