import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconLogo from '../icon/IconLogo';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: '#462887',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  listItem: {
    paddingLeft: '10px',
  },
  listItemIcon: {
    filter: 'invert(1)',
  },
  logoTextWhite: {
    color: 'white',
  },
}));

const NavigationDrawerLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.toolbar}>
      <ListItem className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>
          <IconLogo />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            // WAITING ON UX FONT/LOGO
            <Typography className={classes.logoTextWhite}>
              <span>
                ENVASE
              </span>&nbsp;
              <span style={{fontWeight: 'bold'}}>
                TMS
              </span>
            </Typography>
          } />
      </ListItem>
    </div>
  );
}

export default NavigationDrawerLogo;