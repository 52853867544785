import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

const IntermodalInvoiceListTransmitDialogContent = (props) => {
  const {
    clearInvoices,
    transmitInvoices,
    inProgress,
    isOpen,
    onClose,
    errorMessage,
  } = props;

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        {inProgress && <LinearProgress />}
        <form onSubmit={transmitInvoices}>
          <DialogTitle id="form-dialog-title">Transmit Invoices</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you would like to transmit your selected invoices?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={onClose} 
              color="primary"
              disabled={inProgress}>
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={clearInvoices}
              disabled={inProgress}>
              Clear Invoices without Transmission
            </Button>
            <Button 
              type="submit" 
              color="primary"
              disabled={inProgress}>
              Transmit Invoices
            </Button>
          </DialogActions>      
          {errorMessage && <Grid item md={12} xs={12}>
            <Alert severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </form>
      </Dialog>
    </div>
  );
}

export default IntermodalInvoiceListTransmitDialogContent;