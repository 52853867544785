import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import { get, getLocationsToApply } from './AreaTagService';
import { getList } from '../area_tag_line/AreaTagLineService';

import AreaTagEditDialog from './AreaTagEditDialog';
import AreaTagLineCreateDialog from '../area_tag_line/AreaTagLineCreateDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const AreaTagColumnEditButton = (props) => {
  const {
    areaTagID,
    getAreaTags
  } = props;

  const classes = useStyles();
  const [areaTagFormData, setAreaTagFormData] = useState({});
  const [isAreaTagEditModalOpen, setIsAreaTagEditModalOpen] = useState(false);
  const [areaTagLines, setAreaTagLines] = useState([]);
  const [isAreaTagLineCreateDialogOpen, setIsAreaTagLineCreateDialogOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [locationsToApplyCount, setLocationsToApplyCount] = useState();

  useEffect(() => {
    getList(areaTagID)
      .then(function(response) { 
        setAreaTagLines(response);
        setInProgress(false);
      });
    getLocationsToApply(areaTagID)
      .then(function(response) {
        setLocationsToApplyCount(response.length);
      });
  }, [areaTagID]);

  async function openAreaTagEditModal() {
    const result = await get(areaTagID);
    setAreaTagFormData(result);
    setIsAreaTagEditModalOpen(true);
  };

  const closeAreaTagEditModal = () => {
    setIsAreaTagEditModalOpen(false);
    getAreaTags();
  };

  const getAreaTagLineList = () => {
    setInProgress(true);
    getList(areaTagID)
      .then(function(response) { 
        setAreaTagLines(response);
        setInProgress(false);
      });  
    getLocationsToApply(areaTagID)
      .then(function(response) {
        setLocationsToApplyCount(response.length);
        setInProgress(false);
      });
  };

  const openAreaTagLineCreateDialog = () => {
    setIsAreaTagLineCreateDialogOpen(true);
  };

  const closeAreaTagLineCreateDialog = () => {
    setIsAreaTagLineCreateDialogOpen(false);
    getAreaTagLineList();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openAreaTagEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <AreaTagEditDialog
        isOpen={isAreaTagEditModalOpen}
        onClose={closeAreaTagEditModal}
        areaTagFormData={areaTagFormData}
        setAreaTagFormData={setAreaTagFormData}
        areaTagLines={areaTagLines}
        getAreaTagLineList={getAreaTagLineList}
        setAreaTagLines={setAreaTagLines}
        openAreaTagLineCreateDialog={openAreaTagLineCreateDialog}
        inProgress={inProgress}
        setInProgress={setInProgress}
        locationsToApplyCount={locationsToApplyCount}
      />
      <AreaTagLineCreateDialog
        areaTagID={areaTagID}
        isOpen={isAreaTagLineCreateDialogOpen}
        onClose={closeAreaTagLineCreateDialog}
      />
    </>
  );
}

AreaTagColumnEditButton.propTypes = {
  areaTagID: PropTypes.number.isRequired
};

export default AreaTagColumnEditButton;