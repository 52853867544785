import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CoreNumberField from '../core/CoreNumberField';
import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CustomerField from '../customer/CustomerField';
import OrderTypeField from './OrderTypeField';
import OrderDropHookField from './OrderDropHookField';
import OrderLoadedStatusField from './OrderLoadedStatusField';
import OrderWeightUOMField from './OrderWeightUOMField';

const useStyles = makeStyles(() => ({
  line: {
    opacity: 0.3
  }
}));

const OrderEditContentInfo = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    orderFormValidation,
    updateOrderFormData,
    customerName
  } = props;

  return (
    <div>
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>General Information</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{paddingBottom: '10px'}}>
          <Grid item md={4} xs={4}>
            <CustomerField
              name="Customer"
              label="Bill to Customer"
              value={orderFormData.Customer}
              nameData="CustomerID"
              valueData={orderFormData.CustomerID}
              customerForEdit={customerName}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
              validationError={orderFormValidation.Customer}                
              autoFocus={true}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <OrderTypeField
              name="Type"
              label="Type"
              value={orderFormData.Type}
              validationError={orderFormValidation.Type}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
            />
          </Grid>
          {orderFormData.Type !== "One Way" && <Grid item md={2} xs={2}>
            <OrderDropHookField
              name="LiveDrop"
              label="Live/Drop"
              value={orderFormData.LiveDrop}
              validationError={orderFormValidation.LiveDrop}
              onChange={e => updateOrderFormData(e)}
              isRequired={(orderFormData.Type !== "One Way")}
            />
          </Grid>}
          {orderFormData.Type === "One Way" && <Grid item md={2} xs={2}>
            <OrderLoadedStatusField
              name="LoadedStatus"
              label="Loaded Status"
              value={orderFormData.LoadedStatus}
              validationError={orderFormValidation.LoadedStatus}
              onChange={e => updateOrderFormData(e)}
              isRequired={(orderFormData.Type === "One Way")}
            />
          </Grid>}
          <Grid item md={2} xs={2}>
            <CoreNumberField
              name="Weight"
              label="Container Weight"
              minValue={0}
              value={orderFormData.Weight}
              validationError={orderFormValidation.Weight}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <OrderWeightUOMField
              name="WeightUOM"
              label="Weight UOM"
              value={orderFormData.WeightUOM}
              validationError={orderFormValidation.WeightUOM}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <CoreNumberField
              name="NumPieces"
              label="Number of Pieces"
              minValue={0}
              value={orderFormData.NumPieces}
              validationError={orderFormValidation.NumPieces}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CoreTextField
              name="ReferenceNumber"
              label="Reference Number"
              value={orderFormData.ReferenceNumber}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
        </Grid>
      </Container>
      <Container square={true} maxWidth="lg" component={Paper} style={{padding: 0}}>
        <hr className={classes.line}></hr>
      </Container>
      <Container square={true} maxWidth="lg" component={Paper}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={2}>
            <CoreCheckboxField
              name="IsOverweight"
              label="Overweight"
              value={orderFormData.IsOverweight}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <CoreCheckboxField
              name="IsHazmat"
              label="HAZMAT"
              value={orderFormData.IsHazmat}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <CoreCheckboxField
              name="OnHold"
              label="On Hold"
              value={orderFormData.OnHold}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>  
          {/*
          {orderFormData.OnHold && <Grid item md={3} xs={6}>
            <CoreTextField
              name="HoldReason"
              label="Reason for Hold"
              value={orderFormData.HoldReason}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>}
          */}          
          <Grid item md={2} xs={2}>
            <CoreCheckboxField
              name="IsExpedite"
              label="Expedite"
              value={orderFormData.IsExpedite}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CoreCheckboxField
              name="IsTempManaged"
              label="Temperature Management"
              value={orderFormData.IsTempManaged}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}

export default OrderEditContentInfo;