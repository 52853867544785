import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIntermodalEventTypes } from '../resource/ResourceService';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

const IntermodalEventTypeField = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getEventList() {      
      const result = await getIntermodalEventTypes();
      var val = [{}];
      if (value === 'Chassis Pickup') {
        val = [{ ID: 1, StringID: 'Chassis Pickup', Name: "Chassis Pickup" }];
      }
      else if (value === 'Chassis Split') {
        val = [{ ID: 1, StringID: 'Chassis Split', Name: "Chassis Split" }];
      }
      else if (value === 'Chassis Return') {
        val = [{ ID: 1, StringID: 'Chassis Return', Name: 'Chassis Return' }];
      }
      const fullObject = [...result, ...val];  
      setEventList(fullObject);
      setIsLoading(false);
    }
    getEventList();
  }, [value]);

  const onEventSelected = newValue => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue ? newValue.Name : ''
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {  
    const eventType = eventList.find(eventType => eventType.Name === value);
    if (eventType) {
      return eventType.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onEventSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={eventList}
      {...rest}
    />
  );

}

IntermodalEventTypeField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default IntermodalEventTypeField;