import { Button, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DispatchColumnStatusColor from './DispatchColumnStatusColor';
import { format, differenceInDays } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import EnvaseTraceColumnStatusColor from '../envase_trace/EnvaseTraceColumnStatusColor';

export function columns(params, openRecord, openSlideout) {
  const sched_disp_last = params.ShowScheduled || params.ShowDispatched || params.ShowLastFreeDay
  return [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 1,
      renderCell: (params) => {  
        const name = params.getValue("IntermodalOrderID");
        const status = params.getValue("Status");
        return (
          <div>
            <IconButton title="Open Full Record" onClick={() => openRecord(name)}>
              <OpenInBrowserIcon color='primary'/>
            </IconButton>
            <IconButton title="Open Slideout" onClick={() => openSlideout(name, status)}>
              <MenuOpenIcon color='primary'/>
            </IconButton>
          </div>
        )
      }
    },
    {
      field: 'OrderNum',
      headerName: 'Order',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        const orderNum = params.getValue("OrderNum");
        const customer = params.getValue("Customer");
        const originLocation = params.getValue("OriginLocation");
        const destinationLocation = params.getValue("DestinationLocation");
        const currentSite = params.getValue("CurrentEventLocation");
        const nextSite = params.getValue("NextEventLocation");
        return (
          <div>
            <Tooltip
              placement="right"
              title={
                <div>
                  <Typography color="inherit">Origin: {originLocation}</Typography>
                  <Typography color="inherit">Destination: {destinationLocation}</Typography>
                  <Typography color="inherit">Bill To: {customer}</Typography>
                  <Typography color="inherit">Current Site: {currentSite}</Typography>
                  <Typography color="inherit">Next Site: {nextSite}</Typography>
                </div>
              }
            >
              <span>{orderNum}</span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      field: 'Driver',
      headerName: 'Driver',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        const name = params.getValue("Driver") !== ' '
          ? params.getValue("Driver")
          : <Button
            name="dispatch_list[assign_driver]"
            variant='contained'
            color='primary'
            size='small'
          >
            Assign Driver
          </Button>;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'CurrentEventCompletedDate',
      headerName: 'Dropped Date',
      align: 'left',
      width: 150,
      hide: params.ShowAll || params.ShowOnHold || params.ShowOnVessel || params.ShowReady || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("CurrentEventCompletedDate") ? format(new Date(params.getValue("CurrentEventCompletedDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'NumDays',
      headerName: '# of Days',
      align: 'left',
      width: 150,
      hide: params.ShowAll || params.ShowOnHold || params.ShowOnVessel || params.ShowReady || sched_disp_last,
    },
    {
      field: 'LoadedStatus',
      headerName: 'L/E',
      align: 'left',
      width: 150,
      hide: params.ShowOnVessel || params.ShowOnHold || params.ShowReady,
      renderCell: (params) => {
        const loadedEmptyStatus = params.getValue("LoadedEmptyStatus");
        return (
          <div>{loadedEmptyStatus}</div>
        )
      }
    },
    {
      field: 'CurrentEventLocationSwap',
      headerName: 'Current Event',
      align: 'left',
      width: 200,
      hide: params.ShowAll || params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowLastFreeDay,
    },
    {
      field: 'Status',
      headerName: 'Delivery Status',
      align: 'left',
      width: 150,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || params.ShowReady || params.ShowDispatched,
      renderCell: (params) => {
        const status = params.getValue("Status");
        return (
          <div>
            <span
              className="delivery-status-circle"
              style={{ backgroundColor: DispatchColumnStatusColor(status) }}
            >
            </span>
            <span>{status}</span>
          </div>
        )
      }
    },
    {
      field: 'VesselName',
      headerName: 'Vessel Name',
      align: 'left',
      width: 150,
      hide: params.ShowDropped || params.ShowReady || sched_disp_last,
    },
    {
      field: 'VesselETA',
      headerName: 'Vessel Date',
      align: 'left',
      width: 150,
      hide: params.ShowDropped ||  params.ShowReady || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("VesselETA") ? format(new Date(params.getValue("VesselETA")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'VesselActualDate',
      headerName: 'Vessel Actual Date',
      align: 'left',
      width: 150,
      hide: params.ShowDropped || params.ShowReady || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("VesselActualDate") ? format(new Date(params.getValue("VesselActualDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'Container',
      headerName: 'Container #',
      align: 'left',
      width: 250,
    },
    {
      field: 'ContainerSize',
      headerName: 'Container Size - Type',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        const name = params.getValue("ContainerSize") || false;
        const type = params.getValue("ContainerType") || false;
        return (
          <div>
            {name && <span>
              {name} {"FT"} {type && " - "} {type}
            </span>}
          </div>
        )
      }
    },
    {
      field: 'Line',
      headerName: 'Line',
      align: 'left',
      width: 150,
      hide: params.ShowOnHold || params.ShowOnVessel,
    },
    {
      field: 'MasterBL',
      headerName: 'MBL # / BK #',
      align: 'left',
      width: 200,
      hide: params.ShowDropped,
    },
    {
      field: 'Chassis',
      headerName: 'Chassis #',
      align: 'left',
      width: 250,
      hide: params.ShowOnHold || params.ShowOnVessel || params.ShowReady || sched_disp_last,
    },
    {
      field: 'EmptyLoadReadyDate',
      headerName: 'Empty/Load Ready Date',
      align: 'left',
      width: 225,
      hide: params.ShowAll || params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowScheduled || params.ShowDispatched || params.ShowLastFreeDay,
      renderCell: (params) => {
        const shipmentType = params.getValue("ShipmentType");
        const loadReadyDate = params.getValue("LoadReadyDate") ? format(new Date(params.getValue("LoadReadyDate")), 'MM/dd/yyyy HH:mm') : null;
        const emptyReadyDate = params.getValue("EmptyReadyDate") ? format(new Date(params.getValue("EmptyReadyDate")), 'MM/dd/yyyy HH:mm') : null;
        if (shipmentType === "Export") {
          return (
            <div>
              {loadReadyDate}
            </div>
          )
        }
        if (shipmentType === "Import") {
          return (
            <div>
              {emptyReadyDate}
            </div>
          )
        }
      }
    },
    {
      field: 'ImportExportReportedBy',
      headerName: 'Reported By',
      align: 'left',
      width: 150,
      hide: params.ShowAll || params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowScheduled || params.ShowDispatched || params.ShowLastFreeDay
    },
    {
      field: 'ContainerReleaseDate',
      headerName: 'Release Date',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnVessel || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("ContainerReleaseDate") ? format(new Date(params.getValue("ContainerReleaseDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'FirstReceivedDate',
      headerName: 'First Received Date',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("FirstReceivedDate") ? format(new Date(params.getValue("FirstReceivedDate")), 'MM/dd/yyyy HH:mm') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'LastFreePickupDay',
      headerName: 'Last Free Day Date',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnVessel,
      renderCell: (params) => {
        const name = params.getValue("LastFreePickupDay") ? format(new Date(params.getValue("LastFreePickupDay")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'CutoffDate',
      headerName: 'Cut Off Date',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnVessel || sched_disp_last,
      renderCell: (params) => {
        const name = params.getValue("CutoffDate") ? format(new Date(params.getValue("CutoffDate")), 'MM/dd/yyyy HH:mm') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'DriverItineraryIndex',
      headerName: 'Driver Itinerary #',
      align: 'left',
      width: 200,
      hide: params.ShowOnVessel || params.ShowReady || params.ShowOnHold || params.ShowDropped || params.ShowScheduled || params.ShowLastFreeDay,
    },
    {
      field: 'PlannedDate',
      headerName: 'Planned Date',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || params.ShowReady || params.ShowScheduled || params.ShowLastFreeDay,
      renderCell: (params) => {
        const name = params.getValue("PlannedDate") ? format(new Date(params.getValue("PlannedDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'CurrentEventLocation',
      headerName: 'Current Event',
      align: 'left',
      width: 200,
      hide: params.ShowOnHold || params.ShowReady || params.ShowScheduled || params.ShowDispatched || params.ShowDropped
    },
    {
      field: 'CurrentLocationApptExempt',
      headerName: 'Appointment Exempt',
      align: 'left',
      width: 200,
      hide: params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowScheduled || params.ShowDispatched | params.ShowDropped || params.ShowLastFreeDay,
      renderCell: (params) => {
        const name = params.getValue("CurrentLocationApptExempt") ? 'Yes' : 'No';
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'LastFreeReturnDate',
      headerName: 'Last Free Return Date',
      align: 'left',
      width: 200,
      hide: params.ShowOnHold || params.ShowOnVessel || params.ShowReady || params.ShowScheduled || params.ShowDispatched || params.ShowDropped,
      renderCell: (params) => {
        const name = params.getValue("LastFreeReturnDate") ? format(new Date(params.getValue("LastFreeReturnDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'LastFreeReturnDateSwap',
      headerName: 'Last Free Return Date',
      align: 'left',
      width: 200,
      hide: params.ShowAll || params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowLastFreeDay,
      renderCell: (params) => {
        const name = params.getValue("LastFreeReturnDate") ? format(new Date(params.getValue("LastFreeReturnDate")), 'MM/dd/yyyy') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'CurrentLocationApptExemptSwap',
      headerName: 'Appointment Exempt',
      align: 'left',
      width: 200,
      hide: params.ShowAll || params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowLastFreeDay,
      renderCell: (params) => {
        const name = params.getValue("CurrentLocationApptExemptSwap") ? 'Yes' : 'No';
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'AppointmentDateStart',
      headerName: 'Appointment Start - End Date',
      align: 'left',
      width: 300,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || params.ShowReady,
      renderCell: (params) => {
        const appointmentStart = params.getValue("AppointmentDateStart") ? format(new Date(params.getValue("AppointmentDateStart")), 'MM/dd/yyyy HH:mm') : null;
        const appointmentEnd = params.getValue("AppointmentDateEnd") ? format(new Date(params.getValue("AppointmentDateEnd")), 'MM/dd/yyyy HH:mm') : null;
        const days = differenceInDays(new Date(params.getValue("AppointmentDateEnd")), new Date(params.getValue("AppointmentDateStart")));
        return (
          <div>
            {appointmentStart && appointmentStart} {appointmentStart && "-"} {appointmentEnd && format(new Date(appointmentEnd), 'HH:mm')}
            {days > 0 ? " +" + days : ""}
          </div>
        )
      }
    },
    {
      field: 'Note',
      headerName: 'Notes',
      align: 'left',
      width: 150,
      hide: params.ShowOnHold || params.ShowOnVessel || params.ShowReady || params.ShowLastFreeDay,
    },
    {
      field: 'NextEventLocation',
      headerName: 'Next Event',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || params.ShowReady,
    },
    {
      field: 'NextLocationApptExempt',
      headerName: 'Appointment Exempt',
      align: 'left',
      width: 200,
      hide: params.ShowOnVessel || params.ShowOnHold || params.ShowReady || params.ShowLastFreeDay,
      renderCell: (params) => {
        const name = params.getValue("NextLocationApptExempt") ? 'Yes' : 'No';
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'NextEventAppointmentStart',
      headerName: 'Next Event Appointment Start-End',
      align: 'left',
      width: 300,
      hide: params.ShowDropped || params.ShowOnHold || params.ShowOnVessel || params.ShowReady,
      renderCell: (params) => {
        const appointmentStart = params.getValue("NextEventAppointmentStart") ? format(new Date(params.getValue("NextEventAppointmentStart")), 'MM/dd/yyyy HH:mm') : null;
        const appointmentEnd = params.getValue("NextEventAppointmentEnd") ? format(new Date(params.getValue("NextEventAppointmentEnd")), 'MM/dd/yyyy HH:mm') : null;
        const days = differenceInDays(new Date(params.getValue("NextEventAppointmentEnd")), new Date(params.getValue("NextEventAppointmentStart")));
        return (
          <div>
            {appointmentStart && appointmentStart} {appointmentStart && "-"} {appointmentEnd && format(new Date(appointmentEnd), 'HH:mm')}
            {days > 0 ? " +" + days : ""}
          </div>
        )
      }
    },
    {
      field: 'OriginLocation',
      headerName: 'Origin Location',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || sched_disp_last,
    },
    {
      field: 'DestinationLocation',
      headerName: 'Destination Location',
      align: 'left',
      width: 200,
      hide: params.ShowDropped || sched_disp_last,
    },
    {
      field: 'Customer',
      headerName: 'Customer',
      align: 'left',
      width: 200,
      hide: params.ShowReady || sched_disp_last,
    },
    {
      field: 'ShipmentType',
      headerName: 'Shipment Type',
      align: 'left',
      width: 150,
    },
    {
      field: 'IsBeingTraced',
      headerName: 'Traced',
      align: 'left',
      width: 150,
      renderCell: (params) => { 
        var isBeingTraced = params.getValue("IsBeingTraced");
        const showWarning = params.getValue("ShowEnvaseTraceWarning");
        return (
          <div>
            {isBeingTraced ?
              <div>
                <span
                  className="delivery-status-circle"
                  style={{ backgroundColor: EnvaseTraceColumnStatusColor(showWarning) }}
                >
                </span> Yes
              </div> : "No"
            }
          </div>
        )
      }
    }
  ];

};