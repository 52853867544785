import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as RefreshSvg } from '../assets/svg-icons/refresh@1x.svg'

const IconRefresh = () => {
  return (
    <SvgIcon fontSize="small">
      <RefreshSvg />
    </SvgIcon>
  )
}

export default IconRefresh;
