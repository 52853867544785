import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from './ReceivableChargeTypeService';
import ReceivableChargeTypeEditDialogContent from './ReceivableChargeTypeEditDialogContent';

const ReceivableChargeTypeEditDialog = (props) => {
  const {
    receivableChargeTypeID,
    isOpen,
    onClose,
  } = props;

  const [receivableChargeTypeFormData, setReceivableChargeTypeFormData] = useState({});
  const [receivableChargeTypeFormValidation, setReceivableChargeTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      get(receivableChargeTypeID)
      .then(function(response) {
        setReceivableChargeTypeFormData(response);
      });    
    }
  }, [isOpen, receivableChargeTypeID]);

  useEffect(() => {
    if (isOpen) {
      setReceivableChargeTypeFormValidation(buildValidation(receivableChargeTypeFormData));
    }
  }, [receivableChargeTypeFormData, isOpen]);

  const updateReceivableChargeTypeFormData = e => {
    const { name, value } = e.target;
    receivableChargeTypeFormData[name] = value;
    setReceivableChargeTypeFormData({ ...receivableChargeTypeFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(receivableChargeTypeFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(receivableChargeTypeFormData);
    setInProgress(false);
    if (result.errors.length) {
      setErrorMessage(result.errors.Error);
      return;
    }
    onClose();
  };

  return (
    isOpen &&
    <ReceivableChargeTypeEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      receivableChargeTypeFormData={receivableChargeTypeFormData}
      receivableChargeTypeFormValidation={receivableChargeTypeFormValidation}
      updateReceivableChargeTypeFormData={updateReceivableChargeTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (receivableChargeTypeFormData) => {
  const {
    Name,
    DescriptionDefault,
    UnitsDefaultValue,
    UnitRateDefaultValue,
    IsFSC,

  } = receivableChargeTypeFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
    DescriptionDefault: validation.isEmpty(DescriptionDefault),
    UnitsDefaultValue: validation.isNumber(UnitsDefaultValue),
    UnitRateDefaultValue: validation.isNumber(UnitRateDefaultValue),
    IsFSC: validation.isNumber(IsFSC),
  };
};

export default ReceivableChargeTypeEditDialog;