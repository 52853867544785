import React from 'react';
import Grid from '@material-ui/core/Grid';
import DashboardDispatchItem from './DashboardDispatchItem';
import DashboardDispatchItemOnly from './DashboardDispatchItemOnly';
import DashboardDispatchViewFilterRow from './DashboardDispatchViewFilterRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const DashboardDispatchView = (props) => {
  const {
    filterData,
    filterDataAsParams,
    updateFilterData,
    resetFilterRow,
  } = props;
  const classes = useStyles();

  return (
    <>      
      <Grid container spacing={3}>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardDispatchViewFilterRow
              filterData={filterData}
              updateFilterData={updateFilterData}
              resetFilterRow={resetFilterRow}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={3} sm={3} md={3}>
            <Grid container item xs={12}>
              <Grid item xs={6} sm={6} md={6} className={classes.paddingRight}>
                <DashboardDispatchItemOnly summaryType="ON_VESSEL" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.paddingLeft}>              
                <DashboardDispatchItemOnly summaryType="ON_HOLD" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardDispatchItem summaryType="READY" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>          
            <DashboardDispatchItem summaryType="EMPTIES_IN" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>          
            <DashboardDispatchItem summaryType="EMPTIES_OUT" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>            
          <Grid item xs={3} sm={3} md={3}>          
            <DashboardDispatchItem summaryType="LOADS_OUT" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardDispatchItem summaryType="LOADS_IN" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>          
            <DashboardDispatchItem summaryType="DELIVERIES" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <Grid container item xs={12}>
              <Grid item xs={6} sm={6} md={6} className={classes.paddingRight}>
                <DashboardDispatchItemOnly summaryType="DEMURRAGE" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.paddingLeft}>              
                <DashboardDispatchItemOnly summaryType="PER_DIEM" filterDataAsParams={filterDataAsParams} filterData={filterData}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>          
      </Grid>      
    </>
  );

}

export default DashboardDispatchView;