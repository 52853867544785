import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import PayableItemEditDialog from './PayableItemEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const ConfigurationPayableItemColumnEditButton = (props) => {
  const {
    payableItemID,
    getPayableItemFields
  } = props;

  const classes = useStyles();
  const [isConfigurationPayableItemEditModalOpen, setIsConfigurationPaybleItemEditModalOpen] = useState(false);

  const openConfigurationPayableItemEditModal = () => {
    setIsConfigurationPaybleItemEditModalOpen(true);
  };

  const closeConfigurationPayableItemEditModal = () => {
    setIsConfigurationPaybleItemEditModalOpen(false);
    getPayableItemFields();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openConfigurationPayableItemEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <PayableItemEditDialog
        payableItemID={payableItemID}
        isOpen={isConfigurationPayableItemEditModalOpen}
        onClose={closeConfigurationPayableItemEditModal}
      />
    </>
  );
}

ConfigurationPayableItemColumnEditButton.propTypes = {
  payableItemID: PropTypes.number.isRequired
};

export default ConfigurationPayableItemColumnEditButton;