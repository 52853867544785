import { useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';
import FeePayTransactionHistoryListTable from './FeePayTransactionHistoryListTable';

const FeePayTransactionHistoryList = () => {

  return (
    <div>
      <CorePageHeader
        title="Transaction History"
        breadCrumb="Home / ... / Transaction History"
      />
      <Container maxWidth="false" className="mt-4">
        <FeePayTransactionHistoryListTable />
      </Container>
    </div>
  );

}

export default FeePayTransactionHistoryList;