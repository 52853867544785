import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import CoreDataGridList from '../core/CoreDataGridList';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import { downloadFile } from '../globalFunction';
import { getList } from './LocationService';
import { exportList } from './LocationService';
import LocationViewSummary from './LocationViewSummary';
import Validation from '../validation';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';

const LocationListTable = () => {
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("locationListAmount")) || 10,
    count: parseInt(localStorage.getItem("locationListAmount")) || 10,
    showObsolete: false,
    isASC: true,
    sortBy: 'InternalName',
    globalSearch: '',
    filters: []
  });
  let history = useHistory();

  const validation = new Validation();
  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(true);
  const [locations, setLocations] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
  });
  const [companyFeature, setCompanyFeature] = useState([]);

  const updateFilterData = (e) => {
    const { name, value } = e.target;
    filterData[name] = value;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const closeSummary = () => {
    setIsSummaryOpen(false);
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => {  
        const name = params.getValue("ID");
        return (
          <div>
            <IconButton title="Open Full Record" onClick={() => openRecord(name)}>
              <OpenInBrowserIcon color='primary'/>
            </IconButton>
            <IconButton title="Open Slideout" onClick={() => openSlideout(name)}>
              <MenuOpenIcon color='primary'/>
            </IconButton>
          </div>
        )
      }
    },
    {
      field: "LocationTemplateID",
      hide: true,
    },
    {
      field: "InternalName",
      headerName: "Location Name",
      align: 'left',
      flex: 2,
    },
    {
      field: 'District',
      headerName: 'District',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: 'County',
      headerName: 'Barangay',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: "City",
      headerName: "City & State",
      align: 'left',
      flex: 1,
    },    
    {
      field: "Phone",
      headerName: "Phone",
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <CoreIntlPhoneOutput value={params.getValue("Phone")} />
        )
      }
    },
    {
      field: "Email",
      headerName: "Email",
      align: 'left',
      flex: 1,
    },
    {
      field: "Contact",
      headerName: "Contact Name",
      align: 'left',
      flex: 1,
    }
  ];

  useEffect(() => {
    async function getInitLocationList() {
      const result = await getList({
        page: 1,
        amount: parseInt(localStorage.getItem("locationListAmount")) || 10,
        count: parseInt(localStorage.getItem("locationListAmount")) || 10,
        showObsolete: false,
        isASC: true,
        sortBy: 'InternalName',
        globalSearch: '',
        filters: []
      });
      if (result.length > 0) {
        setParams(c => ({ ...c, count: result[0].TotalRows }));
      } else {
        setParams(c => ({ ...c, count: 0 }));
      }
      setLocations(result);
      setInProgress(false);
    }
    getCompanyFeature()
        .then(function(response){
          setCompanyFeature(response);
        });
    getInitLocationList();
  }, []);

  function changePage(page) {
    params.page = page + 1;
    getLocationList();
    setParams(params);
  };

  function sort(sortLocation) {
    params.page = 1;
    params.sortBy = sortLocation.field;
    if (sortLocation.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getLocationList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    getLocationList();
    setParams(params);
    localStorage.setItem("locationListAmount", amount);
  };

  // function pushParamFilter(filterName, value, operator) {
  //   params.filters.push({
  //     ColumnName: filterName,
  //     Condition: 2,
  //     ConditionName: operator,
  //     Value: value,
  //   });
  // }

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      // pushParamFilter("Search", filterData.Search, 'Contains');
      params.globalSearch = filterData.Search;
    } else {
      params.globalSearch = '';
    }
    params.page = 1;
    setParams(params);
    getLocationList();
  };

  const getLocationList = async () => {
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setParams(c => ({ ...c, count: result[0].TotalRows }));
    } else {
      setParams(c => ({ ...c, count: 0 }));
    }
    setLocations(result);
    setInProgress(false);
  };

  const exportLocationList = async () => {
    const result = await exportList(params);
    downloadFile(result, "location-export.csv");
  };

  const openRecord = (id) => {
    history.push('/spark/location/view/' + id);
  };

  const openSlideout = (id) => {
    setCurrentRowClicked(id);
    setIsSummaryOpen(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={locations}
          params={params}
          exportList={exportLocationList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='InternalName'
          filterData={filterData}
          updateFilterData={updateFilterData}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
      <LocationViewSummary
        locationTemplateID={currentRowClicked}
        isOpen={isSummaryOpen}
        onClose={closeSummary}
      />
    </div>
  )
};

export default LocationListTable;