import HttpService from '../http-service';

const uri = 'Resource/';

export async function getDriverTypes() {
    const httpService = new HttpService();
    const result = await httpService
      .get(uri + 'GetIntermodalPersonnelDriverType');
    const response = result;
    return response;
};

export async function getDriverTypeName(driverType) {
  const params = { 
    id: driverType
  }
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'getPersonnelDriverTypeName', params);
  const response = result;
  return response;
};

export async function getIntermodalEventTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalEventTypes');
  const response = result;
  return response;
};

export async function getIntermodalLegStatuses() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalLegStatuses');
  const response = result;
  return response;
};

export async function getIntermodalOrderTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalOrderTypes');
  const response = result;
  return response;
};

export async function getDocumentTypeCategories() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDocumentTypeCategories');
  const response = result;
  return response;
};

export async function getIntermodalOrderDropHook() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalOrderDropHook');
  const response = result;
  return response;
};

export async function getIntermodalOrderLoadedStatus() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalOrderLoadedStatus');
  const response = result;
  return response;
};

export async function getIntermodalWeightUOM() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalWeightUOM');
  const response = result;
  return response;
};

export async function getEmailTemplateDocTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalEmailTemplateDocTypes');
  const response = result;
  return response;
};

export async function getBatchInvoiceMethods() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetBatchInvoiceMethods');
  const response = result;
  return response;
};

export async function getSmartTags(id) {  
  const params = { 
    ID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetSmartTags?', params);
  const response = result;
  return response;
};

export async function getIntermodalUnitTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalUnitTypes');
    const response = result;
    return response;
  };
    
export async function getAutoPayRules() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAutoPayRules');
  const response = result;
  return response;
};

export async function getIntermodalUnitRateTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalUnitRateTypes');
    const response = result;
    return response;
  };

export async function getAutoAddIfTypes() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAutoAddIfIntermodalPersonnel');
  const response = result;
  return response;
};

export async function getAutoAddIfTypeName(id) { 
  var params = { 
    ID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAutoAddIfIntermodalPersonnelName', params);
  return result;
};

export async function getLocationTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLocationTypes');
  const response = result;
  return response;
};

export async function getDocumentFieldNames() { 
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetIntermodalDocumentFieldNames');
  return result;
};

export async function getEquipmentTypesForContainer() { 
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetEquipmentTypesForContainer');
  return result;
};

export async function getEquipmentTypesForChassis() { 
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetEquipmentTypesForChassis');
  return result;
};

export async function getIntermodalOrderGateTransactionTypes() { 
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetIntermodalOrderGateTransactionTypes');
  const response = result;
  return response;
};

export async function getIntermodalOrderAppointmentLoadedStatusTypes() { 
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetIntermodalOrderAppointmentLoadedStatusTypes');
  const response = result;
  return response;
};