import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import EquipmentTypeEditDialog from './EquipmentTypeEditDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const EquipmentTypeListColumnEditButton = (props) => {
  const {
    equipmentTypeID,
    getEquipmentTypes
  } = props;

  const classes = useStyles();
  const [isEquipmentTypeEditModalOpen, setIsEquipmentTypeEditModalOpen] = useState(false);

  const openEquipmentTypeEditModal = () => {
    setIsEquipmentTypeEditModalOpen(true);
  };

  const closeEquipmentTypeEditModal = () => {
    setIsEquipmentTypeEditModalOpen(false);
    getEquipmentTypes();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openEquipmentTypeEditModal}>
        <EditIcon />&nbsp;
        <span>EDIT</span>
      </IconButton>
      <EquipmentTypeEditDialog
        equipmentTypeID={equipmentTypeID}
        isOpen={isEquipmentTypeEditModalOpen}
        onClose={closeEquipmentTypeEditModal}
      />
    </>
  );
}

EquipmentTypeListColumnEditButton.propTypes = {
  equipmentTypeID: PropTypes.number.isRequired
};

export default EquipmentTypeListColumnEditButton;