import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import CoreTextField from '../core/CoreTextField';
import CoreCurrencyField from '../core/CoreCurrencyField';
import CoreDateField from '../core/CoreDateField';
import CorePhoneField from '../core/CorePhoneField';
import CountryField from '../country/CountryField';

const PersonnelEditContentEmployeeInfo = (props) => {
  const {
    PersonnelFormData,
    PersonnelFormValidation,
    updatePersonnelFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Employee Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CoreTextField
            name="Title"
            label="Title"
            value={PersonnelFormData.Title}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreDateField
            name="StartDate"
            label="Start Date"
            value={PersonnelFormData.StartDate}
            onChange={e => updatePersonnelFormData(e)}
            validationError={PersonnelFormValidation.StartDate}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreDateField
            name="EndDate"
            label="End Date"
            value={PersonnelFormData.EndDate}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreCurrencyField
            label="Preferred Currency"
            value={PersonnelFormData.CanFunds}
            name="CanFunds"
            isRequired={true}
            validationError={PersonnelFormValidation.CanFunds}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CountryField
            label="Citizenship Country"
            value={PersonnelFormData.Nationality}
            name="Nationality"
            isRequired={false}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <CoreTextField
            name="HealthNum"
            label="Health Insurance #"
            value={PersonnelFormData.HealthNum}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CoreTextField
            name="SIN"
            label="SSN/ SIN"
            value={PersonnelFormData.SIN}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CorePhoneField
            name="EmergencyNumber"
            label="Emergency Contact Number"
            value={PersonnelFormData.EmergencyNumber}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3} className="mb-2">
          <CoreTextField
            name="EmergencyContact"
            label="Emergency Contact Name"
            value={PersonnelFormData.EmergencyContact}
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PersonnelEditContentEmployeeInfo;