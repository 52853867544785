import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Button, Grid, Dialog, 
    DialogActions, DialogContent, 
    DialogTitle, DialogContentText
  } from "@material-ui/core";

import { create } from '../notes/NotesService';
import CoreTextField from '../core/CoreTextField';
import { cancel } from '../order/OrderService';

const OrderCancelDialog = (props) => {
  const {
    open,
    order,
    onClose,
    getOrder,
    getNotes
  } = props;

  const [orderCancelNoteFormData, setOrderCancelNoteFormData] = useState({
    NoteType: 31,
    OriginID: 0,
    CreatedByID: 0,
    DateCreated: new Date(),
    Subject: 'Reason for Cancellation',
    Note: '',
    OrderAlert: false,
    QuoteAlert: false,
    DispatchAlert: false,
    Obsolete: false,
    BinaryFileID: null,
    SavedAsPathOnFS: null,
    FilenameOnFS: "",
    PathOnFS: "",   
    isImportant: false
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => { 
    if (open) {
        setOrderCancelNoteFormData(o => ({ ...o, OriginID: order.ID }));
    }
  }, [open, order.ID]);

  const updateOrderCancelNoteFormData = e => {
      const {name, value} = e.target;
      orderCancelNoteFormData[name] = value;
      setOrderCancelNoteFormData({ ...orderCancelNoteFormData});
  };

  const createCancelNote = async () => { 
    if (orderCancelNoteFormData.Note) {      
        const result = await create(orderCancelNoteFormData);
        if (result.error) {
            setErrorMessage(result.error);
            return;
        }
    }
    cancelOrder();
    onClose();
  };

  const cancelOrder = async () => {
    cancel(order.ID)
      .then(function(response) { 
        getOrder();
        getNotes();
      });
  };

  const onOrderCancel = e => {
    e.preventDefault();
    setErrorMessage('');
    createCancelNote();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Cancel Order {order.OrderNum}</DialogTitle>
      <div>
        <DialogContent>
          <Grid container item xs spacing={12} className="mt-2 mb-2">
            <Grid item xs={12}>
              <DialogContentText>
                This will add a note to the order. If you don't add a reason, you won't be able to add a note to a 
                cancelled order later.
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <CoreTextField
                name="Note"
                label="Reason for Cancellation"
                value={orderCancelNoteFormData.Note}
                onChange={e => updateOrderCancelNoteFormData(e)}
                isRequired={false}
                autoFocus={true}
                maxLength={255}    
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
          <DialogActions>
            <Button onClick={onClose} className="grey">
              Close
            </Button>
            <Button onClick={onOrderCancel} className="grey">
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default OrderCancelDialog;