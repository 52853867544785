import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import EnvaseTraceListColumnEditButton from '../envase_trace/EnvaseTraceListColumnEditButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const ConfigurationViewEnvaseTraceSectionListTable = (props) => {
  const {
    providersList,
    getProvidersList
  } = props;

  let columns = [
    {
      field: 'Name',
      headerName: 'Provider',
      align: 'left',
      flex: 1,
      sortable: false,    
    },
    {
      field: 'Type',
      headerName: 'Type',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'LoginType',
      headerName: 'Status',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const loginType = params.row.LoginType;
        const isEnabled = params.row.IsEnabled;
        const showEnvaseManaged =
          loginType === 'Not Required' || loginType === 'Envase Managed';
        return (
          <div>
            {showEnvaseManaged && 
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}>
                <img height="20" src='/icons/favicon-32x32.png' alt="Company Logo" />
                &nbsp;Free - Included by Envase Technologies
              </div>
            }
            {!showEnvaseManaged && 
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                {isEnabled &&
                  <CheckIcon></CheckIcon>
                }
                {!isEnabled && <CloseIcon></CloseIcon>}
                &nbsp;
                {isEnabled && 'Provider enabled'}
                {!isEnabled && 'Provider is not configured'}
              </div>
            }
          </div>
        )
      }
    },
    {
      field: ' ',
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const id = params.row.ProviderID;
        const loginType = params.row.LoginType;
        const isEnabled = params.row.IsEnabled;
        const isEnvaseManaged =
          loginType === 'Not Required' || loginType === 'Envase Managed';
        // const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {!isEnvaseManaged && isEnabled &&
              <EnvaseTraceListColumnEditButton
                providerID={id}
                getProvidersList={getProvidersList}
                buttonText={'EDIT'}
            />}
            {!isEnvaseManaged && !isEnabled &&
              <EnvaseTraceListColumnEditButton
                providerID={id}
                getProvidersList={getProvidersList}
                buttonText={'CONFIGURE'}
              />}
            {/* {!isEnvaseManaged && isEnabled && 
              <CoreListColumnDeleteButton
                reference={`Provider: ${providerName}`}
                deleteID={id}
                deleteFunction={envaseServiceDeleteCredential}
                getList={getProvidersList}
              />
            } */}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: 7
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configEmailTemplateAmount", amount);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ProviderID} 
        autoHeight={true} 
        rows={providersList} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}                
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </Container>
  );
}

ConfigurationViewEnvaseTraceSectionListTable.propTypes = {
  providersList: PropTypes.array.isRequired,
  getProvidersList: PropTypes.func.isRequired
};

export default ConfigurationViewEnvaseTraceSectionListTable;