import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import EventNoteIcon from '@mui/icons-material/EventNote';

const NavigationDrawerListItemMyAppointments = () => {
  return (
    <NavigationDrawerListItem
      title={'Appointments'}
      icon={<EventNoteIcon />}
      uri={'/spark/my-appointment'} />
  );

}

export default NavigationDrawerListItemMyAppointments;