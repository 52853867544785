import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CoreDateField from '../core/CoreDateField';
import { getEarliestTransactionDate, setEarliestTransactionDate } from './QuickbooksService';
import { formatDateTime } from '../globalFunction';

const QuickbooksEarliestTransactionDate = () => {
  const [dateData, setDateData] = useState({
    EarliestTransaction: null,
  });

  useEffect(() => {
    async function getEarliestDate() { 
      const result = await getEarliestTransactionDate();
      if (result) {
        setDateData(result);
      }
    }
    getEarliestDate();
  }, []);

  const updateDate = e => {
    const { name, value } = e.target;
    dateData[name] = value;
    setDateData({ ...dateData });
    onUpdate();
  };

  const onUpdate = () => {
    dateData.EarliestTransaction = formatDateTime(dateData.EarliestTransaction, dateData.EarliestTransaction);
    processUpdateRequest();
  };
  
  const processUpdateRequest = async () => {  
    const result = await setEarliestTransactionDate(dateData.EarliestTransaction);
    if (result.errors) {
      console.log(result.errors.Error);
      return;
    }
  };

  return (
    <>
      <Paper className="mt-2 munaGrid" elevation={1} square={true} align="left" style={{padding:'20px 15px'}}>
        <Typography variant="subtitle1" style={{paddingBottom:"20px"}}>
          Earliest Transaction Date
        </Typography>
        <div style={{display:"inline-block", maxWidth:"425px"}}>
          <CoreDateField
            name="EarliestTransaction"
            label="Start Date"
            value={dateData.EarliestTransaction}
            onChange={e => updateDate(e)}
          />
          <Typography variant="caption" style={{color: "rgba(0, 0, 0, 0.54)"}}>
            From what Date would you like to send across information to QuickBooks?
          </Typography>
        </div>
      </Paper>
    </>
  );
}

export default QuickbooksEarliestTransactionDate;
