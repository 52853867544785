import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create as locationCreate } from './LocationService';
import LocationCreateDialogContent from './LocationCreateDialogContent';

const LocationCreateDialog = (props) => {

  const {
    isOpen,
    onClose,
    branch,
    persistedLocationName
  } = props;

  const initialData = {
    internalName: '',
    companyName: '',
    address: '',
    city: '',
    postalCode: '',
    province: '**',
    country: 'USA'
  }

  const [locationFormData, setLocationFormData] = useState(initialData);
  const [locationFormValidation, setLocationFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setLocationFormValidation(buildValidation(locationFormData));
    }
  }, [locationFormData, isOpen]);

  useEffect(() => { 
    setLocationFormData(l => ({ ...l, internalName: persistedLocationName || '' }));
  }, [isOpen, persistedLocationName]);

  const updateLocationFormData = e => {
    const {name, value} = e.target;
    locationFormData[name] = value;
    setLocationFormData({ ...locationFormData });
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(locationFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await locationCreate(locationFormData);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    resetForm(parseInt(result.response), locationFormData.internalName);
  };

  const resetForm = (response, locationName) => {
    setLocationFormData(initialData);
    if (response) {
      onClose(response, locationName);
    } else {
      onClose();
    }
  };

  return (
    isOpen &&
    <LocationCreateDialogContent
      isOpen={isOpen}
      resetForm={resetForm}
      onCreate={onCreate}
      errorMessage={errorMessage}
      locationFormData={locationFormData}
      locationFormValidation={locationFormValidation}
      branch={branch}
      updateLocationFormData={updateLocationFormData}/>
  );
}

const buildValidation = (locationFormData) => {
  const { 
    internalName,
    country,
    province
  } = locationFormData || {};
  const validation = new Validation();
  return {
    internalName: validation.isEmpty(internalName),
    country: validation.isEmpty(country),
    province: validation.isEmpty(province)
  };
};

export default LocationCreateDialog;