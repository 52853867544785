import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { createCustomerContact } from '../customer/CustomerService';
import { createLocationContact } from '../location/LocationService';
import ContactCreateDialogContent from './ContactCreateDialogContent';

const ContactCreateDialog = (props) => {
  const {
    id,
    isOpen,
    onClose,
    isFromCustomer,
    isFromLocation
  } = props;

  const [contactFormData, setContactFormData] = useState({
    name: '',
    position: '',
    contactFunction: 0,
    phone: '',
    fax: '',
    pager: '',
    tollFree: '',
    CellPhone: '',
    email: '', 
    notes: '',
    onMailingList: 0,
    extension: '',
    webUsername: "",
    webPassword: "",
    isFav: false,
  });
  const [contactFormValidation, setContactFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setContactFormValidation(buildValidation(contactFormData));
    }
  }, [contactFormData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      resetContactFormData();      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const resetContactFormData = () => {
    setErrorMessage('');
    contactFormData.name = '';
    contactFormData.position = '';
    contactFormData.email = '';
    contactFormData.phone = '';
    contactFormData.extension = '';
    contactFormData.isFav = false;
    setContactFormData({ ...contactFormData })
  };

  const updateContactFormData = e => {
    const {name, value} = e.target;
    contactFormData[name] = value;
    setContactFormData({ ...contactFormData});
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(contactFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    var result;
    if (isFromCustomer) {
      result = await createCustomerContact(id, contactFormData);
    } else if (isFromLocation) {
      result = await createLocationContact(id, contactFormData);
    }
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    props.onClose();
  };

  return (
    isOpen &&
    <ContactCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      contactFormData={contactFormData}
      contactFormValidation={contactFormValidation}
      updateContactFormData={updateContactFormData}
    />
  );
}

const buildValidation = (contactFormData) => {
  const {
      name
  } = contactFormData || {};
  const validation = new Validation();
  return {
      name: validation.isEmpty(name)
  };
};

export default ContactCreateDialog;