import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import ContactEditDialog from '../contact/ContactEditDialog.js'

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const ContactListColumnEditButton = (props) => {
  const {
    contactID,
    getContacts,
    currentFavorite,
  } = props;
  
  const classes = useStyles();
  const [isContactEditModalOpen, setIsContactEditModalOpen] = useState(false);

  const openContactEditModal = () => {
    setIsContactEditModalOpen(true);
  };

  const closeContactEditModal = () => {
    setIsContactEditModalOpen(false);
    getContacts();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openContactEditModal}>
        <EditIcon/>&nbsp;
        <span>EDIT</span>
      </IconButton> 
      <ContactEditDialog
        contactID={contactID}
        isOpen={isContactEditModalOpen}
        onClose={closeContactEditModal}
        currentFavorite={currentFavorite}
      />
    </>
  );
}

ContactListColumnEditButton.propTypes = {
  contactID: PropTypes.number.isRequired,
  currentFavorite: PropTypes.number
};

export default ContactListColumnEditButton;