import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as SizeSvg } from '../assets/svg-icons/size@1x.svg';

const IconSize= () => {
    return (
        <SvgIcon fontSize="small">
            <SizeSvg />
        </SvgIcon>    
    )
}
export default IconSize;
