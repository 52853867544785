import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconInvoice from '../icon/IconInvoice';

const NavigationDrawerListItemInvoice = () => {
  return (
    <NavigationDrawerListItem
      title={'Invoices'}
      icon={<IconInvoice />}
      uri={'/spark/intermodal-invoice'} />
  );

}

export default NavigationDrawerListItemInvoice;