import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './RevenueService';
import RevenueCreateDialogContent from './RevenueCreateDialogContent';
import { getTaxTypeIDNone } from '../configuration/ConfigurationService';

const RevenueCreateDialog = (props) => {
  const {
    id,
    isOpen,
    onClose,
    defaultReceivableChargeTypeID,
    defaultReceivableChargeTypeName
  } = props;

  const initialState = {
    receivableChargeTypeID: defaultReceivableChargeTypeID ? defaultReceivableChargeTypeID : null,
    receivableChargeTypeName: defaultReceivableChargeTypeName ? defaultReceivableChargeTypeName : '',
    units: 1,
    unitRate: 0,
    subtotal: 0,
    tax1TypeID: 0,
    tax2TypeID: 0,
    intermodalOrderID: id
  };

  const [revenueFormData, setRevenueFormData] = useState(initialState);
  const [revenueFormValidation, setRevenueFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setRevenueFormValidation(buildValidation(revenueFormData));
    }
  }, [revenueFormData, isOpen]);

  useEffect(() => {
    revenueFormData.intermodalOrderID = id;    
    setRevenueFormData(revenueFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function getDefaultTaxTypes() {
      const taxType = await getTaxTypeIDNone();
      setRevenueFormData(r => (
        { ...r, tax1TypeID: taxType, tax2TypeID: taxType, 
          receivableChargeTypeID: defaultReceivableChargeTypeID ? defaultReceivableChargeTypeID : null, 
          receivableChargeTypeName: defaultReceivableChargeTypeName ? defaultReceivableChargeTypeName : '' }         
      ));
    }
    getDefaultTaxTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => { 
    setRevenueFormData(r => (
      { ...r, description: revenueFormData.receivableChargeTypeName }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueFormData.receivableChargeTypeID]);

  const updateRevenueFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    revenueFormData[name] = value;
    revenueFormData[nameData] = valueData;
    setRevenueFormData({ ...revenueFormData });
    updateSubtotal();
  };

  const updateSubtotal = () => {
    setRevenueFormData(s => ({...s, subtotal: (revenueFormData.units * revenueFormData.unitRate)}));
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(revenueFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(revenueFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => { 
    setRevenueFormData(initialState);
    props.onClose();
  };

  return (
    isOpen &&
    <RevenueCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      revenueFormData={revenueFormData}
      revenueFormValidation={revenueFormValidation}
      updateRevenueFormData={updateRevenueFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (revenueFormData) => {
  const {
    receivableChargeTypeID
  } = revenueFormData || {};
  const validation = new Validation();
  return {
    receivableChargeTypeID: validation.isNumber(receivableChargeTypeID)
  };
};

export default RevenueCreateDialog;