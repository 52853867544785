import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DispatchedSvg } from '../assets/svg-icons/dsp-dispatched@1x.svg';

const IconDispatched = () => {
  return (
    <SvgIcon fontSize="small">
      <DispatchedSvg />
    </SvgIcon>
  )
}
export default IconDispatched;