import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CoreAddButton from '../core/CoreAddButton';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import BillViewExpenseCreateDialog from './BillViewExpenseCreateDialog';
import { deletePayable } from '../payable/PayableService';
import BillViewExpenseListTableEditButton from './BillViewExpenseListTableEditButton';

const BillViewExpenseListTable = (props) => {

  const {
    billID,
    expenses,
    getExpenses, 
    setExpenses,
    configurationDefault
  } = props;

  const tabSelected = 0;  
  const [isExpenseCreateDialogOpen, setExpenseCreateDialogOpen] = useState(false);


  let columns = [
    { 
      field: 'Type', 
      headerName: 'Type', 
      align: 'left', 
      flex: 2, 
      sortable: false,
      renderCell: (params) => {
        const type = params.getValue("PayableChargeType") ? params.getValue("PayableChargeType").Name : '';
        return (
          <div>{type}</div>
        )
      }    
    },
    { 
      field: 'Description', 
      headerName: 'Description', 
      align: 'left', 
      flex: 1.5, 
      sortable: false 
    },
    { 
      field: 'Units', 
      headerName: 'Units', 
      align: 'left', 
      flex: 1, 
      sortable: false 
    },
    { 
      field: 'UnitRate', 
      headerName: 'Rate', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("UnitRate");
        return (
          <div>{"$"} {name.toFixed(2)}</div>
        )
      }
    },
    { 
      field: 'Subtotal', 
      headerName: 'Subtotal', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Subtotal");
        return (
          <div>{"$"} {name.toFixed(2)}</div>
        )
      }
    },
    { 
      field: 'Tax', 
      headerName: 'Tax', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Tax1") + params.getValue("Tax2");
        return (
          <div>
            {"$"} {name.toFixed(2)}
          </div>
        )
      }
    },
    { 
      field: 'Total', 
      headerName: 'Total', 
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Subtotal") + params.getValue("Tax1") + params.getValue("Tax2");
        return (
          <div>
            {"$"} {name.toFixed(2)}
          </div>
        )
      }
    },
    {
      field: 'Controls', 
      headerName: ' ', 
      align: 'left', 
      flex: 2, 
      sortable: false,
      renderCell: (params) => {
        const payableChargeID = params.getValue("PayableChargeID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
            <BillViewExpenseListTableEditButton
              payableChargeID={payableChargeID}
              getExpenses={getExpenses}
            />}
            {showControls &&
            <CoreListColumnDeleteButton
              reference={`${params.getValue("Description")} from Expenses`}
              deleteID={payableChargeID}
              deleteFunction={deletePayable}
              getList={getExpenses}
            />}
          </div>
        )
      }
    }
  ];

  const onExpenseRowEnter = (params) => {
    for (const expense of expenses) {
      if (expense.PayableChargeID === params.row.PayableChargeID) {
        expense.ShowControls = true;
      }
    }
    setExpenses([ ...expenses ]);
  };

  const onExpenseRowLeave = (params) => {
    for (const expense of expenses) {
      if (expense.PayableChargeID === params.row.PayableChargeID) {
        expense.ShowControls = false;
      }
    }
    setExpenses([...expenses]);
  };

  const handleOpenCreate = () => {
    setExpenseCreateDialogOpen(true)
  };

  const closeExpenseCreateDialog = () => { 
    setExpenseCreateDialogOpen(false);
    getExpenses();
  };

  return (
    <div>  
      <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="munaGrid mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid container item xs={11}>
            <Tabs
              value={tabSelected}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab value={0} label='Expenses' />
            </Tabs>
          </Grid>
          {      
            <Grid container item xs={1}>
              <CoreAddButton handleOpenCreate={handleOpenCreate}/>
            </Grid>
          }
        </Grid>
        <DataGrid
          getRowId={(row) => row.PayableChargeID} 
          hideFooter={true} 
          autoHeight={true} 
          rows={expenses} 
          columns={columns} 
          pageSize={100} 
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          onRowEnter={e => onExpenseRowEnter(e)}
          onRowLeave={e => onExpenseRowLeave(e)}
        />      
      </Container>
      <BillViewExpenseCreateDialog
        billID={billID}
        isOpen={isExpenseCreateDialogOpen}
        onClose={closeExpenseCreateDialog}
        defaultPayableChargeTypeID={configurationDefault.DefaultDryRunPayableChargeTypeID}
        defaultPayableChargeTypeName={configurationDefault.DefaultDryRunPayableChargeTypeName}
      />
    </div>
  );
}

BillViewExpenseListTable.propTypes = {
  expenses: PropTypes.array.isRequired,
  getExpenses: PropTypes.func.isRequired
};

export default BillViewExpenseListTable;