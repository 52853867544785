import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';

const CountryCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    countryFormData,
    onCreate,
    errorMessage,
    countryFormValidation,
    updateCountryFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Country</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="EDICode"
                label="Country Code"
                value={countryFormData.EDICode}
                onChange={e => updateCountryFormData(e)}
                isRequired={true}
                validationError={countryFormValidation.EDICode}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={6} xs={6}>
                <CoreTextField
                    name="Name"
                    label="Country Name"
                    value={countryFormData.Name}
                    onChange={e => updateCountryFormData(e)}
                    isRequired={true}
                    validationError={countryFormValidation.Name}
                />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default CountryCreateDialogContent;