import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  iconFloatRight: {
    margin: 0,
    top: 'auto',
    bottom: 12,
    left: 'auto',
    float: 'right'
  },
}));

const EquipmentCreateButton = () => {
  let history = useHistory();
  const classes = useStyles();

  const openEquipmentCreate = () => {
    history.push('/spark/equipment/create');
  };

  return (
    <Fab 
      name="equipment_list[create]"
      color="primary" 
      aria-label="add" 
      className={classes.iconFloatRight}
      // style={style}
      onClick={openEquipmentCreate}>
      <AddIcon />
    </Fab>
  );
}

export default EquipmentCreateButton;