import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import { getGeocode } from '../mileage/MileageService';

const useStyles = makeStyles(() => ({
  mapContainer: {
    height: '180px', 
    position: 'relative', 
    bottom:'40px',
    left: '-16px',
  },
  line: { 
    opacity: '0.3'
  }
}));

const OrderViewLocationSection = (props) => {
  const classes = useStyles();
  const {
    order
  } = props;

  const [originCoordinates, setOriginCoordinates] = useState({});
  const [destinationCoordinates, setDestinationCoordinates] = useState({});

  useEffect(() => {
    getGeocode(
      order.OriginAddress, 
      order.OriginCity, 
      order.OriginState, 
      order.OriginCountry, 
      order.OriginZip)
      .then(function (response) {
        setOriginCoordinates(response);
    });
    getGeocode(
      order.DestinationAddress, 
      order.DestinationCity, 
      order.DestinationState, 
      order.DestinationCountry, 
      order.DestinationZip)
      .then(function (response) {
        setDestinationCoordinates(response);
    });
  }, [order]);

  function formatOriginAddress () { 
    if (order.OriginCountry === "Philippines") { 
      return order.OriginAddress + '\n' 
        + order.OriginDistrict + '\n'
        + order.OriginCounty + '\n'
        + order.OriginZip + ", "
        + order.OriginCity + ", " 
        + order.OriginCountry;
    }
    return order.OriginAddress + '\n' 
      + order.OriginCity + ", " 
      + order.OriginCountry + " "
      + order.OriginZip;
  };

  function formatDestinationAddress () { 
    if (order.DestinationCountry === "Philippines") { 
      return order.DestinationAddress + '\n'
        + order.DestinationDistrict + '\n'
        + order.DestinationCounty + '\n'
        + order.DestinationZip + ", "
        + order.DestinationCity + ", "
        + order.DestinationCountry;
    }
    return order.DestinationAddress + '\n'
      + order.DestinationCity + ", "
      + order.DestinationCountry + " "
      + order.DestinationZip;
  };

  return (
    <Container square={true} maxWidth="lg" component={Paper} className="mt-2" style={{ whiteSpace: 'pre-line' }}>
      <Grid container md={12} xs={12}>
        <Grid container md={6} xs={6} spacing={2}>
            <Grid item md={12} xs={12}>
                <h4>Origin Address</h4>
            </Grid>
            <Grid item xs={6}>  
                <CoreTextDisplayField
                label={order.OriginCompanyName}
                value={formatOriginAddress()}
                icon={<LocationOnIcon />}
                labelStyle={{fontWeight: 'bold', color: 'black'}}
                />
            </Grid>
             <Grid container item xs={6} className={classes.mapContainer}>
            {originCoordinates &&
                <CoreHereDisplayMap
                coordinates={originCoordinates}
                />
            }
            </Grid>
        </Grid>
        <hr className={classes.line}></hr>
        <Grid container md={6} xs={6}  spacing={2}>
            <Grid item md={12} xs={12}>
                <h4>Destination Address</h4>
            </Grid>
            <Grid item xs={6}>  
                <CoreTextDisplayField
                label={order.DestinationCompanyName}
                value={formatDestinationAddress()}
                icon={<LocationOnIcon />}
                labelStyle={{fontWeight: 'bold', color: 'black'}}
                />
            </Grid>
            <Grid container item xs={6} className={classes.mapContainer}>
            {destinationCoordinates &&
                <CoreHereDisplayMap
                coordinates={destinationCoordinates}
                />
            }
            </Grid>
        </Grid>
      </Grid>
    </Container>
  );

}

export default OrderViewLocationSection;