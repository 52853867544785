import CoreToggleField from '../core/CoreToggleField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import PayableListColumnEditButton from '../payable/PayableListColumnEditButton';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { intermodalOrderDelete as Delete } from '../payable/PayableService';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';

export const columns = (listParameters, openRecord, openSlideout, getSettlementList, putBillOnHold, tagBillForBilling) => [
  {
    field: 'OpenControls',
    headerName: ' ',
    align: 'center',
    flex: 1,
    renderCell: (params) => {  
      const name = params.getValue("IntermodalOrderID");
      return (
        <div>
          <IconButton title="Open Full Record" onClick={() => openRecord(name)} disabled={!name}>
            <OpenInBrowserIcon color={name ? 'primary': 'disabled'}/>
          </IconButton>
          <IconButton title="Open Slideout" onClick={() => openSlideout(name)} disabled={!name}>
            <MenuOpenIcon color={name ? 'primary': 'disabled'}/>
          </IconButton>
        </div>
      )
    }
  },
  {
    field: '',
    headerName: '',
    align: 'left',
    flex: 0.5,
    disableColumnMenu: true,
    hide: listParameters.ShowCompleted,
    renderCell: (params) => { 
      const bill = params.row;
      let tagForBilling = params.getValue("TagForBilling");
      return (  
        <div style={{
          width:'100%', 
          minWidth: '100%', 
          display: 'flex',
          justifyContent: 'space-between'}}>
          <span>
            <CoreCheckboxField
              label={null}
              value={tagForBilling}
              name="TagForBilling"
              onChange={(e) => tagBillForBilling(e, bill) }
              disabled={bill.TotalAmount === 0}
            />
          </span>
        </div>
      )
    }
  },
  {
    field: 'HoldPayment',
    headerName: 'Hold',
    align: 'left',
    flex: 0.7,
    disableColumnMenu: true,
    hide: (listParameters.ShowApproved || listParameters.ShowCompleted),
    renderCell: (params) => { 
      const onHold = params.getValue("HoldPayment") || false;
      const billID = params.getValue("BillID");
      return ( 
        <div style={{
          width:'100%', 
          minWidth: '100%', 
          display: 'flex',
          justifyContent: 'space-between'}}>
          <span>
            <CoreToggleField
              label={null}
              value={onHold}
              name="HoldPayment"
              onChange={(e) => putBillOnHold(e, billID)}
            />
          </span>
        </div>
      )
    }
  },
  {
    field: 'BillID',
    headerName: 'Bill ID',
    align: 'left',
    flex: 1,
    hide: true
  },
  {
    field: 'BillNumber',
    headerName: 'Expense #',
    align: 'left',
    flex: 1,
  },
  {
    field: 'DriverName',
    headerName: 'Driver',
    align: 'left',
    flex: 1,
  },
  {
    field: 'BillDate',
    headerName: 'Expense Date',
    align: 'left',
    flex: 1,
  },
  {
    field: 'OrderNum',
    headerName: 'Order #',
    align: 'left',
    flex: 1,
  },
  {
    field: 'ContainerNumber',
    headerName: 'Container #',
    align: 'left',
    flex: 1,
  },
  {
    field: 'PayableChargeTypeName',
    headerName: 'Payable Item',
    align: 'left',
    flex: 1,
  },
  {
    field: 'EventType',
    headerName: 'Event',
    align: 'left',
    flex: 1,
  },
  {
    field: 'LegIndex',
    headerName: 'Leg',
    align: 'left',
    flex: 1,
    renderCell: (params) => { 
      var index = params.getValue("LegIndex");
      if (index !== null) {
        index = index + 1;
      }
      return (
        index && (<div>{'LEG'} {index}</div>)
      )
    }
  },
  {
    field: 'Units',
    headerName: 'Qty',
    align: 'left',
    flex: 1
  },
  {
    field: 'UnitRate',
    headerName: 'Rate',
    align: 'left',
    flex: 1,
    renderCell: (params) => {
      const name = params.getValue("UnitRate");
      return (
        <div>{"$"} {name.toFixed(2)}</div>
      )
    }
  },
  {
    field: 'TotalAmount',
    headerName: 'Amount',
    align: 'left',
    flex: 1,
    renderCell: (params) => { 
      const name = params.getValue("TotalAmount");
      return (
        <div>{"$"} {name.toFixed(2)}</div>
      )
    },
  },
  { 
    field: 'Controls', 
    headerName: ' ', 
    align: 'left', 
    flex: 2, 
    sortable: false,
    hide: listParameters.ShowCompleted,
    renderCell: (params) => {
      const payableChargeID = params.getValue("PayableChargeID");
      const showControls = params.getValue("ShowControls") || false;
      return (
      <div>
          {showControls &&
          <PayableListColumnEditButton
              payableID={payableChargeID}
              getPayables={getSettlementList}
          />}
          {showControls &&
            <CoreListColumnDeleteButton
                reference={`Payable Item To: ${params.getValue("DriverName")}`}
                deleteID={payableChargeID}
                deleteFunction={Delete}
                getList={getSettlementList}
          />}
      </div>
      )
    }
  }
];