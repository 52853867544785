import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import AutomaticPayableCreateDialogContent from './AutomaticPayableCreateDialogContent';
import { Create } from './AutomaticPayableService';
import { setDateTimeToZero } from '../globalFunction';

const AutomaticPayableCreateDialog = (props) => { 
  const {
    personnelID,
    isOpen,
    onClose
  } = props;

  var initialState = { 
    DateStart: '',
    DateEnd: '',
    AutoAddIf: 0,
    ShipmentType: '',
    EventType: '',
    PayableChargeTypeID: null,
    Units: null,
    UnitRate: null,
    IntermodalUnitType: null,
    IntermodalUnitRateType: null,
  };

  const [autoPayFormData, setAutoPayFormData] = useState({});
  const [autoPayFormValidation, setAutoPayFormValidation] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => { 
    if (isOpen) { 
      setAutoPayFormData(a => ({ ...a, PersonnelID: personnelID }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => { 
    if (isOpen) { 
      setAutoPayFormValidation(buildValidation(autoPayFormData));
    }
  }, [autoPayFormData, isOpen]);

  const updateAutoPayFormData = e => { 
    const { name, value, nameData, valueData, valueUnitsDefaultValue, valueUnitRateDefaultValue, valueIntermodalUnitRateType, valueIntermodalUnitType } = e.target;
    autoPayFormData[name] = value;
    autoPayFormData[nameData] = valueData;
    if (nameData === "PayableChargeTypeID") {
      autoPayFormData.Units = valueUnitsDefaultValue;
      autoPayFormData.UnitRate = valueUnitRateDefaultValue;
      autoPayFormData.IntermodalUnitType = valueIntermodalUnitType;
      autoPayFormData.IntermodalUnitRateType = valueIntermodalUnitRateType;
    }
    setAutoPayFormData({ ...autoPayFormData });
  };

  const onCreate = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(autoPayFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const formatDates = () => {
    autoPayFormData.DateStart = setDateTimeToZero(autoPayFormData.DateStart);
    autoPayFormData.DateEnd = setDateTimeToZero(autoPayFormData.DateEnd);
    setAutoPayFormData({ ...autoPayFormData });
  };

  const processCreateRequest = async () => { 
    setErrorMessage('');
    formatDates();
    setInProgress(false);
    const result = await Create(autoPayFormData);
    if (result.error) {
      setErrorMessage(result.error);
      setInProgress(false);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => { 
    setAutoPayFormData(initialState);
    onClose();
  };  

  return ( 
    isOpen && <AutomaticPayableCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      autoPayFormData={autoPayFormData}
      onCreate={onCreate}
      errorMessage={errorMessage}
      updateAutoPayFormData={updateAutoPayFormData}
      autoPayFormValidation={autoPayFormValidation}
      inProgress={inProgress}
    />
  )
};

const buildValidation = (autoPayFormData) => { 
  const { 
    AutoAddIf,
    PayableChargeTypeID,
    Units,
    UnitRate
  } = autoPayFormData || {};
  const validation = new Validation();
  return { 
    AutoAddIf: validation.isNumber(AutoAddIf),
    PayableChargeTypeID: validation.isNumber(PayableChargeTypeID),
    Units: validation.isNumber(Units),
    UnitRate: validation.isNumber(UnitRate)
  };
};

export default AutomaticPayableCreateDialog;