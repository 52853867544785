import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import IconUnitNumber from '../icon/IconUnitNumber';
import IconUnitType from '../icon/IconUnitType';
import IconTractorType from '../icon/IconTractorType';
import IconOwner from '../icon/IconOwner';
import IconActiveDate from '../icon/IconActiveDate';
import IconInactiveDate from '../icon/IconActiveDate';
import IconLicensePlate from '../icon/IconLicensePlate';
import IconState from '../icon/IconState';
import IconVIN from '../icon/IconVIN';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreToggleDisplayField from '../core/CoreToggleDisplayField';

const EquipmentViewGeneralSection = (props) => {
  const {
    equipment
  } = props;

  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>General Information</h4>
        </Grid>
        <Grid container spacing={2} xs={12} className="mb-2">
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Unit Number"
              value={equipment.UnitNum}
              icon={
                <IconUnitNumber /> 
              }
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Unit Type"
              value={equipment.UnitType}
              icon={
                <IconUnitType />
              }
              />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Equipment Type"
              value={equipment.EquipmentType}
              icon={
                <IconTractorType />
              }
            />
          </Grid>
          <Grid item xs={3}>
            {equipment.OwnerType === 0 && <CoreTextDisplayField
                label="Owned By"
                value="Company"
                icon={
                  <IconOwner />
                }
            />}
            {equipment.OwnerType !== 0 && <CoreTextDisplayField
                label="Owned By"
                value={equipment.OwnerCarrierName}
                icon={
                  <IconOwner />
                }
            />}
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Active Date"
              value={equipment.ServiceStart}
              icon={
                <IconActiveDate />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="In-active Date"
              value={equipment.ServiceEnd}
              icon={
                <IconInactiveDate />
              }
            />
          </Grid>
          {equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="License Plate Number"
                value={equipment.LicenseNum}
                icon={
                  <IconLicensePlate />
                }
              />
            </Grid>
          }
          {equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="License Plate State"
                value={equipment.LicenseState}
                icon={
                  <IconState />
                }
              />
            </Grid>
          }
          {equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreTextDisplayField
                label="VIN"
                value={equipment.VINNum}
                icon={
                  <IconVIN />
                }
              />
            </Grid>
          }
          <Grid item xs={3}>

          </Grid>
          <Grid item xs={3}>

          </Grid>
          {equipment.UnitType !== 'Container' &&
            <Grid item xs={3}>
              <CoreToggleDisplayField
                label="Is Leased"
                value={equipment.IsLeased}
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </Container>
  );
}

export default EquipmentViewGeneralSection;