import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import FeePayPaymentMethodField from './FeePayPaymentMethodField';

import { CurrencyDisplay } from '@envasetechnologies/feepay-components';

const useStyles = makeStyles(() => ({
  line: {
    opacity: 0.3
  },
  lineMovedUp: {
    opacity: 0.3,
    marginTop: '-15px'
  },
  noPadding: { 
    padding: 0
  },
  moveUp: { 
    marginTop: '-15px'  
  },
  orderItemTotal: { 
    display: 'inline-flex', 
    alignItems: 'center', 
    paddingLeft: '6px',
    paddingRight: '6px',  
    width: 'inherit'
  }
}));

const FeePayShoppingCartPayment = (props) => { 
  const classes = useStyles();
  const {
    selectedPaymentMethod,
    onPaymentMethodSelect,
    onPlaceOrder,
    onCancel,
    paymentIntent,
    selectedCartItemsCount,
    total,
  } = props;

  return ( 
    <div>
      <Container square={true} maxWidth="lg" className="m1-1 munaGrid" component={Paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <h3>Payment</h3>
          </Grid>
        </Grid>
      </Container>
      <Container square={true} maxWidth="lg" className={classes.noPadding} component={Paper}>
        <hr className={classes.line}></hr>
      </Container>
      <Container square={true} maxWidth="lg" className={classes.moveUp} component={Paper}>
        <Grid container spacing={2} className="mb-1">  
          <span className={classes.orderItemTotal}>
            <Grid item xs={6} style={{textAlign: "left"}}>
              <p>Order {selectedCartItemsCount} Total item(s)</p>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
              <CurrencyDisplay amount={total} currency={"PHP"} />
            </Grid>
          </span>
          <Grid item xs={12}>
            <hr className={classes.lineMovedUp}></hr>
          </Grid>
          <Grid item xs={12} className={classes.moveUp}>
            <FeePayPaymentMethodField
              name="paymentMethod"
              label="Select Payment Method"
              value={selectedPaymentMethod}
              onChange={e => onPaymentMethodSelect(e)}
              paymentIntent={paymentIntent}
            />
          </Grid>
          <Grid item xs={6} className="mt-2 mb-1" style={{textAlign: "left"}}>
            <Button variant="outlined" color="primary" onClick={(() => onCancel())}>
                Cancel
            </Button>
          </Grid>
          <Grid item xs={6} className="mt-2 mb-1" style={{textAlign: "right"}}>
            <Button variant="outlined" color="primary" onClick={() => onPlaceOrder()} disabled={selectedCartItemsCount < 1 || selectedPaymentMethod.trim() === ""}>
                Place Order
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default FeePayShoppingCartPayment;