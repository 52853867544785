import React, { useState, useEffect } from "react";
import PersonnelResetDriverPasswordDialogContent from "./PersonnelResetDriverPasswordDialogContent";
import { resetDriverPassword } from "./PersonnelService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import Validation from "../validation";

const PersonnelResetDriverPasswordDialog = (props) => {
  const { open, personnel, handleClose } = props;
  const [error, setError] = useState();
  const [formValidation, setFormValidation] = useState({});
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const [formData, setFormData] = useState({
    email: personnel.Email,
    password: "",
  });

  useEffect(() => {
    setFormData((form) => ({ ...form, email: personnel.Email }));
  }, [personnel]);

  useEffect(() => {
    setFormValidation(buildValidation(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { value } = e.target;
    formData["password"] = value;
    setFormData({ ...formData });
  };

  const onClose = () => {
    setFormData((form) => ({ ...form, password: "" }));
    setError(null);
    setSuccess(null);
    handleClose();
  };

  const onResetPassword = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    if (!new Validation().isValidObject(formValidation)) {
      setError("Check all fields");
      return;
    }
    setLoading(true);
    await processResetPassword();
    setLoading(false);
  };

  const processResetPassword = async () => {
    var response = await resetDriverPassword(personnel.PersonnelID, formData.password);
    if (response.response === 'Success') {
      setSuccess(
        `${formData.email} password has been changed to ${formData.password}`
      );
      setFormData((form) => ({ ...form, password: "" }));
    } else {
      if (response.error) {
        setError(response.error);
      } else {
        setError("An error has occured");
      }
      setFormData((form) => ({ ...form, password: "" }));
      return;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Set Temporary Passsword</DialogTitle>
      <DialogContent>
        <DialogContentText>Set Temporary Driver Password</DialogContentText>
        <PersonnelResetDriverPasswordDialogContent
          formData={formData}
          loading={loading}
          error={error}
          success={success}
          handleChange={handleChange}
          onResetPassword={onResetPassword}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="grey">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonnelResetDriverPasswordDialog;

const buildValidation = (formData) => {
  const { email, password } = formData || {};
  const validation = new Validation();
  return {
    email: validation.isEmpty(email),
    password: validation.isEmpty(password),
  };
};
