import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import CoreDateField from '../core/CoreDateField';
import OrderStatusField from './OrderStatusField';

const OrderBillingView = (props) => {
  const {
    orderFormData,
    updateOrderFormData,
    onSave,
    errorMessage,
    alertSuccess,
    inProgress
  } = props;

  return (
    <div>
      <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1" component={Paper}>
        <Paper variant="outlined" square>
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" gutterBottom>
              <Grid container spacing={2} className="mt-2">                
                <Grid item md={3} xs={6}>
                  <OrderStatusField
                    name="Status"
                    label="Load Status"
                    value={orderFormData.Status || ""}
                    onChange={e => updateOrderFormData(e)}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CoreDateField
                    name="BillDate"
                    label="Invoice Date"
                    value={orderFormData.BillDate || ""}
                    disabled={orderFormData.Status === "Approved For Billing" ? true : false}
                    onChange={e => updateOrderFormData(e)}
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
                <Grid item md={3} xs={6}>
                  {orderFormData.Type === "Export" && <CoreTextField
                    name="BookingNum"
                    label="Booking #"
                    value={orderFormData.BookingNum || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />}
                  {orderFormData.Type === "Import" && <CoreTextField
                    name="BookingNum"
                    label="MasterBL"
                    value={orderFormData.BookingNum || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />}
                  {orderFormData.Type === "One Way" && <CoreTextField
                    name="BookingNum"
                    label="Booking # / MasterBL"
                    value={orderFormData.BookingNum || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />}
                </Grid>
                <Grid item md={3} xs={6}>
                  <CoreTextField
                    name="SerialNum"
                    label="Seal Number"
                    value={orderFormData.SerialNum || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CoreTextField
                    name="HouseBL"
                    label="HouseBL / HAWB"
                    value={orderFormData.HouseBL || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CoreTextField
                    name="Line"
                    label="Line"
                    value={orderFormData.Line || ""}
                    onChange={e => updateOrderFormData(e)}
                    maxLength={50}
                  />
                </Grid>      
                <Grid item md={11} xs={false}>
                  {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                  {alertSuccess && <Alert severity="success">Billing successfully saved.</Alert>}
                </Grid>
                <Grid item md={1} xs={6}>
                  <Button onClick={onSave} color="primary" disabled={inProgress}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Typography>
          </Container>
        </Paper>
      </Container>
    </div>
  );

}

export default OrderBillingView;