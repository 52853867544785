import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreDateField from '../core/CoreDateField';
import CoreOutlinedTextField from '../core/CoreOutlinedTextField';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomerField from '../customer/CustomerField';
import IconArrowRight from '../icon/IconArrowRight';
import IntermodalInvoiceListTransmitDialog from './IntermodalInvoiceListTransmitDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const IntermodalInvoiceListFilterRow = (props) => {
  const {
    filterData,
    updateFilterData,
    resetFilterRow,
    showTransmitButton,
    listWidth,
    customerName,
  } = props;
  const classes = useStyles();

  const [isTransmitDialogOpen, setIsTransmitDialogOpen] = useState(false);

  const closeTransmitDialogOpen = () => {
    setIsTransmitDialogOpen(false);
    resetFilterRow();
  };

  const openTransmitDialog = () => {
    setIsTransmitDialogOpen(true);
  };

  return (
    <div>
      <Container 
        maxWidth={listWidth} 
        className="mb-2">
        <Grid container spacing={1}>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <CustomerField
                  name="Company"
                  label="Customer"
                  customerForEdit={customerName}
                  value={filterData.Company}
                  nameData="CustomerID"
                  valueData={filterData.CustomerID}
                  variant={"outlined"}
                  size={"small"}
                  onChange={e => updateFilterData(e)}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CoreDateField
                  name={"BillDate"}
                  label="Bill Date"
                  value={filterData.BillDate}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CoreOutlinedTextField
                  name="ReferenceNumber"
                  label="REF #"
                  minValue={0}
                  value={filterData.ReferenceNumber}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CoreOutlinedTextField
                  name="BookingNum"
                  label="MBL # / BK #"
                  minValue={0}
                  value={filterData.BookingNum}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid container spacing={1} justify="flex-end">
              <IconButton 
                item md={2}
                className={classes.iconText} 
                onClick={resetFilterRow}>
                <HighlightOffIcon />&nbsp;<span>RESET</span>
              </IconButton>
              {showTransmitButton &&
              <IconButton
                item md={2}
                className={classes.iconText}
                onClick={openTransmitDialog}>
                <span>SEND INVOICES</span>&nbsp;<IconArrowRight />
              </IconButton>}
            </Grid>
          </Grid>
          <IntermodalInvoiceListTransmitDialog
            isOpen={isTransmitDialogOpen}
            onClose={closeTransmitDialogOpen}
          />
        </Grid>
      </Container>
    </div>
  );

}

export default IntermodalInvoiceListFilterRow;