import { Route } from 'react-router-dom';
import IncomingLoadList from './IncomingLoadList';
// import IncomingLoadView from './IncomingLoadView';

const IncomingLoadRouter = () => {
  return (
    <>
      <Route exact path="/spark/incoming-load" component={IncomingLoadList} />    
      {/* <Route exact path="/spark/incoming-load/view/:id" component={IncomingLoadView} /> */}
    </>
  );

}

export default IncomingLoadRouter;