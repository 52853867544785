import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// https://material-ui-pickers.dev/getting-started/installation

const CoreDateField = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    validationError,
    isRequired,
    variant,
    size,
    disableUnderline,
    ...rest
  } = props;
  const [touchedByUser, setTouchedByUser] = useState(false);

  const onSelectEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(dispatchEvent);
  };

  const handleDateChange = (date) => {
    if (date && new Date(date).toString() !== 'Invalid Date') {
      onSelectEvent(date.toLocaleDateString('en-US'));
    }
    if (!date) {
      onSelectEvent(null);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel 
        required={isRequired} 
        error={isRequired && touchedByUser && validationError}
        shrink={value ? true : false}>
        {isRequired ? label : null}
      </InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          placeholder=""
          label={label}
          value={value || null}
          error={isRequired && touchedByUser && validationError}
          onBlur={() => setTouchedByUser(true)}
          onChange={handleDateChange}
          format="MM/dd/yyyy"
          inputVariant={variant}
          required={isRequired}
          size={size}
          InputProps={{
            disableUnderline: disableUnderline ? true : false
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

CoreDateField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreDateField;
