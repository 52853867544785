import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as EmailSvg } from '../assets/svg-icons/email@1x.svg';

const IconEmail = () => {
  return (
    <SvgIcon fontSize="small">
      <EmailSvg />
    </SvgIcon>
  )
}
export default IconEmail;