import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AppointmentCancelSvg } from '../assets/svg-icons/appointment-cancel@1x.svg';

const IconAppointmentCancel = () => {
  return (
    <SvgIcon fontSize="small">
      <AppointmentCancelSvg />
    </SvgIcon>
  )
}
export default IconAppointmentCancel;