import React from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const PersonnelEnableLoginDialogContent = (props) => {
  const {
    emailAddress,
    internalName,
    loading, 
    error, 
    success, 
    onEnableUser,
    onClose,
    isOpen
  } = props;
 
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Enable Envase Login
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              Would you like to enable an Envase TMS login for:
            </p>
            <p>
              <strong>{internalName} ({emailAddress})</strong>
            </p>
            <p>
              Please ensure this email address is correct! A confirmation email address will be sent to this address.
            </p>
          </Grid>
          {(error || success) && (
            <Grid item xs={12} className="mt-3">
                <Alert variant="filled" severity={error ? "error" : "success"}>
                  {error || success}
                </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!loading &&
      <DialogActions>
        <Button onClick={onClose} className="grey">
          {!success && 'Cancel'}
          {success && 'Close'}
        </Button>
        {!success && <Button onClick={onEnableUser} className="grey">
          Enable TMS Login
        </Button>}
      </DialogActions>}
    </Dialog>
  );
};

export default PersonnelEnableLoginDialogContent;