import React from 'react';
import OrderCreateContentInfo from './OrderCreateContentInfo';
import OrderCreateContentLocations from './OrderCreateContentLocations';
import OrderCreateContentVesselInfo from './OrderCreateContentVesselInfo';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import Alert from '@material-ui/lab/Alert';
import OrderCreateContentContainerInfo from './OrderCreateContentContainerInfo';

const OrderCreateContent = (props) => {
  const {
    orderFormData,
    onCreate,
    onClose,
    errorMessage,
    orderFormValidation,
    updateOrderFormData,
    inProgress,
    initialProviderID,
    containerValidatorErrorMessage
  } = props;

  return (
    <form onSubmit={onCreate}>
      <OrderCreateContentInfo
        orderFormData={orderFormData}
        orderFormValidation={orderFormValidation}
        updateOrderFormData={updateOrderFormData}
      />
      <br/>
      <OrderCreateContentLocations
        orderFormData={orderFormData}
        updateOrderFormData={updateOrderFormData}
        orderFormValidation={orderFormValidation}
        initialProviderID={initialProviderID}
      />
      {(orderFormData.type === "Import" || orderFormData.type === "Export") && 
      <br/>}
      <OrderCreateContentVesselInfo
        orderFormData={orderFormData}
        orderFormValidation={orderFormValidation}
        updateOrderFormData={updateOrderFormData}
      />
      {orderFormData.loadedStatus !== "Chassis Only" &&
      <>
      <br/>
      <OrderCreateContentContainerInfo
        orderFormData={orderFormData}
        orderFormValidation={orderFormValidation}
        updateOrderFormData={updateOrderFormData}
      /></>}
      <br/>
      {
        errorMessage && 
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      }
      {
        containerValidatorErrorMessage && 
        <Alert variant="filled" severity="error">
          {containerValidatorErrorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!containerValidatorErrorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons onClose={onClose}/>}
    </form>
  );

}

export default OrderCreateContent;