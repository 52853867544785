import { format } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import EnvaseTraceColumnStatusColor from '../envase_trace/EnvaseTraceColumnStatusColor';

import OrderListBookAppointmentButton from './OrderListBookAppointmentButton';
import OrderListCancelAppointmentButton from './OrderListCancelAppointmentButton';
import OrderListUpdateAppointment from './OrderListUpdateAppointment';

export const columns = (listParameters, openRecord, openSlideout, getPaidStatusIcon, getList, companyFeature) => [
  {
    field: 'Controls',
    headerName: ' ',
    align: 'center',
    width: 200,
    renderCell: (params) => {  
      const name = params.getValue("ID");
      const status = params.getValue("Status");
      const order = params.row;
      
      return (
        <div>
          <IconButton title="Open Full Record" onClick={() => openRecord(name)}>
            <OpenInBrowserIcon color='primary'/>
          </IconButton>
          <IconButton title="Open Slideout" onClick={() => openSlideout(name, status)}>
            <MenuOpenIcon color='primary'/>
          </IconButton>
        </div>
      )
    }
  },
  {
    field: "ID",
    headerName: "OrderID",
    hide: true,
  },
  {
    field: "OrderNum",
    headerName: "Order",
    align: 'left',
    width: 100
  },
  {
    field: "Appointment",
    headerName: "Appointment",
    align: 'left',
    width: 225,
    renderCell: (params) => {  
      // const name = params.getValue("ID");
      // const status = params.getValue("Status");
      const order = params.row;
      
      return (
        <div>
          {companyFeature && companyFeature.IsICTSI && !order.IsAppointmentBooked && <OrderListBookAppointmentButton
            order={order}
            getList={getList}
            disable={!order.IsReadyForAppointment}
          />}
          {companyFeature && companyFeature.IsICTSI && order.IsAppointmentBooked && <OrderListUpdateAppointment
            order={order}
            getList={getList}
          />}
        </div>
      )
    }
  },
  {
    field: "Status",
    headerName: "Load Status",
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const name = params.getValue("Status") || "Unassigned";
      return (
        <div>
          {name}
        </div>
      )
    }
  },
  {
    field: "Customer",
    headerName: "Customer",
    align: 'left',
    width: 150,
  },
  {
    field: "ContainerNumber",
    headerName: "Container #",
    align: 'left',
    width: 250,
  },
  {
    field: "ChassisNumber",
    headerName: "Chassis #",
    align: 'left',
    width: 250,
  },
  {
    field: "ReferenceNumber",
    headerName: "REF #",
    align: 'left',
    width: 150,
  },
  {
    field: "BookingNum",
    headerName: "MBL # / BK #",
    align: 'left',
    width: 150,
  },
  {
    field: "OriginName",
    headerName: "Origin Location",
    align: 'left',
    width: 200,
  },
  {
    field: "OriginCity",
    headerName: "Origin City & State",
    align: 'left',
    width: 200,
  },
  {
    field: "DestinationName",
    headerName: "Destination Location",
    align: 'left',
    width: 200,
  },
  {
    field: "DestinationCity",
    headerName: "Destination City & State",
    align: 'left',
    width: 200,
  },
  {
    field: "LastConfirmedDate",
    headerName: "Last Confirmed Date",
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const name = params.getValue("LastConfirmedDate") ? format(new Date(params.getValue("LastConfirmedDate")), 'MM/dd/yyyy') : null;
      return (
        <div>
          {name}
        </div>
      )
    }
  },
  {
    field: "BatchInvoiceNum",
    headerName: "Batch Invoice #",
    align: 'left',
    width: 150,
  },
  {
    field: "TotalCharges",
    headerName: "Total Revenue",
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const name = (Math.round(params.getValue("TotalCharges") * 100) / 100).toFixed(2);;
      return (
        <div>
          ${name}
        </div>
      )
    }
  },
  {
    field: "BillDate",
    headerName: "Invoice Date",
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const name = params.getValue("BillDate") ? format(new Date(params.getValue("BillDate")), 'MM/dd/yyyy') : null;
      return (
        <div>
          {name}
        </div>
      )
    }
  },
  {
    field: 'IsBeingTraced',
    headerName: 'Traced',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const isBeingTraced = params.getValue("IsBeingTraced");
      const showWarning = params.getValue("ShowEnvaseTraceWarning");
      return (
        <div>          
          { isBeingTraced ? 
            <div>
              <span
                className="delivery-status-circle"
                style={{ backgroundColor: EnvaseTraceColumnStatusColor(showWarning) }}
              >
              </span> Yes 
            </div> : "No"
          }
        </div>
      )
    }
  },
  {
    field: 'IsFullyPaid',
    headerName: 'Paid',
    align: 'left',
    flex: 1,
    hide: !listParameters.ShowAll && !listParameters.ShowInvoiced,
    renderCell: (params) => { 
      const isFullyPaid = params.getValue("IsFullyPaid");
      return getPaidStatusIcon(isFullyPaid);
    }
  }
];