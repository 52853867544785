import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import NotesList from '../notes/NotesList';
import NoteCreateDialog from '../notes/NoteCreateDialog';
import OrderBillingView from './OrderBillingView';
import OrderEquipmentListTable from '../orderEquipment/OrderEquipmentListTable';
import IntermodalEventListTable from '../intermodalEvent/IntermodalEventListTable';
import RevenueListTable from '../revenue/RevenueListTable';
import BillListTable from '../bill/BillListTable';
import { getBills } from '../bill/BillService';
import { update as orderUpdate, getReceivables, getEquipment, getDocuments, getEvents, getEnvaseTraceEvents, createIntermodalLeg } from './OrderService';
import RevenueCreateDialog from '../revenue/RevenueCreateDialog';
import IntermodalPaymentDeductibleCreateDialog from '../intermodal_payment/IntermodalPaymentDeductibleCreateDialog';
import OrderEquipmentCreateDialog from '../orderEquipment/OrderEquipmentCreateDialog';
import DocumentListTable from '../document/DocumentListTable';
import CorePermissionToEditOverlay from '../core/CorePermissionToEditOverlay';
import CoreAddButton from '../core/CoreAddButton';
import PayableRelatedRevenueDialog from '../payable/PayableRelatedRevenueDialog';
import OrderEnvaseTraceEventListTable from './OrderEnvaseTraceEventListTable';
import OrderAppointmentsListTable from '../orderAppointment/OrderAppointmentsListTable';
import OrderAppointmentCreateDialog from '../orderAppointment/OrderAppointmentCreateDialog';
import { getContainers } from './OrderService';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';
import { GetList as getIntermodalOrderAppointments } from '../intermodal_order_appointments/IntermodalOrderAppointmentsService';

const useStyles = makeStyles(() => ({
  tabWidth: {
    width: '1170px',
  },
  tabMinWidth: {
    minWidth: '10%',
    display: 'inline-flex',
  }
}));

const OrderTabBar = (props) => {
  const {
    id,
    noteType,
    getNotes,
    notes,
    tabSelected,
    onChange,
    order,
    getOrder,
    envaseTraceLocationExists
  } = props;
  const classes = useStyles();
  const [receivables, setReceivables] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [events, setEvents] = useState([]);
  const [traceEvents, setTraceEvents] = useState([]);
  const [eventsInProgress, setEventsInProgress] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isRevenueCreateDialogOpen, setRevenueCreateDialogOpen] = useState(false);
  const [isEquipmentCreateDialogOpen, setEquipmentCreateDialogOpen] = useState(false);
  const [isNotesCreateDialogOpen, setNotesCreateDialogOpen] = useState(false);
  const [isPayableCreateDialogOpen, setPayableCreateDialogOpen] = useState(false);
  const [isPayableRelatedRevenueDialogOpen, setIsPayableRelatedRevenueDialogOpen] = useState(false);
  const [bills, setBills] = useState([]);
  const [isOrderAppointmentCreateDialogOpen, setOrderAppointmentCreateDialogOpen] = useState(false);
  const [payables, setPayables] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [orderFormData, setOrderFormData] = useState({ ...order });
  const [payableFormData, setPayableFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [container, setContainer] = useState({});
  const [companyFeature, setCompanyFeature] = useState([]);

  var today = new Date().toLocaleDateString();

  useEffect(() => {
    getReceivables(id)
      .then(function (response) {
        setReceivables(response);
      });
    getBills(id)
      .then(function(response) {
        setBills(response);
      });
    getEquipment(id)
      .then(function (response) {
        setEquipment(response);
      });
    getDocuments(id)
      .then(function (response) {
        setDocuments(response);
      });
    getEvents(id)
      .then(function (response) {
        setEvents(response);
      });
    getEnvaseTraceEvents(id)
      .then(function (response) {
        setTraceEvents(response);
      });
      getIntermodalOrderAppointments(id)
      .then(function (response) {
        setAppointments(response);
      });
      getContainers(id)
      .then(function (response){
        if(response) {
          setContainer(response[0]);
      }
      });
      getCompanyFeature()
        .then(function(response){
          setCompanyFeature(response);
        });
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setOrderFormData({ ...order });
  }, [order]);

  const getReceivablesList = () => {
    getReceivables(id)
      .then(function (response) {
        setReceivables(response);
      });
  };

  const getBillsList = () => { 
    getBills(id)
      .then(function(response) {
        setBills(response);
      });
    };

  const getEquipmentList = () => {
    getEquipment(id)
      .then(function (response) {
        setEquipment(response);
      });
  };

  const getDocumentsList = () => {
    getDocuments(id)
      .then(function (response) {
        setDocuments(response);
      });
  };

  const getEnvaseTraceEventsList = () => {
    getEnvaseTraceEvents(id)
      .then(function (response) {
        setTraceEvents(response);
      });
  };

  const getEventsList = () => {
    setEventsInProgress(true);
    getEvents(id)
      .then(function (response) {
        setEvents(response);
        setEventsInProgress(false);
      });
    getBillsList();
    getOrder();
  };

  const getOrderAppointments = () => {
    getIntermodalOrderAppointments(id)
      .then(function (response) {
        setAppointments(response);
      });
  }

  const onTabEvent = (newValue) => {
    if (newValue === 4) {
      getEventsList();
    }
    if (newValue === 6) {
      getNotes();
    }
    if (newValue === 7) {
      getEnvaseTraceEventsList();
    }
    const tempEvent = {
      target: {
        value: newValue
      }
    };
    onChange(tempEvent);
  };

  const handleOpenCreate = () => {
    switch (tabSelected) {
      case 1:
        openEquipmentCreateDialog();
        break;
      case 2:
        openRevenueCreateDialog();
        break;
      case 4:
        addEventsLeg();
        break;
      case 5:
        openPayableCreateDialog();
        break;
      case 6:
        openNotesCreateDialog();
        break;
      case 8:
        openOrderAppointmentCreateDialog();
        break;
      default:
        break;
    }
  };

  const updateOrderFormData = e => {
    const { name, value } = e.target;
    orderFormData[name] = value;
    setOrderFormData({ ...orderFormData });
  };

  const onSave = e => {
    setInProgress(true);
    setAlertSuccess(false);
    formatOrder();
    e.preventDefault();
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await orderUpdate(orderFormData);
    if (result.error) {
      setErrorMessage(result.error);
      setInProgress(false);
      return;
    }
    getOrder();
    setAlertSuccess(true);
    setInProgress(false);
  };

  const openRevenueCreateDialog = () => {
    setRevenueCreateDialogOpen(true);
  };

  const closeRevenueCreateDialog = () => {
    setRevenueCreateDialogOpen(false);
    getReceivablesList();
  };

  const openEquipmentCreateDialog = () => {
    setEquipmentCreateDialogOpen(true);
  };

  const closeEquipmentCreateDialog = () => {
    setEquipmentCreateDialogOpen(false);
    getEquipmentList();
  };

  const addEventsLeg = () => {
    setEventsInProgress(true);
    createIntermodalLeg(id)
      .then(function (response) {
        getEventsList();
      });
  };

  const openNotesCreateDialog = () => {
    setNotesCreateDialogOpen(true);
  };

  const closeNotesCreateDialog = () => {
    setNotesCreateDialogOpen(false);
    getNotes();
  };

  const openPayableCreateDialog = () => {
    setPayableCreateDialogOpen(true);
  };

  const closePayableCreateDialog = (payable) => {
    if (payable.RelatedReceivableChargeTypeID) { 
      payable.IntermodalOrderID = id;
      setPayableFormData(payable);
      setIsPayableRelatedRevenueDialogOpen(true);
    }
    setPayableCreateDialogOpen(false);
    getBillsList();
  };

  const closePayableRelatedRevenueDialog = () => { 
    setIsPayableRelatedRevenueDialogOpen(false);
    setPayableFormData({});
    getReceivablesList();
  };

  const openOrderAppointmentCreateDialog = () => {
    setOrderAppointmentCreateDialogOpen(true);
  };

    const closeOrderAppointmentCreateDialog = () => {
    getOrderAppointments();
    setOrderAppointmentCreateDialogOpen(false);
  };

  const formatOrder = () => {
    orderFormData.SerialNum = orderFormData.SerialNum?.trim() || null;
    orderFormData.BookingNum = orderFormData.BookingNum?.trim() || null;
    orderFormData.Line = orderFormData.Line?.trim() || null;
    orderFormData.HouseBL = orderFormData.HouseBL?.trim() || null;
  };

  return (
    <Container style={{ padding: '0', position: 'relative' }} maxWidth="lg" className="mt-2">
      <Grid container spacing={2} className="mt-2">
        <Grid container item xs={11}>
          <Tabs
            value={tabSelected}
            onChange={(event, newValue) => { onTabEvent(newValue) }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab className={classes.tabMinWidth} value={0} label='Billing' />
            <Tab className={classes.tabMinWidth} value={1} label='Equipment' />
            <Tab className={classes.tabMinWidth} value={2} label='Revenue Items' />
            <Tab className={classes.tabMinWidth} value={3} label='Documents' />
            <Tab className={classes.tabMinWidth} value={4} label='Events' />
            <Tab className={classes.tabMinWidth} value={5} label='Expenses' />
            <Tab className={classes.tabMinWidth} value={6} label='Notes' />
            {(envaseTraceLocationExists || traceEvents.length > 0) && <Tab className={classes.tabMinWidth} value={7} label='Envase Trace' />}
            {companyFeature && companyFeature.IsICTSI && 
            <Tab className={classes.tabMinWidth} value={8} label='Appointments' />
            }
          </Tabs>
        </Grid>
        {
          /* Don't show Add button for Invoiced Orders unless Notes tab OR if Events list is empty */
          (
            (orderFormData.Status !== "Invoiced" && orderFormData.Status !== "Cancelled" && [1, 2, 5, 6].includes(tabSelected)) ||
            ((orderFormData.Status === "Invoiced") && tabSelected === 6) ||
            (orderFormData.Status !== "Invoiced" && orderFormData.Status !== "Cancelled" && (tabSelected === 4) && events.length === 0) ||
            (tabSelected === 8 && orderFormData.IsAppointmentBooked !== true && orderFormData.IsReadyForAppointment)
          ) &&
          
          <Grid container item xs={1}>
            <CoreAddButton handleOpenCreate={handleOpenCreate} />
          </Grid>
        }
      </Grid>
      {tabSelected === 0 &&
        <OrderBillingView
          orderFormData={orderFormData}
          updateOrderFormData={updateOrderFormData}
          onSave={onSave}
          errorMessage={errorMessage}
          alertSuccess={alertSuccess}
          inProgress={inProgress}
        />
      }
      {tabSelected === 1 &&
        <OrderEquipmentListTable
          orderID={id}
          equipment={equipment}
          getEquipment={getEquipmentList}
          setEquipment={setEquipment}
        />
      }
      {tabSelected === 2 &&
        <RevenueListTable
          receivables={receivables}
          getReceivables={getReceivablesList}
          setReceivables={setReceivables}
        />
      }
      {tabSelected === 2 &&
        <RevenueCreateDialog
          originID={id}
          isOpen={isRevenueCreateDialogOpen}
          onClose={closeRevenueCreateDialog}
        />
      }
      {tabSelected === 3 &&
        <DocumentListTable
          orderID={id}
          documents={documents}
          getDocuments={getDocumentsList}
          setDocuments={setDocuments}
        />
      }
      {tabSelected === 4 &&
        <IntermodalEventListTable
          originID={id}
          events={events}
          getEvents={getEventsList}
          setEvents={setEvents}
          getPayables={getBillsList} 
          getReceivables={getReceivablesList}
          inProgress={eventsInProgress}
          setInProgress={setEventsInProgress}
        />
      }

      { tabSelected === 5 &&
        <BillListTable
          bills={bills}
        />
      }
      {tabSelected === 5 &&
        <IntermodalPaymentDeductibleCreateDialog
          isOpen={isPayableCreateDialogOpen}
          onClose={closePayableCreateDialog}
          populatedParams={{ BillDate: today, PersonnelID: null, OrderNumber: orderFormData.OrderNum }}
        />
      }
      { tabSelected === 5 &&
        <PayableRelatedRevenueDialog
          isOpen={isPayableRelatedRevenueDialogOpen}
          onClose={closePayableRelatedRevenueDialog}
          payableFormData={payableFormData}
        />
      }
      {tabSelected === 6 &&
        <NotesList
          notes={notes}
          getNotes={getNotes}
        />
      }
      {tabSelected === 6 &&
        <NoteCreateDialog
          originID={id}
          noteType={noteType}
          isOpen={isNotesCreateDialogOpen}
          onClose={closeNotesCreateDialog}
        />
      }
      {tabSelected === 1 &&
        <OrderEquipmentCreateDialog
          id={id}
          isOpen={isEquipmentCreateDialogOpen}
          onClose={closeEquipmentCreateDialog}
          closeEquipmentCreateDialog={closeEquipmentCreateDialog}
          openOrderEquipmentCreateDialog={openEquipmentCreateDialog}
          getEquipment={getEquipmentList}
        />
      }
      {tabSelected === 2 &&
        <RevenueCreateDialog
          id={id}
          isOpen={isRevenueCreateDialogOpen}
          onClose={closeRevenueCreateDialog}
        />
      }
      {tabSelected === 7 &&
        <OrderEnvaseTraceEventListTable
          traceEvents={traceEvents}
        />
      }
      {tabSelected === 8 &&
        <OrderAppointmentsListTable
          appointments={appointments}
          onAppointmentCancel={getOrderAppointments}
        />
      }
      {tabSelected === 8 &&
        <OrderAppointmentCreateDialog
        isOpen={isOrderAppointmentCreateDialogOpen}
        onClose={closeOrderAppointmentCreateDialog}
        order={order}
        containerNum={container?.UnitNum}
      />
      }
      {(orderFormData.Status === "Cancelled") && <CorePermissionToEditOverlay />}
    </Container>
  );

}

export default OrderTabBar;