import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import AreaTagLineListTable from '../area_tag_line/AreaTagLineListTable';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const bold = {
  fontWeight: '600'
};

const AreaTagEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    areaTagFormData,
    onUpdate,
    errorMessage,
    areaTagFormValidation,
    updateAreaTagFormData,
    inProgress,
    areaTagLines,
    setAreaTagLines,
    getAreaTagLineList,
    openAreaTagLineCreateDialog,
    applyAreaTagsToLocation,
    locationsToApplyCount,
  } = props;

  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>      
        <form onSubmit={onUpdate}>
          <DialogTitle id="form-dialog-title">Edit Area Tag or Modify Area Tag Lines</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className="mt-1">            
              <Grid item md={12} xs={12}>
                  <CoreTextField
                    autoFocus={true}
                    name="Name"
                    label="Name"
                    value={areaTagFormData.Name}
                    onChange={e => updateAreaTagFormData(e)}
                    isRequired={true}
                    validationError={areaTagFormValidation.Name}
                  />
              </Grid>           
              {errorMessage && <Grid item md={12} xs={12} className="mt-3">
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Grid>}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={inProgress} color="primary">
              Close
            </Button>
            <Button type="submit" disabled={inProgress} color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
        {inProgress && <LinearProgress />}
        <Paper variant="outlined" square className="mt-1">
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom className="mb-2">
              {"Area Tag Lines"}
              <Button title={`Clicking this will apply area tag ${areaTagFormData.Name} to locations that match the area tag line properties.`} 
                variant="contained" color="primary" disabled={locationsToApplyCount < 1} style={{float: 'right'}} onClick={applyAreaTagsToLocation}>
                Apply Area Tag to Locations
              </Button>
            </Typography>
            <AreaTagLineListTable 
              areaTagLines={areaTagLines}
              getAreaTagLines={getAreaTagLineList}
              setAreaTagLines={setAreaTagLines}
              openAreaTagLineCreateDialog={openAreaTagLineCreateDialog}
            />
          </Container>
        </Paper>     
      </Dialog>
    </>
  );

}

export default AreaTagEditDialogContent;