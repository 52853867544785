import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { getMainBranchID } from './ConfigurationService';
import { get as getBranch, update as updateBranch } from '../branch/BranchService';
import CoreTextField from '../core/CoreTextField';
import CountryField from '../country/CountryField';
import CorePhoneField from '../core/CorePhoneField';
import ProvinceField from '../province/ProvinceField';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import ConfigurationViewBranchSectionLogo from './ConfigurationViewBranchSectionLogo';
import Divider from '@material-ui/core/Divider';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};

const ConfigurationViewBranchSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;

  const [branchFormdataLoaded, setBranchFormDataLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [branchFormData, setBranchFormData] = useState({});
  const [branchFormValidation, setBranchFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const mainBranchID = await getMainBranchID();
    const branch = await getBranch(mainBranchID);
    setBranchFormData(branch);
    setInProgress(false);
    setBranchFormDataLoaded(true);
  };

  useEffect(() => {
    setBranchFormValidation(buildValidation(branchFormData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchFormData]);

  const updateBranchFormData = e => {
    const { name, value } = e.target;
    branchFormData[name] = value;
    setBranchFormData({ ...branchFormData });
  };

  const formatBranch = () => {
    branchFormData.Phone = branchFormData.Phone.replace(/[-+=() ]/g,'');
    setBranchFormData({ ...branchFormData });
  };


  const onUpdate = e => {
    setInProgress(true);
    setAlertSuccess(false);
    formatBranch();
    e.preventDefault();
    if (!new Validation().isValidObject(branchFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await updateBranch(branchFormData);
    setInProgress(false);
    if (result.errors.Error) {
      setErrorMessage(result.errors.Error);
      return;
    }
    setAlertSuccess(true);
    initialize();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      {branchFormdataLoaded &&
        <Paper variant="outlined" square className="mt-1">
          {isOpen &&
            <Container maxWidth="lg" className="mt-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Branch Details"}
                <span style={rightFloat}>
                  <CoreExpandLessButton
                    toggleIsOpen={toggleIsOpen}
                    ariaLabel="Hide Operations">
                  </CoreExpandLessButton>
                </span>
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {"The branch details are where you will update your company information. This information is what will display in any of the outbound documents that are generated from the system."}
                <span style={rightFloat}>
                </span>
              </Typography>
              <Grid container spacing={2} className="mt-2">
                <Grid md={2} sm item>
                  <ConfigurationViewBranchSectionLogo
                    branchID={branchFormData.BranchID}>
                  </ConfigurationViewBranchSectionLogo>
                </Grid>
                <Grid container spacing={2} xs={10}>
                  <Grid item md={6} xs={12}>
                    <CoreTextField
                      name="Name"
                      label="Name"
                      value={branchFormData.Name}
                      onChange={e => updateBranchFormData(e)}
                      validationError={branchFormValidation.Name}
                      autoFocus={true}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CorePhoneField
                      name="Phone"
                      label="Phone"
                      value={branchFormData.Phone}
                      onChange={e => updateBranchFormData(e)}
                    />
                  </Grid>                  
                  {branchFormData.Country !== "Philippines" && <>
                    <Grid item md={4} xs={12}>
                      <CoreTextField
                        name="Address"
                        label="Address"
                        value={branchFormData.Address}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="City"
                        label="City"
                        value={branchFormData.City}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <ProvinceField
                        name="Province"
                        label="State / Province"
                        value={branchFormData.Province}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="PostalCode"
                        label="ZIP / Postal Code"
                        value={branchFormData.PostalCode}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CountryField
                        name="Country"
                        label="Country"
                        isRequired={false}
                        value={branchFormData.Country}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                  </>}
                  {branchFormData.Country === "Philippines" && <>
                    <Grid item md={6} xs={12}>
                      <CoreTextField
                        name="Address"
                        label="Address"
                        value={branchFormData.Address}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="County"
                        label="Barangay"
                        value={branchFormData.County}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="District"
                        label="District"
                        value={branchFormData.District}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="PostalCode"
                        label="ZIP / Postal Code"
                        value={branchFormData.PostalCode}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CoreTextField
                        name="City"
                        label="City"
                        value={branchFormData.City}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <ProvinceField
                        name="Province"
                        label="State / Province"
                        value={branchFormData.Province}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CountryField
                        name="Country"
                        label="Country"
                        isRequired={false}
                        value={branchFormData.Country}
                        onChange={e => updateBranchFormData(e)}
                      />
                    </Grid>
                  </>}
                  <Grid item md={2} xs={12}>
                    <CoreTextField
                      name="DOTNum"
                      label="DOT"
                      maxLength={8}
                      value={branchFormData.DOTNum}
                      onChange={e => updateBranchFormData(e)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CoreTextField
                      name="SCAC"
                      label="SCAC"
                      maxLength={4}
                      value={branchFormData.SCAC}
                      onChange={e => updateBranchFormData(e)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CoreTextField
                      name="DocketNum"
                      label="MC"
                      maxLength={10}
                      value={branchFormData.DocketNum}
                      onChange={e => updateBranchFormData(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider className="mt-4"/>
            </Container>
          }
          {isOpen &&
            <DialogActions style={justifyContent} className="mb-1">
              <span>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {alertSuccess && <Alert severity="success">Branch information has been saved.</Alert>}
              </span>
              <span>
              <Button
                color="primary"
                component="label"
                onClick ={async() =>{await initialize()}}>
                Cancel
              </Button>
                <Button  style={{flex: 1, backgroundColor: '#6639BF', color: 'white', marginRight:'20px'}} variant="contained" onClick={e => onUpdate(e)}>
                  Save Changes
                </Button>
              </span>
            </DialogActions>
          }
          {!isOpen &&
            <Container maxWidth="lg" className="mt-2 mb-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Branch Details"}
                <span style={rightFloat}>
                  <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Operations"></CoreExpandMoreButton>
                </span>
              </Typography>
            </Container>
          }
        </Paper>
      }
    </div>
  );

}

const buildValidation = (branchFormData) => {
  const {
    Name,
  } = branchFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
  };
};

export default ConfigurationViewBranchSection;