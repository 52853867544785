import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  widthSize: {
    width: '100%',
  },
}));

const EquipmentUnitTypeDropdownField = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    disabled,
    variant,
    size
  } = props;
  const classes = useStyles();
  
  const [menuValue, setMenuValue] = useState('');

  useEffect(() => {
    setMenuValue(value);
  }, [value]);

  const handleChange = (event) => {
    const dispatchEvent = {
        target: {
          name: name ? name : 'UnitType',
          value: event.target.value
        }
      };
    onChange(dispatchEvent);
  };

  return (
    <FormControl className={classes.widthSize} variant={variant} size={size}>
      <InputLabel variant={variant}>{label ? label : "Unit Type"}</InputLabel>
      <Select       
        value={menuValue}
        onChange={(e, newValue) => { setMenuValue(e.target.value); handleChange(e) }}
        disabled={disabled}             
      >
        <MenuItem value={''}>&nbsp;</MenuItem>
        <MenuItem value={3}>Chassis</MenuItem>
        <MenuItem value={2}>Container</MenuItem>
        <MenuItem value={0}>Tractor</MenuItem>
        <MenuItem value={1}>Trailer</MenuItem>
      </Select>
    </FormControl>
  );
};

export default EquipmentUnitTypeDropdownField;