import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './ReceivableChargeTypeService';
import ReceivableChargeTypeCreateDialogContent from './ReceivableChargeTypeCreateDialogContent';

const ReceivableChargeTypeCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    name: '',
    descriptionDefault: '',
    unitsDefaultType: 0,
    unitsDefaultValue: 1,
    unitRateDefaultType: 0,
    unitRateDefaultValue: 0,
    isFSC: 0,
  };

  const [receivableChargeTypeFormData, setReceivableChargeTypeFormData] = useState(initialState);
  const [receivableChargeTypeFormValidation, setReceivableChargeTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setReceivableChargeTypeFormValidation(buildValidation(receivableChargeTypeFormData));
    }
  }, [receivableChargeTypeFormData, isOpen]);

  const updateReceivableChargeTypeFormData = e => {
    const { name, value } = e.target;
    receivableChargeTypeFormData[name] = value;
    setReceivableChargeTypeFormData({ ...receivableChargeTypeFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(receivableChargeTypeFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(receivableChargeTypeFormData);
    setInProgress(false);
    if (result.errors.length) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setReceivableChargeTypeFormData(initialState);
    onClose();
  };

  return (
    isOpen &&
    <ReceivableChargeTypeCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      receivableChargeTypeFormData={receivableChargeTypeFormData}
      receivableChargeTypeFormValidation={receivableChargeTypeFormValidation}
      updateReceivableChargeTypeFormData={updateReceivableChargeTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (receivableChargeTypeFormData) => {
  const {
    name,
    descriptionDefault,
    unitsDefaultValue,
    unitRateDefaultValue,
    isFSC,

  } = receivableChargeTypeFormData || {};
  const validation = new Validation();
  return {
    name: validation.isEmpty(name),
    descriptionDefault: validation.isEmpty(descriptionDefault),
    unitsDefaultValue: validation.isNumber(unitsDefaultValue),
    unitRateDefaultValue: validation.isNumber(unitRateDefaultValue),
    isFSC: validation.isNumber(isFSC),
  };
};

export default ReceivableChargeTypeCreateDialog;