import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CoreExitButton from '../core/CoreExitButton';
import Container from '@material-ui/core/Container';
import { get, getContacts, getNotes } from './CustomerService';
import CustomerViewSummaryInfoSection from './CustomerViewSummaryInfoSection';
import CustomerViewSummaryContactSection from './CustomerViewSummaryContactSection';
import CustomerViewSummaryNoteSection from './CustomerViewSummaryNoteSection';
import CoreSummaryFooter from '../core/CoreSummaryFooter';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  paper: {
    width:400
  }
}));

const CustomerViewSummary = (props) => {
  const classes = useStyles();
  const {
    customerID,
    isOpen,
    onClose,
  } = props;
  let history = useHistory();

  const [inProgress, setInProgress] = useState(true);
  const [customer, setCustomer] = useState({});
  const [notes, setCustomerNotes] = useState([]);
  const [contacts, setCustomerContacts] = useState([]);

  useEffect(() => {
    setInProgress(true);
    setCustomer({});
    setCustomerContacts([]);
    setCustomerNotes([]);
    if (isOpen) {
      get(customerID).then(function(response) {
        setCustomer(response);
        setInProgress(false);
      });
      getContacts(customerID).then(function(response) {
        setCustomerContacts(response);
      });
      getNotes(customerID).then(function(response) {
        setCustomerNotes(response);
      });
    }
  }, [isOpen, customerID]);

  const editCustomer = () => {
    history.push({
      pathname: '/spark/customer/edit/' + customer.ID,
      state: customer
    });
  };

  const openCustomer = () => {
    history.push('/spark/customer/view/' + customer.ID);
  };

  const quickContact = (contacts) => {
    let quickContact = {}
    for (let i = 0; i < contacts.length; i++) {
      if(contacts[i].isFav) {
        quickContact = contacts[i]
      } else {
        quickContact = contacts[0]
      }
    }
    return quickContact
  }

  return (
    <>
      <Drawer 
        anchor={'right'} 
        ModalProps={{onBackdropClick: onClose }}
        open={isOpen} 
        classes={{ paper: classes.paper }}>  
        <CoreSummaryHeader
          title={"Summary View"}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
        />
        {inProgress && <LinearProgress />}
        <Container maxWidth="lg" className="mt-1">
          <CustomerViewSummaryInfoSection
            customer={customer}
          />
          <CustomerViewSummaryContactSection
            contact={quickContact(contacts)}
          />
          <CustomerViewSummaryNoteSection
            note={notes[0] ?? {}}
          />
        </Container>
        {!inProgress && <CoreSummaryFooter
          editRecord={editCustomer}
          openRecord={openCustomer}
          disableEdit={false}
        />}
      </Drawer>
    </>    
  );

}

export default CustomerViewSummary;