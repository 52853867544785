import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';

const uri = 'Mileage/';

export function useMileageGetGeocodeRequest(location) {
  const [response, setResponse] = useState({
    'lat': 0,
    'long': 0
  });

  const fetch = useCallback(() => {
    async function fetchData() {
      try {
        const httpService = new HttpService();
        const result = await httpService
          .get(uri + 'GetGeocode?' + 
            'address=' + location.Address + 
            '&city=' + location.City + 
            '&province=' + location.Province + 
            '&country=' + location.Country + 
            '&postalCode=' + location.PostalCode);
        const coordinates = result.substring(4).split(',');
        setResponse({ 
          'lat': parseFloat(coordinates[0]), 
          'lng': parseFloat(coordinates[1])
        });
      } catch (Exception) {
        return;
      }
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    if (
      location.Address && 
      location.City &&
      location.Province &&
      location.PostalCode
    ) {
      fetch();
    }
  }, [location, fetch]);

  return {
    data: response,
    refetch: fetch
  };
}