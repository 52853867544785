import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Task/' + token + '/';

export async function getNotificationsList() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAllTasks');
  return result.data;
};

export async function completeTask(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `${id}/CompleteTask`);
  return result.data;
};

export async function uncompleteTask(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `${id}/UncompleteTask`);
  return result.data;
};

export async function completeAllTasks() {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `CompleteAllTasks`);
  return result.data;
};

export async function uncompleteAllTasks() {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + `UncompleteAllTasks`);
  return result.data;
};
