import React, { useState, useEffect } from 'react';
import Validation from '../validation';

import PointToPointTemplateEditDialogContent from './PointToPointTemplateEditDialogContent'
import { Update } from './PointToPointTemplateService';

const PointToPointTemplateEditDialog = (props) => { 
  const {
    isOpen,
    onClose,
    templateFormData,
    setTemplateFormData,
    pointToPointTemplateLines,
    getPointToPointTemplateLines,
    setPointToPointTemplateLines
  } = props;

  const [rateTemplateFormValidation, setRateTemplateFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => { 
    if (isOpen) { 
      setRateTemplateFormValidation(buildValidation(templateFormData));
    }
  }, [templateFormData, isOpen]);

  const updateTemplateFormData = e => { 
    const { name, value } = e.target;
    templateFormData[name] = value;
    setTemplateFormData({ ...templateFormData });
  };

  const onUpdate = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(rateTemplateFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => { 
    setErrorMessage('');
    const result = await Update(templateFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    props.onClose();
  };

  return ( 
    <>
    {isOpen &&
    <PointToPointTemplateEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      rateTemplateFormData={templateFormData}
      updateRateTemplateFormData={updateTemplateFormData}
      rateTemplateFormValidation={rateTemplateFormValidation}
      templateLines={pointToPointTemplateLines}
      getTemplateLines={getPointToPointTemplateLines}
      setTemplateLines={setPointToPointTemplateLines}
      errorMessage={errorMessage}
      inProgress={inProgress}
    />}
    </>
  )
};

const buildValidation = (templateFormData) => {
  const {
    Name,
  } = templateFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
  };
};

export default PointToPointTemplateEditDialog;