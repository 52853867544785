import React from 'react';

import CoreWarningDialogContent from '../core/CoreWarningDialogContent';

const CoreWarningDialog = (props) => {
  const {
    isOpen,
    onConfirm,
    onClose
  } = props;

  return (
    isOpen &&
    <CoreWarningDialogContent
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
}

export default CoreWarningDialog;