import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';

import { getForUpdate, Update as EquipmentUpdate } from './EquipmentService';
import Validation from '../validation';

import CorePageHeader from '../core/CorePageHeader';
import CoreExitButton from '../core/CoreExitButton';
import EquipmentEditContent from './EquipmentEditContent';
import LinearProgress from '@material-ui/core/LinearProgress';

const EquipmentEdit = () => {
  const { id } = useParams();
  let history = useHistory();

  const [EquipmentFormData, setEquipmentFormData] = useState({
    UnitNum: '',
    UnitType: 0,
    TractorType: 3,
    BranchID: 0,
    DefaultClassID: null,
    EquipmentType: 'Power Only',
    Manufacturer: '',
    Model: '',
    ModelYear: '',
    LicenseNum: '',
    LicenseState: '',
    VINNum: '',
    ACEID: '',
    ACEExpiry: null,
    ACEExpiryRemindTaskID: 0,
    ACEEquipType: null,
    ACETransponder: '',
    ACIID: '',
    ACIExpiry: null,
    ACIExpiryRemindTaskID: 0,
    ACIEquipType: null,
    ACITransponder: '',
    RegistrationNum: '',
    RegistrationExpiry: null,
    RegistrationRemindTaskID: null,
    RevenuePercentage: 0,
    WeightCapacity: 0,
    WeightEmpty: 0,
    OutsideLengthFt: 0,
    OutsideLengthInch: 0,
    OutsideWidthFt: 0,
    OutsideWidthInch: 0,
    OutsideHeightFt: 0,
    OutsideHeightInch: 0,
    InsideLengthFt: 0,
    InsideLengthInch: 0,
    InsideWidthFt: 0,
    InsideWidthInch: 0,
    InsideHeightFt: 0,
    InsideHeightInch: 0,
    OwnerType: 0,
    OwnerCarrierID: null,
    ServiceStart: null,
    ServiceEnd: null,
    PurchasePrice: 0,
    PurchasePriceTax1: 0,
    PurchasePriceTaxTypeID1: 0,
    PurchasePriceTax2: 0,
    PurchasePriceTaxTypeID2: 0,
    IsLeased: false,
    LeaseCompany: '',
    LeaseEnd: null,
    LeaseEndRemindTaskID: null,
    LeaseRateMonthly: 0,
    LeaseRateMonthlyTax1: 0,
    LeaseRateMonthlyTaxTypeID1: 0,
    LeaseRateMonthlyTax2: 0,
    LeaseRateMonthlyTaxTypeID2: 0,
    LeaseBuyout: 0,
    HasReefer: false,
    ReeferManuf: '',
    ReeferModel: '',
    ReeferModelYear: '',
    ReeferVIN: '',
    Axles: 0,
    FuelType: 1,
    SafetyInspectionNum: '',
    SafetyInspectionExpiry: null,
    SafetyRemindTaskID: null,
    IFTADecalNum: '',
    IFTADecalExpiry: null,
    IFTADecalTaskID: null,
    ContractMilesScheduleID: null,
    Obsolete: false,
    UserDef1: '',
    UserDef2: '',
    UserDef3: '',
    UserDef4: '',
    BorderConnectEquipmentType: ''
  });
  const [inProgress, setInProgress] = useState(false);
  const [equipmentFormDataLoaded, setEquipmentFormDataLoaded] = useState(false);
  const [EquipmentFormValidation, setEquipmentFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    function convertTaskID(value) {
      if (value > 0) {
        value = 1;
      }
      return value;
    };
    getForUpdate(id)
      .then(function(response) {
        response.RegistrationRemindTaskID = convertTaskID(response.RegistrationRemindTaskID);
        response.SafetyRemindTaskID = convertTaskID(response.SafetyRemindTaskID);
        response.IFTADecalTaskID = convertTaskID(response.IFTADecalTaskID);
        response.LeaseEndRemindTaskID = convertTaskID(response.LeaseEndRemindTaskID);
        response.UnitType = parseInt(response.UnitType);
        setEquipmentFormData({ ...response });
        setEquipmentFormDataLoaded(true);
    });
  }, [id]);

  useEffect(() => {
      setEquipmentFormValidation(buildValidation(EquipmentFormData));
  }, [EquipmentFormData]);

  const UpdateEquipmentFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    if (e.target.name === "OwnerType" && e.target.value !== EquipmentFormData.OwnerType) {
      EquipmentFormData.OwnerCarrierID = null;
      EquipmentFormData.OwnerCarrierName = "";
    }
    if (e.target.name === 'UnitType' && e.target.value === 2) {
      EquipmentFormData.OwnerType = 2;
      EquipmentFormData.Manufacturer = '';
      EquipmentFormData.Model = '';
      EquipmentFormData.ModelYear = '';
      EquipmentFormData.Axles = 0;
      EquipmentFormData.LicenseNum = '';
      EquipmentFormData.LicenseState = '';
      EquipmentFormData.VINNum = '';
    }
    EquipmentFormData[name] = value;
    EquipmentFormData[nameData] = valueData;
    setEquipmentFormData({ ...EquipmentFormData });
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(EquipmentFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    setInProgress(true);
    const result = await EquipmentUpdate(EquipmentFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    history.push('/spark/equipment/view/' + id);
  };

  const onClose = () => {
    history.push('/spark/equipment/view/' + id);
  };

  return (
    <div>
      <CorePageHeader
        title={"Edit Equipment " + EquipmentFormData.UnitNum}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {equipmentFormDataLoaded &&
        <EquipmentEditContent
          onClose={onClose}
          onUpdate={onUpdate}
          errorMessage={errorMessage}
          EquipmentFormData={EquipmentFormData}
          EquipmentFormValidation={EquipmentFormValidation}
          UpdateEquipmentFormData={UpdateEquipmentFormData}
          inProgress={inProgress}
        />}
      </Container>
    </div>
  );
}

const buildValidation = (EquipmentFormData) => {
  const { 
    UnitNum,
    UnitType,
    TractorType,
    OwnerType,
    ServiceStart
  } = EquipmentFormData || {};
  const validation = new Validation();
  return {
    UnitNum: validation.isEmpty(UnitNum),
    UnitType: validation.isNumber(UnitType),
    TractorType: validation.isNumber(TractorType),
    OwnerType: validation.isNumber(OwnerType),
    ServiceStart: validation.isEmpty(ServiceStart)
  };
};

export default EquipmentEdit;