import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import IntermodalInvoiceListTable from './IntermodalInvoiceListTable';

const IntermodalInvoiceList = () => {

  return (
    <div>
      <CorePageHeader
        title="Invoicing" />
      <Container maxWidth="false" className="mt-4">
        <IntermodalInvoiceListTable />
      </Container>
    </div>
  );

}

export default IntermodalInvoiceList;