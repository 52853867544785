import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOnVesselDispatchSummary, getOnHoldDispatchSummary, getDemurrageDispatchSummary, getPerDiemDispatchSummary } from './DashboardService';
import Constants from './DashboardConstants.json';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'left',
    minHeight: '226px',
  },
  stats: {
    padding: theme.spacing(2)
  },
  count: {
    fontWeight: '600',
    alignSelf: 'center',
  },
  countLink: {
    fontWeight: '600',
    color: '#3f51b5',
    alignSelf: 'center',
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  bold: {
    fontWeight: 'bold'
  },
}));

const DashboardDispatchItemOnly = (props) => {
  let history = useHistory();
  const { 
    summaryType,
    filterDataAsParams,
    filterData,
  } = props;
  const [loading, setLoading] = useState(true)
  const [statsSummary, setStatsSummary] = useState([]);

  useEffect(() => {
    async function getOnVessel() { 
      setLoading(true);
      const result = await getOnVesselDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getOnHold() { 
      setLoading(true);
      const result = await getOnHoldDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getDemurrage() { 
      setLoading(true);
      const result = await getDemurrageDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getPerDiem() { 
      setLoading(true);
      const result = await getPerDiemDispatchSummary(filterData);
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    if(summaryType === 'ON_VESSEL') {
      getOnVessel();
    } else if(summaryType === 'ON_HOLD') {
      getOnHold();
    } else if(summaryType === 'DEMURRAGE') {
      getDemurrage();
    } else if(summaryType === 'PER_DIEM') {
      getPerDiem();
    }
  }, [summaryType, filterData]);

  const classes = useStyles();

  const tally = (total) => {
    return  (
      (!loading) ? total : ''
    );
  };

  const navigate = (url, count) => {
    if (url && count) {
      history.push(url);
    } else {
      return;
    }
  }

  const getCountClass = (url, count) => {
    if (url && count) {
      return classes.countLink
    } else {
      return classes.count
    }
  }

  const getCursor = (url, count) => {
    if (url && count) {
      return classes.cursorPointer
    } else {
      return ''
    }
  }

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      <Grid className={classes.stats}>
        <Grid item sm 
          onClick={ () => navigate(Constants.DISPATCH_VIEW[summaryType].STATUS[0].url + filterDataAsParams,
            tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[0].key])
          )}
          className={ getCursor(Constants.DISPATCH_VIEW[summaryType].STATUS[0].url, 
            tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[0].key])
          )}
        >
          <Typography variant="subtitle1" className={classes.bold}>
            {Constants.DISPATCH_VIEW[summaryType].TITLE}
          </Typography>
          <Typography variant="h3">
            <div className={
              getCountClass(Constants.DISPATCH_VIEW[summaryType].STATUS[0].url,
              tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[0].key])
            )}>
              {loading && <CircularProgress />}
              {tally(statsSummary[Constants.DISPATCH_VIEW[summaryType].STATUS[0].key])}
            </div>
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{fontSize: '12px'}}>
            {Constants.DISPATCH_VIEW[summaryType].STATUS[0].label}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

}

DashboardDispatchItemOnly.propTypes = {
  summaryType: PropTypes.string
};

DashboardDispatchItemOnly.defaultProps = {
  summaryType: ""
};

export default DashboardDispatchItemOnly;
