const ItineraryEventColumnStatusColor = (status) => {
  switch (status) {
    case "Completed":
      return '#81C784';
    case "Pending":
      return '#FFB74D';
    case "Ready":
      return '#81C784';
    case "In Progress":
      return '#FFD10A';
    case "Dispatched":
      return '#73AFE5';
    case "Dropped":
      return '#38A4D8';
    case "Last Free Day":
      return '#E57373';
    case "Rejected":
      return '#FF0000';
    default:
      return '';
  }
}

export default ItineraryEventColumnStatusColor;
