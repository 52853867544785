import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getForSpinnerCompare } from './CustomerService';
import CustomerCreateDialog from './CustomerCreateDialog';

const CustomerField = (props) => {

  const {
    label,    
    value,
    name,
    nameData,
    valueData,
    customerForEdit,
    isRequired,
    validationError,
    onChange,
    isDisabled,
    ...rest
  } = props;

  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [persistedCustomerName, setPersistedCustomerName] = useState('');
  const [showAddButton, setShowAddButton] = useState(false);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);

  useEffect(() => {
    async function getCustomerList() { 
      const result = await getForSpinnerCompare(customerForEdit);
      setCustomerList(result);
      setIsLoading(false);
    }
    getCustomerList();
  }, [customerForEdit]);

  async function getCustomerListForSpinner(compareValue) { 
    const result = await getForSpinnerCompare(compareValue);
    setCustomerList(result);
  }

  const onCustomerSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.ID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.InternalName === value) {
      return option.InternalName;
    }  
  };

  const getDefaultValue = () => {
    const customer = customerList.find(customer => customer.ID === valueData);
    if (customer){ 
      return customer.InternalName;
    }
    return '';
  }

  const openCustomerDialog = () => { 
    setIsCustomerDialogOpen(true);
  };

  const getShowAddButton = (event, inputValue) => {
    const obj = customerList.find(c => c.InternalName.toLowerCase() === inputValue.toLowerCase());
    if (inputValue && obj === undefined) {
      setPersistedCustomerName(inputValue);
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  };

  const closeCustomerDialog = async (responseID, customerName) => {
    if (!isNaN(responseID)) {
      setIsLoading(true);
      const result = await getForSpinnerCompare(customerName);
      setCustomerList(result);
      const obj = result.find(c => c.ID === responseID);
      onCustomerSelected(obj);
      setIsLoading(false);
    }
    setIsCustomerDialogOpen(false);
  };

  return (
    <>
      {!isLoading &&
        <CoreAutocompleteField
          label={label}
          value={value}
          name={name}
          isRequired={isRequired}
          validationError={validationError}
          onChange={onCustomerSelected}
          getDefaultValue={getDefaultValue}
          getOptionSelected={getOptionSelected}
          options={customerList}
          openCreateDialogOpen={openCustomerDialog}
          showAddButton={showAddButton}
          getShowAddButton={getShowAddButton}
          getList={getCustomerListForSpinner}
          isDisabled={isDisabled}
          {...rest}
        />
      }
      <CustomerCreateDialog
        isOpen={isCustomerDialogOpen}
        onClose={closeCustomerDialog}
        persistedCustomerName={persistedCustomerName}
      />
    </>
  );

}

CustomerField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customerForEdit: PropTypes.string
};

export default CustomerField;