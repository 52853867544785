import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CoreAlertDialog from './CoreAlertDialog';

const useStyles = makeStyles(() => ({
    iconText: {
      fontSize: '0.875rem',
    },
}));

const ContactListColumnFavoriteButton = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    currentFavorite=0, //This determines if a contact is being passed
    contactID
  } = props;

  const [alertOpen, setAlertOpen] = useState(false);

  const classes = useStyles();
  
  const handleClick = () => {
    var newValue = value;
    if (newValue === null) {
      newValue = false;
    }

    if (currentFavorite && currentFavorite !== contactID && newValue === false) {
      setAlertOpen(true);
    } else {
      updateChange();
    }
  };

  const updateChange = () => {
    const dispatchEvent = {
      target: {
        name,
        value: !value
      }
    };
    onChange(dispatchEvent);
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const onConfirm = () => { 
    updateChange();
    handleAlertClose();
  };

  return (
    <>
      <IconButton onClick={() => handleClick()}>
        { value ? <StarIcon/> : <StarBorderIcon/> }
        <span className={classes.iconText}>{label}</span>
      </IconButton>
      <CoreAlertDialog
        alertMessage={
          <p>
            Do you want to change the preferred contact to this one?
          </p>
        }
        alertOpen={alertOpen}
        onConfirm={onConfirm}
        handleAlertClose={handleAlertClose}
      />
    </>
  );
}

ContactListColumnFavoriteButton.propTypes = {
  value: PropTypes.bool,
  currentFavorid: PropTypes.number,
  contactID: PropTypes.number,
};

export default ContactListColumnFavoriteButton;