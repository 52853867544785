import Avatar from '@material-ui/core/Avatar';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getPersonnelID, logout } from '../account/AccountService';
import { getIsOwner } from '../admin/AdminService';
import { getFull } from '../personnel/PersonnelService';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer'
  }
}));

const NavigationToolbarButtonAvatar = () => {
  const classes = useStyles();
  let history = useHistory();

  const [initials, setInitials] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    getPersonnel();
    checkOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkOwner  = async() => {
    const response = await getIsOwner();
    setIsOwner(response);
  };

  const getPersonnel = async() => {
    const personnelID = await getPersonnelID();
    const personnel = await getFull(personnelID);
    setInitials(personnel.FirstName[0]);
    if (personnel.LastName){
      setInitials(personnel.FirstName[0] + personnel.LastName[0]);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async() => {
    await logout();
    localStorage.removeItem('token');
    window.Intercom('shutdown');
    history.push('/login');
  };

  const handleSubscriptionManagement = () => {
    history.push('/spark/subscription-management');
  };

  return (
    <div className={classes.avatar}>
      <Avatar onClick={handleClick}>
        {initials}
      </Avatar>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}>
        {
          isOwner && 
          <MenuItem name="navigation[subscription_management]" onClick={handleSubscriptionManagement}>
            Subscription Management&nbsp;&nbsp;
          </MenuItem>
        }
        <MenuItem name="navigation[logout]" onClick={handleLogout}>
          Logout&nbsp;&nbsp;
        </MenuItem>
      </Menu>
    </div>
  );

}

export default NavigationToolbarButtonAvatar;