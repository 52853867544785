import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import IconButton from '@material-ui/core/IconButton';
import IconLocationLight from '../icon/IconLocationLight';

const LocationAreaTagList = (props) => {
  const {
    areaTags,
  } = props;

  return (
    <Container style={{ minHeight: '150px' }} maxWidth="lg" className="munaGrid" component={Paper}>
      {areaTags !== null && <Grid container spacing={1}>
        {areaTags.map(
          (areaTag) =>
            areaTag.LocationAreaTags[0].ID && (
              <Grid item xs={3}>                
                <CoreTextDisplayField
                  value={<>
                    <IconButton style={{pointerEvents: 'none'}}>
                      {<IconLocationLight />}
                    </IconButton>
                    {areaTag.Name}
                  </>}
                />
              </Grid>
            )
        )}
      </Grid>}
    </Container>
  );
}

export default LocationAreaTagList;