import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';
import AutomaticPayableEditDialog from './AutomaticPayableEditDialog';
const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const AutomaticPayableColumnEditButton = (props) => { 
  const { 
    automaticPayableID,
    getAutomaticPayables
  } = props;
  const classes = useStyles();
  const [isAutomaticPayableEditModalOpen, setAutomaticPayableEditModalOpen] = useState(false);

  const openAutomaticPayableEditModal = () => { 
    setAutomaticPayableEditModalOpen(true);
  };

  const closeAutomaticPayableEditModal = () => { 
    setAutomaticPayableEditModalOpen(false);
    getAutomaticPayables();
  };

  return ( 
    <>
      <IconButton className={classes.iconText} onClick={openAutomaticPayableEditModal}>
        <EditIcon/>&nbsp;
        <span>EDIT</span>
      </IconButton>
      <AutomaticPayableEditDialog
        defaultPayableChargeTypeID={automaticPayableID}
        isOpen={isAutomaticPayableEditModalOpen}
        onClose={closeAutomaticPayableEditModal}
      />
    </>
  );
};

export default AutomaticPayableColumnEditButton;