import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import paymentProcessingImg from '../assets/feepay_payment_processing.jpg';

const useStyles = makeStyles(() => ({
    imgStyle: {
      display: 'block'
    },
    buttonStyle: { 
      color:'white', 
      backgroundColor:'#6639BF',
      '&:hover': {
        backgroundColor: '#6639BF',
      },
      marginRight:'5px',
      variant:'outlined'
    }
  }));

  const FeePayShoppingCartPaymentResultProcessing = () => { 
    let history = useHistory();
    const classes = useStyles();

    const handleGoToHome = () => { 
      history.push('/spark/dashboard');
    };

    return ( 
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <h3>Processing Payment</h3>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.imgStyle} >
            <img src={paymentProcessingImg} alt="Logo"/>
            </Box>
          </Grid>
          <Grid item xs={12} tyle={{textAlign: 'center'}}>
            <p>Kindly keep patience we are processing your payment. In the mean time you can browse around</p>
            <Button className={classes.buttonStyle} onClick={() => handleGoToHome()}>
              Go to Home
            </Button>
          </Grid>
        </Grid>
      </>
    )
  };

  export default FeePayShoppingCartPaymentResultProcessing;