import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
}));

const CorePageHeader = (props) => {
  const classes = useStyles();

  const {
    title,
    isInverted,
    createButton
  } = props;

  return (
    <Paper 
      variant="outlined" 
      style={{height: '79px'}}
      className={clsx({
        [classes.purpleBackground]: isInverted
      })}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Typography variant="h6" gutterBottom>
          { title }
        </Typography>
        {createButton}
      </Container>
    </Paper>
  );
}

CorePageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  createButton: PropTypes.element,
  isInverted: PropTypes.bool
};

export default CorePageHeader;