import React, {useState, useEffect} from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import IconPersonnelLight from '../icon/IconPersonnelLight';
import IconOrderLight from '../icon/IconOrderLight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconNavigationToolbarButton from './NavigationToolbarButton';
import { getNotificationsList } from '../notifications/NotificationsService';

const useStyles = makeStyles((theme) => ({
  purpleIcon: {
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);',
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  popoverContainer: {
    padding: '12px'
  },
  seeAll: {
    paddingLeft: '12px'
  },
  unseenGridItem: {
    backgroundColor: 'rgba(0, 230, 118, 0.08)',
    margin: '10px 0 0 0',
    padding: '10px',
    cursor: 'pointer'
  },
  message: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  date: {
    paddingTop: '10px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  iconContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: "8px",
    height: '40px',
    width: '40px',
    textAlign: 'center',
  },
  greenCircle: {
    position: 'absolute',
    top: '-2px',
    right: '-2px',
    height: '8px',
    width: '8px',
    borderRadius: '4px',
    backgroundColor: '#00A152',
  },
  statusIcon: {
    paddingTop: '5px',
    filter: 'invert(50%) sepia(93%) saturate(5839%) hue-rotate(140deg) brightness(101%) contrast(101%)',
    '& svg': {
      fontSize: '26px'
    }
  },
  truncate: {
    width: '210px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noResults: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '20px 0 10px 0;',
    flexGrow: 1,
    textAlign: 'center'
  }
}));

const NavigationToolbarButtonNotification = ({ history }) => {
  const location = useLocation();
  const classes = useStyles();

  const [notificationsList, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unseenNotices, setUnseenNotices] = useState(0);

  useEffect(() => {
    // Listen to location to update counts
    async function getNotifications() { 
      const result = await getNotificationsList();
      if(result.length > 0) {
        setNotifications(result);
        let count = 0;
        for(let i = 0; i < result.length; ++i){
          if(!result[i].Status && result[i].Pending === 0) {
            count++;
          }
        }
        setUnseenNotices(count);
      }
    }
    getNotifications();
  }, [location]);

  const notificationBellClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNoticeClick = () => {
    history.push('/spark/notifications');
    setAnchorEl(null);
  }

  const UnreadGridItems = () => {
    let itemList=[];
    notificationsList.forEach((notice,index)=>{
      if (!notice.Status && notice.Pending === 0) {
        itemList.push(
          <Grid container className={classes.unseenGridItem} key={index} onClick={()=>handleNoticeClick()}>
            <Grid item xs={2}>
              <div className={classes.iconContainer}>
                <div className={classes.greenCircle} />
                <div className={classes.statusIcon}>
                  {notice.Type === "Personnel" ? (
                    <IconPersonnelLight className={classes.icon}/>
                  ) : (
                    <IconOrderLight className={classes.icon}/>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.truncate}>{notice.Title}</Typography>
              <Typography variant="body2" className={`${classes.message} ${classes.truncate}`}>{notice.Message}</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="body2" className={classes.date}>
                {notice.WhenDue.split('T', 1)[0]}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    })
    return itemList
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div aria-describedby={id} onClick={(e)=>notificationBellClick(e)}>
        {unseenNotices ? (
          <Badge badgeContent={unseenNotices} color="secondary">
            <div className={classes.purpleIcon}>
              <IconNavigationToolbarButton name="navigation[notification]" icon={<NotificationsIcon/>}  />
            </div>
          </Badge>
        ) : (
          <IconNavigationToolbarButton name="navigation[notification]" icon={<NotificationsIcon/>} />
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '420px' },
        }}
      >
        <Grid container className={classes.popoverContainer}>
          <Grid item xs={6} >
            <Typography variant="body2">Notifications</Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="body2">
              { unseenNotices ? `${unseenNotices} unread` : null }
              <Link href="#" color="primary" className={classes.seeAll} onClick={()=>handleNoticeClick()}>See All</Link>
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.lightDivider} />
        <Grid container className={classes.popoverContainer} style={{paddingTop:'0'}}>
          { unseenNotices ? (<UnreadGridItems />) : (
            <Typography variant="subtitle2" className={classes.noResults}>You have no new notifications.</Typography>
          ) }
        </Grid>
      </Popover>
    </>
  );

}

export default withRouter(NavigationToolbarButtonNotification);
