
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'PointToPointTemplateLine/' + token + '/';

export async function Create(componentFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', componentFormData);
  return result;
};

export async function Update(componentFormData, isCreateNewTemplate, personnelIDList) { 
  var params = {
    isCreateNewTemplate: isCreateNewTemplate,
    personnelIDList: personnelIDList
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', componentFormData, params);
  return result;
};

export async function Delete(componentID) { 
  let params = {
    id: componentID
  };
  const httpSerice = new HttpService();
  const result = await httpSerice
    .post(uri + 'Delete', null, params);
  return result;
};

export async function GetList(id) { 
  var params = {
    pointToPointTemplateID: id
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList', params);
  return result.response;
};

export async function Get(id) {
  var params = {
    id: id
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};