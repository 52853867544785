import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Dashboard/' + token + '/';

export async function getOrderSummary() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetOrderSummary');
  return result.data;
};

export async function getInvoiceSummary() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetInvoiceSummary');
  return result.data;
};

export async function getSettlementSummary() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetSettlementSummary');
  return result.data;
};

export async function getCustomersForDashboard(filter) { 
  var params = {
    filter: filter
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCustomersForDashboard', params);
  return result.data;
};

export async function getCustomersLoadsForDashboard(filter) { 
  var params = {
    filter: filter
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetCustomersLoadsForDashboard', params);
  return result.data;
};

export async function getDriversForDashboard(filter) { 
  var params = {
    filter: filter
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDriversForDashboard', params);
  return result.data;
};

export async function getDriversLoadsForDashboard(filter) { 
  var params = {
    filter: filter
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDriversLoadsForDashboard', params);
  return result.data;
};

export async function getDriversLateApptForDashboard(filter) { 
  var params = {
    filter: filter
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDriversLateApptForDashboard', params);
  return result.data;
};

export async function getRevenueStatsForDashboard() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetRevenueStatsForDashboard');
  return result.data;
};

export async function getWeeklyOrdersForDashboard() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetWeeklyOrdersForDashboard');
  return result.data;
};

export async function getWeeklyMarginsForDashboard() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetWeeklyMarginsForDashboard');
  return result.data;
};

export async function getDispatchSummary() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDispatchSummary');
  return result.data;
};

export async function getLastFreeDay() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLastFreeDay');
  return result.data;
};

export async function getReadyDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetReadyDispatchSummary', params);
  return result.data;
};

export async function getEmptiesInDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEmptiesInDispatchSummary', params);
  return result.data;
};

export async function getEmptiesOutDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEmptiesOutDispatchSummary', params);
  return result.data;
};

export async function getLoadsOutOfTerminalDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLoadsOutOfTerminalDispatchSummary', params);
  return result.data;
};

export async function getLoadsIntoTerminalDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLoadsIntoTerminalDispatchSummary', params);
  return result.data;
};

export async function getDeliveriesDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };  
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDeliveriesDispatchSummary', params);
  return result.data;
};

export async function getOnHoldDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetOnHoldDispatchSummary', params);
  return result.data;
};

export async function getOnVesselDispatchSummary(filterData) { 
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetOnVesselDispatchSummary', params);
  return result.data;
};

export async function getPerDiemDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPerDiemDispatchSummary', params);
  return result.data;
};

export async function getDemurrageDispatchSummary(filterData) {
  var params = {
    customerID: filterData.CustomerID,
    locationID: filterData.LocationTemplateID,
    steamshipLineID: filterData.SteamshipLineID,
    appt: filterData.Appointment,
    containerSize: filterData.ContainerSize,
    containerType: filterData.ContainerType,
    shipmentType: filterData.ShipmentType
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDemurrageDispatchSummary', params);
  return result.data;
};