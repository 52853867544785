import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';

const CoreExpandMoreButton = (props) => {
  const { 
    toggleIsOpen,
    ariaLabel,
    ...rest
  } = props;

  return (
    <IconButton 
      style={{padding: "0"}} 
      onClick={toggleIsOpen} 
      color="primary" 
      aria-label={ariaLabel} 
      component="span"
      {...rest}>
      <ExpandMore />
    </IconButton>
  );
}

export default CoreExpandMoreButton;