import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IntermodalLegStatusField from './IntermodalLegStatusField';
import { updateLeg, addDryRunLeg } from '../intermodalEvent/IntermodalEventService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const IntermodalEventListTableLegStatusField = (props) => {
  const {
    leg,
    setInProgress,
    inProgress,
    getEvents,
    openAddChargeDialog,
  } = props;

  const [legFormData, setLegFormData] = useState({
    ID: leg.ID,
    IntermodalOrderID: leg.IntermodalOrderID,
    LegIndex: leg.LegIndex,
    DriverID: leg.DriverID,
    DriverItineraryIndex: leg.DriverItineraryIndex,
    PlannedDate: leg.PlannedDate,
    DriverAssignedDate: leg.DriverAssignedDate,    
    LegStatus: leg.LegStatus,
  });
  const [dryRunDialog, setDryRunDialog] = useState(false);

  useEffect(() => {
    setLegFormData({ 
      ID: leg.ID,
      IntermodalOrderID: leg.IntermodalOrderID,
      LegIndex: leg.LegIndex,
      DriverID: leg.DriverID,
      DriverItineraryIndex: leg.DriverItineraryIndex,
      PlannedDate: leg.PlannedDate,
      DriverAssignedDate: leg.DriverAssignedDate,    
      LegStatus: leg.LegStatus    
    });
  }, [leg]);

  const updateLegFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    legFormData[name] = value;
    legFormData[nameData] = valueData;
    setLegFormData({ ...legFormData })
    onUpdate();
  };

  const onUpdate = () => {
    if (legFormData.LegStatus === "Dry Run") {
      setDryRunDialog(true);
    } else {
      setInProgress(true);
      processUpdateRequest();
    }
  };

  const processUpdateRequest = async () => {  
    const result = await updateLeg(legFormData);
    if (result.errors) {
      console.log(result.errors.Error);
      return;
    }
    setInProgress(false);
  };

  const openAddDryRunLeg = () => {
    setInProgress(true);
    addDryRunLeg(legFormData.ID)
      .then(function (response) {
        getEvents();
        setDryRunDialog(false);
        setInProgress(false);
        openAddChargeDialog(response, legFormData.DriverID);
      });
  };

  const handleClose = () => {
    setDryRunDialog(false);
    setInProgress(false);
  };

  return (
    <>
      <IntermodalLegStatusField
        name="LegStatus"
        label="Status"
        value={legFormData.LegStatus}
        onChange={e => updateLegFormData(e)}
        isRequired={false}
      />
      <Dialog
        open={dryRunDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Creating Dry Run?"}
        </DialogTitle>
        <DialogContent>
          Please confirm that you are copying this leg and creating a Dry Run. 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={inProgress}>Cancel</Button>
          <Button onClick={openAddDryRunLeg} disabled={inProgress} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>      
    </>
  );

}

IntermodalEventListTableLegStatusField.propTypes = {
  leg: PropTypes.array.isRequired,
};

export default IntermodalEventListTableLegStatusField;