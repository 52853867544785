import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  iconFloatRight: {
    margin: 0,
    top: 'auto',
    bottom: 12,
    left: 'auto',
    float: 'right'
  },
}));

const CustomerCreateButton = () => {
  let history = useHistory();
  const classes = useStyles();

  const openCustomerCreate = () => {
    history.push('/spark/customer/create');
  };

  return (
    <>
      <Fab 
        name="customer_list[create]"
        color="primary" 
        aria-label="add" 
        className={classes.iconFloatRight}
        // style={style}
        onClick={openCustomerCreate}>
        <AddIcon />
      </Fab>
    </>
  );
}

export default CustomerCreateButton;