import { useState, useEffect, useCallback } from 'react';
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Location/' + token + '/';

export function useLocationGetContactsRequest(
  id
) {

  const [response, setResponse] = useState([]);

  const fetch = useCallback(() => {
    async function fetchData() {
      const params = {
        locationTemplateID: id
      };
      const httpService = new HttpService();
      const result = await httpService
        .get(uri + 'GetContacts', params);
      let data = result.response;
      for (const contact of data) {
        contact.Name = contact.Name.trim();
        contact.Position = contact.Position.trim();
        contact.Email = contact.Email.trim();
        var number = contact.Phone.trim();
        if (number) {
          contact.Phone = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
        }
      }
      setResponse(data);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id, fetch]);

  return {
    data: response,
    refetch: fetch,
    setResponse: setResponse
  };
}