import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconButton: {
    opacity: '50%',
  },
}));

const NavigationToolbarButton = (props) => {
  const {
    icon,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <IconButton className={classes.iconButton} {...rest} >
      {icon}
    </IconButton>
  );

}

NavigationToolbarButton.propTypes = {
  icon: PropTypes.element.isRequired
};

export default NavigationToolbarButton;