
import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'PointToPointTemplate/' + token + '/';

export async function Create(rateTemplateFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', rateTemplateFormData);
  return result;
};

export async function Update(rateTemplateFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', rateTemplateFormData);
  return result;
};

export async function Delete(rateTemplateID) { 
  let params = {
    id: rateTemplateID
  };
  const httpSerice = new HttpService();
  const result = await httpSerice
    .post(uri + 'Delete', null, params);
  return result;
};

export async function GetList() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  return result.response;
};

export async function Get(id) {
  var params = {
    id: id
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};

export async function getForSpinnerCompare(value) {
  var params = {compareValue: value ? value : ''};
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinnerCompare', params);
  const response = result.response;
  for (const template of response) {
    template.Name = template.Name.trim();
  }
  return response;
}

export async function GetTemplateLines(id) { 
  var params = { 
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetTemplateLines', params);
  return result.response;
};

export async function GetDrivers(id, personnelID) { 
  var params = { 
    templateID: id,
    personnelID: personnelID ?? null,
  };
  const httpService = new HttpService();
  const result = await httpService  
    .get(uri + 'GetDrivers', params);
  return result.response;
};