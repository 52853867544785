import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CoreListStatusButtonIcon from '../core/CoreListStatusButtonIcon';

const useStyles = makeStyles(() => ({
  paper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 20,
    cursor: 'pointer',
    borderRadius: '8px'
  },
  paperSelected: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 20,
    cursor: 'pointer',
    backgroundColor: '#6639BF',
    color: '#FFFFFF !important',
    borderRadius: '8px'
  },
}));

const IntermodalInvoiceListStatusButton = (props) => {

  const {
    count,
    name,
    label,
    icon,
    intermodalInvoiceStatus,
    buttonColor,
    onChange
  } = props;
  const classes = useStyles();

  const onStatusChange = () => {
    if (count !== 0) {
      const dispatchEvent = {
        target: {
          name,
          value: intermodalInvoiceStatus
        }
      };
      onChange(dispatchEvent);
    }
  };

  return (
    <div>
      <Paper onClick={onStatusChange}
        className={clsx({
          [classes.paper]: !intermodalInvoiceStatus,
          [classes.paperSelected]: intermodalInvoiceStatus
        })}
        elevation={1}
        spacing={4}>
        <Grid container>
          <Grid item md={10} xs={10}>
            <Typography variant="h6" align="left">
              {count}
            </Typography>
          </Grid>
          {icon && <Grid item md={2} xs={2} align="right">
            <CoreListStatusButtonIcon
              backgroundColor={buttonColor}
              icon={icon}
              isSelected={intermodalInvoiceStatus}
            />
          </Grid>}
          {!icon && <Grid item md={2} xs={2} align="right">
            &nbsp;
          </Grid>}
          <Grid item md={12} xs={12}>
            <small>{label}</small>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );

}

export default IntermodalInvoiceListStatusButton;