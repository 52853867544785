import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as InvoicedSvg } from '../assets/svg-icons/invoiced@1x.svg'

const IconInvoiced = () => {
  return (
    <SvgIcon fontSize="small">
      <InvoicedSvg />
    </SvgIcon>
  )
}

export default IconInvoiced;