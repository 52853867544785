import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";

const LocationAreaTagCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    onCreate,
    errorMessage,
    inProgress,
    availableAreaTags,
    appliedAreaTags,
    leftCheckedItems,
    setLeftCheckedItems,
    rightCheckedItems,
    setRightCheckedItems,
  } = props;

  const handleToggle = (value, isLeftSide) => () => {
    const currentIndex = isLeftSide ? leftCheckedItems.indexOf(value) : rightCheckedItems.indexOf(value);
    const newChecked = isLeftSide ? [...leftCheckedItems] : [...rightCheckedItems];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (isLeftSide) {
      setLeftCheckedItems(newChecked);
    } else {
      setRightCheckedItems(newChecked);
    }
  };

  const customList = (items, isLeftSide) => (
    <Paper variant={"outlined"} className="mt-1" style={{ width: 370, height: 300, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {          
          const labelId = `transfer-list-item-${value.AreaTagID}-label`;
          return (
            <ListItem
              key={value.AreaTagID}
              role="listitem"
              button
              onClick={handleToggle(value, isLeftSide)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isLeftSide ? leftCheckedItems.indexOf(value) !== -1 : rightCheckedItems.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.Name}`}/>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Area Tags</DialogTitle>
        {inProgress && <LinearProgress />}
        <DialogContent>
          <Grid container spacing={2} className="mt-1">            
            <Grid item md={5} xs={5}>
              <Typography variant="h8" component="div">
                Available Area Tags
              </Typography>
              {customList(availableAreaTags, true)}
            </Grid>
            <Grid item md={2} xs={2} style={{alignSelf: 'center'}}>
              <Grid container direction="column" alignItems="center">
                <Button
                  className='mt-1'
                  variant="outlined"
                  size="small"
                  onClick={() => onCreate(true)}
                  disabled={leftCheckedItems.length === 0}
                  title="Apply selected Area Tags"
                >
                  &gt;
                </Button>
                <Button
                  className='mt-1'
                  variant="outlined"
                  size="small"
                  onClick={() => onCreate(false)}
                  disabled={rightCheckedItems.length === 0}
                  title='Remove selected Area Tags'
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item md={5} xs={5}>
              <Typography variant="h8" component="div">
                Applied Area Tags
              </Typography>
              {customList(appliedAreaTags, false)}              
            </Grid>            
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default LocationAreaTagCreateDialogContent;