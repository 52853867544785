import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as StateSvg } from '../assets/svg-icons/state@1x.svg';

const IconState = () => {
    return (
        <SvgIcon fontSize="small">
            <StateSvg />
        </SvgIcon>    
    )
}
export default IconState;