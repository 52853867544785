import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as YardMoveSvg } from '../assets/svg-icons/yardmove@1x.svg';

const IconYardMove = (props) => {

  const {
    fontSize
  } = props;

  return (
    <SvgIcon fontSize={fontSize ?? 'large'}>
      <YardMoveSvg />
    </SvgIcon>
  )
}
export default IconYardMove;