import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'EmailTemplate/' + token + '/';

export async function get(id) {
  var params = { 
    id: id 
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetFull', params);
  return result.response;
};

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  return result.response;
};

export async function create(emailTemplateFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', emailTemplateFormData);
  return result;
};

export async function update(emailTemplateFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', emailTemplateFormData);
  return result;
};

export async function deleteEmailTemplate(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};