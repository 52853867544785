import { Route } from 'react-router-dom';
import Quickbooks from './Quickbooks';

const QuickbooksRouter = () => {
  return (
    <>
      <Route exact path="/spark/quickbooks" component={Quickbooks} />
    </>
  );

}

export default QuickbooksRouter;
