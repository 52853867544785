import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ModelSvg } from '../assets/svg-icons/model@1x.svg';

const IconModel = () => {
    return (
        <SvgIcon fontSize="small">
            <ModelSvg />
        </SvgIcon>    
    )
}
export default IconModel;