export const reportOptions = () => [
  {
    ID: 1, 
    Name: "Profit by Customer Summary", 
    Description: "Summary of revenue and expenses grouped by customer name.", 
    redirect: "/spark/reports/profit-by-customer-summary" 
  },
  {
    ID: 2,
    Name: "Profit by Customer Detail",
    Description: "Detailed report of revenue and expenses grouped by customer name.",
    redirect: "/spark/reports/profit-by-customer-detail"
  }
];