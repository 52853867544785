import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import { format } from 'date-fns';

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import OrderEquipmentListColumnEditButton from './OrderEquipmentListColumnEditButton';
import { deleteOrderEquipment } from './OrderEquipmentService';

const OrderEquipmentListTable = (props) => {
  const {
    orderID,
    equipment,
    getEquipment,
    setEquipment
  } = props;

  let columns = [
    {
      field: 'UnitNum',
      headerName: 'Unit #',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("UnitNum");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>{name}</span>
          </div>
        )
      }
    },
    {
      field: 'OutsideLengthFt',
      headerName: 'Size',
      align: 'left',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("OutsideLengthFt");
        return (
          <div>{name} {"FT"}</div>
        )
      }
    },
    {
      field: 'EquipmentType',
      headerName: 'Equipment Type',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'UnitType',
      headerName: 'Type',
      align: 'left',
      flex: 0.5,
      sortable: false
    }, 
    {
      field: 'OwnerCarrierName',
      headerName: 'Owner',
      align: 'left',
      flex: 1,
      sortable: false
    },           
    {
      field: 'LastFreeReturnDate',
      headerName: 'Last Free Return Date',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("LastFreeReturnDate") ? format(new Date(params.getValue("LastFreeReturnDate")), 'MM/dd/yyyy') : null;
        return (
          <div>{name}</div>
        )
      }
    },
    {
      field: 'Controls',
      headerName: ' ',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const id = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <OrderEquipmentListColumnEditButton
                orderEquipmentID={id}
                orderID={orderID}
                getEquipment={getEquipment}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Unit #${params.getValue("UnitNum")}`}
                deleteID={id}
                deleteFunction={deleteOrderEquipment}
                getList={getEquipment}
              />}
          </div>
        )      
      }
    }
  ];

  const onEquipmentRowEnter = (params) => {
    for (const item of equipment) {
      if (item.ID === params.row.ID) {
        item.ShowControls = true;
      }
    }
    setEquipment([ ...equipment ]);
  };

  const onEquipmentRowLeave = (params) => {
    for (const item of equipment) {
      if (item.ID === params.row.ID) {
        item.ShowControls = false;
      }
    }
    setEquipment([...equipment]);
  };

  return (
    <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
      <DataGrid
        getRowId={(row) => row.ID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={equipment} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onEquipmentRowEnter(e)}
        onRowLeave={e => onEquipmentRowLeave(e)}
      />
    </Paper>
  );
}

OrderEquipmentListTable.propTypes = {
  orderID: PropTypes.number.isRequired,
  equipment: PropTypes.array.isRequired,
  getEquipment: PropTypes.func.isRequired
};

export default OrderEquipmentListTable;