import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as QuickbooksSvg } from '../assets/svg-icons/quickbooks@1x.svg'

const IconQuickbooks = () => {
  return (
    <SvgIcon fontSize="small">
      <QuickbooksSvg />
    </SvgIcon>
  )
}

export default IconQuickbooks;