import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

import CorePhoneField from '../core/CorePhoneField';
import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';
import CountryField from '../country/CountryField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';

const CustomerCreateContentBillAddress = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData,
    coordinates,
    branch
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={8}>
          <h4>Billing Address</h4>
        </Grid>
        <Grid item md={4} xs={4}>
          <Typography variant="subtitle2" gutterBottom style={{float:"right"}} className="mt-1">
            <CoreCheckboxField
              name="billAddressSame"
              label="Same as Physical"
              value={customerFormData.billAddressSame}
              onChange={e => updateCustomerFormData(e)}
            />
          </Typography>   
        </Grid>  
      </Grid>
      {!customerFormData.billAddressSame &&
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            {(customerFormData.billCountry !== "Philippines" && branch.Country !== "Philippines") && <>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="billAddress"
                  label="Address"
                  value={customerFormData.billAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.billAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="billCity"
                  label="City"
                  value={customerFormData.billCity}
                  validationError={customerFormValidation.billCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="billProvince"
                  label="State / Province"
                  value={customerFormData.billProvince}
                  validationError={customerFormValidation.billProvince}
                  onChange={e => updateCustomerFormData(e)}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="billPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.billPostalCode}
                  validationError={customerFormValidation.billPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CountryField
                  name="billCountry"
                  label="Country"              
                  value={customerFormData.billCountry}
                  validationError={customerFormValidation.billCountry}
                  onChange={e => updateCustomerFormData(e)}
                />
              </Grid>
            </>}
            {(customerFormData.billCountry === "Philippines" || branch.Country === "Philippines" )&& <>
              <Grid item md={6} xs={6}>
                <CountryField
                  name="billCountry"
                  label="Country"              
                  value={customerFormData.billCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.billCountry}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="billProvince"
                  label="State / Province"
                  value={customerFormData.billProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.billProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="billCity"
                  label="City"
                  value={customerFormData.billCity}
                  validationError={customerFormValidation.billCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="billPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.billPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.billPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="billDistrict"
                  label="District"
                  value={customerFormData.billDistrict}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="billCounty"
                  label="Barangay"
                  value={customerFormData.billCounty}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="billAddress"
                  label="Address"
                  value={customerFormData.billAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.billAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
            </>}
            <Grid item md={6} xs={6}>
              <CorePhoneField
                name="billPhone"
                label="Primary Number"              
                value={customerFormData.billPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.billPhone}
              />
              {/* <CoreTextField
                name="billPhone"
                label="Primary Number"              
                value={customerFormData.billPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.billPhone}
                maxLength={15}
              /> */}
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CorePhoneField
                name="billTollFree"
                label="Secondary Number"              
                value={customerFormData.billTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.billTollFree}
              />
              {/* <CoreTextField
                name="billTollFree"
                label="Secondary Number"              
                value={customerFormData.billTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.billTollFree}
                maxLength={15}
              /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }     
        </Grid>
      </Grid>}
    </Container>
  );
}

export default CustomerCreateContentBillAddress;