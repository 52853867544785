import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getUnmappedRevenueItems } from './QuickbooksService';

const QuickbooksLocalRevenueDropdownField = (props) => {

  const {
    label,
    value,
    name,
    nameData,
    valueData,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [localRevenues, setLocalRevenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getLocalRevenuesList() {
      const result = await getUnmappedRevenueItems();
      setLocalRevenues(result);
      setIsLoading(false);
    }
    getLocalRevenuesList();
  }, []);

  const onLocalRevenueSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.ReceivableChargeTypeID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const acct = localRevenues
      .find(acct => acct.ReceivableChargeTypeID === valueData);
    if (acct) {
      return acct.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onLocalRevenueSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={localRevenues}
      {...rest}
    />
  );

}

QuickbooksLocalRevenueDropdownField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default QuickbooksLocalRevenueDropdownField;