export default class Validation {

    isValidObject(formValidation) {
        var result = true;
        for (const i in formValidation) {
            if (formValidation[i]) {
                result = false;
                break;
            }
        }
        return result;
    }

    isEmpty(value) {
        if (value) {
            return false;
        } else {
            return true;
        }
    }

    isNumber(value) {
        if (!isNaN(parseInt(value))) {
            return false;
        } else {
            return true;
        }
    }

    // TO DO 

    validatePositiveWholeNumber(value) {
        const regex = /^\+?(0|[1-9]\d*)$/;
        return regex.test(value);
    }

    validateNullablePositiveWholeNumber(value) {
        if (!value) {
            return true;
        }
        const regex = /^\+?(0|[1-9]\d*)$/;
        return regex.test(value);
    }

    validatePhone(value) {
        if (/\d{3}\d{3}\d{4}/.test(value) || /\d{1}\d{3}\d{3}\d{4}/.test(value) || !value) {
            return true;
        } else {
            return false;
        }
    }

    validateEmailRequired(value) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(String(value).toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    validateEmail(value) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(String(value).toLowerCase()) || !value) {
            return true;
        } else {
            return false;
        }
    }

    validateCurrency(value) {
        const regex = /^\d+(\.\d{1,2})?$/;
        if (regex.test(value)) {
            return true;
        }
        return false;
    }

    validateNullableCurrency(value) {
        if (!value) {
            return true;
        }
        const regex = /^\d+(\.\d{1,2})?$/;
        if (regex.test(value)) {
            return true;
        }
        return false;
    }

    validateNegativeCurrency(value) {
        const regex = /^-?\d+(\.\d{1,2})?$/;
        if (regex.test(value)) {
            return true;
        }
        return false;
    }

    validateNegativeRate(value) {
        const regex = /^-?\d+(\.\d{1,5})?$/;
        if (regex.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    validateRate(value) {
        const regex = /^\d+(\.\d{1,5})?$/;
        if (regex.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    validateNullableDate(value) {
        if (value) {
            const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
            return regex.test(value);
        }
        return true;
    }

    validateDate(value) {
        const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        return regex.test(value);
    }

    validateTime(value) {
        const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    }

}
