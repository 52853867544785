import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

import { FeePayContext, TransactionHistoryContent, TransactionHistoryTable } from '@envasetechnologies/feepay-components';
import { TransactionHistory } from '@envasetechnologies/feepay-components';

const useStyles = makeStyles(() => ({
  
}));

const FeePayTransactionHistory = (props) => {
  const classes = useStyles();
  const {

  } = props;

  const feePayContext = useContext(FeePayContext);  

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth={'xl'} className="" component={Paper}>
      <TransactionHistory
        pageSize={10}
      >
      </TransactionHistory>
    </Container>
  );
}

export default FeePayTransactionHistory;