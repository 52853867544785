import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const ItineraryAssignFooter = (props) => {
  const useStyles = makeStyles((theme) => ({
    summaryFooter: {
      position: 'fixed',
      bottom: '0',
      width: '400px',
      margin: '0',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #ededed',
      boxShadow: '2px 13px 10px 4px #333333',
      zIndex: '3'
    },
    fixedFooterSpacer: {
      height: '70px'
    }
  }));

  const classes = useStyles();

  const {
    leg,
    assignLeg,
    onClose,
  } = props; 

  return (
    <div>
      <div className={classes.summaryFooter}>
        <Container className="mt-1 mb-1">
          <Grid container spacing={0}>
            <Grid item md={5} xs={5} style={{textAlign: 'left'}}>
              <Button variant="outlined" onClick={onClose} color="primary">
                CLOSE
              </Button>
            </Grid>
            <Grid item md={2} xs={2} style={{textAlign: 'left'}}>
              <Button variant="contained" onClick={assignLeg} color="primary" disabled={leg.ID ? false : true}>
                ASSIGN
              </Button>
            </Grid>
            <Grid item md={5} xs={5}></Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.fixedFooterSpacer} />
    </div>
  );
}

export default ItineraryAssignFooter;
