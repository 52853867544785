import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import CoreAddButton from '../core/CoreAddButton';
import { deleteAreaTag } from '../area_tag/AreaTagService';
import AreaTagColumnEditButton from '../area_tag/AreaTagColumnEditButton';

const ConfigurationViewAreaTagSectionList = (props) => {
  const {
    areaTags,
    getAreaTags,
    setAreaTags,
    openAreaTagCreateDialog
  } = props;

  let columns = [
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openAreaTagCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const areaTagID = params.getValue("AreaTagID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <AreaTagColumnEditButton
                areaTagID={areaTagID} 
                getAreaTags={getAreaTags}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Area Tag: ${params.getValue("Name")}, components, and assigned locations associated to ${params.getValue("Name")}`}
                deleteID={areaTagID}
                deleteFunction={deleteAreaTag}
                getList={getAreaTags}
              />}
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configAreaTagAmount")) || 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configAreaTagAmount", amount);
  };

  const onAreaTagRowEnter = (params) => {
    for (const item of areaTags) {
      if (item.AreaTagID === params.row.AreaTagID) {
        item.ShowControls = true;
      }
    }
    setAreaTags([ ...areaTags ]);
  };

  const onAreaTagRowLeave = (params) => {
    for (const item of areaTags) {
      if (item.AreaTagID === params.row.AreaTagID) {
        item.ShowControls = false;
      }
    }
    setAreaTags([...areaTags]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.AreaTagID} 
        autoHeight={true} 
        rows={areaTags} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onAreaTagRowEnter(e)}
        onRowLeave={e => onAreaTagRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewAreaTagSectionList.propTypes = {
  areaTags: PropTypes.array.isRequired,
  getAreaTags: PropTypes.func.isRequired
};

export default ConfigurationViewAreaTagSectionList;