import React from 'react';
import Grid from '@material-ui/core/Grid';

import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';
import CountryField from '../country/CountryField';

const CustomerCreateDialogContentInfo = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData
  } = props;

  return (
    <>
      <Grid item md={12} xs={12}>
        <CoreTextField
          name="custAddress"
          label="Address"
          value={customerFormData.custAddress}
          onChange={e => updateCustomerFormData(e)}
          validationError={customerFormValidation.custAddress}
          maxLength={255}
          autoComplete="disable-autocomplete"
        />
      </Grid>
      {customerFormData.custCountry !== "Philippines" && <>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="custCity"
            label="City"
            value={customerFormData.custCity}
            validationError={customerFormValidation.custCity}
            onChange={e => updateCustomerFormData(e)}
            maxLength={100}
            autoComplete="disable-autocomplete"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ProvinceField
            name="custProvince"
            label="State / Province"
            value={customerFormData.custProvince}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custProvince}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="custPostalCode"
            label="ZIP / Postal Code"
            value={customerFormData.custPostalCode}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custPostalCode}
            maxLength={15}
            autoComplete="disable-autocomplete"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CountryField
            name="custCountry"
            label="Country"              
            value={customerFormData.custCountry}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custCountry}
          />
        </Grid>
      </>}
      {customerFormData.custCountry === "Philippines" && <>
        <Grid item md={6} xs={6}>
          <CoreTextField
            name="custDistrict"
            label="District"
            value={customerFormData.custDistrict}
            onChange={e => updateCustomerFormData(e)}
            maxLength={100}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <CoreTextField
            name="custCounty"
            label="Barangay"
            value={customerFormData.custCounty}
            onChange={e => updateCustomerFormData(e)}
            maxLength={100}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="custPostalCode"
            label="ZIP / Postal Code"
            value={customerFormData.custPostalCode}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custPostalCode}
            maxLength={15}
            autoComplete="disable-autocomplete"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CoreTextField
            name="custCity"
            label="City"
            value={customerFormData.custCity}
            validationError={customerFormValidation.custCity}
            onChange={e => updateCustomerFormData(e)}
            maxLength={100}
            autoComplete="disable-autocomplete"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ProvinceField
            name="custProvince"
            label="State / Province"
            value={customerFormData.custProvince}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custProvince}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CountryField
            name="custCountry"
            label="Country"              
            value={customerFormData.custCountry}
            onChange={e => updateCustomerFormData(e)}
            validationError={customerFormValidation.custCountry}
          />
        </Grid>
      </>}
    </>
  );
}

export default CustomerCreateDialogContentInfo;