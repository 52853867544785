import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import IconInternalName from '../icon/IconInternalName';
import IconLegalName from '../icon/IconLegalName';
import IconEmail from '../icon/IconEmail';
import IconAccountRep from '../icon/IconAccountRep';
import IconInvoiced from '../icon/IconInvoiced';

const CustomerViewGeneralSection = (props) => {
  const {
    customer
  } = props;

  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        {customer.Obsolete &&
          <Grid item md={12} xs={12} className="mt-2">
            <Alert severity="warning">
              {"Customer record is inactive. Click MORE to activate customer."}
            </Alert>
          </Grid>
        }
        <Grid item md={12} xs={12}>
          <h4>General Information</h4>
        </Grid>
        <Grid item container spacing={2} xs={12} className="mb-2">
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Internal Name"
              value={customer.InternalName}
              icon={<IconInternalName />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Legal Name"
              value={customer.LegalName}
              icon={<IconLegalName />}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Email"
              value={customer.Email}
              icon={<IconEmail />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Account Representative"
              value={customer.AccountRepName}
              icon={<IconAccountRep />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Credit Terms"
              value={customer.CreditTerms}
              icon={<IconInvoiced />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CustomerViewGeneralSection;