import React from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const PersonnelDisableLoginDialogContent = (props) => {
  const {
    internalName,
    loading, 
    error, 
    success, 
    onDisableUser,
    onClose,
    isOpen
  } = props;
 
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Disable Envase Login
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              Are you sure you want to disable login access for <strong>{internalName}</strong>
            </p>
          </Grid>
          {(error || success) && (
            <Grid item xs={12} className="mt-3">
                <Alert variant="filled" severity={error ? "error" : "success"}>
                  {error || success}
                </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!loading &&
      <DialogActions>
        <Button onClick={onClose} className="grey">
          {!success && 'Cancel'}
          {success && 'Close' }
        </Button>
        {!success && <Button onClick={onDisableUser} className="grey">
          Disable TMS Login
        </Button>}
      </DialogActions>}
    </Dialog>
  );
};

export default PersonnelDisableLoginDialogContent;