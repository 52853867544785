import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CorePasswordField from '../core/CorePasswordField';

import AuthenticationPage from './AuthenticationPage';

const AuthenticationLoginContent = (props) => {

  const {
    loginFormData,
    loginFormValidation,
    updateLoginFormData,
    errorMessage,
    onLogin
  } = props;

  const loginForm =
    <div>
      <Typography variant="h5" gutterBottom className="mb-4">
        Intermodal TMS
      </Typography>
      <form onSubmit={onLogin}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <CoreTextField
              name="username"
              label="Username"
              value={loginFormData.username}
              onChange={e => updateLoginFormData(e)}
              isRequired={true}
              validationError={loginFormValidation.username}
              icon={<SupervisedUserCircleIcon />}
            />
          </Grid>
          <Grid item md={12} xs={12} className="mt-2">
            <CorePasswordField
              name="password"
              label="Password"
              value={loginFormData.password}
              onChange={e => updateLoginFormData(e)}
              validationError={loginFormValidation.password}
              showIcon={true}
            />
          </Grid>
          <Grid item md={12} xs={12} className="mt-3">
            <Typography gutterBottom>
              <Link href="/reset-password">
                Forgot Password?
              </Link>
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} className="mt-3">
            <Button type="submit" variant="contained" color="primary" size="large">
              Login
            </Button>
          </Grid>
          <Grid item md={12} xs={12} className="mt-3">
            <Typography gutterBottom style={{fontStyle: 'italic'}}>
              <Link href="https://www.envasetechnologies.com/intermodal-drayage-software/envase-tms/">
                Learn more and get started here
              </Link>
            </Typography>
          </Grid>
          { errorMessage && <Grid item md={12} xs={12} className="mt-3">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
          {/* <Grid item md={12} xs={12} className="mt-3">
            <Typography gutterBottom>
              Need an account?&nbsp;
              <Link href="/create-account">
                Create an account
              </Link>
            </Typography>
          </Grid> */}
        </Grid>
      </form>
    </div>

  return (
    <AuthenticationPage form={loginForm} />
  );

}

AuthenticationLoginContent.propTypes = {
  loginFormData: PropTypes.object.isRequired,
  loginFormValidation: PropTypes.object.isRequired,
  updateLoginFormData: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

AuthenticationLoginContent.defaultProps = {
  loading: false,
  loginFormValidation: {}
};

export default AuthenticationLoginContent;