import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as OwnerSvg } from '../assets/svg-icons/owner@1x.svg';

const IconOwner = () => {
    return (
        <SvgIcon fontSize="small">
            <OwnerSvg />
        </SvgIcon>    
    )
}
export default IconOwner;