import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Validation from '../validation';

import CoreCheckboxField from '../core/CoreCheckboxField';
import OrderAppointmentCancelDialog from './OrderAppointmentCancelDialog';
import { GetList } from '../intermodal_order_appointments/IntermodalOrderAppointmentsService';

const OrderAppointmentCancelAppointmentListDialog = (props) => { 
  const { 
    isOpen,
    onClose,
    inProgress,
    order
  } = props;

  const [appointmentList, setAppointmentList] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);

  useEffect(() => {
    async function getAppointments() { 
      const response = await GetList(order.ID);
      setAppointmentList(response);
    };

    if (isOpen) { 
      getAppointments();
    }
  }, [isOpen]);

  const closeCancelAppointmentModal = () => { 
    setIsCancelAppointmentModalOpen(false);
  };

  const onAppointmentCancel = () => { 
    setIsCancelAppointmentModalOpen(false);
    onClose();
  };

  const setAppointmentData = (e, row) => {
    resetListCheckBoxes(row);
    setAppointment(row);
  };

  function resetListCheckBoxes(row) { 
    for (const appointmentRow of appointmentList) {
      if (appointmentRow.ID === row.ID) {
        appointmentRow.Selected = true;
      } else { 
        appointmentRow.Selected = false;
      }
    }
    setAppointmentList([ ...appointmentList ]);
  };

  const handleConfirm = () => {
    setIsCancelAppointmentModalOpen(true);
    onClose();
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'left',
      width: 100,
      sortable: false,
      renderCell: (params) => {        
        const row = params.row;
        const selected = params.getValue("Selected") || false;
        return (
        <div style={{
            width:'100%', 
            minWidth: '100%', 
            display: 'flex',
            justifyContent: 'space-between'}}>
            <span>
            <CoreCheckboxField
                label={null}
                value={selected}
                name="LocationCode"
                onChange={(e) => setAppointmentData(e, row)}
            />
            </span>
        </div>
        )
      }
    },
    {
      field: 'GateTransactionID',
      headerName: 'Gate Transaction ID',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'ContainerNum',
      headerName: 'Container Number',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'DateCreated',
      headerName: 'Date Created',
      align: 'left',
      flex: 1,
      sortable: false
    }
  ];

  return ( 
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Cancel Appointment</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
                  <DataGrid
                    getRowId={(row) => row.ID} 
                    rows={appointmentList}
                    columns={columns} 
                    hideFooter={true} 
                    autoHeight={true} 
                    pageSize={100} 
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableSelectionOnClick={true}
                  />
                </Paper>
              </Grid>  
              {errorMessage && 
                <Grid item md={12} xs={12}> 
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                </Grid>
              }
            </Grid>  
          </DialogContent>
          <DialogActions>
            <Button disabled={inProgress} onClick={onClose} color="primary">
              Close
            </Button>
            <Button disabled={appointment === null} onClick={handleConfirm} color="primary">
              Confirm
            </Button>
        </DialogActions>
      </Dialog>
      <OrderAppointmentCancelDialog
        isOpen={isCancelAppointmentModalOpen}
        onClose={closeCancelAppointmentModal}
        appointment={appointment}
        onAppointmentCancel={onAppointmentCancel}
        order={order}
      />
    </>
  )
}

export default OrderAppointmentCancelAppointmentListDialog;