import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CoreTextField from '../core/CoreTextField';
import CoreDateField from '../core/CoreDateField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import ProvinceField from '../province/ProvinceField';
import EquipmentUnitTypeDropdownField from './EquipmentUnitTypeDropdownField';
import EquipmentOwnerTypeDropdownField from './EquipmentOwnerTypeDropdownField';
import CarrierOwnerOperatorListField from '../carrier/CarrierOwnerOperatorListField';
import CarrierListField from '../carrier/CarrierListField';
import EquipmentTypeField from '../equipment_type/EquipmentTypeField';
import EquipmentTypeContainerField from '../equipment_type/EquipmentTypeContainerField';
import EquipmentTypeChassisField from '../equipment_type/EquipmentTypeChassisField';

const EquipmentCreateContentInfo = (props) => {
  const {
    EquipmentFormData,
    EquipmentFormValidation,
    UpdateEquipmentFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>General Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <CoreTextField
            name="UnitNum"
            label="Unit Number"
            value={EquipmentFormData.UnitNum}
            onChange={e => UpdateEquipmentFormData(e)}
            isRequired={true}
            validationError={EquipmentFormValidation.UnitNum}
            autoFocus={true}
            isCapsOnly={EquipmentFormData.UnitType === 2 || EquipmentFormData.UnitType === 3}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <EquipmentUnitTypeDropdownField
            value={EquipmentFormData.UnitType}
            onChange={e => UpdateEquipmentFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
            {EquipmentFormData.UnitType === 2 &&
              <EquipmentTypeContainerField
                name="EquipmentType"
                label="Equipment Type"
                value={EquipmentFormData.EquipmentType}
                onChange={e => UpdateEquipmentFormData(e)}
              />
            }
            {EquipmentFormData.UnitType === 3 &&
              <EquipmentTypeChassisField
                name="EquipmentType"
                label="Equipment Type"
                value={EquipmentFormData.EquipmentType}
                onChange={e => UpdateEquipmentFormData(e)}
              />
            }
            {(EquipmentFormData.UnitType !== 2 && EquipmentFormData.UnitType !== 3) &&
              <EquipmentTypeField
                name="EquipmentType"
                label="Equipment Type"
                value={EquipmentFormData.EquipmentType}
                onChange={e => UpdateEquipmentFormData(e)}
              />
            }
        </Grid>
        <Grid item md={3} xs={12}>
          <EquipmentOwnerTypeDropdownField
            value={EquipmentFormData.OwnerType}
            onChange={e => UpdateEquipmentFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CoreDateField
            name="ServiceStart"
            label="Active Date"
            value={EquipmentFormData.ServiceStart}
            validationError={EquipmentFormValidation.ServiceStart}
            isRequired={true}
            onChange={e => UpdateEquipmentFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CoreDateField
            name="ServiceEnd"
            label="In-active Date"
            value={EquipmentFormData.ServiceEnd}
            onChange={e => UpdateEquipmentFormData(e)}
          />
        </Grid>
        <Grid item md={3} xs={12}>

        </Grid>
        <Grid item md={3} xs={12}>
          {EquipmentFormData.OwnerType === 1 && <CarrierOwnerOperatorListField
            name="OwnerCarrierName"
            label="Owner Operator"
            value={EquipmentFormData.OwnerCarrierName}
            nameData="OwnerCarrierID"
            valueData={EquipmentFormData.OwnerCarrierID}
            onChange={e => UpdateEquipmentFormData(e)}
            validationError={EquipmentFormValidation.OwnerCarrierID}
          />}
          {EquipmentFormData.OwnerType === 2 && <CarrierListField
            name="OwnerCarrierName"
            label="Vendor"
            value={EquipmentFormData.OwnerCarrierName}
            nameData="OwnerCarrierID"
            valueData={EquipmentFormData.OwnerCarrierID}
            onChange={e => UpdateEquipmentFormData(e)}
            validationError={EquipmentFormValidation.OwnerCarrierID}
          />}
        </Grid>
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={6} xs={12}> <CoreTextField
              name="LicenseNum"
              label="License Plate Number"
              value={EquipmentFormData.LicenseNum}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.LicenseNum}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={6} xs={12}>
            <ProvinceField
              name="LicenseState"
              label="License Plate State"
              value={EquipmentFormData.LicenseState}
              validationError={EquipmentFormValidation.LicenseState}
              onChange={e => UpdateEquipmentFormData(e)}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={6} xs={12}>
            <CoreTextField
              name="VINNum"
              label="Vehicle Identification Number"
              value={EquipmentFormData.VINNum}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.VINNum}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={6} xs={12} className="mb-2">
            <CoreCheckboxField
              label="Is Leased"
              value={EquipmentFormData.IsLeased}
              name="IsLeased"
              onChange={e => UpdateEquipmentFormData(e)}
            />
          </Grid>
        }
      </Grid>
    </Container>  
  );
}

export default EquipmentCreateContentInfo;