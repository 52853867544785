import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreToggleField from '../core/CoreToggleField';
import PayableChargeTypeField from '../payableChargeType/PayableChargeTypeField';
import AreaTagField from '../area_tag/AreaTagField';
import { DialogContentText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { DataGrid } from "@material-ui/data-grid";
import CoreTextField from '../core/CoreTextField';

const PointToPointTemplateLineEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    onUpdate,
    templateLineFormData,
    updateTemplateLineFormData,
    errorMessage,
    templateLineFormValidation,
    inProgress,
    drivers,
    setDrivers,
    isCreateNewTemplate,
  } = props;

  const openRecord = (id) => { 
    window.open('/spark/personnel/view/' + id, '_blank');
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.25,
      renderCell: (params) => { 
        const id = parseInt(params.getValue("PersonnelID"));
        return ( 
          <IconButton title="Open Personnel Record" onClick={() => openRecord(id)}>              
            <OpenInBrowserIcon color='primary'/>
          </IconButton>
        )
      },
    },
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => { 
        const firstName = params.getValue("FirstName");
        const lastName = params.getValue("LastName");
        return (
          <span>{firstName + " " + lastName}</span>
        )
      },
    },
    {
      field: 'UpdateTemplate',
      headerName: 'Apply updated template line?',
      align: 'center',
      flex: 1.5,
      sortable: false,
      hide: !isCreateNewTemplate,
      renderCell: (params) => { 
        const updateTemplate = params.getValue("UpdateTemplate") || false;
        const personnelID = params.getValue("PersonnelID");
        return ( 
          <div style={{
            width:'100%', 
            minWidth: '100%', 
            display: 'flex',
            justifyContent: 'center'}}>
            <span>
              <CoreToggleField
                label={null}
                value={updateTemplate}
                name="UpdateTemplate"
                onChange={(e) => addPersonnelToList(e, personnelID)}
              />
            </span>
          </div>
        )
      }
    }
  ];

  const addPersonnelToList = async (event, personnelID) => { 
    const {value} = event.target;
    const index = drivers.findIndex(item => item.PersonnelID === personnelID);
    drivers[index].UpdateTemplate = value;
    setDrivers(drivers);
  };

  const onDriverRowEnter = (params) => {
    for (const driver of drivers) {
      if (driver.PersonnelID === params.row.PersonnelID) {
        driver.ShowControls = true;
      }
    }
    setDrivers([...drivers]);
  };

  const onDriverRowLeave = (params) => {
    for (const driver of drivers) {
      if (driver.PersonnelID === params.row.PersonnelID) {
        driver.ShowControls = false;
      }
    }
    setDrivers([...drivers]);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Point-To-Point Template Line</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <DialogContentText>
              {"Affected Drivers:"} <br></br>
            </DialogContentText>
            <Grid item md={12} xs={12}>
              <DataGrid                            
                getRowId={(row) => row.PersonnelID} 
                hideFooter={true} 
                autoHeight={true} 
                rows={drivers} 
                columns={columns} 
                pageSize={5} 
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
                onRowEnter={e => onDriverRowEnter(e)}
                onRowLeave={e => onDriverRowLeave(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <AreaTagField
                label="Point A"
                name="OriginAreaTagName"
                value={templateLineFormData.OriginAreaTagName}
                nameData="OriginAreaTagID"
                valueData={templateLineFormData.OriginAreaTagID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.OriginAreaTagID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <AreaTagField
                label="Point B"
                name="DestinationAreaTagName"
                value={templateLineFormData.DestinationAreaTagName}
                nameData="DestinationAreaTagID"
                valueData={templateLineFormData.DestinationAreaTagID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.DestinationAreaTagID}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreToggleField
                label="Two-Way Rate"
                name="TwoWay"
                value={templateLineFormData.TwoWay}
                onChange={e => updateTemplateLineFormData(e)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <PayableChargeTypeField
                label="Payable Item Type"   
                value={templateLineFormData.PayableChargeTypeName}
                name="PayableChargeTypeName"
                nameData="PayableChargeTypeID"
                valueData={templateLineFormData.PayableChargeTypeID}
                isRequired={true}
                onChange={e => updateTemplateLineFormData(e)}
                validationError={templateLineFormValidation.PayableChargeTypeID}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              {(templateLineFormData.IntermodalUnitRateType === null || templateLineFormData.IntermodalUnitType === 0 || templateLineFormData.IntermodalUnitType === 1) &&
              <CoreNumberField
                name="Units"
                label="Quantity"
                minValue={0}
                isDecimal={true}
                value={templateLineFormData.Units}
                onChange={e => updateTemplateLineFormData(e)}
                isRequired={true}
                validationError={templateLineFormValidation.Units}
              />}
              {templateLineFormData.IntermodalUnitRateType !== null && templateLineFormData.IntermodalUnitType !== 0 && templateLineFormData.IntermodalUnitType !== 1 && 
              <CoreTextField
                label="Unit Type"
                value={templateLineFormData.IntermodalUnitTypeName}
                disabled={true}
              />}
            </Grid>
            <Grid item md={6} xs={6}>
              {(templateLineFormData.IntermodalUnitRateType === null || templateLineFormData.IntermodalUnitRateType === 0) && 
              <CoreNumberField
                name="UnitRate"
                label="Rate"
                minValue={0}
                isCurrency={true}
                value={templateLineFormData.UnitRate}
                onChange={e => updateTemplateLineFormData(e)}
                isRequired={true}
                validationError={templateLineFormValidation.UnitRate}
              />}
              {templateLineFormData.IntermodalUnitRateType !== null && templateLineFormData.IntermodalUnitRateType !== 0 && 
              <CoreTextField
                label="Unit Rate Type"
                value={templateLineFormData.IntermodalUnitRateTypeName}
                disabled={true}
              />}
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PointToPointTemplateLineEditDialogContent;