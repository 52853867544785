import { Route } from 'react-router-dom';
import ConfigurationView from './ConfigurationView';

const ConfigurationRouter = () => {
  return (
    <>
      <Route exact path="/spark/configuration" component={ConfigurationView} />
    </>
  );

}

export default ConfigurationRouter;