import React from 'react';

import DeleteLegAndPayablesDialogContent from './DeleteLegAndPayablesDialogContent';

const DeleteLegAndPayablesDialog = (props) => {
  const {
    deleteID,
    deleteFunction,
    getList,
    isOpen,
    onClose,
  } = props;

  const onDelete = deletePayables => {
    processDeleteContact(deletePayables);    
  };

  const processDeleteContact = async (deleteRelatedPayables) => {
    await deleteFunction(deleteID, deleteRelatedPayables);
    onClose();
    getList();
  };

  return (
    isOpen &&
    <DeleteLegAndPayablesDialogContent
      deleteID={deleteID}
      onDelete={onDelete}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

export default DeleteLegAndPayablesDialog;