import React, { useState, useEffect } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const PersonnelUserDriverRadioField = (props) => {
  const {
    name,
    value,
    nameData,
    onChange
  } = props;

  const [radioState, setRadioState] = useState("0");

  useEffect(() => {
    setRadioState(value);
  }, [value]);

  const handleToggle = (newValue) => {
    const tempEventListAsDriver = {
      target: {
        name,
        value: parseInt(newValue)
      }
    };
    const tempEventDriverType = {
      target: {
        nameData,
        valueData: parseInt(newValue)
      }
    };
    onChange(tempEventListAsDriver);
    onChange(tempEventDriverType);
  };

  return (
    <FormControl component="fieldset">
        <RadioGroup row value={radioState} onChange={(event, newValue) => { handleToggle(newValue); setRadioState(newValue) }}>
            <FormControlLabel value={0} control={<Radio />} label="User" />
            <FormControlLabel value={1} control={<Radio />} label="Company Driver" />
            <FormControlLabel value={2} control={<Radio />} label="Owner Operator" />
        </RadioGroup>
    </FormControl>
  );
}

export default PersonnelUserDriverRadioField;