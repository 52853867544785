import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AccountRepSvg } from '../assets/svg-icons/account-representative@1x.svg';

const IconAccountRep = () => {
  return (
    <SvgIcon fontSize="small">
      <AccountRepSvg />
    </SvgIcon>    
  )
}
export default IconAccountRep;