import React from "react";
import { Button } from "@material-ui/core";
import {AppointmentDetails} from "@envasetechnologies/precheck-components";

export default function MyAppointmentViewDetails(props) {
  const { isViewApptOpen, isLoading, viewAppt, handleViewDetailsClicked } = props;
  return (
    <AppointmentDetails
    isOpen={isViewApptOpen}
    isLoading={isLoading}
    appointment={viewAppt}
    onClose={() => handleViewDetailsClicked(null)}
    renderSidePanelFooter={(viewAppt) => (
      <div className="d-flex align-items-center justify-content-between">
        <Button size="small" variant="outlined" color="primary">
          More
        </Button>
        <Button size="small" variant="contained" color="primary">
          Edit
        </Button>
      </div>
    )}
  />
  );
};