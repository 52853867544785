import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'CustomerInvCode/' + token + '/';

export async function getInvoiceCode(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetInvoiceCode');
  return result.data[0];
};

export async function deleteInvoiceCode(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/Delete');
  return result;
};

export async function updateInvoiceCode(data) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + data.CustomerInvCodeID + '/Update', null, data);
  return result;
}