import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ZipCodeSvg } from '../assets/svg-icons/zip-code@1x.svg';

const IconZipCode = () => {
  return (
    <SvgIcon fontSize="small">
      <ZipCodeSvg />
    </SvgIcon>
  )
}

export default IconZipCode;