import HttpService from "../http-service";

const uri = 'EnvaseTrace/';

export async function getCredentials(){
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetCredentialList');
    return result.response;
}

export async function getCredential(providerID){
    const params = {
        providerID
    };
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetCredential', params);
    return result.response;
}

export async function getCredentialByID(envaseTraceProviderID){
    const params = {
        ID: envaseTraceProviderID
    };
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetCredentialByID', params);
    return result.response;
}

export async function updateCredential(request) {
    const httpService = new HttpService();
    const result = await httpService
        .post(uri + 'UpdateCredential', request);
    return result.response;
}

export async function deleteCredential(providerID) {
    const params = {
        providerID
    };
    const httpService = new HttpService();
    const result = await httpService
        .post(uri + 'DeleteCredential', null, params);
    return result.response;
};

export async function getProvider(id) { 
    const params = { 
        envaseTraceProviderID: id
    };
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetProvider', params);
    return result.response[0];
};

export async function getProviderList() {  
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetProviderList');
    return result.response;
};