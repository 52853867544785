import HttpService from '../http-service';
import Validation from '../validation';
import { getProvider } from '../envase_trace/EnvaseTraceService';

const token = localStorage.getItem('token');
const uri = 'Location/' + token + '/';
const validation = new Validation();

const serializeFilterArray = function(filters) {
  var httpString = '';
  for (var i = 0; i < filters.length; i++) {
    httpString += '&columnName[' + i +']=' + filters[i].ColumnName;
    httpString += '&condition[' + i +']=' + filters[i].Condition;
    httpString += '&value[' + i +']=' + filters[i].Value;
  }
  return httpString;
};

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  const location = result.data[0];
  location.Phone = location.Phone.trim();
  location.Fax = location.Fax.trim();
  location.TollFree = location.TollFree.trim();
  if (location.EnvaseTraceProviderID) { 
    var provider = await getProvider(location.EnvaseTraceProviderID);
    location.ProviderName = provider.Name;
    location.LocationCode = provider.LocationCode;
  }
  return location;
};

export async function create(locationFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', locationFormData);
  return result;
};

export async function update(locationFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', locationFormData);
  return result;
};

export async function getList(params) {  
  const httpService = new HttpService();
  const result = await httpService
    .get(`${uri}GetFilteredList?page=${params.page}&amount=${params.amount}&globalSearch=${params.globalSearch}&${serializeFilterArray(params.filters)}&isASC=${params.isASC}&sortBy=${params.sortBy}&showObsolete=${params.showObsolete}`);
  const response = result.data;
  for (const location of response) {
    if (!validation.isEmpty(location.City) && !validation.isEmpty(location.Province)) {
      location.City += ", ";
    }
    location.City += location.Province;
  }
  return response;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .get(`${uri}ExportFilteredList?page=${params.page}&amount=${params.amount}&globalSearch=${params.globalSearch}&${serializeFilterArray(params.filters)}&isASC=${params.isASC}&sortBy=${params.sortBy}&showObsolete=${params.showObsolete}`);
  return result;
};

export async function getNotes(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetNotes');
  return result.data;
};

export async function getContacts(id) {
  var params = {
    locationTemplateID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetContacts', params);
  const response = result.response;
  for (const contact of response) {
    contact.Name = contact.Name.trim();
    contact.Position = contact.Position.trim();
    contact.Email = contact.Email.trim();
    var number = contact.Phone.trim();
    if(number) {
      contact.Phone = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
    }
  }
  return response;
}

export async function getForSpinnerCompare(value) {
  var params = {compareValue: value ? value : ''};
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinnerCompare', params);
  const response = result.data;
  for (const location of response) {
    location.Name = location.InternalName.trim();
    location.SecondaryText = location.Address.trim() ? location.Address.trim() : '';
    location.SecondaryText += location.City.trim() ?  ", " + location.City.trim() : '';
    location.SecondaryText += location.Province.trim() ? ", " + location.Province.trim() : '';
    location.SecondaryText += location.PostalCode.trim() ? ", " + location.PostalCode.trim() : '';
    location.SecondaryText = location.SecondaryText.replace(/^,/, '');
  }
  return response;
};

export async function getDefaultICTSILocation() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDefaultICTSILocation');
  const response = result.data;
  for (const location of response) {
    location.Name = location.InternalName.trim();
    location.SecondaryText = location.Address.trim() ? location.Address.trim() : '';
    location.SecondaryText += location.City.trim() ?  ", " + location.City.trim() : '';
    location.SecondaryText += location.Province.trim() ? ", " + location.Province.trim() : '';
    location.SecondaryText += location.PostalCode.trim() ? ", " + location.PostalCode.trim() : '';
    location.SecondaryText = location.SecondaryText.replace(/^,/, '');
  }
  return response;
};

export async function createLocationContact(id, params) {
  params.locationTemplateID = id;
  // Strip the phone
  params.phone = params.phone.replace(/\D/g,'');
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateContact', params);
    return result;
};

export async function setProvider(locationTemplateID, providerID) { 
  var params = { 
    locationTemplateID: locationTemplateID,
    envaseProviderID: providerID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SetProvider', null, params);
  return result;
};