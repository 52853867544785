import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import AreaTagLineEditDialog from '../area_tag_line/AreaTagLineEditDialog.js'

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const AreaTagLineListColumnEditButton = (props) => {
  const {
    areaTagLineID,
    getAreaTagLines,
    currentFavorite,
  } = props;
  
  const classes = useStyles();
  const [isAreaTagLineEditModalOpen, setIsAreaTagLineEditModalOpen] = useState(false);

  const openAreaTagLineEditModal = () => {
    setIsAreaTagLineEditModalOpen(true);
  };

  const closeAreaTagLineEditModal = () => {
    setIsAreaTagLineEditModalOpen(false);
    getAreaTagLines();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openAreaTagLineEditModal}>
        <EditIcon/>&nbsp;
        <span>EDIT</span>
      </IconButton> 
      <AreaTagLineEditDialog
        areaTagLineID={areaTagLineID}
        isOpen={isAreaTagLineEditModalOpen}
        onClose={closeAreaTagLineEditModal}
        currentFavorite={currentFavorite}
      />
    </>
  );
}

AreaTagLineListColumnEditButton.propTypes = {
  areaTagLineID: PropTypes.number.isRequired,
  currentFavorite: PropTypes.number
};

export default AreaTagLineListColumnEditButton;