import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import IconAppointmentBook from '../icon/IconAppointmentBook';
import IconAppointmentCancel from '../icon/IconAppointmentCancel';

import OrderAppointmentCancelAppointmentListDialog from '../orderAppointment/OrderAppointmentCancelAppointmentListDialog';
import { Button, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { Event, MoreVert } from '@material-ui/icons';
import OrderAppointmentCreateDialog from '../orderAppointment/OrderAppointmentCreateDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'green',
    width: '170px',
    justifyContent: 'space-between',
  },
}));

const OrderListUpdateAppointment = (props) => { 
  const {
    order,
    getList,
    disable
  } = props;
  const classes = useStyles();
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);
  const [isAppointmentConfigureDialogOpen, setIsBookAppointmentConfigureDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openCancelAppointmentModal = () => { 
    setIsCancelAppointmentModalOpen(true);
  };

  const closeCancelAppointmentModal = () => { 
    getList();
    setIsCancelAppointmentModalOpen(false);
  };

  const openAppointmentConfigureDialog = () => { 
    setIsBookAppointmentConfigureDialogOpen(true);
  };

  const closeAppointmentConfigureDialog = () => { 
    setIsBookAppointmentConfigureDialogOpen(false);
    getList();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAppointmentDate = (appts) => {
    const apptArray = JSON.parse(appts);
    if (!apptArray[0].AppointmentDate) {
      return 'N/A';
    }
    return format(new Date(apptArray[0].AppointmentDate), 'MM/dd/yyyy');
  };

  return (
    <>
      <div>
      <Button
        variant='outlined'
        disabled={disable}
        className={classes.iconText}
        endIcon={<Event style={{marginLeft: '20px'}} />}
      >
        {order.Appointments ? getAppointmentDate(order.Appointments) : 'N/A'}
      </Button>
      <IconButton onClick={handleClick} onClose={handleClose}>
        <MoreVert />
      </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openCancelAppointmentModal}>
          <ListItemIcon>
            <IconAppointmentCancel />
          </ListItemIcon>
          <Typography>Cancel Appointment</Typography>
        </MenuItem>
        <MenuItem onClick={openAppointmentConfigureDialog}>
          <ListItemIcon>
            <IconAppointmentBook />
          </ListItemIcon>
          <Typography>Add Appointment</Typography>
        </MenuItem>
      </Menu>
      <OrderAppointmentCancelAppointmentListDialog
        isOpen={isCancelAppointmentModalOpen}
        onClose={closeCancelAppointmentModal}
        order={order}
      />
      <OrderAppointmentCreateDialog
        isOpen={isAppointmentConfigureDialogOpen}
        onClose={closeAppointmentConfigureDialog}
        order={order}
        containerNum={order.ContainerNumber}
      />
    </>
  )

}

export default OrderListUpdateAppointment;
