import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Select } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';


const CoreListPaidUnpaidField = (props) => { 
  const {
    name,
    value,
    onChange,
    size
  } = props;

  const [menuValue, setMenuValue] = useState('');

  const handleChange = (event) => { 
    const dispatchEvent = { 
      target: { 
        name: name,
        value: event.target.value
      }
    };
    onChange(dispatchEvent);
  };

  return ( 
    <FormControl fullWidth variant={'outlined'} size={size} InputLabelProps={{shrink: value ? true : false}}
    >
      <InputLabel>Paid</InputLabel>
      <Select
        value={menuValue}
        onChange={(e) => { setMenuValue(e.target.value); handleChange(e) }}
      >
        <MenuItem value={0}>Unpaid</MenuItem>
        <MenuItem value={1}>Paid</MenuItem>
        <MenuItem value={2}>All</MenuItem> 
      </Select>
    </FormControl>
  )
};

CoreListPaidUnpaidField.propTypes = { 
  name: PropTypes.string,
  value: PropTypes.string
};

export default CoreListPaidUnpaidField;