import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DispatchListStatusButton from './DispatchListStatusButton';
import Container from '@material-ui/core/Container';

import IconShipTypeSolid from '../icon/IconShipTypeSolid';
import IconReady from '../icon/IconReady';
import IconOnHold from '../icon/IconOnHold';
import IconDispatched from '../icon/IconDispatched';
import IconDropped from '../icon/IconDropped';
import IconDateCalSolid from '../icon/IconDateCalSolid';
import IconLFD from '../icon/IconLFD';

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    flexWrap: 'nowrap',
  },
}));

const DispatchListStatus = (props) => {
  const {
    dispatchStatuses,
    dispatches,
    updateStatusButtonChange,
    listWidth
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth={listWidth} className="mb-2">
        <Grid container justify="space-between" className={classes.gridWrap}>
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].AllCount : 0}
            name={"All"}
            label={"All"}
            dispatchStatus={dispatchStatuses.ShowAll}
            onChange={updateStatusButtonChange}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].OnVesselCount : 0}
            name={"ShowOnVessel"}
            label={"On Vessel"}
            dispatchStatus={dispatchStatuses.ShowOnVessel}
            onChange={updateStatusButtonChange}
            icon={<IconShipTypeSolid />}
            buttonColor={"#E573D8"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].OnHoldCount : 0}
            name={"ShowOnHold"}
            label={"On Hold"}
            dispatchStatus={dispatchStatuses.ShowOnHold}
            onChange={updateStatusButtonChange}
            icon={<IconOnHold />}
            buttonColor={"#FFB74D"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].ReadyCount : 0}
            name={"ShowReady"}
            label={"Ready"}
            dispatchStatus={dispatchStatuses.ShowReady}
            onChange={updateStatusButtonChange}
            icon={<IconReady />}
            buttonColor={"#81C784"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].ScheduledCount : 0}
            name={"ShowScheduled"}
            label={"Scheduled"}
            dispatchStatus={dispatchStatuses.ShowScheduled}
            onChange={updateStatusButtonChange}
            icon={<IconDateCalSolid />}
            buttonColor={"#FFD10A"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].DispatchedCount : 0}
            name={"ShowDispatched"}
            label={"Dispatched"}
            dispatchStatus={dispatchStatuses.ShowDispatched}
            onChange={updateStatusButtonChange}
            icon={<IconDispatched />}
            buttonColor={"#73AFE5"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].DroppedCount : 0}
            name={"ShowDropped"}
            label={"Dropped"}
            dispatchStatus={dispatchStatuses.ShowDropped}
            onChange={updateStatusButtonChange}
            icon={<IconDropped />}
            buttonColor={"#38A4D8"}
          />          
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].LastFreeDayCount : 0}
            name={"ShowLastFreeDay"}
            label={"Last Free Day"}
            dispatchStatus={dispatchStatuses.ShowLastFreeDay}
            onChange={updateStatusButtonChange}
            icon={<IconLFD />}
            buttonColor={"#E57373"}
          />
          <DispatchListStatusButton
            count={dispatches[0] ? dispatches[0].RejectedCount : 0}
            name={"ShowRejected"}
            label={"Rejected"}
            dispatchStatus={dispatchStatuses.ShowRejected}
            onChange={updateStatusButtonChange}
            icon={<IconLFD />}
            buttonColor={"#FF0000"}
          />
        </Grid>
      </Container>
    </div>
  );

}

export default DispatchListStatus;