import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import OrderListStatusButtonIcon from './OrderListStatusButtonIcon';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingLeft: 20,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 20,
    cursor: 'pointer',
    borderRadius: '8px',
    width: '100%',
    marginRight: '15px',
    "&:last-child": {
      marginRight: 5
    }
  },
  paperSelected: {
    paddingLeft: 20,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 20,
    cursor: 'pointer',
    backgroundColor: '#6639BF',
    color: '#FFFFFF !important',
    borderRadius: '8px',
    width: '100%',
    marginRight: '15px',
    "&:last-child": {
      marginRight: 5
    }
  },
}));

const OrderListStatusButton = (props) => {

  const {
    count,
    name,
    label,
    orderStatus,
    onChange,
    icon,
    buttonColor
  } = props;
  const classes = useStyles();

  const onStatusChange = () => {
    if (count !== 0) {
      const dispatchEvent = {
        target: {
          name,
          value: orderStatus
        }
      };
      onChange(dispatchEvent);
    }
  };


  return (
    <Paper onClick={onStatusChange}
      className={clsx({
        [classes.paper]: !orderStatus,
        [classes.paperSelected]: orderStatus
      })}
      elevation={1}
      spacing={1}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <Typography variant="h6" align="left">
            {count}
          </Typography>
        </Grid>
        {icon && <Grid item md={2} xs={2} align="right">
          <OrderListStatusButtonIcon
            backgroundColor={buttonColor}
            icon={icon}
            isSelected={orderStatus}
          />
        </Grid>}
        {!icon && <Grid item md={2} xs={2} align="right">
          &nbsp;
        </Grid>}
        <Grid item md={12} xs={12}>
          <small>{label}</small>
        </Grid>
      </Grid>
    </Paper>
  );

}

export default OrderListStatusButton;