import { React, useEffect, useState } from 'react';
import { CancelAppointmentDialog } from "@envasetechnologies/precheck-components";

import { Delete } from '../intermodal_order_appointments/IntermodalOrderAppointmentsService';
import { getToken } from '../order/ReservationService'
import { getTIN } from '../tax/TaxService';
import { useCartRequests } from '@envasetechnologies/feepay-components';

const OrderAppointmentCancelDialog = (props) => { 
  const { 
    isOpen,
    onClose,
    appointment,
    onAppointmentCancel
  } = props;

  const cartRequests = useCartRequests();
  const [cancelContainer, setCancelContainer] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [preCheckToken, setPreCheckToken] = useState('');
  const [motorCarrierTIN, setMotorCarrierTIN] = useState('');

  useEffect(() => {
  async function getPreCheckToken() {
    const response = await getToken();
    if (response) {
      setPreCheckToken(response);
    }
  };

  async function getTaxIdentificationNumber() {
    const response = await getTIN();
    if (response) {
      setMotorCarrierTIN(response);
      handleContainer(appointment);
    }
  }

  if (isOpen) {
    getPreCheckToken().then(() => {
      getTaxIdentificationNumber();
    });
  }
}, [isOpen]);

async function handleContainer(appointment) {
  setCancelContainer({
    gateTransactionId: appointment.GateTransactionID,
    locationCode: appointment.LocationCode,
    isDualGateTransaction: false,
    appointmentNumber: appointment.AppointmentNumber,
  });
  setIsDataLoaded(true);
};

const handleOnSuccess = async (response) => {
  await Delete(appointment.ID);
  if (response.data) {
    var productInput = { 
      id: '',
      externalId: appointment.InvoiceDraftNumber,
      type: 'ICTSI_APPOINTMENT_INVOICE',
      canRefresh: true,
      maxRefreshes: 10,
      initialDurationInMinutes: 1,
      refreshDurationInMinutes: 1
    };
    await cartRequests.requestDeleteAllCartItemsForProduct(productInput);
  }
  onAppointmentCancel();
};

const handleClose = () => { 
  setCancelContainer(null);
  onClose();
};

return (
  <div>
    {isDataLoaded && <CancelAppointmentDialog
        isOpen={isOpen && !!cancelContainer}
        onClose={handleClose}
        {...(cancelContainer || {})}
        onError={(error) => console.log(error)}
        onSuccess={(response) => handleOnSuccess(response)}
        accessToken={preCheckToken}
        orgTIN={null}
        motorCarrierTIN={null}
      />}
  </div>
);

}

export default OrderAppointmentCancelDialog;