import { TruckVisitAppointments } from '@envasetechnologies/precheck-components';
import { getToken } from '../order/ReservationService'
import { getTIN } from '../tax/TaxService';
import { useEffect, useState } from 'react';
import TruckVisitFilterRow from './TruckVisitFilterRow';
import TablePagination from '@mui/material/TablePagination';
import TruckVisitSortRow from './TruckVisitSortRow';
import { truckVisitAppointmentSearch } from './TruckVisitService';
import LinearProgress from '@material-ui/core/LinearProgress';

const TruckVisitListTable = () => {
    const [motorCarrierTIN, setMotorCarrierTIN] = useState('');
    const [preCheckToken, setPreCheckToken] = useState('');
    const [truckVisitAppointments, setTruckVisitAppointments] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState({});
    const [pagination, setPagination] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortBy: '',
        sortByDesc: false,
        takeAll: false,
    });
    const listWidth = 'xl';
    const headers = [
        {id: 'tvaNumber' , label: 'Truck Visit Appt #'},
        {id: 'truckVisitAppointmentStatus' , label: 'Status'},
        {id: 'startDateTime' , label: 'Start Time'},
        {id: 'truckerDriverLicenseNumber' , label: 'Driver License'},
        {id: 'tractorLicensePlateNumber' , label: 'Truck ID'},
        {id: 'gateID' , label: 'Gate'},
    ];

    useEffect(() => {
        async function getTaxIdentificationNumber() {
            const response = await getTIN();
            if (response) {
                setMotorCarrierTIN(response);
            }
        }

        async function getPreCheckToken() {
            const response = await getToken();
            if (response) {
                setPreCheckToken(response);
            }
        }

        getPreCheckToken().then(() => {
            getTaxIdentificationNumber();
        });
    }, []);

    useEffect (() => {
        const getData = async() => {
            setIsLoading(true);
            const payload = {
                ...searchOptions,
                pagination: pagination
            };
            const result = await truckVisitAppointmentSearch(payload);
            setTruckVisitAppointments(result?.response?.data?.items || []);
            setTotalCount(result?.response?.data?.totalCount || 0);
            setIsLoading(false);
        };

        getData();
    }, [searchOptions, pagination]);

    const resetFilterRow = () => {
        for (let key in searchOptions) {
            delete searchOptions[key];
        }
        setSearchOptions({...searchOptions});
        pagination.pageNumber = 0;
        setPagination({...pagination});
    };

    const updateFilterData = (e) => {
        const { name, value } = e.target;
        if(value === '') {
            delete searchOptions[name];
        } else {
            searchOptions[name] = value;
        }
        setSearchOptions({...searchOptions});
        pagination.pageNumber = 0;
        setPagination({...pagination});
    };

    const handlePageChange = (e, newPage) => {
        pagination.pageNumber = newPage;
        setPagination({...pagination});
    };

    const handleRowsPerPageChange = (e) => {
        pagination.pageSize = e.target.value;
        pagination.pageNumber = 0;
        setPagination({...pagination});
    };

    const handleSortList = (id) => {
        if (pagination.sortBy === id && pagination.sortByDesc) {
            pagination.sortBy = '';
            pagination.sortByDesc = false;
        } else if (pagination.sortBy === id && !pagination.sortByDesc) {
            pagination.sortByDesc = true;
        } else {
            pagination.sortBy = id;
            pagination.sortByDesc = false;
        }
        pagination.pageNumber = 0;
        setPagination({...pagination});
    };

    return (
        <div>
            <TruckVisitFilterRow
                listWidth={listWidth}
                filterData={searchOptions}
                resetFilterRow={resetFilterRow}
                updateFilterData={updateFilterData}
            />

            <TruckVisitSortRow
                headers={headers}
                updateSortData={handleSortList}
                pagination={pagination}
            />

            {!motorCarrierTIN ? <LinearProgress /> :
            <TruckVisitAppointments
                motorCarrierTIN={motorCarrierTIN}
                accessToken={preCheckToken}
                isLoading={isLoading}
                truckVisitAppointments={truckVisitAppointments}
            />}

            <TablePagination
                component="div"
                count={totalCount}
                page={pagination.pageNumber}
                onPageChange={ handlePageChange }
                rowsPerPage={pagination.pageSize}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 20, 50, 100]}
            />

        </div>
    )
}

export default TruckVisitListTable;