import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import NotesList from '../notes/NotesList';
import LocationViewGeneralSection from './LocationViewGeneralSection';
import LocationViewAddressSection from './LocationViewAddressSection';
import LocationViewInfoSection from './LocationViewInfoSection';
import ContactNoteTabBar from '../contact/ContactNoteTabBar';
import ContactListTable from '../contact/ContactListTable';
import LocationViewPageHeader from './LocationViewPageHeader';
import LocationAreaTagList from '../location_area_tag/LocationAreaTagList';

import { useLocationGetRequest } from '../hooks/useLocationGetRequest';
import { useLocationGetContactsRequest } from '../hooks/useLocationGetContactsRequest';
import { useLocationGetNotesRequest } from '../hooks/useLocationGetNotesRequest';
import { useLocationAreaTagGetListRequest } from '../hooks/useLocationAreaTagGetListRequest';

const LocationView = () => {
  const { id } = useParams();

  const locationResponse = useLocationGetRequest(id);
  const locationContactsResponse = useLocationGetContactsRequest(id);
  const locationNotesResponse = useLocationGetNotesRequest(id);
  const areaTagResponse = useLocationAreaTagGetListRequest(id);
  const [tabSelected, setTabSelected] = useState(0);

  const handleList = e => {
    const {value} = e.target;
    setTabSelected(value);
  };

  if (!locationResponse.isLoaded) return null;
  return (
    <div>
      <LocationViewPageHeader
        location={locationResponse.data}
      />
      <LocationViewGeneralSection
        location={locationResponse.data}
      />
      <Container 
        maxWidth="lg" 
        component={Paper} 
        style={{ backgroundColor: '#F3F0FA' }}>
        <LocationViewAddressSection
          location={locationResponse.data}
        />
      </Container>
      <LocationViewInfoSection
        location={locationResponse.data}
      />
      <ContactNoteTabBar 
        id={id}
        noteType={18}
        getContacts={locationContactsResponse.refetch}
        getNotes={locationNotesResponse.refetch}
        getAreaTags={areaTagResponse.refetch}
        onChange={e => handleList(e)}
        isFromCustomer={false}
        isFromLocation={true}
      />
      {
        tabSelected === 0 &&
        <ContactListTable
          contacts={locationContactsResponse.data}
          getContacts={locationContactsResponse.refetch}
          setContacts={locationContactsResponse.setResponse}
        />
      }
      {
        tabSelected === 1 &&
        <NotesList
          notes={locationNotesResponse.data}
          getNotes={locationNotesResponse.refetch}
        /> 
      }
      {
        tabSelected === 2 &&
        <LocationAreaTagList
          areaTags={areaTagResponse.data}
          getAreaTags={areaTagResponse.refetch}
        />
      }
    </div>
  );

}

export default LocationView;