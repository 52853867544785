import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './AreaTagLineService';
import AreaTagLineCreateDialogContent from '../area_tag_line/AreaTagLineCreateDialogContent';

const AreaTagLineCreateDialog = (props) => {
  const {
    areaTagID,
    isOpen,
    onClose
  } = props;

  const initialState = {
    AreaTagID: areaTagID,
    City: '',
    Province: '',
    PostalCode: '',
  };

  const [areaTagLineFormData, setAreaTagLineFormData] = useState(initialState);
  const [areaTagLineFormValidation, setAreaTagLineFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAreaTagLineFormValidation(buildValidation(areaTagLineFormData));
    }
  }, [areaTagLineFormData, isOpen]);

  const updateAreaTagLineFormData = e => {
    const { name, value } = e.target;
    areaTagLineFormData[name] = value;
    setAreaTagLineFormData({ ...areaTagLineFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(areaTagLineFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(areaTagLineFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error[0]);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setAreaTagLineFormData({...initialState});
    onClose();
  };

  return (
    isOpen &&
    <AreaTagLineCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      areaTagLineFormData={areaTagLineFormData}
      areaTagLineFormValidation={areaTagLineFormValidation}
      updateAreaTagLineFormData={updateAreaTagLineFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (areaTagLineFormData) => {
  const {
    AreaTagID,
    City,
    Province, 
    PostalCode
  } = areaTagLineFormData || {};
  const validation = new Validation();
  return {
    AreaTagID: validation.isNumber(AreaTagID),
    Province: (City !== '' && PostalCode === '') || (City === '' && PostalCode === '') ? validation.isEmpty(Province) : false,
    PostalCode: (City !== '' && Province === '') || (City === '' && Province === '') ? validation.isEmpty(PostalCode) : false,
  };
};

export default AreaTagLineCreateDialog;