import { React, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge } from '@mui/material';
import { useCartRequests } from '@envasetechnologies/feepay-components';
import { FeePayContext } from '@envasetechnologies/feepay-components';

const NavigationToolbarButtonShoppingCart = () => {  
  let history = useHistory();

  const cartRequests = useCartRequests();
  const [itemCount, setItemCount] = useState(0);
  const feePayContext = useContext(FeePayContext);

  useEffect(() => {
    async function getCartAmount() { 
      const result = await cartRequests.requestCart();    
      setItemCount(result.cartItems.length);
    }
    if (feePayContext.isCustomerLoaded){
      getCartAmount();
    }
  }, [feePayContext]);

  const handleClick = () => {
    history.push('/spark/cart')
  };

  return (
    <div>
      <Badge color="error" badgeContent={itemCount}>
        <Button name="navigation[send_feedback]" variant="outlined" color="primary" onClick={handleClick}>
          {<ShoppingCartIcon/>}
        </Button>
      </Badge>
    </div>
  );

}


export default NavigationToolbarButtonShoppingCart;