import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as IconLeftSvg } from '../assets/svg-icons/arrow-left@1x.svg';

const IconArrowLeft = () => {
  return (
    <SvgIcon fontSize="small">
      <IconLeftSvg />
    </SvgIcon>
  )
}
export default IconArrowLeft;