import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { enableEnvaseConnect } from './PersonnelService';
import { 
  getActiveEnvaseDriverCount, 
  getPurchasedEnvaseDriverCount 
} from '../subscription_management/SubscriptionManagementService';

const PersonnelEnableEnvaseMobile = (props) => {
  const {
    isOpen,
    onClose,
    driverName,
    personnelID
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [activeDriverCount, setActiveDriverCount] = useState(null);
  const [purchasedDriverCount, setPurchasedDriverCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showIncreaseWarning, setShowIncreaseWarning] = useState(false);

  useEffect(() => {
    async function getDriverCount() {
      const result = await getActiveEnvaseDriverCount();
      setActiveDriverCount(result);
    }
    async function getPurchasedDriverCount() {
      const result = await getPurchasedEnvaseDriverCount();
      setPurchasedDriverCount(result);
    }
    if (isOpen) {
      getDriverCount();
      getPurchasedDriverCount();
    }
  }, [isOpen]);

  useEffect(() => {
    if (activeDriverCount && purchasedDriverCount) {
      if (parseInt(activeDriverCount) >= parseInt(purchasedDriverCount)) {
        setShowIncreaseWarning(true);
      }
    }
  }, [activeDriverCount, purchasedDriverCount]);

  const onConfirm = async () => {
    setIsLoading(true);
    const result = await enableEnvaseConnect(personnelID);
    setIsLoading(false);
    if (result.error) {
      setErrorMessage(result.error);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setErrorMessage(null);
    setActiveDriverCount(null);
    setActiveDriverCount(null);
    setShowIncreaseWarning(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onConfirm}>
        <DialogTitle id="form-dialog-title" style={{textTransform: 'capitalize'}}>
          Enable Envase Mobile Driver
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to enable {driverName} for Envase Mobile?
          </DialogContentText>
          {errorMessage && 
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          }
          {showIncreaseWarning &&
            <Alert variant="outlined" severity='warning'>
              All of your driver subscriptions seats have already been allocated ({purchasedDriverCount}). By clicking "Enable Driver" below, your subscription will automatically increase to ({purchasedDriverCount} + 1) and applicable charged will be prorated on your next billing cycle.              
            </Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" disabled={isLoading}>
            Enable Driver { isLoading && <CircularProgress size={16} style={{marginLeft: '5px'}}/> }
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

PersonnelEnableEnvaseMobile.propTypes = {
  driverName: PropTypes.string.isRequired,
  personnelID: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default PersonnelEnableEnvaseMobile;
