import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

const CoreListNumberField = (props) => {
  const { value, isRequired, autoFocus, validationError, minValue, maxValue, disabled, isCurrency, ...rest } = props;

  return (
    <FormControl fullWidth>
      <Input
        type="number"
        value={value}
        autoFocus={autoFocus}
        inputProps = {{
          min: minValue,
          max: maxValue,
          step: isCurrency ? 0.01 : 0.00
        }}
        disabled={disabled}
        {...rest}
      />
    </FormControl>
  );
}

CoreListNumberField.propTypes = {
  value: PropTypes.number,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool
};

export default CoreListNumberField;
