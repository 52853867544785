import HttpService from '../http-service';
import HTTPConfiguration from '../http-configuration';
import Validation from '../validation';
import { format } from 'date-fns';
import { get as getReceivableChargeType } from '../receivable_charge_type/ReceivableChargeTypeService';
import { get as getPayableChargeType } from '../payableChargeType/PayableChargeTypeService';

const token = localStorage.getItem('token');
const uri = 'IntermodalOrder/' + token + '/';
const validation = new Validation();
const config = new HTTPConfiguration();

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  const response = result.response;
  response.VesselTime = response.VesselDate ? response.VesselDate.substring(11, 16) + " hrs" : null;
  response.VesselActualTime = response.VesselActualDate ? response.VesselActualDate.substring(11, 16) + " hrs" : null;
  response.ContainerReleaseTime = response.ContainerReleaseDate ? response.ContainerReleaseDate.substring(11, 16) + " hrs" : null;
  response.ContainerLastTime = response.ContainerLastDate ? response.ContainerLastDate.substring(11, 16) + " hrs" : null;
  response.VesselDate = response.VesselDate ? format(new Date(response.VesselDate), 'MM/dd/yyyy') : null;
  response.VesselActualDate = response.VesselActualDate ? format(new Date(response.VesselActualDate), 'MM/dd/yyyy') : null;
  response.ContainerReleaseDate = response.ContainerReleaseDate ? format(new Date(response.ContainerReleaseDate), 'MM/dd/yyyy') : null;
  response.ContainerLastDate = response.ContainerLastDate ? format(new Date(response.ContainerLastDate), 'MM/dd/yyyy') : null;  
  response.CutoffTime = response.CutoffDate ? response.CutoffDate.substring(11, 16) + " hrs" : null;
  response.CutoffDate = response.CutoffDate ? format(new Date(response.CutoffDate), 'MM/dd/yyyy') : null;  
  response.FirstReceivedTime = response.FirstReceivedDate ? response.FirstReceivedDate.substring(11, 16) + " hrs" : null;  
  response.FirstReceivedDate = response.FirstReceivedDate ? format(new Date(response.FirstReceivedDate), 'MM/dd/yyyy') : null;  
  response.EmptyReadyTime = response.EmptyReadyDate ? response.EmptyReadyDate.substring(11, 16) + " hrs" : null;
  response.EmptyReadyDate = response.EmptyReadyDate ? format(new Date(response.EmptyReadyDate), 'MM/dd/yyyy') : null;  
  response.LoadReadyTime = response.LoadReadyDate ? response.LoadReadyDate.substring(11, 16) + " hrs" : null;
  response.LoadReadyDate = response.LoadReadyDate ? format(new Date(response.LoadReadyDate), 'MM/dd/yyyy') : null;  
  return response;
};

export async function getForUpdate(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  const response = result.response;
  response.VesselTime = response.VesselDate ? new Date(response.VesselDate) : null;
  response.VesselActualTime = response.VesselActualDate ? new Date(response.VesselActualDate) : null;
  response.ContainerReleaseTime = response.ContainerReleaseDate ? new Date(response.ContainerReleaseDate) : null;
  response.ContainerLastTime = response.ContainerLastDate ? new Date(response.ContainerLastDate) : null;
  response.VesselDate = response.VesselDate ? format(new Date(response.VesselDate), 'MM/dd/yyyy') : null;
  response.VesselActualDate = response.VesselActualDate ? format(new Date(response.VesselActualDate), 'MM/dd/yyyy') : null;
  response.ContainerReleaseDate = response.ContainerReleaseDate ? format(new Date(response.ContainerReleaseDate), 'MM/dd/yyyy') : null;
  response.ContainerLastDate = response.ContainerLastDate ? format(new Date(response.ContainerLastDate), 'MM/dd/yyyy') : null;  
  response.CutoffTime = response.CutoffDate ? new Date(response.CutoffDate) : null;
  response.CutoffDate = response.CutoffDate ? format(new Date(response.CutoffDate), 'MM/dd/yyyy') : null;  
  response.FirstReceivedDate = response.FirstReceivedDate ? format(new Date(response.FirstReceivedDate), 'MM/dd/yyyy') : null;  
  response.FirstReceivedTime = response.FirstReceivedDate ? new Date(response.FirstReceivedDate) : null; 
  response.EmptyReadyTime = response.EmptyReadyDate ? new Date(response.EmptyReadyDate) : null;
  response.EmptyReadyDate = response.EmptyReadyDate ? format(new Date(response.EmptyReadyDate), 'MM/dd/yyyy') : null;  
  response.LoadReadyTime = response.LoadReadyDate ? new Date(response.LoadReadyDate) : null;
  response.LoadReadyDate = response.LoadReadyDate ? format(new Date(response.LoadReadyDate), 'MM/dd/yyyy') : null;  
  return response;
};

export async function getReceivables(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetReceivables', params);
  const response = result.response;
  for (const receivable of response) {
    receivable.UnitRate = receivable.UnitRate.toFixed(2);
    receivable.Subtotal = receivable.Subtotal.toFixed(2);
    var chargeTypeName = await getReceivableChargeType(receivable.ReceivableChargeTypeID);
    receivable.ReceivableChargeType = chargeTypeName.Name;
  }
  return response;
};

export async function getEquipment(id) {
  var params = {
    orderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEquipment', params);
  const response = result.response;
  for (const equipment of response) {
    if (equipment.UnitType === 0) {
      equipment.UnitType = "Tractor";
    } else if (equipment.UnitType === 1) {
      equipment.UnitType = "Trailer";
    } else if (equipment.UnitType === 2) {
      equipment.UnitType = "Container";
    } else if (equipment.UnitType === 3) {
      equipment.UnitType = "Chassis";
    }
  }
  return response;
};

export async function getEvents(id) {
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalLegs', params);
  const response = result.response;
  for (const leg of response) {
    leg.IsLegOpen = true;
    for (const event of leg.IntermodalEvents) {
      event.Length = leg.IntermodalEvents.length;
    }
  }
  return response;
};

export async function createIntermodalLeg(id) {
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreateIntermodalLeg', null, params);
  return result;
};

export async function getPayables(id) { 
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPayables', params);
  const response = result.data;
  for (const payable of response) {
    payable.BillDate = payable.BillDate ? format(new Date(payable.BillDate), 'MM/dd/yyyy') : null;
    payable.UnitRate = payable.UnitRate.toFixed(2);
    payable.Subtotal = payable.Subtotal.toFixed(2);
    var chargeTypeName = await getPayableChargeType(payable.PayableChargeTypeID);
    payable.PayableChargeType = chargeTypeName.Name;
  } 
  return response;
};

export async function getNotes(id) { 
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetNotes', params);
  return result.response;
};

export async function addDocument(id, document) {
  var formData = new FormData();
  formData.append('file', document);
  var params = {
    id: id
  }
  const httpService = new HttpService();
  const result = await httpService
    .postFile(uri + 'AddDocument', formData, params);
  return result;
};

export async function deleteDocument(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'DeleteDocument', null, params);
  return result;
};

export async function getDocument(id) {
  return config.API_URL + uri + id + '/GetDocument';
};

export async function getDocuments(id) {
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDocuments', params);
  const response = result.response;
  for (const document of response) {
    document.DateCreated = format(new Date(document.DateCreated), 'MM/dd/yyyy');
  }
  return response;
};

export async function getDocumentTypes() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDocumentTypes');
    const response = result.response;
    for (const type of response) {
      type.Name = type.Description;
    }
    return response;
};

export async function saveDocumentType(id, documentTypeID) {
  var params = {
    id: id,
    documentTypeID: documentTypeID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SaveDocumentType', null, params);
  return result;
};


export async function create(orderFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', orderFormData);
  return result;
};

export async function update(orderFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', orderFormData);
  return result;
};

export async function getList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetIntermodalOrderList', params);
  const response = result.response;
  for (const order of response) {
    if (!validation.isEmpty(order.OriginCity) && !validation.isEmpty(order.OriginState)) {
      order.OriginCity += ", ";
    }
    order.OriginCity += order.OriginState;
    if (!validation.isEmpty(order.DestinationCity) && !validation.isEmpty(order.DestinationState)) {
      order.DestinationCity += ", ";
    }
    order.DestinationCity += order.DestinationState;
  }
  return response;
};

export async function getIntermodalInvoiceList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetIntermodalInvoiceList', params);
  const response = result.data;
  for (const order of response) {
    if (!validation.isEmpty(order.OriginCity) && !validation.isEmpty(order.OriginState)) {
      order.OriginCity += ", ";
    }
    order.OriginCity += order.OriginState;
    if (!validation.isEmpty(order.DestinationCity) && !validation.isEmpty(order.DestinationState)) {
      order.DestinationCity += ", ";
    }
    order.DestinationCity += order.DestinationState;
  }
  return response;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ExportIntermodalOrderList', params);
  return result;
};

export async function copyOrder(orderCopyFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CopyOrder', orderCopyFormData);
  const response = result;
  response.ID = response.response;
  var container = await getContainers(response.response);
  response.Container = container[0];
  var order = await get(response.response);
  response.OrderNum = order.OrderNum;
  return response;
};

export async function cancel(orderID) { 
  var params = { 
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Cancel', null, params);
  const response = result;
  return response;
};

export async function reactivate(orderID) { 
  var params = {
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Reactivate', null, params);
  const response = result;
  return response;
};

export async function deleteOrder(orderID) { 
  var params = {
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  const response = result;
  return response;
};

export async function isAbleToEditOrderOrigin(id) { 
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsAbleToEditOrderOrigin', params);
  return result.response === 'true';
};

export async function isAbleToEditOrderDestination(id) { 
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsAbleToEditOrderDestination', params);
  return result.response === 'true';
};

export async function getContainers(id) { 
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetContainers', params);
  const response = result.response;
  return response;
};

export async function getLoadedEmptyStatus(id) { 
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetLoadEmptyStatus', null, params);
  if (result.data[0].LoadedEmptyStatus === "L") { 
    return "Loaded"
  } else { 
    return "Unloaded"
  }
};

export async function favorite(orderID) { 
  var params = {
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Favorite', null, params);
  const response = result;
  return response;
};

export async function unfavorite(orderID) { 
  var params = {
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'unfavorite', null, params);
  const response = result;
  return response;
};

export async function complete(orderID) { 
  var params = {
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Complete', null, params);
  const response = result;
  return response;
};

export async function markAsFullyPaid(orderID) {  
  var params = { 
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MarkAsFullyPaid', null, params);
  const response = result;
  return response;
};

export async function markAsUnpaid(orderID) {  
  var params = { 
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MarkAsUnpaid', null, params);
  const response = result;
  return response;
};

export async function getEnvaseTraceEvents(orderID) { 
  var params = { 
    intermodalOrderID: orderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetEnvaseTraceEvents', params);
  const response = result.response;
  for (const row of response) { 
    row.DateTime = row.Date ? row.Date.substring(11, 16) + " hrs" : null;
    row.Date = row.Date ? format(new Date(row.Date), 'MM/dd/yyyy') : null;
    row.EventDateTime = row.EventDate ? row.EventDate.substring(11, 16) + " hrs" : null;
    row.EventDate = row.EventDate ? format(new Date(row.EventDate), 'MM/dd/yyyy') : null;
  }
  return response;
};

export async function getIntermodalEvents(id) {
  var params = {
    intermodalOrderID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetIntermodalEvents', null, params);
  const response = result.response;
  for (const row of response) { 
    row.Selected = false;
  }
  return response;
};

export async function getTerminalCharges(params) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetTerminalCharges', params);
  if (result.response.data.result[0]) {
    return result.response.data.result[0];
  } else  if (result.response.data.result) {
    return result.response.data.result;
  }
  return result.response;
};