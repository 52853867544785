import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CoreButtonContainer from '../core/CoreButtonContainer';
import IconOnHold from '../icon/IconOnHold';
import IconReady from '../icon/IconReady';
import IconArrowRight from '../icon/IconArrowRight';
import IconArrowLeft from '../icon/IconArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoreExitButton from '../core/CoreExitButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  },
}));

const IntermodalInvoiceViewPageHeader = (props) => {
  const classes = useStyles();
  let history = useHistory();

  const {
    title,
    orderCount,
    orderPosition,
    nextFunction,
    previousFunction,
    readyForBillingFunction,
    billingOnHoldFunction,
    inProgress,
    listInProgress
  } = props;

  const onClose = () => {
    history.push('/spark/intermodal-invoice');
  };

  return (
    <Paper 
      variant="outlined" 
      className={classes.purpleBackground}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              { title }
            </Typography>
          </Grid>
          <Grid item md={6} spacing={2} container style={{paddingRight: '75px'}} justify="flex-end" alignItems="center">
            <CoreButtonContainer>
              {orderCount > 0 &&
              <>
                <IconButton
                  color="primary"
                  aria-label="Previous Page"
                  component="span"
                  className={classes.iconColor}
                  onClick={previousFunction}
                  disabled={inProgress}>
                  <IconArrowLeft />
                </IconButton>
                {listInProgress &&
                  <span>
                    <CircularProgress size={20} />
                  </span>
                }
                {!listInProgress &&
                  <span>
                    {orderPosition} of {orderCount}
                  </span>
                }
                <IconButton 
                  color="primary" 
                  aria-label="Next Page" 
                  component="span"                
                  className={classes.iconColor}
                  onClick={nextFunction}
                  disabled={inProgress}>
                  <IconArrowRight />
                </IconButton>
              </>
              }
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.buttonColor}
                disabled={inProgress}
                onClick={billingOnHoldFunction}
                endIcon={
                  <span className={classes.iconColor}>
                    <IconOnHold />
                  </span>
              }>
                BILLING ON HOLD                
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.buttonColor}
                disabled={inProgress}
                onClick={readyForBillingFunction}
                endIcon={
                  <span className={classes.iconColor}>
                    <IconReady />
                  </span>
              }>
                APPROVED FOR BILLING
              </Button>
            </CoreButtonContainer>
          </Grid>
        </Grid>
        <CoreExitButton
          onClick={onClose}
        />
      </Container>
    </Paper>
  );
}

IntermodalInvoiceViewPageHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default IntermodalInvoiceViewPageHeader;