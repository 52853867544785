import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconCurrency from '../icon/IconCurrency';

const NavigationDrawerListItemTransactionHistory = () => { 
  return ( 
    <NavigationDrawerListItem
      title={"Transaction History"}
      icon={<IconCurrency />}
      uri={"/spark/transaction-history"}
    />
  );
};  

export default NavigationDrawerListItemTransactionHistory;