import React, { useEffect, useState } from 'react';
import CoreRouter from './CoreRouter';
import Navigation from '../navigation/Navigation';
import { makeStyles } from '@material-ui/core/styles';
import { getData as pendoServiceGetData } from '../pendo/PendoService';
import { getData as intercomServiceGetData } from '../intercom/IntercomService';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';
import { 
  getOrganizationID as accountServiceGetOrganizationId
} from '../account/AccountService';

import { useCustomerRequests } from '@envasetechnologies/feepay-components';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: [
      'Inter',
      'cursive',
    ].join(','),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    marginLeft: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    },
  },
  footer: {
    height: '30px',
    width: '100%'
  }
}));

const CoreApp = (props) => {
  const {
    updateFeePayContext
  } = props;
  const classes = useStyles();
  const [isPendoInitialized,] = useState(false);
  const [isIntercomInitialized,] = useState(false);
  const customerRequests = useCustomerRequests();


  async function initializeFeePayCustomer(companyFeature) {
    const orgId = await accountServiceGetOrganizationId();
    var externalID = orgId + '/' + companyFeature.CompanyID;
    try { 
      var result = await customerRequests.requestCustomerByExternalId(externalID);
      updateFeePayContext(result);
    } catch (error) { 
      createFeePayCustomer(externalID);
    }
  };

  async function createFeePayCustomer(externalID) { 
    var body = {
      externalId: externalID,
      metadata: null
    }
    var result = await customerRequests.requestCreateCustomer(body);
    updateFeePayContext(result);
  };

  useEffect(async () => { 
    const companyFeature = await getCompanyFeature();
    if (companyFeature.IsICTSI) { 
      initializeFeePayCustomer(companyFeature);
    }
  }, []);

  useEffect(() => {
    async function initializePendo() {
      const response = await pendoServiceGetData();
      window.pendo.initialize({
        visitor: {
          id: response.userID,
          email: response.email,
          full_name: response.full_name,
          is_paying: response.is_paying,
          monthly_value: response.monthly_value,
          creationDate: response.createionDate,
          username: response.username,
          companyName: response.companyName,
          companyID: response.companyID,
          ignoreSubscription: response.ignoreSubscription,
          purchasedTMSUsers: response.purchasedTMSUsers,
          purchasedMobileUsers: response.purchasedMobileUsers,
          activeTMSUsers: response.activeTMSUsers,
          activeMobileUsers: response.activeMobileUsers
        },

        account: {
          id: response.companyName,
          email: response.emai,
          full_name: response.full_name,
          is_paying: response.is_paying,
          monthly_value: response.monthly_value,
          creationDate: response.createionDate,
          username: response.username,
          companyName: response.companyName,
          companyID: response.companyID,
          ignoreSubscription: response.ignoreSubscription,
          purchasedTMSUsers: response.purchasedTMSUsers,
          purchasedMobileUsers: response.purchasedMobileUsers,
          activeTMSUsers: response.activeTMSUsers,
          activeMobileUsers: response.activeMobileUsers,
          planPrice: 49
        }
      });
    };
    initializePendo();
  }, [isPendoInitialized]);

  useEffect(() => { 
    async function initializeIntercom() {  
      const response = await intercomServiceGetData();
      window.tailwind_id = response.user_id;
      if(!response.login_id.includes("SUPPORT_")) {
          window.Intercom("boot", {
              app_id: "d3ui2qgw",
              name: response.name,
              email: response.email,
              user_id: response.user_id,
              login_id: response.login_id,
              company_id: response.company_id,
              company_code: response.company_code,
              is_verified: response.is_verified,
              sf_account_id: response.sf_account_id,
              user_hash: response.user_hash,
              company: {
                  id: response.company.id,
                  name: response.company.name,
                  created_at: response.company.created_at,
                  plan: response.company.plan,
                  total_revenue: response.company.total_revenue,
                  status: response.company.status,
                  next_bill_date: response.company.next_bill_date,
                  card_on_file: response.company.card_on_file,
                  monthly_spend: response.company.monthly_spend,
                  user_licenses: response.company.user_licenses,
                  sf_account_id: response.company.sf_account_id,
                  product: "ENVASE"
              }
          });
          window.Intercom("update", {
              app_id: "d3ui2qgw",
              name: response.name,
              email: response.email,
              user_id: response.user_id,
              login_id: response.login_id,
              company_id: response.company_id,
              company_code: response.company_code,
              is_verified: response.is_verified,
              sf_account_id: response.sf_account_id,
              user_hash: response.user_hash,
              company: {
                  id: response.company.id,
                  name: response.company.name,
                  created_at: response.company.created_at,
                  plan: response.company.plan,
                  total_revenue: response.company.total_revenue,
                  status: response.company.status,
                  next_bill_date: response.company.next_bill_date,
                  card_on_file: response.company.card_on_file,
                  monthly_spend: response.company.monthly_spend,
                  user_licenses: response.company.user_licenses,
                  sf_account_id: response.company.sf_account_id,
                  product: "ENVASE"
              }
          });
      }
    };
    initializeIntercom();
  }, [isIntercomInitialized]);

  return (
    <div>
      <Navigation/>
      {/* CONTENT */}
      <div className={classes.content}>
          <div className={classes.toolbar} />
          <CoreRouter/>
      </div>
      <div className={classes.footer} />
    </div>
  );

}

export default CoreApp;