import { useEffect, useState, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import CorePageHeader from '../core/CorePageHeader';
import FeePayShoppingCartPaymentResultFailure from './FeePayShoppingCartPaymentResultFailure';
import FeePayShoppingCartPaymentResultSuccess from './FeePayShoppingCartPaymentResultSuccess';
import FeePayShoppingCartPaymentResultProcessing from './FeePayShoppingCartPaymentResultProcessing';

import { usePaymentRequests } from '@envasetechnologies/feepay-components';
import { FeePaySnackBarContext } from './FeePaySnackBarContext';

const FeePayShoppingCartPaymentResult = () => { 
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(null);

  const { setFeePaySnackBarContext } = useContext(FeePaySnackBarContext);
  const paymentRequests = usePaymentRequests();

  const urlParams = new URLSearchParams(window.location.search);
  const intentId = urlParams.get("intentId");
  const status = urlParams.get("status");
  const autoConfirm = urlParams.get("autoConfirm");
  const isADR = urlParams.get("isADR");

  useEffect(() => {
    if (intentId) {
      (async () => {
        try {
          setIsLoading(true);
          var paymentIntentDetails = await paymentRequests.requestIntentById({paymentIntentId: intentId});
          if (isADR && autoConfirm && status === 'AUTHORIZED') { 
            await processADRPayment(paymentIntentDetails);
          } else if (autoConfirm && status === 'AUTHORIZED') {
            await processBankPayment(paymentIntentDetails);
          }
          setIsLoading(false);
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentId, paymentRequests]);

  const processADRPayment = async (paymentIntentDetails) => { 
    if (paymentIntentDetails.status === "TRANSACTION_FAILED") { 
      setIsPaymentSuccess(false);
    } else { 
      setIsPaymentSuccess(true);
      await populateReceiptDownloadSnackBar(paymentIntentDetails);
    }
  };

  const processBankPayment = async (paymentIntentDetails) => { 
    paymentIntentDetails = await confirmPaymentWithAdditionalData(paymentIntentDetails.id, null);
    if (paymentIntentDetails.status === "TRANSACTION_FAILED") { 
      setIsPaymentSuccess(false);
    } else { 
      setIsPaymentSuccess(true);
      await populateReceiptDownloadSnackBar(paymentIntentDetails);
    }
  };

  const confirmPaymentWithAdditionalData = async (paymentIntentId, additionalData) => {
    var dataPlusTestConfigOptions = additionalData;
    return await paymentRequests.requestConfirmPayment({
      paymentIntentId: paymentIntentId,
      additionalData: {...dataPlusTestConfigOptions?.additionalData},
    });
  };

  const populateReceiptDownloadSnackBar = async (paymentIntent) => { 
    try { 
      var receipt = await paymentRequests.requestReceiptById({paymentIntentId: paymentIntent.id});
      const pdfDownloadLink = 'data:application/pdf;base64,' + receipt.base64EncodedContent    
      setFeePaySnackBarContext({
        message: 'Payment Processed',
        isOpen: true,
        pdfDownloadLink: pdfDownloadLink
      });
    } catch (e) { 
      console.error(e);
    }
  };

  return (
    <div>
      <CorePageHeader
        title="Cart"
        breadCrumb="Home / ... / Cart"
      />
      <Container maxWidth={'lg'} className="mt-4" style={{textAlign: 'center'}}>
        <Grid container spacing={2} className="mt-1 mb-1">
          <Grid item xs={1}></Grid>   
          <Grid item xs={10}>
            {isLoading && <LinearProgress />}
            <Container maxWidth="xl" className="m1-1 munaGrid" component={Paper}> 
              {isLoading && 
                <FeePayShoppingCartPaymentResultProcessing />
              }
              {!isLoading && <>
                {!isPaymentSuccess && isPaymentSuccess != null &&
                  <FeePayShoppingCartPaymentResultFailure />
                }
                {isPaymentSuccess && 
                  <FeePayShoppingCartPaymentResultSuccess />
                }
              </>}
            </Container>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default FeePayShoppingCartPaymentResult;