import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AccountSvg } from '../assets/svg-icons/account_balance_24px@1x.svg';

const IconAccountBalance = () => {
  return (
    <SvgIcon fontSize="small">
      <AccountSvg />
    </SvgIcon>
  )
}
export default IconAccountBalance;