import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconDispatched from '../icon/IconDispatched';
import IconPendingInvoice from '../icon/IconPendingInvoice';
import IconCheck from '../icon/IconCheck';

const useStyles = makeStyles((theme) => ({
  pendingAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  inProgressAvatar: {
    backgroundColor: '#FFB74D',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  completedAvatar: {
    backgroundColor: '#81C784',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    filter: 'invert(1)',
  }
}));

const IntermodalEventListStatus = (props) => {
  const classes = useStyles();
  const pendingTitle = 'Pending';
  const inProgressTitle = 'In Progress';
  const completedTitle = 'Completed';

  const {
    event
  } = props;

  return (
    <>
      { // Pending
        (event.ActualArrivalDate === null) &&
        <Tooltip
          title={
            <div>
              {pendingTitle}
            </div>
          }>
          <Avatar
            className={classes.pendingAvatar}>
            <div className={classes.icon}>
              <IconPendingInvoice fontSize="small" />
            </div>
          </Avatar>
        </Tooltip>
      }
      { // In Progress
        (event.ActualArrivalDate !== null && event.ActualDepartDate === null) &&
        <Tooltip
          title={
            <div>
              {inProgressTitle}
            </div>
          }>
          <Avatar
            className={classes.inProgressAvatar}>
            <div className={classes.icon}>
              <IconDispatched fontSize="small" />
            </div>
          </Avatar>
        </Tooltip>
      }
      { // Completed
        (event.ActualArrivalDate !== null && event.ActualDepartDate !== null) &&
        <Tooltip
          title={
            <div>
              {completedTitle}
            </div>
          }>
          <Avatar
            className={classes.completedAvatar}>
            <div className={classes.icon}>
              <IconCheck fontSize="small" />
            </div>
          </Avatar>
        </Tooltip>
      }
    </>
  );

};

IntermodalEventListStatus.propTypes = {
  invoice: PropTypes.object
};

export default IntermodalEventListStatus;