import Grid from '@material-ui/core/Grid';
import CoreNotePaper from '../core/CoreNotePaper';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';

const CustomerViewSummaryNoteSection = (props) => {
  const { note } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="LAST NOTE" />
        </Grid>
        <Grid container item xs={12}>
          {note.Note && <CoreNotePaper note={note}/>}
        </Grid>        
      </Grid>
    </div>
  );
}

export default CustomerViewSummaryNoteSection;
