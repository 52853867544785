import React from 'react';
import {Card, CardHeader, Avatar, Box } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: "70px",
    width: "90%",
    margin: "0 auto",
    cursor: "pointer",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardHeader: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  selected: {
    backgroundColor: "#6639bf",
    color: "white"
  }
}));

const getInitials = (name) => {
  if (name && name.length > 0) {
    if (name.includes(" ")) {
      return name.split(" ").shift().charAt(0) + name.split(" ").pop().charAt(0)
    }
    return name.split(" ").shift().charAt(0)
  }
  return "";
}

const ItineraryDriverList = (props) => {
  const {
    drivers, 
    onDriverClick, 
    selectedDriver, 
    driverSearch,
    driversLoading,
    containerHeight
  } = props;

  const classes = useStyles();

  driversLoading && console.log("driversLoading", drivers);
  return (
    <Box style={{minHeight: containerHeight, maxHeight: containerHeight, overflow: 'auto', marginTop: '20px'}}>
      {drivers && drivers
      .filter(driver => driver.DriverName.match(new RegExp(driverSearch, "i")))
      .map((driver, index) => (
        <Card
          elevation={0}
          className={classes.cardRoot}
          variant='outlined'
          onClick={() => {onDriverClick(driver)}}
          key={index}
        >
          <div
            className={`${classes.cardHeader} ${driver.DriverID === selectedDriver.DriverID ? classes.selected : ""}`}
          >
            <CardHeader
              avatar={
                <Avatar style={{backgroundColor: '#9575CD'}} aria-label='photo'>{getInitials(driver.DriverName)}</Avatar>
              }
              title={driver.DriverName}
            />
            <ArrowForwardIcon
              fontSize='small'
              style={
                {
                  color: driver.DriverID === selectedDriver.DriverID
                  ?'rgba(255,255, 2555, 1)'
                  :'rgba(0, 0, 0, 0.54)', marginRight:"10%"
                }
              }
            />
          </div>
        </Card>
      ))}
    </Box>
  )
}

export default ItineraryDriverList