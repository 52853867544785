import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'AreaTag/' + token + '/';

export async function get(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  return result.response;
};

export async function getLocationsToApply(id) {
  var params = {
    areaTagID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetLocationsToApply', params);
  return result.response;
};

export async function create(areaTagFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', areaTagFormData);
  return result;
};

export async function update(areaTagFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', areaTagFormData);
  return result;
};

export async function deleteAreaTag(id) {
  var params = {
    areaTagID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};

export async function applyAreaTagToLocations(id) {
  var params = {
    areaTagID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ApplyAreaTagToLocations', null, params);
  return result;
};