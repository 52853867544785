import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CoreNumberField from '../core/CoreNumberField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CustomerField from '../customer/CustomerField';
import OrderTypeField from './OrderTypeField';
import OrderDropHookField from './OrderDropHookField';
import CoreTextField from '../core/CoreTextField';
import OrderLoadedStatusField from './OrderLoadedStatusField';
import OrderWeightUOMField from './OrderWeightUOMField';

const useStyles = makeStyles(() => ({
  line: {
    opacity: 0.3
  }
}));

const OrderCreateContentInfo = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    orderFormValidation,
    updateOrderFormData
  } = props;

  return (
    <>
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>General Information</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{paddingBottom: '10px'}}>
          <Grid item md={4} xs={4}>
            <CustomerField
              name="customer"
              label="Bill to Customer"
              value={orderFormData.customer}
              nameData="customerID"
              valueData={orderFormData.customerID}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
              validationError={orderFormValidation.customer}
              autoFocus={true}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <OrderTypeField
              name="type"
              label="Type"
              value={orderFormData.type}
              validationError={orderFormValidation.type}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
            />
          </Grid>
          {orderFormData.type !== "One Way" && <Grid item md={2} xs={2}>
            <OrderDropHookField
              name="liveDrop"
              label="Live/Drop"
              value={orderFormData.liveDrop}
              validationError={orderFormValidation.liveDrop}
              onChange={e => updateOrderFormData(e)}
              isRequired={(orderFormData.type !== "One Way")}
            />
          </Grid>}
          {orderFormData.type === "One Way" && <Grid item md={2} xs={2}>
            <OrderLoadedStatusField
              name="loadedStatus"
              label="Loaded Status"
              value={orderFormData.loadedStatus}
              validationError={orderFormValidation.loadedStatus}
              onChange={e => updateOrderFormData(e)}
              isRequired={(orderFormData.type === "One Way")}
            />
          </Grid>}
          <Grid item md={2} xs={2}>
            <CoreNumberField
              name="weight"
              label="Container Weight"
              minValue={0}
              value={orderFormData.weight}
              validationError={orderFormValidation.weight}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <OrderWeightUOMField
              name="weightUOM"
              label="Weight UOM"
              value={orderFormData.weightUOM}
              validationError={orderFormValidation.weightUOM}
              onChange={e => updateOrderFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <CoreNumberField
              name="numPieces"
              label="Number of Pieces"
              minValue={0}
              value={orderFormData.numPieces}
              validationError={orderFormValidation.numPieces}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <CoreTextField
              name="referenceNumber"
              label="Reference Number"
              value={orderFormData.referenceNumber}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={4} xs={4}>
           {orderFormData.type === "Export" && <CoreTextField
              name="bookingNum"
              label="Booking #"
              value={orderFormData.bookingNum || ""}
              onChange={e => updateOrderFormData(e)}
              maxLength={50}
            />}
            {orderFormData.type === "Import" && <CoreTextField
              name="bookingNum"
              label="MasterBL"
              value={orderFormData.bookingNum || ""}
              onChange={e => updateOrderFormData(e)}
              maxLength={50}
            />}
            {orderFormData.type === "One Way" && <CoreTextField
              name="bookingNum"
              label="Booking # / MasterBL"
              value={orderFormData.bookingNum || ""}
              onChange={e => updateOrderFormData(e)}
              maxLength={50}
            />}
          </Grid>
        </Grid>
        </Container>
        <Container square={true} maxWidth="lg" component={Paper} style={{padding: 0}}>
          <hr className={classes.line}></hr>
        </Container>
        <Container square={true} maxWidth="lg" component={Paper}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={2}>
              <CoreCheckboxField
                name="isOverweight"
                label="Overweight"
                value={orderFormData.isOverweight}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <CoreCheckboxField
                name="isHazmat"
                label="HAZMAT"
                value={orderFormData.isHazmat}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <CoreCheckboxField
                name="onHold"
                label="On Hold"
                value={orderFormData.onHold}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            {/*
            {orderFormData.onHold && <Grid item md={3} xs={6}>
              <CoreTextField
                name="holdReason"
                label="Reason for Hold"
                value={orderFormData.holdReason}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            }
            */}
            <Grid item md={2} xs={2}>
              <CoreCheckboxField
                name="isExpedite"
                label="Expedite"
                value={orderFormData.isExpedite}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <CoreCheckboxField
                name="isTempManaged"
                label="Temperature Management"
                value={orderFormData.isTempManaged}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
          </Grid>
      </Container>
    </>
  );

}

export default OrderCreateContentInfo;