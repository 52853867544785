import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomerField from '../customer/CustomerField';
import LocationField from '../location/LocationField';
import CoreBooleanField from '../core/CoreBooleanField';
import CoreOutlinedNumberField from '../core/CoreOutlinedNumberField';
import EquipmentTypeField from '../equipment_type/EquipmentTypeField';
import OrderTypeField from '../order/OrderTypeField';
import CarrierEquipmentListField from '../carrier/CarrierEquipmentListField';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const DashboardDispatchViewFilterRow = (props) => {
  const {
    filterData,
    updateFilterData,
    resetFilterRow,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth={'xl'}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <CustomerField
                  name="Customer"
                  label="Customer"
                  value={filterData.Customer}
                  nameData="CustomerID"
                  valueData={filterData.CustomerID}
                  variant={"outlined"}
                  size={"small"}
                  onChange={e => updateFilterData(e)}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <LocationField
                  name="LocationTemplateID"
                  label="Location"
                  value={filterData.LocationTemplateID}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CarrierEquipmentListField
                  name="SteamshipLine"
                  label="Steamship Line"
                  value={filterData.SteamshipLine}
                  nameData="SteamshipLineID"
                  valueData={filterData.SteamshipLineID}
                  onChange={e => updateFilterData(e)}  
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <CoreBooleanField
                  name={"Appointment"}
                  label="Appointment"
                  value={filterData.Appointment}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container spacing={2}>              
              <Grid item md={4} xs={4}>
                <CoreOutlinedNumberField
                  name="ContainerSize"
                  label="Container Size"
                  minValue={0}
                  value={filterData.ContainerSize}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <EquipmentTypeField
                  name="ContainerType"
                  label="Container Type"
                  value={filterData.ContainerType}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <OrderTypeField
                  name="ShipmentType"
                  label="Shipment Type"
                  value={filterData.ShipmentType}
                  onChange={e => updateFilterData(e)}
                  variant={"outlined"}
                  size={"small"}
                  noDefault={true}
                />
              </Grid>
            </Grid>
          </Grid>          
          <Grid item md={1} xs={1}>
            <Grid container spacing={1} justify="flex-end">
              <IconButton 
                item md={2}
                className={classes.iconText} 
                onClick={resetFilterRow}>
                <HighlightOffIcon />&nbsp;<span>RESET</span>
              </IconButton>             
            </Grid>
          </Grid>         
        </Grid>
      </Container>
    </div>
  );

}

export default DashboardDispatchViewFilterRow;