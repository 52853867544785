import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CoreTextField from '../core/CoreTextField';
import CorePhoneField from '../core/CorePhoneField';
import CoreNumberField from '../core/CoreNumberField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CountryField from '../country/CountryField';
import ProvinceField from '../province/ProvinceField';

const PersonnelCreateContentVendorInfo = (props) => {
  const {
    VendorFormData,
    updateVendorFormData,
    PersonnelFormData,
    coordinates
  } = props;

  const handleCountryChange = async (e) => {
    updateVendorFormData(e);
    PersonnelFormData.Country = VendorFormData.DisCountry;
  };
  return (
    <div>
      <Grid item xs={12}>
        <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
            <CoreTextField
                name="InternalName"
                label="Company Internal Name"
                value={VendorFormData.VendorInternalName}
                onChange={e => updateVendorFormData(e)}
                isRequired={true}
                autoFocus={true}
            />
            </Grid>
            <Grid item md={3} xs={12}>
            <CoreTextField
                name="LegalName"
                label="Company Legal Name"
                value={VendorFormData.LegalName}
                isRequired={true}
                onChange={e => updateVendorFormData(e)}
            />
            </Grid>
            <Grid item md={3} xs={12}>
            <CoreTextField
                name="RemitName"
                label="Company Remit Name"
                value={VendorFormData.RemitName}
                isRequired={true}
                onChange={e => updateVendorFormData(e)}
            />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
            <CoreTextField
                name="FederalIDNum"
                label="Federal ID Number/EIN"
                value={VendorFormData.FederalIDNum}
                onChange={e => updateVendorFormData(e)}
            />
            </Grid>
            <Grid item md={3} xs={12}>
            <CoreNumberField
                name="PaymentTerms"
                label="Payment Terms"
                value={VendorFormData.PaymentTerms}
                onChange={e => updateVendorFormData(e)}
            />
            </Grid>
        </Grid>
        <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} >
                        <CoreTextField
                        name="DisAddress"
                        label="Company Physical Address"
                        isRequired={true}
                        value={VendorFormData.DisAddress}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                    <Grid item md={6} xs={6}></Grid>
                    {VendorFormData.DisCountry === "Philippines" && <>
                      <Grid item md={6} xs={6}>
                        <CoreTextField
                          name="DisDistrict"
                          label="District"
                          isRequired={false}
                          value={VendorFormData.DisDistrict}
                          onChange={e => updateVendorFormData(e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}></Grid>
                      <Grid item md={6} xs={6}>
                        <CoreTextField
                          name="DisCounty"
                          label="Barangay"
                          isRequired={false}
                          value={VendorFormData.DisCounty}
                          onChange={e => updateVendorFormData(e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}></Grid>
                    </>}
                </Grid>
                {VendorFormData.DisCountry !== "Philippines" && <>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                    <CoreTextField
                        name="DisCity"
                        label="City"
                        value={VendorFormData.DisCity}
                        onChange={e => updateVendorFormData(e)}
                        autoComplete="disable-autocomplete"
                    />
                    </Grid>
                    <Grid item md={6} xs={12}>
                    <ProvinceField
                        label="State / Province"
                        value={VendorFormData.DisProvince}
                        name="DisProvince"
                        isRequired={false}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                    <CoreTextField
                        name="DisPostalCode"
                        label="ZIP / Postal Code"
                        value={VendorFormData.DisPostalCode}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                    <Grid item md={6} xs={12}>
                    <CountryField
                        label="Country"
                        value={VendorFormData.DisCountry}
                        name="DisCountry"
                        onChange={e => handleCountryChange(e)}
                        isRequired={true}
                    />
                    </Grid>
                  </Grid>
                </>}
                {VendorFormData.DisCountry === "Philippines" && <>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <CoreTextField
                        name="DisPostalCode"
                        label="ZIP / Postal Code"
                        value={VendorFormData.DisPostalCode}
                        onChange={e => updateVendorFormData(e)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CoreTextField
                        name="DisCity"
                        label="City"
                        value={VendorFormData.DisCity}
                        onChange={e => updateVendorFormData(e)}
                        autoComplete="disable-autocomplete"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <ProvinceField
                        label="State / Province"
                        value={VendorFormData.DisProvince}
                        name="DisProvince"
                        isRequired={false}
                        onChange={e => updateVendorFormData(e)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CountryField
                        label="Country"
                        value={VendorFormData.DisCountry}
                        name="DisCountry"
                        onChange={e => handleCountryChange(e)}
                        isRequired={true}
                      />
                    </Grid>
                  </Grid>
                </>}
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <CorePhoneField
                        name="DisPhone"
                        label="Primary Number"
                        value={VendorFormData.DisPhone}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="mb-2">
                        <CorePhoneField
                        name="DisTollFree"
                        label="Secondary Number"
                        value={VendorFormData.DisTollFree}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                </Grid> 
            </Grid>
            <Grid item xs={6}>
            {/* Map Section */}
            {coordinates &&
              <CoreHereDisplayMap
                coordinates={coordinates}
              />
            }
            </Grid>
           
        </Grid>
             
        <Grid container spacing={2}>
          <Typography variant="subtitle2" gutterBottom style={{float:"right"}} className="mt-1">
            <CoreCheckboxField
              name="RemAddressSame"
              label="Remittance Address Same as Physical"
              value={VendorFormData.RemAddressSame}
              onChange={e => updateVendorFormData(e)}
            />
          </Typography>   
        </Grid> 
        {
            VendorFormData.RemAddressSame === false &&
            <Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <CoreTextField
                        name="RemAddress"
                        label="Company Remittance Address"
                        isRequired={true}
                        value={VendorFormData.RemAddress}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                    <Grid item md={9} xs={12}></Grid>
                    {VendorFormData.RemCountry === "Philippines" && <>
                      <Grid item md={3} xs={12}>
                        <CoreTextField
                          name="RemDistrict"
                          label="District"
                          isRequired={false}
                          value={VendorFormData.RemDistrict}
                          onChange={e => updateVendorFormData(e)}
                        />
                      </Grid>
                      <Grid item md={9} xs={12}></Grid>
                      <Grid item md={3} xs={12}>
                        <CoreTextField
                          name="RemCounty"
                          label="Barangay"
                          isRequired={false}
                          value={VendorFormData.RemCounty}
                          onChange={e => updateVendorFormData(e)}
                        />
                      </Grid>
                      <Grid item md={9} xs={12}></Grid>
                    </>}
                </Grid>
                {VendorFormData.RemCountry !== "Philippines" && <>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                    <CoreTextField
                        name="RemCity"
                        label="City"
                        value={VendorFormData.RemCity}
                        onChange={e => updateVendorFormData(e)}
                        autoComplete="disable-autocomplete"
                    />
                    </Grid>
                    <Grid item md={3} xs={12}>
                    <ProvinceField
                        label="State / Province"
                        value={VendorFormData.RemProvince}
                        name="RemProvince"
                        isRequired={false}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                    <CoreTextField
                        name="RemPostalCode"
                        label="ZIP / Postal Code"
                        value={VendorFormData.RemPostalCode}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                    <Grid item md={3} xs={12}>
                    <CountryField
                        label="Country"
                        value={VendorFormData.RemCountry}
                        name="RemCountry"
                        onChange={e => updateVendorFormData(e)}
                        isRequired={true}
                    />
                    </Grid>
                </Grid>
                </>}
                {VendorFormData.RemCountry === "Philippines" && <>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                    <CoreTextField
                        name="RemPostalCode"
                        label="ZIP / Postal Code"
                        value={VendorFormData.RemPostalCode}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                    <Grid item md={3} xs={12}>
                    <CoreTextField
                        name="RemCity"
                        label="City"
                        value={VendorFormData.RemCity}
                        onChange={e => updateVendorFormData(e)}
                        autoComplete="disable-autocomplete"
                    />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                    <ProvinceField
                        label="State / Province"
                        value={VendorFormData.RemProvince}
                        name="RemProvince"
                        isRequired={false}
                        onChange={e => updateVendorFormData(e)}
                    />
                    </Grid>
                    <Grid item md={3} xs={12}>
                    <CountryField
                        label="Country"
                        value={VendorFormData.RemCountry}
                        name="RemCountry"
                        onChange={e => updateVendorFormData(e)}
                        isRequired={true}
                    />
                    </Grid>
                  </Grid>
                </>}
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <CorePhoneField
                        name="RemPhone"
                        label="Primary Number"
                        value={VendorFormData.RemPhone}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} className="mb-2">
                        <CorePhoneField
                        name="RemTollFree"
                        label="Secondary Number"
                        value={VendorFormData.RemTollFree}
                        onChange={e => updateVendorFormData(e)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        }
      </Grid>
    </div>
  );
}

export default PersonnelCreateContentVendorInfo;