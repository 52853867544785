import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AuthenticationFooter from './AuthenticationFooter';

import logo from '../assets/envase_truck_home.jpg';
import styles from './Authentication.module.css';

const AuthenticationPage = ({ form }) => {
  return (
    <Box className={styles.loginBody}>
      <Grid container>
        <Grid item md={4} className={styles.leftSplitBox}>
          <Grid item xs container direction="column" className={styles.leftSplitBoxForm}>
            <Box m={15}>
              <Typography gutterBottom variant="subtitle1">
                <img alt="Envase Logo" src="/envase-logo-color-140x50-96.png"></img>
              </Typography>
              {form}
              <AuthenticationFooter></AuthenticationFooter>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={8} className={styles.rightSplitBox}>
          <Box display={{ xs: 'none', sm: 'block' }} className={styles.imgWrap} >
            <img src={logo} alt="Logo" className={styles.img} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

}

export default AuthenticationPage;