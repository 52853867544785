import HttpService from "../http-service";

const token = localStorage.getItem('token');
const uri = 'SubscriptionManagement/';

export const getActiveEnvaseDriverCount = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetActiveEnvaseDriverCount?token=' + token);
  return result.response;
};

export const getPurchasedEnvaseDriverCount = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPurchasedEnvaseDriverCount?token=' + token);
  return result.response;
};

export const getActiveUserCount = async() => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetActiveUserCount?token=' + token);
  return result.response;
};

export const increaseMobileQuantity = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IncreaseMobileQuantity?token=' + token);
  return result.response;
};

export const decreaseMobileQuantity = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'DecreaseMobileQuantity?token=' + token);
  return result.response;
};

export const attachPaymentMethod = async (cardToken) => {
  const params = {
    cardToken,
    token
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'AttachPaymentMethod', null, params);
  return result.response;
};

export const attachPaymentMethodLogin = async (companyID, username, cardToken) => {
  const params = {
    cardToken,
    companyID,
    username
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'AttachPaymentMethodLogin', null, params);
  return result.response;
};

export const getPaymentMethod = async() => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetPaymentMethod?token=' + token);
  return result.response;
};