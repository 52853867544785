import { Route } from 'react-router-dom';
import LocationList from './LocationList';
import LocationView from './LocationView';
import LocationCreate from './LocationCreate';
import LocationEdit from './LocationEdit';

const LocationRouter = () => {
  return (
    <>
      <Route exact path="/spark/location" component={LocationList} />
      <Route exact path="/spark/location/create" component={LocationCreate} />
      <Route exact path="/spark/location/edit/:id" component={LocationEdit} />
      <Route exact path="/spark/location/view/:id" component={LocationView} />
    </>
  );

}

export default LocationRouter;