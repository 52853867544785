import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getList } from './ReceivableChargeTypeService';

const ReceivableChargeTypeField = (props) => {

  const {
    label,    
    value,
    name,
    nameData,
    valueData,
    isRequired,
    validationError,
    onChange,
    responseObject,
    isDisabled=false,
    ...rest
  } = props;

  const [receivableChargeTypeList, setReceivableChargeTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getReceivableChargeTypes() { 
    const result = await getList();
      setReceivableChargeTypeList(result);
      setIsLoading(false);
    }
    getReceivableChargeTypes();
  }, []);

  const onReceivableChargeTypeSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.ReceivableChargeTypeID : null
      }
    };
    onChange(dispatchEvent);
    sendResponseObject(newValue);
  };

  const sendResponseObject = newValue => {
    if (responseObject && newValue !== null) {
      responseObject(newValue);
    }
  }

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }  
  };

  const getDefaultValue = () => {
    const receivable = receivableChargeTypeList
      .find(chargeType => chargeType.ReceivableChargeTypeID === valueData);
    if (receivable) {
      return receivable.Name;  
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onReceivableChargeTypeSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={receivableChargeTypeList}
      isDisabled={isDisabled}
      {...rest}
    />
  );

}

ReceivableChargeTypeField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default ReceivableChargeTypeField;