import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const OrderAppointmentAuthorizeTerminalFeesDialog = (props) => {
  const {
    handleClose,
    isOpen,
    payTerminalFees,
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Authorize Terminal Fees</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-2 mb-2">
          <Grid item md={12} xs={12}>
            Would you like to process any outstanding terminal fees?
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={() => payTerminalFees()} variant="contained" color="primary">
          Process
        </Button>
      </DialogActions>
    </Dialog >
  );

};

export default OrderAppointmentAuthorizeTerminalFeesDialog;