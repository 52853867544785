import React, { useState, useEffect } from 'react';
import Validation from '../validation';

import AutomaticPayableEditDialogContent from './AutomaticPayableEditDialogContent';
import { Get, Update } from './AutomaticPayableService';

const AutomaticPayableEditDialog = (props) => { 
  const { 
    defaultPayableChargeTypeID,
    isOpen,
    onClose
  } = props;

  const [autoPayFormData, setAutoPayFormData] = useState({});
  const [autoPayFormValidation, setAutoPayFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isAutoPayDataLoaded, setIsAutoPayDataLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => { 
    if (isOpen) { 
      Get(defaultPayableChargeTypeID)
        .then(function(response) { 
          setAutoPayFormData(response);
          setAutoPayFormData(c => ({ ...c, IntermodalUnitType: response.PayableChargeType.IntermodalUnitType }));
          setAutoPayFormData(c => ({ ...c, IntermodalUnitRateType: response.PayableChargeType.IntermodalUnitRateType }));
          setIsAutoPayDataLoaded(true);
        });
    }
  }, [isOpen, defaultPayableChargeTypeID]);

  useEffect(() => { 
    if (isOpen) { 
        setAutoPayFormValidation(buildValidation(autoPayFormData));
    }
  }, [isOpen, autoPayFormData]);

  const updateAutoPayFormData = e => { 
    const {name, value, nameData, valueData, valueUnitsDefaultValue, valueUnitRateDefaultValue, valueIntermodalUnitRateType, valueIntermodalUnitType} = e.target;
    autoPayFormData[name] = value;
    autoPayFormData[nameData] = valueData;
    if (nameData === "PayableChargeTypeID") {
      autoPayFormData.Units = valueUnitsDefaultValue;
      autoPayFormData.UnitRate = valueUnitRateDefaultValue;
      autoPayFormData.IntermodalUnitType = valueIntermodalUnitType;
      autoPayFormData.IntermodalUnitRateType = valueIntermodalUnitRateType;
    }
    setAutoPayFormData({ ...autoPayFormData });
  };

  const onUpdate = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(autoPayFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => { 
    setErrorMessage('');
    const result = await Update(autoPayFormData);
    if (result.error) { 
      setErrorMessage(result.error[0]);
      setInProgress(false);
      return;
    }
    onClose();
  };

  return ( 
    isOpen && isAutoPayDataLoaded && 
    <AutomaticPayableEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      autoPayFormData={autoPayFormData}
      updateAutoPayFormData={updateAutoPayFormData}
      errorMessage={errorMessage}
      autoPayFormValidation={autoPayFormValidation}
      inProgress={inProgress}
    />
  )
};

const buildValidation = (autoPayFormData) => { 
    const { 
      AutoAddIf,
      PayableChargeTypeID,
      Units,
      UnitRate
    } = autoPayFormData || {};
    const validation = new Validation();
    return { 
      AutoAddIf: validation.isNumber(AutoAddIf),
      PayableChargeTypeID: validation.isNumber(PayableChargeTypeID),
      Units: validation.isNumber(Units),
      UnitRate: validation.isNumber(UnitRate)
    };
  };

export default AutomaticPayableEditDialog;