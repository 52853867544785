import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getDocumentFieldNames } from '../resource/ResourceService';

const DocumentFieldDropdown = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    getDocumentFieldBody,
    resetDocumentCreate,
    ...rest
  } = props;

  const [documentFieldList, setDocumentFieldList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getDocumentFieldList() {
      const result = await getDocumentFieldNames();
      setDocumentFieldList(result);
      setIsLoading(false);
    }
    getDocumentFieldList();
  }, []);

  const onDocumentSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : ''
      }
    };
    onChange(dispatchEvent);
    resetDocumentCreate();
    if (newValue && newValue.Name) {
      getDocumentFieldBody();
    }
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {   
    const document = documentFieldList
      .find(document => document.Name === value);
    if (document) {
      return document.Name;
    }
    return '';
  };

  return (
    <>
    {!isLoading &&
      <CoreAutocompleteField
        label={label}
        value={value}
        name={name}
        isRequired={isRequired}
        validationError={validationError}
        onChange={onDocumentSelected}
        getDefaultValue={getDefaultValue}
        getOptionSelected={getOptionSelected}
        options={documentFieldList}
        {...rest}
      />
    }
    </>
  );

}

DocumentFieldDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DocumentFieldDropdown;