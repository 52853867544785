import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import LinearProgress from '@material-ui/core/LinearProgress';

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import DocumentDropzone from './DocumentDropzone';
import { deleteDocument, getDocument } from '../order/OrderService';
import DocumentListDocumentTypeDropdown from './DocumentListDocumentTypeDropdown';

const DocumentListTable = (props) => {
  const {
    orderID,
    documents,
    getDocuments,
    setDocuments
  } = props;

  const [inProgress, setInProgress] = useState(false);

  let columns = [
    {
      field: 'FileName',
      headerName: 'File Name',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("FileName");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>{name}</span>
          </div>
        )
      }
    },
    {
      field: 'DateCreated',
      headerName: 'Date Uploaded',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'DocType',
      headerName: 'Document Type',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const id = params.getValue("ID");
        const documentTypeID = params.getValue("DocumentTypeID")
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            lineHeight: 'normal'
          }}>
            <DocumentListDocumentTypeDropdown
              id={id}
              value={documentTypeID}
              getDocuments={getDocuments}
            />
          </div>
        )
      }
    },
    {
      field: 'Controls',
      headerName: ' ',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const id = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`File: ${params.getValue("FileName")}`}
                deleteID={id}
                deleteFunction={deleteDocument}
                getList={getDocuments}
              />}
          </div>
        )
      }
    }
  ];

  const onDocumentRowEnter = (params) => {
    for (const doc of documents) {
      if (doc.ID === params.row.ID) {
        doc.ShowControls = true;
      }
    }
    setDocuments([...documents]);
  };

  const onDocumentRowLeave = (params) => {
    for (const doc of documents) {
      if (doc.ID === params.row.ID) {
        doc.ShowControls = false;
      }
    }
    setDocuments([...documents]);
  };

  const onDocumentCellClick = async (params) => {
    if (params.field !== 'Controls' && params.field !== 'DocType') {
      var result = await getDocument(params.id);
      window.open(result, '_blank');
    }
  };

  return (
    <Paper style={{ padding: '0', marginTop: '10px' }} maxWidth="lg" className="munaGrid" square>
      <DocumentDropzone
        orderID={orderID}
        getDocuments={getDocuments}
        setInProgress={setInProgress}
      />
      {inProgress && <LinearProgress />}
      <DataGrid
        getRowId={(row) => row.ID}
        hideFooter={true}
        autoHeight={true}
        rows={documents}
        columns={columns}
        pageSize={100}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onDocumentRowEnter(e)}
        onRowLeave={e => onDocumentRowLeave(e)}
        onCellClick={e => onDocumentCellClick(e)}
      />
    </Paper>
  );
}

DocumentListTable.propTypes = {
  documents: PropTypes.array.isRequired,
  getDocuments: PropTypes.func.isRequired
};

export default DocumentListTable;