import HttpService from '../http-service';

export default async function authenticationLogin(username, password) {
  const httpService = new HttpService();
  var product = "TMS";
  const params = { username, password, product };
  const result = await httpService
    .post('Account/Login', params);
  return result;
};

export async function isTokenValid() {
  const token = localStorage.getItem('token');
  const uri = 'Account/' + token + '/IsTokenValid';
  const httpService = new HttpService();
  const result = await httpService.get(uri);
  if (result !== true) return false;
  return result;
}

export async function generatePasswordReset(username) {
  const params = { username };
  const httpService = new HttpService();
  const result = await httpService
    .post('Account/GeneratePasswordReset', null, params);
  return result;
}