import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CoreExitButton from '../core/CoreExitButton';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { get } from './LocationService';
import LocationViewSummaryInfoSection from './LocationViewSummaryInfoSection';
import CoreSummaryFooter from '../core/CoreSummaryFooter';
import CoreSummaryHeader from '../core/CoreSummaryHeader';

const useStyles = makeStyles(() => ({
  paper: {
    width:400
  }
}));

const LocationViewSummary = (props) => {
  const classes = useStyles();
  const {
    locationTemplateID,
    isOpen,
    onClose,
  } = props;
  let history = useHistory();

  const [location, setLocation] = useState({});
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setInProgress(true);
    setLocation({});    
    if (isOpen) {
      get(locationTemplateID).then(function(response) {
        setLocation(response);
        setInProgress(false);
      });
    }
  }, [isOpen, locationTemplateID]);

  const editLocation = () => {
    history.push({
      pathname: '/spark/location/edit/' + locationTemplateID, 
      state: location
    });
  };

  const openLocation = () => {
    history.push('/spark/location/view/' + locationTemplateID);
  };

  return (
    <>
      <Drawer 
        anchor={'right'}
        open={isOpen}
        ModalProps={{onBackdropClick: onClose }}
        classes={{ paper: classes.paper }}>  
        <CoreSummaryHeader
          title={"Summary View"}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
        />
        {inProgress && <LinearProgress />}
        <Container maxWidth="lg" className="mt-1">
          <LocationViewSummaryInfoSection
            location={location}
          />
        </Container>
        {!inProgress && <CoreSummaryFooter
          editRecord={editLocation}
          openRecord={openLocation}
          disableEdit={false}
        />}
      </Drawer>
    </>    
  );

}

export default LocationViewSummary;