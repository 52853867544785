import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircleIcon from '@material-ui/icons/Brightness1';
import CoreDataGridList from '../core/CoreDataGridList';
import { downloadFile } from '../globalFunction';
import { getList } from './OrderService';
import { exportList } from './OrderService';
import OrderListStatus from './OrderListStatus';
import OrderViewSummary from './OrderViewSummary';
import Validation from '../validation';
import Container from '@material-ui/core/Container';
import OrderListFilterRow from './OrderListFilterRow';
import { columns } from './OrderListColumnService';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';

const useStyles = makeStyles(() => ({
  statusIcon: {
    color: 'black',
    fontWeight: 'normal',
    borderRadius: '15px',
    padding: '5px',
    fontSize: '12px',
    height: '24px',
    width: '70px',
    backgroundColor: '#E0E0E0',
    display: 'inline-flex',
    alignItems: 'center'
  },
  iconCircle: {
    fontSize: '15px',
    verticalAlign: 'text-bottom'
  },
}));

const OrderListTable = () => {
  const classes = useStyles();
  const location = useLocation();
  const onCloseRoute = location.pathname + location.search;

  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("orderListAmount")) || 10,
    isASC: false,
    sortBy: 'OrderNum',
    filters: [],
    ShowAll: true,
    ShowDispatched: false,
    ShowInTransit: false,
    ShowCompleted: false,
    ShowUnassigned: false,
    ShowBillingOnHold: false,
    ShowApprovedForBilling: false,
    ShowInvoiced: false,
    ShowCancelled: false
  });
  let history = useHistory();

  const validation = new Validation();
  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(true);
  const [orders, setOrders] = useState([]);
  const [rowCount, setRowCount] = useState(10);
  const [companyFeature, setCompanyFeature] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
    Customer: '',
    CustomerID: null,
    ReferenceNumber: '',
    ContainerNumber: '',
    BookingNum: '',
    LastConfirmedDate:'',
    LastFreeDate: '',
  });

  useEffect(() => {
    buildURLSearchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrderList();
    getCompanyFeature()
        .then(function(response){
          setCompanyFeature(response);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const updateFilterData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    filterData[name] = value;
    filterData[nameData] = valueData;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const resetFilterRow = () => {
    params.filters = [];
    params.page = 1;
    setFilterData(c => ({ ...c, Customer: '' }));
    setFilterData(c => ({ ...c, CustomerID: null }));
    setFilterData(c => ({ ...c, ReferenceNumber: '' }));
    setFilterData(c => ({ ...c, ContainerNumber: '' }));
    setFilterData(c => ({ ...c, BookingNum: '' }));
    setFilterData(c => ({ ...c, LastConfirmedDate: '' }));
    setFilterData(c => ({ ...c, AppointmentBooked: '' }));
    setParams({ ...params });
  };

  const closeSummary = () => {
    setIsSummaryOpen(false)
  };

  const updateStatusButtonChange = e => {
    const { name } = e.target;
    params.ShowAll = false;
    params.ShowDispatched = false;
    params.ShowInTransit = false;
    params.ShowCompleted = false;
    params.ShowBillingOnHold = false;
    params.ShowApprovedForBilling = false;
    params.ShowInvoiced = false;
    params.ShowUnassigned = false;
    params.ShowCancelled = false;
    switch (name) {
      case 'All':
        params.ShowAll = true;
        break;
      case 'ShowDispatched':
        params.ShowDispatched = true;
        break;
      case 'ShowUnassigned':
        params.ShowUnassigned = true;
        break;
      case 'ShowInTransit':
        params.ShowInTransit = true;
        break;
      case 'ShowCompleted':
        params.ShowCompleted = true;
        break;
      case 'ShowBillingOnHold':
        params.ShowBillingOnHold = true;
        break;
      case 'ShowApprovedForBilling':
        params.ShowApprovedForBilling = true;
        break;
      case 'ShowInvoiced':
        params.ShowInvoiced = true;
        break;
      case 'ShowCancelled':
        params.ShowCancelled = true;
        break;
      default:
        break;
    }
    params.page = 1;
    setParams({ ...params });
  };

  function buildURLSearchFilters() {
    const urlParams = new URLSearchParams(window.location.search);
    const listStatus = urlParams.get('listStatus');
    const referenceNumber = urlParams.get('referenceNumber');
    const containerNumber = urlParams.get('containerNumber');
    const bookingNum = urlParams.get('bookingNum');
    const lastFreeDate = urlParams.get('lastFreeDate');
    const cust = urlParams.get('customer');
    const custID = parseInt(urlParams.get('ID'));
    if (cust) {
      setFilterData(c => ({ ...c, Customer: cust }));    
      pushParamFilter('Customer', cust, 'Contains');
    }
    if (custID) {
      setFilterData(c => ({ ...c, CustomerID: custID }));    
    }
    if (listStatus) {
      updateStatusButtonChange({target:{name: listStatus}});
    }
    if (referenceNumber) {
      setFilterData(c => ({ ...c, ReferenceNumber: referenceNumber }));    
      pushParamFilter('ReferenceNumber', referenceNumber, 'Equals');
    }
    if (containerNumber) {
      setFilterData(c => ({ ...c, ContainerNumber: containerNumber }));    
      pushParamFilter('ContainerNumber', containerNumber, 'Equals');
    }
    if (bookingNum) {
      setFilterData(c => ({ ...c, BookingNum: bookingNum }));    
      pushParamFilter('BookingNum', bookingNum, 'Equals');
    }
    if (lastFreeDate) {
      setFilterData(c => ({ ...c, LastFreeDate: lastFreeDate }));    
      pushParamFilter('LastFreeDate', lastFreeDate, 'Equals');
    }
    if (params.filters.length > 0) {
      console.log(params.filters);
      setParams({ ...params });
    }
  };

  function changePage(page) {
    params.page = page + 1;
    setParams({ ...params });
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    setParams({ ...params });
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    setParams({ ...params });
    localStorage.setItem("orderListAmount", amount);
  };

  function pushParamFilter(filterName, value, operator) {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  }

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }
    if (filterData.Customer) {
      pushParamFilter("Customer", filterData.Customer, 'Contains');
    }
    if (!validation.isEmpty(filterData.ReferenceNumber)) {
      pushParamFilter("ReferenceNumber", filterData.ReferenceNumber, 'Contains');
    }
    if (!validation.isEmpty(filterData.ContainerNumber)) {
      pushParamFilter("ContainerNumber", filterData.ContainerNumber, 'Contains');
    }
    if (!validation.isEmpty(filterData.BookingNum)) {
      pushParamFilter("BookingNum", filterData.BookingNum, 'Contains');
    }
    if (!validation.isEmpty(filterData.LastConfirmedDate)) {
      pushParamFilter("LastConfirmedDate", filterData.LastConfirmedDate, 'Equals');
    }
    if (!validation.isEmpty(filterData.LastFreeDate)) {
      pushParamFilter("LastFreeDate", filterData.LastFreeDate, 'Equals');
    }
    if (filterData.Favorite) { 
      pushParamFilter("favorite", filterData.Favorite, 'Equals');
    }
    if (filterData.PaidUnpaid != null) { 
      pushParamFilter("PaidUnpaid", filterData.PaidUnpaid, 'Equals');
    }
    if (filterData.AppointmentBooked != null) {
      pushParamFilter("AppointmentBooked", filterData.AppointmentBooked, 'Equals');
    }
    params.page = 1;
    setParams({ ...params });
  };

  const getOrderList = async () => {
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setRowCount(result[0].TotalRows);
    } else {
      setRowCount(0);
    }
    setOrders(result);
    setInProgress(false);
  };

  const exportOrderList = async () => {
    const result = await exportList(params);
    downloadFile(result, "order-export.csv");
  };

  const openRecord = (id) => {
    history.push({
      pathname: '/spark/order/view/' + id,
      state: {
        onCloseRoute: onCloseRoute,
      }
    });
  };
  
  const openSlideout = (id, status) => {
    setCurrentRowClicked(c => ({ ...c, ID: id}));
    setCurrentRowClicked(c => ({ ...c, Status: status}));
    setIsSummaryOpen(true);
  };

  function getPaidStatusIcon(isFullyPaid) { 
    return (
      isFullyPaid ? 
        <span className={classes.statusIcon}><CircleIcon style={{ color: '#86DC76' }} className={classes.iconCircle}/>&nbsp;{"Paid"}&nbsp;</span> :
        <span className={classes.statusIcon}><CircleIcon style={{ color: '#BDBDBD' }} className={classes.iconCircle}/>&nbsp;{"Unpaid"}&nbsp;</span>
    )
  };

  return (
    <div style={{ position: 'relative' }}>
      <OrderListStatus
        orderStatuses={params}
        orders={orders}
        updateStatusButtonChange={updateStatusButtonChange}
        listWidth={listWidth}
      />
      <OrderListFilterRow
        filterData={filterData}
        updateFilterData={updateFilterData}
        resetFilterRow={resetFilterRow}
        listWidth={listWidth}
        customerName={filterData.Customer}
        companyFeature={companyFeature}
      />
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns(params, openRecord, openSlideout, getPaidStatusIcon, getOrderList, companyFeature)}
          data={orders}
          params={params}
          exportList={exportOrderList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='desc'
          defaultSortBy='OrderNum'
          filterData={filterData}
          updateFilterData={updateFilterData}
          listWidth={listWidth}
          setListWidth={setListWidth}
          rowCount={rowCount}
        />
      </Container>
      <OrderViewSummary
        orderID={currentRowClicked.ID} 
        orderStatus={currentRowClicked.Status}
        isOpen={isSummaryOpen} 
        onClose={closeSummary}
        onCloseRoute={onCloseRoute}
      /> 
    </div>
  )
};

export default OrderListTable;