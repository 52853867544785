import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

const CoreDeleteDialogContent = (props) => {
  const {
    onDelete,
    isOpen,
    onClose,
    reference,
    errorMessage
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog">
      <form onSubmit={onDelete}>
        <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {reference}?
            {errorMessage && 
              <Alert variant="filled" severity="error" className="mt-1 mb-1">
                {errorMessage}
              </Alert>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CoreDeleteDialogContent;