import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import IconPhone from '../icon/IconPhone';
import IconName from '../icon/IconName';
import IconLegalName from '../icon/IconLegalName';
import IconLocationLight from '../icon/IconLocationLight';
import IconEmail from '../icon/IconEmail';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const PersonnelViewSummaryGeneralSection = (props) => {
  const classes = useStyles();

  const {
    personnel
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="GENERAL INFORMATION" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Type"
              value={personnel.ListAsDriver ? 'Driver' : 'User'}
              icon={<IconName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Legal Name"
              value={`${personnel.FirstName || ''} ${personnel.LastName || ''}`}
              icon={<IconLegalName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Secondary #"
              value={personnel.BusPhone || ''}
              icon={<IconPhone />}
              isPhoneNum={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Email Address"
              value={personnel.Email || ''}
              icon={<IconEmail />}
              isEmail={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Address"
              value={personnel.Address || ''}
              icon={<IconLocationLight />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonnelViewSummaryGeneralSection;
