import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getList } from './AreaTagService';

import CoreAutocompleteField from '../core/CoreAutocompleteField';

const AreaTagField = (props) => {
  const {
    label,
    value,
    name,
    valueData,
    nameData,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [areaTagList, setAreaTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getAreaTagList() { 
      const result = await getList();
      setAreaTagList(result);
      setIsLoading(false);
    }
    getAreaTagList();
  }, []);

  const onAreaTagSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.AreaTagID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    var defaultAreaTag = areaTagList.find(areaTag => areaTag.AreaTagID === (valueData));
    if (defaultAreaTag) {
      return defaultAreaTag.Name;
    }
    return '';
  };

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onAreaTagSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={areaTagList}
      {...rest}
    />
  );
}

AreaTagField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default AreaTagField;