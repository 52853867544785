import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconDashboard from '../icon/IconDashboard';

const NavigationDrawerListItemDashboard = () => {
  return (
    <NavigationDrawerListItem 
      title={'Dashboard'} 
      icon={<IconDashboard />} 
      uri={'/spark/dashboard'}/>
  );

}

export default NavigationDrawerListItemDashboard;