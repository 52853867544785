import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as TruckSvg } from '../assets/svg-icons/truck@1x.svg'

const IconTruck = () => {
  return (
    <SvgIcon fontSize="medium">
      <TruckSvg />
    </SvgIcon>
  )
}

export default IconTruck;