import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreDateField from '../core/CoreDateField';
import { updateLeg } from '../intermodalEvent/IntermodalEventService';

const IntermodalEventListTableDateField = (props) => {
  const {
    leg,
    getEvents,
    setInProgress
  } = props;

  const [eventFormData, setEventFormData] = useState(leg);

  useEffect(() => {
    setEventFormData(leg);
  }, [leg]);

  const updateEventFormData = e => {
    const { name, value } = e.target;
    eventFormData[name] = value;
    setEventFormData({ ...eventFormData });
    onUpdate();
  };

  const onUpdate = () => {
    setInProgress(true);
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    if (eventFormData.PlannedDate) { 
      var d = new Date(eventFormData.PlannedDate); 
      if (d.getTime() - d.getTime() !== 0) { 
        setInProgress(false);
        return;
      }
      eventFormData.PlannedDate = d.toDateString();
    }
    const result = await updateLeg(eventFormData);
    if (result.errors) {
      return;
    }
    setInProgress(false);
    getEvents();
  };

  return (
    <CoreDateField
        name={"PlannedDate"}
        label="Planned Date"
        value={leg.PlannedDate}
        onChange={e => updateEventFormData(e)}
    />
  );

}

IntermodalEventListTableDateField.propTypes = {
  leg: PropTypes.array.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTableDateField;