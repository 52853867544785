import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import CoreTextField from '../core/CoreTextField';
import CoreCheckboxField from '../core/CoreCheckboxField';
import CoreDateField from '../core/CoreDateField';

const PersonnelEditContentDriverInfo = (props) => {
  const {
    PersonnelFormData,
    updatePersonnelFormData,
    DriverLicenseFormData,
    updateDriverLicenseFormData,
    TWICFormData,
    updateTWICFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Driver Check Information</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CoreTextField
            name="DocumentNumber"
            label="Driver License"
            value={DriverLicenseFormData.DocumentNumber}
            onChange={e => updateDriverLicenseFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreDateField
            name="ExpiryDate"
            label="Expiration Date"
            value={DriverLicenseFormData.ExpiryDate}
            onChange={e => updateDriverLicenseFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextField
            name="DocumentNumber"
            label="TWIC #"
            value={TWICFormData.DocumentNumber}
            onChange={e => updateTWICFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreDateField
            name="ExpiryDate"
            label="Expiration Date"
            value={TWICFormData.ExpiryDate}
            onChange={e => updateTWICFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreCheckboxField
            label="HAZMAT Certification"
            value={PersonnelFormData.Check1}
            name="Check1"
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreCheckboxField
            label="Medical Exam"
            value={PersonnelFormData.Check2}
            name="Check2"
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <CoreCheckboxField
            label="Drug Test"
            value={PersonnelFormData.Check3}
            name="Check3"
            onChange={e => updatePersonnelFormData(e)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PersonnelEditContentDriverInfo;