import React from 'react';
import { FormControlLabel, Switch, Typography } from "@material-ui/core";

const ConfigurationOwnerOperator = (props) => {
  const { inProgress, configuration, handleConfigChange } = props;
  return (
    <>
      <Typography variant='subtitle1'>Owner Operator:</Typography>
      {Object.values(configuration).map((config, i) => {
        return (
          <div key={i}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={inProgress}
                  checked={
                    (config.value && (config.role === null || config.role === "owner-operator")) ||
                    (!config.value && config.role === "company-driver")
                  }
                  onChange={handleConfigChange}
                  name={config.name}
                />
              }
              label={config.displayName}
              labelPlacement="end"
              value="owner-operator"
            />
          </div>
        );
      })}
    </>
  )
}

export default ConfigurationOwnerOperator