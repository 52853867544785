import HttpService from '../http-service';

const uri = 'Mileage/';

export async function getGeocode(address, city, province, country, postalCode) {
  if (!address && !city && !province && !postalCode) {
    return;
  }
  try {
    const httpService = new HttpService();
    const result = await httpService
      .get(uri + 'GetGeocode?' + 
        'address=' + address + 
        '&city=' + city + 
        '&province=' + province + 
        '&country=' + country +
        '&postalCode=' + postalCode);
    const coordinates = result.substring(4).split(',');
    return {'lat': parseFloat(coordinates[0]), 'lng': parseFloat(coordinates[1])}
  } catch(Exception) {
    return;
  }
};