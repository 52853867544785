import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { deleteNote, markImportant } from '../notes/NotesService';
import CoreFavoriteButtonDisplayField from '../core/CoreFavoriteButtonDisplayField';

import NoteEditDialog from '../notes/NoteEditDialog';

const useStyles = makeStyles(() => ({
  dialogPaper: {
      height: '445px',
      width: '600px'
  },
  noPaddingTop: {
    paddingTop: '0px'
  },
}));

const NoteView = (props) => {
    const {
        note,
        isOpen,
        onClose
    } = props;
    const classes = useStyles();

    const [isProcessing, setIsProcessing] = useState(false);
    const [isNoteEditOpen, setIsNoteEditOpen] = useState(false);
    const [isImportant, setIsImportant] = useState(note.isImportant);
    const [importantErrorMessage, setImportantErrorMessage] = useState("");

    useEffect(() => {
      setIsImportant(note.isImportant);
    }, [note.isImportant]);

    const openNoteEditModal = () => {
      setIsNoteEditOpen(true);
    };
  
    const closeNoteEditModal = () => {
      setIsNoteEditOpen(false);
      props.getNotes();
      props.onClose();
    };

    const handleDelete = async () => {
      await deleteNote(note.NoteID);
      props.getNotes();
      props.onClose();
    };

    const toggleImportant = async () => {
      setImportantErrorMessage("");
      setIsProcessing(true);
      markImportant(note.NoteID, !isImportant).then(function(response) {
        if (response.error) {
          setImportantErrorMessage(response.error);
        } else {
          setIsImportant(!isImportant);
          props.getNotes();
        }
      }).finally(function() {
        setIsProcessing(false);
      });
    }

    return(
      <>
        {!isNoteEditOpen ? 
        <Dialog classes={{ paper : classes.dialogPaper}} open={isOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {note.Subject}
            <span style={{float: 'right'}}>
              <IconButton
              aria-label="Edit"
              onClick={() => openNoteEditModal(true)}
              >
              <MoreVertIcon/>
              </IconButton>
          </span>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} className={["mt-2", classes.noPaddingTop]}>
              <Grid item md={12} xs={12}>
                {note.Note}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {!isProcessing &&
              <CoreFavoriteButtonDisplayField 
                label="Mark as Important"
                value={isImportant}
                onClick={() => toggleImportant()}
              />
            }
            {isProcessing &&
              <CircularProgress size={20} />
            }
            <Button onClick={() => handleDelete()} color="primary">
              Delete
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
          {importantErrorMessage &&
            <Alert severity="error">
              { importantErrorMessage }
            </Alert>
          }
          </Dialog>
        : 
        <NoteEditDialog
          noteID={note.NoteID}
          isOpen={isNoteEditOpen}
          onClose={closeNoteEditModal}
        />
        }
      </>
    );
}

NoteView.propTypes = {
    note: PropTypes.object.isRequired
};

export default NoteView;