import { Route } from 'react-router-dom';
import BillEdit from './BillEdit';
import BillView from './BillView';

const BillRouter = () => {
  return (
    <>
      <Route exact path="/spark/bill/view/:id" component={BillView} />
      <Route exact path="/spark/bill/edit/:id" component={BillEdit} />
    </>
  );
}

export default BillRouter;