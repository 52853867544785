import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import ConfigurationViewPointToPointTemplatesSectionListTable from './ConfigurationViewPointToPointTemplatesSectionListTable';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import PointToPointTemplateCreateDialog from '../point_to_point/PointToPointTemplateCreateDialog';
import { GetList } from '../point_to_point/PointToPointTemplateService';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};

const ConfigurationViewPointToPointTemplatesSection = (props) => { 
  const { 
    isOpen,
    toggleIsOpen
  } = props;

  const [inProgress, setInProgress] = useState(true);
  const [isRateTemplateCreateDialogOpen, setIsRateTemplateCreateDialogOpen] = useState(false);
  const [rateTemplates, setRateTemplates] = useState();

  useEffect(() => { 
    GetList()
      .then(function(response) { 
        setRateTemplates(response);
        setInProgress(false);
      });
  }, []);

  const getPointToPointRateTemplateList = () => { 
    GetList()
      .then(function(response) { 
        setRateTemplates(response);
      });
  };

  const openRateTemplateCreateDialog = () => {
    setIsRateTemplateCreateDialogOpen(true);
  };

  const closeRateTemplateCreateDialog = () => {
    setIsRateTemplateCreateDialogOpen(false);
    getPointToPointRateTemplateList();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Point-To-Point Expense Rate Templates"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel={"Hide Countries"}></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"Using Area Tags, this section allows you to define expense rates for driver moments between multiple areas. A rate matrix can then be used within the Automatic Expenses section of a Personnel record to allow expenses to be created when the driver is identified on a leg that involves movement between two areas."}
                <span style={rightFloat}>
                </span>
            </Typography>
            <ConfigurationViewPointToPointTemplatesSectionListTable 
              rateTemplates={rateTemplates}
              getRateTemplates={getPointToPointRateTemplateList}
              setRateTemplates={setRateTemplates}
              openRateTemplateCreateDialog={openRateTemplateCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
            <span></span>
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Point-To-Point Expense Rate Templates"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel={"Show Countries"}></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <PointToPointTemplateCreateDialog
        isOpen={isRateTemplateCreateDialogOpen}
        onClose={closeRateTemplateCreateDialog}
      />
    </div>
  );
};

export default ConfigurationViewPointToPointTemplatesSection;