import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getWeeklyOrdersForDashboard} from './DashboardService';
import FormattingFunctions from '../helpers/formattingFunctions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
  upColor: {
    color: '#81C784'
  },
  downColor: {
    color: '#E57373'
  },
  revDataLi: {
    padding: '0 0px'
  },
  fontSmall: {
    fontSize: 'small'
  },
  arrowBottom: {
    verticalAlign: 'text-bottom'
  },
}));

const DashboardWeeklyOrders = () => {
  const classes = useStyles();
  const [weeklyOrder, setWeeklyOrder] = useState({});
  const [revenueColor, setRevenueColor] = useState(classes.upColor);
  const [ordersColor, setOrdersColor] = useState(classes.upColor);

  useEffect(() => {
    async function getWeeklyOrders() { 
      const result = await getWeeklyOrdersForDashboard();
      if(result.length > 0) { 
        setWeeklyOrder(result[0]);
        setRevenueColor(result[0].Revenue >= result[0].PrevWeekRevenue ? classes.upColor : classes.downColor);
        setOrdersColor(result[0].OrderCount >= result[0].PrevWeekOrderCount ? classes.upColor : classes.downColor);
      }
    }
    getWeeklyOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="subtitle2" style={{display:'flex', justifyContent:'flex-start', fontWeight:'bold'}}>
        Weekly Orders
      </Typography>
      <List>
        <ListItem className={classes.revDataLi}>
          <ListItemText 
            style={{marginBottom: '0'}}
            primary={<div style={{fontWeight: 'bold'}}>{weeklyOrder.Revenue ? FormattingFunctions.formatCurrency(weeklyOrder.Revenue) : "$0"}</div>}              
            secondary={<div><span className={classes.fontSmall}>Net Revenue</span></div>}
          />
          <ListItemText
            style={{textAlignLast: 'end', marginBottom: '0'}}            
            primary={<div>
              <span className={revenueColor}>
                {weeklyOrder.Revenue > weeklyOrder.PrevWeekRevenue && <ArrowUpwardIcon className={classes.arrowBottom} fontSize='small'/>}
                {weeklyOrder.Revenue < weeklyOrder.PrevWeekRevenue && <ArrowDownwardIcon className={classes.arrowBottom} fontSize='small'/>}
                &nbsp;{FormattingFunctions.percentageDiff(weeklyOrder.Revenue, weeklyOrder.PrevWeekRevenue)}
              </span>
            </div>}
            secondary={<span className={classes.fontSmall}>from last week</span>}
          />
        </ListItem>
        <ListItem className={classes.revDataLi}>
          <ListItemText 
            style={{marginBottom: '0'}}
            primary={<div style={{fontWeight: 'bold'}}>{weeklyOrder.OrderCount ? weeklyOrder.OrderCount : 0}</div>}
            secondary={<span className={classes.fontSmall}>Orders Completed</span>}
          />
          <ListItemText
            style={{textAlignLast: 'end', marginBottom: '0'}}
            primary={<div>
              <span className={ordersColor}>
                {weeklyOrder.OrderCount > weeklyOrder.PrevWeekOrderCount && <ArrowUpwardIcon className={classes.arrowBottom} fontSize='small'/>}
                {weeklyOrder.OrderCount < weeklyOrder.PrevWeekOrderCount && <ArrowDownwardIcon className={classes.arrowBottom} fontSize='small'/>}
                &nbsp;{FormattingFunctions.percentageDiff(weeklyOrder.OrderCount, weeklyOrder.PrevWeekOrderCount)}
              </span>
            </div>}
            secondary={<span className={classes.fontSmall}>from last week</span>}
          />
        </ListItem>
      </List>
    </div>
  );

}

export default DashboardWeeklyOrders;
