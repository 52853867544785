import { Route } from 'react-router-dom';
import SubscriptionManagement from './SubscriptionManagement';

const SubscriptionManagementRouter = () => {
  return (
    <>
      <Route exact path="/spark/subscription-management" component={SubscriptionManagement} />
    </>
  );

}

export default SubscriptionManagementRouter;