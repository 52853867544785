import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';

// const API_ID = "ErtjdjTUeBnGYbJAiy7C";
const apikey = "ROG8OIr4hTJ5VcqUfFCVKKrx1Uq0P-GXVLn69UKBXVk";

const CoreHereDisplayMap = (props) => {
  const {
    coordinates
  } = props;
  
  const mapRef = useRef(null);
  const map = useRef(null);
  let locationMarker = useRef(null);

  useEffect(() => {
    if (
      !isNaN(coordinates.lat) && 
      !isNaN(coordinates.lng) &&
      !map.current
    ) {
      const platform = new H.service.Platform({ apikey });
      const defaultLayers = platform.createDefaultLayers();
      const newMap = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, 
        {
          pixelRatio: window.devicePixelRatio,
          center: coordinates,
          zoom: 14,
        }
      );

      new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );
      H.ui.UI.createDefault(newMap, defaultLayers);
      locationMarker = new H.map.Marker(coordinates);
      newMap.addObject(locationMarker);
      map.current = newMap;
    } else if (
      !isNaN(coordinates.lat) &&
      !isNaN(coordinates.lng) &&
      map.current
    ){
      map.current.removeObjects(map.current.getObjects());
      locationMarker = new H.map.Marker(coordinates)
      map.current.addObject(locationMarker);
      map.current.setCenter(coordinates);
    }
  }, [coordinates]);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={mapRef} />
  );
}

export default CoreHereDisplayMap;