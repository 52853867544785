import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'MobileCompanyConfiguration/' + token + '/';

export async function getMobileConfigurationData() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetMobileConfig');
  return result;
}

export async function setMobileConfigurationData(data) {
  let {name, value, role} = data;
  value = value === true ? "true" : "false";
  const body = {name, value, role};
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SetMobileConfig', body);
  return result;
}

/**
Example:

{ name: displayPayAmount, value: true, role: owner-operator } <- only owner-operator can see pay amount

{ name: displayPayAmount, value: false, role: owner-operator } <- only company-driver can see pay amount

{ name: displayPayAmount, value: false, role: null } <- no one can see pay amount

{ name: displayPayAmount, value: true, role: null } <- every one can see pay amount

 */
export function handleConfig(event, configurationOptions) {
  let { name, value, role } = event;
  const previousConfig = configurationOptions[findConfig(name, configurationOptions)];

  if ((name === "acceptLoads" || name === "rejectLoads") && value === true) {
    role = "owner-operator";
    return { name, value, role };
  }
  if ((name === "acceptLoads" || name === "rejectLoads") && value === false) {
    role = null;
    return { name, value, role };
  }

  if (value === true && previousConfig.role && configurationOptions.role !== role) {
    role = null;
    return { name, value, role };
  }

  if (value === true && previousConfig.role === null) {
    // role = role;
    return event;
  }
  if (value === true && previousConfig.role !== null) {
    role = null;
    return { name, value, role };
  }
  if (value === false && previousConfig.value === true && previousConfig.role === null) {
    value = !value;
    if (role === "owner-operator") {
      role = "company-driver";
    } else if (role === "company-driver") {
      role = "owner-operator";
    }
    return { name, value, role };
  }
  if (value === false && previousConfig.value === true && previousConfig.role !== null) {
    role = null;
    return { name, value, role };
  }
  if (value === false) {
    role = null;
  }
  return { name, value, role };
}

const findConfig = (name, configOptions) => {
  return Object.values(configOptions).findIndex((i) => i.name === name);
};