import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import CoreNumberField from '../core/CoreNumberField';
import IntermodalUnitTypeField from './IntermodalUnitTypeField';
import IntermodalUnitRateTypeField from './IntermodalUnitRateTypeField';
import ReceivableChargeTypeField from '../receivable_charge_type/ReceivableChargeTypeField';
import { List, Paper } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import CoreCheckboxField from '../core/CoreCheckboxField';
import Typography from '@material-ui/core/Typography';

const ConfigurationLeaseTypeCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    payableItemFormData,
    onCreate,
    errorMessage,
    payableItemFormValidation,
    updatePayableItemFormData,
    inProgress,
    receivableChargeTypeList,
    updatePayableRelatedReceivableChargeType,
    payableChargeTypeList,
    updatePayableRelatedPayableChargeType
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Expense Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="Name"
                label="Name"
                value={payableItemFormData.Name}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={true}
                validationError={payableItemFormValidation.Name}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="DescriptionDefault"
                label="Description"
                value={payableItemFormData.DescriptionDefault}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={true}
                validationError={payableItemFormValidation.DescriptionDefault}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <IntermodalUnitTypeField
                name="IntermodalUnitTypeName"
                label="Unit Type"
                value={payableItemFormData.IntermodalUnitTypeName}
                nameData="IntermodalUnitType"
                valueData={payableItemFormData.IntermodalUnitType}
                isRequired={true}
                onChange={e => updatePayableItemFormData(e)}
                validationError={payableItemFormValidation.IntermodalUnitType}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <IntermodalUnitRateTypeField
                name="IntermodalUnitRateTypeName"
                label="Unit Rate Type"
                value={payableItemFormData.IntermodalUnitRateTypeName}
                nameData="IntermodalUnitRateType"
                valueData={payableItemFormData.IntermodalUnitRateType}
                isRequired={true}
                onChange={e => updatePayableItemFormData(e)}
                validationError={payableItemFormValidation.IntermodalUnitRateType}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              {payableItemFormData.IntermodalUnitType !== 2 && <CoreNumberField
                name="UnitsDefaultValue"
                label="Unit Default"
                value={payableItemFormData.UnitsDefaultValue}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={true}
                disabled={payableItemFormData.IntermodalUnitType === 1 || payableItemFormData.IntermodalUnitType === 3 }
                validationError={payableItemFormValidation.UnitsDefaultValue}
              />}
              {payableItemFormData.IntermodalUnitType === 2 && <CoreNumberField
                name="UnitsDefaultValuePercent"
                label="Unit Default %"
                value={payableItemFormData.UnitsDefaultValuePercent}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={true}
                icon={'%'}
              />}
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="UnitRateDefaultValue"
                label="Unit Rate Default"
                value={payableItemFormData.UnitRateDefaultValue}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={true}
                validationError={payableItemFormValidation.UnitRateDefaultValue}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <ReceivableChargeTypeField
                label="Related Revenue Item"
                name="RelatedReceivableChargeTypeName"
                value={payableItemFormData.RelatedReceivableChargeTypeName}
                nameData="RelatedReceivableChargeTypeID"
                valueData={payableItemFormData.RelatedReceivableChargeTypeID}
                onChange={e => updatePayableItemFormData(e)}
                isRequired={false}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
          {(payableItemFormData.IntermodalUnitRateType === 1 || payableItemFormData.IntermodalUnitRateType === 3) && <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={6}>
              <Typography variant="subtitle1" gutterBottom className="mb-2">
                {"Related Revenue Items For Auto Pay: "}            
              </Typography>
              <Paper style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
                <List dense={true}>
                  { receivableChargeTypeList.map((receivableChargeType) =>
                    <ListItem>
                      <CoreCheckboxField                     
                        label={receivableChargeType.Name}
                        value={receivableChargeType.IsChecked}
                        name={receivableChargeType.ReceivableChargeTypeID}           
                        rate={(receivableChargeType.UnitsDefaultValue * receivableChargeType.UnitRateDefaultValue)}
                        onChange={e => updatePayableRelatedReceivableChargeType(e)}
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>
            {payableItemFormData.IntermodalUnitRateType === 3 && <Grid item md={6} xs={6}>
              <Typography variant="subtitle1" gutterBottom className="mb-2">
                {"Related Expense Items For Auto Pay: "}            
              </Typography>
              <Paper style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
                <List dense={true}>
                  { payableChargeTypeList.map((payableChargeType) =>
                    <ListItem>
                      <CoreCheckboxField                     
                        label={payableChargeType.Name}
                        value={payableChargeType.IsChecked}
                        name={payableChargeType.PayableChargeTypeID}  
                        rate={(payableChargeType.UnitsDefaultValue * payableChargeType.UnitRateDefaultValue)}
                        onChange={e => updatePayableRelatedPayableChargeType(e)}
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>}
          </Grid>}
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={inProgress} type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default ConfigurationLeaseTypeCreateDialogContent;