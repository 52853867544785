import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateAppointmentDialog } from "@envasetechnologies/precheck-components";

import { getToken } from '../order/ReservationService'
import { getTIN } from '../tax/TaxService';
import { get as getBranch } from '../branch/BranchService';
import { 
  Create as CreateIntermodalOrderAppointment,
  UpdatePaidStatus as IntermodalAppointmentUpdatePaidStatus,
  UpdateBookedDraft
} from '../intermodal_order_appointments/IntermodalOrderAppointmentsService';
import { usePaymentRequests, useCartRequests } from '@envasetechnologies/feepay-components';
import OrderAppointmentQuickBuySuccessDialog from './OrderAppointmentQuickBuySuccessDialog';
import OrderAppointmentAuthorizeTerminalFeesDialog from './OrderAppointmentAuthorizeTerminalFeesDialog';
import { get as getIntermodalOrder, getTerminalCharges, getIntermodalEvents } from '../order/OrderService';
import { format } from 'date-fns';

const OrderAppointmentCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
    order,
    containerNum
  } = props;

  const [container, setContainer] = useState({})
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAuthorizeTerminalFeesDialogOpen, setIsAuthorizeTerminalFeesDialogOpen] = useState(false);
  const [draftNum, setDraftNum] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [legOptions, setLegOptions] = useState([]);
  const [checkoutStatus, setCheckoutStatus] = useState();
  const [preCheckToken, setPreCheckToken] = useState('');
  const [motorCarrierTIN, setMotorCarrierTIN] = useState('');
  const [draftAppointment, setDraftAppointment] = useState('');
  const [intermodalOrder, setIntermodalOrder] = useState({});
  const [appointmentBookingData, setAppointmentBookingData] = useState({
    'MotorCarrierSCACCode':'',
    'EquipmentLoadStatus': '',
    'LocationCode': '',
    'GateTransactionType': '',
    'ContainerNumber': '',
    'ReferenceNo': null, //export
    'DriverLicenseNumber': ''
});
  
  const feePayPaymentRequests = usePaymentRequests();
  const cartRequests = useCartRequests();
  
  let history = useHistory();

  useEffect(() => {
    async function getOrder() { 
      const response = await getIntermodalOrder(order.ID);
      if (response) { 
        setIntermodalOrder(response);
      }
    };

    async function getPreCheckToken() {
      const response = await getToken();
      if (response) {
        setPreCheckToken(response);
      }
    };

    async function getTaxIdentificationNumber() {
      const response = await getTIN();
      if (response) {
        setMotorCarrierTIN(response);
        handleContainer(order);
      }
    };

    async function getEvents() {
      const response = await getIntermodalEvents(order.ID);
      if (response) {
        const legOptions = response.map(event => ({
            leg: event.IntermodalLeg.LegIndex + 1,
            eventType: event.EventType,
            locationCode: event.LocationTemplate.EnvaseTraceProvider.LocationCode
        }));
        setLegOptions(legOptions);
      }
    }

    async function createDraftAppointment() {
      var createAppointmentRequest = {
        intermodalOrderID: order.ID,
        shipperPortalOrderID: order.ShipperPortalOrderID,
        containerNum: containerNum,
        locationCode: appointmentBookingData.LocationCode,
        status: 'Draft'
      }
      const appointmentResponse = await CreateIntermodalOrderAppointment(createAppointmentRequest);
      setDraftAppointment(appointmentResponse);
    }

    if (isOpen) {
      getPreCheckToken()
        .then(() => {
          return getOrderBranch(order);
        })
        .then(() => { 
          getOrder();
        })
        .then(() => {
          getTaxIdentificationNumber();
        });
      getEvents();
      createDraftAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getOrderBranch = async (order) => {
    let branch = await getBranch(order.BranchID);
    appointmentBookingData.MotorCarrierSCACCode = branch.SCAC;
    setAppointmentBookingData({ ...appointmentBookingData });
  };

  async function handleContainer(order) {
    if (order.Type === "Import") {
      setContainer({
        containerNumber: containerNum,
        motorCarrierSCACCode: "",
      });
    } else {
      setContainer({
        containerNumber: containerNum,
        equipmentLoadStatus: appointmentBookingData.EquipmentLoadStatus,
        isoEquipmentType: "",
        locationCode: appointmentBookingData.LocationCode,
        motorCarrierSCACCode: "",
        referenceNo: order.BookingNum,
        sealNumber: null,
        tractorLicensePlateNumber: null,
        type: appointmentBookingData.GateTransactionType,
        truckerDriverLicenseNumber: appointmentBookingData.DriverLicenseNumber
      });
    }

    setIsDataLoaded(true);
  };

  const processAddToCart = async (appointmentResponse) => { 
      var productInput = { 
      id: '',
      externalId: appointmentResponse.InvoiceDraftNumber,
      type: 'ICTSI_APPOINTMENT_INVOICE',
      description: containerNum,
      canRefresh: true,
      maxRefreshes: 10,
      initialDurationInMinutes: 1,
      refreshDurationInMinutes: 1
    };
    await cartRequests.requestAddProductToCart(productInput);
    setCheckoutStatus('success');
  };

  const processQuickBuy = async (appointmentResponse) => { 
    const body = {
      product: {
        externalId: appointmentResponse.InvoiceDraftNumber,
        type: 'ICTSI_APPOINTMENT_INVOICE',
        description: containerNum
      },
      paymentMethod: {
        type: 'ICTSI_ON_ACCOUNT'
      }
    };
    const quickBuyResponse = await feePayPaymentRequests.requestQuickBuy(body);
    if (quickBuyResponse.status === 'TRANSACTION_COMPLETE') {
      const updatePaidStatusRequest = {
        IntermodalOrderID: order.ID,
        AppointmentID: appointmentResponse.ID,
        AmountPaid: quickBuyResponse.amountCollected,
        PaymentMethod: quickBuyResponse.paymentMethod,
        TransactionID: quickBuyResponse.lastTransactionId
      };
      
      await IntermodalAppointmentUpdatePaidStatus(updatePaidStatusRequest);
      setIsDialogOpen(true);
    } else if (quickBuyResponse.status === 'TRANSACTION_FAILED') {
      // add appointment to shopping cart?
      console.log('Payment Failed - Logic is still missing.');
    }
  }

  const handleOnSuccess = async (response) => {    
    var appointmentFormData = {
      id: draftAppointment.ID,
      intermodalOrderID: order.ID,
      shipperPortalOrderID: order.ShipperPortalOrderID,
      gateTransactionID: response.data.gateTransactionId,
      containerNum: container.containerNumber,
      motorCarrierTIN: motorCarrierTIN,
      locationCode: container.locationCode
    };
    const appointmentResponse = await UpdateBookedDraft(appointmentFormData);

    if (appointmentResponse.IsOnAccountCustomer) { 
      await processQuickBuy(appointmentResponse);
    } else if (appointmentResponse.IsADRCustomer) { 
      setCheckoutStatus('pending');
      await processAddToCart(appointmentResponse);      
    }
    await handleTerminalFees();
  };

  const handleTerminalFees = async() => {
    var eventFormData = {
      containerNumber: container.containerNumber,
      payThroughDate: format(new Date(), 'MM/dd/yyyy HH:mm')
    };
    getTerminalCharges(eventFormData)
      .then(function (response) {
        setDraftNum(response.draftNbr);
        setPaymentMethods(response.PaymentMethods ?? []);
        if (response.totalAmountDue && response.totalAmountDue > 0) {      
          setIsAuthorizeTerminalFeesDialogOpen(true);
        }
      });
  };

  const handleClose = () => {
    setContainer(null);
    onClose();
  };

  const handleCloseSuccessDialog = async() => {
    setIsDialogOpen(false);
    setIsAuthorizeTerminalFeesDialogOpen(true);
  };

  const handleCloseAuthorizeTerminalFeesDialog = async() => {
    setIsAuthorizeTerminalFeesDialogOpen(false);
  };

  const payTerminalFees = async() => {    
    setIsAuthorizeTerminalFeesDialogOpen(false);
    if (paymentMethods.OnAccountPaymentMethod) {
      await processQuickBuyTerminalFees();
    } else if (paymentMethods.ADRPaymentMethod) {
      await processAddToCartTerminalFees();
    }
  };

  const processQuickBuyTerminalFees = async () => { 
    const body = {
      product: {
        externalId: draftNum,
        type: 'ICTSI_FEE_INVOICE',
        description: containerNum,
      },
      paymentMethod: {
        type: 'ICTSI_ON_ACCOUNT'
      }
    };
    await feePayPaymentRequests.requestQuickBuy(body);
  }

  const processAddToCartTerminalFees = async () => { 
    var productInput = { 
      id: '',
      externalId: draftNum,
      type: 'ICTSI_FEE_INVOICE',
      description: containerNum,
      canRefresh: true,
      maxRefreshes: 10,
      initialDurationInMinutes: 1,
      refreshDurationInMinutes: 1
    };
    await cartRequests.requestAddProductToCart(productInput);
  };

  const onCheckoutClick = () => {
    history.push('/spark/cart')
  };

  return (
    <div>
      {isDialogOpen && <OrderAppointmentQuickBuySuccessDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseSuccessDialog}
      />}
      {isAuthorizeTerminalFeesDialogOpen && <OrderAppointmentAuthorizeTerminalFeesDialog
        isOpen={isAuthorizeTerminalFeesDialogOpen}
        handleClose={handleCloseAuthorizeTerminalFeesDialog}
        payTerminalFees={payTerminalFees}
      />}
      {isOpen && isDataLoaded && <CreateAppointmentDialog
        isOpen={isOpen && isDataLoaded && !!container}
        onClose={handleClose}
        container={container}
        onError={(error) => console.log(error)}
        onSuccess={(response) => handleOnSuccess(response)}
        accessToken={preCheckToken}
        motorCarrierTIN={motorCarrierTIN}
        tmsReferenceNumber={draftAppointment.ID.toString()}
        legOptions={legOptions}
        onCheckoutClick={() => onCheckoutClick()}
        checkoutStatus={checkoutStatus}
        loadReadyDate={intermodalOrder.Type === "Export" ? intermodalOrder.LoadReadyDate : null}
      />}
    </div>
  );

}

export default OrderAppointmentCreateDialog;
