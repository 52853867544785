import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import { getPayableItem, updatePayableItem } from './PayableItemService';
import PayableItemEditDialogContent from './PayableItemEditDialogContent';
import { getList as getPayableChargeTypeList, getRCTList, deleteRCTs, addRCT, getPCTList, deletePCTs, addPCT } from '../payableChargeType/PayableChargeTypeService';
import { getList as getReceivableChargeTypeList } from '../receivable_charge_type/ReceivableChargeTypeService';
// payableChargeTypeID

const PayableItemEditDialog = (props) => {
  const {
    payableItemID,
    isOpen,
    onClose,
  } = props;

  const [payableItemFormData, setPayableItemFormData] = useState({});
  const [payableItemFormValidation, setPayableItemFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [receivableChargeTypeList, setReceivableChargeTypeList] = useState([]);
  const [receivableChargeTypeApplyList, setReceivableChargeTypeApplyList] = useState([]);
  const [payableChargeTypeList, setPayableChargeTypeList] = useState([]);
  const [payableChargeTypeApplyList, setPayableChargeTypeApplyList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getPayableItem(payableItemID)
      .then(function (response) {
        setPayableItemFormData(response);
      });        
    }    
  }, [isOpen, payableItemID]);

  useEffect(() => {
    if (isOpen) {
        setPayableItemFormValidation(buildValidation(payableItemFormData));
    }
  }, [payableItemFormData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      async function getReceivableChargeTypes() { 
        const result = await getReceivableChargeTypeList();
          formatReceivableChargeTypes(result);
          setInProgress(false);
      }
      async function getPayableChargeTypes() { 
        const result = await getPayableChargeTypeList(payableItemID);
          formatPayableChargeTypes(result);
          setInProgress(false);
      }
      getReceivableChargeTypes();
      getPayableChargeTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const formatReceivableChargeTypes = receivableChargeTypes => {
    getRCTList(payableItemID)
      .then(function (response) {    
        formatReceivableChargeTypeApplyList(response, receivableChargeTypes);
      }); 
  };

  const formatPayableChargeTypes = payableChargeTypes => {
    getPCTList(payableItemID)
      .then(function (response) {    
        formatPayableChargeTypeApplyList(response, payableChargeTypes);
      }); 
  };

  const formatReceivableChargeTypeApplyList = (response, receivableChargeTypes) => {
    setReceivableChargeTypeApplyList([]);
    response.forEach(
      item => {
        receivableChargeTypeApplyList.push(item.ReceivableChargeTypeID);
        for (var i = 0; i < receivableChargeTypes.length; i++) {
          if (item.ReceivableChargeTypeID === receivableChargeTypes[i].ReceivableChargeTypeID) {
            receivableChargeTypes[i].IsChecked = true;
            break;
          }
        }
      }
    );
    setReceivableChargeTypeList(receivableChargeTypes);
    setReceivableChargeTypeApplyList(receivableChargeTypeApplyList);
  };

  const formatPayableChargeTypeApplyList = (response, payableChargeTypes) => {
    setPayableChargeTypeApplyList([]);
    response.forEach(
      item => {
        payableChargeTypeApplyList.push(item.RelatedPayableChargeTypeID);
        for (var i = 0; i < payableChargeTypes.length; i++) {
          if (item.RelatedPayableChargeTypeID === payableChargeTypes[i].PayableChargeTypeID) {
            payableChargeTypes[i].IsChecked = true;
            break;
          }
        }
      }
    );
    setPayableChargeTypeList(payableChargeTypes);
    setPayableChargeTypeApplyList(payableChargeTypeApplyList);
  };

  const updatePayableItemFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    payableItemFormData[name] = value;
    payableItemFormData[nameData] = valueData;
    if (payableItemFormData.IntermodalUnitType === 1 || payableItemFormData.IntermodalUnitType === 3) {
      payableItemFormData.UnitsDefaultValue = 1;
    }
    if (payableItemFormData.IntermodalUnitType === 2) {
      payableItemFormData.UnitsDefaultValue = payableItemFormData.UnitsDefaultValuePercent / 100;
    } else {
      payableItemFormData.UnitsDefaultValuePercent = payableItemFormData.UnitsDefaultValue * 100;
    }
    if (payableItemFormData.IntermodalUnitRateType !== 1 || payableItemFormData.IntermodalUnitRateType !== 3) {
      resetReceivableChargeTypes();
    }
    if (payableItemFormData.IntermodalUnitRateType !== 3) {
      resetPayableChargeTypes();
    }
    setPayableItemFormData({ ...payableItemFormData });
  };

  const resetReceivableChargeTypes = () => {
    setReceivableChargeTypeApplyList([]);
    receivableChargeTypeList.forEach(
      item => {
        item.IsChecked = false;
      }
    );
  };

  const resetPayableChargeTypes = () => {
    setPayableChargeTypeApplyList([]);
    payableChargeTypeList.forEach(
      item => {
        item.IsChecked = false;
      }
    );
  };

  const updatePayableRelatedReceivableChargeType = e => {
    const { name, value, rate } = e.target;
    if (value === false) {
      var index = receivableChargeTypeApplyList.indexOf(name);
      if (index !== -1) {
        receivableChargeTypeApplyList.splice(index, 1);
        setPayableItemFormData(c => ({ ...c, UnitRateDefaultValue: (payableItemFormData.UnitRateDefaultValue ?? 0) - rate }));    
      }
    } else {
      receivableChargeTypeApplyList.push(name);
      setPayableItemFormData(c => ({ ...c, UnitRateDefaultValue: (payableItemFormData.UnitRateDefaultValue ?? 0) + rate }));    
    }
    setReceivableChargeTypeApplyList(receivableChargeTypeApplyList);
  };

  const updatePayableRelatedPayableChargeType = e => {
    const { name, value, rate } = e.target;
    if (value === false) {
      var index = payableChargeTypeApplyList.indexOf(name);
      if (index !== -1) {
        payableChargeTypeApplyList.splice(index, 1);
        setPayableItemFormData(c => ({ ...c, UnitRateDefaultValue: (payableItemFormData.UnitRateDefaultValue ?? 0) - rate }));    
      }
    } else {
      payableChargeTypeApplyList.push(name);
      setPayableItemFormData(c => ({ ...c, UnitRateDefaultValue: (payableItemFormData.UnitRateDefaultValue ?? 0) + rate }));    
    }
    setPayableChargeTypeApplyList(payableChargeTypeApplyList);
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(payableItemFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await updatePayableItem(payableItemFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    } else {
      deleteRelatedRCTs();
    }
    closeDialog();
  };

  const deleteRelatedRCTs = async() => {
    deleteRCTs(payableItemID)
      .then(function (result) {
        deleteRelatedPCTs();
      });
  };

  const deleteRelatedPCTs = async() => {
    deletePCTs(payableItemID)
      .then(function (result) {
        addAdditionals();
      });
  };

  const addAdditionals = async() => {
    receivableChargeTypeApplyList.forEach(r => {
      addRCT(payableItemID, r);
    });
    payableChargeTypeApplyList.forEach(p => {
      addPCT(payableItemID, p);
    });
  };

  const closeDialog = () => {
    setReceivableChargeTypeApplyList([]);
    onClose();
  };

  return (
    isOpen &&
    <PayableItemEditDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      payableItemFormData={payableItemFormData}
      payableItemFormValidation={payableItemFormValidation}
      updatePayableItemFormData={updatePayableItemFormData}
      inProgress={inProgress}
      receivableChargeTypeList={receivableChargeTypeList}
      updatePayableRelatedReceivableChargeType={updatePayableRelatedReceivableChargeType}
      payableChargeTypeList={payableChargeTypeList}
      updatePayableRelatedPayableChargeType={updatePayableRelatedPayableChargeType}
    />
  );
}

const buildValidation = (payableItemFormData) => {
  const {
    Name,
    DescriptionDefault,
    UnitsDefaultValue,
    UnitRateDefaultValue,
    IntermodalUnitType,
    IntermodalUnitRateType,  
  } = payableItemFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
    DescriptionDefault: validation.isEmpty(DescriptionDefault),
    UnitsDefaultValue: validation.isNumber(UnitsDefaultValue),
    UnitRateDefaultValue: validation.isNumber(UnitRateDefaultValue),
    IntermodalUnitType: validation.isNumber(IntermodalUnitType),
    IntermodalUnitRateType: validation.isNumber(IntermodalUnitRateType),
  };
};

export default PayableItemEditDialog;
