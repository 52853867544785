import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreCheckDisplayField from '../core/CoreCheckDisplayField';

const useStyles = makeStyles(() => ({
  line: {
    opacity: 0.3
  },
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  }
}));

const OrderViewGeneralSection = (props) => {
  const classes = useStyles();
  const {
    order
  } = props;

  return (
    <div>
    <Container square={true} maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>General Information</h4>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <CoreTextDisplayField
              label="Bill to Customer"
              value={order.Customer}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={2}>
            { order.CustomerInvCode && 
              <CoreTextDisplayField
                label="Invoice Code"
                value={order.CustomerInvCode}
                iconSpacing={true}
                />
            }
          </Grid>
          <Grid item xs={2}>
            <CoreTextDisplayField
              label="Type"
              value={order.Type}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={2}>
            {order.LiveDrop && <CoreTextDisplayField
              label="Live/Drop"
              value={order.LiveDrop}
              iconSpacing={true}
            />}
            {order.LoadedStatus && <CoreTextDisplayField
              label="Loaded Status"
              value={order.LoadedStatus}
              iconSpacing={true}
            />}
          </Grid>
          <Grid item xs={2}>
            <CoreTextDisplayField
              label="Container Weight"
              value={`${order.Weight} ${order.WeightUOM}`}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={2}>
            <CoreTextDisplayField
              label="Number of Pieces"
              value={order.NumPieces}
              iconSpacing={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Reference Number"
              value={order.ReferenceNumber}
              iconSpacing={true}
            />
          </Grid>          
        </Grid>
      </Grid>
    </Container>
    <Container square={true} maxWidth="lg" component={Paper} style={{padding: 0}}>
      <hr className={classes.line}></hr>
    </Container>
    <Container square={true} maxWidth="lg" component={Paper}>
      <Grid container spacing={2} style={{paddingTop: '16px'}}>  
        <Grid container item xs={12}>
          <Grid item xs={2}>
            <CoreCheckDisplayField
              label="Overweight"
              value={order.IsOverweight ? true : false}
              disabled={!order.IsOverweight}
            />
          </Grid>
          <Grid item xs={2}>
            <CoreCheckDisplayField
              label="HAZMAT"
              value={order.IsHazmat ? true : false}
              disabled={!order.IsHazmat}
            />
          </Grid>
          <Grid item xs={2}>
            <CoreCheckDisplayField
              label="On Hold"
              value={order.OnHold ? true : false}
              disabled={!order.OnHold}
            />
          </Grid>
          {/*
          {order.OnHold && <Grid item xs={2}>
            <CoreTextDisplayField
              label="Reason for Hold"
              value={order.HoldReason}
            />
          </Grid>}
          */}
          <Grid item xs={2}>
            <CoreCheckDisplayField
              label="Expedite"
              value={order.IsExpedite ? true : false}
              disabled={!order.IsExpedite}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreCheckDisplayField
              label="Temperature Management"
              value={order.IsTempManaged ? true : false}
              disabled={!order.IsTempManaged}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
    </div>
  );

}

OrderViewGeneralSection.propTypes = {
  order: PropTypes.object.isRequired,
  isMoreButtonEnabled: PropTypes.bool.isRequired
};

export default OrderViewGeneralSection;