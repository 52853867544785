import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import { format } from 'date-fns';
import OrderAppointmentCancelButton from './OrderAppointmentCancelButton';

const OrderAppointmentsListTable = (props) => {
  const {
    appointments,
    onAppointmentCancel
  } = props;

  let columns = [
    {
      field: 'AppointmentNumber',
      headerName: 'Appointment Number',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'LocationCode',
      headerName: 'Location',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'ContainerNum',
      headerName: 'Container Number',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'AppointmentDate',
      headerName: 'Appointment Date',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("AppointmentDate") ? format(new Date(params.getValue("AppointmentDate")), 'MM/dd/yyyy HH:mm') : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      width: 150,
      renderCell: (params) => {  
        var appointment = params.row;
        return (
          <div style={{display: 'inline-flex'}}>
            <OrderAppointmentCancelButton
              appointment={appointment}
              onAppointmentCancel={onAppointmentCancel}
            />
          </div>
        )
      }
    },
  ];

  return (
    <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
      <DataGrid
        getRowId={(row) => row.ID} 
        rows={appointments}
        columns={columns} 
        hideFooter={true} 
        autoHeight={true} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </Paper>
  );
}

OrderAppointmentsListTable.propTypes = {
  appointments: PropTypes.array.isRequired,
};

export default OrderAppointmentsListTable;