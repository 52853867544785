import React from "react";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import CoreTextField from "../core/CoreTextField";
import CarrierListField from "../carrier/CarrierListField";
import EquipmentOwnerTypeDropdownField from "../equipment/EquipmentOwnerTypeDropdownField";
import CarrierOwnerOperatorListField from "../carrier/CarrierOwnerOperatorListField";
import Validation from "../validation";
import CoreNumberField from "../core/CoreNumberField";

const OrderCreateContentContainerInfo = (props) => {
  const {
    orderFormData,
    orderFormValidation,
    updateOrderFormData
  } = props;
  const validation = new Validation();

  return (
    <>
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Container Information</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} className="mt-2" style={{ paddingBottom: '24px' }}>
          <Grid item md={3} xs={4}>
            <CoreTextField
              name="containerNum"
              label="Container #"
              value={orderFormData.containerNum}
              onChange={e => updateOrderFormData(e)}
              isCapsOnly={true}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <CoreNumberField            
              name="containerSize"
              label="Container Size"
              minValue={0}
              value={orderFormData.containerSize}
              validationError={orderFormValidation.containerSize}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <EquipmentOwnerTypeDropdownField
              value={orderFormData.OwnerType}
              onChange={e => updateOrderFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            {orderFormData.OwnerType === 1 &&
              <CarrierOwnerOperatorListField
                name="ownerVendorName"
                label="Container Owner"
                value={orderFormData.ownerVendorName}
                nameData="ownerVendorID"
                valueData={orderFormData.ownerVendorID}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.ownerVendorID}
                isRequired={orderFormData.OwnerType === 1 && !validation.isEmpty(orderFormData.containerNum)}
              />
            }
            {orderFormData.OwnerType === 2 &&
              <CarrierListField
                name="ownerVendorName"
                label="Container Vendor"
                value={orderFormData.ownerVendorName}
                nameData="ownerVendorID"
                valueData={orderFormData.ownerVendorID}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.ownerVendorID}
                isRequired={orderFormData.OwnerType === 2 && !validation.isEmpty(orderFormData.containerNum)}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default OrderCreateContentContainerInfo;