import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';

const EnvaseTraceConfigureDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    formData,
    onUpdate,
    errorMessage,
    formValidation,
    updateFormData,
    inProgress,
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">
          Configure Provider
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            {formData.LoginType === 'User Password' &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="username"
                  label="Username"
                  value={formData.username}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.username}
                />
              </Grid>
            }
            {formData.LoginType === 'Email Password' &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.email}
                />
              </Grid>
            }
            {formData.LoginType === 'API Key' &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="apiKey"
                  label="API Key"
                  value={formData.apiKey}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.apiKey}
                />
              </Grid>
            }
            {(formData.LoginType === 'Authorization' || formData.LoginType === 'Authorization SID') &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="authorization"
                  label="Authorization Token"
                  value={formData.authorization}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.authorization}
                />
              </Grid>
            }
            {formData.LoginType === 'Authorization SID' &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="sid"
                  label="SID"
                  value={formData.sid}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.sid}
                />
              </Grid>
            }
            {formData.LoginType === 'Fleet' &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="fleet"
                  label="Fleet"
                  value={formData.fleet}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.fleet}
                />
              </Grid>
            }
            {(formData.LoginType === 'User Password' || formData.LoginType === 'Email Password') &&
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="password"
                  label="Password"
                  value={formData.password}
                  onChange={e => updateFormData(e)}
                  isRequired={true}
                  validationError={formValidation.password}
                />
              </Grid>
            }
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default EnvaseTraceConfigureDialogContent;