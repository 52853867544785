import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as PrintSvg } from '../assets/svg-icons/print@1x.svg';

const IconPrint = () => {
  return (
    <SvgIcon fontSize="small">
      <PrintSvg />
    </SvgIcon>
  )
}
export default IconPrint;