import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CountryField from '../country/CountryField';
import ProvinceField from '../province/ProvinceField';

const LocationCreateDialogContent = (props) => {
  const {
    isOpen,
    resetForm,
    locationFormData,
    onCreate,
    errorMessage,
    branch,
    locationFormValidation,
    updateLocationFormData
  } = props;

  const submitForm = (e) => { 
    onCreate(e);
  };
  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form>
        <DialogTitle id="form-dialog-title">Create Location</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="internalName"
                label="Internal Name"
                value={locationFormData.internalName}
                onChange={e => updateLocationFormData(e)}
                isRequired={true}
                validationError={locationFormValidation.internalName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="companyName"
                label="Company Name"
                value={locationFormData.companyName}
                onChange={e => updateLocationFormData(e)}
                isRequired={true}
                validationError={locationFormValidation.companyName}
              />
            </Grid>
            {(locationFormData.country !== "Philippines" && branch.Country !== "Philippines") && <>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="address"
                  label="Address"
                  value={locationFormData.address}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.address}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CoreTextField
                  name="city"
                  label="City"
                  value={locationFormData.city}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.city}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CoreTextField
                  name="postalCode"
                  label="ZIP / Postal Code"
                  value={locationFormData.postalCode}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.postalCode}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ProvinceField
                  name="province"
                  label="State / Province"
                  value={locationFormData.province}
                  onChange={e => updateLocationFormData(e)}
                  isRequired={true}
                  validationError={locationFormValidation.province}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CountryField
                  label="Country"
                  name="country"
                  value={locationFormData.country}
                  onChange={e => updateLocationFormData(e)}
                  isRequired={true}
                  validationError={locationFormValidation.country}
                />
              </Grid>
            </>}
            {(locationFormData.country === "Philippines" || branch.Country === "Philippines") && <>
              <Grid item md={6} xs={12}>
                <CountryField
                  label="Country"
                  name="country"
                  value={locationFormData.country}
                  onChange={e => updateLocationFormData(e)}
                  isRequired={true}
                  validationError={locationFormValidation.country}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ProvinceField
                  name="province"
                  label="State / Province"
                  value={locationFormData.province}
                  onChange={e => updateLocationFormData(e)}
                  isRequired={true}
                  validationError={locationFormValidation.province}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CoreTextField
                  name="city"
                  label="City"
                  value={locationFormData.city}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.city}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CoreTextField
                  name="postalCode"
                  label="ZIP / Postal Code"
                  value={locationFormData.postalCode}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.postalCode}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="District"
                  label="District"
                  value={locationFormData.District}
                  onChange={e => updateLocationFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="County"
                  label="Barangay"
                  value={locationFormData.County}
                  onChange={e => updateLocationFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="address"
                  label="Address"
                  value={locationFormData.address}
                  onChange={e => updateLocationFormData(e)}
                  validationError={locationFormValidation.address}
                />
              </Grid>
            </>}
            
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={e => submitForm(e)}>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LocationCreateDialogContent;