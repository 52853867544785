import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import IconLocationLight from '../icon/IconLocationLight';
import IconCity from '../icon/IconCity';
import IconState from '../icon/IconState';
import IconZipCode from '../icon/IconZipCode';
import IconCountry from '../icon/IconCountry';
import IconPhone from '../icon/IconPhone';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import { useMileageGetGeocodeRequest } from '../hooks/useMileageGetGeocodeRequest';

const LocationViewAddressSection = (props) => {
  const {
    location
  } = props;
  const coordinatesResponse = useMileageGetGeocodeRequest(location);

  return (
    <div style={{ backgroundColor: '#F3F0FA'}}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          <Grid container spacing={2} xs={12}>
            <Grid item md={12} xs={12}>
              <h4>Address</h4>
            </Grid>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="Address"
                  value={location.Address}
                  icon={<IconLocationLight />}
                />
              </Grid>
            {location.Country !== "Philippines" && <>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="City"
                  value={location.City}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="State / Province"
                  value={location.Province}
                  icon={<IconState />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Zip Code/Postal Code"
                  value={location.PostalCode}
                  icon={<IconZipCode />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Country"
                  value={location.Country}
                  icon={<IconCountry />}
                />
              </Grid>
            </>}
            {location.Country === "Philippines" && <>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="District"
                  value={location.District}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={12}>
                <CoreTextDisplayField
                  label="Barangay"
                  value={location.County}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Zip Code/Postal Code"
                  value={location.PostalCode}
                  icon={<IconZipCode />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="City"
                  value={location.City}
                  icon={<IconCity />}
                />
              </Grid>
              
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="State / Province"
                  value={location.Province}
                  icon={<IconState />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Country"
                  value={location.Country}
                  icon={<IconCountry />}
                />
              </Grid>
            </>}
            <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Primary Number"
                  value={location.Phone}
                  icon={<IconPhone />}
                  isPhoneNum={true}
                />
              </Grid>
              <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Fax"
                  value={location.Fax}
                  icon={<IconPhone/>}
                  isPhoneNum={true}
                />
              </Grid>
              <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Secondary Number"
                  value={location.TollFree}
                  icon={<IconPhone />}
                  isPhoneNum={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6}>
          {coordinatesResponse.data &&
            <CoreHereDisplayMap
              coordinates={coordinatesResponse.data}
            />
          }
        </Grid>
      </Grid>
    </div>
  );

}

export default LocationViewAddressSection;