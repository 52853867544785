import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from './DocumentFieldService';
import DocumentFieldEditDialogContent from './DocumentFieldEditDialogContent';

const DocumentFieldEditDialog = (props) => {
  const {
    documentFieldID,
    isOpen,
    onClose,
  } = props;

  const [documentFieldFormData, setDocumentFieldFormData] = useState({});
  const [documentFieldFormValidation, setDocumentFieldFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      get(documentFieldID)
      .then(function(response) {
        setDocumentFieldFormData(response);
      });    
    }
  }, [isOpen, documentFieldID]);

  useEffect(() => {
    if (isOpen) {
      setDocumentFieldFormValidation(buildValidation(documentFieldFormData));
    }
  }, [documentFieldFormData, isOpen]);

  const updateDocumentFieldFormData = e => {
    const { name, value } = e.target;
    documentFieldFormData[name] = value;
    setDocumentFieldFormData({ ...documentFieldFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(documentFieldFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(documentFieldFormData);
    setInProgress(false);
    if (result.errors) {
        setErrorMessage(result.errors.Error);
        return;
    }
    onClose();
  };

  return (
    isOpen &&
    <DocumentFieldEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      documentFieldFormData={documentFieldFormData}
      documentFieldFormValidation={documentFieldFormValidation}
      updateDocumentFieldFormData={updateDocumentFieldFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (documentFieldFormData) => {
  const {
    FieldName
  } = documentFieldFormData || {};
  const validation = new Validation();
  return {
    FieldName: validation.isEmpty(FieldName)
  };
};

export default DocumentFieldEditDialog;