import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconShipType from '../icon/IconShipType';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreToggleDisplayField from '../core/CoreToggleDisplayField';

import IconTruck from '../icon/IconTruck';
import IconLicensePlate from '../icon/IconLicensePlate';
import IconDateCal from '../icon/IconDateCal';

const PersonnelViewDriverInfoSection = (props) => {
  const {
    personnel,
    driverLicense,
    TWIC
  } = props;

  return (
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>Driver Checks/Exam</h4>
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Driver Type"
            value={personnel.DriverTypeName}
            icon={
              <IconTruck />
            }          
          />
        </Grid>
        <Grid item xs={3}>
          <CoreToggleDisplayField
            label="HAZMAT Certificate"
            value={personnel.Check1}
            icon={
              <IconShipType /> 
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreToggleDisplayField
            label="Medical Exam"
            value={personnel.Check2}
            icon={
              <IconShipType /> 
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreToggleDisplayField
            label="Drug Test"
            value={personnel.Check3}
            icon={
              <IconShipType /> 
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Driver License"
            value={driverLicense.DocumentNumber}
            icon={
              <IconLicensePlate />
            }          
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Expiration Date"
            value={driverLicense.ExpiryDate}
            icon={
              <IconDateCal />
            }          
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="TWIC #"
            value={TWIC.DocumentNumber}
            icon={
              <IconLicensePlate />
            }          
          />
        </Grid>
        <Grid item xs={3} className="mb-2">
          <CoreTextDisplayField
            label="Expiration Date"
            value={TWIC.ExpiryDate}
            icon={
              <IconDateCal />
            }          
          />
        </Grid>
      </Grid>
  );
}

export default PersonnelViewDriverInfoSection;