import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconDispatch from '../icon/IconDispatch';

const NavigationDrawerListItemDispatch = () => {
  return (
    <NavigationDrawerListItem
      title={'Dispatches'}
      icon={<IconDispatch />}
      uri={'/spark/dispatch'} />
  );

}

export default NavigationDrawerListItemDispatch;