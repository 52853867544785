import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Message/';

export const getConversations = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'DriverConversationList?token=' + token);
  return result.response;
};

export const getMessages = async (personnelID) => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'DriverMessageList?personnelID=' + personnelID + '&token=' + token);
  return result.response;
};

export const sendMessage = async (personnelID, message) => {
  var postBody = {
    PersonnelID : personnelID,
    body: message,
    source: "EnvaseMobile" //Assumes all messages are going to driver.
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create?token=' + token, postBody);
  return result;
};

export const markAsRead = async (personnelID) => {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'MarkRead?personnelID=' + personnelID + '&token=' + token);
  return result.response;
};

export const getContactDriversList = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'DriverContactList?token=' + token);
  return result.response;
}
