import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import IconDateCal from '../icon/IconDateCal';
import IconLicensePlate from '../icon/IconLicensePlate';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const PersonnelViewSummaryTravelDocsSection = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="DRIVER INFORMATION" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Drivers License"
              value="Still Todo"
              icon={<IconLicensePlate />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Drivers License Expiry"
              value="Still Todo"
              icon={<IconDateCal />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="TWIC #"
              value="Still Todo"
              icon={<IconLicensePlate />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="TWIC Expiry"
              value="Still Todo"
              icon={<IconDateCal />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonnelViewSummaryTravelDocsSection;