import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'AreaTagLine/' + token + '/';

export async function get(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};

export async function getList(areaTagID) {
  var params = { areaTagID: areaTagID };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList', params);
  return result.response;
};

export async function create(areaTagLineFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', areaTagLineFormData);
  return result;
};

export async function update(areaTagLineFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', areaTagLineFormData);
  return result;
};

export async function deleteAreaTagLine(id) {
  var params = {
    areaTagLineID: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};