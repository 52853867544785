import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  iconFloatRight: {
    margin: 0,
    top: 'auto',
    bottom: 12,
    left: 'auto',
    float: 'right',
    background: '#2196F3',
    '&:hover': {
      backgroundColor: '#2196F3',
    },
    color: '#FFFFFF'
  },
}));

const CoreExitButton = (props) => {
  const {
    onClick
  } = props;

  const classes = useStyles();

  return (
    <>
      <Fab 
        color="primary" 
        aria-label="exit" 
        className={classes.iconFloatRight}
        disableRipple
        // style={style}
        onClick={onClick}>
        <CloseIcon />
      </Fab>
    </>
  );
}

export default CoreExitButton;