import React from 'react';
import AutomaticPayableRuleSetField from './AutomaticPayableRuleSetField';

const AutomaticPayableRuleSetSection = (props) => {
  const {
    autoPayFormData,
    updateAutoPayFormData,
  } = props;

  return (
    <>      
      <AutomaticPayableRuleSetField
        name="AutoPayRuleSet"
        label="Automatic Expense Rule-Set"
        value={autoPayFormData.AutoPayRuleSet}
        onChange={e => updateAutoPayFormData(e)}
        disableClearable={true}
      />
    </>
  );

}

export default AutomaticPayableRuleSetSection;