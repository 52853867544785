import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CoreTextDisplayField from '../core/CoreTextDisplayField';

import IconOrganization from '../icon/IconOrganization';
import IconInternalName from '../icon/IconInternalName';
import IconYardMove from '../icon/IconYardMove';

const LocationViewGeneralSection = (props) => {
  const {
    location
  } = props;

  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>Location Details</h4>
        </Grid>
        <Grid container spacing={2} xs={12} className="mb-2">
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Internal Name"
              value={location.InternalName}
              icon={<IconInternalName />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Company Name"
              value={location.CompanyName}
              icon={<IconOrganization />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Trace Provider"
              value={location.ProviderName}
              icon={<IconOrganization />}
            />
          </Grid>
          <Grid item xs={3}>
            <CoreTextDisplayField
              label="Type"
              value={location.Type}
              icon={<IconYardMove fontSize={'small'} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

}

export default LocationViewGeneralSection;