import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import RevenueListTable from '../revenue/RevenueListTable';
import RevenueCreateDialog from '../revenue/RevenueCreateDialog';
import Button from '@material-ui/core/Button';

import { getReceivables } from '../order/OrderService';

const IntermodalInvoiceViewRevenueList = (props) => {
  const {
    intermodalOrderID
  } = props;

  const [receivables, setReceivables] = useState([]);
  const [isRevenueCreateDialogOpen, setRevenueCreateDialogOpen] = useState(false);

  useEffect(() => {
    getReceivablesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermodalOrderID]);

  function getReceivablesList() {
    getReceivables(intermodalOrderID)
      .then(function (response) {
        setReceivables(response);
      });
  };

  const openRevenueCreateDialog = () => {
    setRevenueCreateDialogOpen(true);
  };

  const closeRevenueCreateDialog = () => {
    setRevenueCreateDialogOpen(false);
    getReceivablesList();
  };

  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={11} xs={12}>
          <h4>Revenue</h4>
        </Grid>
      </Grid>
      <RevenueListTable
        receivables={receivables}
        getReceivables={getReceivablesList}
        setReceivables={setReceivables}
      />
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} className="mt-1">
          <Button onClick={openRevenueCreateDialog} color="primary">
            Add Revenue Item
          </Button>
        </Grid>
      </Grid>
      <RevenueCreateDialog
        id={intermodalOrderID}
        isOpen={isRevenueCreateDialogOpen}
        onClose={closeRevenueCreateDialog}
      />
    </Container>
  );
}

IntermodalInvoiceViewRevenueList.propTypes = {
  // Not required since component may load without lists
  intermodalOrderID: PropTypes.number
};

export default IntermodalInvoiceViewRevenueList;