import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import OrderViewSummaryTabBarDetail from './OrderViewSummaryTabBarDetail';
import OrderViewSummaryTabBarEvent from './OrderViewSummaryTabBarEvent';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
  tabWidth: {
    width: '1170px',
  }
}));

const OrderViewSummaryTabBar = (props) => {
  const {
    order,
    equipment,
    documents,
    events,
    getEvents, 
    setInProgress,
    tabSelected,
    onChange
  } = props;
  const classes = useStyles();

  const onTabEvent = (newValue) => {
    const tempEvent = {
      target: {
        value: newValue
      }
    };
    onChange(tempEvent);
  };

  function getNumberOfEvents() { 
    var totalEventsNum = 0;
    events.forEach(e => {
      totalEventsNum += e.IntermodalEvents.length;
    });
    return totalEventsNum;
  };

  return (
    <Container style={{ padding: '0', position: 'relative' }} maxWidth="lg"  className="mt-3">
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Tabs
            value={tabSelected}
            onChange={(event, newValue) => { onTabEvent(newValue) }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab value={1} label='Details' />
            <Tab value={0} label={'Events (' + getNumberOfEvents() + ')'} />
          </Tabs>
        </Grid>
      </Grid>
      { tabSelected === 0 &&
        <OrderViewSummaryTabBarEvent
          events={events}
          getEvents={getEvents}
          setInProgress={setInProgress}
        />
      }
      { tabSelected === 1 && 
        <OrderViewSummaryTabBarDetail
          order={order}
          equipment={equipment}
          documents={documents}
        />
      }
    </Container>
  );

}

export default OrderViewSummaryTabBar;