import React from 'react';
import Grid from '@material-ui/core/Grid';

import PersonnelAccountRepField from '../personnel/PersonnelAccountRepField';
import CoreTextField from '../core/CoreTextField';

const CustomerCreateDialogContentInfo = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData
  } = props;

  return (
    <>
      <Grid item md={6} xs={12}>
        <CoreTextField
          name="internalName"
          label="Internal Name"
          value={customerFormData.internalName}
          onChange={e => updateCustomerFormData(e)}
          isRequired={true}
          validationError={customerFormValidation.internalName}
          autoFocus={true}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CoreTextField
          name="legalName"
          label="Legal Name"
          value={customerFormData.legalName}
          onChange={e => updateCustomerFormData(e)}
          isRequired={true}
          validationError={customerFormValidation.legalName}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CoreTextField
          name="email"
          label="Email"
          value={customerFormData.email}
          onChange={e => updateCustomerFormData(e)}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PersonnelAccountRepField
          name="accountRep"
          label="Account Rep"
          value={customerFormData.accountRep}
          valueID={customerFormData.accountRepID}
          onChange={e => updateCustomerFormData(e)}
          isRequired={true}
          validationError={customerFormValidation.accountRepID}
        />
      </Grid>
    </>
  );
}

export default CustomerCreateDialogContentInfo;