import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const PersonnelGenderField = (props) => {
    const {
        label,
        value,
        name,
        onChange
    } = props;

    const [optionSelected, setOptionSelected] = useState(value);

    const onSelectEvent = (newValue) => {
      const dispatchEvent = {
        target: {
          name,
          value: newValue
        }
      };
      onChange(dispatchEvent);
    };

    const handleChange = (event) => {
      onSelectEvent(event.target.value)
      setOptionSelected(event.target.value);
    };

    return (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={optionSelected}
          onChange={handleChange}
        >
          <MenuItem value="M">Male</MenuItem>
          <MenuItem value="F">Female</MenuItem>
        </Select>
      </FormControl>
    );
}

export default PersonnelGenderField;