import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as PersonnelSvg } from '../assets/svg-icons/personnel@1x.svg'

const IconPersonnel = () => {
  return (
    <SvgIcon fontSize="small">
      <PersonnelSvg />
    </SvgIcon>
  )
}

export default IconPersonnel;