import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ContainerSvg } from '../assets/svg-icons/container@1x.svg';

const IconContainer= () => {
    return (
        <SvgIcon fontSize="small">
            <ContainerSvg />
        </SvgIcon>    
    )
}
export default IconContainer;
