import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as EquipmentSvg } from '../assets/svg-icons/equipment@1x.svg'

const IconEquipment = () => {
  return (
    <SvgIcon fontSize="small">
      <EquipmentSvg />
    </SvgIcon>
  )
}

export default IconEquipment;