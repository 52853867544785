import HttpService from "../http-service";
import { format } from 'date-fns';

const token = localStorage.getItem('token');
const uri = 'Report/' + token + '/';

export async function getProfitByCustomerSummary(reportFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalProfitByCustomerSummary', reportFormData);
  const response = result.data;
  for (const row of response) { 
    row.Revenue = row.Revenue ? row.Revenue : 0;
    row.Expense = row.Expense ? row.Expense : 0;
    row.RevenuePerHour = row.Hours ? (row.Revenue / row.Hours).toFixed(2) : 0;
    row.RevenuePerMile = row.Miles ? (row.Revenue / row.Miles).toFixed(2) : 0;
    row.ExpensePerHour = row.Hours ? (row.Expense / row.Hours).toFixed(2) : 0;
    row.ExpensePerMile = row.Miles ? (row.Expense / row.Miles).toFixed(2) : 0;
    row.Profit = (row.Revenue - row.Expense).toFixed(2);
    row.ProfitMargin = row.Revenue ? (100 * ((row.Revenue - row.Expense) / row.Revenue)).toFixed(2) : 0;  
    row.Hours = row.Hours ? row.Hours : 0;
    row.Miles = row.Miles ? row.Miles : 0;
  } 
  return response;
};

export async function getProfitByCustomerDetail(reportFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalProfitByCustomerDetail', reportFormData);
  const response = result.data;
  for (const row of response) { 
    row.Revenue = row.Revenue ? row.Revenue : 0;
    row.Expense = row.Expense ? row.Expense : 0;
    row.RevenuePerHour = row.Hours ? (row.Revenue / row.Hours).toFixed(2) : 0;
    row.RevenuePerMile = row.Miles ? (row.Revenue / row.Miles).toFixed(2) : 0;
    row.ExpensePerHour = row.Hours ? (row.Expense / row.Hours).toFixed(2) : 0;
    row.ExpensePerMile = row.Miles ? (row.Expense / row.Miles).toFixed(2) : 0;
    row.Profit = (row.Revenue - row.Expense).toFixed(2);
    row.ProfitMargin = row.Revenue ? (100 *((row.Revenue - row.Expense) / row.Revenue)).toFixed(2) : 0;
    row.Hours = row.Hours ? row.Hours : 0;
    row.Miles = row.Miles ? row.Miles : 0;
    row.InvoiceDate = row.InvoiceDate ? format(new Date(row.InvoiceDate), 'MM/dd/yyyy') : null;
  }
  return result.data;
};

export async function getCustomerLogs(reportFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CustomerLogs', reportFormData);
  const response = result.data;
  for (const row of response) { 
    row.ModificationDate = row.ModificationDate ? format(new Date(row.ModificationDate), 'LLL dd, yyyy') : null;
  }
  return result.data;
};