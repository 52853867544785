import { Route } from 'react-router-dom';
import FeePayTransactionHistoryList from './FeePayTransactionHistoryList';
import FeePayShoppingCart from './FeePayShoppingCart';
import FeePayShoppingCartPaymentResult from './FeePayShoppingCartPaymentResult';

const FeePayRouter = () => {
  return (
    <>
      <Route exact path="/spark/transaction-history" component={FeePayTransactionHistoryList} />
      <Route exact path="/spark/cart" component={FeePayShoppingCart} />
      <Route exact path="/spark/cart-payment" component={FeePayShoppingCartPaymentResult} />
    </>
  );
}

export default FeePayRouter;