import React from 'react';
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconTrash from '../icon/IconTrash';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';

const QuickbooksMappedRevenueList = (props) => {

  const {
    inProgress,
    mappedRevenueData,
    revenueMappingDelete,
    revenueMappingEdit,
  } = props;

  const useStyles = makeStyles(() => ({
    iconText: {
      fontSize: '0.875rem',
      fontWeight: 550,
      color: 'black',
      filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
    },
  }));

  const classes = useStyles();

  const columns = [
    { 
      field: 'Name',
      headerName: 'Local Revenue Item',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            {params.getValue("Name")}
          </div>
        )
      }
    },
    { 
      field: 'QuickbooksRevenueItemName',
      headerName: 'QuickBooks Revenue Item',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <div>{params.getValue("QuickbooksRevenueItemName")}</div>
        )
      }
    },
    {
      field: 'AcctSyncXactID',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const acctID = params.getValue("AcctSyncXactID");
        return (
          <div style={{ width:'100%',textAlign:'right' }}>
            <IconButton className={classes.iconText} onClick={() => revenueMappingEdit(acctID)}>
              <EditIcon fontSize='small' />
            </IconButton>
            <IconButton className={classes.iconText} onClick={() => revenueMappingDelete(acctID)}>
              <IconTrash />
            </IconButton>
          </div>
        )
      }
    }, 
  ];

  return (
    <div style={{width: '100%'}}>
      {inProgress && <LinearProgress />}
      <DataGrid
        rows={mappedRevenueData}
        columns={columns}
        getRowId={(row) => row.AcctSyncXactID}
        hideFooter={true} 
        autoHeight={true} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  );
}

export default QuickbooksMappedRevenueList;
