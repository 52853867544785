import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  summaryFooter: {
    position: 'fixed',
    bottom: '0',
    width: '400px',
    margin: '0',
    backgroundColor: '#ffffff',
    borderTop: '1px solid #ededed',
    boxShadow: '2px 13px 10px 4px #333333',
    zIndex: '3'
  },
  fixedFooterSpacer: {
    height: '70px'
  },
  root: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const FeePayAdrSlideOutPayButton = (props) => {
  const classes = useStyles();
  const {
    handlePayButtonClick,
    selectedAdrs,
    items
  } = props;

  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true);


  useEffect(() => {
    var totalAmount = getTotalSelectedAdrAmount();
    if (parseFloat(items[0].amount) !== roundNumber(totalAmount)) {
      setIsPayButtonDisabled(true);
    } else {
      setIsPayButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdrs]);

  const getTotalSelectedAdrAmount = () => {
    var totalAdrs = 0;
    selectedAdrs.forEach((adr) => {
      totalAdrs += getNumber(adr.amount);
    });
    return totalAdrs;
  };

  const getNumber = (number) => {
    if (number && number.toString().charAt(0) === 'e') {
      return parseFloat(number.toString().substring(1));
    }
    return parseFloat(number);
  };

  const roundNumber = (number) => {
    return Math.round(number * 100) / 100;
  }

  return (
    <div>
      <div className={classes.summaryFooter}>
        <Container className="mt-1 mb-1">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isPayButtonDisabled}
              onClick={() => handlePayButtonClick()}
            >
              Pay
            </Button>
        </Container>
      </div>
      <div className={classes.fixedFooterSpacer} />
    </div>
  );
}

export default FeePayAdrSlideOutPayButton;
