import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/Brightness1';
import CoreButtonContainer from '../core/CoreButtonContainer';
import CoreExitButton from '../core/CoreExitButton';
import CoreEditButton from '../core/CoreEditButton';
import CoreMoreMenuButton from '../core/CoreMoreMenuButton';
import OrderCopyDialog from './OrderCopyDialog';
import OrderCancelDialog from './OrderCancelDialog';
import CoreDeleteDialog from '../core/CoreDeleteDialog';
import { deleteOrder, reactivate, markAsFullyPaid, markAsUnpaid, favorite as favoriteOrder, unfavorite as unfavoriteOrder, complete } from './OrderService';
import OrderPrintMenuButton from './OrderPrintMenuButton';
import OrderPrintBOLDialog from './OrderPrintBOLDialog';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  },
  statusIcon: {
    color: 'black',
    fontWeight: 'normal',
    borderRadius: '15px',
    padding: '5px',
    fontSize: '12px',
    backgroundColor: '#E0E0E0'
  },
  iconCircle: {
    fontSize: '15px',
    verticalAlign: 'text-bottom'
  },
}));

const OrderViewPageHeader = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const {
    order,
    getOrder,
    getNotes,
    printInvoice,
    printBatchInvoice,
    onCloseRoute
  } = props;
  const [isOrderCopyDialogOpen, setIsOrderCopyDialogOpen] = useState(false);
  const [isOrderDeleteDialogOpen, setIsOrderDeleteDialogOpen] = useState(false);
  const [isOrderCancelDialogOpen, setIsOrderCancelDialogOpen] = useState(false);
  const [isOrderBOLDialogOpen, setIsOrderBOLDialogOpen] = useState(false);
  const [statusIcon, setStatusIcon] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const openPrint = Boolean(anchorElPrint);

  useEffect(() => { 
    getOrderStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  function reactivateOrder() { 
    reactivate(order.ID)
      .then(function(response) {
        getOrder();
      });
  };

  function getOrderStatus() {
    switch(order.Status) {
      case "Unassigned": 
        setStatusIcon(getStatusIcon('#BDBDBD', order.Status));
        break;
      case "Dispatched": 
        setStatusIcon(getStatusIcon('#54C7F2', order.Status));
        break;
      case "In Transit": 
        setStatusIcon(getStatusIcon('#FFA30A', order.Status));
        break;
      case "Completed": 
        setStatusIcon(getStatusIcon('#86DC76', order.Status));
        break;
      case "Billing On Hold": 
        setStatusIcon(getStatusIcon('#EA5858', order.Status));
        break;
      case "Approved For Billing": 
        setStatusIcon(getStatusIcon('#9575CD', order.Status));
        break;
      case "Invoiced": 
        setStatusIcon(getStatusIcon('#80CBC4', order.Status));
        break;
      case "Cancelled": 
        setStatusIcon(getStatusIcon('#EA5858', order.Status));
        break;
      default: return;
    }
  };

  function getStatusIcon(circleColor, statusName) {
    return <span className={classes.statusIcon}><CircleIcon style={{ color: circleColor }} className={classes.iconCircle}/> {statusName}&nbsp;</span>;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrintClick = (event) => {
    setAnchorElPrint(event.currentTarget);
  };

  const handlePrintClose = () => {
    setAnchorElPrint(null);
  };


  const copy = () => { 
    setIsOrderCopyDialogOpen(true);
    setAnchorEl(null);
  };

  const cancel = () => { 
    setIsOrderCancelDialogOpen(true);
    setAnchorEl(null);
  };

  const deleteOrderClick = () => { 
    setIsOrderDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const favorite = () => { 
    favoriteOrder(order.ID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const unFavorite = () => { 
    unfavoriteOrder(order.ID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const completeOrder = () => {
    complete(order.ID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
     });    
  };

  const markOrderAsPaid = () => { 
    markAsFullyPaid(order.ID) 
      .then(function(response) { 
         getOrder();
         setAnchorEl(null);
      });
  };

  const markOrderAsUnpaid = () => {
    markAsUnpaid(order.ID)
      .then(function(response) { 
        getOrder();
        setAnchorEl(null);
      });
  };

  const onClose = () => {
    setIsOrderCopyDialogOpen(false);
    setIsOrderDeleteDialogOpen(false);
    setIsOrderCancelDialogOpen(false);
  };

  const onExitButtonClose = () => { 
    history.push(onCloseRoute);
  };

  const openBOLDialog = () => {
    setIsOrderBOLDialogOpen(true);
  };

  const closeBOLDialog = () => {
    setIsOrderBOLDialogOpen(false);
  };

  const navigateToList = () => { 
    history.push('/spark/order');
  };

  return (
    <>
    <Paper 
      variant="outlined" 
      className={classes.purpleBackground}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              { "Order " + order.OrderNum + " Details" }
              &nbsp;&nbsp;&nbsp;
              {statusIcon}
            </Typography>
          </Grid>
          <Grid item md={6} spacing={2} container justify="flex-end" alignItems="center" style={{paddingRight: '90px'}}>
            <CoreButtonContainer>
              {order.Status !== "Cancelled" && <CoreEditButton
                editView={'/spark/order/edit/' + order.ID}
              />}
              <OrderPrintMenuButton
                onClick={handlePrintClick}
              />
              <Menu
                id="long-menu-print"
                anchorEl={anchorElPrint}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={openPrint}
                onClose={handlePrintClose}
                PaperProps={{
                  style: {
                    maxHeight: '150px',
                    width: 'auto',
                  },
                }}
              >
              {
                <MenuItem onClick={() => printInvoice()}>Invoice</MenuItem>
              }
              {order.BatchInvoiceNum &&
                <MenuItem onClick={() => printBatchInvoice()}>Batch Invoice</MenuItem>
              }
              {
                <MenuItem onClick={() => openBOLDialog()}>BOL</MenuItem>
              }                          
              </Menu>
              {order.Status === "Cancelled" &&
                <Button variant="outlined" className={classes.buttonColor} onClick={() => reactivateOrder()}>
                  Reactivate
                </Button>
              }
              <CoreMoreMenuButton
                onClick={handleClick}
              />
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '150px',
                    width: 'auto',
                  },
                }}
              >
              {order.Status !== "Cancelled" && 
                <MenuItem onClick={copy}>Copy</MenuItem>
              }
              {order.Status !== "Cancelled" && !order.Favorite &&
                <MenuItem onClick={favorite}>Favorite</MenuItem>
              }
              {order.Status !== "Cancelled" && order.Favorite && 
                <MenuItem onClick={unFavorite}>Unfavorite</MenuItem>
              }
              {order.Status !== "Cancelled" && order.Status !== "Completed" && 
                <MenuItem onClick={completeOrder}>Complete</MenuItem>
              }
              {order.Status !== "Cancelled" && 
                <MenuItem onClick={cancel}>Cancel</MenuItem>
              }
              {order.Status !== "Invoiced" && 
                <MenuItem onClick={deleteOrderClick}>Delete</MenuItem>
              }    
              {order.Status === "Invoiced" && !order.IsFullyPaid &&
                <MenuItem onClick={markOrderAsPaid}>Mark As Paid</MenuItem>
              }
              {order.Status === "Invoiced" && order.IsFullyPaid &&
                <MenuItem onClick={markOrderAsUnpaid}>Mark As Unpaid</MenuItem>
              }
              </Menu>
            </CoreButtonContainer>
          </Grid>
        </Grid>
        <CoreExitButton
          onClick={onExitButtonClose}
        />
      </Container>
    </Paper>
    <OrderCopyDialog
      open={isOrderCopyDialogOpen}
      onClose={onClose}
      order={order}
    />
    <CoreDeleteDialog
      deleteID={order.ID}
      reference={"ORDER " + order.OrderNum}
      deleteFunction={deleteOrder}
      onDeleteSuccess={navigateToList}
      isOpen={isOrderDeleteDialogOpen}
      onClose={onClose}
    />
    <OrderCancelDialog
      open={isOrderCancelDialogOpen}
      onClose={onClose}
      order={order}
      getOrder={getOrder}
      getNotes={getNotes}
    />
    <OrderPrintBOLDialog
      open={isOrderBOLDialogOpen}
      onClose={closeBOLDialog}
      orderID={order.ID}
    />
    </>
  );
}

OrderViewPageHeader.propTypes = {
  order: PropTypes.object.isRequired,
  printBatchInvoice: PropTypes.func.isRequired
};

export default OrderViewPageHeader;