import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CoreNumberField from '../core/CoreNumberField';
import CoreDateField from '../core/CoreDateField';
import PayableChargeTypeField from '../payableChargeType/PayableChargeTypeField';
import PersonnelField from '../personnel/PersonnelField';
import IntermodalLegEventField from '../intermodalEvent/IntermodalLegEventField';

const IntermodalPaymentDeductibleCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    deductibleFormData,
    onCreate,
    errorMessage,
    deductibleFormValidation,
    updateDeductibleFormData,
    disableOrderNumber,
    inProgress
  } = props;

  const updateUnits = data => {
    const dispatchUnits = {
      target: {
        name: "Units",
        value: data.UnitsDefaultValue
      }
    };
    const dispatchUnitRate = {
      target: {
        name: "UnitRate",
        value: data.UnitRateDefaultValue
      }
    };
    updateDeductibleFormData(dispatchUnits);
    updateDeductibleFormData(dispatchUnitRate);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Expense</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={6} xs={12}>
                <CoreDateField
                    name={"BillDate"}
                    label="Expense Date"
                    value={deductibleFormData.BillDate}
                    onChange={e => updateDeductibleFormData(e)}
                    isRequired={true}
                    validationError={deductibleFormValidation.BillDate}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <PersonnelField
                    name={"PersonnelName"}
                    label={"Personnel"}
                    value={deductibleFormData.PersonnelName}
                    nameData="PersonnelID" 
                    valueData={deductibleFormData.PersonnelID}
                    onChange={e => updateDeductibleFormData(e)}
                    isRequired={true}
                    validationError={deductibleFormValidation.PersonnelID}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <CoreNumberField
                    name="OrderNumber"
                    label="Order Number"
                    value={deductibleFormData.OrderNumber}
                    onChange={e => updateDeductibleFormData(e)}
                    disabled={disableOrderNumber}
                />
            </Grid>
            {deductibleFormData.OrderNumber && <Grid item md={6} xs={12}>
              <IntermodalLegEventField
                  orderNum={deductibleFormData.OrderNumber}
                  label="Leg"
                  name="LegEventLocation"
                  value={deductibleFormData.LegEventLocation}
                  nameData="LegID"
                  valueData={deductibleFormData.LegID}
                  isRequired={false}
                  validationError={deductibleFormValidation.LegID}
                  onChange={e => updateDeductibleFormData(e)}
              />
            </Grid>}
            <Grid item md={12} xs={12}>
                <PayableChargeTypeField
                    label="Expense Item"   
                    value={deductibleFormData.PayableChargeTypeName}
                    name="PayableChargeTypeName"
                    nameData="PayableChargeTypeID"
                    valueData={deductibleFormData.PayableChargeTypeID}
                    isRequired={true}
                    validationError={deductibleFormValidation.PayableChargeTypeID}
                    responseObject={updateUnits}
                    onChange={e => updateDeductibleFormData(e)}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <CoreTextField
                    name="Description"
                    label="Description"
                    value={deductibleFormData.Description}
                    onChange={e => updateDeductibleFormData(e)}
                    validationError={deductibleFormData.Description}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <CoreNumberField
                    name="Units"
                    label="Quantity"
                    isDecimal={true}
                    value={deductibleFormData.Units}
                    onChange={e => updateDeductibleFormData(e)}
                    validationError={deductibleFormValidation.Units}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <CoreNumberField
                    name="UnitRate"
                    label="Rate"
                    minValue={0}
                    isCurrency={true}
                    value={deductibleFormData.UnitRate}
                    onChange={e => updateDeductibleFormData(e)}
                    validationError={deductibleFormValidation.UnitRate}
                />
            </Grid>
            <Grid item md={6} xs={12}>

            </Grid>
            <Grid item md={6} xs={12}>
                <CoreNumberField
                    name="Subtotal"
                    label="Total"
                    minValue={0}
                    isCurrency={true}
                    disabled={true}
                    value={deductibleFormData.Subtotal}
                    validationError={deductibleFormValidation.Subtotal}
                />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button name="payable_deductible_create[confirm]" disabled={inProgress} type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default IntermodalPaymentDeductibleCreateDialogContent;