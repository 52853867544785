import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CoreButtonContainer from '../core/CoreButtonContainer';
import CoreExitButton from '../core/CoreExitButton';
import CoreEditButton from '../core/CoreEditButton';
import CoreMoreMenuButton from '../core/CoreMoreMenuButton';
import PersonnelEnableLoginDialog from './PersonnelEnableLoginDialog';
import PersonnelDisableLoginDialog from './PersonnelDisableLoginDialog';
import PersonnelEnableEnvaseMobile from './PersonnelEnableEnvaseMobile';
import PersonnelDisableEnvaseMobile from './PersonnelDisableEnvaseMobile';
import PersonnelResetDriverPasswordDialog from './PersonnelResetDriverPasswordDialog';
import {isEnvaseConnectEnabled } from './PersonnelService';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  }
}));

const PersonnelViewPageHeader = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const {
    personnel,
    isLoginEnabled,
    isOwner,
    refreshEvent,
    activatePersonnel,
    deactivatePersonnel
  } = props;
  const [envaseConnectEnabled, setEnvaseConnectEnabled] = useState(false);
  const [isEnableEnvaseMobileOpen, setIsEnableEnvaseMobileOpen] = useState(false);
  const [isDisableEnvaseMobileOpen, setIsDisableEnvaseMobileOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isEnvaseEnableLoginDialogOpen, setIsEnvaseEnableLoginDialogOpen] = useState(false);
  const [isEnvaseDisableLoginDialogOpen, setIsEnvaseDisableLoginDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(personnel.ListAsDriver) {
      async function checkEnvaseConnect() {
        const result = await isEnvaseConnectEnabled(personnel.PersonnelID);
        if (result.error) {
        } else {
          setEnvaseConnectEnabled(result.response);
        }
      }
      checkEnvaseConnect();
    }
  }, [personnel]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enableEnvaseConnect = () => {  
    setIsEnableEnvaseMobileOpen(true);
    setAnchorEl(null);
  };

  const disableEnvaseConnect = () => {  
    setIsDisableEnvaseMobileOpen(true);
    setAnchorEl(null);
  };

  const resetDriverPassword = () => { 
    setIsPasswordDialogOpen(true);
    setAnchorEl(null);
  };

  const enableLogin = () => {  
    setIsEnvaseEnableLoginDialogOpen(true);
    setAnchorEl(null);
  };

  const disableLogin = () => { 
    setIsEnvaseDisableLoginDialogOpen(true);
    setAnchorEl(null);
  };

  const activate = () => { 
    activatePersonnel();
    setAnchorEl(null);
  };

  const deactivate = () => { 
    deactivatePersonnel();
    setAnchorEl();
  };

  const onClose = () => {
    setIsPasswordDialogOpen(false);
    setIsEnvaseEnableLoginDialogOpen(false);
    setIsEnvaseDisableLoginDialogOpen(false);
    setIsEnableEnvaseMobileOpen(false);
    setIsDisableEnvaseMobileOpen(false);
    setErrorMessage(null);
    refreshEvent();
  };

  const onExitButtonClose = () => { 
    history.push('/spark/personnel');
  };

  return (
    <>
    <Paper 
      variant="outlined" 
      className={classes.purpleBackground}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              { "Personnel " + personnel.InternalName + " Details" }
            </Typography>
          </Grid>
          <Grid item md={6} spacing={2} container justify="flex-end" alignItems="center" style={{paddingRight: '90px'}}>
            <CoreButtonContainer>
              <CoreEditButton
                editView={'/spark/personnel/edit/' + personnel.PersonnelID}
              />
              <CoreMoreMenuButton
                onClick={handleClick}
              />
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '150px',
                    width: 'auto',
                  },
                }}
              >
              {personnel.ListAsDriver && envaseConnectEnabled && <MenuItem key="menuItem1" onClick={disableEnvaseConnect}>
                Disable Envase Mobile
              </MenuItem>}
              {personnel.ListAsDriver && !envaseConnectEnabled && <MenuItem key="menuItem2" onClick={enableEnvaseConnect}>
                Enable Envase Mobile
              </MenuItem>}
              {isOwner && isLoginEnabled && <MenuItem key="menuItem3" onClick={disableLogin}>
                Disable Envase TMS Login
              </MenuItem>}
              {isOwner && !isLoginEnabled && <MenuItem key="menuItem4" onClick={enableLogin}>
                Enable Envase TMS Login
              </MenuItem>}
              {personnel.ListAsDriver && 
              <Tooltip title={!personnel.Email ? "Driver must have an email address" : ""}>
              <span>
                <MenuItem key="menuItem5" onClick={resetDriverPassword}>
                    Reset Driver Password
                </MenuItem>
              </span>
              </Tooltip>}
              {personnel.Obsolete && <MenuItem key="menuItem5" onClick={activate}>
                Activate
              </MenuItem>}
              {!personnel.Obsolete && <MenuItem key="menuItem6" onClick={deactivate}>
                Deactivate
              </MenuItem>}
            </Menu>
            </CoreButtonContainer>
          </Grid>
        </Grid>
        <CoreExitButton
          onClick={onExitButtonClose}
        />
      </Container>
    </Paper>
    {personnel && 
    <PersonnelEnableEnvaseMobile
        isOpen={isEnableEnvaseMobileOpen}
        onClose={onClose}
        personnelID={personnel.PersonnelID}
        driverName={`${personnel.FirstName} ${personnel.LastName}`}>
    </PersonnelEnableEnvaseMobile>
    }
    {personnel &&
    <PersonnelDisableEnvaseMobile
        isOpen={isDisableEnvaseMobileOpen}
        onClose={onClose}
        personnelID={personnel.PersonnelID}
        driverName={`${personnel.FirstName} ${personnel.LastName}`}>
    </PersonnelDisableEnvaseMobile>
    }
    {personnel && 
    <PersonnelResetDriverPasswordDialog
        open={isPasswordDialogOpen}
        handleClose={onClose}
        personnel={personnel}
        errorMessage={errorMessage}
    />
    }
    {personnel &&
    <PersonnelEnableLoginDialog
        open={isEnvaseEnableLoginDialogOpen}
        handleClose={onClose}
        personnel={personnel}
        errorMessage={errorMessage}
    />
    }
    {personnel &&
    <PersonnelDisableLoginDialog
        open={isEnvaseDisableLoginDialogOpen}
        handleClose={onClose}
        personnel={personnel}
        errorMessage={errorMessage}
    />
    }
    </>
  );
}

PersonnelViewPageHeader.propTypes = {
  personnel: PropTypes.object.isRequired
};

export default PersonnelViewPageHeader;