import React from 'react';
import PropTypes from 'prop-types';

const CoreGhostLoader  = (props) => {
  const { height, margin, colorClass, loop} = props;

  let i = 0;
  let ghostItems = [];
  while (i < loop) {
    i++;
    ghostItems.push(
      <div className="ghost-loading-bar"
        style={{height: height, margin: margin}}
        key={`ghostItem-${i}`}
      />
    );
  }
  return (
    <div className={colorClass}>
      {ghostItems}
    </div>
  )
}

CoreGhostLoader.propTypes = {
  height: PropTypes.string,
  margin: PropTypes.string,
  colorClass: PropTypes.string
};

export default CoreGhostLoader;
