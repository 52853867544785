import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import CoreExitButton from '../core/CoreExitButton';
import Container from '@material-ui/core/Container';
import { getFull as getPersonnel, getTravelDocs } from './PersonnelService';
import PersonnelViewSummaryGeneralSection from './PersonnelViewSummaryGeneralSection';
import PersonnelViewSummaryEmployeeInfoSection from './PersonnelViewSummaryEmployeeInfoSection';
import PersonnelViewSummaryTravelDocsSection from './PersonnelViewSummaryTravelDocsSection';
import CoreSummaryFooter from '../core/CoreSummaryFooter';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  paper: {
    width:400
  }
}));

const PersonnelViewSummary = (props) => {
  const classes = useStyles();
  const {
    personnelID,
    isOpen,
    onClose,
  } = props;
  let history = useHistory();

  const [inProgress, setInProgress] = useState(true);
  const [personnel, setPersonnel] = useState({});
  const [travelDocs, setTravelDocs] = useState({});

  useEffect(() => {
    setInProgress(true);
    setPersonnel({});
    setTravelDocs({});
    if (isOpen) {
      getPersonnel(personnelID).then(function(response) {
        if(response.EmergencyNumber) {
          response.EmergencyNumber = response.EmergencyNumber.trim();
        }
        setPersonnel(response);
        setInProgress(false);
      });
      getTravelDocs(personnelID).then(function(response) {
        setTravelDocs(response);
      });
    }
  }, [isOpen, personnelID]);

  const editPersonnel = () => {
    history.push({
      pathname: '/spark/personnel/edit/' + personnel.ID,
      state: personnel
    });
  };

  const openPersonnel = () => {
    history.push('/spark/personnel/view/' + personnel.ID);
  };

  return (
    <>
      <Drawer 
        anchor={'right'} 
        open={isOpen}
        ModalProps={{onBackdropClick: onClose }}
        classes={{ paper: classes.paper }}>  
        <CoreSummaryHeader
          title={"Summary View"}
          isInverted={true}
          createButton={
            <CoreExitButton
              onClick={onClose}
            />
          }
        />
        {inProgress && <LinearProgress />}
        <Container maxWidth="lg" className="mt-1">
          <PersonnelViewSummaryGeneralSection
            personnel={personnel}
          />
          <PersonnelViewSummaryEmployeeInfoSection
            personnel={personnel}
          />
          <Hidden xsUp>
            { /*NOTE: Hidden until travelDocs is ironed out.*/}
            { personnel.ListAsDriver ? (
              <PersonnelViewSummaryTravelDocsSection
                travelDocs={travelDocs}
              />
            ) : null }
          </Hidden>
        </Container>
        {!inProgress && <CoreSummaryFooter
          editRecord={editPersonnel}
          openRecord={openPersonnel}
          disableEdit={false}
        />}
      </Drawer>
    </>    
  );

}

export default PersonnelViewSummary;