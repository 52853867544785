import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

const CoreListTextField = (props) => {
  const { 
    value, 
    isRequired, 
    autoFocus, 
    validationError, 
    maxLength, 
    isCapsOnly,
    readOnly,
    onLeaveEvent,
    ...rest 
  } = props;

  const inputValue = (e) => {
    if (isCapsOnly) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  return (
    <FormControl fullWidth>
      <Input
        type="text"
        value={value}
        onChange={e => e.target.value}
        onInput={inputValue}
        autoFocus={autoFocus}
        inputProps = {{maxLength:maxLength}}
        required={isRequired}
        readOnly={readOnly}
        {...rest}
      />
    </FormControl>
  );
}

CoreListTextField.propTypes = {
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default CoreListTextField;
