import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'PayableChargeType/' + token + '/';

export async function getPayableItem(id, legID) {
  var params = {
    payableChargeTypeID: parseInt(id),
    intermodalLegID: legID ? parseInt(legID) : null,
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalPayableItem', params);
  return result.response;
}

export async function getPayableItems() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetIntermodalPayableItemsList');
  const response = result.response;
  for (const payable of response) {
    payable.UnitRateDefaultValue = payable.UnitRateDefaultValue.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 5});
  } 
  return response;
}

export async function createPayableItem(payableItemFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'CreatePayableChargeType', payableItemFormData);
  return result;
}

export async function updatePayableItem(payableItemFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdatePayableChargeType', payableItemFormData);
  return result;
}

export async function deletePayableItem(id) {
  let params = {
    payableChargeTypeID: id
  }
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Obsolete', null, params);
  return result;
}