import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { DataGrid } from "@material-ui/data-grid";

import { GetDrivers } from './PointToPointTemplateService';

const PointToPointTemplateDriverDialog = (props) => { 
  const {
    isOpen,
    onClose,
    templateID,
    templateName
  } = props;

  const [drivers, setDrivers] = useState([]);

  const openRecord = (id) => { 
    window.open('/spark/personnel/view/' + id, '_blank');
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.25,
      renderCell: (params) => { 
        const id = parseInt(params.getValue("PersonnelID"));
        return ( 
          <IconButton title="Open Personnel Record" onClick={() => openRecord(id)}>              
            <OpenInBrowserIcon color='primary'/>
          </IconButton>
        )
      }
    },
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => { 
        const firstName = params.getValue("FirstName");
        const lastName = params.getValue("LastName");
        return (
          <span>{firstName + " " + lastName}</span>
        )
      }
    }
  ];

  useEffect(() => { 
    if (isOpen) { 
      GetDrivers(templateID)
        .then(function(response) { 
          setDrivers(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onDriverRowEnter = (params) => {
    for (const driver of drivers) {
      if (driver.PersonnelID === params.row.PersonnelID) {
        driver.ShowControls = true;
      }
    }
    setDrivers([...drivers]);
  };

  const onDriverRowLeave = (params) => {
    for (const driver of drivers) {
      if (driver.PersonnelID === params.row.PersonnelID) {
        driver.ShowControls = false;
      }
    }
    setDrivers([...drivers]);
  };

  return ( 
    <>
    {isOpen &&
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">Drivers using {templateName}</DialogTitle>
      <DialogContent className="mt-2">
        <DataGrid
          getRowId={(row) => row.PersonnelID} 
          hideFooter={true} 
          autoHeight={true} 
          rows={drivers} 
          columns={columns} 
          pageSize={100} 
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          onRowEnter={e => onDriverRowEnter(e)}
          onRowLeave={e => onDriverRowLeave(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    }
    </>
  )
};

export default PointToPointTemplateDriverDialog;