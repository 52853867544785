import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LegalNameSvg } from '../assets/svg-icons/legal-name@1x.svg';

const IconLegalName = () => {
  return (
    <SvgIcon fontSize="small">
      <LegalNameSvg />
    </SvgIcon>
  )
}
export default IconLegalName;