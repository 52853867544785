import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import NoteView from '../notes/NoteView';
import CoreNotePaper from '../core/CoreNotePaper';

const NotesList = (props) => {
  const {
    notes,
    getNotes
  } = props;

  const [isNoteViewOpen, setNoteViewOpen] = useState(false);
  const [noteSelected, setNoteSelected] = useState({});

  const handleClick = (note) => {
    setNoteSelected(note);
    setNoteViewOpen(true);
  };

  const closeNoteView = () => {
    setNoteViewOpen(false);
  };

  return (
    <>
      <Container style={{ padding: '0' }} maxWidth="lg" className="mt-1 mb-1">
        <Grid container spacing={2} className="mt-2">
          {notes.map((note) => {
            return (
              !note.Obsolete ? 
              <Grid item xs={3} key={note.NoteID}>
                <div onClick={() => handleClick(note)}>
                  <CoreNotePaper
                    note={note}
                    lightNote={true}
                    showNoteFooter={true}
                  />
                </div>
              </Grid>
              : null
            )
          })}
        </Grid>
      </Container>
      {isNoteViewOpen ? <NoteView note={noteSelected} getNotes={getNotes} isOpen={isNoteViewOpen} onClose={closeNoteView} /> : null}
    </>
  );
}

NotesList.propTypes = {
  notes: PropTypes.array.isRequired
};

export default NotesList;