import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import CoreAddButton from '../core/CoreAddButton';
import { deleteAreaTagLine } from './AreaTagLineService';
import AreaTagLineColumnEditButton from './AreaTagLineColumnEditButton';

const AreaTagLineListTable = (props) => {
  const {
    areaTagLines,
    getAreaTagLines,
    setAreaTagLines,
    openAreaTagLineCreateDialog
  } = props;

  let columns = [
    {
      field: 'City',
      headerName: 'City',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'Province',
      headerName: 'State',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'PostalCode',
      headerName: 'Zip Code',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openAreaTagLineCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const areaTagLineID = params.getValue("AreaTagLineID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <AreaTagLineColumnEditButton
                areaTagLineID={areaTagLineID} 
                getAreaTagLines={getAreaTagLines}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Area Tag Line: ${params.getValue("City")}`}
                deleteID={areaTagLineID}
                deleteFunction={deleteAreaTagLine}
                getList={getAreaTagLines}
              />}
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("areaTagLineAmount")) || 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("areaTagLineAmount", amount);
  };

  const onAreaTagLineRowEnter = (params) => {
    for (const item of areaTagLines) {
      if (item.AreaTagLineID === params.row.AreaTagLineID) {
        item.ShowControls = true;
      }
    }
    setAreaTagLines([ ...areaTagLines ]);
  };

  const onAreaTagLineRowLeave = (params) => {
    for (const item of areaTagLines) {
      if (item.AreaTagLineID === params.row.AreaTagLineID) {
        item.ShowControls = false;
      }
    }
    setAreaTagLines([...areaTagLines]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.AreaTagLineID} 
        autoHeight={true} 
        rows={areaTagLines} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onAreaTagLineRowEnter(e)}
        onRowLeave={e => onAreaTagLineRowLeave(e)}
      />
    </Container>
  );
}

AreaTagLineListTable.propTypes = {
  areaTagLines: PropTypes.array.isRequired,
  getAreaTagLines: PropTypes.func.isRequired
};

export default AreaTagLineListTable;