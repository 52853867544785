import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreAddButton from '../core/CoreAddButton';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import { Delete as deleteAutomaticPayable } from './AutomaticPayableService';
import AutomaticPayableColumnEditButton from './AutomaticPayableColumnEditButton';

const AutomaticPayableListTable = (props) => { 
  const { 
    automaticPayables,
    getAutomaticPayables,
    setAutomaticPayables,
    openAutomaticPayableCreateDialog
  } = props;

  let columns = [
    {
      field: 'AutoAddIfName',
      headerName: 'Auto Add If',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'ShipmentType',
      headerName: 'Shipment Type',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'EventType',
      headerName: 'Event Type',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'PayableChargeTyp',
      headerName: 'Expense Item',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => { 
        const name = params.getValue("PayableChargeType") ? params.getValue("PayableChargeType").Name : '';
        return ( 
          <span>{name}</span>
        )
      }
    },
    {
      field: 'Units',
      headerName: 'Units',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'UnitRate',
      headerName: 'Unit Rate',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'DateStart',
      headerName: 'Start Date',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'DateEnd',
      headerName: 'End Date',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openAutomaticPayableCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        const showControls = params.getValue("ShowControls") || false;
        const id = params.getValue("DefaultPayableChargeTypeID");
        return (
          <div>
            {showControls &&
              <AutomaticPayableColumnEditButton
                automaticPayableID={id}
                getAutomaticPayables={getAutomaticPayables}
              />
            }
            {showControls &&
              <CoreListColumnDeleteButton
                reference={` this Automatic Payable `}
                deleteID={id}
                deleteFunction={deleteAutomaticPayable}
                getList={getAutomaticPayables}
              />
            }
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
  };

  const onComponentRowEnter = (params) => {
    for (const item of automaticPayables) {
      if (item.DefaultPayableChargeTypeID === params.row.DefaultPayableChargeTypeID) {
        item.ShowControls = true;
      }
    }
    setAutomaticPayables([...automaticPayables]);
  };

  const onComponentRowLeave = (params) => {
    for (const item of automaticPayables) {
      if (item.DefaultPayableChargeTypeID === params.row.DefaultPayableChargeTypeID) {
        item.ShowControls = false;
      }
    }
    setAutomaticPayables([...automaticPayables]);
  };    

  return ( 
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.DefaultPayableChargeTypeID} 
        autoHeight={true} 
        rows={automaticPayables} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onComponentRowEnter(e)}
        onRowLeave={e => onComponentRowLeave(e)}
      />
    </Container>
  )  
};

export default AutomaticPayableListTable;