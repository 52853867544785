import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const CoreTextField = (props) => {
  const [touchedByUser, setTouchedByUser] = useState(false);
  const { 
    label, 
    value, 
    isRequired, 
    icon, 
    autoFocus, 
    validationError, 
    maxLength, 
    isCapsOnly,
    readOnly,
    noWrap,
    onLeaveEvent,
    ...rest 
  } = props;

  const buildEndAdornment = () => {
    if (icon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled={true}>
            {icon}
          </IconButton>
        </InputAdornment>);
    }
    return <></>;
  };

  const inputValue = (e) => {
    if (isCapsOnly) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  const blurFunction = () => {
    setTouchedByUser(true);
    if (onLeaveEvent) {
      onLeaveEvent();
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel 
        required={isRequired} 
        error={isRequired && touchedByUser && validationError}
        shrink={value ? true : false}>
        {noWrap ? <span style={{whiteSpace: 'nowrap'}}>{label}</span> : label}
      </InputLabel>
      <Input
        type="text"
        value={value}
        onChange={e => e.target.value}
        onInput={inputValue}
        onBlur={() => blurFunction() }
        endAdornment={buildEndAdornment()}
        autoFocus={autoFocus}
        inputProps = {{maxLength:maxLength}}
        required={isRequired}
        readOnly={readOnly}
        {...rest}
      />
    </FormControl>
  );
}

CoreTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreTextField;
