import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'ReceivableCharge/' + token + '/';

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  return result.data[0];
};

export async function create(revenueFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalOrderCreate', revenueFormData);
  return result;
};

export async function update(revenueFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalOrderUpdate', revenueFormData);
  return result;
};

export async function deleteReceivable(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalOrderDelete', null, params);
  return result;
};