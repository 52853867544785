import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CoreAlertDialog from '../core/CoreAlertDialog';

import { toggleFavorite } from './ContactService';



const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const ContactListColumnFavoriteIcon = (props) => {
  const classes = useStyles();

  const {
    isFavorite,
    contactID,
    currentFavorite,
    getContacts,
    contacts,
  } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSelected, setIsSelected] = useState(isFavorite);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [favErrorMessage, setFavErrorMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [selectedName, setSelectedName] = useState('');


  useEffect(() => {
    setIsSelected(isFavorite);
  }, [isFavorite]);

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  
  const onConfirm = () => {
    setAlertOpen(false);
    toggle();
  };

  const favoriteClick = () => {
    
    if(currentFavorite && currentFavorite !== contactID) {
      // There is already a favorite assigned. Notify user.
      setCurrentName(contacts.find((element) => element.ID === currentFavorite).Name || '');
      setSelectedName(contacts.find((element) => element.ID === contactID).Name || '');
      handleAlertOpen();
    } else {
      toggle();
    }
  }

  const toggle = () => {
    setIsProcessing(true);
    toggleFavorite(contactID, !isFavorite).then(function(response) {
      if (response.errors && response.errors.Error) {
        setFavErrorMessage(`Error encountered: ${response.errors.Error}`);
        setOpenSnackbar(true);
      } else {
        setIsSelected(!isFavorite);
        getContacts();
      }
    }).finally(function() {
      setIsProcessing(false);
    });
  }

  const handleSnackBarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {!isProcessing &&
        <IconButton className={classes.iconText} onClick={(e) => favoriteClick()}>
          {isSelected && <StarIcon />}
          {!isSelected && <StarBorderIcon />}
        </IconButton>
      }
      {isProcessing &&
        <CircularProgress size={20} style={{marginLeft:'15px'}} />
      }
      <Snackbar open={openSnackbar} autoHideDuration={8000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="error">
          { favErrorMessage }
        </Alert>
      </Snackbar>
      <CoreAlertDialog
        alertMessage={
          <p>
            Do you want to change the preferred contact from {currentName}  to {selectedName}?
          </p>
        }
        alertOpen={alertOpen}
        onConfirm={onConfirm}
        handleAlertOpen={handleAlertOpen}
        handleAlertClose={handleAlertClose}
      />
    </>
  );
}

ContactListColumnFavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool,
  contactID: PropTypes.number.isRequired,
  currentFavorite: PropTypes.number.isRequired,
  handleAlertOpen: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
};

export default ContactListColumnFavoriteIcon;
