import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textArea: { 
    minWidth: '548px', 
    maxWidth: '548px', 
    minHeight: '260px',
    fontFamily: 'inherit', 
    outline: '0px'
  }
}));

const NoteTextAreaField = (props) => {
  const classes = useStyles();
  const { name, value, isRequired, validationError, onChange, maxLength, onLeaveEvent } = props;

  const handleChange = (event) => {
    const dispatchEvent = {
        target: {
            name: name,
            value: event.target.value,
        }
    };
    onChange(dispatchEvent);
  };

  const blurFunction = () => {
    if (onLeaveEvent) {
      onLeaveEvent();
    }
  };

  return (
    <>
      <textarea className={classes.textArea}
        maxLength={maxLength}
        placeholder="Enter.." 
        name={name}
        value={value}
        onChange={handleChange}
        required={isRequired}
        error={(isRequired && validationError).toString()}
        onBlur={() => blurFunction() }
      />
    </>
  );
}

export default NoteTextAreaField;