import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconCheck from '../icon/IconCheck';
import IconPendingInvoice from '../icon/IconPendingInvoice';
import IconReady from '../icon/IconReady';
import IconShipTypeSolid from '../icon/IconShipTypeSolid';
import IconOnHold from '../icon/IconOnHold';
import { getOrderSummary, getInvoiceSummary, getSettlementSummary } from './DashboardService';
import Constants from './DashboardConstants.json';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'left',
  },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  gridIcon: {
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  stats: {
    padding: theme.spacing(2)
  },
  divider: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  h6: {
    margin: '0 0 5px 0'
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  status: {
    margin: '0 0 5px 0',
    fontWeight: '400',
    fontSize: '12px'
  },
  count: {
    fontWeight: '600',
    alignSelf: 'center',
  },
  countLink: {
    fontWeight: '600',
    color: '#3f51b5',
    alignSelf: 'center',
  },
  iconAvatar:{
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    filter: 'invert(1)',
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  bold: {
    fontWeight: 'bold'
  },
}));

const DashboardSummaryItem = (props) => {
  let history = useHistory();
  const { summaryType } = props;
  const [loading, setLoading] = useState(true)
  const [statsSummary, setStatsSummary] = useState([]);

  useEffect(() => {
    async function getOrders() { 
      const result = await getOrderSummary();
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getInvoices() { 
      const result = await getInvoiceSummary();
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    async function getSettlements() { 
      const result = await getSettlementSummary();
      if(result.length > 0) {
        setStatsSummary(result[0]);
        setLoading(false);
      }
    }
    if(summaryType === 'ORDER') {
      getOrders();
    } else if(summaryType === 'INVOICE') {
      getInvoices();
    } else {
      getSettlements();
    }
  }, [summaryType]);

  const classes = useStyles();

  const tally = (total) => {
    return  (
      (!loading) ? total : ''
    );
  };

  const icon = (iconName) => {
    switch (iconName) {
      case "IconPendingInvoice": return <IconPendingInvoice />
      case "IconCheck": return <IconCheck />
      case "IconReady": return <IconReady />
      case "IconShipTypeSolid": return <IconShipTypeSolid />
      case "IconOnHold": return <IconOnHold />
      default: break;
    }
  };

  const navigate = (url, count) => {
    if (url && count) {
      history.push(url);
    } else {
      return;
    }
  }

  const getCountClass = (url, count) => {
    if (url && count) {
      return classes.countLink
    } else {
      return classes.count
    }
  }

  const getCursor = (url, count) => {
    if (url && count) {
      return classes.cursorPointer
    } else {
      return ''
    }
  }

  const outputGridItem = (i) => {
    return (
      <Grid item sm 
      onClick={ () => navigate(Constants.SUMMARY_TYPE[summaryType].STATUS[i].url,
        tally(statsSummary[Constants.SUMMARY_TYPE[summaryType].STATUS[i].key])
      )}
      className={ getCursor(Constants.SUMMARY_TYPE[summaryType].STATUS[i].url, 
        tally(statsSummary[Constants.SUMMARY_TYPE[summaryType].STATUS[i].key])
      )}
    >
      <h6 className={classes.status}>{Constants.SUMMARY_TYPE[summaryType].STATUS[i].label}</h6>
      <Grid container className={classes.gridIcon}>
      <Avatar className={classes.iconAvatar} style={{backgroundColor: Constants.SUMMARY_TYPE[summaryType].STATUS[i].iconColor}}>
        <div className={classes.icon}>
          {icon(Constants.SUMMARY_TYPE[summaryType].STATUS[i].icon)}
        </div>
      </Avatar>
        &nbsp;&nbsp;
        <div className={
          getCountClass(Constants.SUMMARY_TYPE[summaryType].STATUS[i].url,
          tally(statsSummary[Constants.SUMMARY_TYPE[summaryType].STATUS[i].key])
        )}>
          {loading && <CircularProgress size={16} />}
          {tally(statsSummary[Constants.SUMMARY_TYPE[summaryType].STATUS[i].key])}
        </div>
      </Grid>
    </Grid>
    )
  }

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      <Grid className={classes.stats}>
        <Typography variant="subtitle1" className={classes.bold}>
          {Constants.SUMMARY_TYPE[summaryType].TITLE}
        </Typography>
        <Typography variant="h3">
          {loading && <CircularProgress />}
          {tally(statsSummary[Constants.SUMMARY_TYPE[summaryType].STATUS[0].key])}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontSize: '12px'}}>
          {Constants.SUMMARY_TYPE[summaryType].STATUS[0].label}
        </Typography>
      </Grid>
      <Grid className={classes.divider}>    
        <Divider className={classes.lightDivider} />
      </Grid>
      <Grid container className={classes.grid}>
        {outputGridItem(1)}
        {outputGridItem(2)}
        {outputGridItem(3)}
      </Grid>
    </Paper>
  );

}

DashboardSummaryItem.propTypes = {
  summaryType: PropTypes.string
};

DashboardSummaryItem.defaultProps = {
  summaryType: ""
};

export default DashboardSummaryItem;
