import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import OrderEditContentLocation from './OrderEditContentLocation';

const useStyles = makeStyles(() => ({
  line: { 
    opacity: '0.3'
  }
}));

const OrderEditContentLocations = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    updateOrderFormData,
    orderFormValidation,
    initialProviderID
  } = props;

  return (
    <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
      <Grid container md={12} xs={12}>
        <Grid container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <OrderEditContentLocation
              headerLabel="Origin Location"
              name="OriginID"
              locationName={orderFormData.OriginLocationName}
              value={orderFormData.OriginID}
              validationError={orderFormValidation.OriginID}
              updateOrderFormData={updateOrderFormData}
              isAbleToEditLocation={orderFormData.IsAbleToEditOrderOrigin}
              orderType={orderFormData.Type}
              isOriginLocation={true}
              initialProviderID={initialProviderID}
            />
          </Grid>
        </Grid>
        <hr className={classes.line}></hr>
        <Grid container md={6} xs={6} spacing={2} className="mb-2">
          <Grid item md={12} xs={12}>
            <OrderEditContentLocation
              headerLabel="Destination Location"
              name="DestinationID"
              locationName={orderFormData.DestinationLocationName}
              value={orderFormData.DestinationID}
              validationError={orderFormValidation.DestinationID}
              updateOrderFormData={updateOrderFormData}
              isAbleToEditLocation={orderFormData.IsAbleToEditOrderOrigin}
              orderType={orderFormData.Type}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

}

export default OrderEditContentLocations;