import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';
import IconLocationLight from '../icon/IconLocationLight';
import IconCustomerStroke from '../icon/IconCustomerStroke';
import IconEquipmentLight from '../icon/IconEquipmentLight';
import IconPersonnelLight from '../icon/IconPersonnelLight';

import LocationCreateDialog from '../location/LocationCreateDialog';
import PersonnelCreateDialog from '../personnel/PersonnelCreateDialog';
import EquipmentCreateDialog from '../equipment/EquipmentCreateDialog';
import CustomerCreateDialog from '../customer/CustomerCreateDialog';

const useStyles = makeStyles(() => ({
  iconButton: {
    opacity: '50%',
  },
  icon: {
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
  iconAddButton:{
    color:'white', 
    backgroundColor:'#6639BF',
    '&:hover': {
      backgroundColor: '#6639BF',
    },
    marginRight:'5px',
    variant:'outlined'
  },
  iconText: {
    fontSize: '14px'
  },
  quickCreateMenu: {
    '& .MuiPopover-paper': {
      borderRadius: '2px',
      borderBottom: '1px solid #cccccc',
      boxShadow: '0px -3px 3px -3px rgb(0 0 0 / 20%), 2px 1px 2px -3px rgb(0 0 0 / 14%), 0px 2px 4px 1px rgb(0 0 0 / 12%)'
    }

  }
}));

const NavigationToolbarButtonNotification = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const [isCustomerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [isEquipmentDialogOpen, setEquipmentDialogOpen] = useState(false);
  const [isPersonnelDialogOpen, setPersonnelDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openLocationDialog = () => {
    setLocationDialogOpen(true);
    handleClose();
  };

  const closeLocationDialog = () => {
    setLocationDialogOpen(false);
  };

  const openPersonnelDialog = () => {
    setPersonnelDialogOpen(true);
    handleClose();
  };

  const closePersonnelDialog = () => {
    setPersonnelDialogOpen(false);
  };

  const openEquipmentDialogOpen = () => {
    setEquipmentDialogOpen(true);
    handleClose();
  };

  const closeEquipmentDialog = () => {
    setEquipmentDialogOpen(false);
    handleClose();
  };

  const openCustomerDialog = () => {
    setCustomerDialogOpen(true);
    handleClose();
  };

  const closeCustomerDialog = () => {
    setCustomerDialogOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button name="navigation[quick_add]" className={classes.iconAddButton} onClick={handleClick}>
        <AddIcon/>
      </Button>
      <Menu
        id="quick-create-menu"
        className={classes.quickCreateMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={openCustomerDialog}>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.icon}>
              <IconCustomerStroke/>
            </Grid>
            <Grid item xs={8} className={classes.iconText}>
              Customer&nbsp;&nbsp;
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openLocationDialog}>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.icon}>
              <IconLocationLight />
            </Grid>
            <Grid item xs={8} className={classes.iconText}>
              Location&nbsp;&nbsp;
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openEquipmentDialogOpen}>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.icon}>
              <IconEquipmentLight />
            </Grid>
            <Grid item xs={8} className={classes.iconText}>
              Equipment&nbsp;&nbsp;
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openPersonnelDialog}>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.icon}>
              <IconPersonnelLight />
            </Grid>
            <Grid item xs={8} className={classes.iconText}>
              Personnel&nbsp;&nbsp;
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
      <LocationCreateDialog
        isOpen={isLocationDialogOpen} 
        onClose={closeLocationDialog} />
      <PersonnelCreateDialog
        isOpen={isPersonnelDialogOpen}
        onClose={closePersonnelDialog} />
      <EquipmentCreateDialog
        isOpen={isEquipmentDialogOpen}
        onClose={closeEquipmentDialog} />
      <CustomerCreateDialog
        isOpen={isCustomerDialogOpen}
        onClose={closeCustomerDialog} />
    </div>
  );

}

export default NavigationToolbarButtonNotification;