import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  notation: {
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
}));

const QuickbooksMappingHeader = (props) => {
  const {title, description, notation, addButton} = props;
  const classes = useStyles();

  return (
    <div style={{padding:"20px 15px", textAlign:"left"}}>
      <Typography variant="subtitle1" style={{paddingBottom:"20px"}}>
        <span>{title}</span>
        <span style={{float: 'right'}}>{addButton}</span>
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      <Typography variant="caption" className={classes.greyText}>
        {notation}
      </Typography>
    </div>
  );

}

export default QuickbooksMappingHeader;
