import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconOwner from '../icon/IconOwner';
import IconLicensePlate from '../icon/IconLicensePlate';
import IconTractorType from '../icon/IconTractorType';
import IconUnitType from '../icon/IconUnitType';
import IconUnitNumber from '../icon/IconUnitNumber';
import IconVIN from '../icon/IconVIN';
import IconMake from '../icon/IconMake';
import IconModel from '../icon/IconModel';
import CoreTextDisplayField from '../core/CoreTextDisplayField';

const useStyles = makeStyles(() => ({
  infoCard: {
    width: '380px',
    border: '0',
    boxShadow: '0',
    borderRadius: '8px',
    padding: '5px 10px 0px 10px !important',
    backgroundColor: '#f5f5f5'
  },
  summarySectionTitle: {
    fontWeight: 'normal',
    fontSize: '12px',
    marginTop: '28px',
    marginBottom: '12px'
  },
  divider: {
    borderBottom: '1px solid #efefef',
    paddingBottom: '20px'
  },
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  },
}));

const EquipmentViewSummaryInfoSection = (props) => {
  const classes = useStyles();

  const {
    equipment
  } = props;

  return (
    <div>
      <Grid container spacing={1} className={classes.divider}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>EQUIPMENT INFORMATION</h5>
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Unit Number"
            value={equipment.UnitNum}
            icon={<IconUnitNumber />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Unit Type"
            value={equipment.UnitType}
            icon={<IconUnitType />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Tractor Type"
            value={equipment.TractorType}
            icon={<IconTractorType />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Owner"
            value={equipment.OwnerCarrierName}
            icon={<IconOwner />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="License Plate Number"
            value={equipment.LicenseNum}
            icon={<IconLicensePlate />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="VIN #"
            value={equipment.VINNum}
            icon={<IconVIN />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Make"
            value={equipment.Manufacturer}
            icon={<IconMake />}
          />
        </Grid>
        <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
          <CoreTextDisplayField
            label="Model"
            value={`${equipment.ModelYear} ${equipment.Model}`}
            icon={<IconModel />}
          />
        </Grid>
      </Grid>
      {equipment.LastNote &&
      // TODO: There is currently no LastNote in the response.
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <h5 className={classes.summarySectionTitle}>LAST NOTE</h5>
        </Grid>
        <Grid container item xs={12}>
          <Card elevation={0} className={classes.infoCard}>
            <CardContent >              
              <Typography variant="body2" component="p">
                {equipment.LastNote}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      }
    </div>
  );

}

export default EquipmentViewSummaryInfoSection;
