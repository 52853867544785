import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import QuickbooksConnectButton from  './QuickbooksConnectButton';

const QuickbooksConnect = (props) => {

  return (
    <Paper className="mt-2 munaGrid" elevation={1} square={true} align="left" style={{padding:'20px 15px'}}>
      <Typography variant="subtitle1" style={{paddingBottom:"20px"}}>
        Connect to QuickBooks Online
      </Typography>
      <Typography variant="body1" style={{fontSize: '14px',lineHeight: '20px'}}>
        To start your QuickBooks Online Sync you must first login to QuickBooks online by clicking the button below.
      </Typography>
      <Typography variant="body1" style={{fontSize: '14px',lineHeight: '20px',padding:'10px 0 20px 0'}}>
        Once logged in, verify configuration settings and then click on the sync tab to get started.
      </Typography>
      <QuickbooksConnectButton />
    </Paper>
  );
}

export default QuickbooksConnect;
