import React, { useState, useEffect } from 'react';
import Validation from '../validation';

import authenticationLogin from './Authentication.service';
import AuthenticationLoginContent from './AuthenticationLoginContent';

const AuthenticationLogin = () => {

  const [loginFormData, setLoginFormData] = useState({
    username: '',
    password: ''
  });
  const [loginFormValidation, setLoginFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setLoginFormValidation(buildValidation(loginFormData))
  }, [loginFormData]);

  const updateLoginFormData = e => {
    const { name, value } = e.target;
    loginFormData[name] = value;
    setLoginFormData({ ...loginFormData });
  };

  const onLogin = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(loginFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processLoginRequest();
  };
  
  const processLoginRequest = async () => {
    setErrorMessage('');
    const result = await authenticationLogin(loginFormData.username, loginFormData.password);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      if (result.errors.Error.includes('owner')) {
        let companyID = result.errors.Error.replace(/(^\d+)(.+$)/i, '$1');
        window.location = '/payment-method?companyID=' + companyID + '&username=' + loginFormData.username;
      }
    } else {
      localStorage.setItem('token', result);
      window.location = '/spark/dashboard';
    }
  };

  return (
    <AuthenticationLoginContent
      onLogin={onLogin}
      errorMessage={errorMessage}
      loginFormData={loginFormData}
      loginFormValidation={loginFormValidation}
      updateLoginFormData={updateLoginFormData}
    />
  );

}

const buildValidation = (loginFormData) => {
  const { username, password } = loginFormData || {};
  const validation = new Validation();
  return {
    username: validation.isEmpty(username),
    password: validation.isEmpty(password)
  };
};

export default AuthenticationLogin;