import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getMainBranchID } from './ConfigurationService';
import { uploadLogo } from '../branch/BranchService';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import { getLogo } from '../branch/BranchService';
import Box from '@material-ui/core/Box'



const ConfigurationViewBranchSectionLogo = (props) => {
  const {
      branchID
  } = props;

  const [logoData, setLogoData] = useState();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    getCompanyLogo();
  };

  const replaceCompanyLogo = async (file) => {
    if (!file) {
      return;
    }
    await uploadLogo(branchID, file);
    getCompanyLogo();
  };

  const getCompanyLogo = async () => {
    const mainBranchID = await getMainBranchID();
    const logo = await getLogo(mainBranchID);
    setLogoData(logo);
  };

  

  return (
    <div>   
        <Box mr={2} height={100}>
            <Grid item align="center">
                <Card variant='outlined'>
                    <CardContent>
                      <img alt="companyLogo" height="40" width="100%"  src={`data:image/jpeg;base64,${logoData}`} />
                    </CardContent>
                </Card>
                <Button
                    color="primary"
                    component="label"
                    onChange={(e) => replaceCompanyLogo(e.target.files[0])}>
                    Replace Logo
                    <input
                    type="file"
                    hidden />
                </Button>
            </Grid>      
        </Box>
    </div>
  );

};


export default ConfigurationViewBranchSectionLogo;