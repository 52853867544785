import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from "react-chartjs-2";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDispatchSummary } from './DashboardService';
import Constants from './DashboardConstants.json';

const useStyles = makeStyles({
  barChartContainer: {
    minWidth: '99%',
    width: '99%',
    marginTop: '12px',
    height: '250px',
  },
  flag: {
    margin: '-4px auto 0 auto',
    width: '22px',
    height: '22px',
    lineHeight: '22px',
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '11px',
    cursor: 'pointer'
  },
  ordersPopover: {
    padding: '15px',
  },
  popoverFlag: {
    position: 'absolute',
    top: '6px',
    left: '0'
  },
  popoverTitle: {
    position: 'relative',
    paddingLeft: '30px'
  },
  headCellBorder: {
    border: '1px solid #EEEBE2',
    borderTop: 'none'
  },
  cellBorder: {
    border: '1px solid #EEEBE2',
    padding: '20px 16px 16px 16px'
  }

});

const DashboardDispatchTable = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const dt = new Date();
  const currentWeekDay = dt.getDay();
  const weekStart = new Date(new Date(dt).setDate(dt.getDate() - currentWeekDay));

  const [chartData, setChartData] = useState({
    labels: [
      [Constants.DISPATCH.DAY_MAP[0].label, 
        new Date(weekStart).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[1].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[2].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[3].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[4].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[5].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
      [Constants.DISPATCH.DAY_MAP[6].label, 
        new Date(weekStart.setDate(weekStart.getDate() + 1)).toLocaleString("en-US", { month: "short" }) + " " + 
        new Date(weekStart).toLocaleString("en-US", { day: "2-digit" })],
    ],
    datasets: [
      {
        data: null,
        backgroundColor: [Constants.DISPATCH.STATUS["AwaitingPickup"].color],
        label: "Awaiting Pickup",
      },
      {
        data: null,
        backgroundColor: [Constants.DISPATCH.STATUS["NeedsUpdate"].color],
        label: "Needs Update",
      },
      {
        data: null,
        backgroundColor: [Constants.DISPATCH.STATUS["RunningLate"].color],
        label: "Running Late"
      }
    ]
  });

  useEffect(() => {
    async function getDispatch() { 
      const result = await getDispatchSummary();
      if(result.length > 0) {
        chartData.datasets[0].data = result.map((row) => row.AwaitingPickup[0].NumOrders);
        chartData.datasets[1].data = result.map((row) => row.NeedsUpdate[0].NumOrders);
        chartData.datasets[2].data = result.map((row) => row.RunningLate[0].NumOrders);
        setChartData(chartData);
      }
      setLoading(false);
    }
    getDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClickBar(event, array) {
    var startOfWeek = new Date(new Date(dt).setDate(dt.getDate() - currentWeekDay));
    var dayClicked = new Date(startOfWeek.setDate(startOfWeek.getDate() + array[0].index));
    var formattedDate = ((dayClicked.getMonth() > 8) ? (dayClicked.getMonth() + 1) : ('0' + (dayClicked.getMonth() + 1))) + '/' + ((dayClicked.getDate() > 9) ? dayClicked.getDate() : ('0' + dayClicked.getDate())) + '/' + dayClicked.getFullYear();
    window.location = '/spark/dispatch?statusType=' + array[0].element.$context.dataset.label.replace(/\s/g, "") + '&statusTypeDate=' + formattedDate;
  };

  return (
    <>
      <div className={classes.barChartContainer}> 
        {loading && (
          <div align="center">
            <CircularProgress size={60} style={{marginTop: '50px'}} />
          </div>
        )}      
        {!loading && <Bar 
          data={chartData}
          options={{
            scales: {
              y: {
                ticks: {
                  stepSize: 1
                },
              }
            },
            onClick: onClickBar,
            maintainAspectRatio: false,
            plugins:{
              tooltip: {
                callbacks: {        
                  title: function() {},           
                  label: (context) => {
                    return ` "${context.dataset.label}"`;
                  },
                  afterLabel: (context) => {
                    return ` ${context.raw} dispatches`
                  },      
                },
                borderWidth: '1',
                borderColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white', 
              },
              legend: {
               display: false
              }
            }            
          }}
        />}
      </div>
    </>
  );

}

export default DashboardDispatchTable;
