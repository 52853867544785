import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LogoSvg } from '../assets/svg-icons/logo@1x.svg'

const IconLogo = () => {
  return (
    <SvgIcon fontSize="small">
      <LogoSvg />
    </SvgIcon>
  )
}

export default IconLogo;