import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';

import CoreButtonContainer from '../core/CoreButtonContainer';
import CoreExitButton from '../core/CoreExitButton';
import CoreEditButton from '../core/CoreEditButton';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  }
}));

const LocationViewPageHeader = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const {
    location
  } = props;

  const onClose = () => {
    history.push('/spark/location');
  };

  return (
    <>
    <Paper 
      variant="outlined" 
      className={classes.purpleBackground}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              { "Location " + location.InternalName + " Details" }
            </Typography>
          </Grid>
          <Grid item md={6} spacing={2} container justify="flex-end" alignItems="center" style={{paddingRight: '90px'}}>
            <CoreButtonContainer>
              <CoreEditButton
                editView={'/spark/location/edit/' + location.LocationTemplateID}
              />
            </CoreButtonContainer>
          </Grid>
        </Grid>
        <CoreExitButton
          onClick={onClose}
        />
      </Container>
    </Paper>
    </>
  );
}

LocationViewPageHeader.propTypes = {
  location: PropTypes.object.isRequired
};

export default LocationViewPageHeader;