import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { update } from './DocumentTypeService';
import DocumentTypeEditDialogContent from './DocumentTypeEditDialogContent';

const DocumentTypeEditDialog = (props) => {
  const {
    isOpen,
    onClose,
    documentTypeFormData,
    setDocumentTypeFormData
  } = props;

  const [documentTypeFormValidation, setDocumentTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDocumentTypeFormValidation(buildValidation(documentTypeFormData));
    }
  }, [documentTypeFormData, isOpen]);

  const updateDocumentTypeFormData = e => {
    const { name, value } = e.target;
    documentTypeFormData[name] = value;
    setDocumentTypeFormData({ ...documentTypeFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(documentTypeFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(documentTypeFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    props.onClose();
  };

  return (
    isOpen &&
    <DocumentTypeEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      documentTypeFormData={documentTypeFormData}
      documentTypeFormValidation={documentTypeFormValidation}
      updateDocumentTypeFormData={updateDocumentTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (documentTypeFormData) => {
  const {
    Description,
    RelatedTo,
  } = documentTypeFormData || {};
  const validation = new Validation();
  return {
    Description: validation.isEmpty(Description),
    RelatedTo: validation.isEmpty(RelatedTo)
  };
};

export default DocumentTypeEditDialog;