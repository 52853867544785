import React, { useState, useEffect } from "react";
import { disableEnvase } from "./AdminService";
import PersonnelDisableLoginDialogContent from "./PersonnelDisableLoginDialogContent";

const PersonnelDisableLoginDialog = (props) => {
  const { 
    open, 
    personnel, 
    handleClose 
  } = props;

  const [error, setError] = useState();
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const [personnelEmailAddress, setPersonnelEmailAddress] = useState('');
  const [personnelInternalName, setPersonnelInternalName] = useState('');

  useEffect(() => {
    setPersonnelEmailAddress(personnel.Email);
    setPersonnelInternalName(personnel.InternalName);
  }, [personnel]);

  const onClose = () => {
    setError(null);
    setSuccess(null);
    handleClose();
  };

  const onDisableUser = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    setLoading(true);
    await processRegisterEnvase();
    setLoading(false);
  };

  const processRegisterEnvase = async () => {
    const response = await disableEnvase(personnel.PersonnelID);
    if (response.response === 'Success') {
      setSuccess(
        `User ${personnel.InternalName} has been disabled.`
      );
    } else {
      if (response.error) {
        setError(response.error);
      } else {
        setError("An error has occured");
      }
      return;
    }
  };

  return (
      <PersonnelDisableLoginDialogContent
        emailAddress={personnelEmailAddress}
        internalName={personnelInternalName}
        loading={loading}
        error={error}
        success={success}
        onDisableUser={onDisableUser}
        onClose={onClose}
        isOpen={open}
        />
  );
};

export default PersonnelDisableLoginDialog;