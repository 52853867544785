import React from 'react';
import Button from '@material-ui/core/Button';

const NavigationToolbarButtonFeedback = () => {
  const handleClick = () => {
    window.open('https://forms.monday.com/forms/d53fd084677dd58d5d54886c6b1b6d20?r=use1');
  };

  return (
    <div>
      <Button name="navigation[send_feedback]" variant="outlined" color="primary" onClick={handleClick}>
        Send Feedback
      </Button>
    </div>
  );

}


export default NavigationToolbarButtonFeedback;