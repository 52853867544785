import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import IntermodalEventCreateDialogContent from './IntermodalEventCreateDialogContent';
import { create } from './IntermodalEventService';
import { setProvider } from '../location/LocationService';
import { getCredentialByID } from '../envase_trace/EnvaseTraceService';

const IntermodalEventCreateDialog = (props) => {
  const {
    intermodalLegID,
    insertingAfterEventID,
    isOpen,
    onClose,
    isAddEventAbove,
    currentEventType,
    eventIndexForNewEvent,
    initialEventType
  } = props;

  const [eventFormData, setEventFormData] = useState({
    legID: intermodalLegID,
    eventType: null,
    locationTemplateID: null,
    appointmentDateStart: null,
    appointmentDateEnd: null,
    actualArrivalDate: null,
    actualDepartDate: null,
    appointmentNumber: "",
    note: "",
    isAppointmentExempt: false,
    envaseTraceProviderID: null
  });

  const [eventFormValidation, setEventFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [eventChosen, setEventChosen] = useState('ShowEvent');
  const [initialProviderID, setInitialProviderID] = useState(null);
  const [traceCredentials, setTraceCredentials] = useState({});

  useEffect(() => {
    setEventFormData(e => ({ ...e, legID: intermodalLegID }));
  }, [intermodalLegID]);

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setEventFormValidation(buildValidation(eventFormData));
    } 
  }, [eventFormData, eventChosen, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setEventFormData(c => ({ ...c, eventType: "ShowEvent" }));
      setEventChosen("ShowEvent");
    }
  }, [eventChosen, isOpen]);

  useEffect(() => { 
    if (eventFormData.envaseTraceProviderID) { 
      getCredentialByID(eventFormData.envaseTraceProviderID)
        .then(function(response) { 
          setTraceCredentials(response);
        });
    }
  }, [eventFormData.locationTemplateID, eventFormData.envaseTraceProviderID]);

  const updateEventFormData = e => {
    const { name, value, nameData, valueData, envaseTraceProviderID } = e.target;
    eventFormData[name] = value;
    eventFormData[nameData] = valueData;
    if (name === "locationTemplateID") { 
      eventFormData.envaseTraceProviderID = envaseTraceProviderID;
      setInitialProviderID(envaseTraceProviderID);
    }
    setEventFormData({ ...eventFormData });
  };

  const updateEventChosenData = (e) => {
    const { name } = e.target;
    setEventChosen(name);
    switch (name) {
      case 'ShowYardMove':
        eventFormData.eventType = "Yard Move";
        setEventFormData(eventFormData);
        break;
      case 'ShowXDock':
        eventFormData.eventType = "X-Dock";
        setEventFormData(eventFormData);
        break;
      default:
        break;
    }
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(eventFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    let errorMsg = [];
    setErrorMessage('');
    const result = await create(eventFormData, insertingAfterEventID, isAddEventAbove);
    setInProgress(false);
    if (result.error) {
      result.error.forEach((item, index) => {
        errorMsg.push(
          <div>
            {item}<br />
          </div>
        );
      });
      setErrorMessage(errorMsg);
      return;
    }
    setLocationProvider();
    props.onClose();
  };

  const setLocationProvider = async () => { 
    if (eventFormData.envaseTraceProviderID !== initialProviderID) { 
      await setProvider(eventFormData.locationTemplateID, eventFormData.envaseTraceProviderID);
      setInitialProviderID(null);
    }
  };

  return (
    isOpen &&
    <IntermodalEventCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      eventFormData={eventFormData}
      eventFormValidation={eventFormValidation}
      updateEventFormData={updateEventFormData}
      eventChosen={eventChosen}
      updateEventChosenData={updateEventChosenData}
      inProgress={inProgress}
      currentEventType={currentEventType}
      traceCredentials={traceCredentials}
      initialProviderID={initialProviderID}
      eventIndexForNewEvent={eventIndexForNewEvent}
      initialEventType={initialEventType}
      isAddEventAbove={isAddEventAbove}
    />
  );

}

const buildValidation = (eventFormData) => {
  const {
    eventType,
    locationTemplateID,
  } = eventFormData || {};
  const validation = new Validation();
  return {
    eventType: validation.isEmpty(eventType),
    locationTemplateID: validation.isNumber(locationTemplateID),
  }
};

export default IntermodalEventCreateDialog;