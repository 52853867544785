import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import CoreTextField from "../core/CoreTextField";
import CorePasswordField from "../core/CorePasswordField";
import Alert from "@material-ui/lab/Alert";

const PersonnelResetDriverPasswordDialogContent = (props) => {
  const { formData, loading, error, success, handleChange, onResetPassword } =
    props;
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box>
      <form autoComplete="off" onSubmit={onResetPassword}>
        <Grid container item direction="column" xs spacing={0}>
          <Grid container item direction="column" spacing={3}>
            <Grid item xs={12}>
              <CoreTextField
                name="email"
                label="E-mail"
                value={formData.email}
                onChange={e => handleChange(e)}
                isRequired={true}
                icon={<SupervisedUserCircleIcon />}
                disabled
            />
            </Grid>
            <Grid item xs={12}>
              <CorePasswordField
              name="password"
              label="Password"
              value={formData.password}
              onChange={e => handleChange(e)}
              showIcon={true}
              type={showPassword ? "text" : "password"}
            />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={handleShowPassword}
                    color="primary"
                  />
                }
                label="Show Password"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt-1">
            {(error || success) && (
              <Alert variant="filled" severity={error ? "error" : "success"}>
                {error || success}
              </Alert>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PersonnelResetDriverPasswordDialogContent;