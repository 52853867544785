import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { Create as PersonnelCreate } from './PersonnelService';
import { getMainBranchID } from '../configuration/ConfigurationService';
import PersonnelCreateDialogContent from './PersonnelCreateDialogContent';

const PersonnelCreateDialog = (props) => {

  const {
    isOpen,
    onClose
  } = props;

  const initialData = {
    firstName: '',
    middleName: '',
    lastName: '',
    internalName: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    homePhone: '',
    persCell: '',
    busPhone: '',
    email: '',
    listAsDriver: 0,
    branchID: 0,
    canFunds: 0
  }

  const [PersonnelFormData, setPersonnelFormData] = useState({initialData});
  const [PersonnelFormValidation, setPersonnelFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setPersonnelFormValidation(buildValidation(PersonnelFormData));
    }
  }, [PersonnelFormData, isOpen]);

  useEffect(() => {
    setDefaultBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultBranch = async () => {
    const result = await getMainBranchID();
    initialData.branchID = result;
    setPersonnelFormData({ ...initialData });
  };

  const updatePersonnelFormData = e => {
    const {name, value} = e.target;
    PersonnelFormData[name] = value;
    setPersonnelFormData({ ...PersonnelFormData });
  };

  const onCreate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(PersonnelFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    // Strip Phone Numbers
    PersonnelFormData.busPhone = PersonnelFormData.busPhone ? PersonnelFormData.busPhone.replace(/\D/g,'') : '';
    PersonnelFormData.persCell = PersonnelFormData.persCell ? PersonnelFormData.persCell.replace(/\D/g,'') : '';
    PersonnelFormData.homePhone = PersonnelFormData.homePhone ? PersonnelFormData.homePhone.replace(/\D/g,'') : '';
    const result = await PersonnelCreate(PersonnelFormData);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    resetForm();
  };

  const resetForm = () => {
    setErrorMessage('');
    setDefaultBranch();
    onClose();
  }

  return (
    isOpen &&
    <PersonnelCreateDialogContent
      resetForm={resetForm}
      isOpen={isOpen}
      onCreate={onCreate}
      errorMessage={errorMessage}
      PersonnelFormData={PersonnelFormData}
      PersonnelFormValidation={PersonnelFormValidation}
      updatePersonnelFormData={updatePersonnelFormData}/>
  );
}

const buildValidation = (PersonnelFormData) => {
  const { 
    firstName,
    internalName
  } = PersonnelFormData || {};
  const validation = new Validation();
  return {
    firstName: validation.isEmpty(firstName),
    internalName: validation.isEmpty(internalName)
  };
};

export default PersonnelCreateDialog;