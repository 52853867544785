import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LocationLightSvg } from '../assets/svg-icons/location-light@1x.svg'

const IconLocationLight = () => {
  return (
    <SvgIcon fontSize="small">
      <LocationLightSvg />
    </SvgIcon>
  )
}

export default IconLocationLight;