import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import IconOrganization from '../icon/IconOrganization';
import IconDateCal from '../icon/IconDateCal';
import IconCurrency from '../icon/IconCurrency';
import IconHealth from '../icon/IconHealth';
import IconEmergency from '../icon/IconEmergency';
import IconLegalName from '../icon/IconLegalName';
import IconEmail from '../icon/IconEmail';

const PersonnelViewEmployeeInfoSection = (props) => {
  const {
    personnel
  } = props;
    
  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>Employee Information</h4>
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Title"
            value={personnel.Title}
            icon={
              <IconOrganization />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
              label="Email Address"
              value={personnel.Email}
              icon={
                <IconEmail />
              }
            />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Start Date"
            value={personnel.StartDate}
            icon={
              <IconDateCal />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="End Date"
            value={personnel.EndDate}
            icon={
              <IconDateCal />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Preferred Currency"
            value={personnel.CanFunds ? "CAD" : "USD"}
            icon={
              <IconCurrency />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Health Insurance #"
            value={personnel.HealthNum}
            icon={
              <IconHealth />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CoreTextDisplayField
            label="Emergency Contact Name"
            value={personnel.EmergencyContact}
            icon={
              <IconLegalName />
            }
          />    
        </Grid>
        <Grid item xs={3} className="mb-2">
          <CoreTextDisplayField
            label="Emergency Contact #"
            value={personnel.EmergencyNumber}
            icon={
              <IconEmergency />
            }
            isPhoneNum={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PersonnelViewEmployeeInfoSection;