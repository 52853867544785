import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import DocumentFieldListColumnEditButton from '../document_field/DocumentFieldListColumnEditButton';
import { deleteDocumentField } from '../document_field/DocumentFieldService';
import CoreAddButton from '../core/CoreAddButton';

const ConfigurationViewDocumentFieldSectionListTable = (props) => {
  const {
    documentFields,
    getDocumentFields,
    setDocumentFields,
    openDocumentFieldCreateDialog
  } = props;

  let columns = [
    {
      field: 'FieldName',
      headerName: 'Field Name',
      align: 'left',
      flex: 2,
      sortable: false,    
    },
    {
      field: 'FieldText',
      headerName: 'Field Placeholder',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Controls',
      headerName: <CoreAddButton handleOpenCreate={openDocumentFieldCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const documentFieldID = params.getValue("ReportFieldID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <DocumentFieldListColumnEditButton
                documentFieldID={documentFieldID}
                getDocumentFields={getDocumentFields}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Field Name: ${params.getValue("FieldName")}`}
                deleteID={documentFieldID}
                deleteFunction={deleteDocumentField}
                getList={getDocumentFields}
              />}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configDocumentFieldAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configDocumentFieldAmount", amount);
  };

  const onFieldRowEnter = (params) => {
    for (const document of documentFields) {
      if (document.ReportFieldID === params.row.ReportFieldID) {
        document.ShowControls = true;
      }
    }
    setDocumentFields([ ...documentFields ]);
  };

  const onFieldRowLeave = (params) => {
    for (const document of documentFields) {
      if (document.ReportFieldID === params.row.ReportFieldID) {
        document.ShowControls = false;
      }
    }
    setDocumentFields([ ...documentFields ]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ReportFieldID} 
        autoHeight={true} 
        rows={documentFields} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onFieldRowEnter(e)}
        onRowLeave={e => onFieldRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewDocumentFieldSectionListTable.propTypes = {
  documentFields: PropTypes.array.isRequired,
  getDocumentFields: PropTypes.func.isRequired
};

export default ConfigurationViewDocumentFieldSectionListTable;