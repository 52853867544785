import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoreGhostLoader from '../core/CoreGhostLoader';
import CorePageHeader from '../core/CorePageHeader';
import IconPersonnelLight from '../icon/IconPersonnelLight';
import IconOrderLight from '../icon/IconOrderLight';
import IconEquipmentLight from '../icon/IconEquipmentLight';
import IconReady from '../icon/IconReady';
import { getNotificationsList, completeTask, uncompleteTask, uncompleteAllTasks, completeAllTasks } from '../notifications/NotificationsService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: '20px', //grid padding
    textAlign: 'left',
    margin: '20px 20px 0px 20px'
  },
  unseenGridItem: {
    backgroundColor: 'rgba(0, 230, 118, 0.08)',
    margin: '2px 0',
    padding: '10px',
    "&:hover": {
      backgroundColor: 'rgba(0, 230, 118, 0.12)'
    }
  },
  defaultGridItem: {
    borderBottom: '1px solid #EEEEEE',
    margin: '0px',
    padding: '10px',
    "&:hover": {
      backgroundColor: 'rgb(247, 246, 243)'
    }
  },
  message: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  date: {
    paddingTop: '10px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  iconContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: "8px",
    height: '40px',
    width: '40px',
    textAlign: 'center',
  },
  iconContainerBorder: {
    border: '1px solid #EEEEEE'
  },
  iconCircle: {
    position: 'absolute',
    top: '-3px',
    right: '-3px',
    height: '8px',
    width: '8px',
    borderRadius: '4px',
  },
  greenCircle: {
    backgroundColor: '#00A152',
  },
  greyCircle: {
    backgroundColor: '#EEEEEE'
  },
  statusIcon: {
    paddingTop: '5px',
    '& svg': {
      fontSize: '26px'
    }
  },
  filterGreen: {
    filter: 'invert(50%) sepia(93%) saturate(5839%) hue-rotate(140deg) brightness(101%) contrast(101%)',
  },
  filterGrey: {
    opacity: '.6'
  },
  noResults: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '20px 0 10px 0;',
    flexGrow: 1,
    textAlign: 'center'
  },
  lowercase: {
    textTransform: 'lowercase'
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    padding: '0'
  },
  subtitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingBottom: '10px'
  },
  markAll: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    paddingTop:'15px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '15px',
      marginBottom: '-2px'
    }
  },
  backdrop: {
    zIndex: '1000',
    backgroundColor: 'rgba(0,0,0,0.1)'
  }
}));

const NotificationsList = ({ history }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [seenList, setSeenList] = useState([]);
  const [unseenList, setUnseenList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [unseenCount, setUnseenCount] = useState(null);
  const [seenCount, setSeenCount] = useState(null);
  const [pendingCount, setPendingCount] = useState(null);
  useEffect(() => {
    async function getNotifications() { 
      const result = await getNotificationsList();
      updateLists(result);
    }
    getNotifications();
  }, []);

  const updateLists = (result) => {
    if(result.length > 0) {
      let seenArr = [];
      let unseenArr = [];
      let pendingArr = [];
      let sCount = 0;
      let uCount = 0;
      let pCount = 0;
      for(let i = 0; i < result.length; ++i){
        if(result[i].Status) {
          sCount++;
          seenArr.push(result[i])
        } else if (result[i].Pending > 0) {
          pCount++;
          pendingArr.push(result[i])
        } else {
          uCount++;
          unseenArr.push(result[i])
        }
      }
      setSeenList(seenArr);
      setUnseenList(unseenArr);
      setPendingList(pendingArr);
      setSeenCount(sCount);
      setUnseenCount(uCount);
      setPendingCount(pCount);
    }
  }

  const waitForCheck = () => {
    setTimeout(function () {
      setOpen(true);
    }, 100);
  }

  const markAll = (currentStatus) => {
    let markAction;
    if (currentStatus === 'Un-seen') {
      // mark all Un-seen(currentStatus) as Seen
      markAction = completeAllTasks();
    } else {
      // mark all Seen(currentStatus) as Un-seen
      markAction = uncompleteAllTasks();
    }
    async function mark() { 
      setOpen(true);
      await markAction;
      // TODO: We should check result first. Check if success then getNotifications
      async function getNotifications() { 
        const result = await getNotificationsList();
        updateLists(result);
        setOpen(false);
        // Notification top nav can listen to the update
        history.push('/spark/notifications#updated');
      }
      getNotifications();
    }
    mark();
  }

  const ListHeader = (props) => {
    const { title, listCount, markAllLabel} = props;
    return (
      <header>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>
              You have {listCount || 0} <span className={classes.lowercase}>{title}</span> notifications
            </Typography>
          </Grid>
          {(title !== "Pending" && listCount > 0) &&
          <Grid item xs={6} align="right">
            <Typography variant="subtitle2" className={classes.markAll} onClick={() => markAll(title)}>
              {markAllLabel} <IconReady />
            </Typography>
          </Grid>
          }
        </Grid>
      </header>
    )
  }

  const checkToComplete = (taskId) => {
    async function update() { 
      waitForCheck();
      await completeTask(taskId);
      // Check if success then getNotifications
      async function getNotifications() { 
        const result = await getNotificationsList();
        updateLists(result);
        setOpen(false);
        // Notification top nav can listen to the update
        history.push('/spark/notifications#updated');
      }
      getNotifications();
    }
    update();
  }

  const checkToUncomplete = (taskId) => {
    async function update() { 
      waitForCheck();
      await uncompleteTask(taskId);
      // Check if success then getNotifications
      async function getNotifications() { 
        const result = await getNotificationsList();
        updateLists(result);
        setOpen(false);
        // Notification top nav can listen to the update
        history.push('/spark/notifications#updated');
      }
      getNotifications();
    }
    update();
  }

  const GridItems = (props) => {
    const { qualifier } = props;

    let notificationsList;
    let iconContainerBorder = '';
    let circleColor = classes.greyCircle;
    let iconFilter = classes.filterGrey;
    let gridContainer = classes.defaultGridItem

    if (qualifier === "un-seen") {
      notificationsList = unseenList;
      circleColor = classes.greenCircle;
      iconFilter = classes.filterGreen;
      gridContainer = classes.unseenGridItem
    } else if (qualifier === "pending") {
      notificationsList = pendingList;
      iconContainerBorder = classes.iconContainerBorder;
    } else {
      notificationsList = seenList;
      iconContainerBorder = classes.iconContainerBorder;
    }

    let itemList=[];
    notificationsList.forEach((notice,index)=>{
      if (notice) {
        itemList.push(
          <Grid container className={gridContainer} key={notice.TaskID}>
            <Grid item xs={2} sm={1}>
              <div className={`${classes.iconContainer} ${iconContainerBorder}`}>
                <div className={`${classes.iconCircle} ${circleColor}`} />
                <div className={`${classes.statusIcon} ${iconFilter}`}>
                  { notice.Type === "Personnel" && <IconPersonnelLight className={classes.icon}/> }
                  { notice.Type === "Equipment" && <IconEquipmentLight className={classes.icon}/> }
                  { notice.Type === "Order" && <IconOrderLight className={classes.icon}/> }
                </div>
              </div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <Typography variant="body2">{notice.Title}</Typography>
              <Typography variant="body2" className={classes.message}>{notice.Message}</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="body2" className={classes.date}>
              {notice.WhenDue}
              </Typography>
            </Grid>
            <Grid item xs={1} align="right">
              { qualifier === "un-seen" && 
                <Checkbox
                  value={notice.TaskID}
                  inputProps={{ 'aria-label': 'Mark as Seen' }}
                  onClick={() => checkToComplete(notice.TaskID)}
                />
              }
              { qualifier === "seen" && 
                <Checkbox
                  value={notice.TaskID}
                  inputProps={{ 'aria-label': 'Mark as Un-seen' }}
                  onClick={() => checkToUncomplete(notice.TaskID)}
                />
              }
            </Grid>
          </Grid>
        )
      }
    })
    return itemList
  }

  return (
    <div>
      <CorePageHeader
        title="Notifications"
        breadCrumb="Home / ... / Notifications List"
      />
      <Container maxWidth="lg">
        <Paper elevation={1} square={true} className={classes.paper}>
          <ListHeader title="Un-seen" listCount={unseenCount} markAllLabel='Mark all as seen' />
          { unseenCount > 0 && <GridItems qualifier="un-seen" /> }
          { unseenCount === null && <CoreGhostLoader height="62px" margin="2px 0" loop={2} colorClass="ghost-green"/> }
        </Paper>
        <Paper elevation={1} square={true} className={classes.paper}>
          <ListHeader title="Seen" listCount={seenCount} markAllLabel='Mark all as un-seen' />
            { seenCount > 0 && <GridItems qualifier="seen" /> }
            { seenCount === null && <CoreGhostLoader height="62px" margin="2px 0" loop={2} colorClass="ghost-grey"/> }
        </Paper>
        <Paper elevation={1} square={true} className={classes.paper}>
          <ListHeader title="Pending" listCount={pendingCount} />
            { pendingCount > 0 && <GridItems qualifier="pending" /> }
            { pendingCount === null && <CoreGhostLoader height="62px" margin="2px 0" loop={2} colorClass="ghost-grey"/> }
        </Paper>
      </Container>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress />
      </Backdrop>
    </div>
  );

}

export default NotificationsList;
