import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as PhoneSvg } from '../assets/svg-icons/phone@1x.svg';

const IconPhone = () => {
  return (
    <SvgIcon fontSize="small">
      <PhoneSvg />
    </SvgIcon>
  )
}
export default IconPhone;