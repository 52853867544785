import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import NotesList from '../notes/NotesList';
import NoteCreateDialog from '../notes/NoteCreateDialog';
import OrderBillingView from '../order/OrderBillingView';
import OrderEquipmentListTable from '../orderEquipment/OrderEquipmentListTable';
import IntermodalEventListTable from '../intermodalEvent/IntermodalEventListTable';
import { getPayables } from '../order/OrderService';
import PayableListTable from '../payable/PayableListTable';
import OrderEquipmentCreateDialog from '../orderEquipment/OrderEquipmentCreateDialog';
import DocumentListTable from '../document/DocumentListTable';
import Paper from '@material-ui/core/Paper';
import OrderViewGeneralSection from '../order/OrderViewGeneralSection';
import OrderViewVesselContainerSection from '../order/OrderViewVesselContainerSection';
import { printInvoice } from '../intermodal_invoice/IntermodalInvoiceService';
import CoreAddButton from '../core/CoreAddButton';

import {
  update as orderUpdate,
  getEquipment,
  getDocuments,
  getEvents,
  getNotes,
  createIntermodalLeg
} from '../order/OrderService';
import OrderViewLocationSection from '../order/OrderViewLocationSection';

const useStyles = makeStyles(() => ({
  tabWidth: {
    width: '1170px',
  }
}));

const IntermodalInvoiceViewTabBar = (props) => {
  const {
    id,
    noteType,
    tabSelected,
    onChange,
    order,
    getOrder
  } = props;
  const classes = useStyles();
  const [equipment, setEquipment] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsInProgress, setEventsInProgress] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isEquipmentCreateDialogOpen, setEquipmentCreateDialogOpen] = useState(false);
  const [notes, setOrderNotes] = useState([]);
  const [isNotesCreateDialogOpen, setNotesCreateDialogOpen] = useState(false);
  const [payables, setPayables] = useState([]);
  const [orderFormData, setOrderFormData] = useState({ ...order });
  const [errorMessage, setErrorMessage] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    getNotes(id)
      .then(function (response) {
        setOrderNotes(response);
      });
    getPayables(id)
      .then(function (response) {
        setPayables(response);
      });
    getEquipment(id)
      .then(function (response) {
        setEquipment(response);
      });
    getDocuments(id)
      .then(function (response) {
        setDocuments(response);
      });
    getEvents(id)
      .then(function (response) {
        setEvents(response);
      });
  }, [id]);

  useEffect(() => {
    setOrderFormData({ ...order });
  }, [order]);

  const getPayablesList = () => {
    getPayables(id)
      .then(function (response) {
        setPayables(response);
      });
  };

  const getEquipmentList = () => {
    getEquipment(id)
      .then(function (response) {
        setEquipment(response);
      });
  };

  const getDocumentsList = () => {
    getDocuments(id)
      .then(function (response) {
        setDocuments(response);
      });
  };

  const getEventsList = () => {
    setEventsInProgress(true);
    getEvents(id)
      .then(function (response) {
        setEvents(response);
        setEventsInProgress(false);
      });
    getPayablesList();
  };

  const onTabEvent = (newValue) => {
    const tempEvent = {
      target: {
        value: newValue
      }
    };
    onChange(tempEvent);
  };

  const handleOpenCreate = () => {
    switch (tabSelected) {
      case 2:
        openEquipmentCreateDialog();
        break;
      case 4:
        addEventsLeg();
        break;
      case 6:
        openNotesCreateDialog();
        break;
      default:
        break;
    }
  };

  const updateOrderFormData = e => {
    const { name, value } = e.target;
    orderFormData[name] = value;
    setOrderFormData({ ...orderFormData });
  };

  const onSave = e => {
    setInProgress(true);
    setAlertSuccess(false);
    formatOrder();
    e.preventDefault();
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await orderUpdate(orderFormData);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    getOrder();
    setAlertSuccess(true);
    setInProgress(false);
  };

  const getNotesList = () => {
    getNotes(id)
      .then(function (response) {
        setOrderNotes(response);
      });
  };

  const openEquipmentCreateDialog = () => {
    setEquipmentCreateDialogOpen(true);
  };

  const closeEquipmentCreateDialog = () => {
    setEquipmentCreateDialogOpen(false);
    getEquipmentList();
  };

  const addEventsLeg = () => {
    setEventsInProgress(true);
    createIntermodalLeg(id)
      .then(function (response) {
        getEventsList();
      });
  };

  const openNotesCreateDialog = () => {
    setNotesCreateDialogOpen(true);
  };

  const closeNotesCreateDialog = () => {
    setNotesCreateDialogOpen(false);
    getNotesList();
  };

  function downloadInvoice() { 
    printInvoice(id)
      .then(function(response) { 
        window.open(response, '_blank');
      });
  };

  const formatOrder = () => {
    orderFormData.SerialNum = orderFormData.SerialNum?.trim() || null;
    orderFormData.BookingNum = orderFormData.BookingNum?.trim() || null;
    orderFormData.Line = orderFormData.Line?.trim() || null;
    orderFormData.HouseBL = orderFormData.HouseBL?.trim() || null;
  };

  return (
    <Container style={{ padding: '0' }} maxWidth="lg" className="mt-2">
      <Grid container spacing={2} className="mt-2">
        <Grid container item xs={11}>
          <Tabs
            value={tabSelected}
            onChange={(event, newValue) => { onTabEvent(newValue) }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab value={0} label='Order Info' />
            <Tab value={1} label='Billing' />
            <Tab value={2} label='Equipment' />
            <Tab value={3} label='Documents' />
            <Tab value={4} label='Events' />
            <Tab value={5} label='Expenses' />
            <Tab value={6} label='Notes' />
          </Tabs>
        </Grid>
        {(tabSelected === 2 || tabSelected === 6) &&
          <Grid container item xs={1}>
            <CoreAddButton handleOpenCreate={handleOpenCreate}></CoreAddButton>
          </Grid>
        }
        {(tabSelected === 4) &&
          <Grid container item xs={1} style={{ paddingLeft: '0' }}>
            <CoreAddButton handleOpenCreate={handleOpenCreate} recordTypeText={"LEG"}></CoreAddButton>
          </Grid>
        }
      </Grid>
      { tabSelected === 0 &&
        <>
          <Container square={true} maxWidth="lg" className="mt-4" component={Paper}>
            <OrderViewGeneralSection
              order={order}
              printInvoice={downloadInvoice}
            />
            <OrderViewVesselContainerSection
              order={order}
            />
            <OrderViewLocationSection
              order={order}
            />
          </Container>
        </>
      }
      { tabSelected === 1 &&
        <OrderBillingView
          orderFormData={orderFormData}
          updateOrderFormData={updateOrderFormData}
          onSave={onSave}
          errorMessage={errorMessage}
          alertSuccess={alertSuccess}
          inProgress={inProgress}
        />
      }
      { tabSelected === 2 &&
        <OrderEquipmentListTable
          orderID={id}
          equipment={equipment}
          getEquipment={getEquipmentList}
          setEquipment={setEquipment}
        />
      }
      { tabSelected === 3 &&
        <DocumentListTable
          orderID={id}
          documents={documents}
          getDocuments={getDocumentsList}
          setDocuments={setDocuments}
        />
      }
      { tabSelected === 4 &&
        <IntermodalEventListTable
          originID={id}
          events={events}
          getEvents={getEventsList}
          setEvents={setEvents}
          getPayables={getPayablesList}
          inProgress={eventsInProgress}
          setInProgress={setEventsInProgress}
        />
      }
      { tabSelected === 5 &&
        <PayableListTable
          payables={payables}
          getPayables={getPayablesList}
          setPayables={setPayables}
        />
      }
      {tabSelected === 6 &&
        <NotesList
          notes={notes}
          getNotes={getNotesList}
        />
      }
      {tabSelected === 6 &&
        <NoteCreateDialog
          originID={id}
          noteType={noteType}
          isOpen={isNotesCreateDialogOpen}
          onClose={closeNotesCreateDialog}
        />
      }
      {tabSelected === 2 &&
        <OrderEquipmentCreateDialog
          id={id}
          isOpen={isEquipmentCreateDialogOpen}
          onClose={closeEquipmentCreateDialog}
        />
      }
    </Container>
  );

}

export default IntermodalInvoiceViewTabBar;