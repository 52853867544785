import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import PayableEditDialog from '../payable/PayableEditDialog.js'

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const PayableListColumnEditButton = (props) => {
  const {
    payableID,
    getPayables
  } = props;
  
  const classes = useStyles();
  const [isPayableEditModalOpen, setIsPayableEditModalOpen] = useState(false);

  const openPayableEditModal = () => {
    setIsPayableEditModalOpen(true);
  };

  const closePayableEditModal = () => {
    setIsPayableEditModalOpen(false);
    getPayables();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openPayableEditModal}>
        <EditIcon/>&nbsp;
        <span>EDIT</span>
      </IconButton> 
      <PayableEditDialog
        payableID={payableID}
        isOpen={isPayableEditModalOpen}
        onClose={closePayableEditModal}
      />
    </>
  );
}

PayableListColumnEditButton.propTypes = {
  payableID: PropTypes.number.isRequired
};

export default PayableListColumnEditButton;