import React, { useState, useEffect } from 'react';
import { createPayableItem } from '../payable_item/PayableItemService'

import Validation from '../validation';
import PayableItemCreateDialogContent from './PayableItemCreateDialogContent';
import { getList as getReceivableChargeTypeList } from '../receivable_charge_type/ReceivableChargeTypeService';
import { addRCT, addPCT, getList as getPayableChargeTypeList } from '../payableChargeType/PayableChargeTypeService';

const PayableItemCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    Name: '',
    DescriptionDefault: '',
    UnitsDefaultValue: 0,
    UnitsDefaultValuePercent: 0,
    UnitRateDefaultValue: 0,
    IntermodalUnitTypeName: 'Custom',
    IntermodalUnitType: 0,
    IntermodalUnitRateTypeName: 'Custom',
    IntermodalUnitRateType: 0,
    RelatedReceivableChargeTypeID: null
  };

  const [payableItemFormData, setPayableItemFormData] = useState(initialState);
  const [payableItemFormValidation, setPayableItemFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(true);
  const [receivableChargeTypeList, setReceivableChargeTypeList] = useState([]);
  const [receivableChargeTypeApplyList, setReceivableChargeTypeApplyList] = useState([]);
  const [payableChargeTypeList, setPayableChargeTypeList] = useState([]);
  const [payableChargeTypeApplyList, setPayableChargeTypeApplyList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setPayableItemFormValidation(buildValidation(payableItemFormData));      
    }
  }, [payableItemFormData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      async function getReceivableChargeTypes() { 
        const result = await getReceivableChargeTypeList();
          setReceivableChargeTypeList(result);
          setInProgress(false);
      }
      async function getPayableChargeTypes() { 
        const result = await getPayableChargeTypeList();
        setPayableChargeTypeList(result);
        setInProgress(false);
      }
      getReceivableChargeTypes();
      getPayableChargeTypes();
    }
  }, [isOpen]);

  const updatePayableItemFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    payableItemFormData[name] = value;
    payableItemFormData[nameData] = valueData;
    if (payableItemFormData.IntermodalUnitType === 1 || payableItemFormData.IntermodalUnitType === 3) {
      payableItemFormData.UnitsDefaultValue = 1;
    }
    if (payableItemFormData.IntermodalUnitType === 2) {
      payableItemFormData.UnitsDefaultValue = payableItemFormData.UnitsDefaultValuePercent / 100;
    } else {
      payableItemFormData.UnitsDefaultValuePercent = payableItemFormData.UnitsDefaultValue * 100;
    }
    if (payableItemFormData.IntermodalUnitRateType !== 1 || payableItemFormData.IntermodalUnitRateType !== 3) {
      setReceivableChargeTypeApplyList([]);
    }
    if (payableItemFormData.IntermodalUnitRateType !== 3) {
      setPayableChargeTypeApplyList([]);
    }
    setPayableItemFormData({ ...payableItemFormData });
  };

  const updatePayableRelatedReceivableChargeType = e => {
    const { name, value } = e.target;
    if (value === false) {
      var index = receivableChargeTypeApplyList.indexOf(name);
      if (index !== -1) {
        receivableChargeTypeApplyList.splice(index, 1);
      }
    } else {
      receivableChargeTypeApplyList.push(name);
    }
    setReceivableChargeTypeApplyList(receivableChargeTypeApplyList);
  };

  const updatePayableRelatedPayableChargeType = e => {
    const { name, value } = e.target;
    if (value === false) {
      var index = payableChargeTypeApplyList.indexOf(name);
      if (index !== -1) {
        payableChargeTypeApplyList.splice(index, 1);
      }
    } else {
      payableChargeTypeApplyList.push(name);
    }
    setPayableChargeTypeApplyList(payableChargeTypeApplyList);
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(payableItemFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await createPayableItem(payableItemFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    } else {
      addAdditionals(result.response);
    }
    closeDialog();
  };

  const addAdditionals = async (payableChargeTypeID) => {
    receivableChargeTypeApplyList.forEach(r => {
      addRCT(payableChargeTypeID, r);
    });
    payableChargeTypeApplyList.forEach(p => {
      addPCT(payableChargeTypeID, p);
    });
  };

  const closeDialog = () => {
    setPayableItemFormData({initialState});
    onClose();
  };

  return (
    isOpen &&
    <PayableItemCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      payableItemFormData={payableItemFormData}
      payableItemFormValidation={payableItemFormValidation}
      updatePayableItemFormData={updatePayableItemFormData}
      inProgress={inProgress}
      receivableChargeTypeList={receivableChargeTypeList}
      updatePayableRelatedReceivableChargeType={updatePayableRelatedReceivableChargeType}
      payableChargeTypeList={payableChargeTypeList}
      updatePayableRelatedPayableChargeType={updatePayableRelatedPayableChargeType}
    />
  );
}

const buildValidation = (payableItemFormData) => {
  const {
    Name,
    DescriptionDefault,
    UnitsDefaultValue,
    UnitRateDefaultValue,
    IntermodalUnitType,
    IntermodalUnitRateType,  
  } = payableItemFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
    DescriptionDefault: validation.isEmpty(DescriptionDefault),
    UnitsDefaultValue: validation.isNumber(UnitsDefaultValue),
    UnitRateDefaultValue: validation.isNumber(UnitRateDefaultValue),
    IntermodalUnitType: validation.isNumber(IntermodalUnitType),
    IntermodalUnitRateType: validation.isNumber(IntermodalUnitRateType),
  };
};

export default PayableItemCreateDialog;
