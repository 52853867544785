import { Route } from 'react-router-dom';
import Dashboard from './Dashboard';

const DashboardRouter = ({ parentURL }) => {
    return (
        <>
            <Route exact path="/spark/dashboard" component={Dashboard} />
        </>
    );

}

export default DashboardRouter;