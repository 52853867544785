import { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import CorePageHeader from '../core/CorePageHeader';
import FeePayShoppingCartItemsList from './FeePayShoppingCartItemsList';
import FeePayShoppingCartSubtotal from './FeePayShoppingCartSubtotal';
import FeePayShoppingCartPayment from './FeePayShoppingCartPayment';

import { useCartRequests, usePaymentRequests } from '@envasetechnologies/feepay-components';

const FeePayShoppingCart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCartItemsCount, setSelectedCartItemsCount] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentIntent, setPaymentIntent] = useState({});
  const [total, setTotal] = useState(0);
  const [billerTypeItems, setBillerTypeItems] = useState([]);
  const [selectedBiller, setSelectedBiller] = useState("");

  const cartRequests = useCartRequests();
  const paymentRequests = usePaymentRequests();
  const baseUrl = window.location.href;

  useEffect(() => { 
    setIsLoading(true);
    resetData();
    async function getCartItems() { 
      var cart = await cartRequests.requestCart();
      cart.cartItems.sort((a, b) => (a.billerType > b.billerType) ? 1 : -1);
      var billerTypes = [];
      var itemsForBillerType = [];
      for (const item of cart.cartItems) { 
        item.selected = false;
        if (!billerTypes.includes(item.billerType)) {           
          if (itemsForBillerType.length > 0) { 
            setBillerTypeItems(c => ([...c, {billerName: billerTypes[billerTypes.length - 1], cartItems: itemsForBillerType }]));
            itemsForBillerType = [];
          }
          billerTypes.push(item.billerType);
        } 
        itemsForBillerType.push(item);        
      }
      setBillerTypeItems(c => ([...c, {billerName: billerTypes[billerTypes.length - 1], cartItems: itemsForBillerType }]));
      setIsLoading(false);
    };
    getCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    var selectedTotal = 0;
    var count = 0;
    billerTypeItems.forEach((item) => { 
      item.cartItems.forEach((cItem) => {      
        if (cItem.selected) { 
          selectedTotal += parseFloat(cItem.amount);
          count++;
          setSelectedBiller(cItem.billerType);
        }
      });    
    });
    if (count === 0) {
      setSelectedBiller("");
    }
    setTotal(selectedTotal);
    setSelectedCartItemsCount(count);
  }, [billerTypeItems]);

  const getIndexes = (arr, cartItem) => {
    for (const item of arr) {
      for (const cItem of item.cartItems) {
        if (cItem.id === cartItem.id) {
          return [arr.indexOf(item), item.cartItems.indexOf(cItem)];
        }
      }
    }
    return [-1, -1];
  };

  const handleCartItemSelect = (event, cartItem) => {  
    const { value } = event.target;
    var arr = [...billerTypeItems];
    const indexes = getIndexes(arr, cartItem);
    if (indexes[0] !== -1 || indexes[1] !== -1) {  
      arr[indexes[0]].cartItems[indexes[1]].selected = value;
      setBillerTypeItems(arr);
    }
  };
  
  const handleSelectPaymentMethod = async (e) => { 
    const { 
      value
    } = e.target;
    setSelectedPaymentMethod(value);
  };

  const getSelectedCartItems = () => { 
    var arr = [];
    billerTypeItems.forEach((item) => { 
      item.cartItems.forEach((cItem) => {    
        if (cItem.selected) { 
          arr.push(cItem);
        }      
      });
    });
    return arr;
  };

  const createPaymentIntent = async () => { 
    var items = getSelectedCartItems();
    const createdPaymentIntent = await paymentRequests.requestCreatePaymentIntent({
      cartItems: items
    });
    var intent = await assignPaymentMethod(createdPaymentIntent);
    setPaymentIntent(intent);
    return intent;
  };

  const assignPaymentMethod = async (paymentIntent, additionalData) => { 
    if (paymentIntent) { 
      const attachedPaymentIntent = await paymentRequests.requestAttachPaymentMethod({
        paymentIntentId: paymentIntent.id,
        type: selectedPaymentMethod,
        additionalData: {
          returnUrl: baseUrl + '-payment',
          autoConfirm: true
        },
      });
      return attachedPaymentIntent;
    }
  };

  const confirmPaymentWithAdditionalData = (async (paymentIntentId, additionalData) => {
    var dataPlusTestConfigOptions = additionalData;
    return await paymentRequests.requestConfirmPayment({
      paymentIntentId: paymentIntentId,
      additionalData: {...dataPlusTestConfigOptions?.additionalData},
    });
  });

  const handlePlaceOrder = async (additionalData) => { 
    setIsLoading(true);
    var createdPaymentIntent = await createPaymentIntent();
    if (createdPaymentIntent?.nextAction?.actionType === 'REDIRECT_USER') { 
      handleOrderRedirect(createdPaymentIntent);
    } else { 
      setPaymentIntent(await confirmPaymentWithAdditionalData(createdPaymentIntent.id, null));
    }
    setIsLoading(false);
  };

  const handleOrderRedirect = (paymentIntent) => { 
    if (paymentIntent.nextAction?.redirectUrl) {
      window.location.href = paymentIntent.nextAction.redirectUrl;
    }
  };

  const handleCancel = () => { 
    setPaymentIntent(null);
    setSelectedPaymentMethod(null);
  };

  const deleteCartItem = async(cartItemID) => {
    await cartRequests.requestDeleteCartItemByCartItemId({id: cartItemID});
    getCartItemsList();
  };

  const resetData = () => {
    setBillerTypeItems([]);
    setSelectedBiller("");
    setSelectedCartItemsCount(0);
    setTotal(0);
  }

  const getCartItemsList = async() => { 
    setIsLoading(true);
    resetData();
    var cart = await cartRequests.requestCart();
    cart.cartItems.sort((a, b) => (a.billerType > b.billerType) ? 1 : -1);
    var billerTypes = [];
    var itemsForBillerType = [];
    for (const item of cart.cartItems) { 
      item.selected = false;
      if (!billerTypes.includes(item.billerType)) {           
        if (itemsForBillerType.length > 0) { 
          setBillerTypeItems(c => ([...c, {billerName: billerTypes[billerTypes.length - 1], cartItems: itemsForBillerType }]));
          itemsForBillerType = [];
        }
        billerTypes.push(item.billerType);
      } 
      itemsForBillerType.push(item);        
    }
    setBillerTypeItems(c => ([...c, {billerName: billerTypes[billerTypes.length - 1], cartItems: itemsForBillerType }]));
    setIsLoading(false);
  };

  return (
    <div>
      <CorePageHeader
        title="Cart"
        breadCrumb="Home / ... / Cart"
      />
      <Container maxWidth={'xl'} className="mt-4" style={{textAlign: 'right'}} >
        <Grid container item xs spacing={2} className="mt-1 mb-1">
          <Grid item xs={1}></Grid>
          <Grid item xs={7} className="mb-1">
            {isLoading && <LinearProgress />}           
            <FeePayShoppingCartItemsList
              billerTypeItems={billerTypeItems}
              selectedBiller={selectedBiller}
              handleCartItemSelect={handleCartItemSelect}
              deleteCartItem={deleteCartItem}
            />
            <FeePayShoppingCartSubtotal
              total={total}
            />
          </Grid>
          <Grid item xs={3} className="mb-1">
            {isLoading && <LinearProgress />}
            <FeePayShoppingCartPayment
              selectedPaymentMethod={selectedPaymentMethod}
              onPaymentMethodSelect={handleSelectPaymentMethod}
              onPlaceOrder={handlePlaceOrder}
              onCancel={handleCancel}
              paymentIntent={paymentIntent}
              selectedCartItemsCount={selectedCartItemsCount}
              total={total}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FeePayShoppingCart;