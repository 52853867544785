import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';

import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import EquipmentEditContentBasicInfo from './EquipmentEditContentBasicInfo';
import EquipmentEditContentDetailInfo from './EquipmentEditContentDetailInfo';

const EquipmentEditContent = (props) => {
  const {
    EquipmentFormData,
    onUpdate,
    onClose,
    errorMessage,
    EquipmentFormValidation,
    UpdateEquipmentFormData,
    inProgress
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  return (
    <form onSubmit={onUpdate}>
      <EquipmentEditContentBasicInfo
        EquipmentFormData={EquipmentFormData}
        EquipmentFormValidation={EquipmentFormValidation}
        UpdateEquipmentFormData={UpdateEquipmentFormData}
      /><br></br>      
      <EquipmentEditContentDetailInfo
        EquipmentFormData={EquipmentFormData}
        EquipmentFormValidation={EquipmentFormValidation}
        UpdateEquipmentFormData={UpdateEquipmentFormData}
      />
      {errorMessage &&
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br />}
      {!inProgress && <CoreCreateContentStickyFooterButtons 
        changesDetails={"Equipment " + EquipmentFormData.UnitNum + " details"}
        onClose={onClose} 
      />}
    </form>
  );
}

export default EquipmentEditContent;