import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconConfiguration from '../icon/IconConfiguration';

const NavigationDrawerListItemConfiguration = () => {
  return (
    <NavigationDrawerListItem
      title={'Configuration'}
      icon={<IconConfiguration />}
      uri={'/spark/configuration'} />
  );

}

export default NavigationDrawerListItemConfiguration;