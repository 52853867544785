import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import LocationAreaTagCreateDialog from '../location_area_tag/LocationAreaTagCreateDialog';
import ContactCreateDialog from '../contact/ContactCreateDialog';
import NoteCreateDialog from '../notes/NoteCreateDialog';
import CoreAddButton from '../core/CoreAddButton';
//import CustomerInvoiceCodeCreateDialog from '../customer_invoice_code/CustomerInvoiceCodeCreateDialog';

const useStyles = makeStyles(() => ({
  tabWidth: {
    width: '640px',
  }
}));

const ContactNoteTabBar = (props) => {
  const {
    id,
    noteType,
    getContacts,
    getNotes,
    getAreaTags,
    onChange,
    isFromCustomer,
    isFromLocation
  } = props;
  const classes = useStyles();
  const [tabPosition, setTabPosition] = useState(0);
  const [isContactCreateDialogOpen, setContactCreateDialogOpen] = useState(false);
  const [isNotesCreateDialogOpen, setNotesCreateDialogOpen] = useState(false);
  const [isLocationAreaTagCreateDialogOpen, setLocationAreaTagCreateDialogOpen] = useState(false);

  const onTabEvent = (newValue) => {
    const tempEvent = {
      target: {
        value: newValue
      }
    };
    onChange(tempEvent);
  };

  const handleOpenCreate = () => {
    if (tabPosition === 0) {
      openContactCreateDialog();
    } else if (tabPosition === 1){ 
      openNotesCreateDialog();
    } else if (tabPosition === 2){
      openLocationAreaTagCreateDialog();
    }
  };

  const openContactCreateDialog = () => {
    setContactCreateDialogOpen(true);
  };

  const closeContactCreateDialog = () => {
    setContactCreateDialogOpen(false);
    getContacts();
  };

  const openNotesCreateDialog = () => {
    setNotesCreateDialogOpen(true);
  };

  const closeNotesCreateDialog = () => {
    setNotesCreateDialogOpen(false);
    getNotes();
  };

  const openLocationAreaTagCreateDialog = () => {
    setLocationAreaTagCreateDialogOpen(true);
  };

  const closeLocationAreaTagCreateDialog = () => {
    setLocationAreaTagCreateDialogOpen(false);
    getAreaTags();
  };

  return (
    <Container style={{ padding: '0' }} maxWidth="lg" className="mt-2">
      <Grid container spacing={2} className="mt-2">
        <Grid container item xs={7}>
          <Tabs
            value={tabPosition}
            onChange={(event, newValue) => { onTabEvent(newValue); setTabPosition(newValue) }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabWidth}
          >
            <Tab value={0} label='Contacts' />
            <Tab value={1} label='Notes' />
            <Tab value={2} label='Area Tags' />
          </Tabs>
        </Grid>
        <Grid container item xs={4}></Grid>
        <Grid container item xs={1}>
          <CoreAddButton handleOpenCreate={handleOpenCreate}></CoreAddButton>
        </Grid>
      </Grid>
      {tabPosition === 0 && <ContactCreateDialog
        id={id}
        isOpen={isContactCreateDialogOpen}
        onClose={closeContactCreateDialog}
        isFromCustomer={isFromCustomer}
        isFromLocation={isFromLocation}
      />}
      {tabPosition === 1 && <NoteCreateDialog
        originID={id}
        noteType={noteType}
        isOpen={isNotesCreateDialogOpen}
        onClose={closeNotesCreateDialog}
      />}
      {tabPosition === 2 && <LocationAreaTagCreateDialog
        id={id}
        isOpen={isLocationAreaTagCreateDialogOpen}
        onClose={closeLocationAreaTagCreateDialog}
        getAreaTags={getAreaTags}
      />}
    </Container>
  );
}

export default ContactNoteTabBar;
