import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from './RevenueService';
import RevenueEditDialogContent from './RevenueEditDialogContent';

const RevenueEditDialog = (props) => {
  const {
    receivableChargeID,
    isOpen,
    onClose,
  } = props;

  const [revenueFormDataLoaded, setRevenueFormDataLoaded] = useState(false);
  const [revenueFormData, setRevenueFormData] = useState({});
  const [revenueFormValidation, setRevenueFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      get(receivableChargeID)
      .then(function(response) {
        setRevenueFormData(response);
        setRevenueFormDataLoaded(true);
      });    
    }
  }, [isOpen, receivableChargeID]);

  useEffect(() => {
    if (isOpen) {
      setRevenueFormValidation(buildValidation(revenueFormData));
    }
  }, [revenueFormData, isOpen]);

  useEffect(() => {
    if (!revenueFormDataLoaded) { 
      return;
    }
    setRevenueFormData(r => (
      { ...r, Description: revenueFormData.ReceivableChargeTypeName }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueFormData.ReceivableChargeTypeID]);

  const updateRevenueFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    revenueFormData[name] = value;
    revenueFormData[nameData] = valueData;
    setRevenueFormData({ ...revenueFormData });
    updateSubtotal();
  };

  const updateSubtotal = () => {
    setRevenueFormData(s => ({...s, Subtotal: (revenueFormData.Units * revenueFormData.UnitRate)}));
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(revenueFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(revenueFormData);
    setInProgress(false);
    if (result.errors) {
        setErrorMessage(result.errors.Error);
        return;
    }
    onClose();
  };

  return (
    isOpen && revenueFormDataLoaded &&
    <RevenueEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      revenueFormData={revenueFormData}
      revenueFormValidation={revenueFormValidation}
      updateRevenueFormData={updateRevenueFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (revenueFormData) => {
  const {
    ReceivableChargeTypeID
  } = revenueFormData || {};
  const validation = new Validation();
  return {
    ReceivableChargeTypeID: validation.isNumber(ReceivableChargeTypeID)
  };
};

export default RevenueEditDialog;