import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { get, getContacts, getNotes, activate, deactivate } from './CustomerService';
import { getInvoiceCodes } from './CustomerService';
import CustomerViewGeneralSection from './CustomerViewGeneralSection';
import CustomerViewAddressSection from './CustomerViewAddressSection';
import ContactNoteTabBar from '../contact/ContactNoteTabBar';
import ContactListTable from '../contact/ContactListTable';
import NotesList from '../notes/NotesList';
import CustomerViewPageHeader from './CustomerViewPageHeader';

const CustomerView = () => {
  const { id } = useParams();

  const [customer, setCustomer] = useState({});
  const [contacts, setCustomerContacts] = useState([]);
  const [,setInvoiceCodes] = useState([]);
  const [notes, setCustomerNotes] = useState([]);
  const [tabPosition, setTabPosition] = useState(0);

  useEffect(() => {
    get(id)
      .then(function (response) {
        setCustomer(response);
      });
    getContacts(id)
      .then(function (response) {
        setCustomerContacts(response);
      });
    getNotes(id)
      .then(function (response) {
        setCustomerNotes(response);
      });
    getInvoiceCodes(id)
      .then(function (response) {
        setInvoiceCodes(response);
      });
  }, [id]);

  const getContactsList = () => {
    getContacts(id)
      .then(function (response) {
        setCustomerContacts(response);
      });
  };

  const getInvoiceCodesList = () => {
    getInvoiceCodes(id)
      .then(function (response) {
        setInvoiceCodes(response);
      });
  };

  const getNotesList = () => {
    getNotes(id)
      .then(function (response) {
        setCustomerNotes(response);
      });
  };

  const activateCustomer = () => {
    activate(id)
      .then(function (response) {
          setCustomer(c => ({ ...c, Obsolete: false }));
      });
  };

  const deactivateCustomer = () => {
    deactivate(id)
      .then(function (response) {
          setCustomer(c => ({ ...c, Obsolete: true }));
      });
  };


  const handleList = e => {
    const { value } = e.target;
    setTabPosition(value);
  };

  return (
    <div>
      <CustomerViewPageHeader
        customer={customer}
        activateCustomer={activateCustomer}
        deactivateCustomer={deactivateCustomer}
      />
      <CustomerViewGeneralSection
        customer={customer}
        activateCustomer={activateCustomer}
        deactivateCustomer={deactivateCustomer}
      />
      <CustomerViewAddressSection
        customer={customer}
      />
      <ContactNoteTabBar
        id={id}
        noteType={1}
        getContacts={getContactsList}
        getNotes={getNotesList}
        getInvoiceCodes={getInvoiceCodesList}
        onChange={e => handleList(e)}
        isFromCustomer={true}
        isFromLocation={false}
      />
      {
        tabPosition === 0 &&
        <ContactListTable
          contacts={contacts}
          getContacts={getContactsList}
          setContacts={setCustomerContacts}
        />
      }
      {
        tabPosition === 1 &&
        <NotesList
          notes={notes}
          getNotes={getNotesList}
        />
      }
    </div>
  );

}

export default CustomerView;