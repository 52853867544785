import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Validation from '../validation';
import BillEditContent from './BillEditContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreExitButton from '../core/CoreExitButton';
import { get, updateBill } from './BillService';
import { formatDateTime } from '../globalFunction';

const BillEdit = (props) => {
  let history = useHistory();
  const { id } = useParams();

  const [inProgress, setInProgress] = useState(false);
  const [billFormData, setBillFormData] = useState({
    PayToPersonnel: '',
  });
  const [billFormDataLoaded, setBillFormDataLoaded] = useState(false);
  const [billFormValidation, setBillFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    get(id)
      .then(function(response) {
        setBillFormData({ ...response });
        setBillFormDataLoaded(true);
      });
  }, [id]);

  useEffect(() => {
    setBillFormValidation(buildValidation(billFormData));
  }, [billFormData]);

  const updateBillFormData = e => {
    const {name, value} = e.target;
    const {nameData, valueData} = e.target;
    billFormData[name] = value;
    billFormData[nameData] = valueData;
    setBillFormData({ ...billFormData });
  };
  
  const formatBill = () => {
    billFormData.BillDate = formatDateTime(billFormData.BillDate);    
  }

  const onSave = e => {
    setInProgress(true);
    formatBill();
    e.preventDefault();
    if (!new Validation().isValidObject(billFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await updateBill(billFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    // Redirect to the bill view page
    history.push('/spark/bill/view/' + billFormData.BillID);
  };


  const onClose = () => {
    history.push('/spark/bill/view/' + id);
  };

  return (
    <div>
      <CorePageHeader
        title={"Edit Expense " + billFormData.BillNumber}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {billFormDataLoaded &&
        <BillEditContent
          onSave={onSave}
          onClose={onClose}
          errorMessage={errorMessage}
          billFormData={billFormData}
          billFormValidation={billFormValidation}
          updateBillFormData={updateBillFormData}
          inProgress={inProgress}
        />}
      </Container>
    </div>
  );
}

const buildValidation = (billFormData) => {
  const { 
    BillDate,
  } = billFormData || {};
  const validation = new Validation();
  return {
    BillDate: validation.isEmpty(BillDate),
  };
};

export default BillEdit;