import { Route } from 'react-router-dom';
import OrderList from './OrderList';
import OrderView from './OrderView';
import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';

const OrderRouter = () => {
  return (
    <>
      <Route exact path="/spark/order" component={OrderList} />
      <Route exact path="/spark/order/create" component={OrderCreate} />
      <Route exact path="/spark/order/view/:id" component={OrderView} />
      <Route exact path="/spark/order/edit/:id" component={OrderEdit} />
    </>
  );
}

export default OrderRouter;