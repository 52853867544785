import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { intermodalOrderDelete as Delete } from './PayableService';
import PayableListColumnEditButton from '../payable/PayableListColumnEditButton';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PayableListTable = (props) => {
  const {
    payables,
    getPayables,
    setPayables
  } = props;

  const [openSuccessCopy, setOpenSuccessCopy] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => { 
    var totalAmount = 0;
    payables.forEach(p => {
      totalAmount += parseFloat(p.Subtotal);
    });
    setTotal(totalAmount.toFixed(2));
  }, [payables]);

  // Payable Item, Quantity, Rate, Amount
  let columns = [
    {
      field: 'BillNumber',
      headerName: 'Expense #',
      align: 'left',
      flex: 0.5,
      sortable: 'false'
    },
    {
      field: 'BillDate',
      headerName: 'Date',
      align: 'left',
      flex: 0.5,
      sortable: 'false'
    },
    {
      field: 'DriverName',
      headerName: 'Driver',
      align: 'left',
      flex: 0.5,
      sortable: 'false'
    },
    {
      field: 'LegIndex',
      headerName: 'Leg',
      align: 'left',
      flex: 0,
      sortable: 'false',
      renderCell: (params) => {
        const legIndex = params.getValue("LegIndex");
        if (legIndex == null) { 
          return (<span></span>)
        } 
        return (
          <div style={{
          width:'100%', 
          minWidth: '100%', 
          display: 'flex',
          justifyContent: 'space-between'}}>
            <span>{legIndex + 1}</span>
          </div>
        )
      }
    },
    { 
        field: 'PayableChargeType', 
        headerName: 'Payable Item', 
        align: 'left', 
        flex: 0.75, 
        sortable: false,
        renderCell: (params) => {
          const name = params.getValue("PayableChargeType");
          return (
            <div style={{
              width:'100%', 
              minWidth: '100%', 
              display: 'flex',
              justifyContent: 'space-between'}}>
              <span>{name}</span>
            </div>
          )
        }
    },
    {
      field: 'Description',
      headerName: 'Description',
      align: 'left',
      flex: 0.75,
      sortable: false
    },
    { 
        field: 'Units', 
        headerName: 'Quantity', 
        align: 'left', 
        flex: 0, 
        sortable: false 
    },
    { 
        field: 'UnitRate', 
        headerName: 'Rate', 
        align: 'left', 
        flex: 0, 
        sortable: false
    },
    { 
        field: 'Subtotal', 
        headerName: payables.length === 0 ? 'Total' : 'Total ($' + total + ')', 
        align: 'left', 
        flex: 0.75, 
        sortable: false 
    },
    { 
        field: 'Controls', 
        headerName: ' ', 
        align: 'left', 
        flex: 1, 
        sortable: false,
        renderCell: (params) => {
            const payableChargeID = params.getValue("PayableChargeID");
            const showControls = params.getValue("ShowControls") || false;
            return (
            <div>
                {showControls &&
                <PayableListColumnEditButton
                    payableID={payableChargeID}
                    getPayables={getPayables}
                />}
                {showControls &&
                <CoreListColumnDeleteButton
                    reference={`Payable Item To: ${params.getValue("DriverName")}`}
                    deleteID={payableChargeID}
                    deleteFunction={Delete}
                    getList={getPayables}
                />}
            </div>
            )
        }
      }
  ];

  const onPayableRowEnter = (params) => {
    for (const payable of payables) {
      if (payable.PayableChargeID === params.row.PayableChargeID) {
        payable.ShowControls = true;
      }
    }
    setPayables([ ...payables ]);
  };

  const onPayableRowLeave = (params) => {
    for (const payable of payables) {
      if (payable.PayableChargeID === params.row.PayableChargeID) {
        payable.ShowControls = false;
      }
    }
    setPayables([...payables]);
  };

  const onPayableCellClick = (params) => {
    /*
    switch (params.field) {
      case 'Email':
        navigator.clipboard.writeText(params.formattedValue);
        setOpenSuccessCopy(true);
        break;
      case 'Phone':
        navigator.clipboard.writeText(params.formattedValue);
        setOpenSuccessCopy(true);
        break;
      default:
        break;
    }
    */
  };

  const handleSnackBarClose = () => {
    setOpenSuccessCopy(false);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1 munaGrid" component={Paper}>
      <DataGrid
        getRowId={(row) => row.PayableChargeID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={payables} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onPayableRowEnter(e)}
        onRowLeave={e => onPayableRowLeave(e)}
        onCellClick={e => onPayableCellClick(e)}
      />
      <Snackbar open={openSuccessCopy} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          Content copied!
        </Alert>
      </Snackbar>
    </Container>
  );
}

PayableListTable.propTypes = {
  payables: PropTypes.array.isRequired,
  getPayables: PropTypes.func.isRequired
};

export default PayableListTable;