import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import CorePhoneField from '../core/CorePhoneField';
import CountryField from '../country/CountryField';
import ProvinceField from '../province/ProvinceField';
import PersonnelUserDriverTabField from './PersonnelUserDriverTabField';

const PersonnelCreateDialogContent = (props) => {
  const {
    isOpen,
    resetForm,
    PersonnelFormData,
    onCreate,
    errorMessage,
    PersonnelFormValidation,
    updatePersonnelFormData
  } = props;
  
  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Personnel</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} xs={12}>
              <PersonnelUserDriverTabField
                name="listAsDriver"
                value={PersonnelFormData.listAsDriver}
                onChange={e => updatePersonnelFormData(e)}
              >
              </PersonnelUserDriverTabField>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={4} xs={12}>
              <CoreTextField
                name="firstName"
                label="First Name"
                value={PersonnelFormData.firstName}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={true}
                validationError={PersonnelFormValidation.firstName}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CoreTextField
                name="middleName"
                label="Middle Name"
                value={PersonnelFormData.middleName}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CoreTextField
                name="lastName"
                label="Last Name"
                value={PersonnelFormData.lastName}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="internalName"
                label="Internal Name"
                value={PersonnelFormData.internalName}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={true}
                validationError={PersonnelFormValidation.internalName}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="address"
                label="Address"
                value={PersonnelFormData.address}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CoreTextField
                name="city"
                label="City"
                value={PersonnelFormData.city}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <ProvinceField
                name="province"
                label="State / Province"
                value={PersonnelFormData.province || ""}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CoreTextField
                name="postalCode"
                label="ZIP / Postal Code"
                value={PersonnelFormData.postalCode}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CountryField
                label="Country"
                name="country"
                value={PersonnelFormData.country}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CorePhoneField
                name="homePhone"
                label="Primary"
                value={PersonnelFormData.homePhone}
                onChange={e => updatePersonnelFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CorePhoneField
                name="persCell"
                label="Cell"
                value={PersonnelFormData.persCell}
                onChange={e => updatePersonnelFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <CorePhoneField
              name="busPhone"
              label="Secondary"
              value={PersonnelFormData.busPhone}
              onChange={e => updatePersonnelFormData(e)}
            />
            </Grid>
            <Grid item md={6} xs={12}>
              <CoreTextField  
                name="email"
                label="E-mail"
                value={PersonnelFormData.email}
                onChange={e => updatePersonnelFormData(e)}
                isRequired={false}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PersonnelCreateDialogContent;