import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getList } from './ProvinceService';

const ProvinceField = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [provinceList, setProvinceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getProvinceList() {
      const result = await getList();
      setProvinceList(result);
      setIsLoading(false);
    }
    setIsLoading(true);
    getProvinceList();
  }, []);

  // TODO: add logic for SMS Philippines Province
  const onProvinceSelected = newValue => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue? newValue.Name : ''
      }
    };
    onChange(dispatchEvent);
  }; 

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const province = provinceList
      .find(province => province.Name.trim() === value.trim());
      if (province) {
        return province.Name;
      }
      return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onProvinceSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={provinceList}
      {...rest}
    />
  );

}

ProvinceField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default ProvinceField;