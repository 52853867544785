import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconOrder from '../icon/IconOrder';

const NavigationDrawerListItemOrder = () => {
  return (
    <NavigationDrawerListItem
      title={'Orders'}
      icon={<IconOrder />}
      uri={'/spark/order'} />
  );

}

export default NavigationDrawerListItemOrder;