import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import IconCheck from "@material-ui/icons/Check";
import IconOnHold from '../icon/IconOnHold';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dangerAvatar: {
    backgroundColor: '#E57373',
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  warningAvatar: {
    backgroundColor: '#FFB74D',
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  successAvatar: {
    backgroundColor: '#81C784',
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  infoAvatar: {
    backgroundColor: '#0288D1',
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  icon: {
    filter: 'invert(1)',
  }
}));

const IntermodalInvoiceBatchGroupStatus = (props) => {
  const classes = useStyles();
  const dangerTitle = 'Related orders are still awaiting completion.';
  const warningTitle = 'Related orders have not been selected for invoicing.';
  const successTitle = 'Ready to invoice!';
  const infoTitle = 'Related orders are ready for billing, but have not been selected for invoicing.';
  const endTitle = 'Click for more context.';

  const {
    invoice
  } = props;

  const handleClick = () => {
    if (invoice.BatchInvoiceStatus === 1 || invoice.BatchInvoiceStatus === 3) {
      if (invoice.BatchInvoiceMethod === 'Reference Number') {
        window.location = '/spark/intermodal-invoice?referenceNumber=' + invoice.ReferenceNumber + '&customer=' + invoice.Customer + '&ID=' + invoice.CustomerID;
      }
      if (invoice.BatchInvoiceMethod === 'Master BL/Booking Number') {
        window.location = '/spark/intermodal-invoice?bookingNum=' + invoice.BookingNum + '&customer=' + invoice.Customer + '&ID=' + invoice.CustomerID;
      }
    } else {
      if (invoice.BatchInvoiceMethod === 'Reference Number') {
        window.open('/spark/order?referenceNumber=' + invoice.ReferenceNumber + '&customer=' + invoice.Customer + '&ID=' + invoice.CustomerID);
      }
      if (invoice.BatchInvoiceMethod === 'Master BL/Booking Number') {
        window.open('/spark/order?bookingNum=' + invoice.BookingNum + '&customer=' + invoice.Customer + '&ID=' + invoice.CustomerID);
      }
    }
  };

  const getEndTitle = () => {
    return '(' + invoice.BatchInvoiceMethod + ') ' + endTitle;
  };

  return (
    <>
      {invoice.ResetStatus && <CircularProgress size={25} />}
      { // Danger - orders are still not completed/outside of invoicing area
        (invoice.BatchInvoiceStatus === 0) && !invoice.ResetStatus &&
        <Tooltip
          title={
            <div>
              {dangerTitle}<br />
              {getEndTitle()}
            </div>
          }>
          <Avatar
            className={classes.dangerAvatar}
            onClick={handleClick}>
            <div className={
              classes.icon
            }>
              <IconOnHold />
            </div>
          </Avatar>
        </Tooltip>
      }
      { // Warning - orders are completed but not part of selected batch
        (invoice.BatchInvoiceStatus === 1) && !invoice.ResetStatus &&
        <Tooltip
          title={
            <div>
              {warningTitle}<br />
              {getEndTitle()}
            </div>
          }>
          <Avatar
            className={classes.warningAvatar}
            onClick={handleClick}>
            <div>
              <PriorityHigh fontSize="small" />
            </div>
          </Avatar>
        </Tooltip>
      }
      { // Success - good to go
        (invoice.BatchInvoiceStatus !== 0 && invoice.BatchInvoiceStatus !== 1 && invoice.BatchInvoiceStatus !== 3) &&
        !invoice.ResetStatus &&
        <Tooltip
          title={
            <div>
              {successTitle}<br />
              {getEndTitle()}
            </div>
          }>
          <Avatar
            className={classes.successAvatar}>
            <div>
              <IconCheck fontSize="small" />
            </div>
          </Avatar>
        </Tooltip>
      }
      { // Info - all orders in that group are in the ready for billing state, until all are checked off
        (invoice.BatchInvoiceStatus === 3) && !invoice.ResetStatus &&
        <Tooltip
          title={
            <div>
              {infoTitle}<br />
              {getEndTitle()}
            </div>
          }>
          <Avatar
            className={classes.infoAvatar}
            onClick={handleClick}>
            <div>
              <span style={{color:"white"}}>i</span>
            </div>
          </Avatar>
        </Tooltip>
      }
    </>
  );

};

IntermodalInvoiceBatchGroupStatus.propTypes = {
  invoice: PropTypes.object
};

export default IntermodalInvoiceBatchGroupStatus;