import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import IconContacts from '../icon/IconContacts';
import IconEmergency from '../icon/IconEmergency';
import IconHealth from '../icon/IconHealth';
import IconDateCal from '../icon/IconDateCal';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const PersonnelViewSummaryEmployeeInfoSection = (props) => {
  const classes = useStyles();

  const {
    personnel
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="EMPLOYEE INFORMATION" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Start Date"
              value={personnel.StartDate ? personnel.StartDate.split("T")[0] : ''}
              icon={<IconDateCal />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="End Date"
              value={personnel.EndDate ? personnel.EndDate.split("T")[0] : 'On Going'}
              icon={<IconDateCal />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Gender"
              value={personnel.Gender || ''}
              icon={<IconContacts />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Emergency Contact"
              value={[personnel.EmergencyContact || "", ' - ', <CoreIntlPhoneOutput value={personnel.EmergencyNumber || ''} />]}
              icon={<IconEmergency />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Health Insurance Number"
              value={personnel.HealthNum || ''}
              icon={<IconHealth />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonnelViewSummaryEmployeeInfoSection;
