import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useDropzone } from 'react-dropzone';
import { addDocument } from '../order/OrderService';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const DocumentDropzone = (props) => {
  const {
    orderID,
    getDocuments,
    setInProgress
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(acceptedFiles => {
    saveDocuments(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: '.pdf, image/jpeg, image/png',
    maxSize: 20000000,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const saveDocuments = async (files) => {
    setInProgress(true);
    var uploadErrors = '';
    for (var file of files) {
      const result = await addDocument(orderID, file);
      if (result.error) {
        uploadErrors += result.error;
      }
    }
    setErrorMessage(uploadErrors);
    getDocuments();
    setInProgress(false);
  };

  return (

      <Container maxWidth="lg" style={{padding:'20px'}}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {
            <p style={{ textAlign: 'center', fontSize: 'small' }}>click here to upload or<br />drag and drop file(s) here<br />20MB/file max</p>
          }
        </div>
        <div>
          {errorMessage && <Grid item md={12} xs={12} className="mt-3">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </div>
      </Container>
  );
}

DocumentDropzone.propTypes = {
  orderID: PropTypes.number.isRequired,
  getDocuments: PropTypes.func.isRequired
};

export default DocumentDropzone;