import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreDataGridList from '../core/CoreDataGridList';
import Validation from '../validation';
import Container from '@material-ui/core/Container';
import { downloadFile } from '../globalFunction';
import { format } from 'date-fns';
import { getList, exportList, setBillOnHold, setBillsToApproved, processIntermodalPayment, setBillsToPending, tagForBilling } from '../bill/BillService';
import IntermodalPaymentListStatus from './IntermodalPaymentListStatus';
import IntermodalPaymentFilterRow from './IntermodalPaymentFilterRow';
import OrderViewSummary from '../order/OrderViewSummary';

import { columns } from './IntermodalPaymentListColumnService';

const IntermodalPaymentListTable = () => {

  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("settlementListAmount")) || 10,
    count: parseInt(localStorage.getItem("settlementListAmount")) || 10,
    isASC: false,
    sortBy: 'BillDate',
    filters: [],
    BillDate: format(new Date(), 'MM/dd/yyyy'),
    ShowPending: true,
    ShowOnHold: false,
    ShowApproved: false,
    ShowCompleted: false
  });

  let history = useHistory();
  const validation = new Validation();
  const location = useLocation();
  const onCloseRoute = location.pathname + location.search;

  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(true);
  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [isLegInfoDrawerOpen, setIsLegInfoDrawerOpen] = useState(false);
  const [settlements, setSettlements] = useState([]);
  const [bills, setBills] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
    PersonnelID: null
  });

  const updateFilterData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    filterData[name] = value;
    filterData[nameData] = valueData;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const updateParams = (e) => { 
    const { name, value } = e.target;
    params[name] = value;
    setParams(params);
    getSettlementList();
  };

  const updateStatusButtonChange = e => {
    const { name } = e.target;
    switch (name) {
      case 'ShowPending':
        params.ShowPending = true;
        params.ShowOnHold = false;
        params.ShowApproved = false;
        params.ShowCompleted = false
        break;
      case 'ShowOnHold':
        params.ShowPending = false;
        params.ShowOnHold = true;
        params.ShowApproved = false;
        params.ShowCompleted = false;
        break;
      case 'ShowApproved':
        params.ShowPending = false;
        params.ShowOnHold = false;
        params.ShowApproved = true;
        params.ShowCompleted = false;
        break;
      case 'ShowCompleted':
        params.ShowPending = false;
        params.ShowOnHold = false;
        params.ShowApproved = false;
        params.ShowCompleted = true;
        break;
      default:
        break;
    }
    params.page = 1;
    setParams(params);
    setBills([]);
    getSettlementList();
  };
  
  useEffect(() => {
    buildURLSearchFilters();
    getSettlementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function buildURLSearchFilters() {
    const urlParams = new URLSearchParams(window.location.search);
    const listStatus = urlParams.get('listStatus');
    if (listStatus) {
      updateStatusButtonChange({target:{name: listStatus}});
    }
  };

  function changePage(page) {
    params.page = page + 1;
    getSettlementList();
    setParams(params);
  };

  function sort(sortSettlement) {
    params.page = 1;
    params.sortBy = sortSettlement.field;
    if (sortSettlement.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getSettlementList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    getSettlementList();
    setParams(params);
    localStorage.setItem("settlementListAmount", amount);
  };

  function pushParamFilter(filterName, value, operator) {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  }

  const resetFilterRow = () => {
    setParams(p => ({ ...p, filters: [], page: 1 }));
    setParams(params);
    setFilterData(c => ({ ...c, BillDate: null }));
    setFilterData(c => ({ ...c, PersonnelID: null }));
    getSettlementList();
  };

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }
    if (!validation.isEmpty(filterData.PersonnelID)) {
      pushParamFilter("PersonnelID", filterData.PersonnelID, 'Equals');
    }
    params.page = 1;
    setParams(params);
    getSettlementList();
  };

  const closeLegInfoDrawer = () => {
    setIsLegInfoDrawerOpen(false);
  };

  const onSettlementRowEnter = (params) => {
    for (const settlement of settlements) {
      if (settlement.PayableChargeID === params.row.PayableChargeID) {
        settlement.ShowControls = true;
      }
    }
    setSettlements([ ...settlements ]);
  };

  const onSettlementRowLeave = (params) => {
    for (const settlement of settlements) {
      if (settlement.PayableChargeID === params.row.PayableChargeID) {
        settlement.ShowControls = false;
      }
    }
    setSettlements([...settlements]);
  };

  function appendBills(latestBills) {
    var billsTagged = [];
    for (const bill of latestBills) { 
      if (bill.TagForBilling === true) {
        billsTagged.push(bill);
      }
    }
    setBills(billsTagged);
  };

  const formatParameters = () => { 
    params.BillDate = params.BillDate ? format(new Date(params.BillDate), 'MM/dd/yyyy') : null;
    setParams(params);
  };

  const getSettlementList = async () => {
    setInProgress(true);
    if (!params.BillDate) {
      setInProgress(false);
      return;
    }
    formatParameters();
    const result = await getList(params);
    setSettlements(result);
    if (result.length > 0) {
      setParams(c => ({ ...c, count: result[0].TotalRows }));
    } else {
      setParams(c => ({ ...c, count: 0 }));
    }
    appendBills(result);
    setInProgress(false);
  };

  const exportSettlementList = async () => {
    const result = await exportList(params);
    downloadFile(result, "settlements-export.csv");
  };

  const putBillOnHold = async (event, billID) => { 
    const {value} = event.target;
    await setBillOnHold(billID, value);
    // clears toggles when switching Filters
    const index = settlements.findIndex(item => item.BillID === billID);
    settlements[index].HoldPayment = value;
    setSettlements(settlements);
    getSettlementList();
  };

  const markPending = async () => { 
    if (params.ShowApproved) {
      processPendingSettlements();
    }
  };

  const processPendingSettlements = () => {
    var billIDs = [];
    bills.forEach(
      bill => {
        billIDs.push(bill.BillID);
      }
    );
    setPendingSettlements(billIDs);
  };

  const setPendingSettlements = async (billIDs) => { 
    setBillsToPending(billIDs)
      .then(function (response) {
        setBills([]);
        getSettlementList();
      });
  };

  const processSetApprovedSettlements = () => {
    var billIDs = [];
    bills.forEach(
      bill => {
        billIDs.push(bill.BillID);
      }
    );
    setApprovedSettlements(billIDs);  
  };

  const setApprovedSettlements = async (billIDs) => { 
    setBillsToApproved(billIDs)
      .then(function (response) {
        setBills([]);
        getSettlementList();
      });
  };

  const tagBillForBilling = async (event, bill) => { 
    const {value} = event.target;
    if (value) { 
      setBills([...bills, bill]);
    } else {
      setBills(bills.filter(item => item !== bill));
    }
    // Clears checkboxes when switching Filters
    const index = settlements.findIndex(item => item.BillID === bill.BillID);
    settlements[index].TagForBilling = value;
    setSettlements(settlements);
    await tagForBilling(bill.BillID, value);
    
  };

  const processPrintStatements = () => { 
    var personnelPaymentObj = { 
      PayDate: params.BillDate,
      DatePrinted: params.BillDate,
      Note: '',
      ProcessPayments: false,
      Bills: []
    };
    bills.forEach(
      bill => { 
        var obj = {};
        obj.BillID = bill.BillID;
        obj.PersonnelID = bill.PayToPersonnelID;
        obj.BranchID = bill.BranchID;
        obj.Amount = bill.TotalAmount ? bill.TotalAmount : 0;
        personnelPaymentObj.Bills.push(obj);
      }
    );
    setCompletedSettlements(personnelPaymentObj);
  };

  const processCompleteSettlements = () => {
    var personnelPaymentObj = { 
      PayDate: params.BillDate,
      DatePrinted: params.BillDate,
      Note: '',
      ProcessPayments: true,
      Bills: []
    };
    bills.forEach(
      bill => { 
        var obj = {};
        obj.BillID = bill.BillID;
        obj.PersonnelID = bill.PayToPersonnelID;
        obj.BranchID = bill.BranchID;
        obj.Amount = bill.TotalAmount ? bill.TotalAmount : 0;
        personnelPaymentObj.Bills.push(obj);
      }
    );
    setCompletedSettlements(personnelPaymentObj);
  };

  const setCompletedSettlements = async (personnelPaymentsObj) => { 
    processIntermodalPayment(personnelPaymentsObj)
      .then(function (response) { 
        downloadFile(response, 'payment-documents.zip');
        setBills([]);
        getSettlementList();
      });
  };

  const openRecord = (id) => {
    history.push({
      pathname: '/spark/order/view/' + id,
      state: {
        onCloseRoute: onCloseRoute,
      }
    });
  };

  const openSlideout = (id) => {
    setCurrentRowClicked(c => ({ ...c, IntermodalOrderID: id}));
    setIsLegInfoDrawerOpen(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Container maxWidth={listWidth} className="mt-1">
        {<IntermodalPaymentListStatus 
          settlementStatuses={params}
          settlements={settlements}
          updateStatusButtonChange={updateStatusButtonChange}
        />}
      </Container>
      {<IntermodalPaymentFilterRow
        filterData={filterData}
        updateFilterData={updateFilterData}
        resetFilterRow={resetFilterRow}
        setApprovedSettlements={processSetApprovedSettlements}
        completeSettlements={processCompleteSettlements}
        markPending={markPending}
        parameters={params}
        updateParams={updateParams}
        getSettlements={getSettlementList}
        settlements={bills}
        printStatements={processPrintStatements}
        listWidth={listWidth}
      />}
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns(params, openRecord, openSlideout, getSettlementList, putBillOnHold, tagBillForBilling)}
          data={settlements}
          params={params}
          exportList={exportSettlementList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='desc'
          defaultSortBy='BillDate'
          filterData={filterData}
          updateFilterData={updateFilterData}
          onRowEnter={e => onSettlementRowEnter(e)}
          onRowLeave={e => onSettlementRowLeave(e)}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
      {<OrderViewSummary
        orderID={currentRowClicked.IntermodalOrderID} 
        isOpen={isLegInfoDrawerOpen} 
        onClose={closeLegInfoDrawer}
        onCloseRoute={onCloseRoute}
      />}
    </div>
  )
};

export default IntermodalPaymentListTable;