import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Drawer, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import CoreSummaryHeader from '../core/CoreSummaryHeader';
import CoreExitButton from '../core/CoreExitButton';
import ItineraryAssignFooter from './ItineraryAssignFooter';
import { getUnAssignedLegs } from './ItineraryService';
import { getChassisUnitNumber, getOriginInternalName, getDestinationInternalName } from './ItineraryUtils';

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
  },
  cardPaper: {
    height: '120px',
    width: '90%',
    margin: '0 auto',
    backgroundColor: '#f8f6f3',
    cursor: 'pointer'
  },
  underText: {
    fontWeight: 'bold',
  }
}));

const ItineraryAssign = (props) => {
  const classes = useStyles();
  const {isOpen, onClose, assignLeg} = props;

  const [inProgress, setInProgress] = useState(false);
  const [legClick, setLegClick] = useState({});
  const [unassignedLegs, setUnassignedLegs] = useState([]);

  useEffect(() => {
    getLegs();
  }, []);

  const getLegs = async () => {
    setInProgress(true);
    const response = await getUnAssignedLegs();
    setUnassignedLegs(response.response);
    setInProgress(false);
  };

  const handleLegClick = (leg) => {
    if (legClick.ID && legClick.ID !== leg.ID) {
      return;
    }
    if (legClick.ID === leg.ID) {
      setLegClick({});
      return;
    }
    setLegClick(leg);
  };

  const onCloseAssign = () => {
    setLegClick({});
    onClose();
  };

  const assignLegToDriver = async () => {
    await assignLeg(legClick);
    setLegClick({});
    getLegs();
  };


  return (
    <Drawer
      anchor={'right'} 
      open={isOpen}
      ModalProps={{onBackdropClick: onClose }}
      classes={{ paper: classes.paper }}
    >
      {<CoreSummaryHeader
        title={"Assign Leg"}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />}
      />}

      <br></br>
      <br></br>
      {inProgress && <div><LinearProgress /></div>}
      { unassignedLegs && unassignedLegs.map((leg, index) => (
        <Paper
          key={index}
          className={`${classes.cardPaper} mb-2`}
          onClick={() => handleLegClick(leg)}
        >
          <div style={{margin: '10px'}}>
            <Grid container>
              <Grid container xs={11}>
                <Grid container item>
                  <Grid item xs={6}>
                    <Typography variant='body2' className={classes.underText}>Leg {(leg.LegIndex + 1)}</Typography> 
                    <Typography variant='body2'>Order {leg.IntermodalOrder.OrderNum}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>Container Number</Typography>
                    <Typography noWrap variant='body2' className={classes.underText}>{getChassisUnitNumber(leg)}</Typography>
                  </Grid>
                </Grid>
                <Grid container item className='mt-2'>
                  <Grid item xs={6}>
                    <Typography variant='body2'>Origin</Typography>
                    <Typography noWrap variant='body2'>{getOriginInternalName(leg)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>Destination</Typography>
                    <Typography noWrap variant='body2'>{getDestinationInternalName(leg)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={1}>
                <Checkbox
                  checked={leg.ID === legClick.ID ? true : false}
                  color='primary'
                  style={{height: '24px'}}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      ))}
      {
        <ItineraryAssignFooter leg={legClick} onClose={onCloseAssign} assignLeg={assignLegToDriver}/>
      }
    </Drawer>
  )
};

export default ItineraryAssign;
