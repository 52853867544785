import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import CoreNumberField from '../core/CoreNumberField';
import CoreToggleField from '../core/CoreToggleField';

const ReceivableChargeTypeEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    receivableChargeTypeFormData,
    onUpdate,
    errorMessage,
    receivableChargeTypeFormValidation,
    updateReceivableChargeTypeFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Revenue Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="Name"
                label="Name"
                value={receivableChargeTypeFormData.Name}
                onChange={e => updateReceivableChargeTypeFormData(e)}
                isRequired={true}
                validationError={receivableChargeTypeFormValidation.Name}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="DescriptionDefault"
                label="Description"
                value={receivableChargeTypeFormData.DescriptionDefault}
                onChange={e => updateReceivableChargeTypeFormData(e)}
                isRequired={true}
                validationError={receivableChargeTypeFormValidation.DescriptionDefault}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="UnitsDefaultValue"
                label="Unit Default"
                value={receivableChargeTypeFormData.UnitsDefaultValue}
                onChange={e => updateReceivableChargeTypeFormData(e)}
                isRequired={true}
                validationError={receivableChargeTypeFormValidation.UnitsDefaultValue}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="UnitRateDefaultValue"
                label="Unit Rate Default"
                value={receivableChargeTypeFormData.UnitRateDefaultValue}
                onChange={e => updateReceivableChargeTypeFormData(e)}
                isRequired={true}
                validationError={receivableChargeTypeFormValidation.UnitRateDefaultValue}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreToggleField
                name="IsFSC"
                label="FSC Type"
                value={receivableChargeTypeFormData.IsFSC = receivableChargeTypeFormData.IsFSC ? 1 : 0}
                onChange={e => {
                  e.target.value = e.target.value ? 1 : 0;
                  updateReceivableChargeTypeFormData(e)
                }}
                isRequired={true}
                validationError={receivableChargeTypeFormValidation.IsFSC}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default ReceivableChargeTypeEditDialogContent;