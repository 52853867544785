import { Route } from 'react-router-dom';
import IntermodalInvoiceList from './IntermodalInvoiceList';
import IntermodalInvoiceView from './IntermodalInvoiceView';

const IntermodalInvoiceRouter = () => {
  return (
    <>
      <Route exact path="/spark/intermodal-invoice" component={IntermodalInvoiceList} />    
      <Route exact path="/spark/intermodal-invoice/view/:id" component={IntermodalInvoiceView} />
    </>
  );

}

export default IntermodalInvoiceRouter;