import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as EmergencySvg } from '../assets/svg-icons/emergency@1x.svg'

const IconEmergency = () => {
  return (
    <SvgIcon fontSize="small">
      <EmergencySvg />
    </SvgIcon>
  )
}

export default IconEmergency;