import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'IntermodalOrderEquipment/' + token + '/';

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  return result.response;
};

export async function create(orderEquipmentFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', orderEquipmentFormData);
  return result;
};

export async function update(equipmentFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', equipmentFormData);
  return result;
};

export async function deleteOrderEquipment(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};