import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { intermodalOrderCreate as Create } from './PayableService';
import PayableCreateDialogContent from './PayableCreateDialogContent';
import { getTaxTypeIDNone } from '../configuration/ConfigurationService';
import { getPayableItem } from '../payable_item/PayableItemService';

const PayableCreateDialog = (props) => {
  const {
    originID,
    intermodalLegID,
    driverID,
    isOpen,
    onClose,
    defaultPayableChargeTypeID,
    defaultPayableChargeTypeName,
  } = props;

  const initialState = { 
    payableChargeID: 0,
    payableChargeTypeID: defaultPayableChargeTypeID ? defaultPayableChargeTypeID : null,
    payableChargeTypeName: defaultPayableChargeTypeName ? defaultPayableChargeTypeName : '',
    sourcePayableChargeID: null,
    billID: 0,
    indexForBill: 0,
    equipmentID: null,
    descriptionEdited: 0,
    description: '',
    classEdited: 0,
    classID: null,
    units: 1,
    unitsEdited: 0,
    unitRate: 0,
    unitRateEdited: 0,
    discountPercent: 0,
    subtotal: 0,
    tax1TypeID: 0,
    tax1Edited: 0,
    tax1: 0,
    tax2TypeID: 0,
    tax2Edited: 0,
    tax2: 0,
    aeRelatedDPCTID: null,
    relatedFuelStopID: null,
    deleted: 0, 
    driverID: driverID,
  };

  const [payableFormData, setPayableFormData] = useState(initialState);
  const [payableFormValidation, setPayableFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setPayableFormValidation(buildValidation(payableFormData));
    }
  }, [payableFormData, isOpen]);

  useEffect(() => {
    async function getDefaultTaxTypes() {
      const taxType = await getTaxTypeIDNone();
      setPayableFormData(p => (
        { ...p, tax1TypeID: taxType, tax2TypeID: taxType,
          payableChargeTypeID: defaultPayableChargeTypeID ? defaultPayableChargeTypeID : null,
          payableChargeTypeName: defaultPayableChargeTypeName ? defaultPayableChargeTypeName : '' }
      ));
    }
    getDefaultTaxTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => { 
    setPayableFormData(p => ({ ...p, driverID: driverID }));
  }, [driverID]);

  useEffect(() => {
    setPayableFormData(p => (
      { ...p, description: payableFormData.payableChargeTypeName }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payableFormData.payableChargeTypeID]);

  const updatePayableFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    payableFormData[name] = value;
    payableFormData[nameData] = valueData;
    setPayableFormData({ ...payableFormData });
    updatePayableItemDefaults(nameData);
  };
  
  const updatePayableItemDefaults = async (name) => {
    if (payableFormData.payableChargeTypeID && name === "payableChargeTypeID") {
      const payableItem = await getPayableItem(payableFormData.payableChargeTypeID, intermodalLegID);
      setPayableFormData(p => ({ ...p, units: payableItem.UnitsDefaultValue}));
      setPayableFormData(p => ({ ...p, unitRate: payableItem.UnitRateDefaultValue }));
      setPayableFormData(s => ({...s, subtotal: payableItem.UnitsDefaultValue * payableItem.UnitRateDefaultValue}));
    } else {
      updateSubtotal();
    }
  };

  const updateSubtotal = () => {
    setPayableFormData(s => ({...s, subtotal: (payableFormData.units * payableFormData.unitRate)}));
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(payableFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await Create(payableFormData, originID, intermodalLegID);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => { 
    setPayableFormData(initialState);
    props.onClose();
  };

  return (
    isOpen &&
    <PayableCreateDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      payableFormData={payableFormData}
      payableFormValidation={payableFormValidation}
      updatePayableFormData={updatePayableFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (payableFormData) => {
  const {
    payableChargeTypeID,
    driverID
  } = payableFormData || {};
  const validation = new Validation();
  return {
    payableChargeTypeID: validation.isNumber(payableChargeTypeID),
    driverID: validation.isNumber(driverID)
  };
};

export default PayableCreateDialog;