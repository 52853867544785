import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import AuthenticationPage from './AuthenticationPage';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthenticationCreditCardContent from './AuthenticationCreditCardContent';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AuthenticationCreditCard = () => {

  const [companyID, setCompanyID] = useState(null);
  const [username, setUsername] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
    const urlParams = new URLSearchParams(window.location.search);
    const companyIDParam = urlParams.get('companyID');
    const usernameParam = urlParams.get('username');
    if (companyIDParam && usernameParam) {
      setCompanyID(companyIDParam);
      setUsername(usernameParam);
    } else {
      setErrorMessage('Invalid parameters supplied. Looks like you do not belong on this page');
    }
  }, []);

  const creditCardForm =
    <div>
      <Typography variant="h5" gutterBottom className="mb-4">
        Payment Method
      </Typography>
      <Grid container>
        <Elements stripe={stripePromise}>
          {!errorMessage &&
            <AuthenticationCreditCardContent
              companyID={companyID}
              username={username}
            />
          }
        </Elements>
        { errorMessage && 
          <Alert variant="outlined" severity="error" className="mt-2">
            {errorMessage}
          </Alert>
        }
      </Grid>
    </div>

  return (
    <AuthenticationPage form={creditCardForm} />
  );

}

export default AuthenticationCreditCard;