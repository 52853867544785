import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DateCalSolidSvg } from '../assets/svg-icons/datecalsolid@1x.svg';

const IconDateCalSolid = () => {
  return (
    <SvgIcon fontSize="small">
      <DateCalSolidSvg />
    </SvgIcon>
  )
}
export default IconDateCalSolid;