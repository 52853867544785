import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconItinerary from '../icon/IconItinerary';

const NavigationDrawerListItemItinerary = () => {
  return (
    <NavigationDrawerListItem
      title={'Itinerary'}
      icon={<IconItinerary />}
      uri={'/spark/itinerary'} />
  );

}

export default NavigationDrawerListItemItinerary;