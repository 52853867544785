import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const ReportsDateFilterTypeField = (props) => { 
  const { 
    label,
    value,
    name,
    onChange
  } = props;

  const [optionSelected, setOptionSelected] = useState(value);

  const onSelectEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(dispatchEvent);
  };

  const handleChange = (event) => {
    onSelectEvent(event.target.value)
    setOptionSelected(event.target.value);
  };

  return ( 
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
          value={optionSelected}
          onChange={handleChange}
        >
          <MenuItem value={0}>Created Date</MenuItem>
          <MenuItem value={1}>Pickup Date</MenuItem>
          <MenuItem value={2}>Delivery Date</MenuItem>
          <MenuItem value={3}>Invoice Date</MenuItem>
        </Select>
    </FormControl>
  );
}

export default ReportsDateFilterTypeField;