import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles(() => ({
    iconText: {
      fontSize: '0.875rem',
    },
}));

const CoreFavoriteButtonDisplayField = (props) => {
  const { label, value, onClick } = props;
  const classes = useStyles();

  return (
    <>
      <IconButton onClick={onClick}>
        { value ? <StarIcon/> : <StarBorderIcon/> }
        <span className={classes.iconText}>{label}</span>
      </IconButton>
    </>
  );
}

CoreFavoriteButtonDisplayField.propTypes = {
  value: PropTypes.bool
};

export default CoreFavoriteButtonDisplayField;