import React from 'react';
import { FormControlLabel, Switch, Typography } from "@material-ui/core";

const ConfigurationCompanyDriver = (props) => {
  const { inProgress, configuration, handleConfigChange } = props;
  return (
    <>
      <Typography variant='subtitle1'>Company Driver:</Typography>
      {Object.values(configuration).map((config, i) => {
        if (config.name !== "acceptLoads" && config.name !== "rejectLoads")
          return (
            <div key={i}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={inProgress}
                    checked={
                      (config.value &&
                        (config.role === null || config.role === "company-driver")) ||
                      (!config.value && config.role === "owner-operator")
                    }
                    onChange={handleConfigChange}
                    name={config.name}
                  />
                }
                label={config.displayName}
                labelPlacement="end"
                value="company-driver"
              />
            </div>
          );
          return <div key={i}></div>;
        })
      }
    </>
  )
}

export default ConfigurationCompanyDriver