import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'PayableChargeType/' + token + '/';

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  return result.data[0];
};

export async function getList(payableChargeTypeID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'getList');
  var response = result.data;
  if (payableChargeTypeID !== null) {
    for(var i = 0; i < response.length; i++) { 
      if (response[i].PayableChargeTypeID === payableChargeTypeID) {
        response.splice(i, 1);
        break;
      }
    }
  }
  return response;
};

export async function getRCTList(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetRCTs');
  return result.data;
};

export async function getPCTList(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetPCTs');
  return result.data;
};

export async function deleteRCTs(id) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/DeleteRCTs');
  return result;
};

export async function deletePCTs(id) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/DeletePCTs');
  return result;
};

export async function addRCT(payableChargeTypeID, receivableChargeTypeID) {
  var params = {
    receivableChargeTypeID: parseInt(receivableChargeTypeID),
    percentage: 1
  }; 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + parseInt(payableChargeTypeID) + '/AddRCT', null, params);
  return result;
};

export async function addPCT(payableChargeTypeID, relatedPayableChargeTypeID) { 
  var params = {
    relatedPayableChargeTypeID: parseInt(relatedPayableChargeTypeID),
    percentage: 1
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + parseInt(payableChargeTypeID) + '/AddPCT', null, params);
  return result;
};