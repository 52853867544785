import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { 
    getFull as personnelServiceGetFull, 
    getTravelDocs, 
    isLoginEnabled as adminServiceIsLoginEnabled,
    activate,
    deactivate,
    getVendor as personnelServiceGetVendor
} from '../personnel/PersonnelService';
import { isOwner as adminServiceIsOwner } from './AdminService';
import PersonnelViewPageHeader from './PersonnelViewPageHeader';
import PersonnelViewGeneralSection from '../personnel/PersonnelViewGeneralSection';
import PersonnelViewAddressSection from '../personnel/PersonnelViewAddressSection';
import PersonnelViewEmployeeInfoSection from './PersonnelViewEmployeeInfoSection';
import PersonnelViewDriverInfoSection from './PersonnelViewDriverInfoSection';
import PersonnelViewAutomaticPayableSection from './PersonnelViewAutomaticPayableSection';


const PersonnelView = () => {
  let history = useHistory();
  const { id } = useParams();

  const [personnel, setPersonnel] = useState({});
  const [vendor, setVendor] = useState({});
  const [personnelTravelDocsLoaded, setPersonnelTravelDocsLoaded] = useState(false);
  const [isLoginEnabled, setIsLoginEnabled] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAutoPaySectionOpen, setIsAutoPaySectionOpen] = useState(false);
  const [driverLicense, setDriverLicense] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 8,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );
  const [TWIC, setTWIC] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 18,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );

  useEffect(() => {
    getPersonnelRecord();
    getIsOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getIsOwner = async () => {
    const response = await adminServiceIsOwner();
    setIsOwner(response);
  };

  const getPersonnelRecord = () => {
    personnelServiceGetFull(id)
      .then(function (response) {
        response.StartDate = getDateWithoutTime(response.StartDate);
        response.EndDate = getDateWithoutTime(response.EndDate);
        setPersonnel(response);
        getPersonnelTravelDocs(id);
        getRelatedVendorRecord(response.RelatedVendorID);
        getIsLoginEnabled();
      });
  };

  const getRelatedVendorRecord = (relatedVendorID) => {
    personnelServiceGetVendor(relatedVendorID)
      .then(function (response) {
        response.VendorInternalName = response.InternalName;
        setVendor(response);
    });
  };

  const getIsLoginEnabled = async () => {
    const response = await adminServiceIsLoginEnabled(id);
    setIsLoginEnabled(response);
  };

  const getPersonnelTravelDocs = (id) => {
    getTravelDocs(id)
      .then(function (response) {
        formatTravelDocs(response);
        setPersonnelTravelDocsLoaded(true);
      });
  };

  const formatTravelDocs = (travelDocs) => {
    for (var i = 0; i < travelDocs.length; i++) {
      if (travelDocs[i].ExpiryDate) {
        travelDocs[i].ExpiryDate = getDateWithoutTime(travelDocs[i].ExpiryDate);
      }
      if (travelDocs[i].ACETravelDocType === 8) {
        setDriverLicense(travelDocs[i]);
      }
      if (travelDocs[i].ACETravelDocType === 18) {
        setTWIC(travelDocs[i]);
      }
    }
  };

  const getDateWithoutTime = (date) => {
    if (!date) {
      return;      
    }
    return date.substring(0, date.length - 9);
  };

  const activatePersonnel = () => { 
    activate(id)
      .then(function(response) { 
        setPersonnel(p => ({ ...p, Obsolete: false }));
      });
  };

  const deactivatePersonnel = () => { 
    deactivate(id)
      .then(function(response) {  
        setPersonnel(p => ({ ...p, Obsolete: true }));
      });
  };

  const onClose = () => {
    history.push('/spark/personnel');
  };

  function toggleIsAutoPaySectionOpen() {
    if (isAutoPaySectionOpen) {
      setIsAutoPaySectionOpen(false);
    } else {
      setIsAutoPaySectionOpen(true);
    }
  }

  return (
    <div>
      <PersonnelViewPageHeader
        personnel={personnel}
        isLoginEnabled={isLoginEnabled}
        isOwner={isOwner}
        onClose={onClose}
        refreshEvent={getPersonnelRecord}
        activatePersonnel={activatePersonnel}
        deactivatePersonnel={deactivatePersonnel}
      />
      <PersonnelViewGeneralSection
        personnel={personnel}
        isLoginEnabled={isLoginEnabled}
        isOwner={isOwner}
        refreshEvent={getPersonnelRecord}
      />
      <PersonnelViewAddressSection
        personnel={personnel}
        vendor={vendor}
      />
      <PersonnelViewEmployeeInfoSection
        personnel={personnel}
      />
      {personnelTravelDocsLoaded && personnel.ListAsDriver && 
      <Container maxWidth="lg" className="mt-4" component={Paper}>
        <PersonnelViewDriverInfoSection
          personnel={personnel}
          driverLicense={driverLicense}
          TWIC={TWIC}
        />
      </Container>
      }
      {personnelTravelDocsLoaded && personnel.ListAsDriver && <PersonnelViewAutomaticPayableSection
        personnel={personnel}
        setPersonnel={setPersonnel}
        isOpen={isAutoPaySectionOpen}
        toggleIsOpen={toggleIsAutoPaySectionOpen}
      />}
    </div>
  );
}

export default PersonnelView;