import React from 'react';
import { TableCell, TableSortLabel, Grid } from '@mui/material';

const TruckVisitSortRow = (props) => {
    const {
        headers,
        updateSortData,
        pagination
    } = props;

    return (
        <Grid container>
            <Grid item md={11} xs={12}>
                <Grid container>
                {headers.map((header) => (
                    <Grid item md={2} xs={4}>
                        <TableCell size='small'>
                            <TableSortLabel
                                active={pagination.sortBy === header.id}
                                direction={pagination.sortByDesc ? 'desc' : 'asc'}
                                onClick={() => updateSortData(header.id)}
                            >
                                <span>{header.label}</span>
                            </TableSortLabel>
                        </TableCell>
                    </Grid>
                    
                ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TruckVisitSortRow;