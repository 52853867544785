import React from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

const CoreDropdownField = (props) => {

  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    variant, 
    size,
    list,
    ...rest
  } = props;

  const optionsList = [];

  for (const option of list) {
    optionsList.push({"Name": option })
  }

  const onOptionSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const val = optionsList
      .find(item => item.Name === value);
    if (val) {
      return val.Name;
    }
    return '';
  }

  return (
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onOptionSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={optionsList}
      variant={variant}
      disableClearable={false}
      size={size}
      {...rest}
    />
  );

}

CoreDropdownField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
};

export default CoreDropdownField;