import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import CoreDataGridSearch from './CoreDataGridSearch';
import CoreDataGridPrint from './CoreDataGridPrint';
// import CoreDataGridFilter from './CoreDataGridFilter';
import CoreDataGridDownload from './CoreDataGridDownload';
// import CoreDataGridSavedSearch from './CoreDataGridSavedSearch';
// import CoreDataGridSortShowHide from './CoreDataGridSortShowHide';
import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';

const CoreDataGridToolbar = (props) => {
  const {
    exportList,
    filterData,
    updateFilterData,
    printTable,
    params,
    showInactive,
    hideInactive,
    showObsoleteButton,
    disableExportList
  } = props;


  const [searchTerms, setSearchTerms] = useState({
    target:{
      name:'Search',
      value:''
    }
  });
  
  useEffect(() => {
    if (filterData.Search !== searchTerms.target.value) {
      const timeOutId = setTimeout(() => updateFilterData(searchTerms), 500);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <CoreDataGridSearch
            name="Search"
            value={searchTerms.target.value}
            onChange={e => setSearchTerms(e)}
          />
        </Grid>
        {showObsoleteButton && <Grid item md={1} xs={1} className={"mt-2"}>
          {!params.showObsolete && <Button
            onClick={showInactive}
            color="primary"
          >
            <span>SHOW INACTIVE</span>
          </Button>}
          {params.showObsolete && <Button
            onClick={hideInactive}
            color="primary"
          >
            <span>HIDE INACTIVE</span>
          </Button>}
        </Grid>}
        {!showObsoleteButton && <Grid item md={1} xs={1}></Grid>}
        <Grid item md={8} xs={5}>
          <DialogActions style={{ justifyContent: 'flex-end' }}>
            {/* <CoreDataGridFilter columns={columns} /> */}
            {/* <CoreDataGridSavedSearch /> */}
            {/* <CoreDataGridSortShowHide columns={columns} setColumns={setColumns} /> */}
            {!disableExportList && <CoreDataGridDownload onClick={exportList} />}
            <CoreDataGridPrint onClick={printTable} />
          </DialogActions>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CoreDataGridToolbar;