import React, { useEffect, useState } from 'react';
import CustomerCreateContentInfo from './CustomerCreateContentInfo';
import CustomerCreateContentAddress from './CustomerCreateContentAddress';
import CustomerCreateContentBillAddress from './CustomerCreateContentBillAddress';
import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import Alert from '@material-ui/lab/Alert';

import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';
const CustomerCreateContent = (props) => {
  const {
    customerFormData,
    onCreate,
    onClose,
    errorMessage,
    customerFormValidation,
    updateCustomerFormData,
    addressCoordinates,
    billAddressCoordinates,
    inProgress
  } = props;

  const [branch, setBranch] = useState('');
  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };

  return (
    <form onSubmit={onCreate}>
      <CustomerCreateContentInfo
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
      />
      <br/>
      <CustomerCreateContentAddress
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
        coordinates={addressCoordinates}
        branch={branch}
      />
      <br/>      
      <CustomerCreateContentBillAddress
        customerFormData={customerFormData}
        customerFormValidation={customerFormValidation}
        updateCustomerFormData={updateCustomerFormData}
        coordinates={billAddressCoordinates}
        branch={branch}
      />
      {
        errorMessage && 
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br/>}
      {!inProgress && <CoreCreateContentStickyFooterButtons onClose={onClose}/>}
    </form>
  );
}

export default CustomerCreateContent;