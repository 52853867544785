import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import IconLocationLight from '../icon/IconLocationLight';
import IconCity from '../icon/IconCity';
import IconState from '../icon/IconState';
import IconZipCode from '../icon/IconZipCode';
import IconCountry from '../icon/IconCountry';
import IconPhone from '../icon/IconPhone';

import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreToggleDisplayField from '../core/CoreToggleDisplayField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import { getGeocode } from '../mileage/MileageService';

const CustomerViewAddressSection = (props) => {
  const {
    customer
  } = props;
  const [addressCoordinates, setAddressCoordinates] = useState({'lat': 0, 'lng': 0});
  const [billAddressCoordinates, setBillAddressCoordinates] = useState({'lat': 0, 'lng': 0});

  useEffect(() => {
    getGeocode(
      customer.CustAddress, 
      customer.CustCity, 
      customer.CustProvince, 
      customer.CustCountry, 
      customer.CustPostalCode) 
      .then(function(response) {
        setAddressCoordinates(response);
     });
    if (!customer.BillAddressSame) {
      getGeocode(
        customer.BillAddress, 
        customer.BillCity, 
        customer.BillProvince, 
        customer.BillCountry, 
        customer.BillPostalCode) 
        .then(function(response) {
          setBillAddressCoordinates(response);
       });
    }
  }, [customer]);

  return (
    <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>    
          <Grid item md={12} xs={12}>
            <h4>Physical Address</h4>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CoreTextDisplayField
                label="Address"
                value={customer.CustAddress}
                icon={<IconLocationLight />}
              />
            </Grid>
            {customer.CustCountry !== "Philippines" && <>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="City"
                  value={customer.CustCity}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="State / Province"
                  value={customer.CustProvince}
                  icon={<IconState />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Zip / Postal Code"
                  value={customer.CustPostalCode}
                  icon={<IconZipCode />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Country"
                  value={customer.CustCountry}
                  icon={<IconCountry />}
                />
              </Grid>
            </>}
            {customer.CustCountry === "Philippines" && <>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="District"
                  value={customer.CustDistrict}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Barangay"
                  value={customer.CustCounty}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Zip / Postal Code"
                  value={customer.CustPostalCode}
                  icon={<IconZipCode />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="City"
                  value={customer.CustCity}
                  icon={<IconCity />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="State / Province"
                  value={customer.CustProvince}
                  icon={<IconState />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Country"
                  value={customer.CustCountry}
                  icon={<IconCountry />}
                />
              </Grid>
            </>}
            <Grid item xs={6}>
              <CoreTextDisplayField
                label="Primary Number"
                value={customer.CustPhone}
                icon={<IconPhone />}
                isPhoneNum={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CoreTextDisplayField
                label="Secondary Number"
                value={customer.CustTollFree}
                icon={<IconPhone />}
                isPhoneNum={true}
              />
            </Grid>
            {customer.BillAddressSame && <>
              <Grid item xs={12} className="mb-2">
                <CoreToggleDisplayField
                  label="Same as Billing"
                  value={customer.BillAddressSame}
                />
              </Grid>
            </>}
            {/* Billing Address section */}
            {!customer.BillAddressSame && <>
              <Grid item md={12} xs={12}>
                <h4>Billing Address</h4>
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Address"
                  value={customer.BillAddress}
                  icon={<IconLocationLight />}
                />
              </Grid>
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label=""
                  value=""
                />
              </Grid>
              {customer.BillCountry !== "Philippines" && <>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="City"
                    value={customer.BillCity}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="State / Province"
                    value={customer.BillProvince}
                    icon={<IconState />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Zip / Postal Code"
                    value={customer.BillPostalCode}
                    icon={<IconZipCode />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Country"
                    value={customer.BillCountry}
                    icon={<IconCountry />}
                  />
                </Grid>
              </>}
              {customer.BillCountry === "Philippines" && <>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="District"
                    value={customer.BillDistrict}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Barangay"
                    value={customer.BillCounty}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Zip / Postal Code"
                    value={customer.BillPostalCode}
                    icon={<IconZipCode />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="City"
                    value={customer.BillCity}
                    icon={<IconCity />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="State / Province"
                    value={customer.BillProvince}
                    icon={<IconState />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextDisplayField
                    label="Country"
                    value={customer.BillCountry}
                    icon={<IconCountry />}
                  />
                </Grid>
              </>}
              <Grid item xs={6}>
                <CoreTextDisplayField
                  label="Primary Number"
                  value={customer.BillPhone}
                  icon={<IconPhone />}
                  isPhoneNum={true}
                />
              </Grid>
              <Grid item xs={6} className="mb-2">
                <CoreTextDisplayField
                  label="Secondary Number"
                  value={customer.BillTollFree}
                  icon={<IconPhone />}
                  isPhoneNum={true}
                />
              </Grid>
            </>}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12} className="mb-1">
            {addressCoordinates &&
              <CoreHereDisplayMap
                coordinates={addressCoordinates}
              />
            }
          </Grid>
          {!customer.BillAddressSame &&
            <Grid item xs={12} className="mb-1">
              {billAddressCoordinates &&
                <CoreHereDisplayMap
                  coordinates={billAddressCoordinates}
                />
              }
            </Grid>}
        </Grid>
      </Grid>
    </Container>
  );
}

export default CustomerViewAddressSection;