import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getWeeklyMarginsForDashboard } from './DashboardService';
import FormattingFunctions from '../helpers/formattingFunctions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
  upColor: {
    color: '#81C784'
  },
  downColor: {
    color: '#E57373'
  },
  arrowBottom: {
    verticalAlign: 'text-bottom'
  },
  revDataLi: {
    padding: '0 0px'
  },
  fontSmall: {
    fontSize: 'small'
  },
}));

const DashboardWeeklyMargins = () => {
  const classes = useStyles();
  const [weeklyMargin, setWeeklyMargin] = useState({});
  const [profitColor, setProfitColor] = useState(classes.upColor);
  const [marginColor, setMarginColor] = useState(classes.upColor);

  useEffect(() => {
    async function getWeeklyMargins() { 
      const result = await getWeeklyMarginsForDashboard();
      if(result.length > 0) {
        setWeeklyMargin(result[0]);
        setProfitColor(result[0].AvgProfit >= result[0].LastWeekAvgProfit ? classes.upColor : classes.downColor);
        setMarginColor(result[0].AvgMargin >= result[0].LastWeekAvgMargin ? classes.upColor : classes.downColor);
      }
    }
    getWeeklyMargins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="subtitle2" style={{display:'flex', justifyContent:'flex-start', fontWeight:'bold'}}>
        Weekly Margin
      </Typography>
      <List>
        <ListItem className={classes.revDataLi}>
          <ListItemText 
            style={{marginBottom: '0'}}
            primary={<div style={{fontWeight: 'bold'}}>{weeklyMargin.AvgProfit ? FormattingFunctions.formatCurrency(weeklyMargin.AvgProfit) : "$0"}</div>} 
            secondary={<span className={classes.fontSmall}>Average Profit</span>}
          />
          <ListItemText
            style={{textAlignLast: 'end', marginBottom: '0'}}
            primary={<div>
              <span className={profitColor}>
                {weeklyMargin.AvgProfit > weeklyMargin.LastWeekAvgProfit && <ArrowUpwardIcon className={classes.arrowBottom} fontSize='small'/>}
                {weeklyMargin.AvgProfit < weeklyMargin.LastWeekAvgProfit && <ArrowDownwardIcon className={classes.arrowBottom} fontSize='small'/>}
                &nbsp;{FormattingFunctions.percentageDiff(weeklyMargin.AvgProfit, weeklyMargin.LastWeekAvgProfit)}
              </span>
            </div>}
            secondary={<span className={classes.fontSmall}>from last week</span>}
          />
        </ListItem>
        <ListItem className={classes.revDataLi}>
          <ListItemText 
            style={{marginBottom: '0'}}
            primary={<div style={{fontWeight: 'bold'}}>{weeklyMargin.AvgMargin ? FormattingFunctions.formatCurrency(weeklyMargin.AvgMargin) : "$0"}</div>} 
            secondary={<span className={classes.fontSmall}>Average Margin</span>}
          />
          <ListItemText
            style={{textAlignLast: 'end', marginBottom: '0'}}
            primary={<div>
              <span className={marginColor}>
                {weeklyMargin.AvgMargin > weeklyMargin.LastWeekAvgMargin && <ArrowUpwardIcon className={classes.arrowBottom} fontSize='small'/>}
                {weeklyMargin.AvgMargin < weeklyMargin.LastWeekAvgMargin && <ArrowDownwardIcon className={classes.arrowBottom} fontSize='small'/>}
                &nbsp;{FormattingFunctions.percentageDiff(weeklyMargin.AvgMargin, weeklyMargin.LastWeekAvgMargin)}
              </span>
            </div>}
            secondary={<span className={classes.fontSmall}>from last week</span>}
          />
        </ListItem>
      </List>
    </div>   
  );

}

export default DashboardWeeklyMargins;
