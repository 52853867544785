import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { getInternalName } from '../personnel/PersonnelService';

const useStyles = makeStyles(() => ({
  paperNote: {
    background:'#eeebf5',
    width:'100%',
    position: 'relative',
    borderRadius: '8px',
    '& p': {
      fontSize: '14px',
      color: '#333333',
      padding: '0',
      margin: '0'
    },
    '& p.subject': {
      fontWeight: 600,
      color: '#333333',
      paddingBottom: '10px'
    }
  },
  cornerFold: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '2em',
    height: '2em',
    backgroundColor: '#653ac2',
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 2em 2em 0',
    borderColor: 'transparent #ffffff transparent transparent'
  },
  noteContent: {
    padding: '15px'
  },
  noteFooter: {
    display: 'flex',
    backgroundColor: '#fbfbfb',
    borderTop: '1px solid #f8f8f8',
    padding: '10px 15px',
    borderRadius: '0 0 8px 8px',
    width: 'auto',
    "& p": {
      fontSize: '12px !important',
      color: 'gray !important'
    }
  },
  paperNoteLight: {
    backgroundColor: '#ffffff'
  },
  paperNoteLightContent: {
    minHeight: '61px'
  },
  paperNoteLightTriangle: {
    borderColor: 'transparent #f7f6f3 transparent transparent'
  }

}));

const isDateToday = (dateCreated) => {
  const today = new Date().toDateString();
  dateCreated = new Date(dateCreated).toDateString();
  const isToday = (today === dateCreated);
  if (isToday) {
    return "Today"
  } else {
    return dateCreated
  }
}

const CoreNotePaper  = (props) => {
  const classes = useStyles();
  const { note, lightNote, showNoteFooter } = props;
  const [personnelName, setPersonnelName] = useState('');

  useEffect(() => {
    async function getPersonnelName(id) { 
      const result = await getInternalName(id);
      if (result) {
        setPersonnelName(result);
      } else {
        return null;
      }
    }
    getPersonnelName(note.CreatedByID);
  }, [note.CreatedByID]);

  return (
    <div className={`${classes.paperNote} ${lightNote && classes.paperNoteLight}`}>
      <div className={classes.cornerFold}>
        <div className={`${classes.triangle} ${lightNote && classes.paperNoteLightTriangle}`}/>
      </div>
      <div className={`${classes.noteContent} ${lightNote && classes.paperNoteLightContent}`} >
        {note.Subject && <p className="subject">{note.Subject}:</p>}
        {note.Note && <p>{note.Note}</p>}
      </div>
      {showNoteFooter && 
        <Grid container className={classes.noteFooter}>
          <Grid item xs={6}>
            <p>{personnelName}</p>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <p>{isDateToday(note.DateCreated)}</p>
          </Grid>
        </Grid>
      }
    </div>
  )
}

CoreNotePaper.propTypes = {
  note: PropTypes.object,
  lightNote: PropTypes.bool
};

export default CoreNotePaper;