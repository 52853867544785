import React, {useEffect, useState} from "react";
import { CreateTruckVisitAppointmentDialog } from "@envasetechnologies/precheck-components";
import { getToken } from "../order/ReservationService";
import { getTIN } from "../tax/TaxService";
import { addTruckVisitAppointment, getReferenceNumber } from "./MyAppointmentService";

const MyAppointmentCreateTruckVisit = (props) => {
  const { isOpen, onClose, appointments } = props;

  const [preCheckToken, setPreCheckToken] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [motorCarrierTIN, setMotorCarrierTIN] = useState('');
  const [tmsReferenceNumber, setTMSReferenceNumber] = useState('');

  useEffect(() => {
    async function getPreCheckToken() {
      const response = await getToken();
      if (response) {
        setPreCheckToken(response);
      }
    };

    async function getTaxIdentificationNumber() {
      const response = await getTIN();
      if (response) {
        setMotorCarrierTIN(response);
      }
    }

    async function getTMSReferenceNumber() {
      const response = await getReferenceNumber(appointments[0].gateTransactionId);
      if (response) {
        setTMSReferenceNumber(response);
      }
    }

    if (isOpen) {
      getPreCheckToken().then(() => {
        getTaxIdentificationNumber().then(() => {
          getTMSReferenceNumber().then(() => {
            setIsDataLoaded(true);
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClose = async () => {
    setIsDataLoaded(false);
    onClose(null);
  };

  const handleSuccess = async (response) => {
    console.log(response);
    var tva = response.data.truckVisitAppointmentId;
    const gateTransactionIds = appointments.map(appointment => appointment.gateTransactionId);
    addTruckVisitAppointment(tva, tmsReferenceNumber, gateTransactionIds);
  };

  return (
    <>
    {isDataLoaded &&
      <CreateTruckVisitAppointmentDialog
        isOpen={isOpen && isDataLoaded}
        onClose={() => handleClose()}
        onSuccess={(response) => handleSuccess(response)}
        onError={() => console.log("An error occurred")}
        appointments={appointments}
        motorCarrierTIN={motorCarrierTIN}
        tmsReferenceNumber={tmsReferenceNumber || 0}
        accessToken={preCheckToken}
      />
    }
    </>
  ); 
};

export default MyAppointmentCreateTruckVisit;