import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import PayableItemColumnEditButton from '../payable_item/PayableItemColumnEditButton';
import { deletePayableItem } from '../payable_item/PayableItemService';
import CoreAddButton from '../core/CoreAddButton';

const ConfigurationViewPayableItemSectionList = (props) => {
  const {
    payableItems,
    getPayableItems,
    setPayableItems,
    openPayableItemCreateDialog
  } = props;
  
  let columns = [
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const Name = params.getValue("Name");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>{Name}</span>
          </div>
        )
      }
    },
    {
      field: 'DescriptionDefault',
      headerName: 'Description',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'IntermodalUnitTypeName',
      headerName: 'Unit Type',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'IntermodalUnitRateTypeName',
      headerName: 'Unit Rate Type',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'UnitsDefaultValue',
      headerName: 'Unit Default',
      align: 'left',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        const unitType = params.getValue("IntermodalUnitType");
        const unitsDefaultValue = params.getValue("UnitsDefaultValue");
        const percent = params.getValue("UnitsDefaultValuePercent");
        const Name = unitType === 2 ? percent + ' %' : unitsDefaultValue; 
        return (
          <div>
            <span>{Name}</span>
          </div>
        )
      }
    },
    {
      field: 'UnitRateDefaultValue',
      headerName: 'Unit Rate Default',
      align: 'left',
      flex: 0.5,
      sortable: false
    },
    {
      field: 'Controls',
      headerName: <CoreAddButton handleOpenCreate={openPayableItemCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const payableItemId = params.row.PayableChargeTypeID;
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <PayableItemColumnEditButton
                payableItemID={payableItemId}
                getPayableItemFields={getPayableItems}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Payable Item: ${params.getValue("Name")}`}
                deleteID={payableItemId}
                deleteFunction={deletePayableItem}
                getList={getPayableItems}
              />}
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configPayableItemAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configPayableItemAmount", amount);
  };

  const onPayableItemRowEnter = (params) => {
    for (const item of payableItems) {
      if (item.PayableChargeTypeID === params.row.PayableChargeTypeID) {
        item.ShowControls = true;
      }
    }
    setPayableItems([ ...payableItems ]);
  };

  const onPayableItemRowLeave = (params) => {
    for (const item of payableItems) {
      if (item.PayableChargeTypeID === params.row.PayableChargeTypeID) {
        item.ShowControls = false;
      }
    }
    setPayableItems([...payableItems]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.PayableChargeTypeID}
        autoHeight={true} 
        rows={payableItems} 
        columns={columns}
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onPayableItemRowEnter(e)}
        onRowLeave={e => onPayableItemRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewPayableItemSectionList.propTypes = {
  payableItems: PropTypes.array.isRequired,
  getPayableItems: PropTypes.func.isRequired
};

export default ConfigurationViewPayableItemSectionList;