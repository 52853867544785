import React from 'react';
import Grid from '@material-ui/core/Grid';
import DashboardSummaryItem from './DashboardSummaryItem';
import DashboardDispatchSummary from './DashboardDispatchSummary';
import DashboardTopFive from './DashboardTopFive';
import DashboardLastFreeDay from './DashboardLastFreeDay';
import DashboardFinancialSummary from './DashboardFinancialSummary';
import DashboardPrecheckSummaryItem from './DashboardPrecheckSummaryItem';

const DashboardCompanyView = () => {

  return (
    <>      
      <Grid container spacing={3}>
        <Grid container item spacing={2} xs={10}>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardSummaryItem summaryType="ORDER" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardPrecheckSummaryItem />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardSummaryItem summaryType="INVOICE" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <DashboardSummaryItem summaryType="SETTLEMENT" />
          </Grid>
          <Grid item xs={12}>
            <DashboardDispatchSummary loading={false} />
          </Grid>
          <Grid item xs={6} md={6}>
            <DashboardTopFive isTopFiveCustomers={true} />
          </Grid>
          <Grid item xs={6} md={6}>
            <DashboardTopFive isTopFiveCustomers={false} />
          </Grid>
        </Grid>
        <Grid container item xs={2}>
          <Grid item xs={12} sm={12} md={12} className="mb-2">
            <DashboardLastFreeDay />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardFinancialSummary />
          </Grid>
        </Grid>
      </Grid>      
    </>
  );

}

export default DashboardCompanyView;