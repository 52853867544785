import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDocumentTypes } from '../order/OrderService';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { saveDocumentType } from '../order/OrderService';

const DocumentListDocumentTypeDropdown = (props) => {
  const {
    id,
    value,
    getDocuments,
    ...rest
  } = props;

  const [documentTypes, setDocumentTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getDocumentTypesList() { 
      const result = await getDocumentTypes();
      setDocumentTypes(result);
      setIsLoading(false);
    }
    getDocumentTypesList();
  }, []);

  const onDocumentTypeSelected = async (newValue) => {
    var documentTypeSelected = newValue ? newValue.ID : null;
    await saveDocumentType(id, documentTypeSelected);
    getDocuments();
  };

  const getOptionSelected = (option, value) => {
    if (option && option.ID === value) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const document = documentTypes.find(type => type.ID === value);
    if (document) {
      return document.Name;
    }
    return '';
  }

  return (
    !isLoading &&
    <CoreAutocompleteField
      value={value}
      onChange={onDocumentTypeSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={documentTypes}      
      {...rest}
    />
  );

}

DocumentListDocumentTypeDropdown.propTypes = {
  id: PropTypes.number.isRequired
}

export default DocumentListDocumentTypeDropdown;