import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AppForBillingSvg } from '../assets/svg-icons/app-for-billing@1x.svg'

const IconAppForBilling = () => {
  return (
    <SvgIcon fontSize="small">
      <AppForBillingSvg />
    </SvgIcon>
  )
}

export default IconAppForBilling;