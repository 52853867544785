import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import FeePayShoppingCartItemsDeleteDialog from './FeePayShoppingCartItemsDeleteDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
  iconTextDisabled: {
    color: 'gray',   
  },
}));

const FeePayShoppingCartItemsDeleteButton = (props) => {
  const {
    itemID,
    deleteCartItem,
    reference,
    isDisabled
  } = props;
  const classes = useStyles();
  const [isCoreDeleteModalOpen, setIsCoreDeleteModalOpen] = useState(false);

  const openCoreDeleteModal = () => {
    setIsCoreDeleteModalOpen(true);

  };

  const closeCoreDeleteModal = () => {
    setIsCoreDeleteModalOpen(false);
  };

  return (
    <>
      <IconButton className={!isDisabled ? classes.iconText : classes.iconTextDisabled} disabled={isDisabled} onClick={openCoreDeleteModal}>
        <DeleteForeverOutlinedIcon />
      </IconButton> 
      <FeePayShoppingCartItemsDeleteDialog
        deleteCartItem={deleteCartItem}
        itemID={itemID} 
        reference={reference}
        isOpen={isCoreDeleteModalOpen}
        onClose={closeCoreDeleteModal}
      />
    </>
  );
}

FeePayShoppingCartItemsDeleteButton.propTypes = {
  itemID: PropTypes.number.isRequired,
  deleteCartItem: PropTypes.func.isRequired,
};

export default FeePayShoppingCartItemsDeleteButton;