import HttpService from '../http-service';

import { format } from 'date-fns';
import { getAutoAddIfTypeName } from '../resource/ResourceService';

const token = localStorage.getItem('token');
const uri = 'DefaultPayableChargeType/' + token + '/';

export async function Create(defaultPayableChargeTypeFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'IntermodalCreate', defaultPayableChargeTypeFormData);
  return result;
};

export async function Update(defaultPayableChargeTypeFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', defaultPayableChargeTypeFormData);
  return result;
};

export async function Delete(id) { 
  var params = { 
    id: id 
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};

export async function Get(id) { 
  var params = { 
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.response;
};

export async function GetList(personnelID) {
  var params = { 
    personnelID: personnelID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList', params);
  var response = result.response;
  for (const item of response) { 
    var autoAddIf = await getAutoAddIfTypeName(item.AutoAddIf);
    item.AutoAddIfName = autoAddIf;
    item.DateStart = item.DateStart ? format(new Date(item.DateStart), 'MM/dd/yyyy') : "";
    item.DateEnd = item.DateEnd ? format(new Date(item.DateEnd), 'MM/dd/yyyy') : "";
  }
  return response;
};