import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import {
  getCredentials as envaseTraceServiceGetCredentials
} from '../envase_trace/EnvaseTraceService';
import ConfigurationViewEnvaseTraceSectionListTable from './ConfigurationViewEnvaseTraceSectionListTable';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewEnvaseTraceSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [providersList, setProvidersList] = useState({});

  useEffect(() => {
    envaseTraceServiceGetCredentials()
      .then(function (response) {
        setProvidersList(response);
        setInProgress(false);
      });
  }, []);

  const refreshEvent = async () => {
    setInProgress(true);
    const result = await envaseTraceServiceGetCredentials();
    setProvidersList(result);
    setInProgress(false);
  };

  return (
    <div>
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Envase Trace Credentials"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Email Template"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {"Here you can manage all of your envase tracing providers."}
              <span style={rightFloat}>
              </span>
            </Typography>
            <ConfigurationViewEnvaseTraceSectionListTable
              providersList={providersList}
              getProvidersList={refreshEvent}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Envase Trace Credentials"}
              <span style={rightFloat}>
                <CoreExpandMoreButton 
                  toggleIsOpen={toggleIsOpen} 
                  disabled={inProgress}
                  ariaLabel="Show Envase Trace Credentials">
                </CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      {inProgress && <LinearProgress />}
    </div>
  );

}

export default ConfigurationViewEnvaseTraceSection;