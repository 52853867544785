import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Pendo/';

export async function getData(){
    const httpService = new HttpService();
    const params = { token };
    const result = await httpService
        .get(uri + 'GetData', params);
    return result.response;
};
