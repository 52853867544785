import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CloseSvg } from '../assets/svg-icons/close@1x.svg';

const IconClose = () => {
  return (
    <SvgIcon fontSize="small">
      <CloseSvg />
    </SvgIcon>
  )
}
export default IconClose;