import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { getPayable, updatePayable } from '../payable/PayableService';
import BillViewExpenseEditDialogContent from './BillViewExpenseEditDialogContent';
import { getPayableItem } from '../payable_item/PayableItemService';

const BillViewExpenseEditDialog = (props) => {
  const {
    payableChargeID,
    isOpen,
    onClose,
  } = props;
  
  const [payableFormData, setPayableFormData] = useState({
    PayableChargeID: 0,
    PayableChargeTypeName: '',
    PayableChargeTypeID: 1,
    Units: 1,
    UnitsEdited: 0,
    UnitRate: 0,
    UnitRateEdited: 0,
    Subtotal: 0,
    Tax1TypeID: 1,
    Tax1Edited: 0,
    Tax1: 0,
    Tax2TypeID: 1,
    Tax2Edited: 0,
    Tax2: 0
  });
  const [payableFormDataLoaded, setPayableFormDataLoaded] = useState(false);
  const [payableFormValidation, setPayableFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    if (isOpen) {
      getPayable(payableChargeID)
      .then(function(response) {
        setPayableFormData(response);
        setPayableFormDataLoaded(true);
      });    
    }
  }, [isOpen, payableChargeID]);

  useEffect(() => {
    if (isOpen) {
      setPayableFormValidation(buildValidation(payableFormData));
    }
  }, [payableFormData, isOpen]);

  useEffect(() => {
    if (!payableFormDataLoaded) { 
      return;
    }
    setPayableFormData(p => (
      { ...p, Description: payableFormData.PayableChargeTypeName }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payableFormData.PayableChargeTypeID]);

  const updatePayableFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    payableFormData[name] = value
    payableFormData[nameData] = valueData;
    setPayableFormData({ ...payableFormData});
    updatePayableItemDefaults(nameData);
  };

  const updatePayableItemDefaults = async (name) => {
    if (payableFormData.PayableChargeTypeID && name === "PayableChargeTypeID") {
      const payableItem = await getPayableItem(payableFormData.PayableChargeTypeID);
      setPayableFormData(p => ({ ...p, Units: payableItem.UnitsDefaultValue}));
      setPayableFormData(p => ({ ...p, UnitRate: payableItem.UnitRateDefaultValue }));
      setPayableFormData(s => ({...s, Subtotal: (payableItem.UnitsDefaultValue * payableItem.UnitRateDefaultValue)}));
    } else {
      updateSubtotal();
    }
  };

  const updateSubtotal = () => {
    setPayableFormData(s => ({...s, Subtotal: (payableFormData.Units * payableFormData.UnitRate)}));
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(payableFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await updatePayable(payableFormData);
    if (result.errors.Error) {
        setErrorMessage(result.errors.Error);
        return;
    }
    props.onClose();
  };

  return (
    isOpen && payableFormDataLoaded && 
    <BillViewExpenseEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      payableFormData={payableFormData}
      payableFormValidation={payableFormValidation}
      updatePayableFormData={updatePayableFormData}
    />
  );
}

const buildValidation = (payableFormData) => {
  const {
    PayableChargeTypeID
  } = payableFormData || {};
  const validation = new Validation();
  return {
    PayableChargeTypeID: validation.isNumber(PayableChargeTypeID)
  };
};

export default BillViewExpenseEditDialog;