import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import CoreTextField from '../core/CoreTextField';
import CountryField from '../country/CountryField';
import ProvinceField from '../province/ProvinceField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CorePhoneField from '../core/CorePhoneField';
import EnvaseTraceProviderFieldDropdown from '../envase_trace/EnvaseTraceProviderFieldDropdown';
import LocationTypeField from './LocationTypeField';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';
const LocationCreateContentInfo = (props) => {
  const {
    locationFormData,
    locationFormValidation,
    updateLocationFormData,
    coordinates,
    traceCredentials
  } = props;

  const [branch, setBranch] = useState('');

  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };
  return (
    <div>
      <Container maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h4>Location Information</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <CoreTextField
              name="internalName"
              label="Internal Name"
              value={locationFormData.internalName}
              onChange={e => updateLocationFormData(e)}
              isRequired={true}
              validationError={locationFormValidation.internalName}
              autoFocus={true}
              maxLength={255}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <CoreTextField
              name="companyName"
              label="Company Name"
              value={locationFormData.companyName}
              onChange={e => updateLocationFormData(e)}
              isRequired={true}
              validationError={locationFormValidation.companyName}
              maxLength={255}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <EnvaseTraceProviderFieldDropdown
              name="envasetraceproviderID"
              label="Trace Provider"
              value={locationFormData.envasetraceproviderID}
              onChange={e => updateLocationFormData(e)}
              isRequired={false}
              validationError={locationFormValidation.envasetraceproviderID}
            />
          </Grid>
          <Grid item md={3} xs={12} className={"mb-2"}>
            <LocationTypeField
              name="type"
              label="Type"
              value={locationFormData.type}
              onChange={e => updateLocationFormData(e)}
              isRequired={false}
            />
          </Grid>
          {locationFormData.envasetraceproviderID !== null && !traceCredentials.IsEnabled && <Grid item md={12} xs={12} className="mb-2">
            <Alert severity="warning">{"Trace Provider credentials do not exist, contact your admin to update"}</Alert>
          </Grid>}
        </Grid>
      </Container>
      <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className="mb-2">
            <Grid container spacing={2}>  
              {(locationFormData.country !== "Philippines" && branch.Country !== "Philippines") && <>              
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="address"
                    label="Address"
                    value={locationFormData.address}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.address}
                    maxLength={255}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="city"
                    label="City"
                    value={locationFormData.city}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.city}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <ProvinceField
                    name="province"
                    label="State / Province"
                    value={locationFormData.province}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.province}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="postalCode"
                    label="ZIP / Postal Code"
                    value={locationFormData.postalCode}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.postalCode}
                    maxLength={15}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CountryField
                    label="Country"
                    name="country"
                    value={locationFormData.country}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.country}
                  />
                </Grid>
              </>}
              {(locationFormData.country === "Philippines" || branch.Country === "Philippines") && <>
                <Grid item md={6} xs={6}>
                  <CountryField
                    label="Country"
                    name="country"
                    value={locationFormData.country}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.country}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <ProvinceField
                    name="province"
                    label="State / Province"
                    value={locationFormData.province}
                    onChange={e => updateLocationFormData(e)}
                    isRequired={true}
                    validationError={locationFormValidation.province}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="city"
                    label="City"
                    value={locationFormData.city}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.city}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <CoreTextField
                    name="postalCode"
                    label="ZIP / Postal Code"
                    value={locationFormData.postalCode}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.postalCode}
                    maxLength={15}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="district"
                    label="District"
                    value={locationFormData.district}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.district}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="county"
                    label="Barangay"
                    value={locationFormData.county}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.county}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="address"
                    label="Address"
                    value={locationFormData.address}
                    onChange={e => updateLocationFormData(e)}
                    validationError={locationFormValidation.address}
                    maxLength={255}
                    autoComplete="disable-autocomplete"
                  />
              </Grid>
              </>}
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Primary Number"
                  name="phone"
                  value={locationFormData.phone}
                  isRequired={false}
                  validationError={locationFormValidation.phone}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Fax"
                  name="fax"
                  value={locationFormData.fax}
                  isRequired={false}
                  validationError={locationFormValidation.fax}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CorePhoneField
                  label="Secondary Number"
                  name="tollFree"
                  value={locationFormData.tollFree}
                  isRequired={false}
                  validationError={locationFormValidation.tollFree}
                  onChange={e => updateLocationFormData(e)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>          
            {coordinates &&
              <CoreHereDisplayMap
                coordinates={coordinates}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}

export default LocationCreateContentInfo;