import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconPendingInvoice from '../icon/IconPendingInvoice';
import IconReady from '../icon/IconReady';
import IconOnHold from '../icon/IconOnHold';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'left',
  },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  gridIcon: {
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  stats: {
    padding: theme.spacing(2)
  },
  divider: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  h6: {
    margin: '0 0 5px 0'
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  status: {
    margin: '0 0 5px 0',
    fontWeight: '400',
    fontSize: '12px'
  },
  count: {
    fontWeight: '600',
    alignSelf: 'center',
  },
  countLink: {
    fontWeight: '600',
    color: '#3f51b5',
    alignSelf: 'center',
  },
  iconAvatar:{
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    filter: 'invert(1)',
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  bold: {
    fontWeight: 'bold'
  },
}));

const DashboardPrecheckSummaryItem = () => {

  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      <Grid className={classes.stats}>
        <Typography variant="subtitle1" className={classes.bold}>
          Precheck
        </Typography>
        <Typography variant="h3">
          0
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontSize: '12px'}}>
          Needs Attention
        </Typography>
      </Grid>
      <Grid className={classes.divider}>    
        <Divider className={classes.lightDivider} />
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item sm>
            <h6 className={classes.status}>Confirmed +3d</h6>
            <Grid container className={classes.gridIcon}>
                      <Avatar className={classes.iconAvatar} style={{ backgroundColor: "#E573D8" }}>
                    <div className={classes.icon}>
                        <IconPendingInvoice/>
                    </div>
                </Avatar>
                &nbsp;&nbsp;
                <div className={classes.count}>
                    0
                </div>
            </Grid>
        </Grid>
        <Grid item sm>
            <h6 className={classes.status}>On Hold</h6>
            <Grid container className={classes.gridIcon}>
                      <Avatar className={classes.iconAvatar} style={{ backgroundColor: "#FFB74D"}}>
                    <div className={classes.icon}>
                        <IconOnHold />
                    </div>
                </Avatar>
                &nbsp;&nbsp;
                <div className={classes.count}>
                    0
                </div>
            </Grid>
        </Grid>
        <Grid item sm>
            <h6 className={classes.status}>Green Lane</h6>
            <Grid container className={classes.gridIcon}>
                      <Avatar className={classes.iconAvatar} style={{ backgroundColor: "#81C784" }}>
                    <div className={classes.icon}>
                        <IconReady />
                    </div>
                </Avatar>
                &nbsp;&nbsp;
                <div className={classes.count}>
                    0
                </div>
            </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

}

DashboardPrecheckSummaryItem.propTypes = {
  summaryType: PropTypes.string
};

DashboardPrecheckSummaryItem.defaultProps = {
  summaryType: ""
};

export default DashboardPrecheckSummaryItem;
