import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as PersonnelLightSvg } from '../assets/svg-icons/personnel-light@1x.svg';

const IconPersonnelLight = () => {
  return (
    <SvgIcon fontSize="small">
      <PersonnelLightSvg />
    </SvgIcon>
  )
}
export default IconPersonnelLight;