import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreDataGridList from '../core/CoreDataGridList';
import Container from '@material-ui/core/Container';
import { downloadFile } from '../globalFunction';
import { getList, exportList } from './EquipmentService';
import EquipmentViewSummary from './EquipmentViewSummary';
import Validation from '../validation';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const EquipmentListTable = () => {
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("equipmentListAmount")) || 10,
    count: parseInt(localStorage.getItem("equipmentListAmount")) || 10,
    showObsolete: false,
    isASC: true,
    sortBy: 'UnitNum',
    filters: []
  });
  const validation = new Validation();
  let history = useHistory();

  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(true);
  const [equipment, setEquipment] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
  });

  const updateFilterData = (e) => {
    const { name, value } = e.target;
    filterData[name] = value;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => {  
        const name = params.getValue("ID");
        return (
          <div>
            <IconButton title="Open Full Record" onClick={() => openRecord(name)}>
              <OpenInBrowserIcon color='primary'/>
            </IconButton>
            <IconButton title="Open Slideout" onClick={() => openSlideout(name)}>
              <MenuOpenIcon color='primary'/>
            </IconButton>
          </div>
        )
      }
    },
    {
      field: "EquipmentID",
      hide: true
    },
    {
      field: "UnitNum",
      headerName: "Equipment Number",
      align: 'left',
      flex: 2,
    },
    {
      field: "UnitType",
      headerName: "Unit Type",
      align: 'left',
      flex: 1,
    },
    {
      field: "EquipmentType",
      headerName: "Equipment Type",
      align: 'left',
      flex: 1,
    },
    {
      field: "OwnerName",
      headerName: "Owned By",
      align: 'left',
      flex: 1,
    },
    {
      field: "LicenseNum",
      headerName: "License Plate #",
      align: 'left',
      flex: 1,
    }
  ];

  useEffect(() => {
    async function getInitEquipmentList() {
      const result = await getList({
        page: 1,
        amount: parseInt(localStorage.getItem("equipmentListAmount")) || 10,
        count: parseInt(localStorage.getItem("equipmentListAmount")) || 10,
        showObsolete: false,
        isASC: true,
        sortBy: 'UnitNum',
        filters: []
      });
      if (result.length > 0) {
        setParams(c => ({ ...c, count: result[0].TotalRows }));
      } else {
        setParams(c => ({ ...c, count: 0 }));
      }
      setEquipment(result);
      setInProgress(false);
    }
    getInitEquipmentList();
  }, []);

  function changePage(page) {
    params.page = page + 1;
    getEquipmentList();
    setParams(params);
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getEquipmentList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    getEquipmentList();
    setParams(params);
    localStorage.setItem("equipmentListAmount", amount);
  };

  function pushParamFilter(filterName, value, operator) {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  };

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }
    params.page = 1;
    setParams(params);
    getEquipmentList();
  };

  const getEquipmentList = async () => {
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setParams(c => ({ ...c, count: result[0].TotalRows }));
    } else {
      setParams(c => ({ ...c, count: 0 }));
    }
    setEquipment(result);
    setInProgress(false);
  };

  const exportEquipmentList = async () => {
    const result = await exportList(params);
    downloadFile(result, "equipment-export.csv");
  };

  const openRecord = (id) => {
    history.push('/spark/equipment/view/' + id);
  };

  const openSlideout = (id) => {
    setCurrentRowClicked(id);
    setIsSummaryOpen(true);
  };

  const closeSummary = () => {
    setIsSummaryOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>    
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={equipment}
          params={params}
          exportList={exportEquipmentList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='UnitNum'
          filterData={filterData}
          updateFilterData={updateFilterData}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
      <EquipmentViewSummary
        equipmentTemplateID={currentRowClicked}
        isOpen={isSummaryOpen}
        onClose={closeSummary}
      />
    </div>
  );
};

export default EquipmentListTable;