import React, { useState, useEffect } from 'react';
import Validation from '../validation';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CoreTextField from '../core/CoreTextField';
import PersonnelField from '../personnel/PersonnelField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { get as getConfiguration, update as updateConfiguration } from './ConfigurationService';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import Divider from '@material-ui/core/Divider';


const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewShipperManagementSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;

  const [configurationFormDataLoaded, setConfigurationFormDataLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [configurationFormData, setConfigurationFormData] = useState({});
  const [configurationFormValidation, setConfigurationFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);

  useEffect(() => {
    getConfiguration()
      .then(function (response) {
        setConfigurationFormData(response);
        setConfigurationFormDataLoaded(true);
        setInProgress(false);
      });
  }, []);

  useEffect(() => {
    setConfigurationFormValidation(buildValidation(configurationFormData));
  }, [configurationFormData]);

  const updateConfigurationFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    configurationFormData[name] = value;
    configurationFormData[nameData] = valueData;
    setConfigurationFormData({ ...configurationFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    setAlertSuccess(false);
    e.preventDefault();
    if (!new Validation().isValidObject(configurationFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await updateConfiguration(configurationFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    setAlertSuccess(true);
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      {configurationFormDataLoaded &&
        <Paper variant="outlined" square className="mt-1">
          {isOpen &&
            <Container maxWidth="lg" className="mt-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Shipper Management Settings"}
                <span style={rightFloat}>
                  <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Shipper Management Settings"></CoreExpandLessButton>
                </span>
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {"Shipper Management Order assignments will be sent via notification to the Personnel below. Emails filled below will also receive Order assignment notifications."}
                <span style={rightFloat}>
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <Grid container spacing={2} className="mt-2">
                  <Grid item md={3} xs={12}>
                    <PersonnelField
                      name={"PersonnelName"}
                      label={"Personnel"}
                      value={configurationFormData.PersonnelName}
                      nameData="DefaultShipperAssignmentOwner" 
                      valueData={configurationFormData.DefaultShipperAssignmentOwner}
                      onChange={e => updateConfigurationFormData(e)}
                      isRequired={true}
                      validationError={configurationFormValidation.DefaultShipperAssignmentOwner}
                    />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <CoreTextField
                      name="ShipperAssignmentNotificationEmails"
                      label="Email(s) *Multiple email addresses must be separated with a comma or semicolon*"
                      value={configurationFormData.ShipperAssignmentNotificationEmails}
                      onChange={e => updateConfigurationFormData(e)}
                      autoFocus={true}
                    />
                  </Grid>
                </Grid>
              </Typography>
              <Divider className="mt-4"/>
            </Container>
          }
          {isOpen &&
            <DialogActions style={justifyContent} className="mb-1">
              <span>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {alertSuccess && <Alert severity="success">Shipper Management Settings successfully saved.</Alert>}
              </span>
              <span>
                <Button  style={{flex: 1, backgroundColor: '#6639BF', color: 'white', marginRight:'20px'}} variant="contained" onClick={e => onUpdate(e)}>
                  Save Changes
                </Button>
              </span>
            </DialogActions>
          }
          {!isOpen &&
            <Container maxWidth="lg" className="mt-2 mb-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Shipper Management Settings"}
                <span style={rightFloat}>
                  <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Shipper Management Settings"></CoreExpandMoreButton>
                </span>
              </Typography>
            </Container>
          }
        </Paper>
      }
    </div>
  );

}

const buildValidation = (configurationFormData) => {
  const {
    DefaultShipperAssignmentOwner,
  } = configurationFormData || {};
  const validation = new Validation();
  return {
    DefaultShipperAssignmentOwner: validation.isNumber(DefaultShipperAssignmentOwner),
  };
};

export default ConfigurationViewShipperManagementSection;