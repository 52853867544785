import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreNumberField from '../core/CoreNumberField';
import CoreTextField from '../core/CoreTextField';
import ReceivableChargeTypeField from '../receivable_charge_type/ReceivableChargeTypeField';

const RevenueCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    revenueFormData,
    onCreate,
    errorMessage,
    revenueFormValidation,
    updateRevenueFormData,
    inProgress
  } = props;

  const updateUnits = data => {
    const dispatchUnits = {
      target: {
        name: "units",
        value: data.UnitsDefaultValue
      }
    };
    const dispatchUnitRate = {
      target: {
        name: "unitRate",
        value: data.UnitRateDefaultValue
      }
    };
    updateRevenueFormData(dispatchUnits);
    updateRevenueFormData(dispatchUnitRate);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Revenue Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} xs={12}>
              <ReceivableChargeTypeField
                label="Type"
                name="receivableChargeTypeName"
                value={revenueFormData.receivableChargeTypeName}
                nameData="receivableChargeTypeID"
                valueData={revenueFormData.receivableChargeTypeID}
                onChange={e => updateRevenueFormData(e)}
                responseObject={updateUnits}
                isRequired={true}
                validationError={revenueFormValidation.receivableChargeTypeID}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="description"
                label="Description"
                value={revenueFormData.description}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="units"
                label="Quantity"
                isCurrency={false}
                value={revenueFormData.units}
                validationError={revenueFormValidation.units}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="unitRate"
                label="Rate"              
                isCurrency={true}
                value={revenueFormData.unitRate}
                validationError={revenueFormValidation.unitRate}
                onChange={e => updateRevenueFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={false}>
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreNumberField
                name="subtotal"
                label="Total"
                isCurrency={true}
                disabled={true}
                value={revenueFormData.subtotal}
                validationError={revenueFormValidation.subtotal}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={inProgress} type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default RevenueCreateDialogContent;