import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory } from 'react-router-dom';
import Validation from '../validation';
import CoreExitButton from '../core/CoreExitButton';
import { create as orderCreate } from './OrderService';
import OrderCreateContent from './OrderCreateContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formatDateTime } from '../globalFunction';
import { 
  getMainBranchID, 
  getDefaultShipmentSettings, 
  getDefaultOrderSettings 
} from '../configuration/ConfigurationService';
import { setProvider, getDefaultICTSILocation } from '../location/LocationService';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';
import { isContainerNumberValid, getContainerNumberErrorMessage } from '../container_validator/ContainerValidator';

const OrderCreate = () => {
  let history = useHistory();
  const onCloseRoute = history.location.state?.onCloseRoute ? history.location.state?.onCloseRoute : '/spark/order';

  const [orderFormData, setOrderFormData] = useState({
    customerID: null,
    customer: '',
    type: '',
    referenceNumber: '',
    liveDrop: '',
    loadedStatus: '',
    weight: 0,
    weightUOM: '',
    branchID: 0,
    isOverweight: false,
    isHazmat: false,
    isExpedite: false,
    isTempManaged: false,
    onHold: false,
    originID: null,
    originName: '',
    originAddress: '',
    originCity: '',
    originState: '',
    originZip: '',
    originCountry: '',
    destinationID: null,
    destinationName: '',
    destinationAddress: '',
    destinationCity: '',
    destinationState: '',
    destinationZip: '',
    destinationCountry: '',
    vesselName: '',
    vesselVoyager: '',
    vesselDate: null,
    vesselTime: null,
    vesselActualDate: null,
    vesselActualTime: null,
    containerReleaseDate: null,
    containerReleaseTime: null,
    containerLastDate: null,
    containerLastTime: null,
    cutoffDate: null,
    cutoffTime: null,
    firstReceivedDate: null,
    firstReceivedTime: null,
    emptyReadyDate: null,
    emptyReadyTime: null,
    loadReadyDate: null,
    loadReadyTime: null,
    importExportReportedby: '',
    favorite: 0,
    containerNum: '',
    containerSize: 0,
    OwnerType: 0,
    ownerVendorName: '',
    ownerVendorID: null,
    numPieces: null
  });

  const [inProgress, setInProgress] = useState(false);
  const [orderFormValidation, setOrderFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [initialProviderID, setInitialProviderID] = useState(null);
  const [companyFeature, setCompanyFeature] = useState([]);
  const [ictsiLocation, setIctsiLocation] = useState([]);
  const [containerValidatorErrorMessage, setContainerValidatorErrorMessage] = useState('');

  useEffect(() => {
    setBranchID();
    async function getIctsiLocation() {
      const defaultICTSILocation = await getDefaultICTSILocation();
      setIctsiLocation(defaultICTSILocation);
      getCompanyFeature()
          .then(function(response){
            setCompanyFeature(response);
            setDefaultShipmentSettings(response.IsICTSI, defaultICTSILocation);
          }); 
    }
    getIctsiLocation();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDefaultWeightUOM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOrderFormValidation(buildValidation(orderFormData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFormData]);

  const setBranchID = async () => {
    const branchID = await getMainBranchID();
    orderFormData.branchID = branchID;
    setOrderFormData({ ...orderFormData });
  };

  const setDefaultWeightUOM = async () => {
    const weightUOM = await getDefaultOrderSettings();
    orderFormData.weightUOM = weightUOM.DefaultIntermodalWeightUOM;
    setOrderFormData({ ...orderFormData });
  };
  
  const setDefaultShipmentSettings = async (isICTSI, locationSpinnerData) => {
    const defaultShipmentSettings = await getDefaultShipmentSettings();
    orderFormData.type = defaultShipmentSettings.DefaultLoadType;
    orderFormData.liveDrop = defaultShipmentSettings.DefaultLiveDrop;
    orderFormData.loadedStatus = defaultShipmentSettings.DefaultLoadedStatus;
    if (isICTSI && defaultShipmentSettings.DefaultLoadType === 'Import') {
      orderFormData.originLocationName = locationSpinnerData[0].InternalName;
      orderFormData.originID = locationSpinnerData[0].LocationTemplateID;
      orderFormData.envaseTraceProviderID = locationSpinnerData[0].EnvaseTraceProviderID;
      setInitialProviderID(locationSpinnerData[0].EnvaseTraceProviderID);
    } else if (isICTSI && defaultShipmentSettings.DefaultLoadType === 'Export') {
      orderFormData.destinationLocationName = locationSpinnerData[0].InternalName;
      orderFormData.destinationID = locationSpinnerData[0].LocationTemplateID;
      orderFormData.envaseTraceProviderID = locationSpinnerData[0].EnvaseTraceProviderID;
      setInitialProviderID(locationSpinnerData[0].EnvaseTraceProviderID);
    }
    setOrderFormData({ ...orderFormData });
  };

  const updateOrderFormData = e => {
    const {
      name,
      value,
      nameData,
      valueData,
      addressData,
      addressValue,
      cityData,
      cityValue,
      stateData,
      stateValue,
      zipData,
      zipValue,
      countryData,
      countryValue,
      envaseTraceProviderID
    } = e.target;
    orderFormData[name] = value;
    orderFormData[nameData] = valueData;
    orderFormData[addressData] = addressValue;
    orderFormData[cityData] = cityValue;
    orderFormData[stateData] = stateValue;
    orderFormData[zipData] = zipValue;
    orderFormData[countryData] = countryValue;
    if (name === "originID") {
      orderFormData.envaseTraceProviderID = envaseTraceProviderID;
      setInitialProviderID(envaseTraceProviderID);
    }
    if (companyFeature.IsICTSI && name === "type" && value === "Import") {
      orderFormData.originLocationName = ictsiLocation[0].InternalName;
      orderFormData.originID = ictsiLocation[0].LocationTemplateID;      
      orderFormData.destinationName = '';
      orderFormData.destinationLocationName = '';
      orderFormData.destinationID = null;
    } else if (companyFeature.IsICTSI && name === "type" && value === "Export") {
      orderFormData.destinationLocationName = ictsiLocation[0].InternalName;
      orderFormData.destinationID = ictsiLocation[0].LocationTemplateID;
      orderFormData.originName = '';
      orderFormData.originLocationName = '';
      orderFormData.originID = null;
    }
    if (e.target.name === 'containerNum') { 
      validateContainer();
    }
    setOrderFormData({ ...orderFormData });
  };

  const validateContainer = () => { 
    setContainerValidatorErrorMessage('');
    if (!isContainerNumberValid(orderFormData.containerNum)) { 
      setContainerValidatorErrorMessage(getContainerNumberErrorMessage(orderFormData.containerNum));
    }
  };

  const formatOrder = () => {
    if (new Validation().isNumber(orderFormData.weight)) {
      orderFormData.weight = 0;
    }
    if (new Validation().isNumber(orderFormData.containerSize)) {
      orderFormData.containerSize = 0;
    }
    orderFormData.vesselDate = formatDateTime(orderFormData.vesselDate, orderFormData.vesselTime);
    orderFormData.vesselActualDate = formatDateTime(orderFormData.vesselActualDate, orderFormData.vesselActualTime);
    orderFormData.containerReleaseDate = formatDateTime(orderFormData.containerReleaseDate, orderFormData.containerReleaseTime);
    orderFormData.containerLastDate = formatDateTime(orderFormData.containerLastDate, orderFormData.containerLastTime);
    orderFormData.referenceNumber = orderFormData.referenceNumber.trim();
    if (orderFormData.type === 'Export') {
      orderFormData.cutoffDate = formatDateTime(orderFormData.cutoffDate, orderFormData.cutoffTime);
      orderFormData.firstReceivedDate = formatDateTime(orderFormData.firstReceivedDate, orderFormData.firstReceivedTime);
      orderFormData.vesselDate = null;
      orderFormData.vesselActualDate = null;
      orderFormData.containerLastDate = null;    
    } else {
      orderFormData.cutoffDate = null;
      orderFormData.firstReceivedDate = null;
    }    
    formatImportExport();
    if (!orderFormData.onHold) { 
      orderFormData.holdReason = '';
    }
    setOrderFormData({ ...orderFormData });
  };

  const formatImportExport = () => {
    if (orderFormData.type === 'One Way') {
      orderFormData.liveDrop = '';
    } else {
      orderFormData.loadedStatus = '';
    }    
    if (orderFormData.liveDrop === 'Drop') {
      if (orderFormData.type === 'Import') {
        orderFormData.emptyReadyDate = formatDateTime(orderFormData.emptyReadyDate, orderFormData.emptyReadyTime);
        orderFormData.loadReadyDate = null;        
      } else if (orderFormData.type === 'Export') {
        orderFormData.loadReadyDate = formatDateTime(orderFormData.loadReadyDate, orderFormData.loadReadyTime);
        orderFormData.emptyReadyDate = null;        
      }
    } else {
      orderFormData.importExportReportedby = '';
      orderFormData.emptyReadyDate = null;
      orderFormData.loadReadyDate = null;
    }
  };

  const onCreate = e => {
    setInProgress(true);
    formatOrder();
    e.preventDefault();
    if (!new Validation().isValidObject(orderFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await orderCreate(orderFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    setLocationProvider();
    // Redirect to the order view page
    history.push({
      pathname: '/spark/order/view/' + result.response,
      state: {
        onCloseRoute: onCloseRoute
      }
    });
  };

  const setLocationProvider = async() => { 
    if (orderFormData.envaseTraceProviderID !== initialProviderID) { 
      await setProvider(orderFormData.originID, orderFormData.envaseTraceProviderID)
      setInitialProviderID(null);
    }
  };

  const onClose = () => {
    history.push(onCloseRoute);
  };

  return (
    <div>
      <CorePageHeader
        title="Create New Order"
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        <OrderCreateContent
          onCreate={onCreate}
          onClose={onClose}
          errorMessage={errorMessage}
          orderFormData={orderFormData}
          orderFormValidation={orderFormValidation}
          updateOrderFormData={updateOrderFormData}
          inProgress={inProgress}
          initialProviderID={initialProviderID}
          containerValidatorErrorMessage={containerValidatorErrorMessage}
        />
      </Container>
    </div>
  );

}

const buildValidation = (orderFormData) => {
  const {
    customerID,
    originID,
    destinationID,
    type,
    weightUOM,
    liveDrop,
    loadedStatus
  } = orderFormData || {};
  const validation = new Validation();
  return {
    customerID: validation.isNumber(customerID),
    originID: validation.isNumber(originID),
    destinationID: validation.isNumber(destinationID),
    type: validation.isEmpty(type),
    weightUOM: validation.isEmpty(weightUOM),
    liveDrop: type !== "One Way" ? validation.isEmpty(liveDrop) : false,
    loadedStatus: type === "One Way" ? validation.isEmpty(loadedStatus): false
  };
};

export default OrderCreate;
