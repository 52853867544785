import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from '../country/CountryService';
import CountryCreateDialogContent from '../country/CountryCreateDialogContent';

const CountryCreateDialog = (props) => {
  const {
    isOpen,
    onClose
  } = props;

  const initialState = {
    EDICode: '',
    Name: ''
  };

  const [countryFormData, setCountryFormData] = useState(initialState);
  const [countryFormValidation, setCountryFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setCountryFormValidation(buildValidation(countryFormData));
    }
  }, [countryFormData, isOpen]);

  const updateCountryFormData = e => {
    const { name, value } = e.target;
    countryFormData[name] = value;
    setCountryFormData({ ...countryFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(countryFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(countryFormData);
    setInProgress(false);
    if (result.errors.Error) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setCountryFormData({initialState});
    onClose();
  };

  return (
    isOpen &&
    <CountryCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      countryFormData={countryFormData}
      countryFormValidation={countryFormValidation}
      updateCountryFormData={updateCountryFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (countryFormData) => {
  const {
    EDICode,
    Name
  } = countryFormData || {};
  const validation = new Validation();
  return {
    EDICode: validation.isEmpty(EDICode),
    Name: validation.isEmpty(Name)
  };
};

export default CountryCreateDialog;