import React from 'react';
import PropTypes from 'prop-types';
import IconAdd from '../icon/IconAdd';
import IconButton from '@material-ui/core/IconButton';

const CoreDataGridDownload = (props) => {
  const {
    onClick,
  } = props;

  return (
    <IconButton onClick={onClick}>
      <IconAdd />
    </IconButton>
  );
}

CoreDataGridDownload.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CoreDataGridDownload;
