import { Route } from 'react-router-dom';
import CustomerList from './CustomerList';
import CustomerView from './CustomerView';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';

const CustomerRouter = () => {
  return (
    <>
      <Route exact path="/spark/customer" component={CustomerList} />
      <Route exact path="/spark/customer/create" component={CustomerCreate} />
      <Route exact path="/spark/customer/edit/:id" component={CustomerEdit} />
      <Route exact path="/spark/customer/view/:id" component={CustomerView} />
    </>
  );

}

export default CustomerRouter;