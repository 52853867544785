import React from 'react';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import EquipmentUnitTypeDropdownField from './EquipmentUnitTypeDropdownField';
import EquipmentTypeField from '../equipment_type/EquipmentTypeField';
import EquipmentTypeContainerField from '../equipment_type/EquipmentTypeContainerField';
import EquipmentTypeChassisField from '../equipment_type/EquipmentTypeChassisField';
import EquipmentOwnerTypeDropdownField from './EquipmentOwnerTypeDropdownField';
import CarrierListField from '../carrier/CarrierListField';
import CarrierOwnerOperatorListField from '../carrier/CarrierOwnerOperatorListField';
import CoreDateField from '../core/CoreDateField';
import CoreNumberField from '../core/CoreNumberField';

const EquipmentCreateDialogContent = (props) => {
  const {
    resetForm,
    isOpen,
    equipmentFormData,
    onCreate,
    errorMessage,
    equipmentFormValidation,
    updateEquipmentFormData,
    persistedUnitNum
  } = props;

  const submitForm = (e) => {
    onCreate(e, persistedUnitNum);
  }

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form>
        <DialogTitle id="form-dialog-title">Create Equipment</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={6} xs={12}>
              <CoreTextField
                name="UnitNum"
                label="Unit Number"
                value={equipmentFormData.UnitNum}
                onChange={e => updateEquipmentFormData(e)}
                isRequired={true}
                validationError={equipmentFormValidation.UnitNum}
                autoFocus={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EquipmentUnitTypeDropdownField
                value={equipmentFormData.UnitType}
                onChange={e => updateEquipmentFormData(e)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {equipmentFormData.UnitType === 2 &&
                <EquipmentTypeContainerField
                  name="EquipmentType"
                  label="Equipment Type"
                  value={equipmentFormData.EquipmentType}
                  onChange={e => updateEquipmentFormData(e)}
                />
              }
              {equipmentFormData.UnitType === 3 &&
                <EquipmentTypeChassisField
                  name="EquipmentType"
                  label="Equipment Type"
                  value={equipmentFormData.EquipmentType}
                  onChange={e => updateEquipmentFormData(e)}
                />
              }
              {(equipmentFormData.UnitType !== 2 && equipmentFormData.UnitType !== 3) &&
                <EquipmentTypeField
                  name="EquipmentType"
                  label="Equipment Type"
                  value={equipmentFormData.EquipmentType}
                  onChange={e => updateEquipmentFormData(e)}
                />
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <EquipmentOwnerTypeDropdownField
                value={equipmentFormData.OwnerType}
                onChange={e => updateEquipmentFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {equipmentFormData.OwnerType === 1 && 
                <CarrierOwnerOperatorListField
                  name="OwnerCarrierName"
                  label="Owner Operator"
                  value={equipmentFormData.OwnerCarrierName}
                  nameData="OwnerCarrierID"
                  valueData={equipmentFormData.OwnerCarrierID}
                  onChange={e => updateEquipmentFormData(e)}
                  validationError={equipmentFormValidation.OwnerCarrierID}
                />
              }
              {equipmentFormData.OwnerType === 2 && 
                <CarrierListField
                  name="OwnerCarrierName"
                  label="Vendor"
                  value={equipmentFormData.OwnerCarrierName}
                  nameData="OwnerCarrierID"
                  valueData={equipmentFormData.OwnerCarrierID}
                  onChange={e => updateEquipmentFormData(e)}
                  validationError={equipmentFormValidation.OwnerCarrierID}
                />
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <CoreDateField
                name="ServiceStart"
                label="Active Date"
                value={equipmentFormData.ServiceStart}
                validationError={equipmentFormValidation.ServiceStart}
                isRequired={true}
                onChange={e => updateEquipmentFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={12} className="mb-2">
              <CoreNumberField
                name="OutsideLengthFt"
                label="Size"
                minValue={0}
                value={equipmentFormData.OutsideLengthFt}
                onChange={e => updateEquipmentFormData(e)}
                validationError={equipmentFormValidation.Axles}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button type="button" color="primary" onClick={e => submitForm(e)}>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EquipmentCreateDialogContent;