import { Route } from 'react-router-dom';
import IntermodalPaymentList from './IntermodalPaymentList';

const IntermodalPaymentRouter = () => {
  return (
    <>
      <Route exact path="/spark/settlement" component={IntermodalPaymentList} />
    </>
  );

}

export default IntermodalPaymentRouter;