import './App.css';
import React, { useEffect, useState } from 'react';
import AuthenticationRouter from './authentication/AuthenticationRouter';
import { isTokenValid } from './authentication/Authentication.service';
import CoreApp from './core/CoreApp';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createMuiTheme  } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import IconClose from './icon/IconClose';
import { makeStyles } from '@material-ui/core/styles';

import { FeePayContext } from '@envasetechnologies/feepay-components';
import { getToken, CLIENT_ID, BASE_URL } from './feePay/FeePayService';

import FeePaySnackBarContext from './feePay/FeePaySnackBarContext';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Inter',
      'cursive',
    ].join(','),
  }
});

const useStyles = makeStyles(() => ({
  root: { 
    background: 'green'
  },
  buttonText: { 
    textDecoration: 'none',
    color: 'white'
  },
  closeButton: {
    filter: 'invert(1)'
  }
}));

const App = () => {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [feePayContext, setFeePayContext] = useState({
    activeInvoice: "",
    jwtFactory: getToken,
    baseUrl: BASE_URL,
    customerId: "",
    clientId: CLIENT_ID,
    isCustomerLoaded: false
  });

  const [feePaySnackBarContext, setFeePaySnackBarContext] = useState({
    message: '',
    isOpen: false,
    pdfDownloadLink: ''
  });

  useEffect(() => {
    async function checkToken() {
      setIsLoading(true);
      const result = await isTokenValid();
      setIsLoggedIn(result);
      setIsLoading(false);
    };
    checkToken();
  }, []);

  const updateFeePayContext = (customer) => {
    feePayContext.customerId = customer.id;
    feePayContext.isCustomerLoaded = true;
    setFeePayContext({ ...feePayContext });
  };

  const buildFeePayPaymentSnackBar = () => { 
    return (
      <Snackbar 
        message={feePaySnackBarContext.message}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}} 
        autoHideDuration={30000}
        open={feePaySnackBarContext.isOpen}
        ContentProps={{
          classes: { 
            root: classes.root
          }
        }}
        action={
          <>
            {feePaySnackBarContext.pdfDownloadLink && 
              <Button onClick={() => handleSnackContextClose()}>
                <a className={classes.buttonText} href={feePaySnackBarContext.pdfDownloadLink} download="N4 Receipt">Download Invoice</a>
              </Button>
            }
            <IconButton className={classes.closeButton} onClick={() => handleSnackContextClose()}>
              <IconClose />
            </IconButton>
          </>
        }
      />
    );
  };

  const handleSnackContextClose = () => { 
    setFeePaySnackBarContext({...feePaySnackBarContext, isOpen: false}); 
  };

  if (isLoading) return null;
  return (
    <FeePayContext.Provider value={feePayContext}>
      <FeePaySnackBarContext.Provider value={{feePaySnackBarContext, setFeePaySnackBarContext}}>
        {buildFeePayPaymentSnackBar()}
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              {isLoggedIn && <Route path="/spark" render={(props) =>
                  <CoreApp updateFeePayContext={updateFeePayContext}/>
                } 
              /> }          
              <Route path="/" component={AuthenticationRouter} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </FeePaySnackBarContext.Provider>
    </FeePayContext.Provider>
  );
}

export default App;
