import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './AreaTagService';
import AreaTagCreateDialogContent from '../area_tag/AreaTagCreateDialogContent';

const AreaTagCreateDialog = (props) => {
  const {
    isOpen,
    onClose
  } = props;

  const initialState = {
    Name: '',
  };

  const [areaTagFormData, setAreaTagFormData] = useState(initialState);
  const [areaTagFormValidation, setAreaTagFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAreaTagFormValidation(buildValidation(areaTagFormData));
    }
  }, [areaTagFormData, isOpen]);

  const updateAreaTagFormData = e => {
    const { name, value } = e.target;
    areaTagFormData[name] = value;
    setAreaTagFormData({ ...areaTagFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(areaTagFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(areaTagFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error[0]);
      return;
    }
    closeDialog(result.response);
  };

  const closeDialog = (areaTagID) => {
    setAreaTagFormData({...initialState});
    onClose(areaTagID);
  };

  return (
    <>
      {isOpen &&
      <AreaTagCreateDialogContent
        isOpen={isOpen}
        onClose={closeDialog}
        onCreate={onCreate}
        errorMessage={errorMessage}
        areaTagFormData={areaTagFormData}
        areaTagFormValidation={areaTagFormValidation}
        updateAreaTagFormData={updateAreaTagFormData}
        inProgress={inProgress}
      />}      
    </>
  );
}

const buildValidation = (areaTagFormData) => {
  const {
    Name,
  } = areaTagFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name),
  };
};

export default AreaTagCreateDialog;