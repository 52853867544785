import React, { useState, useEffect } from 'react';
import CoreAutocompleteField from '../core/CoreAutocompleteField';

import { PaymentMethodTypeLabelEnum } from '@envasetechnologies/feepay-components';

const FeePayPaymentMethodField = (props) => { 
  const {
    label,
    value,
    name,
    isRequired,
    validationError,
    onChange,
    variant,
    noDefault,
    paymentIntent,
    ...rest
  } = props;

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    function getPaymentMethods() {
      let methods = mapPaymentMethodTypes(PaymentMethodTypeLabelEnum);
      setPaymentMethodList(methods);
      setIsLoading(false);
    }
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  const mapPaymentMethodTypes = (methodTypes) => { 
    var arr = [];
    Object.keys(methodTypes).forEach(key => { 
      if (key.toLowerCase().includes("bank")) { 
        arr.push({Value: key, Name: methodTypes[key]});
      }
    });
    return arr;
  };

  const onTypeSelected = newValue => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue ? newValue.Value : ''
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option) {
      return option.Name;
    }
  };

  const getDefaultValue = () => {
    const paymentMethod = paymentMethodList
      .find(paymentMethod => paymentMethod.Value === value);
    if (paymentMethod) {
      return paymentMethod.Name;
    }
    return '';
  };

  return ( 
    !isLoading &&
    <CoreAutocompleteField
      label={label}
      value={value}
      name={name}
      isRequired={isRequired}
      validationError={validationError}
      onChange={onTypeSelected}
      getDefaultValue={getDefaultValue}
      getOptionSelected={getOptionSelected}
      options={paymentMethodList}
      variant={variant}
      {...rest}
    />
  );
};

export default FeePayPaymentMethodField;