import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DispatchSvg } from '../assets/svg-icons/dispatch@1x.svg'

const IconDispatch = () => {
  return (
    <SvgIcon fontSize="small">
      <DispatchSvg />
    </SvgIcon>
  )
}

export default IconDispatch;