import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'EquipmentType/' + token + '/';

export async function getEquipmentType(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id);
  return result.data[0];
};

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  const response = result.data;
  for (const equipmentType of response) {
    equipmentType.FullPath = equipmentType.FullPath.trim();
  }
  return response;
};

export async function create(equipmentTypeFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', null, equipmentTypeFormData);
  return result;
};

export async function updateEquipmentType(equipmentTypeFieldFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + equipmentTypeFieldFormData.EquipmentTypeID + '/Update', null, equipmentTypeFieldFormData);
  return result;
};

export async function deleteEquipmentType(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};