import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'ConfigurationReportField/' + token + '/';

export async function get(id) {
  var params = { 
    ReportFieldID: id 
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get', params);
  return result.data[0];
};

export async function getByFieldName(fieldName) {
  var params = { 
    FieldName: fieldName 
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetByFieldName', params);
  return result.data;
};

export async function getList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetList');
  return result.data;
};

export async function create(documentFieldFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', documentFieldFormData);
  return result;
};

export async function update(documentFieldFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', documentFieldFormData);
  return result;
};

export async function deleteDocumentField(id) {
  var params = {
    id: id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
};

export async function getForSpinnerCompare(value) {
  var params = {compareValue: value ? value : ''};
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinner', params);
  const response = result.data;
  var documents = [];
  for (const doc of response) {
    if (doc.FieldName.includes("Invoice Advertisement") || doc.FieldName.includes("Invoice Notice")) {
      doc.Name = doc.FieldName.trim();
      documents.push(doc);
    }
  }
  return documents;
};