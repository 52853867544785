import OrderCreateButton from './OrderCreateButton';
import OrderListTable from './OrderListTable';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';

const OrderList = () => {
  return (
    <div>
      <CorePageHeader
        title="Order"
        breadCrumb="Home / ... / Order List"
        createButton={
          <OrderCreateButton
            onCloseRoute="/spark/order"
          />
        } />
      <Container maxWidth="false" className="mt-4">
        <OrderListTable />
      </Container>
    </div>
  );

}

export default OrderList;