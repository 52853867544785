import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import CoreTextField from '../core/CoreTextField';
import Validation from '../validation';
import { Create } from './PointToPointTemplateService';

const PointToPointTemplateCreateDialog = (props) => { 
  const { 
    isOpen,
    onClose,
    persistedName
  } = props;

  const initialState = { 
    Name: ''
  };

  const [rateTemplateFormData, setRateTemplateFormData] = useState(initialState);
  const [rateTemplateFormValidation, setRateTemplateFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
        setRateTemplateFormValidation(buildValidation(rateTemplateFormData));
    }
  }, [rateTemplateFormData, isOpen]);

  useEffect(() => { 
    setRateTemplateFormData(l => ({ ...l, Name: persistedName || '' }));
  }, [isOpen, persistedName]);

  const updateRateTemplateFormData = e => { 
    const { name, value } = e.target;
    rateTemplateFormData[name] = value;
    setRateTemplateFormData({ ...rateTemplateFormData });
  };

  const onCreate = e => { 
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(rateTemplateFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => { 
    setErrorMessage('');
    const result = await Create(rateTemplateFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    closeDialog(parseInt(result.response), rateTemplateFormData.Name);
  };

  const closeDialog = (response, templateName) => { 
    setRateTemplateFormData(initialState);
    if (response) {
      onClose(response, templateName);
    } else {
      onClose();
    }
  };

  return ( 
    isOpen &&
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Point-to-Point Rate Template</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="Name"
                label="Template Name"
                value={rateTemplateFormData.Name}
                onChange={e => updateRateTemplateFormData(e)}
                isRequired={true}
                autoFocus={true}
                validationError={rateTemplateFormValidation.Name}
              />
            </Grid>
          </Grid>
          {errorMessage && <Grid item md={12} xs={12} className="mt-3">
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Grid>}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};

const buildValidation = (rateTemplateFormData) => {
  const {
    Name
  } = rateTemplateFormData || {};
  const validation = new Validation();
  return {
    Name: validation.isEmpty(Name)
  };
};

export default PointToPointTemplateCreateDialog;