import { Route } from 'react-router-dom';
import PersonnelList from './PersonnelList';
import PersonnelCreate from './PersonnelCreate';
import PersonnelView from './PersonnelView';
import PersonnelEdit from './PersonnelEdit';

const PersonnelRouter = () => {
  return (
    <>
      <Route exact path="/spark/personnel" component={PersonnelList} />
      <Route exact path="/spark/personnel/create" component={PersonnelCreate} />
      <Route exact path="/spark/personnel/view/:id" component={PersonnelView} />
      <Route exact path="/spark/personnel/edit/:id" component={PersonnelEdit} />
    </>
  );

}

export default PersonnelRouter;