import React, {useState, useEffect} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getLastFreeDay } from './DashboardService';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '24px 24px 10px 24px',
    textAlign: 'left',
    minHeight: '206px'
  },
  center: {
    textAlign: '-webkit-center',
  },
  fontSmall: {
    fontSize: 'small'
  },
  fontXXLargeGreen: {
    fontSize: 'xx-large',
    color: '#81C784',
  },
  fontXXLargeRed: {
    fontSize: 'xx-large',
    color: '#E57373',
  },
  revLoader: {
    marginTop: '20%',
    justifyContent: 'center',
  },
  tooltipFont: {
    fontSize: 'large',
  },
  tooltip: {
    background: "#fff",
    color: "#000",
    border: "1px solid #000",
    borderRadius: 8
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #000",
      backgroundColor: "#fff",
      boxSizing: "border-box"
    }
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5px"
  },
  orderDisplay: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
}));

const DashboardLastFreeDay = () => {
  const [loading, setLoading] = useState(true)
  const currentDate = new Date();
  const tmrwDate = new Date();
  tmrwDate.setDate(tmrwDate.getDate() + 1);
  const dayAfterTmrwDate = new Date();
  dayAfterTmrwDate.setDate(dayAfterTmrwDate.getDate() + 2);

  const [today, setToday] = useState({
    Day: currentDate,
    Orders: [],
  });
  const [tmrw, setTmrw] = useState({
    Day: tmrwDate,
    Orders: [],
  });
  const [dayAfterTmrw, setDayAfterTmrw] = useState({
    Day: dayAfterTmrwDate,
    Orders: [],
  });

  const classes = useStyles();

  useEffect(() => {
    async function getLastFreeDayFunction() { 
      const result = await getLastFreeDay();      
      if(result.length > 0) {       
        today.Orders = result.map((row) => new Date(row.LastFreeDate).getDate() === currentDate.getDate() ? row : null);
        today.Orders = today.Orders.filter(function(item) {return item != null} );
        setToday(today);
        tmrw.Orders = result.map((row) => new Date(row.LastFreeDate).getDate() === tmrwDate.getDate() ? row : null);
        tmrw.Orders = tmrw.Orders.filter(function(item) {return item != null} );
        setTmrw(tmrw);
        dayAfterTmrw.Orders = result.map((row) => new Date(row.LastFreeDate).getDate() === dayAfterTmrwDate.getDate() ? row : null);    
        dayAfterTmrw.Orders = dayAfterTmrw.Orders.filter(function(item) {return item != null} );
        setDayAfterTmrw(dayAfterTmrw);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getLastFreeDayFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTodayTooltipMsg = () => {
    var todayTooltip = [];
    for(const item of today.Orders) {
      todayTooltip.push(<span>Order {item.OrderNum}<br/></span>);
    }
    return todayTooltip;
  };
  
  const createTmrwTooltipMsg = () => {
    var tmrwTooltip = [];
    for(const item of tmrw.Orders) {
      tmrwTooltip.push(<span>Order {item.OrderNum}<br/></span>);
    }
    return tmrwTooltip;
  };

  const createDayAfterTmrwTooltipMsg = () => {
    var dayAfterTmrwTooltip = [];
    for(const item of dayAfterTmrw.Orders) {
      dayAfterTmrwTooltip.push(<span>Order {item.OrderNum}<br/></span>);
    }
    return dayAfterTmrwTooltip;
  };

  const goToOrders = (date) => {
    window.location = '/spark/order?lastFreeDate=' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
  };

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      {loading && <div align="center"><CircularProgress size={60} className={classes.revLoader} /> </div>}      
      {!loading && <Grid container className="mb-1">
        <Grid item xs={12} className="mb-2">
          <Typography variant="subtitle1" style={{display:'flex', justifyContent:'flex-start', fontWeight:'bold'}}>
            Last Free Day
          </Typography>
        </Grid>
        <Grid container className={classes.center}>
          <Grid container item lg={4} md={12} xs={12}>
            <Grid item lg={12} xs={6} className={classes.dates}>
              <span className={classes.fontSmall}>
                {today.Day.toLocaleString("en-US", { weekday: "short" })}
                <br/>
                {today.Day.toLocaleString("en-US", { month: "short" })} {today.Day.toLocaleString("en-US", { day: "2-digit" })}
              </span>
            </Grid>
            <Tooltip   
              disableHoverListener={today.Orders.length <= 0}
              placement={"top"}    
              arrow      
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}              
              title={
                <Typography variant="subtitle2">
                  {createTodayTooltipMsg()}
                </Typography>
              }         
            >
              <Grid item lg={12} xs={6}>
                <Box className={classes.orderDisplay} p={2} borderRadius={'8px'} border={1} height={35} borderColor={"grey.400"} onClick={function() { if (today.Orders.length > 0) {goToOrders(today.Day)}}}>
                  <div>
                    <span className={today.Orders.length > 0 ? classes.fontXXLargeRed : classes.fontXXLargeGreen}>{today.Orders.length}</span>
                    <br/>
                    <span className={classes.fontSmall}>Orders</span>
                  </div>
                </Box>
              </Grid>
            </Tooltip>
          </Grid>
          
          <Grid container item lg={4} md={12} xs={12}>
            <Grid item lg={12} xs={6} className={classes.dates}>
              <span className={classes.fontSmall}>
                {tmrw.Day.toLocaleString("en-US", { weekday: "short" })}
                <br/>
                {tmrw.Day.toLocaleString("en-US", { month: "short" })} {tmrw.Day.toLocaleString("en-US", { day: "2-digit" })}
              </span>
            </Grid>
            <Tooltip   
              disableHoverListener={tmrw.Orders.length <= 0}
              placement="top"    
              arrow       
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}              
              title={
                <Typography variant="subtitle2">
                  {createTmrwTooltipMsg()}
                </Typography>
              }
            >
              <Grid item lg={12} xs={6}>
                <Box className={classes.orderDisplay} p={2} borderRadius={'8px'} border={1} height={35} borderColor={"grey.400"} onClick={function() { if (tmrw.Orders.length > 0) {goToOrders(tmrw.Day)}}}>
                  <div>
                    <span className={tmrw.Orders.length > 0 ? classes.fontXXLargeRed : classes.fontXXLargeGreen}>{tmrw.Orders.length}</span>
                    <br/>
                    <span className={classes.fontSmall}>Orders</span>
                  </div>
                </Box>
              </Grid>
            </Tooltip>
          </Grid>
          
          <Grid container item lg={4} md={12} xs={12}>
            <Grid item lg={12} xs={6} className={classes.dates}>
              <span className={classes.fontSmall}>
                {dayAfterTmrw.Day.toLocaleString("en-US", { weekday: "short" })}
                <br/>
                {dayAfterTmrw.Day.toLocaleString("en-US", { month: "short" })} {dayAfterTmrw.Day.toLocaleString("en-US", { day: "2-digit" })}
              </span>
            </Grid>
           <Tooltip
              disableHoverListener={dayAfterTmrw.Orders.length <= 0}
              placement="top"    
              arrow       
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              title={
                <Typography variant="subtitle2">
                  {createDayAfterTmrwTooltipMsg()}
                </Typography>
              }
            >
              <Grid item lg={12} xs={6}>
                <Box className={classes.orderDisplay} p={2} borderRadius={'8px'} border={1} height={35} borderColor={"grey.400"} onClick={function() { if (dayAfterTmrw.Orders.length > 0) {goToOrders(dayAfterTmrw.Day)}}}>
                  <div>
                    <span className={dayAfterTmrw.Orders.length > 0 ? classes.fontXXLargeRed : classes.fontXXLargeGreen}>{dayAfterTmrw.Orders.length}</span>              
                    <br/>
                    <span className={classes.fontSmall}>Orders</span>
                  </div>
                </Box> 
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>}
    </Paper>
  );

}

export default DashboardLastFreeDay;
