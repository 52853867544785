import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import CoreSummarySectionTitle from '../core/CoreSummarySectionTitle';
import IconInternalName from '../icon/IconInternalName';
import IconLegalName from '../icon/IconLegalName';
import IconLocationLight from '../icon/IconLocationLight';

const useStyles = makeStyles(() => ({
  iconSize: {
    '& svg': {
      fontSize: '26px',
      marginTop: '-3px'
    }
  }
}));

const CustomerViewSummaryInfoSection = (props) => {
  const classes = useStyles();

  const {
    customer
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CoreSummarySectionTitle title="CLIENT INFORMATION" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>
            <CoreTextDisplayField
              label="Internal Name"
              value={customer.InternalName}
              icon={<IconInternalName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Legal Name"
              value={customer.LegalName}
              icon={<IconLegalName />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{fontSize: '14px'}} className={classes.iconSize}>     
            <CoreTextDisplayField
              label="Address"
              value={customer.CustAddress}
              icon={<IconLocationLight />}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default CustomerViewSummaryInfoSection;