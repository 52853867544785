import { React, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CoreTextField from '../core/CoreTextField';
import CoreDateField from '../core/CoreDateField';
import CoreTimeField from '../core/CoreTimeField';

const useStyles = makeStyles(() => ({
  line: { 
    opacity: '0.3'
  }
}));

const OrderCreateContentVesselInfo = (props) => {
  const classes = useStyles();
  const {
    orderFormData,
    orderFormValidation,
    updateOrderFormData
  } = props;
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => { 
    setHeight(ref.current.clientHeight);
  }, [orderFormData]);

  return (
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container md={12} xs={12} alignItems="flex-start">
          <Grid container md={6} xs={6} spacing={2}>
            <Grid item md={12} xs={12}>
              <h4>Vessel Information</h4>
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="vesselName"
                label="Vessel Name"
                value={orderFormData.vesselName}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.vesselName}
                maxLength={100}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="vesselVoyager"
                label="Voyage"
                value={orderFormData.vesselVoyager}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.vesselVoyager}
                maxLength={100}
              />
            </Grid>
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="vesselDate"
                label="Estimated Arrival Date"
                value={orderFormData.vesselDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="vesselTime"
                label="Estimated Arrival Time"
                value={orderFormData.vesselTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="vesselActualDate"
                label="Actual Arrival Date"
                value={orderFormData.vesselActualDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="vesselActualTime"
                label="Actual Arrival Time"
                value={orderFormData.vesselActualTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
          </Grid>
          <hr className={classes.line} style={{height: height}}></hr>
          <Grid ref={ref} container md={6} xs={6} spacing={2} className="mb-2">
            <Grid item md={12} xs={12}>
              <h4>Container Availability</h4>
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreDateField
                name="containerReleaseDate"
                label="Release Date"
                value={orderFormData.containerReleaseDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <CoreTimeField
                name="containerReleaseTime"
                label="Release Time"
                value={orderFormData.containerReleaseTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="containerLastDate"
                label="Last Free Day Date"
                value={orderFormData.containerLastDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type !== "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="containerLastTime"
                label="Last Free Day Time"
                value={orderFormData.containerLastTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === 'Export' && <Grid item md={6} xs={6}>
              <CoreDateField
                name="cutoffDate"
                label="Cut Off Date"
                value={orderFormData.cutoffDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === 'Export' && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="cutoffTime"
                label="Cut Off Time"
                value={orderFormData.cutoffTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === 'Export' && <Grid item md={6} xs={6}>
              <CoreDateField
                name="firstReceivedDate"
                label="First Received Date"
                value={orderFormData.firstReceivedDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === 'Export' && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="firstReceivedTime"
                label="First Received Time"
                value={orderFormData.firstReceivedTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            <Grid item md={6} xs={6}>
              <CoreTextField
                name="importExportReportedby"
                label="Reported By"
                value={orderFormData.importExportReportedby}
                onChange={e => updateOrderFormData(e)}
                validationError={orderFormValidation.importExportReportedby}
                maxLength={255}
              />
            </Grid>
            <Grid item md={6} xs={6}></Grid>
            {orderFormData.type === "Import" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="emptyReadyDate"
                label="Empty Ready Date"
                value={orderFormData.emptyReadyDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === "Import" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="emptyReadyTime"
                label="Empty Ready Time"
                value={orderFormData.emptyReadyTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === "Export" && <Grid item md={6} xs={6}>
              <CoreDateField
                name="loadReadyDate"
                label="Load Ready Date"
                value={orderFormData.loadReadyDate}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
            {orderFormData.type === "Export" && <Grid item md={6} xs={6}>
              <CoreTimeField
                name="loadReadyTime"
                label="Load Ready Time"
                value={orderFormData.loadReadyTime}
                onChange={e => updateOrderFormData(e)}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Container>
  );

}

export default OrderCreateContentVesselInfo;