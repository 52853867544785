import React from 'react';
import PointToPointTemplateField from './PointToPointTemplateField';

const PointToPointTemplateSection = (props) => {
  const {
    autoPayFormData,
    updateAutoPayFormData,
  } = props;

  return (
    <>      
      <PointToPointTemplateField
        name="PointToPointTemplateName"
        label="Point-to-Point Template"
        pointToPointTemplateForEdit={autoPayFormData.PointToPointTemplateName}
        value={autoPayFormData.PointToPointTemplateName}
        nameData="PointToPointTemplateID"
        valueData={autoPayFormData.PointToPointTemplateID}
        size={"small"}
        onChange={e => updateAutoPayFormData(e)}
      />
    </>
  );

}

export default PointToPointTemplateSection;