import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(() => ({
  permissionOverlay: {
    position: 'absolute',
    top: '0',
    zIndex: '1',
    width: '100%',
    height: '100%',
    marginTop: '65px',
    cursor: 'not-allowed'
  }
}));

const CorePermissionToEditOverlay  = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const classes = useStyles();

  return (
    <>
      <div className={classes.permissionOverlay} onClick={handleOpen}>
      </div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title" className="infoDialog" onBackdropClick={handleClose}>
        <DialogTitle id="form-dialog-title">Permissions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Permission required to edit this content.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CorePermissionToEditOverlay;
