import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)',
    width: '90px',
    height: '36px'
  },
  iconColor: {
    filter: 'invert(0)',
    paddingTop: '5px'
  }
}));

const CoreEditButton = (props) => {
  const classes = useStyles();

  const {
    editView
  } = props;
  let history = useHistory();

  const onEditClick = () => { 
    history.push(editView);
  }

  return (
    <Button 
      variant="outlined" 
      color="primary" 
      className={classes.buttonColor} 
      onClick={onEditClick} 
      startIcon={<EditIcon/>}>
      EDIT
    </Button>
  );
}

export default CoreEditButton;