import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreAddButton from '../core/CoreAddButton';
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import PointToPointTemplateLineColumnEditButton from './PointToPointTemplateLineColumnEditButton';
import { Delete as deleteTemplateLine } from './PointToPointTemplateLineService';

const PointToPointTemplateLineListTable = (props) => { 
  const { 
    templateLines,
    getTemplateLines,
    setTemplateLines,
    openTemplateLineCreateDialog,
    isPersonnelAutoPay,
    personnelID,
    updatePersonnelPointToPointTemplate,
  } = props;

  let columns = [
    {
      field: 'OriginAreaTagName',
      headerName: 'Origin',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("OriginAreaTag") ? params.getValue("OriginAreaTag").Name : null;
        return (
          <span>{name}</span>
        )
      }
    },
    {
      field: 'DestinationAreaTagName',
      headerName: 'Destination',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("DestinationAreaTag") ? params.getValue("DestinationAreaTag").Name : null;
        return (
          <span>{name}</span>
        )
      }
    },
    {
      field: 'PayableChargeTypeName',
      headerName: 'Expense Item',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("PayableChargeType") ? params.getValue("PayableChargeType").Name : null;
        return (
          <span>{name}</span>
        )
      }
    },
    {
      field: 'Units',
      headerName: 'Units',
      align: 'left',
      flex: 0.5,
      sortable: false
    },
    {
      field: 'UnitRate',
      headerName: 'Unit Rate',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'TwoWay',
      headerName: 'Two-Way',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => { 
        const isTwoWay = params.getValue("TwoWay");
        return ( 
          <span>{isTwoWay ? "Yes" : "No"}</span>
        )
      }
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openTemplateLineCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        const showControls = params.getValue("ShowControls") || false;
        const id = params.getValue("ID");
        return (
          <div>
            {showControls &&
              <PointToPointTemplateLineColumnEditButton
                pointToPointTemplateLineID={id}
                getPointToPointTemplateLines={getTemplateLines}
                isPersonnelAutoPay={isPersonnelAutoPay}
                personnelID={personnelID}   
                updatePersonnelPointToPointTemplate={updatePersonnelPointToPointTemplate}           
              />
            }
            {showControls &&
              <CoreListColumnDeleteButton
                reference={` this Point-To-Point Template Line`}
                deleteID={id}
                deleteFunction={deleteTemplateLine}
                getList={getTemplateLines}
              />
            }
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
  };

  const onTemplateLineRowEnter = (params) => {
    for (const item of templateLines) {
      if (item.ID === params.row.ID) {
        item.ShowControls = true;
      }
    }
    setTemplateLines([...templateLines]);
  };

  const onTemplateLineRowLeave = (params) => {
    for (const item of templateLines) {
      if (item.ID === params.row.ID) {
        item.ShowControls = false;
      }
    }
    setTemplateLines([...templateLines]);
  };    

  return ( 
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        autoHeight={true} 
        rows={templateLines} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onTemplateLineRowEnter(e)}
        onRowLeave={e => onTemplateLineRowLeave(e)}
      />
    </Container>
  )
};

export default PointToPointTemplateLineListTable;