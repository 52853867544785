import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CoreCheckboxField = (props) => {
  const {
    label,
    value,
    name,
    index,
    onChange,
    rate,
    disabled
  } = props;

  const [toggleSelected, setToggleSelected] = useState(false);

  useEffect(() => {
    setToggleSelected(value);
  }, [value]);

  const onToggleEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue,
        index: index ? index : null,
        rate: rate ? rate : 0
      }
    };
    onChange(dispatchEvent);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={toggleSelected}
            onChange={(e, newValue) => {
                onToggleEvent(newValue)         
                setToggleSelected(newValue)
              }              
            }
            name={name}
            style={{color: !disabled ? "#653AC2" : "#BDBDBD"}}
          />
        }
        disabled={disabled}
        label={label}
      />
    </FormGroup>
  );
}

export default CoreCheckboxField;