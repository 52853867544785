import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';

const AreaTagCreateDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    areaTagFormData,
    onCreate,
    errorMessage,
    areaTagFormValidation,
    updateAreaTagFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <form onSubmit={onCreate}>
        <DialogTitle id="form-dialog-title">Create Area Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">            
            <Grid item md={12} xs={12}>
                <CoreTextField
                  autoFocus={true}
                  name="Name"
                  label="Name"
                  value={areaTagFormData.Name}
                  onChange={e => updateAreaTagFormData(e)}
                  isRequired={true}
                  validationError={areaTagFormValidation.Name}
                />
            </Grid>           
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(null)} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default AreaTagCreateDialogContent;