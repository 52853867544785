import { format } from 'date-fns';

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function downloadFile(response, fileName) {
  if (response.byteLength < 30) {
    return;
  }
  var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
  var blob = new Blob([response]);
  var url = urlCreator.createObjectURL(blob);
  var a = document.createElement("a");
  a.target = '_blank';
  a.style = "display: none";
  a.href = url;
  a.download = fileName; //you may assign this value from header as well 
  a.click();
  window.URL.revokeObjectURL(url);
};

export function mergeDateTime(date, time) {
  var firstDate = new Date(date);
  if (time) {
    var secondDate = new Date(time);    
    firstDate.setHours(secondDate.getHours());
    firstDate.setMinutes(secondDate.getMinutes());
    firstDate = new Date(date).getFullYear() + '-' + ('0' + (new Date(date).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(date).getDate()).slice(-2) + ' ' + ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2);
    return firstDate;
  } else {
    firstDate.setHours(0);
    firstDate.setMinutes(0);
    return (new Date(firstDate.setMinutes(0)));
  }
};

export function setDateTimeToZero(date) {
  if (date) {
    return format(new Date(date), 'MM/dd/yyyy');
  } 
  return null;
}

export function formatDateTime(date, time) {
  if (date && time) {
    return mergeDateTime(date, time)
  } 
  if (date && !time)  {
    return setDateTimeToZero(date);
  }
  return null
};

export function getDateTimeString(input) {
  if (!input) {
    return null;
  }
  const date = new Date(input);
  return date.getFullYear() + '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
    date.getDate().toString().padStart(2, '0') + ' ' +
    date.getHours().toString().padStart(2, '0') + ':' +
    date.getMinutes().toString().padStart(2, '0');
};

export function getTimeString(input) {
  if (!input) {
    return null;
  }
  const date = new Date(input);
  return date.getHours().toString().padStart(2, '0') + ':' +
    date.getMinutes().toString().padStart(2, '0');
};