import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconCurrency from '../icon/IconCurrency';

const NavigationDrawerListItemReport = () => { 
  return ( 
    <NavigationDrawerListItem
      title={"Reports"}
      icon={<IconCurrency />}
      uri={"/spark/reports"}
    />
  );
};

export default NavigationDrawerListItemReport;