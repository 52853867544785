import { Route } from 'react-router-dom';
import Reports from './Reports';
import ReportsProfitByCustomerSummary from './ReportsProfitByCustomerSummary';
import ReportsProfitByCustomerDetail from './ReportProfitByCustomerDetail';
import ReportsCustomerLogs from './ReportsCustomerLogs';
import { getPersonnelID } from '../account/AccountService';
import { getFull } from '../personnel/PersonnelService';
import { getIsOwner } from '../admin/AdminService';
import { useEffect, useState } from 'react';

const ReportsRouter = () => {
  const [isOwner, setIsOwner] = useState(false);
  const [isSupportPersonnel, setIsSupportPersonnel] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsOwner(await getIsOwner());

      const personnelID = await getPersonnelID();
      const fullPersonnelRecord = await getFull(personnelID);
      if (fullPersonnelRecord.LoginID.trim() === "CMS" || fullPersonnelRecord.Email.trim() === "twsupport@envasetechnologies.com") {
        setIsSupportPersonnel(true);
      }
    };

    fetchUserData();
  }, []);

  return ( 
    <>
      <Route exact path="/spark/reports" component={Reports} />
      <Route exact path="/spark/reports/profit-by-customer-summary" component={ReportsProfitByCustomerSummary} />
      <Route exact path="/spark/reports/profit-by-customer-detail" component={ReportsProfitByCustomerDetail} />
      {
        isOwner && 
        isSupportPersonnel && 
        <Route exact path="/spark/reports/customer-logs-report" component={ReportsCustomerLogs} />
      }
    </>
  )
};

export default ReportsRouter;