import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconEquipment from '../icon/IconEquipment';

const NavigationDrawerListItemEquipment = () => {
  return (
    <NavigationDrawerListItem
      title={'Equipment'}
      icon={<IconEquipment />}
      uri={'/spark/equipment'} />
  );

}

export default NavigationDrawerListItemEquipment;