import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import { Create as PersonnelCreate, PersonnelVendorProfileCreate } from './PersonnelService';
import { Create as TravelDocCreate } from '../travel-doc/TravelDocService';
import Validation from '../validation';
import PersonnelCreateContent from './PersonnelCreateContent';
import { getMainBranchID } from '../configuration/ConfigurationService';
import CorePageHeader from '../core/CorePageHeader';
import CoreExitButton from '../core/CoreExitButton';
import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';

const PersonnelCreateView = () => {
  let history = useHistory();

  const [inProgress, setInProgress] = useState(false);
  const [PersonnelFormData, setPersonnelFormData] = useState({
   FirstName: '',
   MiddleName: '',
   LastName: '',
   InternalName: '',
   Address: '',
   City: '',
   Province: '',
   PostalCode: '',
   Country: '',
   Gender: '',
   SIN: '',
   Nationality: '',
   HomePhone: '',
   PersCell: '',
   BusPhone: '',
   Title: '',
   Email:'',
   StartDate: null,
   EndDate: null,
   HealthNum: '',
   EmergencyNumber: '',
   EmergencyContact: '',
   DriverType: 0,
   Check1: false,
   Check1Date: new Date(),
   Check2: false,
   Check2Date: new Date(),
   Check3: false,
   Check3Date: new Date(),
   BranchID: 0,
   CanFunds: 0,
   ListAsDriver: 0
  });

  const [VendorFormData, setVendorFormData] = useState({
    VendorInternalName:'',
    LegalName:'',
    RemitName:'',
    FederalIDNum: '',
    PaymentTerms:0,
    DisAddress:'',
    DisCity:'',
    DisProvince:'',
    DisPostalCode:'',
    DisCountry:'',
    DisPhone:'',
    DisTollFree:'',
    RemAddress:'',
    RemCity:'',
    RemProvince:'',
    RemPostalCode:'',
    RemCountry:'',
    RemPhone:'',
    RemTollFree:'',
    BranchID:0,
    RemAddressSame:false
   });

  const [DriverLicenseFormData, setDriverLicenseFormData] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 8,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );

  const [TWICFormData, setTWICFormData] = useState(
    {
      PersonnelTravelDocID: null, 
      PersonnelID: 0,
      ACETravelDocType: 18,
      DocumentNumber: '',
      Province: '',
      Country: '',
      ExpiryDate: null,
      RemindTaskID: 0
    }
  );

  const [PersonnelFormValidation, setPersonnelFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [coordinates, setCoordinates] = useState({'lat': 37, 'lng': -95});

  useEffect(() => {
    setPersonnelFormValidation(buildValidation(PersonnelFormData));
  }, [PersonnelFormData]);

  useEffect(() => { 
    function getAddressGeocode() {
      if(PersonnelFormData.DriverType !== 2 ) {
        getGeocode(
          PersonnelFormData.Address, 
          PersonnelFormData.City, 
          PersonnelFormData.Province, 
          PersonnelFormData.Country, 
          PersonnelFormData.PostalCode)
          .then(function (response) {
            setCoordinates(response);
          });
      }
    }
    getAddressGeocode();
  }, [
    PersonnelFormData.Address, 
    PersonnelFormData.City, 
    PersonnelFormData.Province, 
    PersonnelFormData.PostalCode, 
    PersonnelFormData.Country, 
    PersonnelFormData.DriverType
  ]);

  useEffect(() => { 
    function getAddressGeocode() {
      if(PersonnelFormData.DriverType === 2 ) {
        getGeocode(
          VendorFormData.DisAddress, 
          VendorFormData.DisCity, 
          VendorFormData.DisProvince, 
          VendorFormData.DisCountry, 
          VendorFormData.DisPostalCode)
          .then(function (response) {
            setCoordinates(response);
        });
      } 
    }
    getAddressGeocode();
  }, [
    VendorFormData.DisAddress, 
    VendorFormData.DisCity, 
    VendorFormData.DisProvince, 
    VendorFormData.DisPostalCode, 
    PersonnelFormData.DriverType
  ]);

  useEffect(() => {
    async function setDefaultBranch() {
      const branchID = await getMainBranchID();
      setPersonnelFormData(p => ({ ...p, BranchID: branchID }));
      setVendorFormData(v => ({ ...v, BranchID: branchID }));
    }
    setDefaultBranch();
  }, [setPersonnelFormData]);

  const updatePersonnelFormData = (e) => {
    const {name, value, nameData, valueData} = e.target;
    PersonnelFormData[name] = value;
    PersonnelFormData[nameData] = valueData;
    setPersonnelFormData({ ...PersonnelFormData });
  };

  const updateVendorFormData = (e) => {
    const {name, value} = e.target;
    VendorFormData[name] = value;
    setVendorFormData({ ...VendorFormData });
  };

  const updateDriverLicenseFormData = (e) => {
    const {name, value} = e.target;
    DriverLicenseFormData[name] = value;
    setDriverLicenseFormData({ ...DriverLicenseFormData });
  };

  const updateTWICFormData = (e) => {
    const {name, value} = e.target;
    TWICFormData[name] = value;
    setTWICFormData({ ...TWICFormData });
  };

  const setDriverFlag = () => {
    if (!PersonnelFormData.ListAsDriver) {
      PersonnelFormData.ListAsAccountRep = 1;
      PersonnelFormData.ListAsDispatcher = 1;
      PersonnelFormData.ListAsSalesRep = 1;
      setPersonnelFormData({ ...PersonnelFormData });
    }    
  };

  const onCreate = e => {
    setInProgress(true);
    setDriverFlag();
    e.preventDefault();
    if (!new Validation().isValidObject(PersonnelFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processCreateRequest();
  };

  const onClose = () => {
    history.push('/spark/personnel');
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    var result;
    if(PersonnelFormData.ListAsDriver === 2) {
      result = await PersonnelVendorProfileCreate(PersonnelFormData, VendorFormData)
    } else {
      result = await PersonnelCreate(PersonnelFormData);
    }
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    processTravelDocCreateRequest(result.response, DriverLicenseFormData);
    processTravelDocCreateRequest(result.response, TWICFormData);
    history.push('/spark/Personnel/view/' + result.response);
  };

  const processTravelDocCreateRequest = async (PersonnelID, travelDoc) => {
    if (!travelDoc.DocumentNumber) {
      return;
    }
    if (travelDoc.ExpiryDate) {
      var date = new Date(travelDoc.ExpiryDate);
      travelDoc.ExpiryDate = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());  
    } else { 
      travelDoc.ExpiryDate = "";
    }
    travelDoc.PersonnelID = PersonnelID;
    travelDoc.Province = PersonnelFormData.Province;
    travelDoc.Country = PersonnelFormData.Country;
    TravelDocCreate(travelDoc);
  };

  return (
    <div>
      <CorePageHeader
        title="Create New Personnel"
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        <PersonnelCreateContent 
          onCreate={onCreate}
          onClose={onClose}
          errorMessage={errorMessage}
          PersonnelFormData={PersonnelFormData}
          PersonnelFormValidation={PersonnelFormValidation}
          updatePersonnelFormData={updatePersonnelFormData}
          VendorFormData={VendorFormData}
          updateVendorFormData={updateVendorFormData}
          DriverLicenseFormData={DriverLicenseFormData}
          updateDriverLicenseFormData={updateDriverLicenseFormData}
          TWICFormData={TWICFormData}
          updateTWICFormData={updateTWICFormData}
          coordinates={coordinates}
          inProgress={inProgress}
        />
      </Container>
    </div>
  );
}

const buildValidation = (PersonnelFormData) => {
  const { 
    FirstName,
    InternalName,
    StartDate,
    Country
  } = PersonnelFormData || {};
  const validation = new Validation();
  return {
    FirstName: validation.isEmpty(FirstName),
    InternalName: validation.isEmpty(InternalName),
    StartDate: validation.isEmpty(StartDate),
    Country: validation.isEmpty(Country)
  };
};

export default PersonnelCreateView;