import { Route } from 'react-router-dom';
import DashboardRouter from '../dashboard/DashboardRouter';
import LocationRouter from '../location/LocationRouter';
import PersonnelRouter from '../personnel/PersonnelRouter';
import CustomerRouter from '../customer/CustomerRouter';
import OrderRouter from '../order/OrderRouter';
import ConfigurationRouter from '../configuration/ConfigurationRouter';
import DispatchRouter from '../dispatch/DispatchRouter';
import EquipmentRouter from '../equipment/EquipmentRouter';
import IntermodalPaymentRouter from '../intermodal_payment/IntermodalPaymentRouter';
import IntermodalInvoiceRouter from '../intermodal_invoice/IntermodalInvoiceRouter';
import NotificationsRouter from '../notifications/NotificationsRouter';
import ChatRouter from '../chat/ChatRouter';
import SubscriptionManagementRouter from '../subscription_management/SubscriptionManagementRouter';
import QuickbooksRouter from '../quickbooks/QuickbooksRouter';
import ItineraryRouter from '../itinerary/ItineraryRouter';
import BillRouter from '../bill/BillRouter';
import ReportsRouter from '../reports/ReportsRouter';
import IncomingLoadRouter from '../incoming_load/IncomingLoadRouter';
import MyAppointmentRouter from '../appointments/MyAppointmentRouter';
import TruckVisitRouter from '../truck_visits/TruckVisitRouter';
import FeePayRouter from '../feePay/FeePayRouter';

const CoreRouter = () => {
  return (
    <>
      <Route exact path="/spark/dashboard" component={DashboardRouter} />
      <Route path="/spark/location" component={LocationRouter} />
      <Route path="/spark/configuration" component={ConfigurationRouter} />
      <Route path="/spark/personnel" component={PersonnelRouter} />
      <Route path="/spark/customer" component={CustomerRouter} />
      <Route path="/spark/order" component={OrderRouter} />
      <Route path="/spark/dispatch" component={DispatchRouter} />
      <Route path="/spark/equipment" component={EquipmentRouter} />
      <Route path="/spark/settlement" component={IntermodalPaymentRouter}/>
      <Route path="/spark/intermodal-invoice" component={IntermodalInvoiceRouter} />
      <Route path="/spark/notifications" component={NotificationsRouter} />
      <Route path="/spark/chat" component={ChatRouter} />
      <Route path="/spark/quickbooks" component={QuickbooksRouter} />
      <Route path="/spark/itinerary" component={ItineraryRouter} />
      <Route path="/spark/subscription-management" component={SubscriptionManagementRouter} />
      <Route path="/spark/bill" component={BillRouter} />
      <Route path="/spark/reports" component={ReportsRouter}></Route>
      <Route path="/spark/incoming-load" component={IncomingLoadRouter}></Route>
      <Route path="/spark/my-appointment" component={MyAppointmentRouter}></Route>
      <Route path="/spark/truck-visit" component={TruckVisitRouter}></Route>
      <Route path="/spark/transaction-history" component={FeePayRouter} />
      <Route path="/spark/cart" component={FeePayRouter} />
      <Route path="/spark/cart-payment" component={FeePayRouter} />
    </>
  );

}

export default CoreRouter;

