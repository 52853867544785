import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getList } from '../document_field/DocumentFieldService';
import ConfigurationViewDocumentFieldSectionListTable from './ConfigurationViewDocumentFieldSectionListTable';
import DocumentFieldCreateDialog from '../document_field/DocumentFieldCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewDocumentFieldSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isDocumentFieldCreateDialogOpen, setIsDocumentFieldCreateDialogOpen] = useState(false);
  const [documentFields, setDocumentFields] = useState({});

  useEffect(() => {
    getList()
      .then(function (response) {
        setDocumentFields(response);
        setInProgress(false);
      });
  }, []);

  const getDocumentFieldsList = () => {
    getList()
      .then(function (response) {
        setDocumentFields(response);
      });
  };

  const openDocumentFieldCreateDialog = () => {
    setIsDocumentFieldCreateDialogOpen(true);
  };

  const closeDocumentFieldCreateDialog = () => {
    setIsDocumentFieldCreateDialogOpen(false);
    getDocumentFieldsList();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Document Fields"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Document Fields"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"This is where you can add/update the customizable text fields that display on the outbound documents"}
                <span style={rightFloat}>
                </span>
              </Typography>
            <ConfigurationViewDocumentFieldSectionListTable
              documentFields={documentFields}
              getDocumentFields={getDocumentFieldsList}
              setDocumentFields={setDocumentFields}
              openDocumentFieldCreateDialog={openDocumentFieldCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Document Fields"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Document Fields"></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <DocumentFieldCreateDialog
        isOpen={isDocumentFieldCreateDialogOpen}
        onClose={closeDocumentFieldCreateDialog}
      />
    </div>
  );

}

export default ConfigurationViewDocumentFieldSection;