import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IntermodalEventListTableDateField from '../intermodalEvent/IntermodalEventListTableDateField';
import IntermodalEventListTableLegDriverField from '../intermodalEvent/IntermodalEventListTableLegDriverField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import { format } from 'date-fns';
import IconLocation from '../icon/IconLocation';

const useStyles = makeStyles(() => ({
  legTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '20px',
    marginBottom: '0px'
  },
  eventTypeTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    textTransform: "uppercase"
  },
  locationTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '0px'
  },
  locationAddressTitle: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginTop: '5px',
    marginBottom: '0px'
  },
  legStatus: {
    color: 'white',
    fontWeight: 'normal',
    borderRadius: '15px',
    padding: '5px',
    fontSize: '12px',
    marginTop: '10px',
    marginBottom: '0px'
  },
  icon: {
    marginTop: '10px',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const OrderViewSummaryTabBarEvent = (props) => {
  const {
    events,
    getEvents,
    setInProgress,
  } = props;

  const classes = useStyles();

  const buildEvents = (intermodalEventsList) => {
    var intermodalEvents = [];
    for (const event of intermodalEventsList) {
      const apptStartDate = event.AppointmentDateStart ? format(new Date(event.AppointmentDateStart), 'MM/dd/yyyy HH:mm') : null;
      const apptEndDate = event.AppointmentDateEnd ? format(new Date(event.AppointmentDateEnd), 'MM/dd/yyyy HH:mm') : null;
      const arrivalDate = event.ActualArrivalDate ? format(new Date(event.ActualArrivalDate), 'MM/dd/yyyy HH:mm') : null;
      const departDate = event.ActualDepartDate ? format(new Date(event.ActualDepartDate), 'MM/dd/yyyy HH:mm') : null;
      intermodalEvents.push(
        <div>
          <Grid container item spacing={1} className="mb-1">
            <Grid item xs={1} className={classes.icon}>
              <IconLocation />
            </Grid>
            <Grid item xs={11}>
              <h5 className={classes.eventTypeTitle}>{event.EventType}</h5>
              <h5 className={classes.locationTitle}>{event.LocationTemplate.InternalName}</h5>
              <h5 className={classes.locationAddressTitle}>{event.LocationTemplate.Address}</h5>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid container item xs={11}>
              <Grid xs={6}>
                <span className={classes.locationAddressTitle}>Appt Start:</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationTitle}>{apptStartDate}</span>
              </Grid>
              <Grid xs={6}>
                <span className={classes.locationAddressTitle}>Appt End:</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationTitle}>{apptEndDate}</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationAddressTitle}>Arrival:</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationTitle}>{arrivalDate}</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationAddressTitle}>Depart:</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.locationTitle}>{departDate}</span>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return intermodalEvents;
  };

  const buildIntermodalLegs = () => {
    var intermodalLegs = [];
    for (const leg of events) {
      intermodalLegs.push(
        <div>
          <Grid container spacing={1} className="mb-1">
            <Grid item md={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <h5 className={classes.legTitle}>{"LEG #" + (leg.LegIndex + 1)}</h5>              
            </Grid>
            <Grid item md={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>              
              {leg.LegStatus === "Unassigned" && <span style={{backgroundColor: '#808080'}} className={classes.legStatus}>{leg.LegStatus}</span>}
              {leg.LegStatus === "Pending" && <span style={{backgroundColor: '#54C7F2'}} className={classes.legStatus}>{leg.LegStatus}</span>}
              {leg.LegStatus === "In Progress" && <span style={{backgroundColor: '#FFA30A'}} className={classes.legStatus}>{leg.LegStatus}</span>}
              {leg.LegStatus === "Completed" && <span style={{ backgroundColor: '#86DC76'}} className={classes.legStatus}>{leg.LegStatus}</span>}
            </Grid>
            <Grid container item spacing={1} xs={12}>
              <Grid item md={6} xs={6}>
                <IntermodalEventListTableLegDriverField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <IntermodalEventListTableDateField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>
            </Grid>
            {buildEvents(leg.IntermodalEvents)}
          </Grid>
          <Divider />
        </div>
      );
    }
    return intermodalLegs;
  };

  return (
    <Container maxWidth="lg">
      { buildIntermodalLegs()}
    </Container>
  );

}

export default OrderViewSummaryTabBarEvent;