import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconTruck from '../icon/IconTruck';

const NavigationDrawerListItemTruckVisits = () => {
  return (
    <NavigationDrawerListItem
      title={'Truck Visits'}
      icon={<IconTruck />}
      uri={'/spark/truck-visit'} />
  );

}

export default NavigationDrawerListItemTruckVisits;