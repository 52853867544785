import HttpService from '../http-service';
import Validation from '../validation';

const token = localStorage.getItem('token');
const uri = 'Customer/' + token + '/';
const validation = new Validation();

export async function get(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetFull');
  return result.data[0];
};

export async function getForSpinnerCompare(value) {
  var params = {compareValue: value ? value : ''};
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinnerCompare', params);
  const response = result.data;
  for (const customer of response) {
    customer.Name = customer.InternalName.trim();
  }
  return response;
};

export async function create(customerFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', customerFormData);
  return result;
};

export async function createCustomerInvCode(data) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + data.CustomerID + '/CreateCustomerInvCode', null, data);
  return result;
};

export async function update(customerFormData) {
    const httpService = new HttpService();
    const result = await httpService
      .post(uri + 'Update', customerFormData);
    return result;
};

export async function getList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetList', params);
  const response = result.data;
  for (const customer of response) {
    if (!validation.isEmpty(customer.CustCity) && !validation.isEmpty(customer.CustProvince)) {
      customer.CustCity += ", ";
    }
    customer.CustCity += customer.CustProvince;
  }
  return response;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ExportList', params);
  return result;
};

export async function getContacts(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetContacts');
  const response = result.data;
  var finalResults = [];
  for (const contact of response) {
    if (!contact.Obsolete) {
      contact.Name = contact.Name.trim();
      contact.Position = contact.Position.trim();
      contact.Email = contact.Email.trim();
      var number = contact.Phone.trim();
      if(number) {
        contact.Phone = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
      }
      finalResults.push(contact);
    } 
  }
  return finalResults;
}

export async function getNotes(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetNotes');
  return result.data;
};

export async function createCustomerContact(id, params) {
  const httpService = new HttpService();
  // Strip the phone
  params.phone = params.phone.replace(/\D/g,'');
  const result = await httpService
    .post(uri + id + '/CreateContact?customerID=' + id + '&name=' + params.name + '&position=' + params.position + '&phone=' + params.phone + '&email=' + params.email + '&extension=' + params.extension + '&isFav=' + params.isFav);
  return result;
};

export async function getInvoiceCodes(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + '/GetInvoiceCodes');
  return result.data;
};

export async function activate(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/Activate');
  return result.data;
};

export async function deactivate(id) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/Obsolete');
  return result.data;
};