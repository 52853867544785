import ContainerValidator from 'container-validator';

const containerValidator = new ContainerValidator();

function isContainerNumberValid(containerNumber) { 
    if (!containerNumber) { 
        return false;
    }
    return containerValidator.isValid(containerNumber);
};

function getContainerNumberErrorMessage(containerNumber) { 
    if (!containerNumber) { 
        return "";
    }
    containerValidator.validate(containerNumber);
    return containerValidator.getErrorMessages();
};

export { isContainerNumberValid, getContainerNumberErrorMessage }; 