import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  iconSelected: {
    filter: 'invert(1)',
  },
  iconDeselected: {
    opacity: '35%',
    filter: 'invert(1)',
  },
  listItemTypography: {
    color: "white",
  }
}));

const NavigationDrawerListItem = (props) => {
  const classes = useStyles();
  
  const { 
    uri, 
    icon, 
    title 
  } = props;
  let history = useHistory();
  let location = useLocation();

  const toggleDrawer = () => {
    history.push(uri);
  };

  const isSelected = () => {
    if (location.pathname.includes(uri)){
      return true;
    }
    return false;
  };

  return (
    <ListItem 
      button 
      key={title} 
      onClick={() => toggleDrawer()} 
      selected={isSelected()}
    >
      <ListItemIcon className={clsx({
        [classes.iconSelected]: isSelected(),
        [classes.iconDeselected]: !isSelected()
      })}>
        {icon}
      </ListItemIcon>
      <ListItemText 
        disableTypography
        primary={
          <Typography className={classes.listItemTypography}>
            {title}
          </Typography>
        }/>
    </ListItem>
  );

}

NavigationDrawerListItem.propTypes = {
  uri: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

export default NavigationDrawerListItem;