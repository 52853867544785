import HttpService from '../http-service';
import { getDriverTypeName } from '../resource/ResourceService';

const token = localStorage.getItem('token');
const uri = 'Personnel/' + token + '/';

export async function getFull(id) {
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + id + '/GetFull');
    if (result && result.data.length > 0) {
      result.data[0].Province = result.data[0].Province.trim();
      result.data[0].Country = result.data[0].Country.trim();
      result.data[0].HomePhone = result.data[0].HomePhone.trim();
      result.data[0].BusPhone = result.data[0].BusPhone.trim();
      result.data[0].EmergencyNumber = result.data[0].EmergencyNumber.trim();
      result.data[0].DriverTypeName = await getDriverTypeName(result.data[0].DriverType);
      return result.data[0];
    }
    return result.data;
};

export async function getVendor(id) {
  const httpService = new HttpService();
  const result = await httpService
      .get(uri + id + '/GetRelatedVendor');
  if (result && result.data.length > 0) {
    result.data[0].VendorInternalName = result.data[0].InternalName;
    result.data[0].DisProvince = result.data[0].DisProvince.trim();
    result.data[0].RemProvince = result.data[0].RemProvince.trim();
    return result.data[0];
  }
  return result.data;
};

const serializeFilterArray = function(filters) {
  var httpString = '';
  for (var i = 0; i < filters.length; i++) {
    httpString += '&columnName[' + i +']=' + filters[i].columnName;
    httpString += '&condition[' + i +']=' + filters[i].condition;
    httpString += '&value[' + i +']=' + filters[i].value;
  }
  return httpString;
};

export async function getList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'getFilteredList?page=' + params.page + '&amount=' + params.amount + '&globalSearch=' + params.globalSearch + '&' + serializeFilterArray(params.filters) + '&isASC=' + params.isASC + '&sortBy=' + params.sortBy + '&showObsolete=' + params.showObsolete);
  return result.data;
};

export async function getListCount(params) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'getListCount?page=' + params.page + '&amount=' + params.amount + '&globalSearch=' + params.globalSearch + '&' + serializeFilterArray(params.filters) + '&isASC=' + params.isASC + '&sortBy=' + params.sortBy + '&showObsolete=' + params.showObsolete);
    return result.data[0].total;
};

export async function exportList(params) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'ExportFilteredList?page=' + params.page + '&amount=' + params.amount + '&globalSearch=' + params.globalSearch + '&' + serializeFilterArray(params.filters) + '&isASC=' + params.isASC + '&sortBy=' + params.sortBy + '&showObsolete=' + params.showObsolete);
  return result;
};

export async function Create(personnelFormData) {
  const httpService = new HttpService();
  const result = await httpService
      .post(uri + 'Create', personnelFormData);
  return result;
};

export async function PersonnelVendorProfileCreate(personnel, vendor) {
  const params = {
    personnel,
    vendor
  }
  const httpService = new HttpService();
  const result = await httpService
      .post(uri + 'CreatePersonnelVendorProfile', params);
  return result;
};

export async function PersonnelVendorProfileUpdate(personnel, vendor) {
  const params = {
    personnel,
    vendor
  }
  const httpService = new HttpService();
  const result = await httpService
      .post(uri + 'UpdatePersonnelVendorProfile', params);
  return result;
};

export async function Update(personnelFormData) { 
  const params = { 
    Personnel: personnelFormData,
    ChangeBillNames: false
  }
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', params);
  return result;
};

export async function getInternalName(id) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + id + "/GetInternalName");
  return result.data[0].InternalName;
};

export async function getAccountRepsList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetAccountRepsForSpinner');
  const response = result.data;
  for (const accountRep of response) {
    accountRep.Name = accountRep.InternalName.trim();
  }
  return response;
};

export async function getDriversList() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDriverForSpinner');
  const response = result.data;
  for (const driver of response) {
    driver.Name = driver.InternalName.trim();
  }
  return response;
};

export async function getPersonnelList() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetForSpinner');
  const response = result.data;
  for (const personnel of response) { 
    personnel.Name = personnel.InternalName.trim();
  }
  return response;
};

export async function getTravelDocs(personnelID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + personnelID + '/GetTravelDocs?personnelID=' + personnelID);
  return result.data;
};

export async function enableEnvaseConnect(personnelID) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'EnableEnvaseConnect?personnelID=' + personnelID);
  return result;
};

export async function disableEnvaseConnect(personnelID) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'DisableEnvaseConnect?personnelID=' + personnelID);
  return result;
};

export async function isEnvaseConnectEnabled(personnelID) {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsEnvaseConnectEnabled?personnelID=' + personnelID);
  return result;
};

export async function resetDriverPassword(personnelID, password){
  const params = {
    personnelID,
    password
  }
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ChangeEnvaseConnectDriverPassword', null, params);
  return result;
};

export async function isLoginEnabled(personnelID) {
  const params = {
    personnelID
  };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsLoginEnabled', params);
  return result.response;
};

export async function activate(id) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/Activate');
  return result.data;
};

export async function deactivate(id) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + id + '/Obsolete');
  return result.data;
};