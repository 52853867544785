import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import DocumentTypeListColumnEditButton from '../document_type/DocumentTypeListColumnEditButton';
import { deleteDocumentType } from '../document_type/DocumentTypeService';
import CoreAddButton from '../core/CoreAddButton';

const ConfigurationViewDocumentTypeSectionListTable = (props) => {
  const {
    documentTypes,
    getDocumentTypes,
    setDocumentTypes,
    openDocumentTypeCreateDialog
  } = props;

  let columns = [
    {
      field: 'Description',
      headerName: 'Name',
      align: 'left',
      flex: 1.5,
      sortable: false,    
    },
    {
      field: 'RelatedTo',
      headerName: 'Document Category',
      align: 'left',
      flex: 1,
      sortable: false
    },
    { 
      field: 'DisplayInApp',
      headerName: 'Display in App',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const displayInApp = params.getValue("DisplayInApp") ? "Yes" : "No";        
        return (
          <div>
            {displayInApp}
          </div>
        )
      }
    },
    { 
      field: 'IncludeInInvoice',
      headerName: 'Include in Invoice',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const includeInInvoice = params.getValue("IncludeInInvoice") ? "Yes" : "No";        
        return (
          <div>
            {includeInInvoice}
          </div>
        )
      }
    },
    {
      field: 'Controls',
      headerName: <CoreAddButton handleOpenCreate={openDocumentTypeCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const documentTypeID = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <DocumentTypeListColumnEditButton
                documentTypeID={documentTypeID}
                getDocumentTypes={getDocumentTypes}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Document Type: ${params.getValue("Description")}`}
                deleteID={documentTypeID}
                deleteFunction={deleteDocumentType}
                getList={getDocumentTypes}
              />}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configDocumentTypesAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configDocumentTypesAmount", amount);
  };

  const onFieldRowEnter = (params) => {
    for (const document of documentTypes) {
      if (document.ID === params.row.ID) {
        document.ShowControls = true;
      }
    }
    setDocumentTypes([ ...documentTypes ]);
  };

  const onFieldRowLeave = (params) => {
    for (const document of documentTypes) {
      if (document.ID === params.row.ID) {
        document.ShowControls = false;
      }
    }
    setDocumentTypes([ ...documentTypes ]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        autoHeight={true} 
        rows={documentTypes} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onFieldRowEnter(e)}
        onRowLeave={e => onFieldRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewDocumentTypeSectionListTable.propTypes = {
  documentTypes: PropTypes.array.isRequired,
  getDocumentTypes: PropTypes.func.isRequired
};

export default ConfigurationViewDocumentTypeSectionListTable;