import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Configuration/' + token + '/';

export async function getMainBranchID() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetMainBranchID');
  if (result.data) {
    return result.data[0].MainBranchID;
  }
  return null;
};

export async function getNextInvoiceNum() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetNextInvoiceNum');
  return result.data[0];
};

export async function updateNextInvoiceNum(configurationFormData) {
  var params = { value: configurationFormData.NextInvoiceNum };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateNextInvoiceNum', null, params);
  return result;
};

export async function getDefaultShipmentSettings() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDefaultShipmentSettings');
  return result.data[0];
};

export async function updateDefaultShipmentSettings(configurationFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateDefaultShipmentSettings', null, configurationFormData);
  return result;
};

export async function getTaxTypeIDNone() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetTaxTypeIDNone');
  return result.data[0].TaxTypeIDNone;
};

export async function getDriverHours() { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDriverHours');
  return result.data[0];
};

export async function updateDriverHours(data) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateDriverHours', null, data);
  return result;
};

export async function getDefaultOrderSettings() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetDefaultOrderSettings');
  return result.data;
};

export async function updateDefaultOrderSettings(configurationFormData) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateDefaultOrderSettings', configurationFormData);
  return result;
};

export async function get() {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'Get');
  return result.data;
};

export async function update(data) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Update', data);
  return result;
}
