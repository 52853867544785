import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import ReceivableChargeTypeField from '../receivable_charge_type/ReceivableChargeTypeField';
import CoreTextField from '../core/CoreTextField';
import CoreNumberField from '../core/CoreNumberField';
import { create } from '../revenue/RevenueService';
import { getTaxTypeIDNone } from '../configuration/ConfigurationService';
import { get } from '../receivable_charge_type/ReceivableChargeTypeService';

const PayableRelatedRevenueDialog = (props) => { 
  const { 
    isOpen,
    onClose,
    payableFormData
  } = props;

  const initialState = { 
    ReceivableChargeTypeID: null,
    Units: 0,
    UnitRate: 0,
    Subtotal: 0,
    Tax1TypeID: 0,
    Tax2TypeID: 0,
    IntermodalOrderID: 0
  };
  const [revenueFormData, setRevenueFormData] = useState({});

  useEffect(() => {
    async function getDefaultTaxTypes() {
      const taxType = await getTaxTypeIDNone();
      setRevenueFormData(r => (
        { ...r, Tax1TypeID: taxType, Tax2TypeID: taxType}         
      ));
    }
    async function getDescription() { 
      const receivableChargeType = await get(payableFormData.RelatedReceivableChargeTypeID);
      setRevenueFormData(r => (
        { ...r, Description: receivableChargeType.DescriptionDefault }
      ));
    }
    if (isOpen) {
        setRevenueFormData(r => ({
            ...r, 
            ReceivableChargeTypeID: payableFormData.RelatedReceivableChargeTypeID,
            Units: payableFormData.Units,
            UnitRate: payableFormData.UnitRate,
            Subtotal: payableFormData.Subtotal,
            IntermodalOrderID: payableFormData.IntermodalOrderID
          }));
        getDefaultTaxTypes();
        getDescription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const updateRevenueFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    revenueFormData[name] = value;
    revenueFormData[nameData] = valueData;
    setRevenueFormData({ ...revenueFormData });
    updateSubtotal();
  };

  const updateSubtotal = () => {
    setRevenueFormData(s => ({...s, subtotal: (revenueFormData.Units * revenueFormData.UnitRate)}));
  };

  const onCreate = e => { 
    e.preventDefault();
    processCreateRequest();
};

  const processCreateRequest = async () => { 
    await create(revenueFormData);
    closeDialog();
  };

  const closeDialog = () => { 
    setRevenueFormData(initialState);
    onClose();
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onCreate}>
      <DialogTitle id="form-dialog-title">Related Revenue</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <DialogContentText>Do you wish to create a related Revenue item for {payableFormData.PayableChargeTypeName} ?</DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <ReceivableChargeTypeField
                label="Type"
                name="ReceivableChargeTypeName"
                value={revenueFormData.ReceivableChargeTypeName}
                nameData="ReceivableChargeTypeID"
                valueData={revenueFormData.ReceivableChargeTypeID}
                onChange={e => updateRevenueFormData(e)}
                isRequired={true}
                autoFocus={true}
                isDisabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <CoreTextField
              name="Description"
              label="Description"
              value={revenueFormData.Description}
              onChange={e => updateRevenueFormData(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <CoreNumberField
              name="Units"
              label="Quantity"
              isCurrency={false}
              value={revenueFormData.Units}
              onChange={e => updateRevenueFormData(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <CoreNumberField
              name="UnitRate"
              label="Rate"
              isCurrency={true}
              value={revenueFormData.UnitRate}
              onChange={e => updateRevenueFormData(e)}
            />
          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={6}>
            <CoreNumberField
              name="Subtotal"
              label="Total"
              isCurrency={true}
              disabled={true}
              value={revenueFormData.Subtotal}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Confirm
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  )
};

export default PayableRelatedRevenueDialog;