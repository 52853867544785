import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { get, getPayableCharges } from './BillService';
import BillViewPageHeader from './BillViewPageHeader';
import BillViewGeneralSection from './BillViewGeneralSection'
import BillViewExpenseListTable from './BillViewExpenseListTable';
import { getDefaultShipmentSettings } from '../configuration/ConfigurationService';
import LinearProgress from '@material-ui/core/LinearProgress';

const BillView = () => {
  const { id } = useParams();
  const [bill, setBill] = useState({});
  const [expenses, setExpenses] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [configurationDefault, setConfigurationDefault] = useState({});

  useEffect(() => {
    setInProgress(true);
    get(id)
    .then(function (response) {
      setBill(response);
      getExpenses();
      getDefaultDryRunCharges();
      setInProgress(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getExpenses = () => {
    setInProgress(true);
    getPayableCharges(id)
      .then(function (response) {
        setExpenses(response);
        setInProgress(false);
      });
  };

  const getDefaultDryRunCharges = () => { 
    getDefaultShipmentSettings()
      .then(function (response) {
        setConfigurationDefault(response);
      });
  };

  return (
    <div>
      {<BillViewPageHeader
        bill={bill}
      />}
      {inProgress && <LinearProgress />}
      {!inProgress && <BillViewGeneralSection
        bill={bill}
      />}
      {!inProgress && <BillViewExpenseListTable
        billID={id}
        expenses={expenses} 
        getExpenses={getExpenses}
        setExpenses={setExpenses}
        configurationDefault={configurationDefault}
      />}
    </div>
  );

}

export default BillView;