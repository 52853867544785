import React from 'react';
import Alert from '@material-ui/lab/Alert';

import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import EquipmentCreateContentBasicInfo from './EquipmentCreateContentBasicInfo';
import EquipmentCreateContentDetailInfo from './EquipmentCreateContentDetailInfo';

const EquipmentCreateContent = (props) => {
  const {
    EquipmentFormData,
    onCreate,
    onClose,
    errorMessage,
    EquipmentFormValidation,
    UpdateEquipmentFormData
  } = props;

  return (
    <form onSubmit={onCreate}>
      <EquipmentCreateContentBasicInfo
        EquipmentFormData={EquipmentFormData}
        EquipmentFormValidation={EquipmentFormValidation}
        UpdateEquipmentFormData={UpdateEquipmentFormData}
      /><br></br>
      <EquipmentCreateContentDetailInfo
        EquipmentFormData={EquipmentFormData}
        EquipmentFormValidation={EquipmentFormValidation}
        UpdateEquipmentFormData={UpdateEquipmentFormData}
      />
      {errorMessage &&
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br />}
      <CoreCreateContentStickyFooterButtons onClose={onClose} />
    </form>
  );
}

export default EquipmentCreateContent;