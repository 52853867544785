import React, { useState } from 'react';
import IntermodalInvoiceListTransmitDialogContent from './IntermodalInvoiceListTransmitDialogContent';
import { transmit, clear } from './IntermodalInvoiceService';

const IntermodalInvoiceListTransmitDialog = (props) => {

  const {
    isOpen,
    onClose
  } = props;

  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const transmitInvoices = async e => {
    setErrorMessage('');    
    e.preventDefault();
    setInProgress(true);
    const result = await transmit();
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    onClose();
  };

  const clearInvoices = async e => {
    e.preventDefault();
    setInProgress(true);
    await clear();
    setInProgress(false);
    onClose();
  };

  const closeDialog = () => {
    setErrorMessage('');
    onClose();
  };

  return (
    isOpen &&
    <IntermodalInvoiceListTransmitDialogContent
      transmitInvoices={transmitInvoices}
      clearInvoices={clearInvoices}
      isOpen={isOpen}
      onClose={closeDialog}
      inProgress={inProgress}
      errorMessage={errorMessage}
    />
  );

};

export default IntermodalInvoiceListTransmitDialog;