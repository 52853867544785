import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IntermodalInvoiceListStatusButton from './IntermodalInvoiceListStatusButton';

import IconPendingInvoice from '../icon/IconPendingInvoice';
import IconOnHold from '../icon/IconOnHold';
import IconReady from '../icon/IconReady';
import { getIntermodalInvoiceList } from '../order/OrderService';
import _ from "lodash";


const IntermodalInvoiceListStatus = (props) => {
  const {
    intermodalInvoiceStatuses,
    updateStatusButtonChange
  } = props;

  const [intermodalInvoices, setIntermodalInvoices] = useState([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermodalInvoiceStatuses]);

  const initialize = async () => {
    var params = _.cloneDeep(intermodalInvoiceStatuses);
    params.ShowAllInvoicing = true;    
    const result = await getIntermodalInvoiceList(params);
    setIntermodalInvoices(result);
  };

  return (
    <div className="mb-2">
      <Grid container justify="space-around" spacing={2}>
        <Grid item md={3} xs={3}>
          <IntermodalInvoiceListStatusButton
            count={intermodalInvoices[0] ? intermodalInvoices[0].AllInvoicingCount : 0}
            name={"ShowAllInvoicing"}
            label={"All"}
            intermodalInvoiceStatus={intermodalInvoiceStatuses.ShowAllInvoicing}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalInvoiceListStatusButton
            count={intermodalInvoices[0] ? intermodalInvoices[0].CompletedCount : 0}
            name={"ShowCompleted"}
            label={"Pending"}
            intermodalInvoiceStatus={intermodalInvoiceStatuses.ShowCompleted}
            icon={<IconPendingInvoice />}
            buttonColor={"#E573D8"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalInvoiceListStatusButton
            count={intermodalInvoices[0] ? intermodalInvoices[0].BillingOnHoldCount : 0}
            name={"ShowBillingOnHold"}
            label={"Billing On Hold"}
            intermodalInvoiceStatus={intermodalInvoiceStatuses.ShowBillingOnHold}
            icon={<IconOnHold />}
            buttonColor={"#FFB74D"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <IntermodalInvoiceListStatusButton
            count={intermodalInvoices[0] ? intermodalInvoices[0].ApprovedForBillingCount : 0}
            name={"ShowApprovedForBilling"}
            label={"Approved For Billing"}
            intermodalInvoiceStatus={intermodalInvoiceStatuses.ShowApprovedForBilling}
            icon={<IconReady />}
            buttonColor={"#81C784"}
            onChange={updateStatusButtonChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default IntermodalInvoiceListStatus;