import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { attachPaymentMethodLogin } from '../subscription_management/SubscriptionManagementService';

import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import '../subscription_management/SubscriptionManagement.css';



const AuthenticationCreditCard = (props) => {

  const {
    companyID,
    username
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    setErrorMessage('');
    event.preventDefault();
    const result = await stripe.createToken(elements.getElement(CardElement));
    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      publishCreditCard(result.token.id);
    }
  };

  const publishCreditCard = async (cardToken) => {
    await attachPaymentMethodLogin(companyID, username, cardToken);
    window.location = '/login';
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='credit-card-input'>
        <CardElement />
      </div>
      <Alert variant="outlined" severity="error" className="mt-2">
        Your payment information is not up to date. 
        Please enter your Credit Card details or contact our support team for further assistance (support@envasetechnologies.com).
      </Alert>
      {errorMessage &&
        <Alert variant="outlined" severity="error" className="mt-2">
          {errorMessage}
        </Alert>
      }
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        className="submit-button mt-2"
        id="card-button">
        Update Payment Method
      </Button>
    </form>
  );

}

AuthenticationCreditCard.propTypes = {
  username: PropTypes.string.isRequired,
  companyID: PropTypes.number.isRequired
};

export default AuthenticationCreditCard;