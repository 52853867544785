import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoreDataGridList from '../core/CoreDataGridList';
import Validation from '../validation';
import Container from '@material-ui/core/Container';
import { getIncomingLoadsList as getList, acceptIncomingLoad, rejectIncomingLoad } from './IncomingLoadService';
import { format } from 'date-fns';
import IncomingLoadListAcceptButton from './IncomingLoadListAcceptButton';
import IncomingLoadListRejectButton from './IncomingLoadListRejectButton';

const IncomingLoadListTable = () => {

  const validation = new Validation();

  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(false);
  const [incomingLoads, setIncomingLoads] = useState([]);
  const [rowCount, setRowCount] = useState(10);
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("incoomingLoadListAmount")) || 10,
    count: parseInt(localStorage.getItem("incomingLoadListAmount")) || 10,
    isASC: true,
    sortBy: 'ID',
    filters: [],
  });
  const [filterData, setFilterData] = useState({
    Search: '',
  })

  useEffect(() => {
    getIncomingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getIncomingList = async () => {    
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setRowCount(result[0].FilteredCount);
    } else {
      setRowCount(0);
    }
    setIncomingLoads(result);     
    setInProgress(false);
  };

  function changePage(page) {
    params.page = page + 1;
    setParams({ ...params });
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    setParams({ ...params });
    localStorage.setItem("incomingLoadListAmount", amount);
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    setParams({ ...params });
  };

  const updateFilterData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    filterData[name] = value;
    filterData[nameData] = valueData;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const buildParamFilter = () => {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }  
    params.page = 1;
    setParams({ ...params });
  };

  const pushParamFilter = (filterName, value, operator) => {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  };

  const columns = [
    {
      field: 'OpenControls',
      headerName: ' ',
      align: 'center',
      width: 100,
      renderCell: (params) => {  
        const id = params.getValue("ID");
        return (
          <div>            
            <IncomingLoadListAcceptButton
              reference={`${params.getValue("ShipperPortalOrder").OriginCompanyName} to ${params.getValue("ShipperPortalOrder").DestinationCompanyName}`}
              shipperPortalOrderAssignmentID={id}
              acceptFunction={acceptIncomingLoad}
              getList={getIncomingList}
            />
            <IncomingLoadListRejectButton
              reference={`${params.getValue("ShipperPortalOrder").OriginCompanyName} to ${params.getValue("ShipperPortalOrder").DestinationCompanyName}`}
              shipperPortalOrderAssignmentID={id}
              rejectFunction={rejectIncomingLoad}
              getList={getIncomingList}
            />
          </div>
        )
      }
    },
    {
      field: 'DateCreated',
      headerName: 'Date Created',
      align: 'left',
      width: 150,
      renderCell: (params) => {  
        const name = format(new Date(params.getValue("DateCreated")), 'MM/dd/yyyy');
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'AppointmentDate',
      headerName: 'Appointment',
      align: 'left',
      width: 150,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").AppointmentDate ? format(new Date(params.getValue("ShipperPortalOrder").AppointmentDate), 'MM/dd/yyyy HH:mm') : '';
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'Expense',
      headerName: 'Rate',
      align: 'left',
      width: 100,
      renderCell: (params) => {  
        const name = params.getValue("Expense");
        return (
          <div>
            ${name}            
          </div>
        )
      }
    },
    {
      field: 'EquipmentType',
      headerName: 'Container Type',
      align: 'left',
      width: 200,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").EquipmentType;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'Type',
      headerName: 'Type',
      align: 'left',
      width: 100,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").Type;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'LiveDrop',
      headerName: 'Live/Drop',
      align: 'left',
      width: 150,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").LiveDrop;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'OriginCompanyName',
      headerName: 'Origin',
      align: 'left',
      width: 200,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").OriginCompanyName;
        return (
          <div>
            {name}            
          </div>
        )
      }
    }, 
    {
      field: 'OriginCity',
      headerName: 'Origin Location',
      align: 'left',
      width: 250,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").OriginCity + ", " + params.getValue("ShipperPortalOrder").OriginProvince + 
                      " " + params.getValue("ShipperPortalOrder").OriginPostalCode + ", " + params.getValue("ShipperPortalOrder").OriginCountry;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },   
    {
      field: 'DestinationCompanyName',
      headerName: 'Destination',
      align: 'left',
      width: 200,      
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").DestinationCompanyName;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },
    {
      field: 'DestinationCity',
      headerName: 'Destination Location',
      align: 'left',
      width: 250,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").DestinationCity + ", " + params.getValue("ShipperPortalOrder").DestinationProvince + 
                    " " + params.getValue("ShipperPortalOrder").DestinationPostalCode + ", " + params.getValue("ShipperPortalOrder").DestinationCountry;
        return (
          <div>
            {name}            
          </div>
        )
      }
    },    
    {
      field: 'Weight',
      headerName: 'Weight',
      align: 'left',
      width: 150,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").Weight + " " + params.getValue("ShipperPortalOrder").WeightUOM;
        return (
          <div>
            {name}            
          </div>
        )
      }
    }, 
    {
      field: 'NumPieces',
      headerName: '# Pieces',
      align: 'left',
      width: 150,
      renderCell: (params) => {  
        const name = params.getValue("ShipperPortalOrder").NumPieces;
        return (
          <div>
            {name}            
          </div>
        )
      }
    }, 
  ];

  return (
    <div style={{position:'relative'}}>
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={incomingLoads}
          params={params}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='ID'
          filterData={filterData}
          updateFilterData={updateFilterData}
          listWidth={listWidth}
          setListWidth={setListWidth}
          rowCount={rowCount}
          disableExportList={true}
        />
      </Container>
    </div>
  );

}

export default IncomingLoadListTable;