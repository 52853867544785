import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create as equipmentCreate } from './EquipmentService';
import EquipmentCreateDialogContent from './EquipmentCreateDialogContent';
import { getMainBranchID } from '../configuration/ConfigurationService';

const EquipmentCreateDialog = (props) => {

  const {
    isOpen,
    onClose,
    persistedUnitNum
  } = props;

  const initialData = {
    UnitNum: '',
    UnitType: 0,
    TractorType: 3,
    BranchID: 0,
    DefaultClassID: null,
    EquipmentType: 'Power Only',
    Manufacturer: '',
    Model: '',
    ModelYear: '',
    LicenseNum: '',
    LicenseState: '',
    VINNum: '',
    ACEID: '',
    ACEExpiry: null,
    ACEExpiryRemindTaskID: 0,
    ACEEquipType: null,
    ACETransponder: '',
    ACIID: '',
    ACIExpiry: null,
    ACIExpiryRemindTaskID: 0,
    ACIEquipType: null,
    ACITransponder: '',
    RegistrationNum: '',
    RegistrationExpiry: null,
    RegistrationRemindTaskID: null,
    RevenuePercentage: 0,
    WeightCapacity: 0,
    WeightEmpty: 0,
    OutsideLengthFt: 0,
    OutsideLengthInch: 0,
    OutsideWidthFt: 0,
    OutsideWidthInch: 0,
    OutsideHeightFt: 0,
    OutsideHeightInch: 0,
    InsideLengthFt: 0,
    InsideLengthInch: 0,
    InsideWidthFt: 0,
    InsideWidthInch: 0,
    InsideHeightFt: 0,
    InsideHeightInch: 0,
    OwnerType: 0,
    OwnerCarrierID: null,
    ServiceStart: new Date().toLocaleDateString(),
    ServiceEnd: null,
    PurchasePrice: 0,
    PurchasePriceTax1: 0,
    PurchasePriceTaxTypeID1: 0,
    PurchasePriceTax2: 0,
    PurchasePriceTaxTypeID2: 0,
    IsLeased: false,
    LeaseCompany: '',
    LeaseEnd: null,
    LeaseEndRemindTaskID: null,
    LeaseRateMonthly: 0,
    LeaseRateMonthlyTax1: 0,
    LeaseRateMonthlyTaxTypeID1: 0,
    LeaseRateMonthlyTax2: 0,
    LeaseRateMonthlyTaxTypeID2: 0,
    LeaseBuyout: 0,
    HasReefer: false,
    ReeferManuf: '',
    ReeferModel: '',
    ReeferModelYear: '',
    ReeferVIN: '',
    Axles: 0,
    FuelType: 1,
    SafetyInspectionNum: '',
    SafetyInspectionExpiry: null,
    SafetyRemindTaskID: null,
    IFTADecalNum: '',
    IFTADecalExpiry: null,
    IFTADecalTaskID: null,
    ContractMilesScheduleID: null,
    Obsolete: false,
    UserDef1: '',
    UserDef2: '',
    UserDef3: '',
    UserDef4: '',
    BorderConnectEquipmentType: ''
  }

  const [equipmentFormData, setEquipmentFormData] = useState(initialData);
  const [equipmentFormValidation, setEquipmentFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setEquipmentFormValidation(buildValidation(equipmentFormData));
    }
  }, [equipmentFormData, isOpen]);


  useEffect(() => {
    async function setDefaultValues() {
      const branchID = await getMainBranchID();
      setEquipmentFormData(e => ({ ...e, UnitNum: persistedUnitNum || '', BranchID: branchID }));
    }
    if (isOpen) {
      setDefaultValues();
    }
  }, [isOpen, persistedUnitNum]);

  const updateEquipmentFormData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    if (e.target.name === "OwnerType" && e.target.value !== equipmentFormData.OwnerType) {
      equipmentFormData.OwnerCarrierID = null;
      equipmentFormData.OwnerCarrierName = "";
    }
    if (e.target.name === 'UnitType' && e.target.value === 2) {
      equipmentFormData.OwnerType = 2;
      equipmentFormData.Manufacturer = '';
      equipmentFormData.Model = '';
      equipmentFormData.ModelYear = '';
      equipmentFormData.Axles = 0;
      equipmentFormData.LicenseNum = '';
      equipmentFormData.LicenseState = '';
      equipmentFormData.VINNum = '';
    }
    if (e.target.name === 'UnitType') {
      equipmentFormData.EquipmentType = '';
    }
    equipmentFormData[name] = value;
    equipmentFormData[nameData] = valueData;
    setEquipmentFormData({ ...equipmentFormData });
  };

  const onCreate = (e) => {
    e.preventDefault();
    if (!new Validation().isValidObject(equipmentFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await equipmentCreate(equipmentFormData);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    //onClose(parseInt(result.response));
    resetForm(parseInt(result.response));
  };

  const resetForm = (response) => {
    setEquipmentFormData(initialData);
    if (response) {
      onClose(parseInt(response));
    } else {
      onClose();
    }
    
  }

  return (
    isOpen && 
    <EquipmentCreateDialogContent
      resetForm={resetForm}
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      errorMessage={errorMessage}
      equipmentFormData={equipmentFormData}
      equipmentFormValidation={equipmentFormValidation}
      updateEquipmentFormData={updateEquipmentFormData}
      persistedUnitNum={persistedUnitNum}
    />
  );
}

const buildValidation = (equipmentFormData) => {
  const {
    UnitNum,
    UnitType,
    TractorType,
    OwnerType,
    ServiceStart
  } = equipmentFormData || {};
  const validation = new Validation();
  return {
    UnitNum: validation.isEmpty(UnitNum),
    UnitType: validation.isNumber(UnitType),
    TractorType: validation.isNumber(TractorType),
    OwnerType: validation.isNumber(OwnerType),
    ServiceStart: validation.isEmpty(ServiceStart)
  };
};

export default EquipmentCreateDialog;