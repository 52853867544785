import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/Person';

import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';
import CountryField from '../country/CountryField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CorePhoneField from '../core/CorePhoneField';

const CustomerCreateContentAddress = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData,
    coordinates,
    branch
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Physical Address</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            {(customerFormData.custCountry !== "Philippines" && branch.Country !== "Philippines") && <>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="custAddress"
                  label="Address"
                  value={customerFormData.custAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="custCity"
                  label="City"
                  value={customerFormData.custCity}
                  validationError={customerFormValidation.custCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="custProvince"
                  label="State / Province"
                  value={customerFormData.custProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="custPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.custPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CountryField
                  name="custCountry"
                  label="Country"              
                  value={customerFormData.custCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custCountry}
                />
              </Grid>
            </>}
            {(customerFormData.custCountry === "Philippines" || branch.Country === "Philippines" )&& <>
              <Grid item md={6} xs={6}>
                <CountryField
                  name="custCountry"
                  label="Country"              
                  value={customerFormData.custCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custCountry}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="custProvince"
                  label="State / Province"
                  value={customerFormData.custProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="custCity"
                  label="City"
                  value={customerFormData.custCity}
                  validationError={customerFormValidation.custCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="custPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.custPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="custDistrict"
                  label="District"
                  value={customerFormData.custDistrict}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="custCounty"
                  label="Barangay"
                  value={customerFormData.custCounty}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="custAddress"
                  label="Address"
                  value={customerFormData.custAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.custAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
            </>}
            <Grid item md={6} xs={6}>
              <CorePhoneField
                name="custPhone"
                label="Primary Number"              
                value={customerFormData.custPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.custPhone}
              />
              {/* <CoreTextField
                name="custPhone"
                label="Primary Number"              
                value={customerFormData.custPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.custPhone}
                maxLength={15}
              /> */}
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CorePhoneField
                name="custTollFree"
                label="Secondary Number"              
                value={customerFormData.custTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.custTollFree}
              />
              {/* <CoreTextField
                name="custTollFree"
                label="Secondary Number"              
                value={customerFormData.custTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.custPhone}
                maxLength={15}
              /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }            
        </Grid>
      </Grid>
    </Container>  
  );
}

export default CustomerCreateContentAddress;