import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as PendingInvoice } from '../assets/svg-icons/pending-invoice@1x.svg';

const IconPendingInvoice = (props) => {

  const {
    fontSize
  } = props;

  return (
    <SvgIcon fontSize={fontSize}>
      <PendingInvoice />
    </SvgIcon>
  )
}
export default IconPendingInvoice;