import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'FeePay/';

export const CLIENT_ID = "32b36754-9f7b-4591-88e5-84ec65aca679";
export const BASE_URL = process.env.REACT_APP_FEEPAY_API_URL;

export async function getToken() { 
    const httpService = new HttpService();
    const result = await httpService
      .get(uri + 'GetToken');
    return result;
};