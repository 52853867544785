import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconAppointmentCancel from '../icon/IconAppointmentCancel';

import OrderAppointmentCancelAppointmentListDialog from '../orderAppointment/OrderAppointmentCancelAppointmentListDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'red',
  },
}));

const OrderListCancelAppointmentButton = (props) => { 
  const {
    order,
    getList,
    disable
  } = props;
  const classes = useStyles();
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);

  const openCancelAppointmentModal = () => { 
    setIsCancelAppointmentModalOpen(true);
  };

  const closeCancelAppointmentModal = () => { 
    getList();
    setIsCancelAppointmentModalOpen(false);
  };

  return ( 
    <>
      <IconButton title="Cancel an Appointment" className={classes.iconText} onClick={openCancelAppointmentModal} disabled={disable}>
        <IconAppointmentCancel/>&nbsp;
      </IconButton>
      <OrderAppointmentCancelAppointmentListDialog
        isOpen={isCancelAppointmentModalOpen}
        onClose={closeCancelAppointmentModal}
        order={order}
      />
    </>
  )

}

export default OrderListCancelAppointmentButton;