import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';

import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import PersonnelEditContentInfo from './PersonnelEditContentInfo';
import PersonnelEditContentEmployeeInfo from './PersonnelEditContentEmployeeInfo';
import PersonnelEditContentDriverInfo from './PersonnelEditContentDriverInfo';

const PersonnelEditContent = (props) => {
  const {
    PersonnelFormData,
    onUpdate,
    onClose,
    errorMessage,
    PersonnelFormValidation,
    updatePersonnelFormData,
    VendorFormData,
    updateVendorFormData,
    DriverLicenseFormData,
    updateDriverLicenseFormData,
    TWICFormData,
    updateTWICFormData,
    coordinates,
    inProgress
  } = props;

  useEffect(() => {
    if(errorMessage){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }, [errorMessage]);

  return (
    <form onSubmit={onUpdate}>
      <PersonnelEditContentInfo
        PersonnelFormData={PersonnelFormData}
        PersonnelFormValidation={PersonnelFormValidation}
        updatePersonnelFormData={updatePersonnelFormData}
        coordinates={coordinates}
        VendorFormData={VendorFormData}
        updateVendorFormData={updateVendorFormData}
      /><br></br>
      <PersonnelEditContentEmployeeInfo
        PersonnelFormData={PersonnelFormData}
        PersonnelFormValidation={PersonnelFormValidation}
        updatePersonnelFormData={updatePersonnelFormData}
      /><br></br>
      {(PersonnelFormData.ListAsDriver === 1 || PersonnelFormData.ListAsDriver === true || PersonnelFormData.DriverType === 2) && 
      <PersonnelEditContentDriverInfo
        PersonnelFormData={PersonnelFormData}
        PersonnelFormValidation={PersonnelFormValidation}
        updatePersonnelFormData={updatePersonnelFormData}
        DriverLicenseFormData={DriverLicenseFormData}
        updateDriverLicenseFormData={updateDriverLicenseFormData}
        TWICFormData={TWICFormData}
        updateTWICFormData={updateTWICFormData}
      />}
      {
        errorMessage &&
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br />}
      {!inProgress && <CoreCreateContentStickyFooterButtons 
        changesDetails={"Personnel " + PersonnelFormData.InternalName + " details"}
        onClose={onClose} 
      />}
    </form>
  );
}

export default PersonnelEditContent;