import React, { useState, useEffect } from 'react';

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const PersonnelUserDriverTabField = (props) => {
  const {
    value,
    name,
    onChange,
    ...rest
  } = props;

  const [tabState, setTabState] = useState(0);

  useEffect(() => {
    const tempEvent = {
      target: {
        name,
        value: tabState
      }
    };
    onChange(tempEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  return (
    <Tabs
        value={tabState}
        onChange={(event, newValue) => { setTabState(newValue) }} 
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        {...rest}
    >
        <Tab value={0} label='User' />
        <Tab value={1} label='Driver' />
    </Tabs>
  );
}

export default PersonnelUserDriverTabField;