import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LiveDropSvg } from '../assets/svg-icons/live-drop@1x.svg';

const IconLiveDrop = () => {
    return (
        <SvgIcon fontSize="small">
            <LiveDropSvg />
        </SvgIcon>    
    )
}
export default IconLiveDrop;