import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '../icon/IconEdit';

import EnvaseTraceConfigureDialog from './EnvaseTraceConfigureDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    color: 'black',
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
}));

const EnvaseTraceListColumnEditButton = (props) => {
  const {
    providerID,
    getProvidersList,
    buttonText
  } = props;
  
  const classes = useStyles();
  const [isConfigureOpen, setIsConfigureOpen] = useState(false);

  const openConfigureDialog = () => {
    setIsConfigureOpen(true);
  };

  const closeConfigureDialog = () => {
    setIsConfigureOpen(false);
    getProvidersList();
  };

  return (
    <>
      <IconButton className={classes.iconText} onClick={openConfigureDialog}>
        <EditIcon />&nbsp;
        <span>{buttonText}</span>
      </IconButton>
      <EnvaseTraceConfigureDialog
        providerID={providerID}
        isOpen={isConfigureOpen}
        onClose={closeConfigureDialog}
      />
    </>
  );
}

EnvaseTraceListColumnEditButton.propTypes = {
  providerID: PropTypes.string.isRequired
};

export default EnvaseTraceListColumnEditButton;