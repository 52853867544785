import React, { useEffect, useState } from 'react';
import { Grid, Button, Hidden, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import ItineraryDriverList from './ItineraryDriverList';
import ItineraryLegList from './ItineraryLegList';
import CorePageHeader from '../core/CorePageHeader';
import CoreDateField from '../core/CoreDateField';
import CoreTextField from '../core/CoreTextField';
import { makeStyles } from '@material-ui/styles';
import { getDrivers, getList, assignLegToDriver } from './ItineraryService';
// import { differenceInDays } from 'date-fns';
import ItineraryAssign from './ItineraryAssign';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  searchBox: {
    width: "80%",
    margin: "0 auto",
  },
}));

const Itinerary = () => {
  const classes = useStyles();
  const [drivers, setDrivers] = useState([]);
  const [driverSearch, setDriverSearch] = useState('');
  const [legs, setLegs] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [legsLoading, setLegsLoading] = useState(false);
  const [legError, setLegError] = useState(null);
  const [dateForm, setDateForm] = useState({fromDate: '', toDate: ''});
  const [containerHeight, setContainerHeight] = useState('30px');
  const [dateError, setDateError] = useState('');
  const [isAssignOpen, setIsAssignOpen] = useState(false);

  useEffect(() => {
    var date = new Date();
    var futureDate = new Date().setDate(date.getDate() + 8);
    setDateForm(df => ({...df, fromDate: date}));
    setDateForm(df => ({...df, toDate: futureDate}));
  }, []);

  useEffect(() => {
    function getContainerHeight() {
      const { innerHeight: height } = window;
      const tempHeight = height - 64 - 81 - 49 - 70;
      setContainerHeight(tempHeight);
    }
    getContainerHeight();
    window.addEventListener('resize', getContainerHeight);
    return () => window.removeEventListener('resize', getContainerHeight);
  }, []);

  useEffect(() => {
    getDrivers().then((response) => {
      setDrivers(response.data);
    });
  }, []);

  const onDriverClick = async (driver) => {
    setSelectedDriver(driver);
    getLegs(driver);
  };

  const getLegs = (driver = null) => {
    setLegsLoading(true);
    getList(dateForm.fromDate, driver?.DriverID ? driver.DriverID : selectedDriver.DriverID)
      .then((response) => {
        if (response.error) {
          setLegError(response.error);
          return;
        }
        const orderLeg = response.response.sort((a, b) => {return a.DriverItineraryIndex - b.DriverItineraryIndex})
        setLegs(orderLeg);
      });
    setLegsLoading(false);
  };

  const updateDateFormData = (e) => {
    const {name, value} = e.target;
    dateForm[name] = value;
    setDateForm({...dateForm});
    setDateError('');
    getLegs();
  };

  const assignLeg = async (leg) => {
    const response = await assignLegToDriver(leg.ID, selectedDriver.DriverID);
    getLegs(selectedDriver);
    return response;
  }

  const handleAssignOpen = () => {
    setIsAssignOpen(true);
  };

  const onCloseAssign = () => {
    setIsAssignOpen(false);
  }

  return (
    <>
    <div>
      <CorePageHeader
        title= "Itinerary View"
        isInverted={true}
      />
      <div className={classes.root}>
        <Grid container>
          <Hidden smDown>
            <Grid container item md={1} lg={3} direction='column'>
              <Grid 
                container 
                item 
                alignItems='flex-end' 
                style={{ 
                  backgroundColor: '#f5f1f9', 
                  paddingTop: '10px', 
                  paddingBottom: '10px', 
                  paddingLeft: '30px'
                }}>
                <Grid item xs={1}>
                  <SearchIcon color='primary' />
                </Grid>
                <Grid item>
                  <CoreTextField
                    value={driverSearch}
                    onChange={(e) => setDriverSearch(e.target.value)}
                    label={"Search Drivers"}                    
                    disableUnderline={true}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <ItineraryDriverList
                  drivers={drivers}
                  onDriverClick={onDriverClick}
                  selectedDriver={selectedDriver}
                  driverSearch={driverSearch}
                  containerHeight={containerHeight}
                />
              </Grid>
            </Grid>
          </Hidden>

          <Grid container wrap='nowrap' item xs={12} md={11} lg={9} direction='column'>
            <Grid container style={{backgroundColor:'#f7f4fc', flexBasis:'0', paddingTop: '10px', paddingBottom: '10px'}}>
              <Grid container item xs={10}>&nbsp;
                <Grid item>
                  <CoreDateField
                    name="fromDate"
                    label="Start Date"
                    value={dateForm.fromDate}
                    disableUnderline={true}
                    keyboardIcon={<EventIcon color='primary'/>}
                    onChange={(e) => updateDateFormData(e)}
                  />
                </Grid>&nbsp;&nbsp;
                {/* <Grid item>
                  <CoreDateField
                    name="toDate"
                    label="To"
                    value={dateForm.toDate}
                    disableUnderline={true}
                    keyboardIcon={<EventIcon color='primary'/>}
                    shouldDisableDate={(date) => date < dateForm.fromDate || differenceInDays(date, dateForm.fromDate) > 8}
                    onChange={(e) => updateDateFormData(e)}
                  />
                </Grid> */}
                {dateError && <Grid><Typography variant='caption'>{dateError}</Typography></Grid>}
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={selectedDriver.DriverID ? false : true}
                    onClick={() => handleAssignOpen()}
                  >
                    Assign Leg
                  </Button>&nbsp;&nbsp;
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ItineraryLegList
                legs={legs}
                getLegs={getLegs}
                legsLoading={legsLoading}
                setLegsLoading={setLegsLoading}
                legError={legError}
                selectedDriver={selectedDriver}
                containerHeight={containerHeight}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
    <ItineraryAssign
      isOpen={isAssignOpen}
      onClose={onCloseAssign}
      assignLeg={assignLeg}
    />
    </>
  )
}

export default Itinerary
