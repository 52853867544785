import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { addLeg, deleteLeg, moveLegUp, moveLegDown } from './IntermodalEventService';
import CoreDeleteDialog from '../core/CoreDeleteDialog';
import DeleteLegAndPayablesDialog from './DeleteLegAndPayablesDialog';

const boldFont = {
  fontWeight: 'bold'
};

const IntermodalEventListTableLegMoreButton = (props) => {
  const {
    leg,
    legLength,
    getEvents,
    setInProgress,
    setCurrentLegID,
    setCurrentEventID,
    setIsAddEventAbove,
    setEventCreateDialogOpen,
    setPayableCreateDialogOpen,
    setCurrentEventType,
    setCurrentDriverID,
    eventTypeBelow
  } = props;

  const anchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteLegDialogOpen, setDeleteLegDialogOpen] = useState(false);
  const [isDeleteLegAndPayablesDialogOpen, setDeleteLegAndPayablesDialogOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }

  const moveIntermodalLegUp = () => {
    setIsMenuOpen(false);
    setInProgress(true);
    moveLegUp(leg.ID)
      .then(function (response) {
        getEvents();
      });
  };

  const moveIntermodalLegDown = () => {
    setIsMenuOpen(false);
    setInProgress(true);
    moveLegDown(leg.ID)
      .then(function (response) {
        getEvents();
      });
  };

  const openAddLeg = () => {
    setIsMenuOpen(false);
    setInProgress(true);
    addLeg(leg.ID)
      .then(function (response) {
        getEvents();
      });
  };

  const openDeleteLegDialog = () => {
    setIsMenuOpen(false);
    if (leg.Bills.length > 0) {
      setDeleteLegAndPayablesDialogOpen(true);
    } else {
      setDeleteLegDialogOpen(true); 
    }    
  };

  const closeDeleteLegDialog = () => {
    setIsMenuOpen(false);
    setDeleteLegDialogOpen(false);
    setDeleteLegAndPayablesDialogOpen(false);
    getEvents();
  };

  const openEventCreateDialog = () => {
    setIsMenuOpen(false);
    setCurrentLegID(leg.ID);
    setCurrentEventID(null);
    setIsAddEventAbove(false);
    setEventCreateDialogOpen(true);
    setCurrentEventType(eventTypeBelow);
  };

  const openPayableCreateDialog = () => {
    setIsMenuOpen(false);
    setCurrentLegID(leg.ID);
    setCurrentDriverID(leg.DriverID);
    setPayableCreateDialogOpen(true);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      >
        <span style={boldFont}>MORE</span>
      </Button>
      <Popper open={isMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={openEventCreateDialog}>Add Event</MenuItem>
                  <MenuItem onClick={moveIntermodalLegUp} disabled={leg.LegIndex === 0}>Move Up</MenuItem>
                  <MenuItem onClick={moveIntermodalLegDown} disabled={legLength === leg.LegIndex}>Move Down</MenuItem>
                  <MenuItem onClick={openAddLeg}>Add a Leg</MenuItem>
                  <MenuItem onClick={openPayableCreateDialog}>Add Payable</MenuItem>
                  <MenuItem onClick={openDeleteLegDialog}>Delete</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {
        <CoreDeleteDialog
          reference="LEG"
          deleteID={leg.ID}
          deleteFunction={deleteLeg}
          onDeleteSuccess={getEvents}
          isOpen={isDeleteLegDialogOpen}
          onClose={closeDeleteLegDialog}
        />
      }
      {
        <DeleteLegAndPayablesDialog
          deleteID={leg.ID}
          deleteFunction={deleteLeg}
          getList={getEvents}
          isOpen={isDeleteLegAndPayablesDialogOpen}
          onClose={closeDeleteLegDialog}
        />
      }
    </>
  );
}

IntermodalEventListTableLegMoreButton.propTypes = {
  leg: PropTypes.array.isRequired,
  legLength: PropTypes.number.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTableLegMoreButton;