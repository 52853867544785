import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

// https://material-ui-pickers.dev/getting-started/installation

const CoreTimeField = (props) => {
  const { name, label, value, onChange } = props;

  const onSelectEvent = (newValue) => {
    const dispatchEvent = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(dispatchEvent);
  };

  const handleTimeChange = (time) => {
    onSelectEvent(time);
  };

  return (
    <FormControl fullWidth>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          id="time-picker"
          ampm={false}
          label={label}
          value={value}
          onChange={handleTimeChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

CoreTimeField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  isRequired: PropTypes.bool,
  icon: PropTypes.element
};

export default CoreTimeField;
