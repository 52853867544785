import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DashboardSvg } from '../assets/svg-icons/dashboard@1x.svg'

const IconDashboard = () => {
  return (
    <SvgIcon fontSize="small" color="secondary">
      <DashboardSvg />
    </SvgIcon>
  )
}

export default IconDashboard;