import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import PersonnelDriverField from '../personnel/PersonnelDriverField';
import AvailableDriverField from '../intermodalEvent/AvailableDriverField';
import { updateLeg } from '../intermodalEvent/IntermodalEventService';

const IntermodalEventListTableLegDriverField = (props) => {
  const {
    leg,
    getEvents,
    setInProgress
  } = props;

  const [eventFormData, setEventFormData] = useState(leg);

  useEffect(() => {
    setEventFormData(leg);
  }, [leg]);

  const updateEventFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    eventFormData[name] = value;
    eventFormData[nameData] = valueData;
    onUpdate();
  };

  const onUpdate = () => {
    setInProgress(true);
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    const result = await updateLeg(eventFormData);
    if (result.errors) {
      console.log("Update Leg Errors");
      return;
    }
    setInProgress(false);
    getEvents();
  };

  return (
    <>
      {/* <PersonnelDriverField
        name="Driver"
        label="Driver"
        value={eventFormData.Driver}
        nameData="DriverID"
        valueData={eventFormData.DriverID}
        onChange={e => updateEventFormData(e)}
      /> */}
      <AvailableDriverField
        legID={eventFormData.LegID || eventFormData.ID}
        name="DriverInternalName"
        label="Search Driver"
        value={(eventFormData.Driver && eventFormData.Driver.InternalName) || eventFormData.DriverInternalName}
        nameData="DriverID"
        valueData={eventFormData.DriverID}
        onChange={e => updateEventFormData(e)}
      />
    </>
  );

}

IntermodalEventListTableLegDriverField.propTypes = {
  leg: PropTypes.array.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTableLegDriverField;