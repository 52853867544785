import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { get, deleteEvent, moveEventDown, moveEventUp, } from './IntermodalEventService';
import CoreDeleteDialog from '../core/CoreDeleteDialog';
import IntermodalEventEditDialog from './IntermodalEventEditDialog';

const IntermodalEventListTableEventMoreButton = (props) => {
  const {
    eventID,
    length,
    eventIndex,
    getEvents,
    setInProgress,
    reference
  } = props;

  const anchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [eventFormData, setEventFormData] = useState({});
  const [isEventEditModalOpen, setIsEventEditModalOpen] = useState(false);
  const [isCoreDeleteModalOpen, setIsCoreDeleteModalOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }

  const moveIntermodalEventUp = () => {
    setIsMenuOpen(false);
    setInProgress(true);
    moveEventUp(eventID)
      .then(function (response) {
        getEvents();
      });
  };

  const moveIntermodalEventDown = () => {
    setIsMenuOpen(false);
    setInProgress(true);
    moveEventDown(eventID)
      .then(function (response) {
        getEvents();
      });
  };

  async function openEventEditModal() {
    setIsMenuOpen(false);
    const result = await get(eventID);
    setEventFormData(result);
    setIsEventEditModalOpen(true);
  };

  const closeEventEditModal = () => {
    setIsMenuOpen(false);
    setIsEventEditModalOpen(false);
    setEventFormData({});
    getEvents();
  };

  const openCoreDeleteModal = () => {
    setIsMenuOpen(false);
    setIsCoreDeleteModalOpen(true);

  };

  const closeCoreDeleteModal = () => {
    setIsMenuOpen(false);
    setIsCoreDeleteModalOpen(false);
  };
  
  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      >
        <span>MORE</span>
      </Button>
      <Popper open={isMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={moveIntermodalEventUp} disabled={eventIndex === 0}>Move Up</MenuItem>
                  <MenuItem onClick={moveIntermodalEventDown} disabled={length === eventIndex}>Move Down</MenuItem>
                  <MenuItem onClick={openEventEditModal}>Edit</MenuItem>
                  <MenuItem onClick={openCoreDeleteModal}>Delete</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <IntermodalEventEditDialog
        isOpen={isEventEditModalOpen}
        onClose={closeEventEditModal}
        eventFormData={eventFormData}
        setEventFormData={setEventFormData}
      />
      <CoreDeleteDialog
        reference={reference}
        deleteID={eventID}
        deleteFunction={deleteEvent}
        onDeleteSuccess={getEvents}
        isOpen={isCoreDeleteModalOpen}
        onClose={closeCoreDeleteModal}
      />
    </>
  );
}

IntermodalEventListTableEventMoreButton.propTypes = {
  eventID: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  eventIndex: PropTypes.number.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTableEventMoreButton;