import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CorePageHeader from '../core/CorePageHeader';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { CardContent } from '@material-ui/core';
import IconCustomer from '../icon/IconCustomer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SubscriptionManagementCheckoutForm from './SubscriptionManagementCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { 
  getPurchasedEnvaseDriverCount, 
  getActiveUserCount,
  increaseMobileQuantity,
  decreaseMobileQuantity
} from './SubscriptionManagementService';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#8460cb',
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: 'pointer'
  },
  icon: {
    filter: 'invert(1)',
  }
}));

const SubscriptionManagement = () => {
  const classes = useStyles();

  const [purchasedDriverCount, setPurchasedDriverCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [driverPrice, setDriverPrice] = useState(0);

  useEffect(() => {
    async function getDriverCount() {
      const count = await getPurchasedEnvaseDriverCount();
      setPurchasedDriverCount(count);
    }
    async function getUserCount() {
      const count = await getActiveUserCount();
      setActiveUserCount(count);
    }
    getDriverCount();
    getUserCount();
  }, []);

  useEffect(() => {
    if (purchasedDriverCount > 0) {
      setDriverPrice((purchasedDriverCount) * 49);
    }
  }, [purchasedDriverCount]);

  const increaseDriverQuantity = async() => {
    await increaseMobileQuantity();
    window.location.reload();
  };

  const decreaseDriverQuantity = async() => {
    await decreaseMobileQuantity();
    window.location.reload();
  };

  return (
    <div>
      <CorePageHeader
        title="Subscription Management"
      />
      <Container maxWidth="lg" className="mt-4" component={Paper}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <h2>${driverPrice}.00</h2>
            <Card className='mt-4' variant='outlined'>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Avatar className={classes.avatar}>
                          <div className={classes.icon}>
                            <IconCustomer  className={classes.icon}/>
                          </div>
                        </Avatar>
                      </Grid>
                      <Grid item xs={9} className="mt-05">
                        <Grid container>
                          <Grid item xs={12}>
                            <strong>
                              Driver Seats
                            </strong>
                          </Grid>
                          <Grid item xs={12}>
                            <small>
                              Quantity: {purchasedDriverCount}
                            </small>
                          </Grid>
                          <Grid item xs={12} className="mt-2">
                            <Button type="submit" color="primary" size="small" onClick={decreaseDriverQuantity}>
                              Decrease
                            </Button>&nbsp;
                            <Button type="submit" variant="outlined" color="primary" size="small" onClick={increaseDriverQuantity}>
                              Increase
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} container justifyContent="flex-end" className="mt-05">
                        <strong>
                          ${driverPrice}.00
                        </strong>
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <small>
                          $49.00 each
                        </small>
                      </Grid>
                    </Grid>
                    <Grid container className='mt-4' border={1}>
                      <Grid item xs={12} container>
                        <Grid item xs={6}>
                          <strong>Monthly Charge</strong>
                        </Grid>
                        <Grid item xs={6} container justifyContent='flex-end'>
                          <strong>${driverPrice}.00</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <Box 
                            border={1}
                            borderLeft={0}
                            borderRight={0}
                            borderTop={0}
                            borderBottom={1} 
                            className="mt-1 mb-1">
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className='mt-4 mb-4' variant='outlined'>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Avatar className={classes.avatar}>
                          <div className={classes.icon}>
                            <IconCustomer className={classes.icon} />
                          </div>
                        </Avatar>
                      </Grid>
                      <Grid item xs={9} className="mt-05">
                        <Grid container>
                          <Grid item xs={12}>
                            <strong>
                              TMS Seats
                            </strong>
                          </Grid>
                          <Grid item xs={12}>
                            <small>
                              Quantity: {activeUserCount}
                            </small>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} container justifyContent="flex-end" className="mt-05">
                        <strong>
                          $0.00
                        </strong>
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <small>
                          $0.00 each
                        </small>
                      </Grid>
                    </Grid>
                    <Grid container className='mt-4' border={1}>
                      <Grid item xs={12} container>
                        <Grid item xs={6}>
                          <strong>Monthly Charge</strong>
                        </Grid>
                        <Grid item xs={6} container justifyContent='flex-end'>
                          <strong>Free</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            border={1}
                            borderLeft={0}
                            borderRight={0}
                            borderTop={0}
                            borderBottom={1}
                            className="mt-1 mb-1">
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Elements stripe={stripePromise}>
              <SubscriptionManagementCheckoutForm />
            </Elements>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

};

export default SubscriptionManagement;