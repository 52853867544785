import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getRevenueStatsForDashboard } from './DashboardService';
import FormattingFunctions from '../helpers/formattingFunctions';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardWeeklyOrders from './DashboardWeeklyOrders';
import DashboardWeeklyMargins from './DashboardWeeklyMargins';
import CoreUseWindowDimensions from '../core/CoreUseWindowDimensions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '24px 24px 10px 24px',
    textAlign: 'left',
    minHeight: '628px'
  },
  bold: {
    fontWeight: 'bold'
  },
  centerGroupButtons: {
    textAlignLast: 'center'
  },
  revDataLi: {
    padding: '0 0px'
  },
  revLoader: {
    marginTop: '20%',
    justifyContent: 'center',
  },
}));

const DashboardFinancialSummary = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentButtonClicked, setCurrentButtonClicked] = useState(1);
  const [dailyRevenue, setDailyRevenue] = useState([]);
  const [netRevenue, setNetRevenue] = useState(0);
  const [shipments, setShipments] = useState(0);
  const [avgMargin, setAvgMargin] = useState(0);
  
  const classes = useStyles();

  const { width } = CoreUseWindowDimensions();

  useEffect(() => {
    async function getDailyRevenue() { 
      const result = await getRevenueStatsForDashboard();
      if(result.length > 0) {
        setDailyRevenue(result);
        setNetRevenue(result[1].NetRevenue);
        setShipments(result[1].shipments);
        setAvgMargin(result[1].avgMargin);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getDailyRevenue();
  }, []);

  const updateCurrentButton = async(dayClicked) => {
    if (currentButtonClicked !== dayClicked) {
      setCurrentButtonClicked(dayClicked)
      setNetRevenue(dailyRevenue[dayClicked].NetRevenue);
      setShipments(dailyRevenue[dayClicked].shipments);
      setAvgMargin(dailyRevenue[dayClicked].avgMargin);      
    }
  };

  return (
    <Paper className={classes.paper} elevation={1} square={true}>
      {loading && <div align="center"><CircularProgress size={60} className={classes.revLoader} /> </div>}
      {!loading && <Grid container className="mb-2">
        <Grid item xs={12}>
          <Typography className="mb-2" variant="subtitle1" style={{display:'flex', justifyContent:'flex-start', fontWeight:'bold'}}>
            Financial Summary
          </Typography>
          <Grid item xs={12} className={classes.centerGroupButtons}>
            <ButtonGroup style={{ width: '100%', minWidth:'80px', maxWidth: '235px'}} color='primary' aria-label="outlined button group" orientation = {width > 1735 ? "horizontal" : "vertical"}>
              {currentButtonClicked !== 0 && <Button onClick={() => updateCurrentButton(0)} variant="outlined" style={{fontSize:"smaller"}}>Yesterday</Button>}
              {currentButtonClicked === 0 && <Button onClick={() => updateCurrentButton(0)} variant="contained" style={{fontSize:"smaller"}}>Yesterday</Button>}
              {currentButtonClicked !== 1 && <Button onClick={() => updateCurrentButton(1)} variant="outlined" style={{fontSize:"smaller"}}>Today</Button>}
              {currentButtonClicked === 1 && <Button onClick={() => updateCurrentButton(1)} variant="contained" style={{fontSize:"smaller"}}>Today</Button>}
              {currentButtonClicked !== 2 && <Button onClick={() => updateCurrentButton(2)} variant="outlined" style={{fontSize:"smaller"}}>Tomorrow</Button>}
              {currentButtonClicked === 2 && <Button onClick={() => updateCurrentButton(2)} variant="contained" style={{fontSize:"smaller"}}>Tomorrow</Button>}
            </ButtonGroup>
          </Grid>
          <List className='mt-1'>
            <ListItem className={classes.revDataLi}>
              <ListItemText primary="Net Revenue" style={{marginBottom: '0'}}/>
              <ListItemText
                style={{display:'flex', justifyContent:'flex-end', marginBottom: '0'}}
                primary={<div className={classes.bold}>{FormattingFunctions.formatCurrency(netRevenue)}</div>}
              />
            </ListItem>
            <ListItem className={classes.revDataLi}>
              <ListItemText primary="Shipments" style={{marginBottom: '0'}}/>
              <ListItemText
                style={{display:'flex', justifyContent:'flex-end', marginBottom: '0'}}
                primary={<div className={classes.bold}>{shipments}</div>}
              />
            </ListItem>
            <ListItem className={classes.revDataLi}>
              <ListItemText primary="Avg Margin" style={{marginBottom: '0'}}/>
              <ListItemText
                style={{display:'flex', justifyContent:'flex-end', marginBottom: '0'}}
                primary={<div className={classes.bold}>{`${avgMargin}%`}</div>}
              />
            </ListItem>
          </List>
          <Divider className="mt-2 mb-3"/>
          <DashboardWeeklyOrders />
          <Divider className="mt-2 mb-3"/>
          <DashboardWeeklyMargins />
        </Grid>
      </Grid>}
    </Paper>
  );

}

export default DashboardFinancialSummary;
