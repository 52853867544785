import HttpService from "../http-service";

export const truckVisitAppointmentSearch = async (searchParams) => {
    const token = localStorage.getItem('token');
    const uri = 'IntermodalTruckVisitAppointments/' + token + '/';

    const httpService = new HttpService();
    const result = await httpService
        .post(uri + 'TruckVisitAppointmentSearch', searchParams);
    return result;
}