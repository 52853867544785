import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Intercom/' + token + '/';

export async function getData(){
    const httpService = new HttpService();
    const result = await httpService
        .get(uri + 'GetDataEnvase');
    return result.data;
};