import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import CoreDataGridList from '../core/CoreDataGridList';
import CoreIntlPhoneOutput from '../core/CoreIntlPhoneOutput';
import { downloadFile } from '../globalFunction';
import { getList, exportList } from './CustomerService';
import CustomerViewSummary from './CustomerViewSummary';
import Validation from '../validation';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';

const CustomerListTable = () => {
  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("customerListAmount")) || 10,
    count: parseInt(localStorage.getItem("customerListAmount")) || 10,
    showObsolete: false,
    isASC: true,
    sortBy: 'InternalName',
    filters: []
  });
  let history = useHistory();

  const validation = new Validation();
  const [currentRowClicked, setCurrentRowClicked] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [listWidth, setListWidth] = useState('xl');
  const [inProgress, setInProgress] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [filterData, setFilterData] = useState({
    Search: '',
  });
  const [companyFeature, setCompanyFeature] = useState([]);

  const updateFilterData = (e) => {
    const { name, value } = e.target;
    filterData[name] = value;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  const closeSummary = () => {
    setIsSummaryOpen(false);    
  };

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => {  
        const name = params.getValue("ID");
        const obsolete = params.getValue("Obsolete");
        return (
          <div>
            <IconButton title="Open Full Record" onClick={() => openRecord(name)}>
              <OpenInBrowserIcon color={obsolete ? 'error' : 'primary'}/>
            </IconButton>
            <IconButton title="Open Slideout" onClick={() => openSlideout(name)}>
              <MenuOpenIcon color={obsolete ? 'error' : 'primary'}/>
            </IconButton>
          </div>
        )
      }
    },
    {
      field: "ID",
      headerName: "ID",
      hide: true,
    },
    {
      field: "InternalName",
      headerName: "Customer Name", 
      align: 'left',
      flex: 2,    
    },
    {
      field: 'CustDistrict',
      headerName: 'District',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: 'CustCounty',
      headerName: 'Barangay',
      align: 'left',
      flex: 1,
      hide: companyFeature && companyFeature.IsICTSI ? false : true,
    },
    {
      field: "CustCity",
      headerName: "City & State",
      align: 'left',
      flex: 1,
    },    
    {
      field: "CustPhone",
      headerName: "Phone",
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <CoreIntlPhoneOutput value={params.getValue("CustPhone")} />
        )
      }
    },
    {
      field: "Email",
      headerName: "Email",
      align: 'left',
      flex: 1,
    },
    {
      field: "AccountRep",
      headerName: "Account Rep",
      align: 'left',
      flex: 1,
    },
    {
      field: "Obsolete",
      headerName: "Active",
      align: 'left',
      flex: 1,
      hide: !params.showObsolete,
      renderCell: (params) => {
        const name = params.getValue("Obsolete") ? "No" : "Yes";
        return (
          <div>
            {name}
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    async function getInitCustomerList() {
      const result = await getList({
        page: 1,
        amount: parseInt(localStorage.getItem("customerListAmount")) || 10,
        count: parseInt(localStorage.getItem("customerListAmount")) || 10,
        showObsolete: false,
        isASC: true,
        sortBy: 'InternalName',
        filters: []
      });
      if (result.length > 0) {
        setParams(c => ({...c, count: result[0].TotalRows}));
      } else {
        setParams(c => ({...c, count: 0}));
      }
      setCustomers(result);
      setInProgress(false); 
    }
    getCompanyFeature()
        .then(function(response){
          setCompanyFeature(response);
        });
    getInitCustomerList();
  }, []);

  function changePage(page) {
    params.page = page + 1;
    getCustomerList();  
    setParams(params);
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    getCustomerList();
    setParams(params);
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    getCustomerList();  
    setParams(params);
    localStorage.setItem('customerListAmount', amount);
  };

  function pushParamFilter(filterName, value, operator) {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  };

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }   
    params.page = 1;
    setParams(params);
    getCustomerList();
  };

  const getCustomerList = async () => {
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setParams(c => ({ ...c, count: result[0].TotalRows }));
    } else {
      setParams(c => ({ ...c, count: 0 }));
    }
    setCustomers(result);
    setInProgress(false); 
  };

  const exportCustomerList = async () => {
    const result = await exportList(params);
    downloadFile(result, "customer-export.csv");
  };

  const openRecord = (id) => {
    history.push('/spark/customer/view/' + id);
  };

  const openSlideout = (id) => {
    setCurrentRowClicked(id);
    setIsSummaryOpen(true);
  };

  const showInactive = () => {
    params.showObsolete = true;
    setParams(params);
    getCustomerList();
  };

  const hideInactive = () => {
    params.showObsolete = false;
    setParams(params);
    getCustomerList();
  };

  return (
    <div style={{position:'relative'}}>
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns}
          data={customers}
          params={params}
          showInactive={showInactive}
          hideInactive={hideInactive}
          exportList={exportCustomerList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='asc'
          defaultSortBy='InternalName'
          filterData={filterData}
          updateFilterData={updateFilterData}
          showObsoleteButton={true}
          listWidth={listWidth}
          setListWidth={setListWidth}
        />
      </Container>
      <CustomerViewSummary
        customerID={currentRowClicked}
        isOpen={isSummaryOpen}
        onClose={closeSummary}
      />
    </div>
  )
};

export default CustomerListTable;