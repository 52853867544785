import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/Person';

import CoreTextField from '../core/CoreTextField';
import ProvinceField from '../province/ProvinceField';
import CountryField from '../country/CountryField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import CorePhoneField from '../core/CorePhoneField';

const CustomerEditContentAddress = (props) => {
  const {
    customerFormData,
    customerFormValidation,
    updateCustomerFormData,
    coordinates,
    branch
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Physical Address</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            {(customerFormData.CustCountry !== "Philippines" && branch.Country !== "Philippines")  && <>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="CustAddress"
                  label="Address"
                  value={customerFormData.CustAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="CustCity"
                  label="City"
                  value={customerFormData.CustCity}
                  validationError={customerFormValidation.CustCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="CustProvince"
                  label="State / Province"
                  value={customerFormData.CustProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="CustPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.CustPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CountryField
                  name="CustCountry"
                  label="Country"              
                  value={customerFormData.CustCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustCountry}
                />
              </Grid>
            </>}
            {(customerFormData.CustCountry === "Philippines" || branch.Country === "Philippines") && <>
              <Grid item md={6} xs={6}>
                <CountryField
                  name="CustCountry"
                  label="Country"              
                  value={customerFormData.CustCountry}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustCountry}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <ProvinceField
                  name="CustProvince"
                  label="State / Province"
                  value={customerFormData.CustProvince}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustProvince}
                />
              </Grid> 
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="CustCity"
                  label="City"
                  value={customerFormData.CustCity}
                  validationError={customerFormValidation.CustCity}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                  autoComplete="disable-autocomplete"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <CoreTextField
                  name="CustPostalCode"
                  label="ZIP / Postal Code"
                  value={customerFormData.CustPostalCode}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustPostalCode}
                  maxLength={15}
                  autoComplete="disable-autocomplete"
                />
              </Grid> 
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="CustDistrict"
                  label="District"
                  value={customerFormData.CustDistrict}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="CustCounty"
                  label="Barangay"
                  value={customerFormData.CustCounty}
                  onChange={e => updateCustomerFormData(e)}
                  maxLength={100}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CoreTextField
                  name="CustAddress"
                  label="Address"
                  value={customerFormData.CustAddress}
                  onChange={e => updateCustomerFormData(e)}
                  validationError={customerFormValidation.CustAddress}
                  icon={<PersonIcon fontSize="default" />}
                  maxLength={255}
                  autoComplete="disable-autocomplete"
                />
              </Grid>       
              
              
            </>}
            <Grid item md={6} xs={6}>
              <CorePhoneField
                name="CustPhone"
                label="Primary Number"              
                value={customerFormData.CustPhone}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.CustPhone}
              />
            </Grid>
            <Grid item md={6} xs={6} className="mb-2">
              <CorePhoneField
                name="CustTollFree"
                label="Secondary Number"              
                value={customerFormData.CustTollFree}
                onChange={e => updateCustomerFormData(e)}
                validationError={customerFormValidation.CustTollFree}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          {coordinates &&
            <CoreHereDisplayMap
              coordinates={coordinates}
            />
          }            
        </Grid>
      </Grid>
    </Container>  
  );
}

export default CustomerEditContentAddress;