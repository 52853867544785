import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as EquipmentLightSvg } from '../assets/svg-icons/equipment-light@1x.svg'

const IconEquipmentLight = () => {
  return (
    <SvgIcon fontSize="small">
      <EquipmentLightSvg />
    </SvgIcon>
  )
}

export default IconEquipmentLight;