import PersonnelCreateButton from './PersonnelCreateButton';
import PersonnelListTable from './PersonnelListTable';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';

const PersonnelList = () => {
  return (
    <div>
      <CorePageHeader
        title="Personnel"
        breadCrumb="Home / ... / Personnel List"
        createButton={
          <PersonnelCreateButton/>
        }
      />
      <Container maxWidth="false" className="mt-4">
        <PersonnelListTable/>
      </Container>
    </div>
  );

}

export default PersonnelList;