import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CoreDateField from '../core/CoreDateField';
import PersonnelDriverField from '../personnel/PersonnelDriverField';

const BillEditContentInfo = (props) => {
  const {
    billFormData,
    billFormValidation,
    updateBillFormData,
  } = props;

  return (
    <div>
      <Container maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h4>Expense Information</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className="mb-2">
            <PersonnelDriverField
              name="PayToPersonnel"
              label="Driver Payee"
              value={billFormData.PayToPersonnel}
              nameData="PayToPersonnelID"
              valueData={billFormData.PayToPersonnelID}
              onChange={e => updateBillFormData(e)}
            />
          </Grid>
          <Grid item md={6} xs={12} className="mb-2">
          <CoreDateField
            name="BillDate"
            label="Expense Date"
            value={billFormData.BillDate}
            validationError={billFormValidation.BillDate}
            isRequired={true}
            onChange={e => updateBillFormData(e)}
          />
          </Grid>
        </Grid>
      </Container>
    </div>
  );

}

export default BillEditContentInfo;