import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import { downloadFile } from '../globalFunction';
import { getList, exportList } from '../intermodalEvent/IntermodalEventService';
import CoreDataGridList from '../core/CoreDataGridList';
import DispatchListStatus from './DispatchListStatus';
import DispatchFilterRow from './DispatchFilterRow';
import Validation from '../validation';
import OrderViewSummary from '../order/OrderViewSummary';
import DispatchListDriverAssignDrawer from './DispatchListDriverAssignDrawer';
import { columns } from './DispatchListColumnService';

const DispatchListTable = () => {

  const [params, setParams] = useState({
    page: 1,
    amount: parseInt(localStorage.getItem("dispatchListAmount")) || 10,
    isASC: false,
    sortBy: 'OrderNum',
    filters: [],
    ShowAll: true,
    ShowOnVessel: false,
    ShowReady: false,
    ShowOnHold: false,
    ShowDispatched: false,
    ShowDropped: false,
    ShowScheduled: false,
    ShowLastFreeDay: false,
    ShowRejected: false,
  });
  let history = useHistory();

  const validation = new Validation();
  const location = useLocation();
  const onCloseRoute = location.pathname + location.search;

  const [isSummaryOpen, setIsSummaryOpen] = useState();
  const [listWidth, setListWidth] = useState('xl');
  const [currentEventClicked, setCurrentEventClicked] = useState([]);
  const [isDriverAssignDrawerOpen, setIsDriverAssignDrawerOpen] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [dispatches, setDispatches] = useState([]);
  const [rowCount, setRowCount] = useState(10);
  const [filterData, setFilterData] = useState({
    Search: '',
    DateFrom: null,
    DateTo: null,
    ContainerSize: '',
    ContainerType: '',
    Customer: '',
    LocationTemplateID: '',
    SteamshipLine: '',
    HasAppointment: '',
    ShipmentType: '',
    LoadStatus: '',
    LoadedStatus: '',
    AwaitingPickupDate: '',
    NeedsUpdateDate: '',
    RunningLateDate: '',
    ShowLastFreeDay: '',
    EmptiesOut: '',
    EmptiesIn: '',
    LoadsOut: '',
    LoadsIn: '',
    Deliveries: '',
    PerDiemLFD: '',
    DemurrageLFD: '',
  });

  useEffect(() => {
    buildURLSearchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDispatchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const updateFilterData = (e) => {
    const { name, value } = e.target;
    filterData[name] = value;
    setFilterData({ ...filterData });
    buildParamFilter();
  };

  function buildURLSearchFilters() {
    const urlParams = new URLSearchParams(window.location.search);
    const listStatus = urlParams.get('listStatus');
    const statusType = urlParams.get('statusType');
    const statusTypeDate = urlParams.get('statusTypeDate');
    const customer = urlParams.get('customer');
    const locationTemplateID = urlParams.get('locationTemplateID');
    const steamshipLine = urlParams.get('steamshipLine');
    const appointment = urlParams.get('appointment');
    const containerSize = urlParams.get('containerSize');
    const containerType = urlParams.get('containerType');
    const shipmentType = urlParams.get('shipmentType');
    const emptiesOut = urlParams.get('emptiesOut');
    const emptiesIn = urlParams.get('emptiesIn');
    const loadsOut = urlParams.get('loadsOut');
    const loadsIn = urlParams.get('loadsIn');
    const deliveries = urlParams.get('deliveries');
    const perdiemlfd = urlParams.get('perdiemlfd');
    const demurragelfd = urlParams.get('demurragelfd');
    if (listStatus) {
      updateStatusButtonChange({ target: { name: listStatus } });
    }
    if (statusType === "AwaitingPickup") {
      setFilterData(c => ({ ...c, AwaitingPickupDate: statusTypeDate }));
      pushParamFilter('AwaitingPickupDate', statusTypeDate, 'Equals');
    }
    if (statusType === "NeedsUpdate") {
      setFilterData(c => ({ ...c, NeedsUpdateDate: statusTypeDate }));
      pushParamFilter('NeedsUpdateDate', statusTypeDate, 'Equals');
    }
    if (statusType === "RunningLate") {
      setFilterData(c => ({ ...c, RunningLateDate: statusTypeDate }));
      pushParamFilter('RunningLateDate', statusTypeDate, 'Equals');
    } if (customer) {
      setFilterData(c => ({ ...c, Customer: customer }));    
      pushParamFilter("Customer", customer, 'Contains');
    } if (locationTemplateID) {
      setFilterData(c => ({ ...c, LocationTemplateID: locationTemplateID }));
      pushParamFilter('LocationTemplateID', locationTemplateID, 'Equals');
    } if (steamshipLine) {
      setFilterData(c => ({ ...c, SteamshipLine: steamshipLine }));    
      pushParamFilter("SteamshipLine", steamshipLine, 'Equals');
    } if (appointment) {
      setFilterData(c => ({ ...c, HasAppointment: appointment }));
      pushParamFilter("HasAppointment", appointment, 'Equals');
    } if (containerSize) {
      setFilterData(c => ({ ...c, ContainerSize: containerSize }));    
      pushParamFilter("ContainerSize", containerSize, 'Contains');
    } if (containerType) {
      setFilterData(c => ({ ...c, ContainerType: containerType }));    
      pushParamFilter("ContainerType", containerType, 'Contains');
    } if (shipmentType) {
      setFilterData(c => ({ ...c, ShipmentType: shipmentType }));    
      pushParamFilter("ShipmentType", shipmentType, 'Contains');
    } if (emptiesOut) {
      setFilterData(c => ({ ...c, EmptiesOut: emptiesOut }));    
      pushParamFilter("EmptiesOut", emptiesOut, 'Equals');
    } if (emptiesIn) {
      setFilterData(c => ({ ...c, EmptiesIn: emptiesIn }));    
      pushParamFilter("EmptiesIn", emptiesIn, 'Equals');
    } if (loadsOut) {
      setFilterData(c => ({ ...c, LoadsOut: loadsOut }));    
      pushParamFilter("LoadsOut", loadsOut, 'Equals');
    } if (loadsIn) {
      setFilterData(c => ({ ...c, LoadsIn: loadsIn }));    
      pushParamFilter("LoadsIn", loadsIn, 'Equals');
    } if (deliveries) {
      setFilterData(c => ({ ...c, Deliveries: deliveries }));    
      pushParamFilter("Deliveries", deliveries, 'Equals');
    } if (perdiemlfd) {
      setFilterData(c => ({ ...c, PerDiemLFD: perdiemlfd }));    
      pushParamFilter("PerDiemLFD", perdiemlfd, 'Equals');
    } if (demurragelfd) {
      setFilterData(c => ({ ...c, DemurrageLFD: demurragelfd }));    
      pushParamFilter("DemurrageLFD", demurragelfd, 'Equals');
    }   
    if (params.filters.length > 0) {
      setParams({ ...params });
    }
  };

  const resetFilterRow = () => {
    params.filters = [];
    params.page = 1;
    setFilterData(c => ({ ...c, DateFrom: null }));
    setFilterData(c => ({ ...c, DateTo: null }));
    setFilterData(c => ({ ...c, ContainerSize: '' }));
    setFilterData(c => ({ ...c, ContainerType: '' }));
    setFilterData(c => ({ ...c, Customer: '' }));
    setFilterData(c => ({ ...c, LocationTemplateID: '' }));
    setFilterData(c => ({ ...c, SteamshipLine: '' }));
    setFilterData(c => ({ ...c, HasAppointment: '' }));
    setFilterData(c => ({ ...c, ShipmentType: '' }));
    setFilterData(c => ({ ...c, EmptiesOut: '' }));
    setFilterData(c => ({ ...c, EmptiesIn: '' }));
    setFilterData(c => ({ ...c, LoadsOut: '' }));
    setFilterData(c => ({ ...c, LoadsIn: '' }));
    setFilterData(c => ({ ...c, Deliveries: '' }));
    setFilterData(c => ({ ...c, PerDiemLFD: '' }));
    setFilterData(c => ({ ...c, DemurrageLFD: '' }));
    setFilterData(c => ({ ...c, LoadStatus: '' }));
    setFilterData(c => ({ ...c, LoadedStatus: '' }));
    setFilterData(c => ({ ...c, AwaitingPickupDate: '' }));
    setFilterData(c => ({ ...c, NeedsUpdateDate: '' }));
    setFilterData(c => ({ ...c, RunningLateDate: '' }));
    setFilterData(c => ({ ...c, ShowLastFreeDay: '' }));
    setParams({ ...params });
  };

  const closeSummary = () => {
    setIsSummaryOpen(false)
  };

  function changePage(page) {
    params.page = page + 1;
    setParams({ ...params });
  };

  function sort(sortOrder) {
    params.page = 1;
    params.sortBy = sortOrder.field;
    if (sortOrder.sort.includes("asc")) {
      params.isASC = true;
    } else {
      params.isASC = false;
    }
    setParams({ ...params });
  };

  function changeRowsPerPage(amount) {
    params.page = 1;
    params.amount = amount;
    setParams({ ...params });
    localStorage.setItem("dispatchListAmount", amount);
  };

  function pushParamFilter(filterName, value, operator) {
    params.filters.push({
      IsActive: true,
      Column: filterName,
      Operator: operator,
      ValueOne: value,
      ValueTwo: null
    });
  };

  function buildParamFilter() {
    params.filters = [];
    if (!validation.isEmpty(filterData.Search)) {
      pushParamFilter("Search", filterData.Search, 'Contains');
    }
    if (filterData.DateFrom) {
      pushParamFilter("AppointmentDateStart", filterData.DateFrom, 'Greater Than');
    }
    if (filterData.DateTo) {
      pushParamFilter("AppointmentDateStart", filterData.DateTo, 'Less Than');
    }
    if (!validation.isEmpty(filterData.HasAppointment)) {
      pushParamFilter("HasAppointment", filterData.HasAppointment, 'Equals');
    }
    if (!validation.isEmpty(filterData.EquipmentType)) {
      pushParamFilter("EquipmentType", filterData.EquipmentType, 'Contains');
    }
    if (!validation.isEmpty(filterData.ContainerSize)) {
      pushParamFilter("ContainerSize", filterData.ContainerSize, 'Contains');
    }
    if (!validation.isEmpty(filterData.ShipmentType)) {
      pushParamFilter("ShipmentType", filterData.ShipmentType, 'Contains');
    }
    if (!validation.isEmpty(filterData.LoadStatus)) {
      pushParamFilter("LoadStatus", filterData.LoadStatus, 'Contains');
    }
    if (!validation.isEmpty(filterData.LoadedStatus)) {
      pushParamFilter("LoadedStatus", filterData.LoadedStatus, 'Contains');
    }
    if (!validation.isEmpty(filterData.IsBeingTraced)) {
      pushParamFilter("IsBeingTraced", filterData.IsBeingTraced, 'Equals');
    }
    if (!validation.isEmpty(filterData.AwaitingPickupDate)) {
      pushParamFilter("AwaitingPickupDate", filterData.AwaitingPickupDate, 'Equals');
    }
    if (!validation.isEmpty(filterData.NeedsUpdateDate)) {
      pushParamFilter("NeedsUpdateDate", filterData.NeedsUpdateDate, 'Equals');
    }
    if (!validation.isEmpty(filterData.RunningLateDate)) {
      pushParamFilter("RunningLateDate", filterData.RunningLateDate, 'Equals');
    }
    if (!validation.isEmpty(filterData.EmptiesOut)) {
      pushParamFilter("EmptiesOut", filterData.EmptiesOut, 'Equals');
    }
    if (!validation.isEmpty(filterData.EmptiesIn)) {
      pushParamFilter("EmptiesIn", filterData.EmptiesIn, 'Equals');
    }
    if (!validation.isEmpty(filterData.LoadsOut)) {
      pushParamFilter("LoadsOut", filterData.LoadsOut, 'Equals');
    }
    if (!validation.isEmpty(filterData.LoadsIn)) {
      pushParamFilter("LoadsIn", filterData.LoadsIn, 'Equals');
    }
    if (!validation.isEmpty(filterData.Deliveries)) {
      pushParamFilter("Deliveries", filterData.Deliveries, 'Equals');
    }
    if (!validation.isEmpty(filterData.PerDiemLFD)) {
      pushParamFilter("PerDiemLFD", filterData.PerDiemLFD, 'Equals');
    }
    if (!validation.isEmpty(filterData.DemurrageLFD)) {
      pushParamFilter("DemurrageLFD", filterData.DemurrageLFD, 'Equals');
    }
    params.page = 1;
    setParams({ ...params });
  };

  const getDispatchList = async () => {
    setInProgress(true);
    const result = await getList(params);
    if (result.length > 0) {
      setRowCount(result[0].TotalRows);
    } else {
      setRowCount(0);
    }
    setDispatches(result);
    setInProgress(false);
  };

  const exportDispatchList = async () => {
    const result = await exportList(params);
    downloadFile(result, "dispatch-export.csv");
  };

  const updateStatusButtonChange = e => {
    const { name } = e.target;
    params.filters = [];
    params.ShowAll = false; 
    params.ShowOnHold = false;
    params.ShowDispatched = false;
    params.ShowRejected = false;
    params.ShowOnVessel = false;
    params.ShowReady = false;    
    params.ShowDropped = false;
    params.ShowScheduled = false;
    params.ShowLastFreeDay = false;
    switch (name) {
      case 'All':
        params.ShowAll = true;  
        params.ShowLastFreeDay = false;
        break;
      case 'ShowOnVessel':
        params.ShowOnVessel = true;  
        break;
      case 'ShowReady':
        params.ShowReady = true;    
        break;
      case 'ShowOnHold':
        params.ShowOnHold = true;
        break;
      case 'ShowDispatched':
        params.ShowDispatched = true;
        break;
      case 'ShowDropped': 
        params.ShowDropped = true;
        break;
      case 'ShowScheduled': 
        params.ShowScheduled = true;
        break;
      case 'ShowLastFreeDay':
        params.ShowLastFreeDay = true;
        break;
      case 'ShowRejected':
        params.ShowRejected = true;
        break;
      default:
        break;
    }
    // if (params.ShowLastFreeDay) {
    //   pushParamFilter("ShowLastFreeDay", true, 'Equals');
    // } else {
    //   setFilterData(c => ({ ...c, ShowLastFreeDay: '' }));
    // }
    params.page = 1;
    setParams({ ...params });
  };

  const onCellClick = e => {
    if (e.field === "Driver") {
      setCurrentEventClicked(e.row);
      setIsDriverAssignDrawerOpen(true);
    }
  };

  const closeAssignDriverDrawer = () => {
    setIsDriverAssignDrawerOpen(false);
    getDispatchList();
  };

  const openRecord = (id) => {
    history.push({
      pathname: '/spark/order/view/' + id,
      state: {
        onCloseRoute: onCloseRoute,
      }
    });
  };
  
  const openSlideout = async (id, status) => {
    setCurrentEventClicked(c => ({ ...c, IntermodalOrderID: id}));
    setCurrentEventClicked(c => ({ ...c, Status: status}));
    setIsSummaryOpen(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <DispatchListStatus
        dispatchStatuses={params}
        dispatches={dispatches}
        updateStatusButtonChange={updateStatusButtonChange}
        listWidth={listWidth}
      />
      <DispatchFilterRow
        filterData={filterData}
        updateFilterData={updateFilterData}
        resetFilterRow={resetFilterRow}
        listWidth={listWidth}
      />
      <Container maxWidth={listWidth} className="mt-1 munaGrid">
        {inProgress && <LinearProgress />}
        <CoreDataGridList
          columns={columns(params, openRecord, openSlideout)}
          data={dispatches}
          params={params}
          exportList={exportDispatchList}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          sort={sort}
          defaultSortDir='desc'
          defaultSortBy='OrderNum'
          filterData={filterData}
          updateFilterData={updateFilterData}
          onCellClick={onCellClick}
          listWidth={listWidth}
          setListWidth={setListWidth}
          rowCount={rowCount}
        />
      </Container>
      {<DispatchListDriverAssignDrawer
        event={currentEventClicked}
        setEvent={setCurrentEventClicked}
        isOpen={isDriverAssignDrawerOpen}
        onClose={closeAssignDriverDrawer}
      />}
      {/* <DispatchListAppointmentDrawer
        id={currentEventClicked}
        isOpen={isAppointmentDrawerOpen}
        onClose={closeAppointmentDrawer}
      /> */}
      {<OrderViewSummary
        orderID={currentEventClicked.IntermodalOrderID}
        dispatchStatus={currentEventClicked.Status}
        isOpen={isSummaryOpen}
        onClose={closeSummary}
        onCloseRoute={onCloseRoute}
      />}
    </div>
  );
};

export default DispatchListTable;