import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getList } from '../email_template/EmailTemplateService';
import ConfigurationViewEmailTemplateSectionListTable from './ConfigurationViewEmailTemplateSectionListTable';
import EmailTemplateCreateDialog from '../email_template/EmailTemplateCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewEmailTemplateSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isEmailTemplateCreateDialogOpen, setIsEmailTemplateCreateDialogOpen] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState({});

  useEffect(() => {
    getList()
      .then(function (response) {
        setEmailTemplates(response);
        setInProgress(false);
      });
  }, []);

  const getEmailTemplatesList = () => {
    getList()
      .then(function (response) {
        setEmailTemplates(response);
      });
  };

  const openEmailTemplateCreateDialog = () => {
    setIsEmailTemplateCreateDialogOpen(true);
  };

  const closeEmailTemplateCreateDialog = () => {
    setIsEmailTemplateCreateDialogOpen(false);
    getEmailTemplatesList();
  };

  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Email Template"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Email Template"></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"The email templates allow you to tailor the messaging of your outbound emails.  "}
                <span style={rightFloat}>
                </span>
              </Typography>
            <ConfigurationViewEmailTemplateSectionListTable
              emailTemplates={emailTemplates}
              getEmailTemplates={getEmailTemplatesList}
              setEmailTemplates={setEmailTemplates}
              openEmailTemplateCreateDialog={openEmailTemplateCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Email Template"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Email Template"></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <EmailTemplateCreateDialog
        isOpen={isEmailTemplateCreateDialogOpen}
        onClose={closeEmailTemplateCreateDialog}
      />
    </div>
  );
  
}

export default ConfigurationViewEmailTemplateSection;