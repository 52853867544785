import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import EmailTemplateListColumnEditButton from '../email_template/EmailTemplateListColumnEditButton';
import { deleteEmailTemplate } from '../email_template/EmailTemplateService';
import CoreAddButton from '../core/CoreAddButton';

const ConfigurationViewEmailTemplateSectionListTable = (props) => {
  const {
    emailTemplates,
    getEmailTemplates,
    setEmailTemplates,
    openEmailTemplateCreateDialog
  } = props;

  let columns = [
    {
      field: 'DocType',
      headerName: 'Document Type',
      align: 'left',
      flex: 1,
      sortable: false,    
    },
    {
      field: 'Subject',
      headerName: 'Subject',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Body',
      headerName: 'Body',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Controls',
      headerName: <CoreAddButton handleOpenCreate={openEmailTemplateCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const id = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <EmailTemplateListColumnEditButton
                id={id}
                getEmailTemplates={getEmailTemplates}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Email Template ID: #${id}`}
                deleteID={id}
                deleteFunction={deleteEmailTemplate}
                getList={getEmailTemplates}
              />}
          </div>
        )
      }
    }
  ];

  const [params, setParams] = useState({
    amount: parseInt(localStorage.getItem("configEmailTemplateAmount")) || 5
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
    localStorage.setItem("configEmailTemplateAmount", amount);
  };

  const onEmailTemplateRowEnter = (params) => {
    for (const emailTemp of emailTemplates) {
      if (emailTemp.ID === params.row.ID) {
        emailTemp.ShowControls = true;
      }
    }
    setEmailTemplates([ ...emailTemplates ]);
  };

  const onEmailTemplateRowLeave = (params) => {
    for (const emailTemp of emailTemplates) {
      if (emailTemp.ID === params.row.ID) {
        emailTemp.ShowControls = false;
      }
    }
    setEmailTemplates([ ...emailTemplates ]);
  };

  return (
    <Container style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        autoHeight={true} 
        rows={emailTemplates} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[5, 10, 15]}                
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onEmailTemplateRowEnter(e)}
        onRowLeave={e => onEmailTemplateRowLeave(e)}
      />
    </Container>
  );
}

ConfigurationViewEmailTemplateSectionListTable.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  getEmailTemplates: PropTypes.func.isRequired
};

export default ConfigurationViewEmailTemplateSectionListTable;