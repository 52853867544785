import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Validation from '../validation';

import CorePageHeader from '../core/CorePageHeader';
import CoreDateField from '../core/CoreDateField';
import CustomerField from '../customer/CustomerField';
import ReportsProfitByCustomerDateFilterTypeField from './ReportsProfitByCustomerDateFilterTypeField';
import { getProfitByCustomerSummary } from './ReportService';

const ReportsProfitByCustomerSummary = () => { 
  let history = useHistory();
  const [reportFormData, setReportFormData] = useState({
    CustomerID: null,
    DateFilterOption: 0,
    StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    EndDate: new Date()
  });
  const [reportFormValidation, setReportFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [reportData, setReportData] = useState([]);
  const [reportedLoaded, setReportedLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  let columns = [
    {
        field: 'Customer',
        headerName: 'Customer Name',
        align: 'left',
        flex: 1.5,
        sortable: false
    },
    {
      field: 'InvoiceCount',
      headerName: 'Order Count',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
        field: 'Revenue',
        headerName: 'Revenue',
        align: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
            const revenue = params.getValue("Revenue");
            return (
                <span>${revenue}</span>
            )
        }
    },
    {
        field: 'RevenuePerHour',
        headerName: 'Revenue/Hr',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const revenuePerHour = params.getValue("RevenuePerHour");
            return ( 
                <span>${revenuePerHour}</span>
            )
        }
    },
    {
        field: 'RevenuePerMile',
        headerName: 'Revenue/Mile',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const revenuePerMile = params.getValue("RevenuePerMile");
            return ( 
                <span>${revenuePerMile}</span>
            )
        }
    },
    {
        field: 'Expense',
        headerName: 'Total Costs',
        align: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const expense = params.getValue("Expense");
            return ( 
                <span>${expense}</span>
            )
        }
    },
    {
        field: 'ExpensePerMile',
        headerName: 'Cost/Mile',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const expensePerMile = params.getValue("ExpensePerMile");
            return ( 
                <span>${expensePerMile}</span>
            )
        }
    },
    {
        field: 'ExpensePerHour',
        headerName: 'Cost/Hour',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const expensePerHour = params.getValue("ExpensePerHour");
            return ( 
                <span>${expensePerHour}</span>
            )
        }
    },
    {
        field: 'Profit',
        headerName: 'Profit $',
        align: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const profit = params.getValue("Profit");
            return ( 
                <span>${profit}</span>
            )
        }
    },
    {
        field: 'ProfitMargin',
        headerName: 'Profit Margin',
        align: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => { 
            const profitMargin = params.getValue("ProfitMargin");
            return (
                <span>{profitMargin} %</span>
            )
        }
    }
  ];

  useEffect(() => {
    setReportFormValidation(buildValidation(reportFormData));
  }, [reportFormData]);

  const updateReportFormData = e => {
    const {
      name,
      value,
      nameData,
      valueData,
    } = e.target;
    reportFormData[name] = value;
    reportFormData[nameData] = valueData;
    setReportFormData({ ...reportFormData });
  };

  const setGrandTotalLine = (data) => {  
    var revenueTotal = 0.00;
    var expenseTotal = 0.00;
    var hoursTotal = 0.00;
    var milesTotal = 0.00;
    var invoiceCountTotal = 0;
    data.forEach(r => {
        revenueTotal = revenueTotal + r.Revenue;
        expenseTotal = expenseTotal + r.Expense;
        hoursTotal = hoursTotal + r.Hours;
        milesTotal = milesTotal + r.Miles;
        invoiceCountTotal = invoiceCountTotal + r.InvoiceCount;
    });
    var row = {
        CustomerID: 0, 
        Customer: 'TOTAL: ',
        InvoiceCount: invoiceCountTotal,
        Expense: expenseTotal,
        Hours: hoursTotal,
        Miles: milesTotal,
        Revenue: revenueTotal,
        RevenuePerHour: hoursTotal ? (revenueTotal / hoursTotal).toFixed(2) : 0,
        RevenuePerMile: milesTotal ? (revenueTotal / milesTotal).toFixed(2) : 0,
        ExpensePerHour: hoursTotal ? (expenseTotal / hoursTotal).toFixed(2) : 0,
        ExpensePerMile: milesTotal ? (expenseTotal / milesTotal).toFixed(2) : 0,
        Profit: (revenueTotal - expenseTotal).toFixed(2),
        ProfitMargin: revenueTotal ? (100 * ((revenueTotal - expenseTotal) / revenueTotal)).toFixed(2) : 0
    };
    setReportData(prevArr => [...prevArr, row]);
  };

  const onConfirm = e => { 
    setInProgress(true);
    setErrorMessage('');
    e.preventDefault();
    if (!new Validation().isValidObject(reportFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processReportRequest();
  };

  const processReportRequest = async () => {
    getProfitByCustomerSummary(reportFormData)
        .then(function(response) {
            setReportData(response);
            setGrandTotalLine(response);
            setReportedLoaded(true);
            setInProgress(false);
        });
  };

  const onClose = () => { 
    setReportFormData({
        CustomerID: null,
        DateFilterOption: 0,
        StartDate: new Date(),
        EndDate: new Date()
    });
    setReportData([]);
    setReportedLoaded(false);
    history.push('/spark/reports');
  };

  function ExportToolBar() { 
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <CorePageHeader
        title="Reports - Profit by Customer Summary"
      />
      <Container square={true} maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2} className="mt-2">
          <Grid item md={12} xs={12}>
            <h4>Profit by Customer Summary</h4>
          </Grid>
        </Grid>
        <Grid container item spacing={2} style={{paddingBottom: '10px'}}>
          <Grid item md={3} xs={3}>
            <CustomerField
              label="Customer"
              name="Customer"
              value={reportFormData.Customer}
              nameData="CustomerID"
              valueData={reportFormData.CustomerID}
              onChange={e => updateReportFormData(e)}
              isRequired={false}
              autoFocus={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <ReportsProfitByCustomerDateFilterTypeField
              label="Date Option"
              name="DateFilterOption"
              value={reportFormData.DateFilterOption}
              onChange={e => updateReportFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <CoreDateField
              label="Start Date"
              name="StartDate"
              value={reportFormData.StartDate}
              onChange={e => updateReportFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <CoreDateField
              name="EndDate"
              label="End Date"
              value={reportFormData.EndDate}
              onChange={e => updateReportFormData(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {errorMessage &&
                <Alert variant="filled" severity="error" className="mt-1 mb-1">
                {errorMessage}
                </Alert>
            }
          </Grid>
          <Grid item md={12} xs={12}>
            <Button onClick={onClose} disabled={inProgress} color="primary">
              Cancel
            </Button>
            <Button onClick={onConfirm} disabled={inProgress} color="primary">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Container>
      {reportedLoaded && <Container style={{'padding': 0}} square={true} maxWidth="lg" className="mt-4" component={Paper}>
        <DataGrid
          getRowId={(row) => row.CustomerID} 
          hideFooter={true}
          autoHeight={true}
          rows={reportData}
          columns={columns}
          pageSize={100}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          components={{
            Toolbar: ExportToolBar,
          }}
        />
      </Container>}
    </div>
  );
}

const buildValidation = (reportFormData) => {
    const {
      StartDate,
      EndDate
    } = reportFormData || {};
    const validation = new Validation();
    return {
      StartDate: validation.isEmpty(StartDate),
      EndDate: validation.isEmpty(EndDate) 
    };
};

export default ReportsProfitByCustomerSummary;