import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ShipTypeSolidSvg } from '../assets/svg-icons/shiptypesolid@1x.svg';

const IconShipTypeSolid = () => {
  return (
    <SvgIcon fontSize="small">
      <ShipTypeSolidSvg />
    </SvgIcon>
  )
}
export default IconShipTypeSolid;