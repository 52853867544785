import React from 'react';
import NavigationDrawerListItem from './NavigationDrawerListItem';
import IconQuickbooks from '../icon/IconQuickbooks';

const NavigationDrawerListItemQuickBooks = () => {
  return (
    <NavigationDrawerListItem
      title={'QuickBooks'}
      icon={<IconQuickbooks />}
      uri={'/spark/quickbooks'} />
  );

}

export default NavigationDrawerListItemQuickBooks;