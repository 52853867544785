import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory, useParams } from 'react-router-dom';
import Validation from '../validation';
import { get as getCustomer, update as customerUpdate } from './CustomerService';
import CoreExitButton from '../core/CoreExitButton';
import CustomerEditContent from './CustomerEditContent';
import { getGeocode } from '../mileage/MileageService';
import LinearProgress from '@material-ui/core/LinearProgress';

const CustomerEdit = (props) => {
  let history = useHistory();
  const { id } = useParams();

  const [inProgress, setInProgress] = useState(false);
  const [customerFormData, setCustomerFormData] = useState({});
  const [customerFormDataLoaded, setCustomerFormDataLoaded] = useState(false);
  const [customerFormValidation, setCustomerFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [addressCoordinates, setAddressCoordinates] = useState({'lat': 43, 'lng': -95});
  const [billAddressCoordinates, setBillAddressCoordinates] = useState({'lat': 43, 'lng': -95});

  useEffect(() => {
    getCustomer(id)
      .then(function(response) {
        response.CustProvince = response.CustProvince.trim();
        response.BillProvince = response.BillProvince.trim();
        setCustomerFormData({...response});
        setCustomerFormDataLoaded(true);
      });
  }, [id]);

  useEffect(() => {
    setCustomerFormValidation(buildValidation(customerFormData));
  }, [customerFormData]);

  useEffect(() => { 
    getGeocode(
      customerFormData.CustAddress, 
      customerFormData.CustCity, 
      customerFormData.CustProvince, 
      customerFormData.CustCountry, 
      customerFormData.CustPostalCode) 
      .then(function(response) {
        setAddressCoordinates(response);
     });
  }, [
    customerFormData.CustAddress, 
    customerFormData.CustCity, 
    customerFormData.CustCountry, 
    customerFormData.CustProvince, 
    customerFormData.CustPostalCode
  ]);

  useEffect(() => { 
    if (!customerFormData.BillAddressSame) {
      getGeocode(
        customerFormData.BillAddress, 
        customerFormData.BillCity, 
        customerFormData.BillProvince, 
        customerFormData.BillCountry, 
        customerFormData.BillPostalCode) 
      .then(function(response) {
        setBillAddressCoordinates(response);
     });
    }
  }, [
    customerFormData.BillAddress, 
    customerFormData.BillCity, 
    customerFormData.BillProvince, 
    customerFormData.BillCountry,
    customerFormData.BillPostalCode, 
    customerFormData.BillAddressSame
  ]);

  useEffect(() => {
    if (!customerFormDataLoaded) { 
      return;
    }
    if (customerFormData.BillAddressSame) {
      customerFormData.BillAddress = customerFormData.CustAddress;
      customerFormData.BillCity = customerFormData.CustCity;
      customerFormData.BillProvince = customerFormData.CustProvince;
      customerFormData.BillPostalCode = customerFormData.CustPostalCode;
      customerFormData.BillCountry = customerFormData.CustCountry;
      customerFormData.BillPhone = customerFormData.CustPhone;
      customerFormData.BillTollFree = customerFormData.CustTollFree;
      customerFormData.BillDistrict = customerFormData.CustDistrict;
      customerFormData.BillCounty = customerFormData.CustCounty;
    } else {
      customerFormData.BillAddress = "";
      customerFormData.BillCity = "";
      customerFormData.BillProvince = "";
      customerFormData.BillPostalCode = "";
      customerFormData.BillCountry = "";
      customerFormData.BillPhone = "";
      customerFormData.BillTollFree = "";
      customerFormData.BillDistrict = "";
      customerFormData.BillCounty = "";
    }
    setCustomerFormData({ ...customerFormData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFormData.BillAddressSame]);

  const updateCustomerFormData = e => {
    const {name, value, nameData, valueData} = e.target;
    customerFormData[name] = value;
    customerFormData[nameData] = valueData;
    setCustomerFormData({ ...customerFormData });
  };

  const formatCustomer = () => {
    if (new Validation().isNumber(customerFormData.CreditTerms)) {
      customerFormData.CreditTerms = 0;
    }
    setCustomerFormData({ ...customerFormData });
  };

  const onSave = e => {
    setInProgress(true);
    formatCustomer();
    e.preventDefault();
    if (!new Validation().isValidObject(customerFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processSaveRequest();
  };

  const processSaveRequest = async () => {
    setErrorMessage('');
    const result = await customerUpdate(customerFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    // Redirect to the location view page
    history.push('/spark/customer/view/' + customerFormData.ID);
  };

  const onClose = () => {
    history.push('/spark/customer/view/' + id);
  };

  return (
    <div>
      <CorePageHeader
        title={"Edit Customer " + customerFormData.InternalName}
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {customerFormDataLoaded &&
        <CustomerEditContent
          onSave={onSave}
          onClose={onClose}
          errorMessage={errorMessage}
          customerFormData={customerFormData}
          customerFormValidation={customerFormValidation}
          updateCustomerFormData={updateCustomerFormData}
          addressCoordinates={addressCoordinates}
          billAddressCoordinates={billAddressCoordinates}
          inProgress={inProgress}
        />}
      </Container>      
    </div>    
  );
}

const buildValidation = (customerFormData) => {
  const { 
    InternalName,
    LegalName,
    AccountRepID,
    BatchInvoiceMethod
  } = customerFormData || {};
  const validation = new Validation();
  return {
    InternalName: validation.isEmpty(InternalName),
    LegalName: validation.isEmpty(LegalName),
    AccountRepID: validation.isNumber(AccountRepID),
    BatchInvoiceMethod: validation.isEmpty(BatchInvoiceMethod)
  };
};

export default CustomerEdit;