import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CoreTextDisplayField from '../core/CoreTextDisplayField';
import IconOrganization from '../icon/IconOrganization';
import IconDateCal from '../icon/IconDateCal';
import IconInternalName from '../icon/IconInternalName';

const BillViewGeneralSection = (props) => {
  const {
    bill
  } = props;

  return (
    <Container maxWidth="lg" className="mt-4" component={Paper}>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} xs={12}>
          <h4>Expense Details</h4>
        </Grid>
        <Grid container spacing={2} xs={12} className="mb-2">
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Payee"
              value={bill.PersonnelPayee ? 
                <a href={"/spark/personnel/view/" + bill.PayToPersonnelID}>{bill.PersonnelPayee.InternalName}</a> : 
                null }
              icon={<IconInternalName />}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Applies To"
              value={
                <>
                  <div><a href={"/spark/order/view/" + bill.IntermodalOrderID}>{"Order " + bill.IntermodalOrder.OrderNum}</a></div>
                  {bill.IntermodalLeg ?
                    <div><a href={"/spark/order/view/" + bill.IntermodalOrderID}>{'Leg # ' + (bill.IntermodalLeg.LegIndex + 1)}</a></div> :
                    null
                  }
                </>
              }
              icon={<IconOrganization />}
            />
          </Grid>
          <Grid item xs={4}>
            <CoreTextDisplayField
              label="Expense Date"
              value={bill.BillDate}
              icon={<IconDateCal />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

}

export default BillViewGeneralSection;