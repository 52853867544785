import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create, getByFieldName, update } from './DocumentFieldService';
import DocumentFieldCreateDialogContent from './DocumentFieldCreateDialogContent';

const DocumentFieldCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    FieldName: '',
    FieldText: ''
  };

  const [documentFieldFormData, setDocumentFieldFormData] = useState(initialState);
  const [documentFieldFormValidation, setDocumentFieldFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isDocumentUpdate, setIsDocumentUpdate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDocumentFieldFormValidation(buildValidation(documentFieldFormData));
    }
  }, [documentFieldFormData, isOpen]);

  const updateDocumentFieldFormData = e => {
    const { name, value } = e.target;
    documentFieldFormData[name] = value;
    setDocumentFieldFormData({ ...documentFieldFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(documentFieldFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    const result = await create(documentFieldFormData);
    setInProgress(false);
    if (result.errors) {
      setErrorMessage(result.errors.Error);
      return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setDocumentFieldFormData({initialState});
    setIsDocumentUpdate(false);
    onClose();
  };

  const getDocumentFieldBody = () => {
    getByFieldName(documentFieldFormData.FieldName)
      .then(function(response) {
        if (response) { 
          setDocumentFieldFormData(response);
          setIsDocumentUpdate(true);
        } else {
          setIsDocumentUpdate(false);
        }
      });
  };

  const resetDocumentCreate = () => {    
    documentFieldFormData.ReportFieldID = 0;
    documentFieldFormData.FieldText = '';
    setDocumentFieldFormData({ ...documentFieldFormData });
    setIsDocumentUpdate(false);
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(documentFieldFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(documentFieldFormData);
    setInProgress(false);
    if (result.errors) {
        setErrorMessage(result.errors.Error);
        return;
    }
    closeDialog();
  };

  return (
    isOpen &&
    <DocumentFieldCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      documentFieldFormData={documentFieldFormData}
      documentFieldFormValidation={documentFieldFormValidation}
      updateDocumentFieldFormData={updateDocumentFieldFormData}
      inProgress={inProgress}
      isDocumentUpdate={isDocumentUpdate}
      getDocumentFieldBody={getDocumentFieldBody}
      resetDocumentCreate={resetDocumentCreate}
      onUpdate={onUpdate}
    />
  );
}

const buildValidation = (documentFieldFormData) => {
  const {
    FieldName
  } = documentFieldFormData || {};
  const validation = new Validation();
  return {
    FieldName: validation.isEmpty(FieldName)
  };
};

export default DocumentFieldCreateDialog;