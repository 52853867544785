import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ItinerarySvg } from '../assets/svg-icons/itinerary@1x.svg';

const IconItinerary = () => {
  return (
    <SvgIcon fontSize="small">
      <ItinerarySvg />
    </SvgIcon>    
  )
}
export default IconItinerary;