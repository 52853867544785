import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import OrderAppointmentCreateDialog from '../orderAppointment/OrderAppointmentCreateDialog';
import { Button } from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';

import OrderAppointmentLoadReadyDateDialog from '../orderAppointment/OrderAppointmentLoadReadyDateDialog';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    fontWeight: 550,
    width: '170px',
    justifyContent: 'space-between',
    },
}));

const OrderListBookAppointmentButton = (props) => { 
  const {
    order,
    getList,
    disable
  } = props;
  const classes = useStyles();
  const [isAppointmentConfigureDialogOpen, setIsBookAppointmentConfigureDialogOpen] = useState(false);
  const [isAppointmentLoadReadyDateDialogOpen, setIsAppointmentLoadReadyDateDialogOpen] = useState(false);

  const handleBookAppointment = () => { 
    if (order.Type === "Export" && !order.LoadReadyDate) { 
      openAppointmentLoadReadyDateDialog();
    } else {
      openAppointmentConfigureDialog();
    }
  };
  
  const openAppointmentConfigureDialog = () => { 
    setIsBookAppointmentConfigureDialogOpen(true);
  };

  const closeAppointmentConfigureDialog = () => { 
    setIsBookAppointmentConfigureDialogOpen(false);
    getList();
  };

  const openAppointmentLoadReadyDateDialog = () => { 
    setIsAppointmentLoadReadyDateDialogOpen(true);
  };

  const closeAppointmentLoadReadyDateDialog = () => { 
    setIsAppointmentLoadReadyDateDialogOpen(false);
  };

  return ( 
    <>
      <Button
        variant='outlined'
        color='primary'
        disabled={disable}
        className={classes.iconText}
        onClick={handleBookAppointment}
        endIcon={<CalendarTodayOutlined style={{marginLeft: '20px'}} />}
      >
        {disable ? "Unavailable" : "Book"}
      </Button>
      <OrderAppointmentCreateDialog
        isOpen={isAppointmentConfigureDialogOpen}
        onClose={closeAppointmentConfigureDialog}
        order={order}
        containerNum={order.ContainerNumber}
      />
      {isAppointmentLoadReadyDateDialogOpen &&
        <OrderAppointmentLoadReadyDateDialog
          isOpen={isAppointmentLoadReadyDateDialogOpen}
          handleClose={closeAppointmentLoadReadyDateDialog}
          openAppointmentCreateDialog={openAppointmentConfigureDialog}
          orderID={order.ID}
        />
      } 
    </>
  )

}

export default OrderListBookAppointmentButton;