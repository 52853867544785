import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { get, update } from '../contact/ContactService';
import ContactEditDialogContent from '../contact/ContactEditDialogContent';

const ContactEditDialog = (props) => {
  const {
    contactID,
    isOpen,
    onClose,
    currentFavorite,
  } = props;
  
  const [contactFormData, setContactFormData] = useState({});
  const [contactFormValidation, setContactFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [contactFormDataLoaded, setContactFormDataLoaded] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      get(contactID)
      .then(function(response) {
        setContactFormData(response);
        setContactFormDataLoaded(true);
      });    
    }
  }, [isOpen, contactID]);

  useEffect(() => {
    if (isOpen) {
      setContactFormValidation(buildValidation(contactFormData));
    }
  }, [contactFormData, isOpen]);

  const updateContactFormData = e => {
    const {name, value} = e.target;
    contactFormData[name] = value
    setContactFormData({ ...contactFormData});
  };

  const onUpdate = e => {
    e.preventDefault();
    if (!new Validation().isValidObject(contactFormValidation)) {
        setErrorMessage('Check required fields');
        return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(contactID, contactFormData);
    if (result.error) {
        setErrorMessage(result.error);
        return;
    }
    onClose();
  };

  return (
    contactFormDataLoaded && isOpen &&
    <ContactEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      contactFormData={contactFormData}
      contactFormValidation={contactFormValidation}
      updateContactFormData={updateContactFormData}
      currentFavorite={currentFavorite}
    />
  );
}

const buildValidation = (contactFormData) => {
  const {
      Name
  } = contactFormData || {};
  const validation = new Validation();
  return {
      Name: validation.isEmpty(Name)
  };
};

export default ContactEditDialog;