import React from 'react';
import Alert from '@material-ui/lab/Alert';

import CoreCreateContentStickyFooterButtons from '../core/CoreCreateContentStickyFooterButtons';
import PersonnelCreateContentInfo from './PersonnelCreateContentInfo';
import PersonnelCreateContentEmployeeInfo from './PersonnelCreateContentEmployeeInfo';
import PersonnelCreateContentDriverInfo from './PersonnelCreateContentDriverInfo';

const PersonnelCreateContent = (props) => {
  const {
    PersonnelFormData,
    onCreate,
    onClose,
    errorMessage,
    PersonnelFormValidation,
    updatePersonnelFormData,
    VendorFormData,
    updateVendorFormData,
    DriverLicenseFormData,
    updateDriverLicenseFormData,
    TWICFormData,
    updateTWICFormData,
    coordinates,
    inProgress
  } = props;

  return (
    <form onSubmit={onCreate}>
      <PersonnelCreateContentInfo
        PersonnelFormData={PersonnelFormData}
        PersonnelFormValidation={PersonnelFormValidation}
        updatePersonnelFormData={updatePersonnelFormData}
        coordinates={coordinates}
        VendorFormData={VendorFormData}
        updateVendorFormData={updateVendorFormData}
      />
      <br />
      <PersonnelCreateContentEmployeeInfo
        PersonnelFormData={PersonnelFormData}
        PersonnelFormValidation={PersonnelFormValidation}
        updatePersonnelFormData={updatePersonnelFormData}
      />
      <br />
      {
        (PersonnelFormData.DriverType === 1 || PersonnelFormData.DriverType === 2) && 
        <PersonnelCreateContentDriverInfo
          PersonnelFormData={PersonnelFormData}
          PersonnelFormValidation={PersonnelFormValidation}
          updatePersonnelFormData={updatePersonnelFormData}
          DriverLicenseFormData={DriverLicenseFormData}
          updateDriverLicenseFormData={updateDriverLicenseFormData}
          TWICFormData={TWICFormData}
          updateTWICFormData={updateTWICFormData}
        />
      }
      {
        errorMessage &&
        <Alert variant="filled" severity="error" className="mt-1 mb-1">
          {errorMessage}
        </Alert>
      }
      {!errorMessage && <br />}
      {!inProgress && <CoreCreateContentStickyFooterButtons onClose={onClose} />}
    </form>
  );
}

export default PersonnelCreateContent;