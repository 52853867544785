import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';

import IconBlock from '../icon/IconBlock';

const useStyles = makeStyles(() => ({
  button: { 
    paddingTop: '0px', 
    pointerEvents: 'none', 
    transform: 'scale(1.5)'
  },
  formControlStyle: {
    flexFlow: 'nowrap', 
    alignItems: 'center'
  }
}));

const CoreCheckDisplayField = (props) => {
  const classes = useStyles();
  const { 
    label, 
    value, 
    labelStyle={ color: 'gray'},
  } = props;
  
  const buildStartAdornment = () => {
    return (
      <Grid item xs={2} style={{ maxWidth: '45px', minWidth: '45px' }}>
        <IconButton className={classes.button}>
          {value ? <span style={{color: 'green'}}><CheckIcon /></span> : <IconBlock />}
        </IconButton>
      </Grid>);
  };

  return (
    <div style={{paddingBottom: 'inherit'}}>
      <Grid container>
        {buildStartAdornment()}
        <Grid item xs={9}>
          <FormControl fullWidth className={classes.formControlStyle}>
            <label style={ value ? {color: 'black' } : labelStyle}>
              {label}
            </label>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

CoreCheckDisplayField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

export default CoreCheckDisplayField;
