import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'Admin/' + token + '/';

export const getIsOwner = async () => {
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'IsOwner');
  return result;
};