import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as DroppedSvg } from '../assets/svg-icons/dsp-dropped@1x.svg';

const IconDropped = () => {
  return (
    <SvgIcon fontSize="small">
      <DroppedSvg />
    </SvgIcon>
  )
}
export default IconDropped;