import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { update } from './IntermodalEventService';
import IntermodalEventEditDialogContent from './IntermodalEventEditDialogContent';
import { setProvider } from '../location/LocationService'; 
import { getCredentialByID } from '../envase_trace/EnvaseTraceService';

const IntermodalEventEditDialog = (props) => {
  const {
    isOpen,
    onClose,
    eventFormData,
    setEventFormData
  } = props;

  const [eventFormValidation, setEventFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [initialProviderID, setInitialProviderID] = useState(null);
  const [traceCredentials, setTraceCredentials] = useState({});

  useEffect(() => {
    if (isOpen) { 
      setInitialProviderID(eventFormData.EnvaseTraceProviderID);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setEventFormValidation(buildValidation(eventFormData));
    }
  }, [eventFormData, isOpen]);

  useEffect(() => { 
    if (eventFormData.EnvaseTraceProviderID) { 
      getCredentialByID(eventFormData.EnvaseTraceProviderID)
        .then(function(response) { 
          setTraceCredentials(response);
        });
    }
  }, [eventFormData.LocationTemplateID, eventFormData.EnvaseTraceProviderID]);

  const updateEventFormData = e => {
    const { name, value, nameData, valueData, envaseTraceProviderID } = e.target;
    eventFormData[name] = value;
    eventFormData[nameData] = valueData;
    if (name === "LocationTemplateID") { 
      eventFormData.EnvaseTraceProviderID = envaseTraceProviderID;
      setInitialProviderID(envaseTraceProviderID);
    }
    setEventFormData({ ...eventFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(eventFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    let errorMsg = [];
    setErrorMessage('');
    const result = await update(eventFormData);
    setInProgress(false);
    if (result.error) {
      result.error.forEach((item, index) => {
        errorMsg.push(
          <div>
            {item}<br />
          </div>
        );
      });
      setErrorMessage(errorMsg);
      return;
    }
    setLocationProvider();
    onClose();
  };

  const setLocationProvider = async () => { 
    if (eventFormData.EnvaseTraceProviderID !== initialProviderID) { 
      await setProvider(eventFormData.LocationTemplateID, eventFormData.EnvaseTraceProviderID);
      setInitialProviderID(null);
    }
  };

  return (
    isOpen &&
    <IntermodalEventEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      eventFormData={eventFormData}
      eventFormValidation={eventFormValidation}
      updateEventFormData={updateEventFormData}
      inProgress={inProgress}
      traceCredentials={traceCredentials}
      initialProviderID={initialProviderID}
    />
  );
}

const buildValidation = (eventFormData) => {
  const {
    EventType,
    LocationTemplateID
  } = eventFormData || {};
  const validation = new Validation();
  return {
    EventType: validation.isEmpty(EventType),
    LocationTemplateID: validation.isNumber(LocationTemplateID)
  }
};

export default IntermodalEventEditDialog;