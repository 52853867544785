import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { update } from './EmailTemplateService';
import { getSmartTags, getEmailTemplateDocTypes } from '../resource/ResourceService';
import EmailTemplateEditDialogContent from './EmailTemplateEditDialogContent';

const EmailTemplateEditDialog = (props) => {
  const {
    isOpen,
    onClose,
    emailTemplateFormData,
    setEmailTemplateFormData
  } = props;

  const [emailTemplateFormValidation, setEmailTemplateFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [emailSmartTags, setEmailSmartTags] = useState([]);
  const [isEmailBodyClicked, setIsEmailBodyClicked] = useState(false);
  const [isEmailSubjectClicked, setIsEmailSubjectClicked] = useState(false);

  useEffect(() => {
    if (isOpen) { 
      getInitialDocTypeSmartTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setEmailTemplateFormValidation(buildValidation(emailTemplateFormData));
    }
  }, [emailTemplateFormData, isOpen]);

  useEffect(() => { 
    if (emailTemplateFormData.DocTypeID) { 
      getDocTypeSmartTags(emailTemplateFormData.DocTypeID);
    } else { 
      setEmailTemplateFormData(e => ({ ...e, DocTypeID: null }));
      setEmailSmartTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateFormData.DocTypeID]);

  function getInitialDocTypeSmartTags() { 
    getEmailTemplateDocTypes()
      .then(function(response) {
        var docTypes = response;
        docTypes.forEach(d => {
          if (d.Name.localeCompare(emailTemplateFormData.DocType) === 0) { 
            getDocTypeSmartTags(d.ID);
          }
        });
      });
  };
  
  function getDocTypeSmartTags(docTypeID) {
    getSmartTags(docTypeID)
    .then(function(response) {
      var data = response.split('\n');
      setEmailSmartTags(data);
    });
  };

  const emailSubjectClicked = (e) => { 
    setIsEmailBodyClicked(false);
    setIsEmailSubjectClicked(true);
  };

  const emailBodyClicked = (e) => {
    setIsEmailSubjectClicked(false); 
    setIsEmailBodyClicked(true);
  };

  const onSmartTagButtonClick = (value) => {
    if (isEmailBodyClicked) { 
      setEmailTemplateFormData(e => ({ ...e,  Body: emailTemplateFormData.Body + value}));
    } else if (isEmailSubjectClicked) { 
      setEmailTemplateFormData(e => ({ ...e,  Subject: emailTemplateFormData.Subject + value}));
    }
  };

  const updateEmailTemplateFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    emailTemplateFormData[name] = value;
    emailTemplateFormData[nameData] = valueData;
    setEmailTemplateFormData({ ...emailTemplateFormData });
  };

  const onUpdate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(emailTemplateFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await update(emailTemplateFormData);
    setInProgress(false);
    if (result.errors) {
        setErrorMessage(result.errors.Error);
        return;
    }
    onClose();
  };

  return (
    isOpen &&
    <EmailTemplateEditDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onUpdate={onUpdate}
      errorMessage={errorMessage}
      emailTemplateFormData={emailTemplateFormData}
      emailTemplateFormValidation={emailTemplateFormValidation}
      updateEmailTemplateFormData={updateEmailTemplateFormData}
      inProgress={inProgress}
      emailSmartTags={emailSmartTags}
      onSmartTagButtonClick={onSmartTagButtonClick}
      emailBodyClicked={emailBodyClicked}
      emailSubjectClicked={emailSubjectClicked}
    />
  );

}

const buildValidation = (emailTemplateFormData) => {
  const {
    DocType,
    Subject,
    Body
  } = emailTemplateFormData || {};
  const validation = new Validation();
  return {
    DocType: validation.isEmpty(DocType),
    Subject: validation.isEmpty(Subject),
    Body: validation.isEmpty(Body),
  };
};

export default EmailTemplateEditDialog;