import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import RevenueListColumnEditButton from './RevenueListColumnEditButton';
import { deleteReceivable } from './RevenueService';

const RevenueListTable = (props) => {
  const {
    receivables,
    getReceivables,
    setReceivables
  } = props;

  const [total, setTotal] = useState(0);

  useEffect(() => { 
    var totalAmount = 0;
    receivables.forEach(r => { 
      totalAmount += parseFloat(r.Subtotal);
    });
    setTotal(totalAmount.toFixed(2));
  }, [receivables]);

  let columns = [
    {
      field: 'ReceivableChargeType',
      headerName: 'Type',
      align: 'left',
      flex: 1.25,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("ReceivableChargeType");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <span>{name}</span>
          </div>
        )
      }
    },    
    {
      field: 'Description',
      headerName: 'Description',
      align: 'left',
      flex: 1.25,
      sortable: false
    },    
    {
      field: 'Units',
      headerName: 'Quantity',
      align: 'left',
      flex: 0.5,
      sortable: false
    },
    {
      field: 'UnitRate',
      headerName: 'Rate',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("UnitRate");
        return (
          <div>{"$"} {name}</div>
        )
      }
    },
    {
      field: 'Subtotal',
      headerName: receivables.length === 0 ? 'Total' : 'Total ($' + total + ')',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Subtotal");
        return (
          <div>{"$"} {name}</div>
        )
      }
    },
    {
      field: 'Controls',
      headerName: ' ',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const receivableChargeID = params.getValue("ReceivableChargeID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <RevenueListColumnEditButton
                receivableChargeID={receivableChargeID}
                getReceivables={getReceivables}
              />}
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Receivable Type: ${params.getValue("ReceivableChargeType")}`}
                deleteID={receivableChargeID}
                deleteFunction={deleteReceivable}
                getList={getReceivables}
              />}
          </div>
        )
      }
    }
  ];

  const onRevenueRowEnter = (params) => {
    for (const revenue of receivables) {
      if (revenue.ReceivableChargeID === params.row.ReceivableChargeID) {
        revenue.ShowControls = true;
      }
    }
    setReceivables([ ...receivables ]);
  };

  const onRevenueRowLeave = (params) => {
    for (const revenue of receivables) {
      if (revenue.ReceivableChargeID === params.row.ReceivableChargeID) {
        revenue.ShowControls = false;
      }
    }
    setReceivables([...receivables]);
  };

  return (
    <Paper style={{ padding: '0', height: ''  }} maxWidth="lg" className="mt-1 munaGrid" square>
      <DataGrid
        getRowId={(row) => row.ReceivableChargeID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={receivables} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onRevenueRowEnter(e)}
        onRowLeave={e => onRevenueRowLeave(e)}
      />
    </Paper>
  );
}

RevenueListTable.propTypes = {
  receivables: PropTypes.array.isRequired,
  getReceivables: PropTypes.func.isRequired
};

export default RevenueListTable;