export const getChassisUnitNumber = (leg) => {
  const equipment = leg.IntermodalOrder.IntermodalOrderEquipment
    .find(eq => {
      return eq.UnitType === 2
    });
  if (equipment && equipment.UnitNum) {
    return equipment.UnitNum;
  }
  return '-';
};

export const getOriginInternalName = (leg) => {
  const event = leg.IntermodalEvents
    .find(e => {
      return e.EventIndex === 0
    });
  if (event) {
    return event.LocationTemplate.InternalName;
  }
  return '-';
};

export const getDestinationInternalName = (leg) => {
  let event = leg.IntermodalEvents[0];
  for (const currentEvent of leg.IntermodalEvents){ 
    if (currentEvent.EventIndex > event.EventIndex) {
      event = currentEvent;
    }
  }
  if (event) {
    return event.LocationTemplate.InternalName;
  }
  return '-';
};