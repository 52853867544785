import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ReadySvg } from '../assets/svg-icons/ready@1x.svg'

const IconReady = () => {
  return (
    <SvgIcon fontSize="small">
      <ReadySvg />
    </SvgIcon>
  )
}

export default IconReady;
