import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';
import NoteTextAreaField from '../notes/NoteTextAreaField';

const DocumentFieldEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    documentFieldFormData,
    onUpdate,
    errorMessage,
    documentFieldFormValidation,
    updateDocumentFieldFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Document Field</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="FieldName"
                label="Field Name"
                value={documentFieldFormData.FieldName}
                onChange={e => updateDocumentFieldFormData(e)}
                isRequired={true}
                validationError={documentFieldFormValidation.FieldName}
                autoFocus={true}
                disabled={true}
              />
            </Grid>
            <Grid item md={12} xs={12}>
                <NoteTextAreaField
                  name="FieldText"
                  value={documentFieldFormData.FieldText}
                  onChange={e => updateDocumentFieldFormData(e)}
                  isRequired={false}
                  validationError={documentFieldFormValidation.FieldText}
                  maxLength={30000}
                />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default DocumentFieldEditDialogContent;