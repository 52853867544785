import React, { useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import CoreCheckboxField from '../core/CoreCheckboxField';
import FeePayAdrSlideOut from './FeePayAdrSlideOut';
import { CurrencyDisplay } from '@envasetechnologies/feepay-components';
import FeePayShoppingCartItemsDeleteButton from './FeePayShoppingCartItemsDeleteButton';

const useStyles = makeStyles((theme) => ({
  smallText: {
    color: 'gray',
    fontSize: '80%',
    overflowWrap: 'break-word',
  },
  underText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  },
  iconTextDisabled: {
    color: 'gray',   
  },
}));

const FeePayShoppingCartItemsList = (props) => { 
  const { 
    billerTypeItems,
    selectedBiller,
    handleCartItemSelect,
    deleteCartItem
  } = props;
  const classes = useStyles();

  const [isAdrSlideOutOpen, setIsAdrSlideOutOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const openAdrSlideOut = (item) => {
    setSelectedItem(i => ([...i, item]));
    setIsAdrSlideOutOpen(true);
  };
  const closeAdrSlideOut = () => {
    setIsAdrSlideOutOpen(false);
    setSelectedItem([]);
  };

  let columns = [
    {
        field: 'Controls',
        headerName: ' ',
        align: 'left',
        flex: 0.2,
        sortable: false,
        renderCell: (params) => { 
          const item = params.row;
          const isDisabled = selectedBiller !== "" && item.billerType !== selectedBiller;
          return (  
            <div style={{
              width:'100%', 
              minWidth: '100%', 
              display: 'flex',
              justifyContent: 'space-between'}}>
              <span>
                <CoreCheckboxField
                  label={null}
                  value={item.selected}
                  onChange={(e) => handleCartItemSelect(e, item)}
                  disabled={isDisabled}
                />
              </span>
            </div>
          )
        }
    },
    {
      field: ' ',
      headerName: 'Description',
      align: 'left',
      flex: 2,
      sortable: false, 
      renderCell: (params) => { 
        const item = params.row;
        return ( 
          <Grid item>
            <Typography variant='body2' className={classes.underText}>{item.productDescription} - {item.productType === "ICTSI_APPOINTMENT_INVOICE" ? "Appointment Fee" : "Terminal Fee"}</Typography>
            <Typography className={classes.smallText}>
              {item.productExternalId}
            </Typography>
          </Grid>
        )
      }
    },
    {
      field: 'ADR Payment',
      headerName: ' ',
      align: 'left',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const item = params.row;
        const isDisabled = selectedBiller !== "" && item.billerType !== selectedBiller;
        return (
          <>
            {
            <Button className={!isDisabled ? classes.iconText : classes.iconTextDisabled} disabled={isDisabled} variant='text' onClick={() => openAdrSlideOut(item)}>
              Pay VIA ADR
            </Button>
            }
          </>
        )
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'left',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => { 
        const item = params.row;
        return ( 
          <>
            {<CurrencyDisplay amount={item.amount} currency={"PHP"}/>}
          </>
        )
      }      
    },
    {
      field: 'Delete',
      headerName: ' ',
      align: 'right',
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        const item = params.row;
        const isDisabled = selectedBiller !== "" && item.billerType !== selectedBiller;
        return (
          <>
            {
              <FeePayShoppingCartItemsDeleteButton
                deleteCartItem={deleteCartItem}
                itemID={item.id}
                reference={`Item ${item.productExternalId}`}
                isDisabled={isDisabled}
              />
            }
          </>
        )
      }
    }
  ];

  return ( 
    <div>
      {billerTypeItems.map((billerType) => (
      <>
        <Container maxWidth="xl" className="m1-1 munaGrid" component={Paper}>
          <Grid container item xs spacing={2}>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <h3 style={{color: (selectedBiller !== "" && billerType.billerName !== selectedBiller) ? "#BDBDBD" : 'black'}}>Items</h3>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <h4 style={{color: (selectedBiller !== "" && billerType.billerName !== selectedBiller) ? '#BDBDBD' : 'black'}}>Biller {billerType.billerName}</h4>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl" className="m1-1 munaGrid" component={Paper}>
          <Grid container item xs spacing={2} className="mb-1">
            <Grid item xs={12}>
              <DataGrid
                getRowId={(row) => row.id}
                rows={billerType.cartItems}
                columns={columns}
                hideFooter={true} 
                autoHeight={true} 
                pageSize={100} 
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
              />
            </Grid>
          </Grid>
        </Container>
        <br/>
      </>
      ))}
      {isAdrSlideOutOpen && <FeePayAdrSlideOut
        isOpen={isAdrSlideOutOpen}
        onClose={closeAdrSlideOut}
        items={selectedItem}
      />}
    </div>
  )
};

export default FeePayShoppingCartItemsList;