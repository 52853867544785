import HTTPConfiguration from './http-configuration';
import { isJson } from './globalFunction';

class HttpService {
  constructor() {
    this.config = new HTTPConfiguration();
  }

  getConstructedEndPoint(endpoint) {
    let url = new URL(this.config.API_URL + endpoint);
    return url;
  }

  async get(endpoint, params = null) {
    let url = new URL(this.config.API_URL + endpoint);
    if (params) {
      url.search = new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    });
    const result = await response.text();
    if (isJson(result)) {
      return JSON.parse(result);
    }
    return result;
  }

  async post(endpoint, body, params = null) {
    let url = new URL(this.config.API_URL + endpoint);
    if (params) {
      let searchParams = new URLSearchParams();
      for (const key in params) {
        const value = params[key];
        if ((value && typeof value !== 'string') || (typeof value === 'string' && /\S/.test(value))) {
          searchParams.append(key, value);
        }
      }
      url.search = searchParams;
    }
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      },
      body: JSON.stringify(body)
    });
    const result = await response.text();
    if (isJson(result)) {
      return JSON.parse(result);
    }
    return result;
  }

  async postForFile(endpoint, body, params = null) {
    let url = new URL(this.config.API_URL + endpoint);
    if (params) {
      let searchParams = new URLSearchParams();
      for (const key in params) {
        const value = params[key];
        if ((value && typeof value !== 'string') || (typeof value === 'string' && /\S/.test(value))) {
          searchParams.append(key, value);
        }
      }
      url.search = searchParams;
    }
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    const result = await response.arrayBuffer();
    return result;
  }

  async postFile(endpoint, body, params = null) {
    let url = new URL(this.config.API_URL + endpoint);
    if (params) {
      url.search = new URLSearchParams(params).toString();
    }
    const response = await fetch(url, {
      method: 'POST',
      body: body
    });
    const result = await response.text();
    if (isJson(result)) {
      return JSON.parse(result);
    }
    return result;
  }

}

export default HttpService;