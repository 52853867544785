import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
  Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ItineraryEventList from './ItineraryEventList';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getLegItemStyle, getListStyle, onDragEnd as onDragToEnd } from './ItineraryDragDropUtils';
import IntermodalEventCreateDialog from '../intermodalEvent/IntermodalEventCreateDialog';
import IntermodalEventListTableDateField from '../intermodalEvent/IntermodalEventListTableDateField';
import IntermodalEventListTableLegDriverField from '../intermodalEvent/IntermodalEventListTableLegDriverField';
import { moveLegIndex } from './ItineraryService';
import ItineraryEventColumnStatusColor from './ItineraryColumnStatusColor';
import { getChassisUnitNumber, getOriginInternalName, getDestinationInternalName } from './ItineraryUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  smallText: {
    color: "gray",
    fontSize: "80%",
    overflowWrap: "break-word",
  },
  statusIcon: {
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '15px',
    padding: '5px',
    fontSize: '13px',
  },
  underText: {
    fontWeight: 'bold'
  }
}));

const ItineraryLegList = (props) => {
  const {
    legs, 
    legsLoading, 
    setLegsLoading, 
    getLegs, 
    selectedDriver,
    containerHeight
  } = props;
  const classes = useStyles();

  const [itemList, setItemList] = useState([]);
  const [isEventCreateDialogOpen, setEventCreateDialogOpen] = useState(false);
  const [addEventLegID, setAddEventLegID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);
  const [legMove, setLegMove] = useState([]);
  const [collapsedLegs, setCollapsedLegs] = useState([]);

  useEffect(() => {
    setItemList([...legs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legs]);

  useEffect(() => {
    if (legMove.source && legMove.destination) {
      var legID = itemList[legMove.destination.index].ID;
      var driverID = itemList[legMove.destination.index].DriverID;
      var newEventIndex = itemList[legMove.destination.index].DriverItineraryIndex + (legMove.destination.index - legMove.source.index);
      updateAndGetLegs(legID, driverID, newEventIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legMove]);

  const updateAndGetLegs = async (legID, driverID, newEventIndex) => {
    await moveLegIndex(legID, driverID, newEventIndex);
    await getLegs();
  }

  const handleMoreClick = (event, legID) => {
    setAnchorEl(event.currentTarget);
    setAddEventLegID(null);
    setAddEventLegID(legID);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setEventCreateDialogOpen(true);
  };

  const closeEventCreateDialog = async () => {
    await getLegs();
    setEventCreateDialogOpen(false);
  };

  const onDragEnd = (result) => {
    const items = onDragToEnd(result, itemList);
    if (!items) {
      return;
    }
    setItemList(items);
    setLegMove(result);
  }

  const accordionClicked = (leg) => async (event, isExpanded) => {
    let collapsedListTemp = [...collapsedLegs];
    if (!isExpanded) {
      collapsedListTemp.push(leg);
    } else {
      const legToRemove = collapsedLegs.find(c => c.ID === leg.ID);
      if (legToRemove) {
        const index = collapsedLegs.indexOf(legToRemove);
        if (index > -1){
          collapsedListTemp.splice(index, 1);
        }
      }
    }
    setCollapsedLegs(collapsedListTemp);
  };

  const isAccordianExpanded = (leg) => {
    const collapsedLeg = collapsedLegs.find(c => c.ID === leg.ID);
    if (collapsedLeg) {
      return false;
    }
    return true;
  };

  const updateEvent = async (legID) => {
    getLegs();
  };

  const getOrderLink = (leg) => {
    return '/spark/order/view/' + leg.IntermodalOrderID;
  };

  if (!legsLoading && legs.length <= 0) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100%'
      >
        <Typography variant='h5'>{Object.keys(selectedDriver).length === 0 ? "Please Select Driver" : "Driver has no data for that date range"}</Typography>
      </Box>
    )
  }

  return (
    <Paper elevation={0} style={{ minHeight: containerHeight, maxHeight: containerHeight, overflow: 'auto'}}>
      {legsLoading && <div><LinearProgress /></div>}
      <br></br>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {itemList.map((leg, index) => (
                <Draggable
                  key={leg.DriverItineraryIndex.toString()}
                  draggableId={leg.DriverItineraryIndex.toString()}
                  index={index}
                  isDragDisabled={legsLoading}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getLegItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Accordion expanded={isAccordianExpanded(leg)} onChange={accordionClicked(leg)} key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-label='Expand'
                          aria-controls='additional-actions1-content'
                          id='additional-actions1-header'
                          style={{userSelect: 'text', cursor: 'text'}}
                        >
                          <Grid
                            container
                            justify='space-between'
                            alignItems='center'
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                          >
                            <Grid item zeroMinWidth {...provided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </Grid>
                            <Grid item xs={1}>
                              <span className={classes.smallText}>
                                <a href={getOrderLink(leg)} target="_blank" rel='noreferrer'>
                                  Order# {leg.IntermodalOrder.OrderNum}
                                </a>
                              </span>
                              <Typography variant='body2' className={classes.underText}>
                                Move {leg.DriverItineraryIndex}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <span className={classes.smallText}>Container</span>
                              <Typography variant='body2' className={classes.underText}>
                                {getChassisUnitNumber(leg)}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <span className={classes.smallText}>Origin</span>
                              <Typography variant='body2' className={classes.underText}>
                                {getOriginInternalName(leg)}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <span className={classes.smallText}>Destination</span>
                              <Typography variant='body2' className={classes.underText}>
                                {getDestinationInternalName(leg)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <div style={{ minWidth: '150px', maxWidth: '150px' }}>
                                <IntermodalEventListTableLegDriverField
                                  leg={leg}
                                  getEvents={getLegs}
                                  setInProgress= {setLegsLoading}
                                />
                              </div>
                            </Grid>
                            <Grid item>
                              <div style={{ minWidth: '145px', maxWidth: '145px' }}>
                                <IntermodalEventListTableDateField
                                  leg={leg}
                                  getEvents={getLegs}
                                  setInProgress={setLegsLoading}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} zeroMinWidth>
                              <div>
                                <span
                                  className="delivery-status-circle"
                                  style={{ backgroundColor: ItineraryEventColumnStatusColor(leg.LegStatus) }}
                                >
                                </span>
                                <span >{leg.LegStatus}</span>
                              </div>
                            </Grid>
                            <Grid item zeroMinWidth>
                              <IconButton edge={'end'} onClick={(event) => handleMoreClick(event, leg.ID)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                open={moreOptionsOpen}
                                onClose={handleMoreClose}
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                              >
                                <MenuItem onClick={handleOptionClick}>
                                  Add Event
                                </MenuItem>
                              </Menu>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor: "#F7F6F3", paddingBottom: '0px'}}>                          
                          <ItineraryEventList events={leg.IntermodalEvents} updateEvent={updateEvent} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <br></br>
      <IntermodalEventCreateDialog
        intermodalLegID={addEventLegID}
        isOpen={isEventCreateDialogOpen}
        onClose={closeEventCreateDialog}
      />
    </Paper>
  )
}

export default ItineraryLegList