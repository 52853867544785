import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as ActiveDateSvg } from '../assets/svg-icons/active-date@1x.svg';

const IconActiveDate = (props) => {
  const {
    size,
  } = props;
  return (
    <SvgIcon fontSize={size ? size : "small"}>
      <ActiveDateSvg />
    </SvgIcon>    
  )
}
export default IconActiveDate;