import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocationField from '../location/LocationField';
import { createChassisPickupReturn } from './IntermodalEventService';

const IntermodalEventListTableLegChassisReturnField = (props) => {
  const {
    leg,
    getEvents,
    setInProgress
  } = props;

  const [eventFormData, setEventFormData] = useState({
    legID: leg.ID,
    eventType: "Chassis Return",
    status: "Pending",
    locationTemplateID: null,
    appointmentDateStart: null,
    appointmentDateEnd: null,
    actualArrivalDate: null,
    actualDepartDate: null
  });

  const updateEventFormData = e => {
    const { name, value, nameData, valueData } = e.target;
    eventFormData[name] = value;
    eventFormData[nameData] = valueData;
    onAutoCreate();
  };

  const onAutoCreate = () => {
    setInProgress(true);
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    var insertingAfterEventID = null;
    if (leg.IntermodalEvents.length > 0) { 
      insertingAfterEventID = leg.IntermodalEvents[leg.IntermodalEvents.length - 1].ID;
    }
    const result = await createChassisPickupReturn(eventFormData, insertingAfterEventID, false);
    setInProgress(false);
    if (result.error) {
        return;
    }
    setInProgress(false);
    getEvents();
    setEventFormData(c => ({ ...c, locationTemplateID: null }));
  };

  return (
    <>
      <LocationField
        name="locationTemplateID"
        label="Chassis Return"
        value={eventFormData.locationTemplateID}
        onChange={e => updateEventFormData(e)}
        isRequired={false}
      />
    </>
  );

}

IntermodalEventListTableLegChassisReturnField.propTypes = {
  leg: PropTypes.array.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTableLegChassisReturnField;