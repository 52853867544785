import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  }
}));

const IntermodalEventListTableEventAddButton = (props) => {
  const {
    legID,
    eventID,
    setCurrentLegID,
    setCurrentEventID,
    setIsAddEventAbove,
    setEventCreateDialogOpen,
    eventTypeBelow,
    setCurrentEventType,
    eventTypeAbove,
    setEventIndexForNewEvent,
    eventIndex,
    initialEventType,
    setInitialEventType
  } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setIsMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpen(false);
    }
  }


  const openEventBelowCreateDialog = () => {
    setIsMenuOpen(false);
    setCurrentLegID(legID);
    setCurrentEventID(eventID);
    setIsAddEventAbove(false);
    setEventIndexForNewEvent(eventIndex + 1);
    setEventCreateDialogOpen(true);
    setCurrentEventType(eventTypeBelow);
    setInitialEventType(initialEventType);
  };

  const openEventAboveCreateDialog = () => {
    setIsMenuOpen(false);
    setCurrentLegID(legID);
    setCurrentEventID(eventID);
    setIsAddEventAbove(true);
    setEventIndexForNewEvent(eventIndex - 1);
    setEventCreateDialogOpen(true);
    setCurrentEventType(eventTypeAbove);
    setInitialEventType(initialEventType);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        className={classes.iconText}
      >
        <AddIcon />&nbsp;<span>ADD</span>
      </Button>
      <Popper open={isMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>                
                  <MenuItem onClick={openEventAboveCreateDialog}>Before</MenuItem>
                  <MenuItem onClick={openEventBelowCreateDialog}>After</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

IntermodalEventListTableEventAddButton.propTypes = {
  legID: PropTypes.number.isRequired,
  eventID: PropTypes.number.isRequired,
  setCurrentLegID: PropTypes.func.isRequired,
  setCurrentEventID: PropTypes.func.isRequired,
  setEventCreateDialogOpen: PropTypes.func.isRequired
};

export default IntermodalEventListTableEventAddButton;