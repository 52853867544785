import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as IconOrganization } from '../assets/svg-icons/organization@1x.svg';

const IconInternalName = () => {
  return (
    <SvgIcon fontSize="small">
      <IconOrganization />
    </SvgIcon>
  )
}
export default IconInternalName;