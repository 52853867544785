import HttpService from '../http-service';
import HTTPConfiguration from '../http-configuration';

const token = localStorage.getItem('token');
const uri = 'IntermodalInvoice/' + token + '/';
const config = new HTTPConfiguration();

export async function select(id) {
  const params = {
    id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Select', null, params);
  const response = result.response;
  return response;
};


export async function deselect(id) {
  const params = {
    id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Deselect', null, params);
  const response = result.response;
  return response;
};

export async function selectBatch(id) {
  const params = { id };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'SelectBatch', null, params);
  const response = result.response;
  return response;
};

export async function deselectBatch(id) {
  const params = { id };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'DeselectBatch', null, params);
  const response = result.response;
  return response;
};


export async function billingOnHold(id) {
  const params = {
    id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'BillingOnHold', null, params);
  const response = result.response;
  return response;
};

export async function readyForBilling(id) {
  const params = {
    id
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'ReadyForBilling', null, params);
  const response = result.response;
  return response;
};

export async function transmit() {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Transmit');
  const response = result;
  return response;
};

export async function clear() {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Clear');
  const response = result.response;
  return response;
};

export async function getBatchInvoiceStatus(id) {
  const params = { id };
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + 'GetBatchInvoiceStatus', params);
  return result.data.Status;
};

export async function printInvoice(id) {
  return config.API_URL + uri + id + '/Invoice';
};

export async function printBatchInvoice(id) {
  return config.API_URL + uri + id + '/BatchInvoice';
};

export async function printBOL(orderID, legID) {
  return config.API_URL + uri + orderID + '/' + legID + '/BOL';
};