import LocationCreateButton from './LocationCreateButton';
import LocationListTable from './LocationListTable';
import Container from '@material-ui/core/Container';

import CorePageHeader from '../core/CorePageHeader';

const LocationList = () => {

  return (
    <div>
      <CorePageHeader
        title="Locations"
        createButton={
          <LocationCreateButton/>
        }/>
      <Container maxWidth="false" className="mt-4">
        <LocationListTable />
      </Container>
    </div>
  );

}

export default LocationList;