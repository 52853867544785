import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import './Navigation.css';

const useStyles = makeStyles(() => ({
  icon: {
    opacity: '35%',
    filter: 'invert(1)',
  },
  listItemTypography: {
    color: "white",
  }
}));

const NavigationNestedDrawer = (props) => {
  const classes = useStyles();
  const { icon, title, children} = props;

  return (
    <>
      <ListItem
        key={title}
      >
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
        <ListItemText 
          disableTypography
          primary={
            <Typography className={classes.listItemTypography}>
              {title}
            </Typography>
          }
        />
      </ListItem>
      <div className="nestedItems">
        {children}
      </div>
    </>
  );

}

NavigationNestedDrawer.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

export default NavigationNestedDrawer;