import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EventAvailable from '@material-ui/icons/EventAvailable';
import { green } from '@material-ui/core/colors';
import IncomingLoadListAcceptDialog from './IncomingLoadListAcceptDialog';
import IncomingLoadAcceptConfirmationDialog from './IncomingLoadAcceptConfirmationDialog';

const IncomingLoadListAcceptButton = (props) => {
  const {
    shipperPortalOrderAssignmentID,
    acceptFunction,
    getList,
    reference
  } = props;

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isAcceptConfirmationDialogOpen, setIsAcceptConfirmationDialogOpen] = useState(false);
  const [createdOrder, setCreatedOrder] = useState({});

  const openAcceptModal = () => {
    setIsAcceptModalOpen(true);
  };

  const closeAcceptModal = () => {
    setIsAcceptModalOpen(false);
  };

  const openAcceptConfirmationDialog = (order) => {
    setCreatedOrder(order); 
    setIsAcceptConfirmationDialogOpen(true);
  };

  const closeAcceptConfirmationDialog = () => { 
    setIsAcceptConfirmationDialogOpen(false);
    getList();
  };

  return (
    <>
      <IconButton title="Accept Order" onClick={openAcceptModal}>
        <EventAvailable style={{ color: green[500] }}/>
      </IconButton>
      <IncomingLoadListAcceptDialog
        shipperPortalOrderAssignmentID={shipperPortalOrderAssignmentID}
        reference={reference}
        acceptFunction={acceptFunction}
        isOpen={isAcceptModalOpen}
        onClose={closeAcceptModal}
        openConfirmationDialog={openAcceptConfirmationDialog}
        setCreatedOrder={setCreatedOrder}
      />
      <IncomingLoadAcceptConfirmationDialog
        isOpen={isAcceptConfirmationDialogOpen}
        onClose={closeAcceptConfirmationDialog}
        createdOrder={createdOrder}
      />
    </>
  );
}

IncomingLoadListAcceptButton.propTypes = {
  shipperPortalOrderAssignmentID: PropTypes.number.isRequired,
  acceptFunction: PropTypes.func.isRequired,
};

export default IncomingLoadListAcceptButton;