import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";

import CoreListColumnDeleteButton from '../core/CoreListColumnDeleteButton';
import CoreAddButton from '../core/CoreAddButton';
import PointToPointTemplateColumnEditButton from '../point_to_point/PointToPointTemplateColumnEditButton';
import { Delete as DeleteRateTemplate } from '../point_to_point/PointToPointTemplateService';

const ConfigurationViewPointToPointTemplatesSectionListTable = (props) => { 
  const {
    rateTemplates,
    getRateTemplates,
    setRateTemplates,
    openRateTemplateCreateDialog
  } = props;

  let columns = [ 
    {
      field: 'Name',
      headerName: 'Description',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'IsActive',
      headerName: 'Active',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => { 
        const isActive = params.getValue("IsActive");
        return ( 
            <span>{isActive ? "Yes" : "No"}</span>
        )
      }
    },
    {
      field: 'Controls',
      headerName:<CoreAddButton handleOpenCreate={openRateTemplateCreateDialog}></CoreAddButton>,
      headerAlign:'right',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const rateTemplateID = params.getValue("ID");
        const showControls = params.getValue("ShowControls") || false;
        return (
          <div>
            {showControls &&
              <PointToPointTemplateColumnEditButton
                rateTemplateID={rateTemplateID}
                getRateTemplates={getRateTemplates}
              />
            }
            {showControls &&
              <CoreListColumnDeleteButton
                reference={`Point-to-Point Rate Template: ${params.getValue("Name")}`}
                deleteID={rateTemplateID}
                deleteFunction={DeleteRateTemplate}
                getList={getRateTemplates}
              />
            }
          </div>
        )      
      }
    }
  ];

  const [params, setParams] = useState({
    amount: 10
  });

  function changeRowsPerPage(amount) {
    params.amount = amount;
    setParams(params);
  };

  const onTemplateRowEnter = (params) => {
    for (const item of rateTemplates) {
      if (item.ID === params.row.ID) {
        item.ShowControls = true;
      }
    }
    setRateTemplates([...rateTemplates]);
  };

  const onTemplateRowLeave = (params) => {
    for (const item of rateTemplates) {
      if (item.ID === params.row.ID) {
        item.ShowControls = false;
      }
    }
    setRateTemplates([...rateTemplates]);
  };  

  return ( 
    <Container style={{ padding: '0', height: ''  }} maxWidth="xl" className="mt-1" component={Paper}>
      <DataGrid
        getRowId={(row) => row.ID} 
        autoHeight={true} 
        rows={rateTemplates} 
        columns={columns} 
        pageSize={params.amount} 
        onPageSizeChange={(e) => {changeRowsPerPage(e.pageSize)}}
        rowsPerPageOptions={[10, 15, 25]}  
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        onRowEnter={e => onTemplateRowEnter(e)}
        onRowLeave={e => onTemplateRowLeave(e)}
      />
    </Container>
  )
};

export default ConfigurationViewPointToPointTemplatesSectionListTable;