import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockIconOpen from '@material-ui/icons/LockOpen';

const CorePasswordField = (props) => {
  const [touchedByUser, setTouchedByUser] = useState(false);
  const { label, value, showIcon, validationError, ...rest } = props;

  const buildEndAdornment = () => {
    if (showIcon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled={true}>
            <LockIconOpen />
          </IconButton>
        </InputAdornment>);
    }
    return <></>;
  };

  return (
    <FormControl fullWidth>
      <InputLabel required={true} error={touchedByUser && validationError}>
        {label}
      </InputLabel>
      <Input
        type="password"
        value={value}
        onBlur={() => setTouchedByUser(true)}
        endAdornment={buildEndAdornment()}
        {...rest}
      />
    </FormControl>
  );
}

CorePasswordField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  validationError: PropTypes.bool,
  showIcon: PropTypes.bool
};

export default CorePasswordField;
