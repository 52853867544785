import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoreNoRecordsFound from '../core/CoreNoRecordsFound';
import { getCustomersForDashboard, getCustomersLoadsForDashboard, getDriversForDashboard, getDriversLoadsForDashboard, getDriversLateApptForDashboard } from './DashboardService';
import FormattingFunctions from '../helpers/formattingFunctions';
import { Doughnut } from 'react-chartjs-2';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: '24px 24px 10px 24px',
    textAlign: 'left',
    minHeight: '278px'
  },
  lightDivider: {
    backgroundColor: '#EEEBE2'
  },
  topListLi: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  relative: {
    position: 'relative',
  },
  absoluteCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  flag: {
    display: 'inline-block',
    width: '14px',
    height: '14px',
    lineHeight: '14px',
    marginRight: '6px',
    borderRadius: '7px'
  },
  groupButtonsAlign: {
    textAlignLast: 'right'
  },
}));

const DashboardTopFive = (props) => {
  const { isTopFiveCustomers } = props;
  const [count, setCount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [topFive, setTopFive] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentButtonClicked, setCurrentButtonClicked] = useState(0);
  const [chartData, setChartData] = useState ({
    datasets: [{
      label: null,
      data: null,
      backgroundColor: [
        '#0B79D0',
        '#58C6FF',
        '#72E0FF',
        '#A2C5E2',
        '#BBDEFB'
      ],
      hoverOffset: 4,
    }]
  });
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);
  const [optionSelected, setOptionSelected] = useState(isTopFiveCustomers ? "Total Revenue" : "Total Expenses");
  const [summaryLengthSelected, setSummaryLengthSelected] = useState('1M');

  useEffect(() => {
    async function getCustomers() { 
      var result;
      if (optionSelected === "Total Loads") {
        result = await getCustomersLoadsForDashboard(summaryLengthSelected);
      } else {
        result = await getCustomersForDashboard(summaryLengthSelected);
      }
      setOptions(['Total Revenue', 'Total Loads']);
      if(result.length > 0) {
        setTopFive(result);
        setCount(result.length);
        chartData.datasets[0].data = result.map((row) => row.Amount);
        chartData.datasets[0].label = result.map((row) => row.Name);
        setTotalAmount(result.map((row) => row.Amount).reduce((partialSum, a) => partialSum + a, 0));
        setChartData(chartData);        
        setLoading(false);
      } else {
        setCount(0)
        setLoading(false);
      }
    }
    async function getDrivers() { 
      var result;
      if (optionSelected === "Moves Completed") {
        result = await getDriversLoadsForDashboard(summaryLengthSelected);
      } else if (optionSelected === "Late Appointments") {
        result = await getDriversLateApptForDashboard(summaryLengthSelected);
      } else {
        result = await getDriversForDashboard(summaryLengthSelected);
      }
      setOptions(['Total Expenses', 'Moves Completed', 'Late Appointments']);
      if(result.length > 0) {
        setTopFive(result);
        setCount(result.length);
        chartData.datasets[0].data = result.map((row) => row.Amount);
        chartData.datasets[0].label = result.map((row) => row.Name);
        setTotalAmount(result.map((row) => row.Amount).reduce((partialSum, a) => partialSum + a, 0));
        setChartData(chartData);        
        setLoading(false);
      } else {
        setCount(0);
        setLoading(false);
      }
    }
    if(isTopFiveCustomers) {
      getCustomers();
    } else {
      getDrivers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopFiveCustomers, currentButtonClicked, optionSelected]);

  const classes = useStyles();

  const title = isTopFiveCustomers ? 'Customer Summary' : 'Driver Summary';

  const updateCurrentButton = async(dayClicked) => {
    if (currentButtonClicked !== dayClicked) {
      setLoading(true);
      switch (dayClicked) {
        case 0:
          setSummaryLengthSelected('1M');
          break;
        case 1:
          setSummaryLengthSelected('1W');          
          break;
        case 2:
          setSummaryLengthSelected('1D');        
          break;
        default:
            break;
      }
      setCurrentButtonClicked(dayClicked);
    }
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {    
    if (optionSelected !== event.currentTarget.innerText){
      setLoading(true);
      setOptionSelected(event.currentTarget.innerText);
      setAnchorEl(null);
    }
  };

  return (
    <Paper className={classes.paper} elevation={1} square={true} align="center">
      <Grid container className="mb-2">
        <Grid item xs={6}>
          <Typography variant="subtitle1" style={{display:'flex', justifyContent:'flex-start', fontWeight:'bold'}}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.groupButtonsAlign}>          
          <ButtonGroup style={{maxWidth: '235px', maxHeight:'30px'}} color='primary' aria-label="outlined button group">
            {currentButtonClicked !== 0 && <Tooltip title="This Month" placement="top"><Button onClick={() => updateCurrentButton(0)} variant="outlined" style={{fontSize:"smaller"}}>1M</Button></Tooltip>}
            {currentButtonClicked === 0 && <Tooltip title="This Month" placement="top"><Button onClick={() => updateCurrentButton(0)} variant="contained" style={{fontSize:"smaller"}}>1M</Button></Tooltip>}
            {currentButtonClicked !== 1 && <Tooltip title="This Week" placement="top"><Button onClick={() => updateCurrentButton(1)} variant="outlined" style={{fontSize:"smaller"}}>1W</Button></Tooltip>}
            {currentButtonClicked === 1 && <Tooltip title="This Week" placement="top"><Button onClick={() => updateCurrentButton(1)} variant="contained" style={{fontSize:"smaller"}}>1W</Button></Tooltip>}
            {currentButtonClicked !== 2 && <Tooltip title="Today" placement="top"><Button onClick={() => updateCurrentButton(2)} variant="outlined" style={{fontSize:"smaller"}}>1D</Button></Tooltip>}
            {currentButtonClicked === 2 && <Tooltip title="Today" placement="top"><Button onClick={() => updateCurrentButton(2)} variant="contained" style={{fontSize:"smaller"}}>1D</Button></Tooltip>}
          </ButtonGroup>
          <IconButton edge={'end'} onClick={handleMoreClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={moreOptionsOpen}
            onClose={handleMoreClose}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={handleOptionClick} selected={option === optionSelected}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      {loading && (
        <div align="center">
          <CircularProgress size={60} style={{marginTop: '50px'}} />
        </div>
      )}
      { count > 0 && !loading ? (
        <div>
          <Grid container className={"mb-1"} style={{'minHeight': '197px'}}>
            <Grid className={classes.relative} item xs={6}>   
              <div className={classes.absoluteCenter}>
                <p>
                  {(optionSelected === "Total Revenue" || optionSelected === "Total Expenses") && <span style={{'fontWeight': 'bold', 'fontSize': '18px'}}>{FormattingFunctions.formatCurrency(totalAmount)}</span>}
                  {(optionSelected !== "Total Revenue" && optionSelected !== "Total Expenses") && <span style={{'fontWeight': 'bold', 'fontSize': '18px'}}>{totalAmount}</span>}
                  <br/>
                  <span style={{'color': 'grey', 'fontSize': '14px'}}>{optionSelected}</span>
                </p>                
              </div>           
              <Doughnut      
                data={chartData}
                options={{
                  cutout: 65,
                  maintainAspectRatio: false,
                  plugins:{                    
                    tooltip: {
                      callbacks: {        
                        title: function() {},           
                        label: (context) => {
                          if (optionSelected === "Total Revenue" || optionSelected === "Total Expenses") {
                            return ` $${context.formattedValue}`;
                          } else {
                            return ` ${context.formattedValue}`;
                          }
                        },
                        afterLabel: (context) => {
                          return ` ${context.dataset.label[context.dataIndex]}`
                        },      
                      },
                      bodyColor: 'black',
                      backgroundColor: 'white',                             
                      borderWidth: '1',
                      borderColor: 'black',    
                      xAlign: 'center',                                                   
                    },
                    legend: {
                    display: false,              
                    }              
                  }            
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <List dense={true}>
                { topFive.map((item, index) =>
                  <ListItem className={classes.topListLi} key={`customer-li-${index}`}>                
                    <ListItemText
                      primary={<div><span className={classes.flag} style={{backgroundColor: chartData.datasets[0].backgroundColor[index]}}/>{item.Name}</div>}
                    />
                    <ListItemText
                      style={{display:'flex', justifyContent:'flex-end'}}
                      primary={FormattingFunctions.getPercentage(item.Amount, totalAmount)}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </div>
      ) : null }
      { count === 0 && !loading ? (
        <CoreNoRecordsFound/>
      ) : null }
    </Paper>
  );

}

DashboardTopFive.propTypes = {
  isTopFiveCustomers: PropTypes.bool,
};

DashboardTopFive.defaultProps = {
  isTopFiveCustomers: true,
};

export default DashboardTopFive;
