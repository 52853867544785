import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from "@material-ui/data-grid";

const IntermodalPaymentConfirmDialog = (props) => {
  const {
    isOpen,
    onConfirm,
    onClose,
    bills
  } = props;

  const [formattedBills, setFormattedBills] = useState([]);
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => { 
    if (isOpen) { 
      formatBills(bills);
    }
  }, [isOpen]);
  
  function formatBills(bills) { 
    var sortedBills = bills.sort((a, b) => (a.DriverName > b.DriverName) ? 1 : (a.DriverName < b.DriverName) ? -1 : 0);
    var b = [];
    var numOfExpenses = 0;
    var payeeTotal = 0;
    var totalNumOfExpenses = 0;
    var payeeGrandTotal = 0;
    var row = {};
    for (var i = 0; i < sortedBills.length; i++) {
      numOfExpenses = numOfExpenses + 1;
      totalNumOfExpenses = totalNumOfExpenses + 1;
      payeeTotal = payeeTotal + sortedBills[i].TotalAmount; 
      payeeGrandTotal = payeeGrandTotal + sortedBills[i].TotalAmount;

      if (i === sortedBills.length - 1)  {
        row = {ID: sortedBills[i].BillID, Driver: sortedBills[i].DriverName, ExpenseCount: numOfExpenses, Total: payeeTotal}
        b.push(row);
        row = {ID: 0, Driver: 'TOTAL', ExpenseCount: totalNumOfExpenses, Total: payeeGrandTotal}
        b.push(row);
        break;
      }

      if (sortedBills[i].DriverName !== sortedBills[i + 1].DriverName) { 
        row = {ID: sortedBills[i].BillID, Driver: sortedBills[i].DriverName, ExpenseCount: numOfExpenses, Total: payeeTotal}
        b.push(row);
        numOfExpenses = 0;
        payeeTotal = 0;
      }
    }
    setFormattedBills(b);
    setInProgress(false);
  };

  let columns = [
    {
      field: 'ID',
      headerName: 'BillID',
      align: 'left',
      sortable: false,
      hide: true
    },
    {
      field: 'Driver',
      headerName: 'Driver',
      align: 'left',
      flex: 1,
      sortable: false,
    },
    {
      field: 'ExpenseCount',
      headerName: 'Expense Count',
      align: 'left',
      flex: 0.75,
      sortable: false,
    },
    {
      field: 'Total',
      headerName: 'Total Approved',
      align: 'left',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => { 
        const totalAmount = params.getValue("Total");
        return (
          <div>$ {totalAmount.toFixed(2)}</div>
        )
      }
    }
  ];

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <br></br>
        <DialogContentText>
          You have selected Expenses for the following drivers to be marked as Completed:
        </DialogContentText>
        {!inProgress && <DataGrid
          getRowId={(row) => row.ID} 
          hideFooter={true} 
          autoHeight={true} 
          rows={formattedBills} 
          columns={columns} 
          pageSize={100} 
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
        />}
        <br></br>
        <DialogContentText>
          By marking these Expenses as Completed: <br/>
          - All Expenses included will become unavailable to edit or delete <br/>
          - The Pay Statements will be generated as PDFs within a .ZIP file and made available to download by your browser <br/><br/>
          If you wish to proceed, select "Mark As Completed" below.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Mark as Completed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IntermodalPaymentConfirmDialog;