import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BillListTable = (props) => {
  const {
    bills,
  } = props;

  let history = useHistory();
  const [openSuccessCopy, setOpenSuccessCopy] = useState(false);
  const [total, setTotal] = useState(0);

  const openRecord = (id) => {
    history.push('/spark/bill/view/' + id);
  };

  useEffect(() => { 
    var totalAmount = 0;
    bills.forEach(p => {
      totalAmount += parseFloat(p.Total);
    });
    setTotal(totalAmount.toFixed(2));
  }, [bills]);

  let columns = [
    {
      field: 'Controls',
      headerName: ' ',
      align: 'center',
      flex: 0.25,
      renderCell: (params) => {  
        const name = params.getValue("BillID");
        return (
          <div>
            <IconButton title="Open Full Bill" onClick={() => openRecord(name)}>
              <OpenInBrowserIcon color='primary'/>
            </IconButton>            
          </div>
        )
      }
    },
    {
      field: 'BillNumber',
      headerName: 'Expense #',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },
    {
      field: 'PayeeName',
      headerName: 'Payee Name',
      align: 'left',
      flex: 1,
      sortable: 'false',
      renderCell: (params) => {
        const name = params.getValue("PayToPersonnelID") ? params.getValue("PersonnelPayee") : params.getValue("CarrierPayee");
        return (
          <div>
            {name.InternalName}
          </div>
        )
      }
    },
    {
      field: 'LegIndex',
      headerName: 'Leg',
      align: 'left',
      flex: 1,
      sortable: 'false',
      renderCell: (params) => { 
        const legIndex = params.getValue("IntermodalLeg") ? params.getValue("IntermodalLeg").LegIndex + 1 : "";
        return ( 
          <div>{legIndex}</div>
        )
      }
    },
    {
      field: 'BillDate',
      headerName: 'Date',
      align: 'left',
      flex: 1,
      sortable: 'false'
    },
    { 
      field: 'Total', 
      headerName: bills.length === 0 ? 'Total' : 'Total ($' + total + ')',
      align: 'left', 
      flex: 1, 
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("Total");
        return (
          <div>{"$"} {name.toFixed(2)}</div>
        )
      }
    },
  ];

  const handleSnackBarClose = () => {
    setOpenSuccessCopy(false);
  };

  return (
    <Container style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1 munaGrid" component={Paper}>
      <DataGrid
        getRowId={(row) => row.BillID} 
        hideFooter={true} 
        autoHeight={true} 
        rows={bills} 
        columns={columns} 
        pageSize={100} 
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
      <Snackbar open={openSuccessCopy} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          Content copied!
        </Alert>
      </Snackbar>
    </Container>
  );
}

BillListTable.propTypes = {
  bills: PropTypes.array.isRequired,
  getBills: PropTypes.func.isRequired
};

export default BillListTable;