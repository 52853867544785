import React, { useEffect, useState, useRef } from "react";
import { Container, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  AppointmentsTable,
  AppointmentsTableBulkActionMenu,
  AppointmentTableRowActions,
} from "@envasetechnologies/precheck-components";

import { GetByGateTransactionID } from "../intermodal_order_appointments/IntermodalOrderAppointmentsService";
import {getGateTransactions} from "./MyAppointmentService";
import CorePageHeader from "../core/CorePageHeader";
import MyAppointmentViewDetails from "./MyAppointmentViewDetails";
import MyAppointmentEditDetails from "./MyAppointmentEditDetails";
import OrderAppointmentCancelDialog from "../orderAppointment/OrderAppointmentCancelDialog";
import MyAppointmentCreateTruckVisit from "./MyAppointmentCreateTruckVisit";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getTIN } from "../tax/TaxService";
import { getToken } from "../order/ReservationService";

function MyAppointmentsView() {
  const [motorCarrierTIN, setMotorCarrierTIN] = useState('');
  const [preCheckToken, setPreCheckToken] = useState('');
  const [isViewApptOpen, setIsViewApptOpen] = useState(false);
  const [viewAppt, setViewAppt] = useState({});
  const [isEditAppointmentOpen, setIsEditAppointmentOpen] = useState(false);
  const [editAppt, setEditAppt] = useState({});
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);
  const [cancelAppt, setCancelAppt] = useState({});
  const [isCreateTruckVisitAppointmentOpen, setIsCreateTruckVisitAppointmentOpen] = useState(false);
  const [createTVAAppt, setCreateTVAAppt] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchOptions, setSearchOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 50,
    sortBy: "",
    sortByDesc: true,
    takeAll: false,
  });
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    async function getTaxIdentificationNumber() {
      const response = await getTIN();
      if (response) {
        setMotorCarrierTIN(response);
      }
    }

    async function getPreCheckToken() {
      const response = await getToken();
      if (response) {
        setPreCheckToken(response);
      }
    }

    getPreCheckToken().then(() => {
        getTaxIdentificationNumber();
    });
}, []);

  const getData = async () => {
    setIsLoading(true);
    const payload = {
      ...searchOptions,
      pagination: pagination
    };
    const result = await getGateTransactions(payload);
    setAppointments(result?.response?.data?.items || []);
    setTotalCount(result?.response?.data?.totalCount || 0);
    setTotalPages(result?.response?.data?.totalPages || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getData();
  }, [searchOptions, pagination]);


  const settingPagination = (e) => {
    for (let key in e) {
      pagination[key] = e[key];
      if (key === 'pageSize' || key === 'sortBy') {
        pagination.pageNumber = 0;
      }
    }
    setPagination({ ...pagination });
  };

  const settingSearchOptions = (e) => {
    for(let key in e) {
      if(e[key] === '' || e[key].length === 0) {
        if(searchOptions[key]) {
          delete searchOptions[key];
        }
        continue;
      } else {
        searchOptions[key] = e[key];
      }
    }
    setSearchOptions({ ...searchOptions });
    pagination.pageNumber = 0;
    setPagination({ ...pagination });
  };

  const handleViewDetailsClicked = (item) => {
    setViewAppt(item);
    setIsViewApptOpen(!isViewApptOpen);
  };

  const handleEditAppointmentClicked = (item) => {
    setEditAppt(item);
    setIsEditAppointmentOpen(!isEditAppointmentOpen);
  };
  
  const handleCancelAppointmentClicked = async (item) => {
    try {
      let appointment = await GetByGateTransactionID(item.gateTransactionId);
      setCancelAppt(appointment);
      setIsCancelAppointmentModalOpen(true);
    }
    catch (error) {
      console.error("Error fetching appointment:", error);
    }
  };

  const closeCancelAppointmentModal = () => {
    setIsCancelAppointmentModalOpen(false);
  };

  const onAppointmentCancel = () => { 
    setIsCancelAppointmentModalOpen(false);
    getData();
  };

  const handleTruckVisitAppointmentClicked = (item) => {
    if (item) {
      setCreateTVAAppt([...createTVAAppt, item]);
    } else {
      setCreateTVAAppt([]);
    }
    setIsCreateTruckVisitAppointmentOpen(!isCreateTruckVisitAppointmentOpen);
  };

  const handleMultipleTruckVisitAppointments = (items) => {
    if (items) {
      setCreateTVAAppt([...createTVAAppt, ...items]);
    } else {
      setCreateTVAAppt([]);
    }
    setIsCreateTruckVisitAppointmentOpen(!isCreateTruckVisitAppointmentOpen);
  };


  const renderActionMenu = (item) => {
    return (
      <AppointmentTableRowActions id={item.gateTransactionId}>
        <MenuItem
          onClick={() => handleViewDetailsClicked(item)}
          sx={{ textTransform: "capitalize" }}
          dense
        >
          <ListItemIcon>
            <EventNoteIcon sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <ListItemText>View Details</ListItemText>
        </MenuItem>
        {/* <MenuItem
          disabled={item.status === "Cancelled"}
          onClick={() => handleEditAppointmentClicked(item)}
          sx={{ textTransform: "capitalize" }}
          dense
        >
          <ListItemIcon>
            <EditIcon sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <ListItemText>Edit Appointment (Needs more work)</ListItemText>
        </MenuItem> */}
        <MenuItem
          disabled={item.status === "Cancelled" || item.appointmentNumber === null}
          onClick={() => handleCancelAppointmentClicked(item)}
          sx={{ textTransform: "capitalize" }}
          dense
        >
          <ListItemIcon>
            <CancelIcon sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <ListItemText>Cancel Appointment</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleTruckVisitAppointmentClicked(item)}
          sx={{ textTransform: "capitalize" }}
          dense
        >
          <ListItemIcon>
            <AddIcon sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <ListItemText>Create Truck Visit Appointment</ListItemText>
        </MenuItem>
      </AppointmentTableRowActions>
    );
  };

  const renderBulkActionMenu = (items) => {
    return (
      <AppointmentsTableBulkActionMenu
        id="appt-table-bulk-action-menu"
        disabled={items.length === 0}
      >
        <MenuItem
          onClick={() => handleMultipleTruckVisitAppointments(items)}
          sx={{ textTransform: "capitalize" }}
          dense
        >
          <ListItemIcon>
            <AddIcon sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <ListItemText>Create Truck Visit Appointments</ListItemText>
        </MenuItem>
      </AppointmentsTableBulkActionMenu>
    )
  };

  return (
    <>
    <CorePageHeader
      title="Appointments"
      breadCrumb="Home / ... / Appointments"
    />
    <Container maxWidth="false" className="mt-4">
      {!motorCarrierTIN ? <LinearProgress /> :
        <AppointmentsTable
        appointments={appointments}
        isLoading={isLoading}
        renderActionMenu={renderActionMenu}
        renderBulkActionMenu={renderBulkActionMenu}
        paginationProps={{
          pagination: pagination,
          totalCount: totalCount,
          totalPages: totalPages,
          setPagination: settingPagination,
        }}
        filterProps={{
          handleFilters: (options) => settingSearchOptions(options),
          defaultValues: {
            status: ["Incomplete"]
          },
        }}
        motorCarrierTIN={motorCarrierTIN}
        accessToken={preCheckToken}
      />}
    </Container>
    {isViewApptOpen && 
    <MyAppointmentViewDetails
      isViewApptOpen={isViewApptOpen}
      isLoading={isLoading}
      viewAppt={viewAppt}
      handleViewDetailsClicked={handleViewDetailsClicked}
    />
    }
    {isEditAppointmentOpen &&
    <MyAppointmentEditDetails
      isOpen={isEditAppointmentOpen}
      handleEditAppointmentClicked={handleEditAppointmentClicked}
      editAppt={editAppt}
    />
    }
    {isCancelAppointmentModalOpen &&
      <OrderAppointmentCancelDialog
        isOpen={isCancelAppointmentModalOpen}
        onClose={closeCancelAppointmentModal}
        appointment={cancelAppt}
        onAppointmentCancel={onAppointmentCancel}
      />
    }
    {isCreateTruckVisitAppointmentOpen &&
      <MyAppointmentCreateTruckVisit
        isOpen={isCreateTruckVisitAppointmentOpen}
        onClose={handleTruckVisitAppointmentClicked}
        appointments={createTVAAppt}
      />
    }
    </>
  );
}

export default MyAppointmentsView;