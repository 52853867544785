import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as MissingPaperSvg } from '../assets/svg-icons/missing-paper@1x.svg'

const IconMissingPaper = () => {
  return (
    <SvgIcon fontSize="small">
      <MissingPaperSvg />
    </SvgIcon>
  )
}

export default IconMissingPaper;