import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CorePageHeader from '../core/CorePageHeader';
import { useHistory, useParams } from 'react-router-dom';
import Validation from '../validation';
import CoreExitButton from '../core/CoreExitButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formatDateTime } from '../globalFunction';

import { getForUpdate, update as orderUpdate, isAbleToEditOrderDestination, isAbleToEditOrderOrigin } from './OrderService';
import { get as getLocation, setProvider } from '../location/LocationService';

import OrderEditContent from './OrderEditContent';

const OrderEdit = () => {
  const { id } = useParams();
  let history = useHistory();
  const onCloseRoute = history.location.state?.onCloseRoute ? history.location.state?.onCloseRoute : '/spark/order';

  const [orderFormData, setOrderFormData] = useState({});
  const [orderFormDataLoaded, setOrderFormDataLoaded] = useState(false);
  const [originLocationLoaded, setOriginLocationLoaded] = useState(false);
  const [destinationLocationLoaded, setDestinationLocationLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [orderFormValidation, setOrderFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [initialProviderID, setInitialProviderID] = useState(null);

  useEffect(() => {
    setOrderFormValidation(buildValidation(orderFormData));
  }, [orderFormData]);

  useEffect(() => {
    getForUpdate(id)
      .then(function (response) {
        setOrderFormData(response);
        getLocations(response);   
        getIsAbleToEditOrderLocations(id);      
        setOrderFormDataLoaded(true);
        setInProgress(false);
      });
  }, [id]);
  
  function getLocations(orderResponse) {
    getLocation(orderResponse.OriginID)
      .then(function (response) {
        setOrderFormData(c => ({ ...c, OriginAddress: response.Address }));
        setOrderFormData(c => ({ ...c, OriginCity: response.City }));
        setOrderFormData(c => ({ ...c, OriginState: response.Province }));
        setOrderFormData(c => ({ ...c, OriginZip: response.PostalCode }));
        setOrderFormData(c => ({ ...c, OriginCountry: response.Country }));
        setOrderFormData(c => ({ ...c, OriginLocationName: response.InternalName }));
        setOrderFormData(c => ({ ...c, EnvaseTraceProviderID: response.EnvaseTraceProviderID}))
        setInitialProviderID(response.EnvaseTraceProviderID);
        setOriginLocationLoaded(true);
      });
    getLocation(orderResponse.DestinationID)
      .then(function (response) {
        setOrderFormData(c => ({ ...c, DestinationAddress: response.Address }));
        setOrderFormData(c => ({ ...c, DestinationCity: response.City }));
        setOrderFormData(c => ({ ...c, DestinationState: response.Province }));
        setOrderFormData(c => ({ ...c, DestinationZip: response.PostalCode }));
        setOrderFormData(c => ({ ...c, DestinationCountry: response.Country }));
        setOrderFormData(c => ({ ...c, DestinationLocationName: response.InternalName }));
        setDestinationLocationLoaded(true);
      });
  };

  function getIsAbleToEditOrderLocations(id) {
    isAbleToEditOrderOrigin(id)
      .then(function(response) {
        setOrderFormData(c => ({ ...c, IsAbleToEditOrderOrigin: response }));
      });
    isAbleToEditOrderDestination(id)
      .then(function(response) {
        setOrderFormData(c => ({ ...c, IsAbleToEditOrderDestination: response }));      
      });
  };

  const updateOrderFormData = e => {
    const {
      name,
      value,
      nameData,
      valueData,
      addressData,
      addressValue,
      cityData,
      cityValue,
      stateData,
      stateValue,
      zipData,
      zipValue,
      countryData,
      countryValue,
      providerID
    } = e.target;
    orderFormData[name] = value;
    orderFormData[nameData] = valueData;
    orderFormData[addressData] = addressValue;
    orderFormData[cityData] = cityValue;
    orderFormData[stateData] = stateValue;
    orderFormData[zipData] = zipValue;
    orderFormData[countryData] = countryValue;
    if (name === "OriginID") {
      orderFormData.EnvaseTraceProviderID = providerID;
      setInitialProviderID(providerID);
    }
    setOrderFormData({ ...orderFormData });
  };

  const formatOrder = () => {
    if (new Validation().isNumber(orderFormData.Weight)) {
      orderFormData.Weight = 0;
    }
    orderFormData.VesselDate = formatDateTime(orderFormData.VesselDate, orderFormData.VesselTime);
    orderFormData.VesselActualDate = formatDateTime(orderFormData.VesselActualDate, orderFormData.VesselActualTime);
    orderFormData.ContainerReleaseDate = formatDateTime(orderFormData.ContainerReleaseDate, orderFormData.ContainerReleaseTime);
    orderFormData.ContainerLastDate = formatDateTime(orderFormData.ContainerLastDate, orderFormData.ContainerLastTime);
    orderFormData.ReferenceNumber = orderFormData.ReferenceNumber.trim();
    if (orderFormData.Type === 'Export') {
      orderFormData.CutoffDate = formatDateTime(orderFormData.CutoffDate, orderFormData.CutoffTime);
      orderFormData.FirstReceivedDate = formatDateTime(orderFormData.FirstReceivedDate, orderFormData.FirstReceivedTime);
      orderFormData.VesselDate = null;
      orderFormData.VesselActualDate = null;
      orderFormData.ContainerLastDate = null;
    } else {
      orderFormData.CutoffDate = null;
      orderFormData.FirstReceivedDate = null;
    }
    formatImportExport();
    if (orderFormData.Type === 'One Way') {
      orderFormData.LiveDrop = '';
    } else {
      orderFormData.LoadedStatus = '';
    }
    if (!orderFormData.OnHold) { 
      orderFormData.HoldReason = '';
    }
    setOrderFormData({ ...orderFormData });
  };

  const formatImportExport = () => {
    if (orderFormData.Type === 'One Way') {
      orderFormData.LiveDrop = '';
    } else {
      orderFormData.LoadedStatus = '';
    }
    if (orderFormData.LiveDrop === 'Drop') {
      if (orderFormData.Type === 'Import') {
        orderFormData.EmptyReadyDate = formatDateTime(orderFormData.EmptyReadyDate, orderFormData.EmptyReadyTime);
        orderFormData.LoadReadyDate = null;        
      } else if (orderFormData.Type === 'Export') {
        orderFormData.LoadReadyDate = formatDateTime(orderFormData.LoadReadyDate, orderFormData.LoadReadyTime);
        orderFormData.EmptyReadyDate = null;        
      }
    }
    // } else {
    //   orderFormData.ImportExportReportedby = '';
    //   orderFormData.EmptyReadyDate = null;
    //   orderFormData.LoadReadyDate = null;
    // }
  };

  const onUpdate = e => {
    setInProgress(true);
    formatOrder();
    e.preventDefault();
    if (!new Validation().isValidObject(orderFormValidation)) {
      setErrorMessage('Check required fields');
      setInProgress(false);
      return;
    }
    processUpdateRequest();
  };

  const processUpdateRequest = async () => {
    setErrorMessage('');
    const result = await orderUpdate(orderFormData);
    setInProgress(false);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }
    setLocationProvider();
    // Redirect to the order view page
    history.push({
      pathname: '/spark/order/view/' + id,
      state: {
        onCloseRoute: onCloseRoute
      }
    });
  };

  const setLocationProvider = async() => { 
    if (orderFormData.EnvaseTraceProviderID !== initialProviderID) { 
      await setProvider(orderFormData.OriginID, orderFormData.EnvaseTraceProviderID)
      setInitialProviderID(null);
    }
  };

  const onClose = () => {
    history.push({
      pathname: '/spark/order/view/' + id,
      state: {
        onCloseRoute: onCloseRoute
      }
    });
  };

  return (
    <div>
      <CorePageHeader
        title={"Edit Order " + orderFormData.OrderNum}
        breadCrumb="Home / ... / Edit Order"
        isInverted={true}
        createButton={
          <CoreExitButton
            onClick={onClose}
          />
        }
      />
      <Container maxWidth="lg" className="mt-4">
        {inProgress && <LinearProgress />}
        {orderFormDataLoaded && originLocationLoaded && destinationLocationLoaded &&
          <OrderEditContent
            onUpdate={onUpdate}
            onClose={onClose}
            errorMessage={errorMessage}
            orderFormData={orderFormData}
            orderFormValidation={orderFormValidation}
            updateOrderFormData={updateOrderFormData}
            inProgress={inProgress}
            initialProviderID={initialProviderID}
          />}
      </Container>
    </div>
  );

}

const buildValidation = (orderFormData) => {
  const {
    Customer,
    CustomerID,
    OriginID,
    DestinationID,
    Type,
    WeightUOM,
    LiveDrop,
    LoadedStatus
  } = orderFormData || {};
  const validation = new Validation();
  return {
    Customer: validation.isEmpty(Customer),
    CustomerID: validation.isNumber(CustomerID),
    OriginID: validation.isNumber(OriginID),
    DestinationID: validation.isNumber(DestinationID),
    Type: validation.isEmpty(Type),
    WeightUOM: validation.isEmpty(WeightUOM),
    LiveDrop: Type !== "One Way" ? validation.isEmpty(LiveDrop) : false,
    LoadedStatus: Type === "One Way" ? validation.isEmpty(LoadedStatus) : false
  };
};

export default OrderEdit;