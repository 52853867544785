import HttpService from '../http-service';

const token = localStorage.getItem('token');
const uri = 'IntermodalOrderAppointments/' + token + '/';

export async function Get(intermodalOrderID) { 
  const httpService = new HttpService();
  const result = await httpService
    .get(uri + intermodalOrderID);
  return result.response;
}

export async function GetByGateTransactionID(gateTransactionID) { 
  const httpService = new HttpService();

  let params = {
    gateTransactionID: gateTransactionID
  };

  const result = await httpService
    .get(uri + "GetByGateTransactionID", params);
  return result.response;
}

export async function Create(appointmentFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Create', appointmentFormData);
  return result.response;
}

export async function UpdateBookedDraft(appointmentFormData) { 
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdateBookedDraft', appointmentFormData);
  return result.response;
}

export async function Delete(appointmentID) { 
  var params = {
    appointmentID: appointmentID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'Delete', null, params);
  return result;
}

export async function GetList(intermodalOrderID) { 
  var params = { 
    intermodalOrderID: intermodalOrderID
  };
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'GetAppointmentsListForIntermodalOrder', null, params);
  const response = result.response;
  return response;
}

export async function UpdatePaidStatus(request) {
  const httpService = new HttpService();
  const result = await httpService
    .post(uri + 'UpdatePaidStatus', request);
  return result;
}