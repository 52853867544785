import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
  iconText: {
    fontSize: '0.875rem',
    color: 'black',
    fontWeight: 550,
    filter: 'invert(37%) sepia(93%) saturate(686%) hue-rotate(226deg) brightness(74%) contrast(110%);'
  }
}));

const CoreAddButton = (props) => {
  const { 
    handleOpenCreate,
    recordTypeText
  } = props;
  const classes = useStyles();

  return (
    <Button variant="text" className={classes.iconText} onClick={handleOpenCreate}>
        <AddIcon />&nbsp;<span>ADD {recordTypeText}</span>
    </Button>
  );
}

CoreAddButton.propTypes = {
  handleOpenCreate: PropTypes.func
};

export default CoreAddButton;