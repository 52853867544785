import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LocationSvg } from '../assets/svg-icons/location@1x.svg'

const IconLocation = () => {
  return (
    <SvgIcon fontSize="small">
      <LocationSvg />
    </SvgIcon>
  )
}

export default IconLocation;