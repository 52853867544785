import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getList } from '../country/CountryService';
import ConfigurationViewCountrySectionListTable from './ConfigurationViewCountrySectionListTable';
import CountryCreateDialog from '../country/CountryCreateDialog';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationCountryTypeSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;
  const [inProgress, setInProgress] = useState(true);
  const [isCountryCreateDialogOpen, setIsCountryCreateDialogOpen] = useState(false);
  const [countries, setCountries] = useState({});

  useEffect(() => {
    getList()
      .then(function(response) { 
        setCountries(response);
        setInProgress(false);
      });
  }, []);


  const getCountryList = () => {
    getList()
      .then(function(response) { 
        setCountries(response);
      });  
  };

  const openCountryCreateDialog = () => {
    setIsCountryCreateDialogOpen(true);
  };

  const closeCountryCreateDialog = () => {
    setIsCountryCreateDialogOpen(false);
    getCountryList();
  };
  
  return (
    <div>
      {inProgress && <LinearProgress />}
      <Paper variant="outlined" square className="mt-1">
        {isOpen &&
          <Container maxWidth="lg" className="mt-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Countries"}
              <span style={rightFloat}>
                <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel={"Hide Countries"}></CoreExpandLessButton>
              </span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                {"This is the list of countries available for you to select from when adding new locations to the system."}
                <span style={rightFloat}>
                </span>
            </Typography>
            <ConfigurationViewCountrySectionListTable 
              countries={countries}
              getCountries={getCountryList}
              setCountries={setCountries}
              openCountryCreateDialog={openCountryCreateDialog}
            />
          </Container>
        }
        {isOpen &&
          <DialogActions style={justifyContent} className="mb-1">
            <span></span>
          </DialogActions>
        }
        {!isOpen &&
          <Container maxWidth="lg" className="mt-2 mb-2">
            <Typography variant="subtitle1" style={bold} gutterBottom>
              {"Countries"}
              <span style={rightFloat}>
                <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel={"Show Countries"}></CoreExpandMoreButton>
              </span>
            </Typography>
          </Container>
        }
      </Paper>
      <CountryCreateDialog
        isOpen={isCountryCreateDialogOpen}
        onClose={closeCountryCreateDialog}
      />
    </div>
  );
}

export default ConfigurationCountryTypeSection
