import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import NavigationDrawer from './NavigationDrawer';
import NavigationToolbarButtonAvatar from './NavigationToolbarButtonAvatar';
import NavigationToolbarButtonNotification from './NavigationToolbarButtonNotification';
import NavigationToolbarButtonChat from './NavigationToolbarButtonChat';
import NavigationToolbarButtonQuickAdd from './NavigationToolbarButtonQuickAdd';
//import NavigationToolbarButtonSearch from './NavigationToolbarButtonSearch';
import NavigationToolbarButtonFeedback from './NavigationToolbarButtonFeedback';
import NavigationToolbarTestSiteIdentifier from './NavigationToolbarTestSiteIdentifier';
import NavigationToolbarButtonShoppingCart from './NavigationToolbarButtonShoppingCart';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { getLogo } from '../branch/BranchService';
import { getCompanyFeature } from '../company_feature/CompanyFeatureService';
import NavigationToolbarAuthorizeTerminalFees from './NavigationToolbarAuthorizeTerminalFees';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    paddingLeft: 57,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 57}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  toolbarButtons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 5
  },
  toolbarButton: {
    marginLeft: 200000
  }
}));

const Navigation = (e) => {
  const classes = useStyles();

  const [logoData, setLogoData] = useState();
  const [companyFeature, setCompanyFeature] = useState({});

  useEffect(() => {
    getCompany();
    getCompanyLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanyLogo = async () => {
    const mainBranchID = await getMainBranchID();
    const logo = await getLogo(mainBranchID);
    setLogoData(logo);
  };

  const getCompany = async () => { 
    const cf = await getCompanyFeature();
    setCompanyFeature(cf);
  };
  
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>      
      <AppBar 
        elevation={0}
        position="fixed"
        color="inherit"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        >
        <Toolbar>
          <Hidden only={['sm', 'xs']}>
            &nbsp;
            {logoData &&
              <img height="40" src={`data:image/jpeg;base64,${logoData}`} alt="Company Logo"/>
            }
          </Hidden>
          <div className={classes.toolbarButtons}>
            {/* -------
              Out until we have mocks for UX
              <NavigationToolbarButtonSearch />
            ----- */}
            <NavigationToolbarTestSiteIdentifier/>
            <NavigationToolbarButtonQuickAdd />
            <NavigationToolbarButtonFeedback />
            {companyFeature.IsICTSI && <NavigationToolbarAuthorizeTerminalFees />}
            {companyFeature.IsICTSI && <NavigationToolbarButtonShoppingCart />}
            <NavigationToolbarButtonChat/>
            <NavigationToolbarButtonNotification/>
            <NavigationToolbarButtonAvatar/>
          </div>
        </Toolbar>
      </AppBar>
      <NavigationDrawer 
        open={open} 
        handleDrawerClose={handleDrawerClose} 
        handleDrawerOpen={handleDrawerOpen}
      />
    </div>
  );
}

export default Navigation;