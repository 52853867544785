import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoreAutocompleteField from '../core/CoreAutocompleteField';
import { getForSpinnerCompare } from './PointToPointTemplateService';
import PointToPointTemplateCreateDialog from './PointToPointTemplateCreateDialog';

const PointToPointTemplateField = (props) => {

  const {
    label,    
    value,
    name,
    nameData,
    valueData,
    pointToPointTemplateForEdit,
    isRequired,
    validationError,
    onChange,
    ...rest
  } = props;

  const [pointToPointTemplateList, setPointToPointTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [persistedPointToPointTemplateName, setPersistedPointToPointTemplateName] = useState('');
  const [showAddButton, setShowAddButton] = useState(false);
  const [isPointToPointTemplateDialogOpen, setIsPointToPointTemplateDialogOpen] = useState(false);

  useEffect(() => {
    async function getPointToPointTemplateList() { 
      setIsLoading(true);
      const result = await getForSpinnerCompare(pointToPointTemplateForEdit);
      setPointToPointTemplateList(result);
      setIsLoading(false);
    }
    getPointToPointTemplateList();
  }, [pointToPointTemplateForEdit]);

  async function getPointToPointTemplateListForSpinner(compareValue) { 
    const result = await getForSpinnerCompare(compareValue);
    setPointToPointTemplateList(result);
  }

  const onTemplateSelected = newValue => {
    const dispatchEvent = {
      target: {
        name: name,
        value: newValue ? newValue.Name : '',
        nameData: nameData,
        valueData: newValue ? newValue.ID : null
      }
    };
    onChange(dispatchEvent);
  };

  const getOptionSelected = (option, value) => {
    if (option && option.Name === value) {
      return option.Name;
    }  
  };

  const getDefaultValue = () => {
    const pointToPointTemplate = pointToPointTemplateList.find(template => template.ID === valueData);
    if (pointToPointTemplate){ 
      return pointToPointTemplate.Name;
    }
    return '';
  }

  const openPointToPointTemplateDialog = () => { 
    setIsPointToPointTemplateDialogOpen(true);
  };

  const getShowAddButton = (event, inputValue) => {
    const obj = pointToPointTemplateList.find(c => c.Name.toLowerCase() === inputValue.toLowerCase());
    if (inputValue && obj === undefined) {
      setPersistedPointToPointTemplateName(inputValue);
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  };

  const closePointToPointTemplateDialog = async (responseID, pointToPointTemplateName) => {
    if (!isNaN(responseID)) {
      setIsLoading(true);
      const result = await getForSpinnerCompare(pointToPointTemplateName);
      setPointToPointTemplateList(result);
      const obj = result.find(c => c.ID === responseID);
      onTemplateSelected(obj);
      setIsLoading(false);
    }
    setIsPointToPointTemplateDialogOpen(false);
  };

  return (
    <>
      {!isLoading &&
        <CoreAutocompleteField
          label={label}
          value={value}
          name={name}
          isRequired={isRequired}
          validationError={validationError}
          onChange={onTemplateSelected}
          getDefaultValue={getDefaultValue}
          getOptionSelected={getOptionSelected}
          options={pointToPointTemplateList}
          openCreateDialogOpen={openPointToPointTemplateDialog}
          showAddButton={showAddButton}
          getShowAddButton={getShowAddButton}
          getList={getPointToPointTemplateListForSpinner}
          {...rest}
        />
      } 
      <PointToPointTemplateCreateDialog
        isOpen={isPointToPointTemplateDialogOpen}
        onClose={closePointToPointTemplateDialog}
        persistedName={persistedPointToPointTemplateName}
      />
    </>
  );

}

PointToPointTemplateField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  validationError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  pointToPointTemplateForEdit: PropTypes.string
};

export default PointToPointTemplateField;