import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';

import CoreButtonContainer from '../core/CoreButtonContainer';
import CoreExitButton from '../core/CoreExitButton';
import CoreEditButton from '../core/CoreEditButton';
import CoreMoreMenuButton from '../core/CoreMoreMenuButton';

const useStyles = makeStyles(() => ({
  purpleBackground: {
    background: '#472785',
    color: 'white'
  },
  buttonColor: {
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255, 0.9)'
  },
  iconColor: {
    filter: 'invert(1)',
    paddingTop: '5px'
  }
}));

const CustomerViewPageHeader = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const {
    customer,
    activateCustomer,
    deactivateCustomer
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activate  = () => {
    activateCustomer();
    setAnchorEl(null);
  };

  const deactivate = () => { 
    deactivateCustomer();
    setAnchorEl(null);
  };

  const onClose = () => {
    history.push('/spark/customer');
  };

  return (
    <>
    <Paper 
      variant="outlined" 
      className={classes.purpleBackground}
      square>
      <Container 
        maxWidth="xl" 
        className="mt-2 mb-2">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              { "Customer " + customer.InternalName + " Details" }
            </Typography>
          </Grid>
          <Grid item md={6} spacing={2} container justify="flex-end" alignItems="center" style={{paddingRight: '90px'}}>
            <CoreButtonContainer>
              <CoreEditButton
                editView={'/spark/customer/edit/' + customer.ID}
              />
              <CoreMoreMenuButton
                onClick={handleClick}
              />
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '150px',
                    width: 'auto',
                  },
                }}
              >
              {customer.Obsolete && <MenuItem key="menuItem1" onClick={activate}>
                Activate
              </MenuItem>}
              {!customer.Obsolete && <MenuItem key="menuItem1" onClick={deactivate}>
                Deactivate
              </MenuItem>}
            </Menu>
            </CoreButtonContainer>
          </Grid>
        </Grid>
        <CoreExitButton
          onClick={onClose}
        />
      </Container>
    </Paper>
    </>
  );
}

CustomerViewPageHeader.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerViewPageHeader;