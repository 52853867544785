import React from 'react';
import CorePageHeader from '../core/CorePageHeader';
import ReportsListTable from './ReportsListTable';

const Reports = () => { 

  return (
    <div>
      <CorePageHeader
        title="Reports"
      />
      <ReportsListTable />
    </div>
  );
}

export default Reports;