import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from "@material-ui/data-grid";
import DialogActions from '@material-ui/core/DialogActions';
import { format } from 'date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import IntermodalEventCreateDialog from './IntermodalEventCreateDialog';
import IntermodalEventListTableEventAddButton from './IntermodalEventListTableEventAddButton';
import IntermodalEventListTableLegMoreButton from './IntermodalEventListTableLegMoreButton';
import IntermodalEventListTableEventMoreButton from './IntermodalEventListTableEventMoreButton';
import IntermodalEventListTableLegDriverField from './IntermodalEventListTableLegDriverField';
import IntermodalEventListTableLegChassisPickupField from './IntermodalEventListTableLegChassisPickupField';
import IntermodalEventListTableLegChassisReturnField from './IntermodalEventListTableLegChassisReturnField';
import IntermodalEventListTableDateField from './IntermodalEventListTableDateField';
import PayableCreateDialog from '../payable/PayableCreateDialog';
import IntermodalEventListTableLegStatusField from './IntermodalEventListTableLegStatusField';
import IntermodalEventListStatus from './IntermodalEventListStatus';
import RevenueCreateDialog from '../revenue/RevenueCreateDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getDefaultShipmentSettings } from '../configuration/ConfigurationService';

const IntermodalEventListTable = (props) => {
  const {
    originID,
    events,
    getEvents,
    setEvents,
    getPayables,
    getReceivables,
    inProgress,
    setInProgress,
  } = props;

  const [currentLegID, setCurrentLegID] = useState();
  const [currentEventID, setCurrentEventID] = useState();
  const [isEventCreateDialogOpen, setEventCreateDialogOpen] = useState(false);
  const [isAddEventAbove, setIsAddEventAbove] = useState(false);
  const [eventIndexForNewEvent, setEventIndexForNewEvent] = useState(0);
  const [isPayableCreateDialogOpen, setPayableCreateDialogOpen] = useState(false);
  const [currentEventType, setCurrentEventType] = useState(null);
  const [initialEventType, setInitialEventType] = useState(null);
  const [currentDriverID, setCurrentDriverID] = useState(null);
  const [addChargeDialog, setAddChargeDialog] = useState(false);
  const [isRevenueCreateDialogOpen, setRevenueCreateDialog] = useState(false);
  const [configurationDefault, setConfigurationDefault] = useState({});

  let columns = [
    {
      field: 'EventType',
      headerName: 'Type',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("EventType");
        return (
          <div style={{
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span>{name}</span>
          </div>
        )
      }
    },
    {
      field: 'LocationName',
      headerName: 'Location Name, City & State',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const event = params.row;
        return (
          <div>{event.LocationTemplate.InternalName}</div>
        )
      }
    },
    {
      field: 'Status',
      headerName: 'Status',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const event = params.row;
        return (
          <IntermodalEventListStatus
            event={event}
          />
        )
      }
    },
    {
      field: 'AppointmentNumber',
      headerName: 'Appt. #',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Appointment',
      headerName: 'Appt. Start/End Date & Time',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("AppointmentDateStart") ? format(new Date(params.getValue("AppointmentDateStart")), 'MM/dd/yyyy HH:mm') : null;
        const name1 = params.getValue("AppointmentDateEnd") ? format(new Date(params.getValue("AppointmentDateEnd")), 'MM/dd/yyyy HH:mm') : null;
        return (
          <div style={{ lineHeight: '20px' }}>
            { name && <span>{"Start - "} {name} <br /></span>}
            { name1 && <span>{"End - "} {name1}</span>}
          </div>
        )
      }
    },
    {
      field: 'ArrivalDeparture',
      headerName: 'Arrival/Departure Date & Time',
      align: 'left',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const name = params.getValue("ActualArrivalDate") ? format(new Date(params.getValue("ActualArrivalDate")), 'MM/dd/yyyy HH:mm') : null;
        const name1 = params.getValue("ActualDepartDate") ? format(new Date(params.getValue("ActualDepartDate")), 'MM/dd/yyyy HH:mm') : null;
        return (
          <div style={{ lineHeight: '20px' }}>
            { name && <span>{"Start - "} {name} <br /></span>}
            { name1 && <span>{"End - "} {name1}</span>}
          </div>
        )
      }
    },
    {
      field: 'Miles',
      headerName: 'Miles',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isFirstEvent = params.getValue("EventIndex");
        const name = isFirstEvent !== 0 ? params.getValue("Miles") : null;
        return (
          <div>
            {name}
          </div>
        )
      }
    },
    {
      field: 'Note',
      headerName: 'Notes',
      align: 'left',
      flex: 1,
      sortable: false
    },
    {
      field: 'Controls',
      headerName: ' ',
      align: 'left',
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        const event = params.row;
        const legID = params.getValue("LegID");
        const id = params.getValue("ID");
        const length = params.getValue("Length") - 1;
        const eventIndex = params.getValue("EventIndex");
        const eventType = getEventBlowEventType(event);
        const eventTypeAbove = getEventAboveEventType(event);
        return (
          <>
            <IntermodalEventListTableEventAddButton
              legID={legID}
              eventID={id}
              setCurrentLegID={setCurrentLegID}
              setCurrentEventID={setCurrentEventID}
              setIsAddEventAbove={setIsAddEventAbove}
              setEventCreateDialogOpen={setEventCreateDialogOpen}
              eventTypeBelow={eventType}
              setCurrentEventType={setCurrentEventType}
              eventTypeAbove={eventTypeAbove}
              setEventIndexForNewEvent={setEventIndexForNewEvent}
              eventIndex={eventIndex}
              initialEventType={event.EventType}
              setInitialEventType={setInitialEventType}
            />
            <IntermodalEventListTableEventMoreButton
              reference={`event: ${params.getValue("EventType")} at ${params.getValue("LocationTemplate").InternalName}`}
              eventID={id}
              length={length}
              eventIndex={eventIndex}
              getEvents={getEvents}
              setInProgress={setInProgress}
            />
          </>
        )
      }
    }
  ];

  const getEventBlowEventType = (event) => {
    for (const leg of events) {
      for (var i = 0; i < leg.IntermodalEvents.length; i++) {
        const currentEvent = leg.IntermodalEvents[i];
        if (currentEvent.ID === event.ID) {
          const eventBlow = leg.IntermodalEvents[i + 1];
          if (eventBlow) {
            return eventBlow.EventType;
          }
        }
      }
    }
    return null;
  };

  const getEventAboveEventType = (event) => {
    for (const leg of events) {
      for (var i = 0; i < leg.IntermodalEvents.length; i++) {
        const currentEvent = leg.IntermodalEvents[i];
        if (currentEvent.ID === event.ID && i !== 0) {
          const eventAbove = leg.IntermodalEvents[i - 1];
          if (eventAbove) {
            return eventAbove.EventType;
          }
        }
      }
    }
    return null;
  };

  const onEventRowEnter = (params) => {
    for (const leg of events) {
      for (const item of leg.IntermodalEvents) {
        if (item.ID === params.row.ID) {
          item.ShowControls = true;
        }
      }
    }
    setEvents([...events]);
  };

  const onEventRowLeave = (params) => {
    for (const leg of events) {
      for (const item of leg.IntermodalEvents) {
        if (item.ID === params.row.ID) {
          item.ShowControls = false;
        }
      }
    }
    setEvents([...events]);
  };

  const closeEventCreateDialog = () => {
    setEventCreateDialogOpen(false);
    getEvents();
  };

  const closePayableCreateDialog = () => { 
    setPayableCreateDialogOpen(false);
    getPayables();
  };

  const openAddChargeDialog = (legID, driverID) => {
    getDefaultDryRunCharges();
    setCurrentLegID(legID);
    setCurrentDriverID(driverID);
    setAddChargeDialog(true);
  }
  
  const openDryRunRevenue = () => {
    setRevenueCreateDialog(true);
  };

  const openDryRunPayable = () => {
    setPayableCreateDialogOpen(true);    
  };

  const handleChargeDialogClose = () => {
    setAddChargeDialog(false);
    setInProgress(false);
    getEvents();
    setConfigurationDefault({});
  };

  const closeRevenueCreateDialog = () => {
    setRevenueCreateDialog(false);
    getReceivables();
  };

  const getDefaultDryRunCharges = () => { 
    getDefaultShipmentSettings()
    .then(function (response) {
      setConfigurationDefault(response);
    });
  };

  return (
    <div className="mt-1">
      {inProgress && <LinearProgress />}
      {events.map((leg) => (
        <Paper style={{ padding: '0', height: '' }} maxWidth="lg" className="mt-1 munaGrid" square>
          <DialogActions key={leg.ID}>
            <Grid item md={1} xs={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <span style={{ alignSelf: 'center', fontWeight: 'bold'}}>
                &nbsp;{"LEG #" + (leg.LegIndex + 1)}&nbsp;
              </span>                  
            </Grid>
            <Grid item md={11} xs={11} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item md={2} xs={2}>
                <IntermodalEventListTableLegStatusField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                  inProgress={inProgress}
                  openAddChargeDialog={openAddChargeDialog}
                />
              </Grid>
              {(leg.LegIndex === 0 || leg.LegIndex === events.length - 1) && <Grid item md={1} xs={false}>
              </Grid>}
              {(leg.LegIndex === 0) && <Grid item md={2} xs={2}>
                <IntermodalEventListTableLegChassisPickupField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>}   
              {(leg.LegIndex === 0 && leg.LegIndex === events.length - 1) && <Grid item md={1} xs={false}>
              </Grid>}
              {(leg.LegIndex !== 0 && leg.LegIndex !== events.length - 1) && <Grid item md={3} xs={false}>
              </Grid>}
              {(leg.LegIndex === events.length - 1) && <Grid item md={2} xs={2}>
                <IntermodalEventListTableLegChassisReturnField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>}
              {(leg.LegIndex === 0 && leg.LegIndex === events.length - 1) && <Grid item md={1} xs={false}>
              </Grid>}
              {(leg.LegIndex !== 0 || leg.LegIndex !== events.length - 1) && <Grid item md={4} xs={false}>
              </Grid>}
              
              <Grid item md={2} xs={2}>
                <IntermodalEventListTableDateField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>
              <Grid item md={1} xs={false}>
              </Grid>
              <Grid item md={2} xs={2}>
                <IntermodalEventListTableLegDriverField
                  leg={leg}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                />
              </Grid>
              <Grid item md={1} xs={false}>
              </Grid>
              <span style={{ alignSelf: 'center' }}>
                <IntermodalEventListTableLegMoreButton
                  leg={leg}
                  legLength={(events.length - 1)}
                  getEvents={getEvents}
                  setInProgress={setInProgress}
                  setCurrentLegID={setCurrentLegID}
                  setCurrentEventID={setCurrentEventID}
                  setIsAddEventAbove={setIsAddEventAbove}
                  setEventCreateDialogOpen={setEventCreateDialogOpen}
                  setPayableCreateDialogOpen={setPayableCreateDialogOpen}
                  setCurrentEventType={setCurrentEventType}
                  setCurrentDriverID={setCurrentDriverID}
                  eventTypeBelow={(leg.IntermodalEvents[leg.IntermodalEvents.length-1] ? leg.IntermodalEvents[leg.IntermodalEvents.length-1].EventType : null)}                      
                />
              </span>
            </Grid>
          </DialogActions>
          <DataGrid
            getRowId={(row) => row.ID}
            hideFooter={true}
            autoHeight={true}
            rows={leg.IntermodalEvents}
            columns={columns}
            pageSize={leg.IntermodalEvents.length}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            onRowEnter={e => onEventRowEnter(e)}
            onRowLeave={e => onEventRowLeave(e)}
          />
        </Paper>
      ))}  
      {
        <IntermodalEventCreateDialog
          intermodalLegID={currentLegID}
          insertingAfterEventID={currentEventID}
          isOpen={isEventCreateDialogOpen}
          onClose={closeEventCreateDialog}
          isAddEventAbove={isAddEventAbove}
          currentEventType={currentEventType}
          eventIndexForNewEvent={eventIndexForNewEvent}
          initialEventType={initialEventType}
        />
      }
      {
        <PayableCreateDialog
          originID={originID}
          intermodalLegID={currentLegID}
          driverID={currentDriverID}
          isOpen={isPayableCreateDialogOpen}
          onClose={closePayableCreateDialog}
          defaultPayableChargeTypeID={configurationDefault.DefaultDryRunPayableChargeTypeID}
          defaultPayableChargeTypeName={configurationDefault.DefaultDryRunPayableChargeTypeName}
        />
      }
      {
        <RevenueCreateDialog
          id={originID}
          isOpen={isRevenueCreateDialogOpen}
          onClose={closeRevenueCreateDialog}
          defaultReceivableChargeTypeID={configurationDefault.DefaultDryRunReceivableChargeTypeID}
          defaultReceivableChargeTypeName={configurationDefault.DefaultDryRunReceivableChargeTypeName}
        />
      }
      {
        <Dialog
          open={addChargeDialog}        
          onClose={handleChargeDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add a Dry Run Revenue/Expense"}
          </DialogTitle>
          <DialogContent>
            Would you like to add a Dry Run Revenue or Dry Run Expense?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleChargeDialogClose} disabled={inProgress}>Close</Button>
            <Button onClick={openDryRunRevenue} disabled={inProgress} autoFocus>
              Add Revenue
            </Button>
            <Button onClick={openDryRunPayable} disabled={inProgress} autoFocus>
              Add Expense
            </Button>
          </DialogActions>
        </Dialog>  
      }    
    </div>
  );

}

IntermodalEventListTable.propTypes = {
  originID: PropTypes.number.isRequired,
  events: PropTypes.array.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default IntermodalEventListTable;