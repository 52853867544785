import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CoreTextField from '../core/CoreTextField';
import CoreNumberField from '../core/CoreNumberField';

const EquipmentCreateContentDetailInfo = (props) => {
  const {
    EquipmentFormData,
    EquipmentFormValidation,
    UpdateEquipmentFormData
  } = props;

  return (
    <Container maxWidth="lg" className="mt-2 mb-2" component={Paper}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Equipment Details</h4>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="Manufacturer"
              label="Manufacturer"
              value={EquipmentFormData.Manufacturer}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.Manufacturer}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="Model"
              label="Vehicle Model"
              value={EquipmentFormData.Model}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.Model}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="ModelYear"
              label="Vehicle Year"
              value={EquipmentFormData.ModelYear}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.ModelYear}
            />
          </Grid>
        }
        {EquipmentFormData.UnitType !== 2 &&
          <Grid item md={3} xs={12} className="mb-2">
            <CoreNumberField
              name="Axles"
              label="No. of Axles"
              minValue={0}
              value={EquipmentFormData.Axles}
              onChange={e => UpdateEquipmentFormData(e)}
              validationError={EquipmentFormValidation.Axles}
            />
          </Grid>
        }
        <Grid item md={3} xs={12} className="mb-2">
          <CoreNumberField
            name="OutsideLengthFt"
            label="Size"
            minValue={0}
            value={EquipmentFormData.OutsideLengthFt}
            onChange={e => UpdateEquipmentFormData(e)}
            validationError={EquipmentFormValidation.Axles}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default EquipmentCreateContentDetailInfo;