import React, { useState, useEffect } from 'react';

import Validation from '../validation';
import { create } from './EquipmentTypeService';
import EquipmentTypeCreateDialogContent from './EquipmentTypeCreateDialogContent';

const EquipmentTypeCreateDialog = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const initialState = {
    FullPath: '',
  };
  
  const [equipmentTypeFormData, setEquipmentTypeFormData] = useState(initialState);
  const [equipmentTypeFormValidation, setEquipmentTypeFormValidation] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setEquipmentTypeFormValidation(buildValidation(equipmentTypeFormData));
    }
  }, [equipmentTypeFormData, isOpen]);

  const updateEquipmentTypeFormData = e => {
    const { name, value } = e.target;
    equipmentTypeFormData[name] = value;
    setEquipmentTypeFormData({ ...equipmentTypeFormData });
  };

  const onCreate = e => {
    setInProgress(true);
    e.preventDefault();
    if (!new Validation().isValidObject(equipmentTypeFormValidation)) {
      setErrorMessage('Check required fields');
      return;
    }
    processCreateRequest();
  };

  const processCreateRequest = async () => {
    setErrorMessage('');
    equipmentTypeFormData.TreeLevel = 1;
    equipmentTypeFormData.SiblingOrder = 1;
    const result = await create(equipmentTypeFormData);
    setInProgress(false);
    if (result.error) {
       setErrorMessage(result.error);
       return;
    }
    closeDialog();
  };

  const closeDialog = () => {
    setEquipmentTypeFormData({initialState});
    onClose();
  };

  return (
    isOpen &&
    <EquipmentTypeCreateDialogContent
      isOpen={isOpen}
      onClose={closeDialog}
      onCreate={onCreate}
      errorMessage={errorMessage}
      equipmentTypeFormData={equipmentTypeFormData}
      equipmentTypeFormValidation={equipmentTypeFormValidation}
      updateEquipmentTypeFormData={updateEquipmentTypeFormData}
      inProgress={inProgress}
    />
  );
}

const buildValidation = (equipmentTypeFormData) => {
  const {
    FullPath
  } = equipmentTypeFormData || {};
  const validation = new Validation();
  return {
    FullPath: validation.isEmpty(FullPath)
  };
};

export default EquipmentTypeCreateDialog;