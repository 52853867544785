import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as EditSvg } from '../assets/svg-icons/edit@1x.svg';

const IconEdit = () => {
  return (
    <SvgIcon fontSize="small">
      <EditSvg />
    </SvgIcon>
  )
}
export default IconEdit;