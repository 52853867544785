import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconCustomer from '../icon/IconCustomer';

import CoreTextField from '../core/CoreTextField';
import CountryField from '../country/CountryField';
import CorePhoneField from '../core/CorePhoneField';
import ProvinceField from '../province/ProvinceField';
import PersonnelGenderField from './PersonnelGenderField';
import PersonnelUserDriverRadioField from './PersonnelUserDriverRadioField';
import CoreHereDisplayMap from '../core/CoreHereDisplayMap';
import PersonnelEditContentVendorInfo from './PersonnelEditContentVendorInfo';
import { getMainBranchID } from '../configuration/ConfigurationService';
import { get as getBranch } from '../branch/BranchService';


const PersonnelEditContentInfo = (props) => {
  const {
    PersonnelFormData,
    PersonnelFormValidation,
    updatePersonnelFormData,
    VendorFormData,
    updateVendorFormData,
    coordinates
  } = props;

  const setInternalName = async (e) => {
    updatePersonnelFormData(e);
    if(PersonnelFormData.ListAsDriver === 2) {
      VendorFormData.VendorInternalName = PersonnelFormData.InternalName
    }
  };

  const [branch, setBranch] = useState('');

  useEffect(() => {
    getBranchDetails();
  }, []);

  const getBranchDetails = async () => {
    const mainBranchID = await getMainBranchID();
    const b = await getBranch(mainBranchID);
    setBranch(b);
  };

  return (
    <div>
      <Container maxWidth="lg" className="mt-2" component={Paper}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h4>Basic Information</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <PersonnelUserDriverRadioField
              value={PersonnelFormData.DriverType}
              name="ListAsDriver"
              nameData="DriverType"
              valueData={PersonnelFormData.DriverType}
              onChange={e => updatePersonnelFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="FirstName"
              label="First Name"
              value={PersonnelFormData.FirstName}
              onChange={e => updatePersonnelFormData(e)}
              isRequired={true}
              validationError={PersonnelFormValidation.FirstName}
              maxLength={25}
              autoFocus={true}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="MiddleName"
              label="Middle Name"
              value={PersonnelFormData.MiddleName}
              onChange={e => updatePersonnelFormData(e)}
              validationError={PersonnelFormValidation.MiddleName}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="LastName"
              label="Last Name"
              value={PersonnelFormData.LastName}
              onChange={e => updatePersonnelFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CoreTextField
              name="InternalName"
              label="Internal Name"
              value={PersonnelFormData.InternalName}
              onChange={e => setInternalName(e)}
              isRequired={true}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CoreTextField
              name="Email"
              label="Email"
              value={PersonnelFormData.Email}
              onChange={e => updatePersonnelFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CorePhoneField
              name="HomePhone"
              label="Primary Number"
              value={PersonnelFormData.HomePhone}
              onChange={e => updatePersonnelFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={12} className="mb-2">
            <CorePhoneField
              name="BusPhone"
              label="Secondary Number"
              value={PersonnelFormData.BusPhone}
              onChange={e => updatePersonnelFormData(e)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <PersonnelGenderField
              label="Gender"
              value={PersonnelFormData.Gender}
              name="Gender"
              isRequired={false}
              onChange={e => updatePersonnelFormData(e)}
            />
            &nbsp;
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" component={Paper} style={{ backgroundColor: '#F3F0FA' }}>
        {
          PersonnelFormData.DriverType !== 2 &&

        <Grid container spacing={2}>
          <Grid item xs={6} className="mb-2">
            <Grid container spacing={2}>
              { (PersonnelFormData.Country !== "Philippines" && branch.Country !== "Philippines")  &&<>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="Address"
                    label="Address"
                    value={PersonnelFormData.Address}
                    onChange={e => updatePersonnelFormData(e)}
                    icon={
                      <IconCustomer />
                    }
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item xs={6}>
                  <CoreTextField
                    name="City"
                    label="City"
                    value={PersonnelFormData.City}
                    onChange={e => updatePersonnelFormData(e)}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <ProvinceField
                    label="State / Province"
                    value={PersonnelFormData.Province}
                    name="Province"
                    isRequired={false}
                    onChange={e => updatePersonnelFormData(e)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CoreTextField
                    name="PostalCode"
                    label="ZIP / Postal Code"
                    value={PersonnelFormData.PostalCode}
                    onChange={e => updatePersonnelFormData(e)}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={12} className="mb-2">
                  <CountryField
                    label="Country"
                    value={PersonnelFormData.Country}
                    validationError={PersonnelFormValidation.Country}
                    name="Country"
                    isRequired={true}
                    onChange={e => updatePersonnelFormData(e)}
                  />
                </Grid>
              </>}
              {(PersonnelFormData.Country === "Philippines" || branch.Country === "Philippines") && <>
              <Grid item md={6} xs={12}>
                  <CountryField
                    label="Country"
                    value={PersonnelFormData.Country}
                    validationError={PersonnelFormValidation.Country}
                    name="Country"
                    isRequired={true}
                    onChange={e => updatePersonnelFormData(e)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ProvinceField
                    label="State / Province"
                    value={PersonnelFormData.Province}
                    name="Province"
                    isRequired={false}
                    onChange={e => updatePersonnelFormData(e)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CoreTextField
                    name="City"
                    label="City"
                    value={PersonnelFormData.City}
                    onChange={e => updatePersonnelFormData(e)}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CoreTextField
                    name="PostalCode"
                    label="ZIP / Postal Code"
                    value={PersonnelFormData.PostalCode}
                    onChange={e => updatePersonnelFormData(e)}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="District"
                    label="District"
                    value={PersonnelFormData.District}
                    onChange={e => updatePersonnelFormData(e)}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="County"
                    label="Barangay"
                    value={PersonnelFormData.County}
                    onChange={e => updatePersonnelFormData(e)}
                    maxLength={100}
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CoreTextField
                    name="Address"
                    label="Address"
                    value={PersonnelFormData.Address}
                    onChange={e => updatePersonnelFormData(e)}
                    icon={
                      <IconCustomer />
                    }
                    autoComplete="disable-autocomplete"
                  />
                </Grid>
              </>}
            </Grid>
          </Grid>
          {/* Map section */}
          <Grid container item xs={6}>
            {coordinates &&
              <CoreHereDisplayMap
                coordinates={coordinates}
              />
            }
          </Grid>           
        </Grid>
        }
        { 
        PersonnelFormData.DriverType === 2 &&
        <Grid>
        <PersonnelEditContentVendorInfo
          PersonnelFormData={PersonnelFormData}
          PersonnelFormValidation={PersonnelFormValidation}
          updatePersonnelFormData={updatePersonnelFormData}
          coordinates={coordinates}
          VendorFormData={VendorFormData}
          updateVendorFormData={updateVendorFormData}
          />
          </Grid>
      }
      </Container>
    </div>
  );
}

export default PersonnelEditContentInfo;