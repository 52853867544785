import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CoreTextField from '../core/CoreTextField';

const EquipmentTypeEditDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    equipmentTypeFormData,
    onUpdate,
    errorMessage,
    equipmentTypeFormValidation,
    updateEquipmentTypeFormData,
    inProgress
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
      <form onSubmit={onUpdate}>
        <DialogTitle id="form-dialog-title">Update Equipment Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mt-1">
            <Grid item md={12} xs={12}>
              <CoreTextField
                name="FullPath"
                label="Name"
                value={equipmentTypeFormData.FullPath}
                onChange={e => updateEquipmentTypeFormData(e)}
                isRequired={true}
                validationError={equipmentTypeFormValidation.FullPath}
                autoFocus={true}
              />
            </Grid>
            {errorMessage && <Grid item md={12} xs={12} className="mt-3">
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={inProgress} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={inProgress} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

export default EquipmentTypeEditDialogContent;