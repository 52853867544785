import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid'
import {
  getMobileConfigurationData,
  setMobileConfigurationData,
  handleConfig
} from './ConfigurationMobileCompanyService';
import CoreExpandLessButton from '../core/CoreExpandLessButton';
import CoreExpandMoreButton from '../core/CoreExpandMoreButton';
import ConfigurationCompanyDriver from '../mobile_configuration/ConfigurationCompanyDriver';
import ConfigurationOwnerOperator from '../mobile_configuration/ConfigurationOwnerOperator';

const justifyContent = {
  justifyContent: 'space-between'
};
const rightFloat = {
  float: 'right'
};
const bold = {
  fontWeight: '600'
};


const ConfigurationViewDriverMobileSection = (props) => {
  const {
    isOpen,
    toggleIsOpen
  } = props;

  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [configuration, setConfiguration] = useState([
    {
      id: null,
      name: "acceptLoads",
      value: true,
      role: "owner-operator",
      displayName: "Accept Loads",
    },
    {
      id: null,
      name: "rejectLoads",
      value: true,
      role: "owner-operator",
      displayName: "Reject Loads",
    },
    {
      id: null,
      name: "displayPayAmount",
      value: true,
      role: "owner-operator",
      displayName: "Show Pay Amount",
    },
    // {
    //   id: null,
    //   name: "mustConfirmGeofenceEvents",
    //   value: true,
    //   role: null,
    //   displayName: "Confirm Geofence Events",
    // },
    {
      id: null,
      name: "resetLoads",
      value: true,
      role: null,
      displayName: "Reset Shipment",
    },
    {
      id: null,
      name: "editChassisField",
      value: true,
      role: null,
      displayName: "Edit Chassis Field",
    },
    {
      id: null,
      name: "editContainerField",
      value: true,
      role: null,
      displayName: "Edit Container Field",
    },
    {
      id: null,
      name: "editSealField",
      value: true,
      role: null,
      displayName: "Edit Seal Field",
    },
    {
      id: null,
      name: "editPiecesField",
      value: true,
      role: null,
      displayName: "Edit Pieces Field",
    },
    {
      id: null,
      name: "editWeightField",
      value: true,
      role: null,
      displayName: "Edit Weight Field",
    },
    {
      id: null,
      name: "editBOLField",
      value: true,
      role: null,
      displayName: "Edit BOL Field",
    },
  ]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const handleConfigChange = async (event) => {
    setInProgress(true);
    setErrorMessage('');
    const name = event.target.name;
    const value = event.target.checked;
    const role = event.target.value;
    const data = handleConfig({ name, value, role }, configuration);

    const response = await setMobileConfigurationData(data);
    if (response.error) {
      setErrorMessage(response.error[0]);
      setInProgress(false);
      return;
    }
    
    if (response.value) {
      configuration[findConfig(data)].value = response.value === "true" ? true : false;
      configuration[findConfig(data)].role = response.role;
      setConfiguration(configuration);
    }
    setInProgress(false);
  }

  const getData = async () => {
    setErrorMessage('');
    const response = await getMobileConfigurationData();
    if (response.error) {
      setErrorMessage(response.error[0]);
      setConfigurationLoaded(true);
      setInProgress(false);
      return;
    }
    response.forEach((res) => {
      if (findConfig(res) >= 0) {
        configuration[findConfig(res)].value = res.value === "true" ? true : false;
        configuration[findConfig(res)].role = res.role;
        configuration[findConfig(res)].id = res.id;
      }
    });
    setConfiguration({...configuration});
    setConfigurationLoaded(true);
    setInProgress(false);
  };

  const findConfig = (data) => {
    return Object.values(configuration).findIndex((i) => i.name === data.name);
  };
    
  return (
    <div>
      {inProgress && <LinearProgress />}
      {configurationLoaded &&
        <Paper variant="outlined" square className="mt-1">
          {isOpen &&
            <Container maxWidth="lg" className="mt-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Driver Mobile Configuration Settings"}
                <span style={rightFloat}>
                  <CoreExpandLessButton toggleIsOpen={toggleIsOpen} ariaLabel="Hide Mobile Configuration Settings"></CoreExpandLessButton>
                </span>
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {"When using our mobile app, Envase Mobile these settings can be used to provide you control over what your drivers and owner/ops are able to see and do within the app."}
                <span style={rightFloat}>
                </span>
              </Typography>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" column="true">
                  <Grid container direction="column">
                    <Grid item container md={12} direction="column">
                      <ConfigurationCompanyDriver
                        inProgress={inProgress}
                        configuration={configuration}
                        handleConfigChange={handleConfigChange}
                      />
                    </Grid>
                    <br></br>
                    <Grid item container md={12} direction="column">
                      <ConfigurationOwnerOperator
                        inProgress={inProgress}
                        configuration={configuration}
                        handleConfigChange={handleConfigChange}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </Container>
          }
          {isOpen &&
            <DialogActions style={justifyContent} className="mb-1">
              <span>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              </span>
            </DialogActions>
          }
          {!isOpen &&
            <Container maxWidth="lg" className="mt-2 mb-2">
              <Typography variant="subtitle1" style={bold} gutterBottom>
                {"Driver Mobile Configuration Settings"}
                <span style={rightFloat}>
                  <CoreExpandMoreButton toggleIsOpen={toggleIsOpen} ariaLabel="Show Driver Hours Settings"></CoreExpandMoreButton>
                </span>
              </Typography>
            </Container>
          }
        </Paper>
      }
    </div>
  )
}

export default ConfigurationViewDriverMobileSection
