import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const PointToPointTemplateLineEditCheckDialogContent = (props) => {
  const {
    isOpen,
    onClose,
    openEditDialog,
    createNewTemplate, 
    inProgress   
  } = props;

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" maxWidth="lg">
      <DialogTitle id="form-dialog-title">Update Point-To-Point Template Line</DialogTitle>
      <DialogContent className='mt-2'>
        <DialogContentText>
          {"Would you like to:"} <br></br>
          {"- Edit this Template Line which will affect all drivers assigned to the Template"} <br></br>
          {"- Copy the Template specifically for this driver and modify the Template Line"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} disabled={inProgress} color="primary">
          Cancel
        </Button>
        <Button variant='outlined' onClick={openEditDialog} disabled={inProgress} color="primary">
          Edit Template Line
        </Button>
        <Button variant='outlined' onClick={createNewTemplate} disabled={inProgress} color="primary">
          Copy Template + Edit Template Line
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PointToPointTemplateLineEditCheckDialogContent;