import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PinDropIcon from '@material-ui/icons/PinDrop';
import NoteIcon from '@material-ui/icons/Note';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getListStyle, onDragEnd as onDragToEnd } from './ItineraryDragDropUtils';
import CoreDisplayDialog from '../core/CoreDisplayDialog';
import IntermodalEventEditDialog from '../intermodalEvent/IntermodalEventEditDialog';
import { moveEventIndex } from './ItineraryService';
import { getDateTimeString, getTimeString } from '../globalFunction';
import ItineraryEventColumnStatusColor from './ItineraryColumnStatusColor';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  smallText: {
    color: 'gray',
    fontSize: '80%',
    overflowWrap: 'break-word',
  },
  statusIcon: {
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '15px',
    padding: '6px',
    fontSize: '13px',
  },
  event: {
    borderBottom: '0.5px solid #D3D3D3',
    '&:last-child': {
      borderBottom: 'none'
    },
  },
  underText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  }
}));

const ItineraryEventList = (props) => {
  const {
    events, 
    loading, 
    updateEvent
  } = props;
  const classes = useStyles();

  const [itemList, setItemList] = useState([]);
  const [noteViewOpen, setNoteViewOpen] = useState(false);
  const [noteSelected, setNoteSeleceted] = useState(null);
  const [eventFormData, setEventFormData] = useState({});
  const [isEventEditModalOpen, setIsEventEditModalOpen] = useState(false);
  const [eventLegID, setEventLegID] = useState(null);
  const [eventMove, setEventMove] = useState([]);

  useEffect(() => {
    setItemList([...events]);
  }, [events]);

  const showNote = async (eventNote) => {
    setNoteSeleceted(eventNote);
    setNoteViewOpen(true);
  };

  const handleNoteClose = () => {
    setNoteSeleceted(null);
    setNoteViewOpen(false);
  };

  async function openEventEditModal(event) {
    setEventLegID(event.LegID);
    event.LocationInternalName = event.LocationTemplate.InternalName;
    setEventFormData(event);
    setIsEventEditModalOpen(true);
  };

  const closeEventEditModal = () => {
    setIsEventEditModalOpen(false);
    setEventFormData({});
    updateEvent(eventLegID);
    setEventLegID(null);
  };

  const onDragEnd = (result) => {
    const items = onDragToEnd(result, itemList);
    setItemList(items);
    setEventMove(result);
  }

  useEffect(() => {
    if (eventMove.source && eventMove.destination) {
      var legID = itemList[eventMove.destination.index].LegID;
      var eventID = itemList[eventMove.destination.index].ID;
      var newEventIndex = eventMove.destination.index || 0;
      updateAndGetEvents(legID, eventID, newEventIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMove]);
  
  const updateAndGetEvents = async(legID, eventID, newEventIndex) => {
    await moveEventIndex(legID, eventID, newEventIndex);
  };

  if (!loading && events.length <= 0) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100%'
        width='100%'
      >
        <Typography variant='button'>No Events</Typography>
      </Box>
    )
  }

  const getAppointmentNumber = (event) => {
    var maxLength = 15;
    if (event.AppointmentNumber) {
      return event.AppointmentNumber.slice(0, maxLength) + (event.AppointmentNumber.length > maxLength ? "..." : "");
    }
    return '-';
  };

  const showAppointmentNumberTooltip = (event) => {
    var maxLength = 15;
    if (event.AppointmentNumber && event.AppointmentNumber.length > maxLength) {
      return event.AppointmentNumber;
    }
    return '';
  }

  const getArrivalTime = (event) => {
    if (event.ActualArrivalDate) {
      const date = new Date(event.ActualArrivalDate);
      return getDateTimeString(date);
    }
    return '-';
  };

  const getDepartureTime = (event) => {
    if (event.ActualDepartDate) {
      const date = new Date(event.ActualDepartDate);
      return getDateTimeString(date);
    }
    return '-';
  };

  const getAppointmentTime = (event) => {
    if (event.AppointmentDateStart) {
      const date = new Date(event.AppointmentDateStart);
      return getTimeString(date);
    }
    return '-';
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {loading && <LinearProgress />}
              {itemList.map((event, index) => (
                <Draggable key={event.ID} draggableId={event.EventIndex.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={classes.event}
                    >
                      <Grid
                        container
                        justify='space-between'
                        alignItems='center'
                        style={{paddingTop: '10px', paddingBottom: '10px'}}
                        key={index}
                      >
                        <Grid item zeroMinWidth {...provided.dragHandleProps} style={{maxWidth: '2px'}}>
                          <DragIndicatorIcon />
                        </Grid>
                        <Grid item xs={1}>
                          <Grid container>
                            <Grid item>
                              <span><PinDropIcon color='primary'/></span>
                            </Grid>
                            <Grid item>
                              <Typography variant='body2' display='inline' className={classes.underText}> &nbsp; {event.EventType}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant='body2' className={classes.underText}>{event.LocationTemplate.InternalName}</Typography>
                          <div className={classes.smallText}>
                            {event.LocationTemplate.Address}<br></br>{event.LocationTemplate.City}
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <span className={classes.smallText}>Appt#</span>                          
                          <Tooltip title={showAppointmentNumberTooltip(event)} placement="top-start">
                            <Typography variant='body2' className={classes.underText}>
                              {getAppointmentNumber(event)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                          <span className={classes.smallText}>Appt Time</span>
                          <Typography variant='body2' className={classes.underText}>{getAppointmentTime(event)}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <span className={classes.smallText}>Arrival</span>
                          <Typography variant='body2' className={classes.underText}>{getArrivalTime(event)}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <span className={classes.smallText}>Departure</span>
                          <Typography variant='body2' className={classes.underText}>{getDepartureTime(event)}</Typography>
                        </Grid>
                        <Grid item zeroMinWidth>
                          <IconButton
                            color='primary'
                            onClick={() => showNote(event.Note || null)}
                            disabled={!event.Note ? true : false}
                          >
                            <NoteIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1} zeroMinWidth>
                          <div>
                            &nbsp;
                            <span
                              className="delivery-status-circle"
                              style={{ backgroundColor: ItineraryEventColumnStatusColor(event.Status) }}
                            >
                            </span>
                            <span>{event.Status}</span>
                          </div>
                        </Grid>
                        <Grid item zeroMinWidth>
                          <IconButton
                            color='primary'
                            onClick={() => openEventEditModal(event)}
                          >
                            <CreateIcon color='primary'/>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {noteViewOpen ? <CoreDisplayDialog isOpen={noteViewOpen} onClose={handleNoteClose} text={noteSelected}/> : <></>}
      <IntermodalEventEditDialog
        isOpen={isEventEditModalOpen}
        onClose={closeEventEditModal}
        eventFormData={eventFormData}
        setEventFormData={setEventFormData}
      />
    </>
  )
}

export default ItineraryEventList