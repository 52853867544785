import { Route } from 'react-router-dom';
import ChatList from './ChatList';

const ChatRouter = () => {
  return (
    <>
      <Route exact path="/spark/chat" component={ChatList} />
    </>
  );

}

export default ChatRouter;
