import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DashboardCompanyView from './DashboardCompanyView';
import DashboardDispatchView from './DashboardDispatchView';

const useStyles = makeStyles((theme) => ({
  withoutRightDrawer: {
    padding: '20px',
  },
  rightFloat: {
    float: 'right',
    paddingRight: '10px',
    minWidth: '384px'
  },
}));

const Dashboard = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const newDate = new Date();
  const todaysDate = newDate.toLocaleString("en-US", { month: "long" }) + " " + 
                      newDate.toLocaleString("en-US", { day : '2-digit'}) + ", " +
                      newDate.getFullYear();

  const contentContainer = classes.withoutRightDrawer;
  const [dashboardView, setDashboardView] = useState(0);
  const [filterData, setFilterData] = useState({
    Customer: '',
    CustomerID: null,
    LocationTemplateID: null,
    SteamshipLine: '',
    SteamshipLineID: null,
    Appointment: '',
    ContainerSize:'',
    ContainerType: '',
    ShipmentType: '',
  });
  const [filterDataAsParams, setFilterDataAsParams] = useState("");

  useEffect(() => {
    buildURLSearchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDashboardViewChange = e => {
    const { value } = e.target;
    setDashboardView(value);
    if (value === 0) {
      urlParams.set('view', 'company');
      window.history.replaceState(null, null, "dashboard?" + urlParams);
    } else if (value === 1) {
      urlParams.set('view', 'dispatch');
      window.history.replaceState(null, null, "dashboard?" + urlParams);
    }
  };

  function buildURLSearchFilters() {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');    
    const customer = urlParams.get('customer');
    const customerID = parseInt(urlParams.get('customerID'));
    const locationTemplateID = parseInt(urlParams.get('locationTemplateID'));
    const steamshipLine = urlParams.get('steamshipLine');
    const steamshipLineID = parseInt(urlParams.get('steamshipLineID'));
    const appointment = urlParams.get('appointment');
    const containerSize = urlParams.get('containerSize');
    const containerType = urlParams.get('containerType');
    const shipmentType = urlParams.get('shipmentType');
    if (view) {
      if (view === 'dispatch') {
        setDashboardView(1);
      } else {
        setDashboardView(0);
      }
    } else {      
      setDashboardView(0);
    }
    if (customer) {
      setFilterData(c => ({ ...c, Customer: customer }));         
    }
    if (customerID) {
      setFilterData(c => ({ ...c, CustomerID: customerID }));         
    }
    if (locationTemplateID) {
      setFilterData(c => ({ ...c, LocationTemplateID: locationTemplateID }));          
    }
    if (steamshipLine) {
      setFilterData(c => ({ ...c, SteamshipLine: steamshipLine }));      
    }
    if (steamshipLineID) {
      setFilterData(c => ({ ...c, SteamshipLineID: steamshipLineID }));      
    }
    if (appointment) {
      setFilterData(c => ({ ...c, Appointment: appointment }));      
    }
    if (containerSize) {
      setFilterData(c => ({ ...c, ContainerSize: containerSize }));
    }
    if (containerType) {
      setFilterData(c => ({ ...c, ContainerType: containerType }));      
    }
    if (shipmentType) {
      setFilterData(c => ({ ...c, ShipmentType: shipmentType }));      
    }
    updateFilterDataAsParams();    
  };

  const updateFilterData = (e) => {
    const { name, value, nameData, valueData } = e.target;
    filterData[name] = value;
    filterData[nameData] = valueData;
    setFilterData({ ...filterData });
    updateFilterDataAsParams();
  };

  const updateFilterDataAsParams = () => {
    if (dashboardView === 1) {
      urlParams.set('view', 'dispatch');
    } else {
      urlParams.set('view', 'company');
    }
    var params = "";
    if (filterData.Customer) {
      params += "customer=" + filterData.Customer + "&"
      urlParams.set('customer', filterData.Customer);
    } else {
      urlParams.delete('customer');
    }
    if (filterData.CustomerID) {
      params += "customerID=" + filterData.CustomerID + "&"
      urlParams.set('customerID', filterData.CustomerID);
    } else {
      urlParams.delete('customerID');
    }
    if (filterData.LocationTemplateID) {
      params += "locationTemplateID=" + filterData.LocationTemplateID + "&"
      urlParams.set('locationTemplateID', filterData.LocationTemplateID);
    } else {
      urlParams.delete('locationTemplateID');
    }
    if (filterData.SteamshipLine) {
      params += "steamshipLine=" + filterData.SteamshipLine + "&"
      urlParams.set('steamshipLine', filterData.SteamshipLine);
    } else {
      urlParams.delete('steamshipLine');
    }
    if (filterData.SteamshipLineID) {
      params += "steamshipLineID=" + filterData.SteamshipLineID + "&"
      urlParams.set('steamshipLineID', filterData.SteamshipLineID);
    } else {
      urlParams.delete('steamshipLineID');
    }
    if (filterData.Appointment) {
      params += "appointment=" + filterData.Appointment + "&"
      urlParams.set('appointment', filterData.Appointment);
    } else {
      urlParams.delete('appointment');
    }
    if (filterData.ContainerSize) {
      params += "containerSize=" + filterData.ContainerSize + "&"
      urlParams.set('containerSize', filterData.ContainerSize);
    } else {
      urlParams.delete('containerSize');
    }
    if (filterData.ContainerType) {
      params += "containerType=" + filterData.ContainerType + "&"
      urlParams.set('containerType', filterData.ContainerType);
    } else {
      urlParams.delete('containerType');
    }
    if (filterData.ShipmentType) {
      params += "shipmentType=" + filterData.ShipmentType + "&"
      urlParams.set('shipmentType', filterData.ShipmentType);
    } else {
      urlParams.delete('shipmentType');
    }
    if (params) {
      setFilterDataAsParams("&" + params.substring(0, params.length - 1));
    }
    window.history.replaceState(null, null, "dashboard?" + urlParams);
  };

  const resetFilterRow = () => {
    setFilterData(c => ({ ...c, Customer: '' }));
    setFilterData(c => ({ ...c, CustomerID: null }));
    setFilterData(c => ({ ...c, LocationTemplateID: null }));
    setFilterData(c => ({ ...c, SteamshipLine: '' }));
    setFilterData(c => ({ ...c, SteamshipLineID: null }));
    setFilterData(c => ({ ...c, Appointment: '' }));
    setFilterData(c => ({ ...c, ContainerSize: '' }));
    setFilterData(c => ({ ...c, ContainerType: '' }));
    setFilterData(c => ({ ...c, ShipmentType: '' }));
    setFilterDataAsParams("");
    urlParams.delete('customer');
    urlParams.delete('customerID');
    urlParams.delete('locationTemplateID');
    urlParams.delete('steamshipLine');
    urlParams.delete('steamshipLineID');
    urlParams.delete('appointment');
    urlParams.delete('containerSize');
    urlParams.delete('containerType');
    urlParams.delete('shipmentType');
    window.history.replaceState(null, null, "dashboard?" + urlParams);    
  };

  return (
    <>
      <Paper 
        variant="outlined" 
      square>
        <Container 
        maxWidth="false" 
        className="mt-2 mb-2">
          <Typography variant="h6" gutterBottom>
            {"Today, " + todaysDate}
            <span className={classes.rightFloat}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Dashboard View</InputLabel>
                <Select
                  value={dashboardView}
                  label="Dashboard View"
                  onChange={handleDashboardViewChange} 
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}          
                >
                  <MenuItem value={0}>Company View</MenuItem>
                  <MenuItem value={1}>Dispatch View</MenuItem>
                </Select>
              </FormControl>
            </span>
          </Typography>
        </Container>
      </Paper>
      <div className={contentContainer}>
        { dashboardView === 0 &&
          <DashboardCompanyView />
        }
        { dashboardView === 1 &&
          <DashboardDispatchView 
            filterData={filterData}
            filterDataAsParams={filterDataAsParams}
            updateFilterData={updateFilterData}
            resetFilterRow={resetFilterRow}
          />
        }
      </div>
    </>
  );

}

export default withWidth()(Dashboard);
